import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Hero from '../../../reusable/hero/Hero'
import General_process from '../../../reusable/general_process/General_process';
import Marquee from '../../../components/used_car_loan/marquee/Marquee';
import Benefits from '../../../components/used_car_loan/benefits/Benefits';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';
import styles from './UsedCar.module.css';
import LoanEligibility from '../../../reusable/loanEligibility/LoanEligibility';
import { Box, Modal } from '@mui/material';
import NewHero from '../../../reusable/hero/UsedCarHero/NewHero';
import LendingPartners from '../../../reusable/lending_partners/LendingPartners';
import { Parallax } from 'react-parallax';
import triangle_effect from '../../../images/vectors/channel_partner/space_rect.svg'
import Testimonial from '../../../reusable/testimonial/Testimonial';
import WhyUseCars from '../../../components/used_car_loan/whyUseCars/WhyUseCars';
import CategoryUsedCarLoan from '../../../components/used_car_loan/category/CategoryUsedCarLoan';
import Criteria from '../../../components/used_car_loan/criteria/Criteria';
import ReferForm from '../../../reusable/referForm/ReferForm';

const styleMUIModal = {
    position: "absolute",
    top: "0%",
    right: "0%",
    width: '50%',
    height: '100%',
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    outline: "none",
    p: 4,
};
const styleMUIModalMobile = {
    width: "100%"
}


const Used_car_loan = () => {
    const [step, setStep] = useState(1);
    const [open, setOpen] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [isFocused, setIsFocused] = useState(false);
    const [focusedInput, setFocusedInput] = useState(null);

    const [phoneNumber, setPhoneNumber] = useState('');
    const [seconds, setSeconds] = useState(20);
    const [isTimeExpired, setIsTimeExpired] = useState(false);
    const [otp, setOtp] = useState(new Array(4).fill(""));
    const [isOtpValid, setIsOtpValid] = useState(true);
    const [otpErrorMessage, setOtpErrorMessage] = useState("");

    const handleOpen = () => {
        setStep(1);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setStep(4);
    }

    useEffect(() => {
        let interval

        if (step == 2) {
            interval = setInterval(() => {
                setSeconds((prevSeconds) => {
                    if (prevSeconds === 1) {
                        setIsTimeExpired(true);
                        clearInterval(interval);
                        return 0;
                    }
                    return prevSeconds - 1;
                });
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [step]);


    // THE FORMATTED DATE FOR THE DATE AND TIME
    const formattedTime = () => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        return `${hours < 10 ? '0' : ''}${hours} : ${minutes < 10 ? '0' : ''}${minutes} : 
        ${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}s`;
    };

    // THIS 3 FUNCTIONS ARE JUST FOR UI PURPOSE 
    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value);
        setIsValid(true);
    };

    const handleInputFocus = () => {
        setIsFocused(true);
    };

    const handleInputBlur = () => {
        setIsFocused(false);
    };

    // APIS HERE ----------------------------------------------------------
    const loginAPI = async () => {
        try {
            const response = await axios.post('/mobile', {
                mobile: phoneNumber,
                signature: "Team Nxcar"
            });
            if (response === 200) {
                setStep(2);
            } else {
                setIsValid(false);
            }
        } catch (error) {
            console.error('Error during login API call:', error);
        }
    };

    // LOGIN API USING HERE
    const handleLogin = async (e) => {
        e.preventDefault();
        const indianPhoneNumberRegex = /^[6-9]\d{9}$/;
        if (indianPhoneNumberRegex.test(phoneNumber)) {
            await loginAPI();
            setStep(2);
        } else {
            setIsValid(false);
        }
    };

    // OTP VERIFICATION API HERE
    const otpVerificationAPI = async () => {
        try {
            const response = await axios.post('/otpVerification', {
                mobile: phoneNumber,
                otp: otp.join('')
            });

            if (response.status == 200) {
                localStorage.setItem("token", response.data.access_token)
                setStep(3)
            } else {
                setIsOtpValid(false);
            }
        } catch (error) {
            setIsOtpValid(false);
        }
    };

    // OTP VERIFICATION API
    const handleOTPChange = (element, index) => {
        if (isNaN(element.value)) return false;

        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

        // Focus next input
        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };

    const handleOTPVerification = async (e) => {
        e.preventDefault();
        await otpVerificationAPI();
    };

    return (
        <>
            <div className={styles.container}>
                <Header />
                <NewHero
                    heading1="Your One-Stop"
                    heading2="Destination for Second Hand Car Loans"
                    single_button="Apply Now"
                />
                <Parallax
                    bgImage={triangle_effect}
                    bgImageAlt="nxcars"
                    strength={1000}
                >
                    <CategoryUsedCarLoan />
                    <Benefits />
                    <Criteria />
                </Parallax>
                <WhyUseCars />
                <ReferForm />
                <General_process />
                <Marquee />
                <Testimonial />
                <LendingPartners />
            </div>
            <div className={styles.footer}>
                <Footer />
            </div>

            {/* MODAL */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
            >
                <Box
                    sx={{ ...styleMUIModal, ...(window.innerWidth <= 600 && styleMUIModalMobile) }}
                >
                </Box>
            </Modal>
            {/* MODAL */}
        </>
    )
}

export default Used_car_loan