import React, { useState } from 'react'
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styles from './Services.module.css';
import rc from '../../../images/vectors/homescreen/weofferServices/rc.svg'
import used from '../../../images/vectors/homescreen/weofferServices/usedcarloan.svg'
import ins from '../../../images/vectors/homescreen/weofferServices/insurance.svg'
import { FaAngleRight } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { scrollToTop } from '../../../reusable/scrollToTop/ScrollToTopButton';
import Consumer from '../../FORMS/Consumer/Consumer';

const styleMUIModal = {
  position: "absolute",
  top: "0%",
  right: "0%",
  width: '40%',
  height: '100%',
  bgcolor: "white",
  boxShadow: 24,
  outline: 0,
  p: 4,
};

const styleMUIModalMobile = {
  width: "100%",
  p: "0",
}

export default function Services() {
  const arr = [
    {
      heading: "RC Check",
      sub_heading: "Effortlessly verify your RC, insurance, ownership, permit, & hypothecation details with our seamless service.",
      img: rc,
      link: '/rc-check',
    },
    {
      heading: "Used Car Loan",
      sub_heading: "Now get used car loans for direct purchases!. Get loans up to 90% of the price, starting at just 12% pa.",
      img: used,
    },
    {
      heading: "Insurance",
      sub_heading: "Explore tailored insurance options for comprehensive coverage against diverse risks, fitting your needs and budget.",
      img: ins,
      link: '/insurance-check',
    },
  ]


  const [openConsumerModal, setOpenConsumerModal] = useState(false);

  const handleLogin = () => {
    setOpenConsumerModal(!openConsumerModal);
  }
  const closeModal = () => {
    setOpenConsumerModal(false);
  }


  return (
    <>
      <div className={styles.container}>
        <div className={styles.main_heading}>Services We Offer</div>
        <div className={styles.flex}>
          {arr.map((item) => (
            <div
              className={styles.card} key={item.img}
              onClick={item.heading === "Used Car Loan" ? handleLogin : null}
            >
              <Link to={item.link} onClick={scrollToTop}>
                <div className={styles.heading}>{item.heading}</div>
                <div className={styles.sub_heading}>
                  {item.sub_heading}
                </div>
                <div className={styles.flex2}>
                  <div className={styles.image}>
                    <img src={item.img} alt="" />
                  </div>
                  <div className={styles.next_button}>
                    <FaAngleRight />
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <Modal
        open={openConsumerModal}
        onClose={handleLogin}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...styleMUIModal, ...(window.innerWidth <= 600 && styleMUIModalMobile) }}>
          <Consumer onClose={closeModal} />
        </Box>
      </Modal>
    </>
  )
}
