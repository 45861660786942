import React from 'react'
import { useState } from 'react';
import styles from './FAQ.module.css'
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai';

const AccordianItem = ({ title,
    content,
    content1,
    content2,
    content3,
    content4,
    content5,
    content6,
    content7,
    content8,
    content9,
    content10,
    content11,
    content12,
    content13,
    content14,
    content15,
    content16,
    content17,
    content18,
    content19,
    content20,
    content21,
    content22,
    content23,
    content24,
    content25,

    content26,
    content27,
    content28,
    content29,
    content30,
    content31,
    content32,
    content33,
    content34,
    content35,
    content36,
    content37,
    content38,
    content39,
    content40,
    content41,
    content42,
    content43,
    content44,

}) => {
    
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };


    return (
        <>
            <div className={styles.accordion_item}>
                <div className={styles.accordion_header} onClick={toggleAccordion}>

                    <h3 className={styles.accordion_title}>
                        {(`${title}`)}
                    </h3>

                    <span className={`${styles.accordion_icon} ${isOpen ? 'minus' : 'plus'}`}>
                        {isOpen ? <AiOutlineMinusCircle /> : <AiOutlinePlusCircle />}
                    </span>

                </div>
                {isOpen && <div className={styles.accordion_content}>
                    {/* {content || content1} */}

                    {content && <p>{content}</p>}
                    {content1 && <p>{content1}</p>}
                    {content2 && <p>{content2}</p>}
                    {content3 && <p>{content3}</p>}
                    {content4 && <p>{content4}</p>}
                    {content5 && <p>{content5}</p>}
                    {content6 && <p>{content6} <br /> <br /></p>}

                    {content7 && <p>{content7}</p>}
                    {content8 && <p>{content8}</p>}
                    {content9 && <p>{content9}</p>}
                    {content10 && <p>{content10}</p>}
                    {content11 && <p>{content11} <br /> <br /></p>}


                    {content12 && <p>{content12}</p>}
                    {content13 && <p>{content13}</p>}
                    {content14 && <p>{content14} <br /><br /> </p>}

                    
                    {content15 && <p>{content15}</p>}
                    {content16 && <p>{content16} <br /><br /></p>}

                    {content17 && <p>{content17}</p>}
                    {content18 && <p>{content18}</p>}
                    {content19 && <p>{content19} <br /><br /> </p>}

                    {content20 && <p>{content20}</p>}
                    {content21 && <p>{content21}</p>}
                    {content22 && <p>{content22}</p>}


                    {content23 && <p>{content23}</p>}
                    {content24 && <p>{content24}</p>}
                    {content25 && <p>{content25} <br /><br /> </p>}

                    {content26 && <p>{content26}</p>}
                    {content27 && <p>{content27}</p>}
                    {content28 && <p>{content28} <br /><br /> </p>}

                    {content29 && <p>{content29} </p>}
                    {content30 && <p>{content30} </p>}
                    {content31 && <p>{content31} <br /><br /> </p>}

                    {content32 && <p>{content32} </p>}
                    {content33 && <p>{content33} </p>}
                    {content34 && <p>{content34} <br /><br /> </p>}

                    
                    {content35 && <p>{content35} </p>}
                    {content36 && <p>{content36} <br /><br /></p>}

                    {content37 && <p>{content37} </p>}
                    {content38 && <p>{content38} </p>}
                    {content39 && <p>{content39} <br /><br /> </p>}

                    
                    {content40 && <p>{content40} </p>}
                    {content41 && <p>{content41} <br /><br /> </p>}
                    {content42 && <p>{content42} </p>}
                    {content43 && <p>{content43} </p>}
                    {content44 && <p>{content44} </p>}
                </div>}

            </div>
        </>
    )
}

export default AccordianItem