import React from 'react'
import styles from './AllBlogs.module.css';
import { Link } from 'react-router-dom';
import Header from '../../../../components/Header/Header';
import Footer from '../../../../components/Footer/Footer';
import img1 from '../../../../images/vectors/homescreen/knoledge/img1.svg'
import img2 from '../../../../images/vectors/homescreen/knoledge/img2.svg'
import img3 from '../../../../images/vectors/homescreen/knoledge/img3.svg'
import img4 from '../../../../images/vectors/homescreen/knoledge/img4.svg'
import img5 from '../../../../images/vectors/homescreen/knoledge/img5.svg'
import { scrollToTop } from '../../../../reusable/scrollToTop/ScrollToTopButton';

const AllBlogs = () => {
    const arr = [
        {
            id: 1,
            image: img1,
            link: '/blog-should-you-buy-a-used-car',
            heading: 'Should you buy a used car?',
            content: "You love your bike. It's been great for getting you to work and dodging through traffic.But, you can't help missing how comfy a car ride is when you see cars passing by. It feels like a dream that's hard to reach.Even if you think about buying a car, you are unsure about spending a lot on a new car.The big question is, should you consider buying a used car?"
        },
        {
            id: 2,
            image: img2,
            link: '/blog-how-to-buy-a-used-car',
            heading: 'How to buy a used car?',
            content: "You've made up your mind: buying a used car is the smart move for you. Buying a second-hand car isn't just about saving money—it's a decision that can also get you behind the wheel of your dream car at a fraction of the cost. So, now that you're set on purchasing a pre-owned car, you might be wondering, what's the first step?"
        },
        {
            id: 3,
            image: img3,
            link: '/blog-when-not-to-buy-a-used-car',
            heading: 'When not to buy a used car?',
            content: "Imagine riding down the coastal roads of Goa in a car you just bought, the wind in your hair, music playing, and not a worry in sight. This could be you, but what if the car turns out to be less dreamy and more of a nightmare? While buying a used car in India can be a wallet-friendly and smart choice, there are times when stepping on the brakes before making the purchase is wiser. Let’s look at the scenarios when buying a used car might not be an option for you."
        },
        {
            id: 4,
            image: img4,
            link: '/blog-right-time-to-buy-car',
            heading: 'When is the right time to buy a used car?',
            content: "A car is like a reliable friend that makes your daily life easier. Once it's yours, it turns into your cosy spot, a place to have fun, and sometimes, it feels like home. Good news! You don’t need to buy a new car; a second-hand car makes more sense if you're concerned about your budget."
        },
        {
            id: 5,
            image: img5,
            link: '/blog-before-you-buy-used-car',
            heading: 'Used Car Inspection Checklist',
            content: "Thinking about buying a used car? Great choice! You can save some cash and still get a fantastic ride. But before you shake hands and take the keys, you gotta make sure everything's in good shape. Here's a simple guide to help you check out a used car, making sure you're getting a good deal."
        }
    ]
    return (
        <>
            <Header />
            <div className={styles.container}>
                <div className={styles.heading}>Blogs</div>
                {/* <div className={styles.sub_heading}>Our Stories & Blogs</div> */}

                <div className={styles.main_container}>
                    {
                        arr.map(item => (
                            <div className={styles.card} key={item.id}>
                                <Link to={item.link} onClick={scrollToTop}>
                                    <div className={styles.card_image}>
                                        <img src={item.image} alt="" />
                                    </div>
                                    <div className={styles.card_heading}>
                                        {item.heading}
                                    </div>
                                    <div className={styles.card_content}>
                                        {item.content}
                                    </div>
                                    <div className={styles.card_read_more}>
                                        Read More
                                    </div>
                                </Link>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className={styles.footer}>
                <Footer />
            </div>
        </>
    )
}

export default AllBlogs