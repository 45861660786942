import React, { useState } from 'react'
import contact_us from '../../../images/vectors/contact_us/contact_us_vector.svg'
import img1 from '../../../images/vectors/contact_us/location.svg'
import img2 from '../../../images/vectors/contact_us/msg.svg'
import img3 from '../../../images/vectors/contact_us/phone.svg'
import img4 from '../../../images/vectors/contact_us/whatsapp.svg'
import styles from './Contact.module.css';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';
import axios from 'axios'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaPhoneVolume } from 'react-icons/fa'


export default function AccountDeletion() {
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank');
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  const handleEmailClick = () => {
    openInNewTab('mailto:Contact@nxfin.in');
  };

  const handlePhoneClick = () => {
    openInNewTab('tel:+919355924132');
  };

  const handleWhatsappClick = () => {
    openInNewTab('https://wa.me/+919355924132');
  };

  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    subject: "",
    message: "",
  })

  const handleContactDetails = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/contactus', formData);

      if (response.status === 200) {
        toast.dismiss();
        toast.success('One of our team will be in contact with you shortly...');
        setFormData({
          name: "",
          mobile: "",
          subject: "",
          message: "",
        });
      } else {
        toast.dismiss();
        toast.error('Form not submitted successfully!');
      }
    } catch (error) {
      // console.log(error.message);
      toast.dismiss();
      toast.error('Something went wrong. Please try again later.');
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <div className={styles.curve_container}>
        <Header />
        <div className={styles.dark}>
          <div className={styles.container}>
            <div className={styles.left}>
              <div className={styles.heading}>
                Data Collection And Storage Inquiry
              </div>
              <div className={styles.nxfin_heading}>
                Fill this form  to review, update or delete your personal Information/Account
              </div>
              {/* form */}
              <div className={styles.form}>
                <form onSubmit={handleContactDetails}>
                  <div className={styles.multi_details}>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder='Enter Name'
                      required
                      className={formData.name.length > 0 ? styles.filled : ''}
                    />
                    <input
                      type="tel"
                      name="mobile"
                      value={formData.mobile}
                      onChange={handleChange}
                      placeholder='Enter Mobile Number'
                      required
                      maxLength="10"
                      title='Please Fill Your Indian Number'
                      className={formData.mobile.length > 0 ? styles.filled : ''}
                    />
                  </div>
                  <input
                    type="text"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    placeholder='Subject'
                    required
                    className={formData.subject.length > 0 ? styles.filled : ''}
                  />
                  <textarea
                    style={{
                      resize: 'none',
                      height: '150px',
                    }}
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder='Enter Enquiry'
                    required
                    className={formData.message.length > 0 ? styles.filled : ''}
                  />
                  <button className={styles.button} type="submit">
                    Submit
                  </button>
                </form>
              </div>

              {/* content */}
              <div className={styles.content}>
                <div className={styles.top}>
                  <div className={styles.top_left}>
                    <img src={img1} alt="" />
                  </div>
                  <div className={styles.top_right}>
                    <div className={styles.top_right_heading}>
                      Location
                    </div>
                    <div className={styles.top_right_content}>
                      Corporate Address :- 3rd Floor  <br />
                      Plot No 809, Sector 42, Golf Course Road, Gurgaon Haryana, India 122009
                      <br />
                      <br />
                      Reg. Address :- Khasra No. 232/141, H. No. 1108B, Block G, Adarsh Enclave Phase VI, Aya Nagar Extension, New Delhi, South Delhi, Delhi 110047


                    </div>
                  </div>
                </div>


                <div className={styles.bottom}>
                  <img src={img2} alt="" onClick={handleEmailClick} />
                  <img src={img3} alt="" onClick={handlePhoneClick} />
                  <img src={img4} alt="" onClick={handleWhatsappClick} />
                </div>
                <div className={styles.phone_number}>
                  <div className={styles.flex_box_phone_number}><FaPhoneVolume className={styles.phone_icon} />
                    +91 93559 24132
                  </div>
                  <div className={styles.flex_box_phone_number}><FaPhoneVolume className={styles.phone_icon} />
                    +91 93559 24133
                  </div>
                </div>

              </div>
            </div>



            <div className={styles.right}>
              <img src={contact_us} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <Footer />
      </div>
    </>
  )
}
