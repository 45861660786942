import React from 'react'
import PieChart from '../../../components/calculator/pie_chart/PieChart'
import Loan_Eligibility from '../../../components/calculator/loan_eligibility/Loan_Eligibility'
import Header from '../../../components/Header/Header'
import Footer from '../../../components/Footer/Footer'

import styles from './Calculator.module.css';

const Calculator = () => {
  return (
    <>
      <div className={styles.container}>
        <Header />
        <div className={styles.dark}>
          <PieChart />
          <Loan_Eligibility />
        </div>
      </div>
      <div className={styles.footer}>
        <Footer />
      </div>
    </>
  )
}

export default Calculator