import React, { useState, useEffect } from 'react'
import axios from 'axios';
import styles from './Loan.module.css';
import img1 from '../../../images/vectors/calculator/loan.svg'
import Zooom from 'react-reveal/Zoom';
import { Box, Modal, Zoom } from '@mui/material';
import LoanEligibility from '../../../reusable/loanEligibility/LoanEligibility';
import NewLoan from '../../../reusable/loanEligibility/NewLoanEligibility/NewLoan';
import { IoClose } from 'react-icons/io5';
import { useMediaQuery } from '@material-ui/core';
import { Car_buying } from '../../../reusable/check_now/animations/Car_buying';

const styleMUIModal = {
  position: "absolute",
  top: "0%",
  right: "0%",
  width: '40%',
  height: '100%',
  bgcolor: "white",
  border: "0px solid #000",
  boxShadow: 24,
  outline: "none",
  overflowY: "auto",
  p: 0,
};
const styleMUIModalMobile = {
  width: "100%",
  height: "100vh",
  top: "0%",
  right: "0%",
  height: '100vh',
  bgcolor: "white",
  border: "0px solid #000",
  boxShadow: 24,
  outline: "none",
  overflowY: "auto",
  p: 0,
}

const Loan_Eligibility = () => {
  const isMobile = useMediaQuery('(max-width: 940px)');
  const [isLoanFormVisible, setIsLoanFormVisible] = useState(false);

  const handleOpenLoanForm = () => {
    setIsLoanFormVisible(true);
  };

  const handleCloseLoanForm = () => {
    setIsLoanFormVisible(false);
  };


  return (
    <>
      <div className={styles.container}>
        <Zooom>
          <div className={styles.heading}>
            {/* How much you can borrow? */}
            Loan eligibility calculator
          </div>


          <div className={styles.loan_container}>
            <div className={styles.left}>
              <div className={styles.left_heading}>
                {/* Find Out Your Loan Eligibility in 5 Mins */}
                Calculate your loan eligibility in 5mins
              </div>
              <div className={styles.left_sub_heading}>
                Embark on an Effortless Journey to Your Dream Car: Discover seamless financing with our user-friendly loan eligibility calculator, factoring in key elements like credit profile, income, house type, employment, and car value. This transparent approach ensures a tailored solution for your unique circumstances. Within minutes, assess your eligibility for hassle-free loan approval. Drive confidently, knowing every aspect is considered, making your path to ownership smooth. Your dream car is within reach—start your journey today.
              </div>
              <div
                onClick={handleOpenLoanForm}
                className={styles.button}>
                Check Eligibility
              </div>
            </div>
            <div className={styles.right}>
              <div className={styles.image}>
                {/* <img src={img1} alt="" /> */}
                <Car_buying />
              </div>
            </div>
          </div>
        </Zooom>
      </div>

      {isLoanFormVisible && (
        <Modal
          open={isLoanFormVisible}
          onClose={handleCloseLoanForm}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={isMobile ? styleMUIModalMobile : styleMUIModal}>
            <NewLoan handleClose={handleCloseLoanForm} />
          </Box>
        </Modal>
      )}

    </>
  )
}

export default Loan_Eligibility