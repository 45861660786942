import React, { useState, useEffect } from 'react';
import styles from './Details.module.css';
import Table from '../../../../reusable/table/Table';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { FaEllipsisH } from "react-icons/fa";
import LoanApplication2 from '../LoanApplication2/LoanApplication2';

const Details = () => {
  const [name, setName] = useState('');
  const [loanData, setLoanData] = useState([]);
  const token = localStorage.getItem('Authorization')
  const info = jwtDecode(token);
  const consumer_id = info.consumer_id;

  const fetchData = async () => {
    try {
      const response = await axios.get(`/lead_status?consumer_id=${consumer_id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`,
        },
      });
      // console.log('r', response.data.data[0].name)
      if (response?.data?.status) {
        setLoanData(response?.data?.data);
        setName(response?.data?.data[0]?.name);
      } else {
        console.error('API request failed:', response);
      }

      if (response?.data?.data?.length !== 0) {
        localStorage.setItem('my_consumer_stored_id',
          response?.data?.data && response?.data?.lead_id)
      }

    } catch (error) {
      console.error('Error fetching data:', error?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [consumer_id]);

  const columns = [
    { label: 'APPLICATION NO.', field: 'application_no' },
    { label: 'CUSTOMER NAME', field: 'name' },
    { label: 'MOBILE', field: 'mobile' },
    { label: 'MAKE MODEL', field: 'make_model_variant' },
    { label: 'LOAN AMOUNT', field: 'loan_amount_requested' },
    { label: 'AMOUNT APPROVED', field: 'amount_approved' },
    { label: 'LEAD STATUS', field: 'lead_status ' },
    { label: 'KYC STATUS', field: 'kyc_status' },
    { label: 'APPLIED ON', field: 'applied_on' },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const range = 2;
  const totalItems = loanData.length;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = loanData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className={styles.right}>
        <div className={styles.welcome_text}>
          <div className={styles.welcome_text1}>Hello,  {name === undefined ? 'User' : name}</div>
          <div className={styles.welcome_text2}> </div>
        </div>

        {
          loanData && loanData.length > 0 ? (
            <>
              <div className={styles.table}>
                {/* WITHOUT PAGINATION */}
                {/* <Table data={loanData} columns={columns} /> */}

                {/* WITH PAGINATION */}
                <Table data={currentData} columns={columns} />

                <div className={styles.pagination}>
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Prev
                  </button>

                  {[...Array(Math.ceil(totalItems / itemsPerPage))].map((_, index) => {
                    const page = index + 1;
                    const isCurrentPage = currentPage === page;
                    if (page >= currentPage - range && page <= currentPage + range) {
                      return (
                        <button
                          key={index}
                          onClick={() => handlePageChange(page)}
                          className={isCurrentPage ? styles.activePage : ''}
                        >
                          {page}
                        </button>
                      );
                    }
                    {/* else if (page === currentPage - range - 1 || page === currentPage + range + 1) {
                    return (
                      <span key={`ellipsis-${index}`} className={styles.ellipsis}>
                        <FaEllipsisH />
                      </span>
                    );
                  } */}
                    return null;
                  })}

                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
                  >
                    Next
                  </button>
                </div>
              </div>
            </>
          ) : (<LoanApplication2 />)
        }
      </div>
    </>
  );
};

export default Details;