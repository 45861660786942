import React, { useState } from 'react';
import styles from './RcCheck.module.css';
import Header from '../../../Header/Header';
import Footer from '../../../Footer/Footer';
import RC from '../../../../images/vectors/homescreen/weofferServices/rc2.svg';
import wave1 from '../../../../images/vectors/homescreen/weofferServices/wave.svg';
import RcCheckForm from './RcCheckForm';
import LandingFooter from '../../../../reusable/heroLandingPage/landingFooter/LandingFooter';

export default function RcCheck() {
  const [stage, setStage] = useState(1);

  return (
    <>
      <div className={styles.header_container}>
        <Header />
        <div className={styles.white_container}>

          <div className={`${styles.container} ${stage === 3 ? styles.container3 : ''}`}>

            <div className={`${styles.left} ${stage === 3 ? styles.stage3Left : ''}`}>
              <RcCheckForm stage={stage} setStage={setStage} />
            </div>

            <div className={`${styles.right} ${stage === 3 ? styles.stage3Right : ''}`}>
              <img src={RC} alt="" />
            </div>

          </div>

          <div className={styles.wave}><img src={wave1} alt="" /></div>
        </div>
      </div>

      <div className={styles.footer}>
        <Footer />
      </div>
    </>
  )
}
