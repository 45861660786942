import './styles/Transparency.css';

const Transparency = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="300"
                height="235"
                fill="none"
                viewBox="0 0 300 235"
            >
                <g id="Property 1=Variant2">
                    <g id="Book">
                        <g id="Group">
                            <g id="Group_2">
                                <g id="Group_3">
                                    <path
                                        id="Vector"
                                        fill="#FAFAFA"
                                        d="M128.602 17.03H101.41a2.304 2.304 0 01-2.301-2.414l.551-11.52A2.305 2.305 0 01101.962.9h27.192c1.316 0 2.365 1.1 2.302 2.415l-.552 11.52a2.305 2.305 0 01-2.302 2.194z"
                                    ></path>
                                </g>
                                <g id="Group_4">
                                    <g id="Group_5">
                                        <path
                                            id="Vector_2"
                                            fill="#263238"
                                            d="M128.602 17.03c-.005-.024.336.025.897-.213a2.286 2.286 0 001.355-2.086l.427-9.842c-.009-1.019.335-2.207-.426-3.069a2.168 2.168 0 00-1.41-.784c-.558-.042-1.182-.01-1.786-.018l-7.807.013-17.889.01a2.179 2.179 0 00-1.853 1.044c-.19.315-.296.675-.313 1.044L99.74 4.29l-.22 4.558-.215 4.4-.052 1.073c-.025.364-.032.686.069 1.01a2.18 2.18 0 001.276 1.411c.304.125.618.165.955.16l1.011.002c.67 0 1.331.002 1.982.003l3.79.007 6.802.017 9.85.044 2.678.027.696.014c.159.004.239.009.239.014 0 .004-.08.01-.238.014l-.696.014-2.678.026-9.85.045-6.802.017-3.79.007-1.982.003-1.011.002a2.65 2.65 0 01-1.051-.176 2.438 2.438 0 01-1.431-1.576c-.116-.353-.107-.752-.082-1.096l.05-1.073.208-4.4c.07-1.495.143-3.016.217-4.56l.055-1.161c.017-.409.137-.822.353-1.176a2.458 2.458 0 012.091-1.178c6.344.004 12.389.008 17.889.01l7.807.014c.617.012 1.198-.021 1.815.027a2.384 2.384 0 011.547.87 2.42 2.42 0 01.536 1.62l-.08 1.595-.516 9.837a2.368 2.368 0 01-.529 1.453c-.287.357-.63.56-.912.68a2.328 2.328 0 01-.681.164c-.159.004-.239-.003-.239-.003z"
                                        ></path>
                                    </g>
                                </g>
                            </g>
                            <g id="Group_6">
                                <g id="Group_7">
                                    <path
                                        id="Vector_3"
                                        fill="#4AA09B"
                                        d="M213.197 17.123l1.164-7.28 4.96-2.882 4.072 4.367-1.227 7.466-8.969-1.67z"
                                    ></path>
                                </g>
                            </g>
                            <g id="Group_8">
                                <g id="Group_9">
                                    <path
                                        id="Vector_4"
                                        fill="#CCE8E5"
                                        d="M236.127 24.813l-6.019 132.1-97.058-2.758-5.774-10.087 10.438-122.771 90.326-5.98 8.087 9.496z"
                                    ></path>
                                </g>
                                <g id="Group_10" opacity="0.3">
                                    <path
                                        id="Vector_5"
                                        fill="#000"
                                        d="M230.108 156.913l-8.087-9.495 6.019-132.1 8.086 9.495-6.018 132.1z"
                                    ></path>
                                </g>
                                <g id="Group_11">
                                    <g id="Group_12">
                                        <g id="Group_13">
                                            <path
                                                id="Vector_6"
                                                fill="#FAFAFA"
                                                d="M228.04 15.318l-6.018 132.1-94.746-3.35 7.167-130.086 93.597 1.336z"
                                            ></path>
                                        </g>
                                        <g id="Group_14">
                                            <g id="Group_15">
                                                <path
                                                    id="Vector_7"
                                                    fill="#263238"
                                                    d="M228.04 15.318l-93.598-1.223.114-.106-7.14 130.087-.135-.147 1.564.056 93.182 3.307-.132.121 6.145-132.095-5.891 132.106-.006.125-.126-.004-93.181-3.282-1.565-.055-.142-.005.008-.142 7.194-130.084.005-.108.109.001 93.595 1.448z"
                                                ></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="Group_16">
                                    <path
                                        id="Vector_8"
                                        fill="#CCE8E5"
                                        d="M145.348 45.835l33.087.97a1.258 1.258 0 001.295-1.221l.037-1.24a1.258 1.258 0 00-1.221-1.295l-33.087-.97a1.257 1.257 0 00-1.294 1.221l-.037 1.24a1.257 1.257 0 001.22 1.295z"
                                    ></path>
                                </g>
                                <g id="Group_17">
                                    <path
                                        id="Vector_9"
                                        fill="#CCE8E5"
                                        d="M145.029 53.097l33.087.97a1.258 1.258 0 001.294-1.22l.037-1.24a1.259 1.259 0 00-1.221-1.295l-33.087-.97a1.259 1.259 0 00-1.295 1.22l-.036 1.24a1.258 1.258 0 001.221 1.295z"
                                    ></path>
                                </g>
                                <g id="Group_18">
                                    <path
                                        id="Vector_10"
                                        fill="#CCE8E5"
                                        d="M144.709 60.358l33.088.97a1.258 1.258 0 001.294-1.22l.037-1.24a1.259 1.259 0 00-1.221-1.295l-33.087-.97a1.258 1.258 0 00-1.294 1.22l-.037 1.24a1.257 1.257 0 001.22 1.295z"
                                    ></path>
                                </g>
                                <g id="Group_19">
                                    <path
                                        id="Vector_11"
                                        fill="#CCE8E5"
                                        d="M144.391 67.62l33.086.97a1.258 1.258 0 001.295-1.22l.036-1.24a1.257 1.257 0 00-1.22-1.295l-33.087-.97a1.258 1.258 0 00-1.295 1.22l-.037 1.24a1.26 1.26 0 001.222 1.295z"
                                    ></path>
                                </g>
                                <g id="Group_20">
                                    <path
                                        id="Vector_12"
                                        fill="#CCE8E5"
                                        d="M144.072 74.882l33.087.97a1.259 1.259 0 001.295-1.22l.036-1.24a1.258 1.258 0 00-1.22-1.295l-33.088-.97a1.257 1.257 0 00-1.294 1.22l-.037 1.24a1.259 1.259 0 001.221 1.295z"
                                    ></path>
                                </g>
                                <g id="Group_21">
                                    <path
                                        id="Vector_13"
                                        fill="#CCE8E5"
                                        d="M143.752 82.143l33.087.97a1.259 1.259 0 001.295-1.22l.036-1.24a1.258 1.258 0 00-1.22-1.294l-33.088-.97a1.258 1.258 0 00-1.294 1.22l-.037 1.24a1.259 1.259 0 001.221 1.294z"
                                    ></path>
                                </g>
                                <g id="Group_22">
                                    <path
                                        id="Vector_14"
                                        fill="#CCE8E5"
                                        d="M143.434 89.405l33.087.97a1.259 1.259 0 001.295-1.22l.036-1.24a1.258 1.258 0 00-1.221-1.295l-33.087-.97a1.259 1.259 0 00-1.295 1.221l-.036 1.24a1.259 1.259 0 001.221 1.294z"
                                    ></path>
                                </g>
                                <g id="Group_23">
                                    <path
                                        id="Vector_15"
                                        fill="#CCE8E5"
                                        d="M182.995 83.23l33.087.97a1.258 1.258 0 001.295-1.222l.036-1.24a1.257 1.257 0 00-1.22-1.294l-33.087-.97a1.258 1.258 0 00-1.295 1.22l-.037 1.24a1.258 1.258 0 001.221 1.295z"
                                    ></path>
                                </g>
                                <g id="Group_24">
                                    <path
                                        id="Vector_16"
                                        fill="#CCE8E5"
                                        d="M182.676 90.491l33.087.97a1.259 1.259 0 001.295-1.22l.036-1.24a1.258 1.258 0 00-1.221-1.295l-33.087-.97a1.257 1.257 0 00-1.294 1.221l-.037 1.24a1.259 1.259 0 001.221 1.294z"
                                    ></path>
                                </g>
                                <g id="Group_25">
                                    <path
                                        id="Vector_17"
                                        fill="#CCE8E5"
                                        d="M182.292 98.149l33.087.97a1.257 1.257 0 001.294-1.221l.037-1.24a1.259 1.259 0 00-1.221-1.295l-33.087-.97a1.259 1.259 0 00-1.295 1.221l-.036 1.24a1.258 1.258 0 001.221 1.295z"
                                    ></path>
                                </g>
                                <g id="Group_26">
                                    <path
                                        id="Vector_18"
                                        fill="#CCE8E5"
                                        d="M181.906 105.806l33.088.97a1.258 1.258 0 001.294-1.221l.037-1.24a1.258 1.258 0 00-1.221-1.294l-33.087-.97a1.257 1.257 0 00-1.295 1.22l-.036 1.24a1.257 1.257 0 001.22 1.295z"
                                    ></path>
                                </g>
                                <g id="Group_27">
                                    <path
                                        id="Vector_19"
                                        fill="#CCE8E5"
                                        d="M181.521 113.463l33.087.971a1.259 1.259 0 001.295-1.221l.036-1.24a1.258 1.258 0 00-1.22-1.295l-33.088-.969a1.257 1.257 0 00-1.294 1.22l-.037 1.24a1.259 1.259 0 001.221 1.294z"
                                    ></path>
                                </g>
                                <g id="Group_28">
                                    <path
                                        id="Vector_20"
                                        fill="#CCE8E5"
                                        d="M181.136 121.12l33.087.971a1.26 1.26 0 001.295-1.221l.036-1.24a1.257 1.257 0 00-1.221-1.294l-33.087-.97a1.257 1.257 0 00-1.294 1.22l-.037 1.24a1.257 1.257 0 001.221 1.294z"
                                    ></path>
                                </g>
                                <g id="Group_29">
                                    <path
                                        id="Vector_21"
                                        fill="#CCE8E5"
                                        d="M180.751 128.778l33.087.971a1.259 1.259 0 001.295-1.221l.036-1.24a1.257 1.257 0 00-1.22-1.294l-33.088-.97a1.257 1.257 0 00-1.294 1.22l-.037 1.24a1.257 1.257 0 001.221 1.294z"
                                    ></path>
                                </g>
                                <g id="Group_30">
                                    <path
                                        id="Vector_22"
                                        fill="#CCE8E5"
                                        d="M140.186 134.487l73.023 2.141a1.26 1.26 0 001.295-1.221l.036-1.24a1.257 1.257 0 00-1.221-1.294l-73.023-2.141a1.259 1.259 0 00-1.295 1.221l-.036 1.24a1.259 1.259 0 001.221 1.294z"
                                    ></path>
                                </g>
                                <g id="Group_31">
                                    <path
                                        id="Vector_23"
                                        fill="#CCE8E5"
                                        d="M145.919 36.38l22.314.653a1.258 1.258 0 001.294-1.22l.037-1.24a1.259 1.259 0 00-1.221-1.295l-22.313-.654a1.259 1.259 0 00-1.295 1.22l-.036 1.24a1.258 1.258 0 001.22 1.295z"
                                    ></path>
                                </g>
                                <g id="Group_32">
                                    <g id="Group_33">
                                        <path
                                            id="Vector_24"
                                            fill="#263238"
                                            d="M132.511 147.047s.156.014.461.028l1.358.058 5.242.198 19.457.686 65.727 2.22.14.005.007-.141.773-16.277 3.592-76.716 1.056-23.249.272-6.321.063-1.644c.013-.37.012-.561.012-.561s-.019.19-.041.56l-.093 1.642-.326 6.32-1.147 23.244-3.677 76.712-.769 16.277.147-.136-65.73-2.123-19.46-.6-5.244-.149-1.359-.031c-.305-.006-.461-.002-.461-.002z"
                                        ></path>
                                    </g>
                                </g>
                                <g id="Group_34">
                                    <g id="Group_35">
                                        <path
                                            id="Vector_25"
                                            fill="#263238"
                                            d="M140.209 151.002s.147.014.435.029l1.282.059 4.951.202 18.411.703 62.456 2.291.14.005.007-.139 1.033-19.558 3.945-75.696 1.162-22.94.301-6.239.071-1.622c.015-.366.015-.553.015-.553s-.02.186-.044.551l-.1 1.621-.355 6.235-1.254 22.936-4.03 75.692-1.028 19.558.147-.135-62.459-2.192c-7.824-.263-14.093-.474-18.414-.618l-4.953-.154-1.283-.033c-.289-.007-.436-.003-.436-.003z"
                                        ></path>
                                    </g>
                                </g>
                                <g id="Group_36">
                                    <g id="Group_37">
                                        <path
                                            id="Vector_26"
                                            fill="#263238"
                                            d="M171.583 13.63l1.43-1.399 17.062.083.827 1.56-19.319-.243z"
                                        ></path>
                                    </g>
                                    <g id="Group_38">
                                        <path
                                            id="Vector_27"
                                            fill="#263238"
                                            d="M190.798 21.998l.103-8.128-19.319-.244-.102 8.129 19.318.243z"
                                        ></path>
                                    </g>
                                    <g id="Group_39">
                                        <path
                                            id="Vector_28"
                                            fill="#263238"
                                            d="M191.451 22.275l.003-.28a.67.67 0 00-.662-.68l-19.319-.244a.672.672 0 00-.68.663l-.003.28a.67.67 0 00.662.68l19.319.244a.673.673 0 00.68-.663z"
                                        ></path>
                                    </g>
                                    <g id="Group_40">
                                        <path
                                            id="Vector_29"
                                            fill="#455A64"
                                            d="M185.422 19.955h-.01l-8.497-.108a.753.753 0 01-.61-.325.755.755 0 01-.084-.685l2.503-6.73a4.44 4.44 0 01-1.79-3.494c-.035-2.421 1.904-4.441 4.32-4.502a4.402 4.402 0 013.235 1.278 4.403 4.403 0 011.321 3.215 4.418 4.418 0 01-1.911 3.589l2.234 6.778a.75.75 0 01-.711.984zm-4.05-15.31c-.034 0-.069 0-.104.002-2.088.053-3.828 1.865-3.798 3.958a3.906 3.906 0 001.728 3.18l.174.118-2.647 7.121a.212.212 0 00.197.288l8.497.108a.204.204 0 00.175-.088.212.212 0 00.031-.193l-2.364-7.173.178-.112a3.889 3.889 0 001.836-3.257 3.871 3.871 0 00-1.161-2.827 3.873 3.873 0 00-2.742-1.125z"
                                        ></path>
                                    </g>
                                    <g id="Group_41">
                                        <g id="Group_42">
                                            <path
                                                id="Vector_30"
                                                fill="#455A64"
                                                d="M190.664 21.313a.943.943 0 00-.194-.011l-.555-.014-2.043-.042-6.739-.11-6.741-.076-2.043-.006-.556.007a.907.907 0 00-.194.012.91.91 0 00.194.02l.555.023 2.042.056c1.725.04 4.108.085 6.741.122 2.632.037 5.015.06 6.741.064.862.002 1.561 0 2.043-.008.242-.005.428-.007.557-.015a.865.865 0 00.192-.022z"
                                            ></path>
                                        </g>
                                    </g>
                                    <g id="Group_43">
                                        <g id="Group_44">
                                            <path
                                                id="Vector_31"
                                                fill="#455A64"
                                                d="M190.941 13.707a.944.944 0 00-.195-.011l-.554-.014-2.042-.042-6.74-.11-6.741-.076-2.043-.006-.555.007a.927.927 0 00-.195.012.91.91 0 00.194.02l.555.023 2.043.056c1.724.04 4.108.085 6.74.122 2.633.037 5.015.06 6.741.064.863.002 1.561 0 2.044-.008.241-.005.427-.007.556-.015a.906.906 0 00.192-.022z"
                                            ></path>
                                        </g>
                                    </g>
                                </g>
                                <g id="Group_45">
                                    <g id="Group_46">
                                        <path
                                            id="Vector_32"
                                            fill="#EBEBEB"
                                            d="M169.573 125.566l-23.341-.684a4.784 4.784 0 01-4.642-4.922l.598-20.382a4.784 4.784 0 014.923-4.642l23.34.684a4.785 4.785 0 014.643 4.923l-.598 20.381a4.785 4.785 0 01-4.923 4.642z"
                                        ></path>
                                    </g>
                                    <g id="Group_47">
                                        <path
                                            id="Vector_33"
                                            fill="#CCE8E5"
                                            d="M141.652 117.766a.8.8 0 01.355-.638l10.773-7.229a2.23 2.23 0 012.848.052.815.815 0 00.142.098l4.882 2.506a.805.805 0 00.93-.14l7.397-7.233a2.295 2.295 0 013.324.282l2.374 2.98a.801.801 0 01.175.508l-.112 12.036a4.545 4.545 0 01-4.689 4.5l-24.083-.762a4.544 4.544 0 01-4.398-4.709l.082-2.251z"
                                        ></path>
                                    </g>
                                    <g id="Group_48">
                                        <path
                                            id="Vector_34"
                                            fill="#CCE8E5"
                                            d="M157.561 102.993a3.29 3.29 0 11-6.575.3 3.29 3.29 0 016.575-.3z"
                                        ></path>
                                    </g>
                                </g>
                                <g id="Group_49">
                                    <g id="Group_50">
                                        <path
                                            id="Vector_35"
                                            fill="#EBEBEB"
                                            d="M217.748 78.224l-30.649-.898a6.283 6.283 0 01-6.096-6.464l.785-26.763a6.284 6.284 0 016.464-6.096l30.65.898a6.282 6.282 0 016.095 6.464l-.785 26.764a6.282 6.282 0 01-6.464 6.095z"
                                        ></path>
                                    </g>
                                    <g id="Group_51">
                                        <path
                                            id="Vector_36"
                                            fill="#CCE8E5"
                                            d="M181.1 67.441l14.594-9.793a2.928 2.928 0 013.825.145l7.189 3.692 10.258-10.03a3.015 3.015 0 014.365.371l3.35 4.206-.141 15.13a6.996 6.996 0 01-7.216 6.925l-29.544-.934a6.994 6.994 0 01-6.769-7.247l.089-2.465z"
                                        ></path>
                                    </g>
                                    <g id="Group_52">
                                        <path
                                            id="Vector_37"
                                            fill="#CCE8E5"
                                            d="M201.971 48.581a4.32 4.32 0 11-8.633.395 4.32 4.32 0 018.633-.395z"
                                        ></path>
                                    </g>
                                </g>
                            </g>
                            <g id="Group_53">
                                <g id="Group_54">
                                    <path
                                        id="Vector_38"
                                        fill="#263238"
                                        d="M146.649 61.702s-.159-.006-.433-.12a2.383 2.383 0 01-.981-.83 2.788 2.788 0 01-.479-1.967c.1-.803.549-1.668 1.301-2.348.753-.688 1.793-1.152 2.931-1.441a16.106 16.106 0 013.716-.48c2.638-.035 5.584.573 8.252 2.092.661.388 1.281.863 1.748 1.468.464.604.764 1.334.785 2.086.031.755-.289 1.486-.783 2.002-.494.524-1.121.857-1.743 1.094-1.26.472-2.537.545-3.702.598-1.173.04-2.267-.02-3.257-.14a20.416 20.416 0 01-4.578-1.105 14.885 14.885 0 01-1.184-.487c-.266-.124-.403-.193-.399-.202.004-.008.149.043.421.15.27.108.674.26 1.198.436 1.048.35 2.598.795 4.565 1.011.982.108 2.066.155 3.226.106 1.155-.062 2.413-.146 3.62-.608.597-.23 1.188-.552 1.639-1.035.45-.476.733-1.13.705-1.813-.021-.681-.299-1.362-.732-1.924-.438-.565-1.028-1.02-1.67-1.396-2.611-1.488-5.512-2.097-8.108-2.076a16.1 16.1 0 00-3.662.447c-1.119.274-2.127.715-2.856 1.364-.73.642-1.17 1.462-1.278 2.223a2.696 2.696 0 00.413 1.884c.301.458.662.71.916.84.259.127.413.156.409.171z"
                                    ></path>
                                </g>
                            </g>
                            <g id="Group_55">
                                <g id="Group_56">
                                    <path
                                        id="Vector_39"
                                        fill="#263238"
                                        d="M160.504 136.59s-.158-.006-.432-.121a2.38 2.38 0 01-.982-.829 2.787 2.787 0 01-.478-1.967c.099-.803.548-1.668 1.301-2.348.753-.688 1.792-1.152 2.93-1.441a16.07 16.07 0 013.717-.481c2.638-.035 5.584.574 8.251 2.093.661.388 1.282.863 1.749 1.468.463.604.763 1.334.785 2.086.031.755-.29 1.486-.783 2.002-.494.524-1.121.857-1.743 1.094-1.26.472-2.537.545-3.703.598a20.99 20.99 0 01-3.257-.141 20.358 20.358 0 01-4.578-1.104c-.522-.193-.92-.361-1.184-.487-.266-.124-.403-.193-.399-.202.004-.008.149.044.421.15.27.108.673.26 1.198.436 1.048.351 2.598.795 4.565 1.011.982.107 2.066.155 3.226.106 1.155-.062 2.412-.146 3.62-.608.597-.231 1.188-.552 1.639-1.035.45-.476.733-1.131.704-1.813-.02-.681-.298-1.362-.731-1.924-.438-.565-1.028-1.019-1.67-1.396-2.611-1.488-5.512-2.097-8.108-2.076a16.042 16.042 0 00-3.662.447c-1.119.274-2.127.715-2.855 1.363-.731.643-1.17 1.463-1.279 2.224a2.694 2.694 0 00.414 1.884c.301.458.662.709.916.839.259.128.412.157.408.172z"
                                    ></path>
                                </g>
                            </g>
                            <g id="Group_57">
                                <g id="Group_58">
                                    <path
                                        id="Vector_40"
                                        fill="#263238"
                                        d="M144.42 37.177s-.158-.007-.432-.12a2.383 2.383 0 01-.981-.83 2.787 2.787 0 01-.479-1.967c.1-.803.549-1.668 1.301-2.348.753-.688 1.793-1.152 2.931-1.441a16.106 16.106 0 013.716-.48c2.638-.036 5.584.573 8.252 2.092.661.388 1.281.863 1.748 1.468.464.603.764 1.334.785 2.086.031.755-.29 1.486-.783 2.002-.495.524-1.121.857-1.743 1.094-1.26.472-2.537.546-3.703.598a21.08 21.08 0 01-3.257-.14 20.402 20.402 0 01-4.578-1.104c-.522-.193-.92-.362-1.184-.487-.266-.124-.403-.193-.399-.202.004-.008.149.043.421.15.27.108.673.26 1.198.436 1.048.35 2.598.795 4.565 1.011.982.107 2.065.155 3.226.106 1.155-.062 2.412-.146 3.62-.608.597-.23 1.188-.552 1.639-1.035.45-.476.733-1.13.704-1.813-.02-.681-.298-1.362-.731-1.924-.438-.565-1.029-1.02-1.67-1.396-2.611-1.488-5.512-2.097-8.108-2.076a16.074 16.074 0 00-3.662.447c-1.119.274-2.127.715-2.856 1.363-.73.643-1.17 1.463-1.279 2.224a2.7 2.7 0 00.415 1.884c.3.458.661.71.915.84.26.126.414.155.409.17z"
                                    ></path>
                                </g>
                            </g>
                            <g id="Group_59">
                                <g id="Group_60">
                                    <path
                                        id="Vector_41"
                                        fill="#4AA09B"
                                        d="M226.678 112.394l7.371.184 3.517 4.532-3.785 4.617-7.563-.222.46-9.111z"
                                    ></path>
                                </g>
                            </g>
                            <g id="Group_61">
                                <g id="Group_62">
                                    <path
                                        id="Vector_42"
                                        fill="#455A64"
                                        d="M227.377 99.465l7.371.184 3.516 4.532-3.784 4.617-7.564-.221.461-9.112z"
                                    ></path>
                                </g>
                            </g>
                            <g id="Group_63">
                                <path
                                    id="Vector_43"
                                    fill="#E0E0E0"
                                    d="M126.939 6.506h-23.315a.278.278 0 00-.277.277V6.9c0 .153.124.277.277.277h23.315a.278.278 0 00.278-.277v-.115a.278.278 0 00-.278-.278z"
                                ></path>
                            </g>
                            <g id="Group_64">
                                <path
                                    id="Vector_44"
                                    fill="#E0E0E0"
                                    d="M126.939 8.631h-23.315a.277.277 0 00-.277.277v.116c0 .153.124.276.277.276h23.315a.278.278 0 00.278-.276v-.116a.278.278 0 00-.278-.277z"
                                ></path>
                            </g>
                            <g id="Group_65">
                                <path
                                    id="Vector_45"
                                    fill="#E0E0E0"
                                    d="M126.939 10.756h-23.315a.278.278 0 00-.277.277v.116c0 .153.124.277.277.277h23.315a.278.278 0 00.278-.277v-.115a.278.278 0 00-.278-.278z"
                                ></path>
                            </g>
                            <g id="Group_66">
                                <g id="Group_67">
                                    <path
                                        id="Vector_46"
                                        fill="#263238"
                                        d="M131.519 8.49c.062-.048 3.772 4.756 8.285 10.727 4.516 5.973 8.125 10.851 8.064 10.898-.062.046-3.771-4.757-8.286-10.73-4.514-5.97-8.124-10.85-8.063-10.896z"
                                    ></path>
                                </g>
                            </g>
                            <g id="Group_68">
                                <path
                                    id="Vector_47"
                                    fill="#263238"
                                    d="M131.383 8.966a.908.908 0 100-1.817.908.908 0 000 1.817z"
                                ></path>
                            </g>
                            <g id="Group_69">
                                <g id="Group_70">
                                    <path
                                        id="Vector_48"
                                        fill="#FAFAFA"
                                        d="M156.637 192.053h-27.192a2.305 2.305 0 01-2.302-2.414l.552-11.521a2.305 2.305 0 012.301-2.194h27.192c1.316 0 2.365 1.1 2.302 2.415l-.552 11.52a2.302 2.302 0 01-2.301 2.194z"
                                    ></path>
                                </g>
                                <g id="Group_71">
                                    <g id="Group_72">
                                        <path
                                            id="Vector_49"
                                            fill="#263238"
                                            d="M156.637 192.053c-.005-.024.336.024.897-.213a2.291 2.291 0 001.355-2.087l.427-9.841c-.009-1.02.335-2.208-.426-3.069a2.17 2.17 0 00-1.41-.785c-.558-.042-1.182-.009-1.786-.018l-7.807.013-17.889.011a2.175 2.175 0 00-1.852 1.043 2.201 2.201 0 00-.314 1.045l-.056 1.161-.22 4.559-.215 4.4-.052 1.073c-.025.363-.032.686.069 1.01a2.182 2.182 0 001.276 1.411c.304.125.619.165.955.16l1.011.002 1.982.003 3.79.007 6.802.017 9.85.045 2.678.026.696.014c.159.004.239.008.239.013 0 .005-.08.01-.237.015l-.697.014c-.626.005-1.525.015-2.677.026l-9.85.044-6.803.017a4168.276 4168.276 0 01-5.771.011l-1.012.001a2.651 2.651 0 01-1.051-.175 2.436 2.436 0 01-1.431-1.577c-.116-.352-.106-.751-.082-1.095l.051-1.073.207-4.4.216-4.56.055-1.161a2.5 2.5 0 01.354-1.176 2.459 2.459 0 012.091-1.178l17.889.011 7.807.013c.617.012 1.198-.021 1.815.027a2.393 2.393 0 011.548.871 2.43 2.43 0 01.536 1.619l-.081 1.595-.515 9.837c-.017.593-.236 1.107-.53 1.453a2.298 2.298 0 01-1.593.844c-.159.004-.239-.003-.239-.003z"
                                        ></path>
                                    </g>
                                </g>
                            </g>
                            <g id="Group_73">
                                <path
                                    id="Vector_50"
                                    fill="#E0E0E0"
                                    d="M154.975 181.529h-23.316a.278.278 0 00-.277.277v.116c0 .153.124.276.277.276h23.316a.276.276 0 00.276-.276v-.116a.277.277 0 00-.276-.277z"
                                ></path>
                            </g>
                            <g id="Group_74">
                                <path
                                    id="Vector_51"
                                    fill="#E0E0E0"
                                    d="M154.975 183.654h-23.316a.277.277 0 00-.277.276v.116c0 .153.124.277.277.277h23.316a.277.277 0 00.276-.277v-.116a.277.277 0 00-.276-.276z"
                                ></path>
                            </g>
                            <g id="Group_75">
                                <path
                                    id="Vector_52"
                                    fill="#E0E0E0"
                                    d="M154.975 185.779h-23.316a.278.278 0 00-.277.277v.116c0 .153.124.277.277.277h23.316a.277.277 0 00.276-.277v-.116a.277.277 0 00-.276-.277z"
                                ></path>
                            </g>
                            <g id="Group_76">
                                <g id="Group_77">
                                    <path
                                        id="Vector_53229299292929"
                                        fill="#263238"
                                        d="M166.929 138.173c.069.035-4.279 8.516-9.71 18.939-5.432 10.427-9.89 18.848-9.958 18.812-.069-.035 4.278-8.514 9.711-18.941 5.43-10.423 9.889-18.845 9.957-18.81z"
                                    ></path>
                                </g>
                            </g>
                            <g id="Group_78">
                                <path
                                    id="Vector_54"
                                    fill="#263238"
                                    d="M148.169 175.924a.909.909 0 11-1.817-.001.909.909 0 011.817.001z"
                                ></path>
                            </g>
                        </g>
                    </g>
                    <g id="Speech_Bubble_3">
                        <g id="Group_79">
                            <g id="Group_80">
                                <g id="Group_81">
                                    <path
                                        id="Vector_55"
                                        fill="#fff"
                                        d="M289.814 37.138h-24.31a9.32 9.32 0 00-9.32 9.32c0 4.966 3.886 9.013 8.781 9.294l2.201 3.808 2.75-3.781h19.897a9.32 9.32 0 00.001-18.64z"
                                    ></path>
                                </g>
                                <g id="Group_82">
                                    <g id="Group_83">
                                        <path
                                            id="Vector_56"
                                            fill="#263238"
                                            d="M289.814 37.138s.084-.005.248 0l.728.026c.633.083 1.588.204 2.724.685 1.128.476 2.429 1.315 3.567 2.66a10.034 10.034 0 011.442 2.41c.392.921.621 1.972.682 3.082a9.36 9.36 0 01-2.36 6.728 9.593 9.593 0 01-3.29 2.393c-1.291.58-2.772.805-4.253.78l-19.385.016.112-.058-2.749 3.782-.126.173-.107-.185c-.284-.491-.589-1.02-.897-1.55l-1.304-2.258.112.069a10.376 10.376 0 01-2.725-.569 9.894 9.894 0 01-2.407-1.317 9.457 9.457 0 01-3.174-4.246c-.628-1.65-.729-3.435-.423-5.095.332-1.654 1.083-3.193 2.152-4.397 1.061-1.212 2.392-2.13 3.826-2.645.18-.063.356-.135.536-.19l.548-.14c.359-.11.733-.135 1.096-.199.366-.049.734-.044 1.095-.069h1.067l7.832.018 11.304.043 3.063.026.796.013c.178.005.27.014.27.014s-.092.01-.271.014l-.795.014-3.064.025-11.304.044-7.832.017-1.066.003c-.353.026-.712.022-1.068.072-.354.063-.718.087-1.069.196l-.533.138c-.175.055-.347.125-.521.187-1.397.506-2.69 1.404-3.72 2.586-1.037 1.175-1.764 2.672-2.084 4.28-.294 1.614-.194 3.35.418 4.95a9.194 9.194 0 003.086 4.121 9.623 9.623 0 002.337 1.277c.847.3 1.737.484 2.645.55l.076.006.037.064 1.305 2.257.896 1.55-.234-.012 2.751-3.78.042-.058h.07l19.386.015c1.469.027 2.893-.187 4.16-.748a9.374 9.374 0 003.218-2.324 9.168 9.168 0 002.338-6.572c-.053-1.089-.271-2.12-.65-3.028a9.918 9.918 0 00-1.4-2.381 9.592 9.592 0 00-3.494-2.66c-2.266-.935-3.68-.729-3.66-.803z"
                                        ></path>
                                    </g>
                                </g>
                            </g>
                            <g id="Group_84">
                                <path
                                    id="Vector_57"
                                    fill="#FFEAAD"
                                    d="M266.977 43.545l.872 1.766 1.949.283-1.41 1.375.332 1.941-1.743-.916-1.743.916.333-1.94-1.411-1.376 1.949-.283.872-1.766z"
                                ></path>
                            </g>
                            <g id="Group_85">
                                <path
                                    id="Vector_58"
                                    fill="#FFEAAD"
                                    d="M274.372 43.545l.872 1.766 1.949.283-1.41 1.375.332 1.941-1.743-.916-1.743.916.333-1.94-1.411-1.376 1.949-.283.872-1.766z"
                                ></path>
                            </g>
                            <g id="Group_86">
                                <path
                                    id="Vector_59"
                                    fill="#FFEAAD"
                                    d="M281.768 43.545l.871 1.766 1.949.283-1.41 1.375.333 1.941-1.743-.916-1.744.916.333-1.94-1.41-1.376 1.949-.283.872-1.766z"
                                ></path>
                            </g>
                            <g id="Group_87">
                                <path
                                    id="Vector_60"
                                    fill="#FFEAAD"
                                    d="M289.163 43.545l.871 1.767 1.949.283-1.41 1.375.333 1.94-1.743-.916-1.744.917.333-1.941-1.41-1.375 1.949-.283.872-1.767z"
                                ></path>
                            </g>
                        </g>
                    </g>
                    <g id="Speech_Bubble_1">
                        <g id="Group_88">
                            <g id="Group_89">
                                <g id="Group_90">
                                    <g id="Group_91">
                                        <g id="Group_92">
                                            <g id="Group_93">
                                                <g id="Group_94">
                                                    <g id="Group_95">
                                                        <g id="Group_96">
                                                            <g id="Group_97">
                                                                <g id="Group_98">
                                                                    <g id="Group_99">
                                                                        <path
                                                                            id="Vector_61"
                                                                            fill="#fff"
                                                                            d="M24.944 60.528l-1.038-3.85a12.775 12.775 0 001.813-7.588c-.46-6.282-5.558-11.377-11.841-11.82-7.892-.558-14.375 6.022-13.7 13.905.542 6.321 5.782 11.36 12.12 11.67 3.24.159 6.225-.889 8.559-2.725l-.004.005 4.091.403z"
                                                                        ></path>
                                                                    </g>
                                                                    <g id="Group_100">
                                                                        <g id="Group_101">
                                                                            <path
                                                                                id="Vector_62"
                                                                                fill="#263238"
                                                                                d="M24.945 60.528s-.097-.016-.276-.037l-.789-.091-3.02-.334.042.095.004-.006a.06.06 0 00-.011-.084.06.06 0 00-.073.001 12.843 12.843 0 01-5.716 2.511c-1.247.221-2.63.235-4.066.014a12.635 12.635 0 01-8.14-4.822 12.78 12.78 0 01-2.317-4.926C-.27 49.178.637 44.825 3.42 41.71l.523-.58.571-.527c.365-.37.807-.642 1.204-.963.853-.564 1.74-1.07 2.683-1.412 1.87-.72 3.86-.973 5.753-.802a12.76 12.76 0 018.982 5.073c1.95 2.624 2.642 5.657 2.512 8.175a12.779 12.779 0 01-1.79 5.973l-.005.046.801 2.834.214.741c.047.168.078.259.078.259a2.426 2.426 0 00-.055-.24l-.184-.731c-.172-.672-.42-1.64-.743-2.894l-.006.045a12.76 12.76 0 001.872-6.023c.152-2.552-.532-5.644-2.507-8.324a12.979 12.979 0 00-9.145-5.2 13.12 13.12 0 00-5.877.81c-.965.349-1.87.865-2.743 1.44-.407.328-.858.608-1.231.985l-.584.539-.535.592C.364 44.71-.56 49.16.321 52.907a13.003 13.003 0 002.377 5.022 12.932 12.932 0 003.89 3.368 12.816 12.816 0 004.42 1.508c1.46.216 2.865.193 4.128-.041a12.83 12.83 0 005.759-2.598l-.085-.083-.004.006a.06.06 0 00.01.083c.01.007.021.01.032.012l3.069.265.775.063c.172.014.257.02.253.016z"
                                                                            ></path>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g id="Group_102">
                                <g id="Group_103">
                                    <path
                                        id="Vector_63"
                                        fill="#FFEAAD"
                                        d="M12.693 56.244l-6.736-4.507 1.572-2.35 4.517 3.021 5.62-7.438 2.257 1.705-7.23 9.569z"
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                    <g id="Character_3">
                        <g id="Group_104">
                            <g id="Group_105">
                                <g id="Group_106">
                                    <g id="Group_107">
                                        <g id="Group_108">
                                            <g id="Group_109">
                                                <g id="Group_110">
                                                    <path
                                                        id="Vector_64"
                                                        fill="#263238"
                                                        d="M235.506 44.115s-2.436 9.106-2.645 15.548l-.07 2.146s5.373 4.462 9.88 4.737l3.778-9.29-10.943-13.14z"
                                                    ></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_111">
                                        <g id="Group_112">
                                            <g id="Group_113">
                                                <g id="Group_114">
                                                    <g id="Group_115">
                                                        <g id="Group_116">
                                                            <g id="Group_117">
                                                                <g id="Group_118">
                                                                    <g id="Group_119">
                                                                        <g id="Group_120">
                                                                            <g id="Group_121">
                                                                                <g id="Group_122">
                                                                                    <path
                                                                                        id="Vector_65"
                                                                                        fill="#263238"
                                                                                        d="M259.374 49.7c-1.027-4.44-1.422-9.61-5.115-12.505-2.74-2.149-5.862-2.748-9.342-2.602-1.713.071-3.648.861-5.14 1.706-2.046 1.158-2.912 3.507-3.9 5.641-1.977 4.275-1.727 10.063-.387 11.672l17.863 9.434c3.567-1.856 5.802-2.596 6.372-7.35.24-2.001.103-4.032-.351-5.996z"
                                                                                    ></path>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                            <g id="Group_123">
                                                                <g id="Group_124">
                                                                    <g id="Group_125">
                                                                        <g id="Group_126">
                                                                            <g id="Group_127">
                                                                                <g id="Group_128">
                                                                                    <path
                                                                                        id="Vector_66"
                                                                                        fill="#FFBF9D"
                                                                                        d="M254.069 40.724l-11.242-3.317c-3.622-.528-6.216 3.855-6.589 7.496-.415 4.044-.763 8.999-.401 12.043.726 6.121 5.358 7.078 5.358 7.078.003-.02.011.092-.025 1.9l-.014 3.725c-.049 1.185.16 1.584.884 2.522 1.658 2.148 4.68 2.435 8.244 1.117 2.065-.765 3.501-2.301 3.659-4.395l1.954-25.924a2.14 2.14 0 00-1.828-2.245z"
                                                                                    ></path>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                                <g id="Group_129">
                                                                    <g id="Group_130">
                                                                        <g id="Group_131">
                                                                            <g id="Group_132">
                                                                                <g id="Group_133">
                                                                                    <g id="Group_134">
                                                                                        <g id="Group_135">
                                                                                            <path
                                                                                                id="Vector_67"
                                                                                                fill="#263238"
                                                                                                d="M237.307 50.442c-.031.41.292.776.721.816.429.04.801-.26.832-.672.031-.41-.292-.777-.72-.817-.43-.04-.802.262-.833.673z"
                                                                                            ></path>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                                <g id="Group_136">
                                                                    <g id="Group_137">
                                                                        <g id="Group_138">
                                                                            <g id="Group_139">
                                                                                <g id="Group_140">
                                                                                    <g id="Group_141">
                                                                                        <g id="Group_142">
                                                                                            <path
                                                                                                id="Vector_68"
                                                                                                fill="#263238"
                                                                                                d="M236.981 48.57c.093.106.702-.308 1.541-.273.839.023 1.438.48 1.533.38.047-.044-.045-.23-.305-.437a2.145 2.145 0 00-1.223-.43c-.523-.017-.97.158-1.231.345-.266.187-.361.368-.315.416z"
                                                                                            ></path>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                                <g id="Group_143">
                                                                    <g id="Group_144">
                                                                        <g id="Group_145">
                                                                            <g id="Group_146">
                                                                                <g id="Group_147">
                                                                                    <g id="Group_148">
                                                                                        <g id="Group_149">
                                                                                            <path
                                                                                                id="Vector_69"
                                                                                                fill="#263238"
                                                                                                d="M244.688 50.563c-.031.411.292.777.721.816.428.04.801-.26.832-.671.031-.412-.293-.778-.72-.817-.43-.04-.803.26-.833.672z"
                                                                                            ></path>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                                <g id="Group_150">
                                                                    <g id="Group_151">
                                                                        <g id="Group_152">
                                                                            <g id="Group_153">
                                                                                <g id="Group_154">
                                                                                    <g id="Group_155">
                                                                                        <g id="Group_156">
                                                                                            <path
                                                                                                id="Vector_70"
                                                                                                fill="#263238"
                                                                                                d="M245.24 48.67c.094.105.703-.309 1.541-.274.839.023 1.438.48 1.533.38.046-.044-.044-.231-.305-.437a2.147 2.147 0 00-1.223-.43c-.523-.018-.97.157-1.231.344-.265.189-.36.37-.315.416z"
                                                                                            ></path>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                                <g id="Group_157">
                                                                    <g id="Group_158">
                                                                        <g id="Group_159">
                                                                            <g id="Group_160">
                                                                                <g id="Group_161">
                                                                                    <g id="Group_162">
                                                                                        <g id="Group_163">
                                                                                            <path
                                                                                                id="Vector_71"
                                                                                                fill="#263238"
                                                                                                d="M241.97 54.112c.006-.047-.512-.158-1.354-.3-.213-.03-.415-.08-.445-.228-.044-.157.054-.385.168-.63l.698-1.608c.972-2.29 1.685-4.175 1.592-4.214-.093-.038-.955 1.786-1.927 4.076l-.672 1.618c-.09.241-.241.512-.144.837a.539.539 0 00.34.328c.136.047.257.054.362.065.849.08 1.377.104 1.382.056z"
                                                                                            ></path>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                                <g id="Group_164">
                                                                    <g id="Group_165">
                                                                        <g id="Group_166">
                                                                            <g id="Group_167">
                                                                                <g id="Group_168">
                                                                                    <g id="Group_169">
                                                                                        <g id="Group_170">
                                                                                            <path
                                                                                                id="Vector_72"
                                                                                                fill="#6F4439"
                                                                                                d="M241.172 64.025s4.082.425 8.233-1.853c0 0-2.182 4.178-8.248 3.369l.015-1.516z"
                                                                                            ></path>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                                <g id="Group_171">
                                                                    <g id="Group_172">
                                                                        <g id="Group_173">
                                                                            <g id="Group_174">
                                                                                <g id="Group_175">
                                                                                    <g id="Group_176">
                                                                                        <path
                                                                                            id="Vector_73"
                                                                                            fill="#6F4439"
                                                                                            d="M244.693 55.978c-.203-.36-.588-.562-.99-.59-.579-.043-1.073.137-1.471.657-.134.15-.14.586-.092.708.048.123.01.062.1.149.327.312.673.625 1.104.746.431.121.972.002 1.273-.412.268-.368.279-.896.076-1.258z"
                                                                                        ></path>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                                <g id="Group_177">
                                                                    <g id="Group_178">
                                                                        <g id="Group_179">
                                                                            <g id="Group_180">
                                                                                <g id="Group_181">
                                                                                    <g id="Group_182">
                                                                                        <g id="Group_183">
                                                                                            <path
                                                                                                id="Vector_74"
                                                                                                fill="#263238"
                                                                                                d="M244.568 54.827c-.136-.014-.179.897-.988 1.509-.808.614-1.774.467-1.787.594-.016.057.208.187.617.217a2.27 2.27 0 001.49-.445c.493-.375.722-.895.769-1.267.053-.38-.038-.61-.101-.608z"
                                                                                            ></path>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                                <g id="Group_184">
                                                                    <g id="Group_185">
                                                                        <g id="Group_186">
                                                                            <g id="Group_187">
                                                                                <g id="Group_188">
                                                                                    <g id="Group_189">
                                                                                        <g id="Group_190">
                                                                                            <path
                                                                                                id="Vector_75"
                                                                                                fill="#263238"
                                                                                                d="M244.923 45.597c.06.235.906.208 1.875.422.974.194 1.737.558 1.887.367.068-.092-.05-.32-.348-.57-.296-.251-.781-.51-1.363-.632-.583-.122-1.132-.081-1.503.03-.375.11-.574.271-.548.383z"
                                                                                            ></path>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                                <g id="Group_191">
                                                                    <g id="Group_192">
                                                                        <g id="Group_193">
                                                                            <g id="Group_194">
                                                                                <g id="Group_195">
                                                                                    <g id="Group_196">
                                                                                        <g id="Group_197">
                                                                                            <path
                                                                                                id="Vector_76"
                                                                                                fill="#263238"
                                                                                                d="M237.052 46.31c.19.166.802-.123 1.57-.243.764-.145 1.439-.079 1.561-.298.052-.106-.079-.28-.389-.418-.306-.137-.797-.215-1.323-.124-.527.091-.96.329-1.2.561-.244.234-.305.44-.219.523z"
                                                                                            ></path>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="Group_198">
                                                    <g id="Group_199">
                                                        <g id="Group_200">
                                                            <g id="Group_201">
                                                                <g id="Group_202">
                                                                    <path
                                                                        id="Vector_77"
                                                                        fill="#263238"
                                                                        d="M242.788 36.752c-.011.043-.019.087-.029.131l.021.01.008-.142z"
                                                                    ></path>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <g id="Group_203">
                                            <g id="Group_204">
                                                <g id="Group_205">
                                                    <g id="Group_206">
                                                        <path
                                                            id="Vector_78"
                                                            fill="#263238"
                                                            d="M250.648 66.481c.172-1.595-.47-3.197-.284-4.791.198-1.69 1.292-3.138 1.741-4.778.72-2.628-.267-5.422-.629-8.137-1.523-.188-2.943-1.103-3.957-2.285-1.22-1.422-1.958-3.185-2.677-4.916a8.235 8.235 0 001.656 4.729c-.676.14-1.389-.149-1.896-.616-.508-.468-.84-1.094-1.113-1.729a13.481 13.481 0 01-1.078-4.867c-.225 2.39.04 4.827.795 7.107-.426-.131-.883-.013-1.324.05-.441.064-.947.052-1.258-.267-.209-.213-.284-.52-.345-.812a18.246 18.246 0 01-.202-6.192c-.543 1.112-1.081 2.236-1.395 3.432-.315 1.196-.397 2.481-.026 3.661-.175.222-.548.187-.756-.005-.208-.192-.287-.484-.331-.764-.16-1.022.021-2.064.201-3.082-.609 1.078-.81 2.33-1.147 3.52-.337 1.193-.872 2.414-1.908 3.093-.179-1.783-.116-3 .152-4.669.205-1.276.81-2.54 1.369-3.706.625-1.304 1.495-2.583 2.467-3.433 1.792-1.57 2.542-.895 3.687-.296l.135.057c.004-.028.005-.057.009-.084l.052-.245c2.98-.67 6.14-.523 9.044.423 2.158.704 2.356 5.35 2.883 3.143.318-1.336 2.05-.117 2.071-.079 2.545 4.591 5.243 11.874 5.367 23.382l-11.303 3.156z"
                                                        ></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="Group_207">
                                    <path
                                        id="Vector_79"
                                        fill="#263238"
                                        d="M258.014 31.413c-1.018-1.112-2.451-1.88-3.957-1.927-1.507-.046-3.056.693-3.822 1.99-.766 1.298-.615 3.12.471 4.164l1.881 1.505c1.294.713 2.894.948 4.271.414 1.377-.533 2.447-1.911 2.411-3.388-.025-1.032-.558-1.997-1.255-2.758z"
                                    ></path>
                                </g>
                                <g id="Group_208">
                                    <g id="Group_209">
                                        <path
                                            id="Vector_80"
                                            fill="#455A64"
                                            d="M256.563 39.76c-.364.17-1.393-1.406-3.232-2.645-1.81-1.28-3.648-1.685-3.618-2.086.016-.182.533-.33 1.372-.243.833.083 1.974.446 3.04 1.18 1.063.737 1.807 1.675 2.179 2.425.378.755.424 1.29.259 1.37z"
                                        ></path>
                                    </g>
                                </g>
                            </g>
                            <g id="Group_210">
                                <g id="Group_211">
                                    <g id="Group_212">
                                        <path
                                            id="Vector_81"
                                            fill="#FFEAAD"
                                            d="M230.413 89.773l7.045-6.597 4.772 7.58c-.095.68-6.178 13.787-8.811 16.503-3.005 3.099-7.101 2.86-8.909-1.42-1.425-3.373-3.789-13.76-3.789-13.76l9.692-2.306z"
                                        ></path>
                                    </g>
                                </g>
                                <g id="Group_213">
                                    <g id="Group_214">
                                        <g id="Group_215">
                                            <g id="Group_216">
                                                <path
                                                    id="Vector_82"
                                                    fill="#FFEAAD"
                                                    d="M238.095 69.93c-1.683.606-5.553 2.722-6.681 4.11-.545.672-1.074 1.435-1.422 2.2-.882 1.938-2.557 16.314-2.557 16.314l7.502 5.433 6.532-25.144-.417-3.988-2.957 1.075z"
                                                ></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="Group_217">
                                    <g id="Group_218">
                                        <g id="Group_219">
                                            <g id="Group_220">
                                                <g id="Group_221">
                                                    <path
                                                        id="Vector_83"
                                                        fill="#FFEAAD"
                                                        d="M235.605 111.434l.473-3.714s-1.446-6.625-1.978-12.378c-.844-9.145 1.541-14.83 1.867-15.793.185-.546 5.084-10.694 5.084-10.694s1.028 3.126 5.163 2.387c5.765-1.031 7.865-4.156 7.865-4.156l7.449 1.61s2.902 11.656 3.329 15.517c.229 2.07-2.499 13.774-2.499 13.774l4.393 6.571c.132.323-1.909 9.304-2.307 9.675-.399.371-7.545 2.031-7.634 2.336-.089.306-13.623 5.972-13.614 5.741.009-.232-6.512-7.976-6.512-7.976l-1.079-2.9z"
                                                    ></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="Group_222">
                                    <g id="Group_223">
                                        <path
                                            id="Vector_84"
                                            fill="#263238"
                                            d="M233.921 80.944c.009.001.003.095-.018.272l-.091.77c-.08.67-.193 1.638-.293 2.839-.186 2.396-.377 5.737.175 9.373.482 3.644 1.185 6.902 1.638 9.272.23 1.184.406 2.145.518 2.81l.119.768c.024.177.032.27.023.271-.01.002-.036-.087-.078-.261a477.58 477.58 0 01-.17-.758l-.606-2.791c-.502-2.358-1.237-5.616-1.721-9.275-.556-3.66-.332-7.033-.095-9.428.125-1.202.268-2.17.381-2.835.058-.333.106-.59.144-.764.039-.175.064-.265.074-.263z"
                                        ></path>
                                    </g>
                                </g>
                            </g>
                            <g id="Group_224">
                                <g id="Group_225">
                                    <g id="Group_226">
                                        <g id="Group_227">
                                            <g id="Group_228">
                                                <path
                                                    id="Vector_85"
                                                    fill="#4A6361"
                                                    d="M268.056 109.85c1.335 2.267 4.385 10.942 5.57 15.373 5.41 20.218 9.987 47.408 11.507 52.949 2.025 7.383 6.561 24.088 8.869 35.213l-11.087 4.835s-14.52-39.125-18.481-48.739c-4.105-9.963-10.953-28.754-10.953-28.754l-3.956 74.534-10.62.328s-3.632-21.002-5.076-33.555c-1.444-12.552-1.606-38.392-1.618-44.95-.014-7.986 3.447-25.952 3.447-25.952s4.142 3.109 11.909 1.919c6.186-.948 19.011-5.706 19.011-5.706l1.478 2.505z"
                                                ></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="Group_229">
                                    <g id="Group_230">
                                        <g id="Group_231">
                                            <g id="Group_232">
                                                <g id="Group_233">
                                                    <path
                                                        id="Vector_86"
                                                        fill="#455A64"
                                                        d="M246.466 115.637c-.018.002.005.307.063.856.056.549.153 1.341.283 2.319.26 1.956.666 4.651 1.196 7.615a179.184 179.184 0 001.518 7.559c.217.962.401 1.739.538 2.273.136.535.22.828.239.824.018-.004-.03-.305-.134-.847l-.452-2.291a398.23 398.23 0 01-1.437-7.567c-.529-2.96-.966-5.647-1.275-7.594l-.37-2.306c-.091-.544-.15-.844-.169-.841z"
                                                    ></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="Group_234">
                                    <g id="Group_235">
                                        <g id="Group_236">
                                            <g id="Group_237">
                                                <g id="Group_238">
                                                    <path
                                                        id="Vector_87"
                                                        fill="#455A64"
                                                        d="M250.255 115.48c.015 0-.017-.133-.207-.216-.184-.08-.474-.103-.809-.026a1.63 1.63 0 00-.53.216.89.89 0 00-.362.589c-.088.478.136 1.046.608 1.348.458.302 1.059.333 1.479.067a.964.964 0 00.409-.551c.06-.205.063-.402.04-.575-.047-.348-.207-.603-.358-.729-.152-.126-.267-.135-.27-.123-.01.023.084.067.194.199.109.131.222.365.239.673.02.297-.06.703-.386.889-.326.196-.818.169-1.195-.08-.381-.24-.565-.7-.509-1.075a.666.666 0 01.245-.445c.138-.101.298-.168.446-.214.299-.093.56-.099.724-.061.166.038.221.128.242.114z"
                                                    ></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="Group_239">
                                    <g id="Group_240">
                                        <g id="Group_241">
                                            <g id="Group_242">
                                                <g id="Group_243">
                                                    <path
                                                        id="Vector_88"
                                                        fill="#455A64"
                                                        d="M243.463 134.432c-.021.035.481.355 1.284.88a68.462 68.462 0 013.08 2.133 67.967 67.967 0 012.948 2.313c.739.612 1.193.997 1.221.967.025-.025-.383-.461-1.092-1.114a36.145 36.145 0 00-2.912-2.388 35.876 35.876 0 00-3.142-2.076c-.833-.486-1.37-.746-1.387-.715z"
                                                    ></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="Group_244">
                                    <g id="Group_245">
                                        <g id="Group_246">
                                            <g id="Group_247">
                                                <g id="Group_248">
                                                    <path
                                                        id="Vector_89"
                                                        fill="#455A64"
                                                        d="M270.352 174.749c.001.019.165.024.459.015.295-.005.721-.038 1.245-.097a22.284 22.284 0 007.829-2.453 14.56 14.56 0 001.078-.631c.246-.161.378-.258.368-.274-.023-.038-.591.278-1.534.731a29.147 29.147 0 01-3.795 1.501c-1.523.476-2.94.767-3.973.933-1.033.167-1.68.232-1.677.275z"
                                                    ></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="Group_249">
                                    <g id="Group_250">
                                        <g id="Group_251">
                                            <g id="Group_252">
                                                <g id="Group_253">
                                                    <path
                                                        id="Vector_90"
                                                        fill="#455A64"
                                                        d="M272.152 173.615c.011.035.429-.069 1.087-.289.659-.22 1.556-.561 2.522-.996.966-.436 1.816-.88 2.417-1.227.602-.347.956-.591.938-.622-.04-.07-1.548.735-3.469 1.597-1.918.866-3.522 1.461-3.495 1.537z"
                                                    ></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g id="Group_254">
                                <g id="Group_255">
                                    <g id="Group_256">
                                        <g id="Group_257">
                                            <g id="Group_258">
                                                <g id="Group_259">
                                                    <g id="Group_260">
                                                        <g id="Group_261">
                                                            <g id="Group_262">
                                                                <g id="Group_263">
                                                                    <g id="Group_264">
                                                                        <g id="Group_265">
                                                                            <g id="Group_266">
                                                                                <g id="Group_267">
                                                                                    <g id="Group_268">
                                                                                        <g id="Group_269">
                                                                                            <g id="Group_270">
                                                                                                <path
                                                                                                    id="Vector_91"
                                                                                                    fill="#263238"
                                                                                                    d="M238.839 207.858l-.056 11.065s-11.266 3.659-11.475 5.703l21.45.879.794-17.237-10.713-.41z"
                                                                                                ></path>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_271">
                                        <g id="Group_272">
                                            <g id="Group_273">
                                                <g id="Group_274">
                                                    <g id="Group_275">
                                                        <g id="Group_276">
                                                            <g id="Group_277">
                                                                <g id="Group_278">
                                                                    <g id="Group_279">
                                                                        <g id="Group_280">
                                                                            <g id="Group_281">
                                                                                <g id="Group_282">
                                                                                    <g id="Group_283">
                                                                                        <g id="Group_284">
                                                                                            <g id="Group_285">
                                                                                                <g id="Group_286">
                                                                                                    <path
                                                                                                        id="Vector_92"
                                                                                                        fill="#E0E0E0"
                                                                                                        d="M245.454 218.265c.411.131.679.609.565 1.025-.113.415-.597.692-1.01.569-.413-.124-.752-.688-.592-1.089.159-.399.745-.693 1.116-.474"
                                                                                                    ></path>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_287">
                                        <g id="Group_288">
                                            <g id="Group_289">
                                                <g id="Group_290">
                                                    <g id="Group_291">
                                                        <g id="Group_292">
                                                            <g id="Group_293">
                                                                <g id="Group_294">
                                                                    <g id="Group_295">
                                                                        <g id="Group_296">
                                                                            <g id="Group_297">
                                                                                <g id="Group_298">
                                                                                    <g id="Group_299">
                                                                                        <g id="Group_300">
                                                                                            <g id="Group_301">
                                                                                                <path
                                                                                                    id="Vector_93"
                                                                                                    fill="#455A64"
                                                                                                    d="M248.759 225.505l.011-1.741-20.571-.191s-.971.376-.891 1.053l21.451.879z"
                                                                                                ></path>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_302">
                                        <g id="Group_303">
                                            <g id="Group_304">
                                                <g id="Group_305">
                                                    <g id="Group_306">
                                                        <g id="Group_307">
                                                            <g id="Group_308">
                                                                <g id="Group_309">
                                                                    <g id="Group_310">
                                                                        <g id="Group_311">
                                                                            <g id="Group_312">
                                                                                <g id="Group_313">
                                                                                    <g id="Group_314">
                                                                                        <g id="Group_315">
                                                                                            <g id="Group_316">
                                                                                                <g id="Group_317">
                                                                                                    <path
                                                                                                        id="Vector_94"
                                                                                                        fill="#fff"
                                                                                                        d="M238.44 218.8c-.001.105.521.174 1.022.532.511.344.758.809.856.772.098-.017-.033-.65-.648-1.072-.611-.428-1.249-.329-1.23-.232z"
                                                                                                    ></path>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_318">
                                        <g id="Group_319">
                                            <g id="Group_320">
                                                <g id="Group_321">
                                                    <g id="Group_322">
                                                        <g id="Group_323">
                                                            <g id="Group_324">
                                                                <g id="Group_325">
                                                                    <g id="Group_326">
                                                                        <g id="Group_327">
                                                                            <g id="Group_328">
                                                                                <g id="Group_329">
                                                                                    <g id="Group_330">
                                                                                        <g id="Group_331">
                                                                                            <g id="Group_332">
                                                                                                <g id="Group_333">
                                                                                                    <path
                                                                                                        id="Vector_95"
                                                                                                        fill="#fff"
                                                                                                        d="M236.152 219.638c-.028.101.418.298.756.755.351.447.421.93.526.929.097.021.195-.589-.235-1.151-.426-.564-1.041-.633-1.047-.533z"
                                                                                                    ></path>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_334">
                                        <g id="Group_335">
                                            <g id="Group_336">
                                                <g id="Group_337">
                                                    <g id="Group_338">
                                                        <g id="Group_339">
                                                            <g id="Group_340">
                                                                <g id="Group_341">
                                                                    <g id="Group_342">
                                                                        <g id="Group_343">
                                                                            <g id="Group_344">
                                                                                <g id="Group_345">
                                                                                    <g id="Group_346">
                                                                                        <g id="Group_347">
                                                                                            <g id="Group_348">
                                                                                                <g id="Group_349">
                                                                                                    <path
                                                                                                        id="Vector_96"
                                                                                                        fill="#fff"
                                                                                                        d="M234.763 222.278c.095.027.262-.491.016-1.076-.241-.586-.725-.833-.773-.747-.063.083.242.404.437.887.205.479.217.922.32.936z"
                                                                                                    ></path>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_350">
                                        <g id="Group_351">
                                            <g id="Group_352">
                                                <g id="Group_353">
                                                    <g id="Group_354">
                                                        <g id="Group_355">
                                                            <g id="Group_356">
                                                                <g id="Group_357">
                                                                    <g id="Group_358">
                                                                        <g id="Group_359">
                                                                            <g id="Group_360">
                                                                                <g id="Group_361">
                                                                                    <g id="Group_362">
                                                                                        <g id="Group_363">
                                                                                            <g id="Group_364">
                                                                                                <g id="Group_365">
                                                                                                    <path
                                                                                                        id="Vector_97"
                                                                                                        fill="#fff"
                                                                                                        d="M238.733 216.279c.04.098.526-.027 1.116.037.592.053 1.045.27 1.103.183.064-.074-.37-.48-1.067-.546-.695-.071-1.2.242-1.152.326z"
                                                                                                    ></path>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g id="Group_366">
                                <g id="Group_367">
                                    <g id="Group_368">
                                        <g id="Group_369">
                                            <g id="Group_370">
                                                <g id="Group_371">
                                                    <g id="Group_372">
                                                        <g id="Group_373">
                                                            <g id="Group_374">
                                                                <g id="Group_375">
                                                                    <g id="Group_376">
                                                                        <g id="Group_377">
                                                                            <path
                                                                                id="Vector_98"
                                                                                fill="#263238"
                                                                                d="M289.498 206.697l4.109 12.741 6.156 9.14a1.372 1.372 0 01-1.706 2.015c-2.573-1.179-12.018-5.564-12.266-6.513-.288-1.1-5.227-13.152-5.227-13.152l8.934-4.231z"
                                                                            ></path>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_378">
                                        <g id="Group_379">
                                            <g id="Group_380">
                                                <g id="Group_381">
                                                    <g id="Group_382">
                                                        <g id="Group_383">
                                                            <g id="Group_384">
                                                                <g id="Group_385">
                                                                    <g id="Group_386">
                                                                        <g id="Group_387">
                                                                            <path
                                                                                id="Vector_99"
                                                                                fill="#455A64"
                                                                                d="M299.312 230.531l-13.818-7.304.187.536c.161.463.479.855.902 1.103 1.131.664 4.294 2.429 11.437 5.741.415.192.902.164 1.292-.076z"
                                                                            ></path>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_388" opacity="0.6">
                                        <g id="Group_389">
                                            <g id="Group_390">
                                                <g id="Group_391">
                                                    <g id="Group_392">
                                                        <g id="Group_393">
                                                            <g id="Group_394">
                                                                <g id="Group_395">
                                                                    <g id="Group_396">
                                                                        <g id="Group_397">
                                                                            <g id="Group_398">
                                                                                <path
                                                                                    id="Vector_100"
                                                                                    fill="#fff"
                                                                                    d="M286.67 218.245c.393.109.663.553.571.949-.093.397-.541.676-.936.574-.395-.102-.736-.624-.599-1.008s.681-.683 1.039-.489"
                                                                                ></path>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_399">
                                        <g id="Group_400">
                                            <g id="Group_401">
                                                <g id="Group_402">
                                                    <g id="Group_403">
                                                        <g id="Group_404">
                                                            <g id="Group_405">
                                                                <g id="Group_406">
                                                                    <g id="Group_407">
                                                                        <g id="Group_408">
                                                                            <g id="Group_409">
                                                                                <path
                                                                                    id="Vector_101"
                                                                                    fill="#fff"
                                                                                    d="M294.5 226.013c.083.01.138-.752.766-1.37.61-.636 1.37-.701 1.359-.784 0-.035-.194-.078-.505-.017-.308.059-.721.25-1.063.595-.341.347-.526.762-.58 1.072-.057.311-.012.504.023.504z"
                                                                                ></path>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_410">
                                        <g id="Group_411">
                                            <g id="Group_412">
                                                <g id="Group_413">
                                                    <g id="Group_414">
                                                        <g id="Group_415">
                                                            <g id="Group_416">
                                                                <g id="Group_417">
                                                                    <g id="Group_418">
                                                                        <g id="Group_419">
                                                                            <g id="Group_420">
                                                                                <path
                                                                                    id="Vector_102"
                                                                                    fill="#fff"
                                                                                    d="M295.99 227.392c.079.034.342-.518.93-.933.58-.428 1.187-.503 1.18-.589.014-.077-.688-.134-1.352.351-.668.476-.836 1.16-.758 1.171z"
                                                                                ></path>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_421">
                                        <g id="Group_422">
                                            <g id="Group_423">
                                                <g id="Group_424">
                                                    <g id="Group_425">
                                                        <g id="Group_426">
                                                            <g id="Group_427">
                                                                <g id="Group_428">
                                                                    <g id="Group_429">
                                                                        <g id="Group_430">
                                                                            <g id="Group_431">
                                                                                <path
                                                                                    id="Vector_103"
                                                                                    fill="#fff"
                                                                                    d="M292.069 224.426c.08.039.48-.812 1.339-1.538.848-.738 1.75-1.004 1.723-1.09-.002-.074-1.003.077-1.915.867-.919.781-1.221 1.746-1.147 1.761z"
                                                                                ></path>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_432">
                                        <g id="Group_433">
                                            <g id="Group_434">
                                                <g id="Group_435">
                                                    <g id="Group_436">
                                                        <g id="Group_437">
                                                            <g id="Group_438">
                                                                <g id="Group_439">
                                                                    <g id="Group_440">
                                                                        <g id="Group_441">
                                                                            <g id="Group_442">
                                                                                <path
                                                                                    id="Vector_104"
                                                                                    fill="#fff"
                                                                                    d="M290.496 220.657c.06.064.657-.47 1.554-.819.893-.362 1.694-.382 1.694-.47.014-.074-.841-.19-1.802.196-.964.378-1.507 1.048-1.446 1.093z"
                                                                                ></path>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_443">
                                        <g id="Group_444">
                                            <g id="Group_445">
                                                <g id="Group_446">
                                                    <g id="Group_447">
                                                        <g id="Group_448">
                                                            <g id="Group_449">
                                                                <g id="Group_450">
                                                                    <g id="Group_451">
                                                                        <g id="Group_452">
                                                                            <g id="Group_453">
                                                                                <path
                                                                                    id="Vector_105"
                                                                                    fill="#fff"
                                                                                    d="M289.195 216.948c.064.059.62-.607 1.595-.897.969-.312 1.803-.077 1.823-.163.012-.034-.182-.144-.532-.211a2.931 2.931 0 00-1.378.093c-.524.163-.941.45-1.189.702-.251.254-.348.455-.319.476z"
                                                                                ></path>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_454">
                                        <g id="Group_455">
                                            <g id="Group_456">
                                                <g id="Group_457">
                                                    <g id="Group_458">
                                                        <g id="Group_459">
                                                            <g id="Group_460">
                                                                <g id="Group_461">
                                                                    <g id="Group_462">
                                                                        <g id="Group_463">
                                                                            <g id="Group_464">
                                                                                <path
                                                                                    id="Vector_106"
                                                                                    fill="#fff"
                                                                                    d="M290.522 215.352c.016-.005-.014-.185-.149-.484a3.524 3.524 0 00-.816-1.12c-.218-.188-.466-.44-.876-.484a.553.553 0 00-.547.32c-.092.215-.067.439-.001.641.292.798 1.252 1.335 2.195 1.136a2.25 2.25 0 001.699-1.837c.016-.207-.006-.446-.178-.625-.172-.181-.458-.178-.628-.084-.356.187-.518.486-.683.723a3.95 3.95 0 00-.521 1.282c-.066.321-.059.503-.042.505.056.017.103-.733.736-1.673.165-.223.349-.496.61-.615.127-.059.259-.047.343.044.084.085.113.253.093.417-.106.673-.693 1.406-1.492 1.576-.793.176-1.635-.298-1.876-.938-.056-.155-.07-.326-.019-.447a.298.298 0 01.299-.181c.281.014.538.222.75.396.856.743 1.052 1.481 1.103 1.448z"
                                                                                ></path>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g id="Group_465">
                                <g id="Group_466">
                                    <g id="Group_467">
                                        <path
                                            id="Vector_107"
                                            fill="#AE7461"
                                            d="M222.155 80.944l5.116 5.283.208 4.602-5.938 1.365h-.302l-.384-2.97-3.422-4.549 4.722-3.731z"
                                        ></path>
                                    </g>
                                </g>
                                <g id="Group_468">
                                    <g id="Group_469">
                                        <g id="Group_470">
                                            <g id="Group_471">
                                                <g id="Group_472">
                                                    <g id="Group_473">
                                                        <g id="Group_474">
                                                            <g id="Group_475">
                                                                <g id="Group_476">
                                                                    <path
                                                                        id="Vector_108"
                                                                        fill="#455A64"
                                                                        d="M216.682 77.12c-5.689 6.073-13.4 9.567-21.716 9.84-8.315.271-16.239-2.712-22.312-8.399-12.535-11.742-13.181-31.494-1.44-44.028 5.688-6.073 13.4-9.567 21.716-9.839 8.316-.271 16.24 2.711 22.311 8.399a30.973 30.973 0 019.243 16.643c1.885 9.516-.679 19.78-7.802 27.385zm-42.602-.082c5.666 5.307 13.059 8.09 20.818 7.836 7.758-.254 14.954-3.514 20.261-9.18 5.307-5.665 8.09-13.059 7.836-20.817-.253-7.76-3.514-14.955-9.18-20.262s-13.059-8.09-20.818-7.836c-7.759.254-14.955 3.514-20.261 9.18-6.647 7.096-9.039 16.67-7.28 25.55a28.912 28.912 0 008.624 15.53z"
                                                                    ></path>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_477">
                                        <g id="Group_478">
                                            <g id="Group_479">
                                                <g id="Group_480">
                                                    <g id="Group_481">
                                                        <g id="Group_482">
                                                            <g id="Group_483">
                                                                <g id="Group_484">
                                                                    <path
                                                                        id="Vector_109"
                                                                        fill="#263238"
                                                                        d="M216.682 77.12c-5.689 6.073-13.4 9.567-21.716 9.84-8.315.271-16.239-2.712-22.312-8.399-12.535-11.742-13.181-31.494-1.44-44.028 5.688-6.073 13.4-9.567 21.716-9.839 8.316-.271 16.24 2.711 22.311 8.399a30.973 30.973 0 019.243 16.643c1.885 9.516-.679 19.78-7.802 27.385zm-42.602-.082c5.666 5.307 13.059 8.09 20.818 7.836 7.758-.254 14.954-3.514 20.261-9.18 5.307-5.665 8.09-13.059 7.836-20.817-.253-7.76-3.514-14.955-9.18-20.262s-13.059-8.09-20.818-7.836c-7.759.254-14.955 3.514-20.261 9.18-6.647 7.096-9.039 16.67-7.28 25.55a28.912 28.912 0 008.624 15.53z"
                                                                    ></path>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_485">
                                        <g id="Group_486">
                                            <g id="Group_487">
                                                <g id="Group_488">
                                                    <g id="Group_489">
                                                        <g id="Group_490">
                                                            <g id="Group_491">
                                                                <g id="Group_492">
                                                                    <path
                                                                        id="Vector_110"
                                                                        fill="#455A64"
                                                                        d="M217.447 75.621c-5.688 6.073-13.4 9.567-21.715 9.839-8.316.272-16.24-2.711-22.313-8.398-12.535-11.743-13.181-31.494-1.44-44.029 5.688-6.072 13.4-9.566 21.716-9.838 8.316-.272 16.239 2.71 22.312 8.398a30.978 30.978 0 019.242 16.643c1.885 9.517-.679 19.78-7.802 27.385zm-42.602-.082c5.666 5.307 13.059 8.09 20.818 7.836 7.759-.254 14.954-3.514 20.261-9.18 5.307-5.665 8.09-13.059 7.836-20.817-.253-7.76-3.514-14.955-9.18-20.262s-13.059-8.09-20.818-7.836c-7.759.254-14.954 3.514-20.262 9.18-6.646 7.096-9.038 16.67-7.279 25.55a28.916 28.916 0 008.624 15.53z"
                                                                    ></path>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="Group_493">
                                    <g id="Group_494">
                                        <g id="Group_495">
                                            <g id="Group_496">
                                                <g id="Group_497">
                                                    <g id="Group_498">
                                                        <g id="Group_499">
                                                            <g id="Group_500">
                                                                <g id="Group_501">
                                                                    <g id="Group_502">
                                                                        <g id="Group_503">
                                                                            <path
                                                                                id="Vector_111"
                                                                                fill="#455A64"
                                                                                d="M252.028 114.943l.991-.959c.884-.856.389-2.811-.539-3.761l-34.799-35.968-4.653 4.502 34.747 35.915c1.173 1.15 3.115 1.372 4.253.271z"
                                                                            ></path>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="Group_504">
                                    <g id="Group_505">
                                        <path
                                            id="Vector_112"
                                            fill="#AE7461"
                                            d="M219.956 78.59c-.164-.156-.486-.253-.703-.187-.218.065-.393.223-.553.385a6.312 6.312 0 00-1.792 4.363c-.002.692.182 1.485.797 1.8l.699-.405c.217-.28.864-.748.95-1.091.361-1.445-.158-1.385.749-3.891.089-.246.186-.657-.147-.974z"
                                        ></path>
                                    </g>
                                    <g id="Group_506">
                                        <g id="Group_507">
                                            <path
                                                id="Vector_113"
                                                fill="#AE7461"
                                                d="M219.791 80.639c.196-.342.416-.675.692-.912.277-.238.62-.37.921-.275.482.152.717.846.662 1.492-.055.646-.33 1.254-.599 1.837l-1.72 3.727-.333-.245c-.237.232-.583.112-.742-.164-.159-.277-.175-.655-.164-1.015.049-1.551.525-3.122 1.283-4.445z"
                                            ></path>
                                        </g>
                                    </g>
                                    <g id="Group_508">
                                        <g id="Group_509">
                                            <path
                                                id="Vector_114"
                                                fill="#AE7461"
                                                d="M220.635 84.356c.395-.874 1.056-1.605 1.721-2.306.231-.245.477-.496.799-.607.322-.111.74-.031.898.264.153.282.018.625-.121.914a42.652 42.652 0 01-2.96 5.196l-1.028-1.035c-.466-.84.295-1.552.691-2.426z"
                                            ></path>
                                        </g>
                                    </g>
                                </g>
                                <g id="Group_510">
                                    <g id="Group_511">
                                        <g id="Group_512">
                                            <g id="Group_513">
                                                <g id="Group_514">
                                                    <path
                                                        id="Vector_115"
                                                        fill="#AE7461"
                                                        d="M248.395 95.847l-.943-.2-4.938-4.208-2.702-2.27c-.226-.19-.5-.316-.791-.365l-2.675-.446s-.418 1.164.801 1.835l1.465.758 1.342 1.586-.172.343s-8.789 1.364-9.338 2.034c-.548.67 0 1.28.244 1.402.244.122.671.061.671.061s-.427 1.036.853 1.89c0 0-.975 1.28.732 1.889 0 0 .488.976 1.463 1.281.975.304 6.217.914 6.766.914l6.301.009 1.265.207-.344-6.72z"
                                                    ></path>
                                                </g>
                                            </g>
                                            <g id="Group_515">
                                                <g id="Group_516">
                                                    <g id="Group_517">
                                                        <path
                                                            id="Vector_116"
                                                            fill="#6F4439"
                                                            d="M231.157 96.39c-.007.072 1.083.223 2.431.131 1.343-.098 2.411-.288 2.405-.357-.007-.083-1.096-.016-2.423.082-1.326.09-2.407.064-2.413.145z"
                                                        ></path>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="Group_518">
                                                <g id="Group_519">
                                                    <g id="Group_520">
                                                        <path
                                                            id="Vector_117"
                                                            fill="#6F4439"
                                                            d="M232.212 98.266c.001.039.229.07.595.119.367.045.873.117 1.445.152.577.037 1.092-.016 1.458-.03.37-.025.591-.02.6-.055.011-.04-.222-.116-.598-.141-.376-.036-.888-.013-1.444-.05-.56-.034-1.074-.075-1.449-.072-.375.001-.607.04-.607.077z"
                                                        ></path>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="Group_521">
                                                <g id="Group_522">
                                                    <g id="Group_523">
                                                        <path
                                                            id="Vector_118"
                                                            fill="#6F4439"
                                                            d="M232.769 100.117c-.006.076 1.056.218 2.37.318.659.041 1.238.093 1.622.251.387.146.564.354.598.322.027-.013-.107-.287-.517-.5-.403-.224-1.03-.309-1.683-.349-1.314-.099-2.385-.118-2.39-.042z"
                                                        ></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <g id="Group_524">
                                            <g id="Group_525">
                                                <g id="Group_526">
                                                    <g id="Group_527">
                                                        <g id="Group_528">
                                                            <path
                                                                id="Vector_177987819"
                                                                fill="#FFEAAD"
                                                                d="M261.529 68.696s3.715.641 6.531 6.176c1.557 3.06 8.833 21.46 9.265 23.074 1.846 6.903-1.727 11.352-5.166 11.654-3.786.333-23.366-5.984-23.366-5.984l-.432-8.366 17.86 2.712-8.581-16.656s-.329-11.093 3.889-12.61z"
                                                            ></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="Group_529">
                                                <g id="Group_530">
                                                    <g id="Group_531">
                                                        <path
                                                            id="Vector_120"
                                                            fill="#263238"
                                                            d="M272.226 102.534c.027-.008-.092-.465-.48-1.115-.383-.649-1.07-1.461-2.01-2.134-.943-.67-1.934-1.056-2.672-1.206-.741-.157-1.211-.12-1.211-.092-.003.048.457.081 1.163.28.706.195 1.647.595 2.559 1.242.909.652 1.593 1.412 2.006 2.017.419.602.601 1.027.645 1.008z"
                                                        ></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_532">
                                        <g id="Group_533">
                                            <path
                                                id="Vector_121"
                                                fill="#263238"
                                                d="M267.254 108.884c-.001.004-.032-.001-.092-.013l-.268-.057c-.238-.049-.58-.151-1.029-.273a160.95 160.95 0 01-3.827-1.178 856.236 856.236 0 01-13.327-4.408l-.082-.028-.004-.087-.408-7.768-.009-.171.169.027c1.409.228 2.889.468 4.394.71 4.843.792 9.429 1.54 13.469 2.199l-.132.181-5.932-11.613-1.663-3.302-.431-.878c-.096-.2-.142-.306-.136-.309.006-.003.065.096.172.29l.466.861 1.727 3.27 6.021 11.566.116.222-.248-.04-13.473-2.171-4.394-.714.161-.144.388 7.77-.086-.116 13.291 4.503 3.803 1.247c.444.134.783.25 1.015.311l.262.078c.058.02.088.031.087.035z"
                                            ></path>
                                        </g>
                                    </g>
                                    <g id="Group_534">
                                        <g id="Group_535">
                                            <path
                                                id="Vector_122"
                                                fill="#263238"
                                                d="M262.326 69.034c.026.044-.506.35-1.246.988a10.754 10.754 0 00-2.373 2.95 10.756 10.756 0 00-1.184 3.597c-.132.969-.1 1.58-.151 1.583-.019.001-.04-.15-.062-.428a6.79 6.79 0 01.017-1.177 9.327 9.327 0 01.321-1.72 10.69 10.69 0 01.814-1.988c.368-.668.795-1.26 1.224-1.763a9.19 9.19 0 011.268-1.206c.393-.306.731-.528.978-.656.245-.132.384-.196.394-.18z"
                                            ></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                    <g id="Character_2">
                        <g id="Group_536">
                            <g id="Group_537">
                                <g id="Group_538">
                                    <path
                                        id="Vector_123"
                                        fill="#FFBF9D"
                                        d="M93.867 80.302s-3.948 21.956-3.757 24.009c.19 2.053 1.24 5.319 3.621 5.879 2.382.559 4.573 0 4.573 0l-2.858-26.968-1.58-2.92z"
                                    ></path>
                                </g>
                            </g>
                            <g id="Group_539">
                                <g id="Group_540">
                                    <g id="Group_541">
                                        <g id="Group_542">
                                            <g id="Group_543">
                                                <g id="Group_544">
                                                    <g id="Group_545">
                                                        <g id="Group_546">
                                                            <g id="Group_547">
                                                                <g id="Group_548">
                                                                    <g id="Group_549">
                                                                        <g id="Group_550">
                                                                            <path
                                                                                id="Vector_124"
                                                                                fill="#FFBF9D"
                                                                                d="M105.392 61.547l-.277-22.688c-.252-5.642 2.82-10.492 8.467-10.588l.522.022a10.096 10.096 0 019.673 9.69c.192 4.89.341 10.297.2 12.912-.29 5.41-5.74 6.03-5.74 6.03-.012 0-.034 3.294-.03 4.884l-4.358 6.046-.001.323-8.456-6.631z"
                                                                            ></path>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_551">
                                        <g id="Group_552">
                                            <g id="Group_553">
                                                <g id="Group_554">
                                                    <g id="Group_555">
                                                        <g id="Group_556">
                                                            <g id="Group_557">
                                                                <g id="Group_558">
                                                                    <g id="Group_559">
                                                                        <g id="Group_560">
                                                                            <g id="Group_561">
                                                                                <path
                                                                                    id="Vector_125"
                                                                                    fill="#FF9A6C"
                                                                                    d="M118.237 56.924s-3.421.06-6.963-2.376c0 0 1.528 4.141 6.95 3.876l.013-1.5z"
                                                                                ></path>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_562">
                                        <g id="Group_563">
                                            <g id="Group_564">
                                                <g id="Group_565">
                                                    <g id="Group_566">
                                                        <g id="Group_567">
                                                            <g id="Group_568">
                                                                <g id="Group_569">
                                                                    <g id="Group_570">
                                                                        <g id="Group_571">
                                                                            <g id="Group_572">
                                                                                <g id="Group_573">
                                                                                    <g id="Group_574">
                                                                                        <g id="Group_575">
                                                                                            <g id="Group_576">
                                                                                                <g id="Group_577">
                                                                                                    <g id="Group_578">
                                                                                                        <path
                                                                                                            id="Vector_126"
                                                                                                            fill="#263238"
                                                                                                            d="M122.053 42.653c-.002.41-.351.744-.778.75-.426.005-.77-.321-.768-.73.002-.41.35-.745.777-.75.427-.006.772.321.769.73z"
                                                                                                        ></path>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_579">
                                        <g id="Group_580">
                                            <g id="Group_581">
                                                <g id="Group_582">
                                                    <g id="Group_583">
                                                        <g id="Group_584">
                                                            <g id="Group_585">
                                                                <g id="Group_586">
                                                                    <g id="Group_587">
                                                                        <g id="Group_588">
                                                                            <g id="Group_589">
                                                                                <g id="Group_590">
                                                                                    <g id="Group_591">
                                                                                        <g id="Group_592">
                                                                                            <g id="Group_593">
                                                                                                <g id="Group_594">
                                                                                                    <g id="Group_595">
                                                                                                        <g id="Group_596">
                                                                                                            <path
                                                                                                                id="Vector_127"
                                                                                                                fill="#263238"
                                                                                                                d="M122.527 40.326c-.1.097-.668-.36-1.5-.393-.83-.044-1.459.359-1.545.253-.042-.046.063-.225.337-.407.27-.182.724-.351 1.242-.327.518.025.946.233 1.188.439.248.206.327.392.278.435z"
                                                                                                            ></path>
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_597">
                                        <g id="Group_598">
                                            <g id="Group_599">
                                                <g id="Group_600">
                                                    <g id="Group_601">
                                                        <g id="Group_602">
                                                            <g id="Group_603">
                                                                <g id="Group_604">
                                                                    <g id="Group_605">
                                                                        <g id="Group_606">
                                                                            <g id="Group_607">
                                                                                <g id="Group_608">
                                                                                    <g id="Group_609">
                                                                                        <g id="Group_610">
                                                                                            <g id="Group_611">
                                                                                                <g id="Group_612">
                                                                                                    <g id="Group_613">
                                                                                                        <path
                                                                                                            id="Vector_128"
                                                                                                            fill="#263238"
                                                                                                            d="M114.622 42.653c-.003.41-.351.744-.778.75-.426.005-.771-.322-.768-.73.002-.41.35-.745.777-.75.427-.006.771.321.769.73z"
                                                                                                        ></path>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_614">
                                        <g id="Group_615">
                                            <g id="Group_616">
                                                <g id="Group_617">
                                                    <g id="Group_618">
                                                        <g id="Group_619">
                                                            <g id="Group_620">
                                                                <g id="Group_621">
                                                                    <g id="Group_622">
                                                                        <g id="Group_623">
                                                                            <g id="Group_624">
                                                                                <g id="Group_625">
                                                                                    <g id="Group_626">
                                                                                        <g id="Group_627">
                                                                                            <g id="Group_628">
                                                                                                <g id="Group_629">
                                                                                                    <g id="Group_630">
                                                                                                        <g id="Group_631">
                                                                                                            <path
                                                                                                                id="Vector_129"
                                                                                                                fill="#263238"
                                                                                                                d="M114.363 40.182c-.1.097-.669-.36-1.5-.393-.831-.044-1.46.359-1.545.253-.043-.047.061-.224.336-.407.27-.183.724-.351 1.242-.328.518.025.946.233 1.189.439.248.207.327.393.278.436z"
                                                                                                            ></path>
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_632">
                                        <g id="Group_633">
                                            <g id="Group_634">
                                                <g id="Group_635">
                                                    <g id="Group_636">
                                                        <g id="Group_637">
                                                            <g id="Group_638">
                                                                <g id="Group_639">
                                                                    <g id="Group_640">
                                                                        <g id="Group_641">
                                                                            <g id="Group_642">
                                                                                <g id="Group_643">
                                                                                    <g id="Group_644">
                                                                                        <g id="Group_645">
                                                                                            <g id="Group_646">
                                                                                                <g id="Group_647">
                                                                                                    <g id="Group_648">
                                                                                                        <path
                                                                                                            id="Vector_130"
                                                                                                            fill="#263238"
                                                                                                            d="M117.084 46.698c-.002-.047.519-.115 1.361-.188.214-.013.416-.047.458-.19.057-.151-.023-.384-.116-.636l-.561-1.644c-.777-2.34-1.33-4.259-1.236-4.29.094-.03.801 1.841 1.579 4.18l.534 1.653c.07.245.197.524.076.838a.533.533 0 01-.362.296c-.138.036-.258.033-.363.035-.846.012-1.369-.006-1.37-.054z"
                                                                                                        ></path>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_649">
                                        <g id="Group_650">
                                            <g id="Group_651">
                                                <g id="Group_652">
                                                    <g id="Group_653">
                                                        <g id="Group_654">
                                                            <g id="Group_655">
                                                                <g id="Group_656">
                                                                    <g id="Group_657">
                                                                        <g id="Group_658">
                                                                            <g id="Group_659">
                                                                                <g id="Group_660">
                                                                                    <g id="Group_661">
                                                                                        <path
                                                                                            id="Vector_131"
                                                                                            fill="#263238"
                                                                                            d="M114.74 37.59c-.121.208-.92-.048-1.902-.11-.981-.081-1.807.056-1.898-.167-.039-.106.135-.29.488-.448.35-.158.883-.27 1.472-.228.588.043 1.099.233 1.423.44.327.206.472.414.417.514z"
                                                                                        ></path>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_662">
                                        <g id="Group_663">
                                            <g id="Group_664">
                                                <g id="Group_665">
                                                    <g id="Group_666">
                                                        <g id="Group_667">
                                                            <g id="Group_668">
                                                                <g id="Group_669">
                                                                    <g id="Group_670">
                                                                        <g id="Group_671">
                                                                            <g id="Group_672">
                                                                                <g id="Group_673">
                                                                                    <g id="Group_674">
                                                                                        <path
                                                                                            id="Vector_132"
                                                                                            fill="#263238"
                                                                                            d="M122.666 37.881c-.178.184-.851-.03-1.666-.06-.814-.055-1.508.087-1.662-.115-.067-.098.047-.284.353-.455.302-.17.803-.303 1.363-.274.56.03 1.04.214 1.319.415.282.202.372.399.293.489z"
                                                                                        ></path>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_675">
                                        <g id="Group_676">
                                            <g id="Group_677">
                                                <g id="Group_678">
                                                    <g id="Group_679">
                                                        <g id="Group_680">
                                                            <g id="Group_681">
                                                                <g id="Group_682">
                                                                    <g id="Group_683">
                                                                        <path
                                                                            id="Vector_133"
                                                                            fill="#FFBF9D"
                                                                            d="M105.391 42.886c-.09-.044-3.674-1.254-3.709 2.452-.034 3.706 3.694 2.977 3.701 2.87.008-.106.008-5.322.008-5.322z"
                                                                        ></path>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_684">
                                        <g id="Group_685">
                                            <g id="Group_686">
                                                <g id="Group_687">
                                                    <g id="Group_688">
                                                        <g id="Group_689">
                                                            <g id="Group_690">
                                                                <g id="Group_691">
                                                                    <g id="Group_692">
                                                                        <g id="Group_693">
                                                                            <path
                                                                                id="Vector_134"
                                                                                fill="#FF9A6C"
                                                                                d="M104.265 46.794c-.017-.012-.065.045-.175.094a.647.647 0 01-.483.005c-.391-.147-.714-.772-.726-1.44a2.122 2.122 0 01.189-.923c.116-.27.293-.458.491-.49a.329.329 0 01.379.18c.049.104.025.178.044.184.01.01.08-.062.052-.214a.416.416 0 00-.144-.234.487.487 0 00-.356-.098c-.286.017-.546.278-.68.568-.15.292-.241.652-.233 1.031.016.747.392 1.45.926 1.61a.69.69 0 00.592-.093c.116-.091.136-.174.124-.18z"
                                                                            ></path>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_694">
                                        <g id="Group_695">
                                            <g id="Group_696">
                                                <g id="Group_697">
                                                    <g id="Group_698">
                                                        <g id="Group_699">
                                                            <g id="Group_700">
                                                                <g id="Group_701">
                                                                    <g id="Group_702">
                                                                        <g id="Group_703">
                                                                            <g id="Group_704">
                                                                                <path
                                                                                    id="Vector_135"
                                                                                    fill="#263238"
                                                                                    d="M114.47 47.214c.128-.008.133.843.865 1.444.73.603 1.636.503 1.644.622.012.054-.201.166-.585.178a2.116 2.116 0 01-1.371-.47c-.446-.37-.64-.863-.67-1.212-.035-.356.059-.567.117-.562z"
                                                                                ></path>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="Group_705">
                                    <g id="Group_706">
                                        <g id="Group_707">
                                            <g id="Group_708">
                                                <g id="Group_709">
                                                    <g id="Group_710">
                                                        <g id="Group_711">
                                                            <g id="Group_712">
                                                                <path
                                                                    id="Vector_136"
                                                                    fill="#263238"
                                                                    d="M131.258 33.106a.878.878 0 00-.407-.517c-.422-.256-.911-.228-1.384-.24a7.798 7.798 0 01-3.381-.891 3.874 3.874 0 001.005-.689c.606-.59.961-1.502.695-2.306-.05-.15-.135-.309-.287-.357-.24-.077-.453.157-.623.343-.559.61-1.072.87-1.895.777-.51-.058-1.005-.178-1.404-.433l-.046-.052a45.549 45.549 0 00-.56-.579l-.001-.002.001.002c-1.379-1.4-2.567-2.371-3.796-2.882-1.802-.749-4.825-1.079-6.717-.605-1.892.474-3.604 1.985-3.889 3.915-1.048-.907-2.636-.917-3.931-.414l-.032-.014c-1.441.495-2.273 2.002-2.714 3.46-.024.08-.045.161-.068.241a5.684 5.684 0 01-1.953 1.865c-.139.079-.3.176-.318.335-.019.158.114.29.242.385.341.25.74.417 1.156.496a6.462 6.462 0 01-.488 1.003c-.165.272-.361.599-.223.886.108.222.387.316.631.283.244-.033.461-.165.671-.295l-.493 1.144c.519.097 1.056.06 1.562-.086.29 1.372.49 3.91.832 4.84.984.03 1.224-.075 1.718.095.639.22.382 1.354 1.221 1.04.427-.159 1.098-1.763 1.245-2.194a21.16 21.16 0 001.018-8.948 6.794 6.794 0 012.118-1.492l-.065.057c1.522-.51 3.242-1.008 4.672-.28 1.109.564 1.751 1.73 2.574 2.662 1.561 1.768 3.339 2.723 5.694 2.85.194 1.64.226 4.316.289 5.844l1.339-3.041v.012c.107-.178.196-.357.283-.537l.029-.054v-.008c.137-.288.267-.578.44-.863a.178.178 0 00.014.013c.819.739 2.051-.363 1.408-1.26a2.138 2.138 0 00-.455-.469c.374.36.936.536 1.433.39.498-.147.888-.644.851-1.162-.026-.353-.274-.671-.591-.814a3.404 3.404 0 001.939-.404c.386-.214.765-.568.641-1.05z"
                                                                ></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_713">
                                        <g id="Group_714">
                                            <g id="Group_715">
                                                <g id="Group_716">
                                                    <g id="Group_717">
                                                        <g id="Group_718">
                                                            <g id="Group_719">
                                                                <path
                                                                    id="Vector_137"
                                                                    fill="#455A64"
                                                                    d="M110.271 29.232c.011.014-.229.14-.55.492-.318.349-.677.958-.853 1.791-.09.414-.149.878-.154 1.377-.008.5.026 1.035.058 1.6.03.563.063 1.16.02 1.779a6.24 6.24 0 01-.133.937c-.072.308-.157.642-.393.906a.817.817 0 01-.768.248 1.216 1.216 0 01-.685-.397c-.344-.411-.425-.928-.507-1.385l.114.015c-.191.407-.387.809-.673 1.129-.282.322-.67.532-1.062.562a1.04 1.04 0 01-.558-.104.903.903 0 01-.376-.379.926.926 0 01-.109-.482.67.67 0 01.179-.42l.057.053a3.51 3.51 0 01-1.175.86c-.155.07-.279.111-.363.137a.588.588 0 01-.13.03.682.682 0 01.123-.05 4.26 4.26 0 00.352-.154 3.596 3.596 0 001.135-.874l.057.052c-.184.191-.199.53-.045.805a.81.81 0 00.34.336.944.944 0 00.506.088c.36-.032.722-.229.986-.535.27-.306.46-.697.648-1.105l.08-.173.034.188c.081.456.168.953.482 1.324.156.178.376.31.612.354a.691.691 0 00.654-.21c.206-.227.293-.544.363-.848.069-.306.11-.614.132-.918.044-.608.013-1.2-.014-1.764-.028-.564-.059-1.101-.046-1.607.01-.506.075-.976.172-1.395.189-.844.569-1.461.903-1.805.339-.347.587-.458.587-.458z"
                                                                ></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_720">
                                        <g id="Group_721">
                                            <g id="Group_722">
                                                <g id="Group_723">
                                                    <g id="Group_724">
                                                        <g id="Group_725">
                                                            <g id="Group_726">
                                                                <path
                                                                    id="Vector_138"
                                                                    fill="#455A64"
                                                                    d="M126.991 34.7a.19.19 0 01-.044.036l-.136.097c-.06.044-.136.095-.232.145-.094.053-.202.117-.332.17-.506.234-1.317.462-2.326.391a6.147 6.147 0 01-3.26-1.245c-1.075-.792-2.013-1.95-2.776-3.274-.387-.662-.701-1.338-1.049-1.965-.345-.626-.727-1.205-1.193-1.665-.46-.461-.997-.805-1.552-.966a2.654 2.654 0 00-.823-.105 2.952 2.952 0 00-.757.137 3.14 3.14 0 00-1.76 1.454c-.252.496-.269.816-.289.81-.002 0 0-.019.005-.056l.028-.166c.028-.145.093-.355.212-.61.131-.25.325-.54.617-.817.291-.273.677-.539 1.159-.7.241-.08.503-.135.78-.15.277-.006.57.015.857.101.577.163 1.132.514 1.605.984.479.468.871 1.058 1.22 1.688.351.63.665 1.306 1.049 1.961.757 1.314 1.681 2.46 2.736 3.244a6.115 6.115 0 003.196 1.25 4.883 4.883 0 002.3-.346c.512-.22.756-.419.765-.403z"
                                                                ></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_727">
                                        <g id="Group_728">
                                            <g id="Group_729">
                                                <g id="Group_730">
                                                    <g id="Group_731">
                                                        <g id="Group_732">
                                                            <g id="Group_733">
                                                                <path
                                                                    id="Vector_139"
                                                                    fill="#455A64"
                                                                    d="M127.512 32.322c.005.008-.065.055-.196.133-.129.081-.327.181-.579.286a5.586 5.586 0 01-.916.29c-.354.075-.749.13-1.168.14a6.396 6.396 0 01-1.173-.093 5.61 5.61 0 01-.926-.25 4.071 4.071 0 01-.591-.263c-.134-.073-.205-.117-.202-.124.011-.022.309.136.822.301a6.31 6.31 0 003.214.174 6.41 6.41 0 00.908-.258c.505-.187.796-.357.807-.336z"
                                                                ></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g id="Group_734">
                                <g id="Group_735">
                                    <g id="Group_736">
                                        <g id="Group_737">
                                            <g id="Group_738">
                                                <g id="Group_739">
                                                    <g id="Group_740">
                                                        <g id="Group_741">
                                                            <g id="Group_742">
                                                                <g id="Group_743">
                                                                    <g id="Group_744">
                                                                        <g id="Group_745">
                                                                            <g id="Group_746">
                                                                                <g id="Group_747">
                                                                                    <g id="Group_748">
                                                                                        <g id="Group_749">
                                                                                            <g id="Group_750">
                                                                                                <g id="Group_751">
                                                                                                    <path
                                                                                                        id="Vector_140"
                                                                                                        fill="#455A64"
                                                                                                        d="M113.325 204.54l-.536 12.253s12.274 4.659 12.396 6.933l-23.794-.185.051-19.126 11.883.125z"
                                                                                                    ></path>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="Group_752">
                                                    <g id="Group_753">
                                                        <g id="Group_754">
                                                            <g id="Group_755">
                                                                <g id="Group_756">
                                                                    <g id="Group_757">
                                                                        <g id="Group_758">
                                                                            <g id="Group_759">
                                                                                <g id="Group_760">
                                                                                    <g id="Group_761">
                                                                                        <g id="Group_762">
                                                                                            <g id="Group_763">
                                                                                                <g id="Group_764">
                                                                                                    <g id="Group_765">
                                                                                                        <path
                                                                                                            id="Vector_141"
                                                                                                            fill="#E0E0E0"
                                                                                                            d="M105.439 215.705c-.461.122-.783.637-.681 1.103.103.467.625.799 1.089.684.463-.114.869-.721.714-1.172-.155-.451-.788-.808-1.21-.586"
                                                                                                        ></path>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="Group_766" opacity="0.6">
                                                    <g id="Group_767">
                                                        <g id="Group_768">
                                                            <g id="Group_769">
                                                                <g id="Group_770">
                                                                    <g id="Group_771">
                                                                        <g id="Group_772">
                                                                            <g id="Group_773">
                                                                                <g id="Group_774">
                                                                                    <g id="Group_775">
                                                                                        <g id="Group_776">
                                                                                            <g id="Group_777">
                                                                                                <g id="Group_778">
                                                                                                    <path
                                                                                                        id="Vector_142"
                                                                                                        fill="#fff"
                                                                                                        d="M101.391 223.541l.08-1.928 22.783.899s1.056.469.93 1.214l-23.793-.185z"
                                                                                                    ></path>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="Group_779">
                                                    <g id="Group_780">
                                                        <g id="Group_781">
                                                            <g id="Group_782">
                                                                <g id="Group_783">
                                                                    <g id="Group_784">
                                                                        <g id="Group_785">
                                                                            <g id="Group_786">
                                                                                <g id="Group_787">
                                                                                    <g id="Group_788">
                                                                                        <g id="Group_789">
                                                                                            <g id="Group_790">
                                                                                                <g id="Group_791">
                                                                                                    <g id="Group_792">
                                                                                                        <path
                                                                                                            id="Vector_143"
                                                                                                            fill="#4A6361"
                                                                                                            d="M113.176 216.675c-.005.116-.586.164-1.161.533-.584.354-.883.855-.99.809-.107-.024.072-.717.775-1.152.701-.441 1.402-.296 1.376-.19z"
                                                                                                        ></path>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="Group_793">
                                                    <g id="Group_794">
                                                        <g id="Group_795">
                                                            <g id="Group_796">
                                                                <g id="Group_797">
                                                                    <g id="Group_798">
                                                                        <g id="Group_799">
                                                                            <g id="Group_800">
                                                                                <g id="Group_801">
                                                                                    <g id="Group_802">
                                                                                        <g id="Group_803">
                                                                                            <g id="Group_804">
                                                                                                <g id="Group_805">
                                                                                                    <g id="Group_806">
                                                                                                        <path
                                                                                                            id="Vector_144"
                                                                                                            fill="#4A6361"
                                                                                                            d="M115.664 217.726c.025.113-.479.307-.878.795-.413.477-.516 1.008-.632 1.002-.109.017-.184-.664.323-1.262.501-.602 1.185-.646 1.187-.535z"
                                                                                                        ></path>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="Group_807">
                                                    <g id="Group_808">
                                                        <g id="Group_809">
                                                            <g id="Group_810">
                                                                <g id="Group_811">
                                                                    <g id="Group_812">
                                                                        <g id="Group_813">
                                                                            <g id="Group_814">
                                                                                <g id="Group_815">
                                                                                    <g id="Group_816">
                                                                                        <g id="Group_817">
                                                                                            <g id="Group_818">
                                                                                                <g id="Group_819">
                                                                                                    <g id="Group_820">
                                                                                                        <path
                                                                                                            id="Vector_145"
                                                                                                            fill="#4A6361"
                                                                                                            d="M117.058 220.724c-.106.024-.263-.558.041-1.192.298-.636.848-.883.896-.786.065.096-.29.435-.531.96-.254.518-.291 1.008-.406 1.018z"
                                                                                                        ></path>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="Group_821">
                                                    <g id="Group_822">
                                                        <g id="Group_823">
                                                            <g id="Group_824">
                                                                <g id="Group_825">
                                                                    <g id="Group_826">
                                                                        <g id="Group_827">
                                                                            <g id="Group_828">
                                                                                <g id="Group_829">
                                                                                    <g id="Group_830">
                                                                                        <g id="Group_831">
                                                                                            <g id="Group_832">
                                                                                                <g id="Group_833">
                                                                                                    <g id="Group_834">
                                                                                                        <path
                                                                                                            id="Vector_146"
                                                                                                            fill="#4A6361"
                                                                                                            d="M112.988 213.869c-.05.105-.582-.059-1.239-.019-.657.026-1.17.242-1.23.142-.066-.085.436-.511 1.211-.546.774-.042 1.316.332 1.258.423z"
                                                                                                        ></path>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <g id="Group_835">
                                            <g id="Group_836">
                                                <g id="Group_837">
                                                    <g id="Group_838">
                                                        <g id="Group_839">
                                                            <path
                                                                id="Vector_147"
                                                                fill="#4A6361"
                                                                d="M113.293 213.73c.059.002.068 1.135.033 2.951l-.095-.146c1.389.528 3.238 1.24 5.201 2.166 2.149 1.005 4.043 2.054 5.273 3.036.616.484 1.07.945 1.304 1.329.248.382.215.674.176.66-.019-.003-.036-.239-.304-.572-.257-.334-.723-.748-1.346-1.192-1.242-.902-3.136-1.902-5.272-2.901-1.95-.92-3.778-1.664-5.142-2.247l-.101-.043.006-.102c.109-1.813.207-2.941.267-2.939z"
                                                            ></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_840">
                                        <g id="Group_841">
                                            <g id="Group_842">
                                                <g id="Group_843">
                                                    <g id="Group_844">
                                                        <g id="Group_845">
                                                            <g id="Group_846">
                                                                <g id="Group_847">
                                                                    <g id="Group_848">
                                                                        <g id="Group_849">
                                                                            <g id="Group_850">
                                                                                <path
                                                                                    id="Vector_148"
                                                                                    fill="#4A6361"
                                                                                    d="M97.938 119.101v11.104l4.38 35.461s-1.388 16.629-2.241 26.016c-.74 8.153.47 22.35.47 22.35h12.987l4.647-47.348 2.884-29.529 5.231-17.492-28.359-.562z"
                                                                                ></path>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <g id="Group_851">
                                            <g id="Group_852">
                                                <g id="Group_853">
                                                    <g id="Group_854">
                                                        <g id="Group_855">
                                                            <g id="Group_856">
                                                                <g id="Group_857">
                                                                    <g id="Group_858">
                                                                        <g id="Group_859">
                                                                            <g id="Group_860">
                                                                                <g id="Group_861">
                                                                                    <g id="Group_862">
                                                                                        <g id="Group_863">
                                                                                            <g id="Group_864">
                                                                                                <g id="Group_865">
                                                                                                    <g id="Group_866">
                                                                                                        <path
                                                                                                            id="Vector_149"
                                                                                                            fill="#455A64"
                                                                                                            d="M120.961 202.414l-3.402 11.783s10.835 7.415 10.419 9.653l-23.084-5.775 4.547-18.577 11.52 2.916z"
                                                                                                        ></path>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                    <g id="Group_867">
                                                        <g id="Group_868">
                                                            <g id="Group_869">
                                                                <g id="Group_870">
                                                                    <g id="Group_871">
                                                                        <g id="Group_872">
                                                                            <g id="Group_873">
                                                                                <g id="Group_874">
                                                                                    <g id="Group_875">
                                                                                        <g id="Group_876">
                                                                                            <g id="Group_877">
                                                                                                <g id="Group_878">
                                                                                                    <g id="Group_879">
                                                                                                        <g id="Group_880">
                                                                                                            <path
                                                                                                                id="Vector_150"
                                                                                                                fill="#E0E0E0"
                                                                                                                d="M110.672 211.412c-.478.01-.912.435-.921.912-.009.477.419.923.897.921.477-.002 1.014-.496.97-.972-.044-.475-.576-.971-1.039-.854"
                                                                                                            ></path>
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                    <g id="Group_881" opacity="0.6">
                                                        <g id="Group_882">
                                                            <g id="Group_883">
                                                                <g id="Group_884">
                                                                    <g id="Group_885">
                                                                        <g id="Group_886">
                                                                            <g id="Group_887">
                                                                                <g id="Group_888">
                                                                                    <g id="Group_889">
                                                                                        <g id="Group_890">
                                                                                            <g id="Group_891">
                                                                                                <g id="Group_892">
                                                                                                    <g id="Group_893">
                                                                                                        <path
                                                                                                            id="Vector_151"
                                                                                                            fill="#fff"
                                                                                                            d="M104.894 218.076l.531-1.854 21.933 6.229s.916.705.619 1.4l-23.083-5.775z"
                                                                                                        ></path>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                    <g id="Group_894">
                                                        <g id="Group_895">
                                                            <g id="Group_896">
                                                                <g id="Group_897">
                                                                    <g id="Group_898">
                                                                        <g id="Group_899">
                                                                            <g id="Group_900">
                                                                                <g id="Group_901">
                                                                                    <g id="Group_902">
                                                                                        <g id="Group_903">
                                                                                            <g id="Group_904">
                                                                                                <g id="Group_905">
                                                                                                    <g id="Group_906">
                                                                                                        <g id="Group_907">
                                                                                                            <path
                                                                                                                id="Vector_152"
                                                                                                                fill="#4A6361"
                                                                                                                d="M117.963 214.173c-.032.112-.608.022-1.253.246-.652.207-1.06.623-1.152.553-.098-.048.238-.68 1.025-.937.782-.264 1.43.041 1.38.138z"
                                                                                                            ></path>
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                    <g id="Group_908">
                                                        <g id="Group_909">
                                                            <g id="Group_910">
                                                                <g id="Group_911">
                                                                    <g id="Group_912">
                                                                        <g id="Group_913">
                                                                            <g id="Group_914">
                                                                                <g id="Group_915">
                                                                                    <g id="Group_916">
                                                                                        <g id="Group_917">
                                                                                            <g id="Group_918">
                                                                                                <g id="Group_919">
                                                                                                    <g id="Group_920">
                                                                                                        <g id="Group_921">
                                                                                                            <path
                                                                                                                id="Vector_153"
                                                                                                                fill="#4A6361"
                                                                                                                d="M120.134 215.781c-.002.115-.538.184-1.04.566-.513.366-.739.857-.85.824-.11-.008-.023-.688.61-1.15.63-.467 1.304-.348 1.28-.24z"
                                                                                                            ></path>
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                    <g id="Group_922">
                                                        <g id="Group_923">
                                                            <g id="Group_924">
                                                                <g id="Group_925">
                                                                    <g id="Group_926">
                                                                        <g id="Group_927">
                                                                            <g id="Group_928">
                                                                                <g id="Group_929">
                                                                                    <g id="Group_930">
                                                                                        <g id="Group_931">
                                                                                            <g id="Group_932">
                                                                                                <g id="Group_933">
                                                                                                    <g id="Group_934">
                                                                                                        <g id="Group_935">
                                                                                                            <path
                                                                                                                id="Vector_154"
                                                                                                                fill="#4A6361"
                                                                                                                d="M120.784 219.022c-.108-.001-.124-.604.32-1.149.44-.548 1.032-.659 1.056-.552.041.108-.384.354-.742.807-.368.444-.519.911-.634.894z"
                                                                                                            ></path>
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                    <g id="Group_936">
                                                        <g id="Group_937">
                                                            <g id="Group_938">
                                                                <g id="Group_939">
                                                                    <g id="Group_940">
                                                                        <g id="Group_941">
                                                                            <g id="Group_942">
                                                                                <g id="Group_943">
                                                                                    <g id="Group_944">
                                                                                        <g id="Group_945">
                                                                                            <g id="Group_946">
                                                                                                <g id="Group_947">
                                                                                                    <g id="Group_948">
                                                                                                        <g id="Group_949">
                                                                                                            <path
                                                                                                                id="Vector_155"
                                                                                                                fill="#4A6361"
                                                                                                                d="M118.44 211.402c-.073.091-.551-.194-1.199-.31-.646-.129-1.195-.039-1.229-.15-.045-.098.543-.394 1.305-.247.762.141 1.201.632 1.123.707z"
                                                                                                            ></path>
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <g id="Group_950">
                                            <g id="Group_951">
                                                <g id="Group_952">
                                                    <g id="Group_953">
                                                        <g id="Group_954">
                                                            <g id="Group_955">
                                                                <g id="Group_956">
                                                                    <g id="Group_957">
                                                                        <g id="Group_958">
                                                                            <g id="Group_959">
                                                                                <path
                                                                                    id="Vector_156"
                                                                                    fill="#4A6361"
                                                                                    d="M131.311 119.858l.596 6.04-.72 43.855-10.278 35.229-2.49 8.543-13.266-2.498 10.226-91.156 15.932-.013z"
                                                                                ></path>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="Group_960">
                                    <g id="Group_961">
                                        <g id="Group_962">
                                            <path
                                                id="Vector_157"
                                                fill="#455A64"
                                                d="M130.91 170.672s-.014.071-.05.208l-.167.609-.669 2.359-2.547 8.857-8.946 30.853-.03.102-.104-.02-13.267-2.492-.121-.023.023-.121 2.106-11.119 5.93-31.218-.001.038-4.955-31.104.006.016-2.216-4.03-.565-1.042c-.125-.235-.184-.358-.184-.358s.074.114.208.345l.588 1.029 2.259 4.007.004.008.001.009 5.073 31.084.004.02-.004.019-5.903 31.223-2.108 11.119-.098-.145 13.264 2.505-.133.082 9.033-30.828 2.61-8.838.704-2.348.186-.604c.043-.135.069-.202.069-.202z"
                                            ></path>
                                        </g>
                                    </g>
                                </g>
                                <g id="Group_963">
                                    <g id="Group_964">
                                        <g id="Group_965">
                                            <path
                                                id="Vector_158"
                                                fill="#455A64"
                                                d="M128.928 123.455c.023.015-.212.449-.722 1.06a9.782 9.782 0 01-5.224 3.183c-.777.173-1.271.182-1.273.156-.024-.084 1.969-.278 4.011-1.541 2.058-1.236 3.145-2.918 3.208-2.858z"
                                            ></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g id="Group_966">
                                <g id="Group_967">
                                    <g id="Group_968">
                                        <path
                                            id="Vector_159"
                                            fill="#FFBF9D"
                                            d="M157.197 57.281c.168-.248.126-.6-.125-.773-.064-.045-.132-.07-.198-.059-.266.044-.965.555-1.503.564-.538.009-1.454-.156-1.992.343-.538.5-1.849 3.24-2.017 3.835-.166.595-.75 5.82-.75 5.82l6.585-9.73z"
                                        ></path>
                                    </g>
                                    <g id="Group_969">
                                        <path
                                            id="Vector_160"
                                            fill="#263238"
                                            d="M159.471 56.597c-.029.033-6.438 2.861-9.253 4.103a.956.956 0 00-.274 1.57c.305.297.768.37 1.146.179l9.198-4.482.758-1.152-1.575-.218z"
                                        ></path>
                                    </g>
                                    <g id="Group_970">
                                        <path
                                            id="Vector_161"
                                            fill="#FFBF9D"
                                            d="M152.684 62.364l2.043-1.505 2.698-3.915s1.105-.657 1.677-.244c.573.414.76.892.494 1.427 0 0 1.524.364 1.633.875.109.51-.22 1.752-.22 1.752s.226.954.011 1.459c-.214.505-.192.941-.192.941s.987 1.208.563 2.444c-.424 1.236-2.205 3.993-3.157 4.706-.952.713-2.757 1.567-2.757 1.567l-1.177 2.467-4.887-4.425 3.271-7.548z"
                                        ></path>
                                    </g>
                                    <g id="Group_971">
                                        <g id="Group_972">
                                            <path
                                                id="Vector_162"
                                                fill="#FF9A6C"
                                                d="M157.339 57.112c.006.004-.038.079-.123.211l-.364.555c-.31.466-.746 1.105-1.232 1.807l-.689.989a2.47 2.47 0 01-.351.403 1.736 1.736 0 01-.391.254c-.259.121-.481.175-.633.212-.153.036-.239.05-.241.044-.001-.007.081-.034.23-.08.148-.05.367-.114.613-.24.527-.22.877-.956 1.381-1.638.486-.703.931-1.336 1.257-1.791l.39-.537c.093-.125.147-.193.153-.189z"
                                            ></path>
                                        </g>
                                    </g>
                                    <g id="Group_973">
                                        <g id="Group_974">
                                            <path
                                                id="Vector_163"
                                                fill="#FF9A6C"
                                                d="M157.967 61.184c-.024-.013.329-.702.787-1.539.459-.837.851-1.504.875-1.49.024.013-.328.701-.787 1.538-.46.837-.851 1.505-.875 1.491z"
                                            ></path>
                                        </g>
                                    </g>
                                    <g id="Group_975">
                                        <g id="Group_976">
                                            <path
                                                id="Vector_164"
                                                fill="#FF9A6C"
                                                d="M158.609 63.632c-.015 0-.023-.088-.073-.215a.903.903 0 00-.318-.41.928.928 0 00-.494-.157c-.137-.004-.222.018-.227.003-.004-.01.076-.059.225-.073a.849.849 0 01.554.146c.19.133.293.327.328.47.036.144.017.236.005.236z"
                                            ></path>
                                        </g>
                                    </g>
                                    <g id="Group_977">
                                        <g id="Group_978">
                                            <path
                                                id="Vector_165"
                                                fill="#FF9A6C"
                                                d="M160.878 64.365c-.01.01-.086-.046-.202-.139-.117-.09-.277-.22-.478-.312-.199-.091-.404-.118-.539-.088-.14.028-.19.112-.202.103-.014 0 .022-.118.183-.17.154-.054.383-.035.6.064.217.1.376.245.483.35.106.109.164.182.155.192z"
                                            ></path>
                                        </g>
                                    </g>
                                    <g id="Group_979">
                                        <g id="Group_980">
                                            <path
                                                id="Vector_166"
                                                fill="#FF9A6C"
                                                d="M160.002 62.305c-.023-.014.178-.374.448-.8.271-.429.51-.763.533-.749.023.015-.178.373-.448.801-.271.428-.51.762-.533.748z"
                                            ></path>
                                        </g>
                                    </g>
                                    <g id="Group_981">
                                        <g id="Group_982">
                                            <path
                                                id="Vector_167"
                                                fill="#FF9A6C"
                                                d="M156.085 62.485c-.015-.001-.013-.085-.051-.207a.77.77 0 00-.276-.394.791.791 0 00-.461-.142c-.128 0-.207.027-.213.012-.005-.01.068-.062.208-.082a.726.726 0 01.811.59c.022.14-.007.225-.018.223z"
                                            ></path>
                                        </g>
                                    </g>
                                    <g id="Group_983">
                                        <g id="Group_984">
                                            <path
                                                id="Vector_168"
                                                fill="#455A64"
                                                d="M150.739 62.47c-.012-.013.089-.089.177-.259a.885.885 0 00.047-.71.93.93 0 00-.508-.503c-.177-.074-.302-.071-.302-.088-.002-.01.128-.04.326.022.194.057.457.23.578.533a.886.886 0 01-.08.782c-.113.175-.233.233-.238.224z"
                                            ></path>
                                        </g>
                                    </g>
                                </g>
                                <g id="Group_985">
                                    <g id="Group_986">
                                        <g id="Group_987">
                                            <g id="Group_988">
                                                <path
                                                    id="Vector_169"
                                                    fill="#FFBF9D"
                                                    d="M133.555 74.84l7.752 6.635 9.515-14.217 6.187 3.65s-9.273 21.347-11.786 23.234c-2.514 1.886-4.634 1.397-7.868-.097-3.234-1.493-14.751-13.089-14.751-13.089l10.951-6.116z"
                                                ></path>
                                            </g>
                                        </g>
                                        <g id="Group_989">
                                            <g id="Group_990">
                                                <g id="Group_991">
                                                    <path
                                                        id="Vector_170"
                                                        fill="#FF9A6C"
                                                        d="M141.582 87.374c-.076-.012.135-1.352.042-3.008-.083-1.657-.436-2.967-.362-2.986.057-.023.526 1.28.611 2.973.095 1.691-.231 3.038-.291 3.021z"
                                                    ></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g id="Group_992">
                                <g id="Group_993">
                                    <g id="Group_994">
                                        <path
                                            id="Vector_171"
                                            fill="#FFEAAD"
                                            d="M101.916 63.983s6.591.095 6.686 8.309c.096 8.213-1.677 19.201-1.677 19.201L90.104 88.53s2.087-15.292 3.868-19.695c1.793-4.433 5.217-5.598 7.944-4.852z"
                                        ></path>
                                    </g>
                                </g>
                                <g id="Group_995">
                                    <g id="Group_996">
                                        <path
                                            id="Vector_172"
                                            fill="#FFEAAD"
                                            d="M105.044 58.07l-.21 1.34s-8.99 5.939-9.499 7.472c-.788 2.378.931 22.458 1.69 28.15.758 5.69.912 18.233.912 18.233-.552 1.477-1.179 2.81-1.386 4.871-.206 2.044 1.386 3.91 1.386 3.91 9.646 3.148 21.43.731 34.177-2.175l-.12-3.614-4.167-39.258s1.214-10.203-3.764-13.157a1006.6 1006.6 0 00-4.889-2.791l-14.13-2.98z"
                                        ></path>
                                    </g>
                                </g>
                                <g id="Group_997" opacity="0.3">
                                    <g id="Group_998">
                                        <path
                                            id="Vector_173"
                                            fill="#000"
                                            d="M119.501 75.088s-.078 5.65 2.962 12.11c3.039 6.459 7.561 10.494 7.561 10.494l-1.348-11.746-9.175-10.858z"
                                        ></path>
                                    </g>
                                </g>
                                <g id="Group_999">
                                    <g id="Group_1000">
                                        <g id="Group_1001">
                                            <path
                                                id="Vector_174"
                                                fill="#FFEAAD"
                                                d="M127.05 65.176c-1.576-1.275-4.507-1.968-6.18-.821-.744.51-1.613 1.28-2.029 2.498-1.428 4.174-.574 8.662.568 11.993 1.143 3.332 6.663 9.747 6.663 9.747l13.639-8.278s-9.129-11.065-10.081-12.208c-.497-.596-1.705-2.222-2.58-2.93z"
                                            ></path>
                                        </g>
                                    </g>
                                    <g id="Group_1002">
                                        <g id="Group_1003">
                                            <g id="Group_1004">
                                                <path
                                                    id="Vector_175"
                                                    fill="#263238"
                                                    d="M127.05 65.176s.108.068.288.23c.182.162.439.416.753.765.632.696 1.465 1.782 2.59 3.104l9.116 10.969.084.1-.112.068-12.886 7.837-.746.453-.092.055-.068-.081a74.55 74.55 0 01-4.214-5.438c-.634-.912-1.23-1.829-1.74-2.773a11.37 11.37 0 01-.671-1.448c-.183-.493-.329-.987-.474-1.477-1.139-3.917-1.168-7.76-.129-10.683a4.627 4.627 0 011.195-1.852c.51-.47 1.044-.888 1.643-1.078a4.624 4.624 0 011.697-.201c.525.028.999.12 1.413.241.832.23 1.423.56 1.804.797.382.24.549.412.549.412-.011.015-.188-.145-.575-.371a7.043 7.043 0 00-1.804-.747 6.258 6.258 0 00-1.392-.216 4.523 4.523 0 00-1.647.214c-.567.184-1.084.597-1.578 1.06a4.476 4.476 0 00-1.14 1.796c-1.004 2.89-.959 6.682.183 10.564.145.484.292.978.474 1.46.18.48.408.95.661 1.415.505.93 1.099 1.84 1.731 2.746a75.21 75.21 0 004.204 5.415l-.16-.025.747-.453 12.899-7.815-.029.168c-3.707-4.52-6.835-8.332-9.046-11.026-1.111-1.335-1.93-2.438-2.541-3.143a12.578 12.578 0 00-.723-.784c-.172-.171-.27-.25-.264-.258z"
                                                ></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="Group_1005">
                                    <g id="Group_1006">
                                        <g id="Group_1007">
                                            <path
                                                id="Vector_176"
                                                fill="#263238"
                                                d="M95.159 71.43c.008-.001.022.08.039.237l.063.685.183 2.522.528 8.323c.213 3.25.436 6.19.629 8.314l.234 2.516.06.685c.01.157.011.24.003.24-.009.002-.027-.079-.054-.234-.03-.184-.064-.408-.106-.68a70.957 70.957 0 01-.313-2.51c-.238-2.123-.49-5.063-.703-8.315-.211-3.251-.358-6.197-.455-8.33l-.103-2.525-.016-.688c-.001-.158.002-.24.01-.24z"
                                            ></path>
                                        </g>
                                    </g>
                                </g>
                                <g id="Group_1008">
                                    <g id="Group_1009">
                                        <g id="Group_1010">
                                            <path
                                                id="Vector_177"
                                                fill="#263238"
                                                d="M125.319 119.101c.002.009-.085.035-.252.079l-.736.178c-.32.079-.714.162-1.173.247-.458.091-.981.192-1.563.279-1.159.2-2.552.362-4.1.512-1.55.13-3.259.225-5.054.248a69.23 69.23 0 01-5.06-.152c-1.551-.126-2.945-.266-4.108-.454a29.458 29.458 0 01-1.565-.265 20.44 20.44 0 01-1.174-.241l-.737-.177c-.167-.044-.254-.07-.252-.079.002-.008.093.002.264.029l.744.131c.322.064.718.13 1.179.199.46.074.984.159 1.566.227 1.161.166 2.553.288 4.099.4a78.22 78.22 0 005.042.132 80.71 80.71 0 005.037-.228c1.544-.138 2.934-.281 4.093-.458a38.349 38.349 0 001.564-.241c.461-.072.857-.14 1.178-.205l.745-.132c.17-.027.261-.038.263-.029z"
                                            ></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                    <g id="Character_1">
                        <g id="Group_1011">
                            <g id="Group_1012">
                                <g id="Group_1013">
                                    <path
                                        id="Vector_178"
                                        fill="#263238"
                                        d="M39.329 34.997c-1.89-.372-3.887.234-5.449 1.36-1.092.789-2.017 1.85-2.49 3.11-.473 1.262-.46 2.732.186 3.915.668 1.222 1.92 2.022 3.215 2.53 1.086.427 2.264.687 3.42.533 1.157-.154 2.288-.767 2.88-1.772l2.23-4.71c0-3.024-2.103-4.594-3.992-4.966z"
                                    ></path>
                                </g>
                                <g id="Group_1014">
                                    <path
                                        id="Vector_179"
                                        fill="#263238"
                                        d="M60.384 40.865c-3.885-4.922-10.825-6.36-16.237-3.193-.556.325-1.082.678-1.58 1.045-3.487 2.57-5.441 7.01-5.187 11.334.254 4.323 2.637 8.428 6.146 10.967l16.96-4.133c1.06-2.873 1.524-5.629 1.86-9.11.034-.36.051-.823.042-1.336a9.283 9.283 0 00-2.004-5.574z"
                                    ></path>
                                </g>
                                <g id="Group_1015">
                                    <g id="Group_1016">
                                        <g id="Group_1017">
                                            <g id="Group_1018">
                                                <g id="Group_1019">
                                                    <g id="Group_1020">
                                                        <g id="Group_1021">
                                                            <g id="Group_1022">
                                                                <path
                                                                    id="Vector_180"
                                                                    fill="#FFBF9D"
                                                                    d="M41.469 47.762c.198-2.804 2.013-5.102 4.736-5.797l8.487-2.165c3.668-.396 6.117 4.116 6.357 7.797.267 4.09.431 8.406-.047 11.46-.96 6.138-5.663 6.93-5.663 6.93 0-.009 0 2.92-.004 4.712l.088 7.803-12.081-8.688-1.895-21.978c-.002-.105.012.066.022-.074z"
                                                                ></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="Group_1023">
                                                <g id="Group_1024">
                                                    <g id="Group_1025">
                                                        <g id="Group_1026">
                                                            <g id="Group_1027">
                                                                <g id="Group_1028">
                                                                    <g id="Group_1029">
                                                                        <g id="Group_1030">
                                                                            <g id="Group_1031">
                                                                                <g id="Group_1032">
                                                                                    <g id="Group_1033">
                                                                                        <g id="Group_1034">
                                                                                            <g id="Group_1035">
                                                                                                <g id="Group_1036">
                                                                                                    <g id="Group_1037">
                                                                                                        <g id="Group_1038">
                                                                                                            <g id="Group_1039">
                                                                                                                <g id="Group_1040">
                                                                                                                    <g id="Group_1041">
                                                                                                                        <g id="Group_1042">
                                                                                                                            <path
                                                                                                                                id="Vector_181"
                                                                                                                                fill="#263238"
                                                                                                                                d="M59.916 52.533c.015.416-.323.771-.757.796-.433.024-.797-.292-.813-.708-.015-.415.324-.772.757-.796.433-.025.797.292.813.708z"
                                                                                                                            ></path>
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="Group_1043">
                                                <g id="Group_1044">
                                                    <g id="Group_1045">
                                                        <g id="Group_1046">
                                                            <g id="Group_1047">
                                                                <g id="Group_1048">
                                                                    <g id="Group_1049">
                                                                        <g id="Group_1050">
                                                                            <g id="Group_1051">
                                                                                <g id="Group_1052">
                                                                                    <g id="Group_1053">
                                                                                        <g id="Group_1054">
                                                                                            <g id="Group_1055">
                                                                                                <g id="Group_1056">
                                                                                                    <g id="Group_1057">
                                                                                                        <g id="Group_1058">
                                                                                                            <g id="Group_1059">
                                                                                                                <g id="Group_1060">
                                                                                                                    <g id="Group_1061">
                                                                                                                        <g id="Group_1062">
                                                                                                                            <g id="Group_1063">
                                                                                                                                <path
                                                                                                                                    id="Vector_182"
                                                                                                                                    fill="#263238"
                                                                                                                                    d="M59.99 50.124c-.098.103-.695-.337-1.542-.333-.846-.008-1.467.43-1.56.326-.044-.046.055-.232.325-.43.266-.196.72-.388 1.248-.387.527.002.972.195 1.227.393.26.2.35.386.301.431z"
                                                                                                                                ></path>
                                                                                                                            </g>
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="Group_1064">
                                                <g id="Group_1065">
                                                    <g id="Group_1066">
                                                        <g id="Group_1067">
                                                            <g id="Group_1068">
                                                                <g id="Group_1069">
                                                                    <g id="Group_1070">
                                                                        <g id="Group_1071">
                                                                            <g id="Group_1072">
                                                                                <g id="Group_1073">
                                                                                    <g id="Group_1074">
                                                                                        <g id="Group_1075">
                                                                                            <g id="Group_1076">
                                                                                                <g id="Group_1077">
                                                                                                    <g id="Group_1078">
                                                                                                        <g id="Group_1079">
                                                                                                            <g id="Group_1080">
                                                                                                                <g id="Group_1081">
                                                                                                                    <g id="Group_1082">
                                                                                                                        <g id="Group_1083">
                                                                                                                            <path
                                                                                                                                id="Vector_183"
                                                                                                                                fill="#263238"
                                                                                                                                d="M52.612 52.533c.016.416-.323.772-.757.796-.432.024-.797-.292-.813-.708-.015-.415.324-.772.757-.796.434-.024.798.292.813.708z"
                                                                                                                            ></path>
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="Group_1084">
                                                <g id="Group_1085">
                                                    <g id="Group_1086">
                                                        <g id="Group_1087">
                                                            <g id="Group_1088">
                                                                <g id="Group_1089">
                                                                    <g id="Group_1090">
                                                                        <g id="Group_1091">
                                                                            <g id="Group_1092">
                                                                                <g id="Group_1093">
                                                                                    <g id="Group_1094">
                                                                                        <g id="Group_1095">
                                                                                            <g id="Group_1096">
                                                                                                <g id="Group_1097">
                                                                                                    <g id="Group_1098">
                                                                                                        <g id="Group_1099">
                                                                                                            <g id="Group_1100">
                                                                                                                <g id="Group_1101">
                                                                                                                    <g id="Group_1102">
                                                                                                                        <g id="Group_1103">
                                                                                                                            <g id="Group_1104">
                                                                                                                                <path
                                                                                                                                    id="Vector_184"
                                                                                                                                    fill="#263238"
                                                                                                                                    d="M52.122 50.282c-.097.103-.695-.337-1.541-.333-.846-.008-1.467.429-1.56.325-.044-.045.053-.23.325-.429.266-.196.72-.388 1.248-.388.527.002.971.195 1.227.394.26.2.35.385.301.43z"
                                                                                                                                ></path>
                                                                                                                            </g>
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="Group_1105">
                                                <g id="Group_1106">
                                                    <g id="Group_1107">
                                                        <g id="Group_1108">
                                                            <g id="Group_1109">
                                                                <g id="Group_1110">
                                                                    <g id="Group_1111">
                                                                        <g id="Group_1112">
                                                                            <g id="Group_1113">
                                                                                <g id="Group_1114">
                                                                                    <g id="Group_1115">
                                                                                        <g id="Group_1116">
                                                                                            <g id="Group_1117">
                                                                                                <g id="Group_1118">
                                                                                                    <g id="Group_1119">
                                                                                                        <g id="Group_1120">
                                                                                                            <g id="Group_1121">
                                                                                                                <g id="Group_1122">
                                                                                                                    <g id="Group_1123">
                                                                                                                        <g id="Group_1124">
                                                                                                                            <g id="Group_1125">
                                                                                                                                <path
                                                                                                                                    id="Vector_185"
                                                                                                                                    fill="#263238"
                                                                                                                                    d="M54.732 56.654c-.004-.049.522-.14 1.375-.252.216-.023.42-.066.456-.214.051-.156-.04-.389-.145-.64l-.644-1.646c-.893-2.342-1.54-4.269-1.445-4.304.094-.035.895 1.835 1.788 4.178l.617 1.656c.082.245.224.524.114.848a.542.542 0 01-.355.317c-.138.043-.26.045-.367.052-.858.048-1.391.053-1.394.005z"
                                                                                                                                ></path>
                                                                                                                            </g>
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="Group_1126">
                                                <g id="Group_1127">
                                                    <g id="Group_1128">
                                                        <g id="Group_1129">
                                                            <g id="Group_1130">
                                                                <g id="Group_1131">
                                                                    <g id="Group_1132">
                                                                        <g id="Group_1133">
                                                                            <g id="Group_1134">
                                                                                <path
                                                                                    id="Vector_186"
                                                                                    fill="#FF9A6C"
                                                                                    d="M47.197 63.804s1.997 4.216 8.138 3.628l.002-1.444s-4.044.266-8.14-2.184z"
                                                                                ></path>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="Group_1135">
                                                <g id="Group_1136">
                                                    <g id="Group_1137">
                                                        <g id="Group_1138">
                                                            <g id="Group_1139">
                                                                <g id="Group_1140">
                                                                    <g id="Group_1141">
                                                                        <g id="Group_1142">
                                                                            <g id="Group_1143">
                                                                                <g id="Group_1144">
                                                                                    <g id="Group_1145">
                                                                                        <g id="Group_1146">
                                                                                            <g id="Group_1147">
                                                                                                <g id="Group_1148">
                                                                                                    <g id="Group_1149">
                                                                                                        <g id="Group_1150">
                                                                                                            <g id="Group_1151">
                                                                                                                <path
                                                                                                                    id="Vector_187"
                                                                                                                    fill="#263238"
                                                                                                                    d="M51.814 46.941c-.083.23-.929.123-1.922.245-.996.102-1.797.395-1.93.189-.058-.099.081-.316.405-.54.32-.223.832-.435 1.429-.503.596-.067 1.143.026 1.505.173.365.147.548.327.513.436z"
                                                                                                                ></path>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="Group_1152">
                                                <g id="Group_1153">
                                                    <g id="Group_1154">
                                                        <g id="Group_1155">
                                                            <g id="Group_1156">
                                                                <g id="Group_1157">
                                                                    <g id="Group_1158">
                                                                        <g id="Group_1159">
                                                                            <g id="Group_1160">
                                                                                <g id="Group_1161">
                                                                                    <g id="Group_1162">
                                                                                        <g id="Group_1163">
                                                                                            <g id="Group_1164">
                                                                                                <g id="Group_1165">
                                                                                                    <g id="Group_1166">
                                                                                                        <g id="Group_1167">
                                                                                                            <g id="Group_1168">
                                                                                                                <path
                                                                                                                    id="Vector_188"
                                                                                                                    fill="#263238"
                                                                                                                    d="M59.113 47.06c-.148.193-.732-.005-1.434-.013-.7-.032-1.295.132-1.432-.068-.06-.098.033-.29.292-.474.256-.182.683-.331 1.165-.318.482.015.9.188 1.145.385.248.197.33.395.264.489z"
                                                                                                                ></path>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="Group_1169">
                                                <g id="Group_1170">
                                                    <g id="Group_1171">
                                                        <g id="Group_1172">
                                                            <g id="Group_1173">
                                                                <g id="Group_1174">
                                                                    <g id="Group_1175">
                                                                        <g id="Group_1176">
                                                                            <g id="Group_1177">
                                                                                <g id="Group_1178">
                                                                                    <g id="Group_1179">
                                                                                        <g id="Group_1180">
                                                                                            <g id="Group_1181">
                                                                                                <g id="Group_1182">
                                                                                                    <g id="Group_1183">
                                                                                                        <g id="Group_1184">
                                                                                                            <g id="Group_1185">
                                                                                                                <g id="Group_1186">
                                                                                                                    <g id="Group_1187">
                                                                                                                        <g id="Group_1188">
                                                                                                                            <g id="Group_1189">
                                                                                                                                <g id="Group_1190">
                                                                                                                                    <g id="Group_11912">
                                                                                                                                        <path
                                                                                                                                            id="Vector_189"
                                                                                                                                            fill="#FFBF9D"
                                                                                                                                            d="M42.31 52.208c-.064-.176-3.266-1.331-3.309 2.072-.04 3.306 3.295 2.655 3.302 2.56.006-.095.007-4.632.007-4.632z"
                                                                                                                                        ></path>
                                                                                                                                    </g>
                                                                                                                                </g>
                                                                                                                            </g>
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="Group_1192">
                                                    <g id="Group_1193">
                                                        <g id="Group_1194">
                                                            <g id="Group_1195">
                                                                <g id="Group_1196">
                                                                    <g id="Group_1197">
                                                                        <g id="Group_1198">
                                                                            <g id="Group_1199">
                                                                                <g id="Group_1200">
                                                                                    <g id="Group_1201">
                                                                                        <g id="Group_1202">
                                                                                            <g id="Group_1203">
                                                                                                <g id="Group_1204">
                                                                                                    <g id="Group_1205">
                                                                                                        <g id="Group_1206">
                                                                                                            <g id="Group_1207">
                                                                                                                <g id="Group_1208">
                                                                                                                    <g id="Group_1209">
                                                                                                                        <g id="Group_1210">
                                                                                                                            <g id="Group_1211">
                                                                                                                                <g id="Group_1212">
                                                                                                                                    <g id="Group_1213">
                                                                                                                                        <g id="Group_1214">
                                                                                                                                            <path
                                                                                                                                                id="Vector_190"
                                                                                                                                                fill="#FF9A6C"
                                                                                                                                                d="M41.294 55.578c-.014-.01-.058.04-.155.083a.575.575 0 01-.431.005c-.348-.131-.637-.689-.648-1.285-.005-.3.06-.587.17-.823.103-.24.26-.408.437-.438.175-.039.299.068.338.161.043.093.022.159.04.165.008.008.07-.055.045-.192a.365.365 0 00-.128-.208.432.432 0 00-.317-.087c-.255.015-.487.248-.607.507-.133.26-.214.581-.208.919.015.666.35 1.293.827 1.436a.616.616 0 00.528-.083c.102-.08.12-.154.109-.16z"
                                                                                                                                            ></path>
                                                                                                                                        </g>
                                                                                                                                    </g>
                                                                                                                                </g>
                                                                                                                            </g>
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="Group_1215">
                                                <g id="Group_1216">
                                                    <g id="Group_1217">
                                                        <g id="Group_1218">
                                                            <g id="Group_1219">
                                                                <g id="Group_1220">
                                                                    <g id="Group_1221">
                                                                        <g id="Group_1222">
                                                                            <g id="Group_1223">
                                                                                <g id="Group_1224">
                                                                                    <path
                                                                                        id="Vector_191"
                                                                                        fill="#fff"
                                                                                        d="M40.922 56.162a.675.675 0 00-.75.481c-.08.318.122.68.434.78.311.098.686-.08.804-.385a.676.676 0 00-.333-.827"
                                                                                    ></path>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="Group_1225">
                                                    <g id="Group_1226">
                                                        <g id="Group_1227">
                                                            <g id="Group_1228">
                                                                <g id="Group_1229">
                                                                    <g id="Group_1230">
                                                                        <g id="Group_1231">
                                                                            <g id="Group_1232">
                                                                                <g id="Group_1233">
                                                                                    <g id="Group_1234">
                                                                                        <path
                                                                                            id="Vector_192"
                                                                                            fill="#263238"
                                                                                            d="M41.075 56.211c-.02.02.215.115.297.444a.56.56 0 01-.129.519.618.618 0 01-.616.177.619.619 0 01-.4-.5.56.56 0 01.194-.499c.258-.221.504-.163.5-.19.002-.008-.05-.035-.155-.037a.673.673 0 00-.412.145.66.66 0 00-.265.596c.024.254.197.53.493.629a.744.744 0 00.765-.228.66.66 0 00.129-.64.673.673 0 00-.253-.356c-.087-.059-.145-.068-.148-.06z"
                                                                                        ></path>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="Group_1235">
                                    <g id="Group_1236">
                                        <g id="Group_1237">
                                            <g id="Group_1238">
                                                <path
                                                    id="Vector_193"
                                                    fill="#FF9A6C"
                                                    d="M51.516 58.105c.203-.361.588-.562.99-.591.58-.042 1.073.137 1.47.657.135.15.141.586.093.709-.048.122-.01.061-.1.148-.327.313-.673.626-1.105.747-.43.121-.971.001-1.273-.412a1.215 1.215 0 01-.075-1.258z"
                                                ></path>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_1239">
                                        <g id="Group_1240">
                                            <g id="Group_1241">
                                                <g id="Group_1242">
                                                    <path
                                                        id="Vector_194"
                                                        fill="#263238"
                                                        d="M51.644 56.955c.136-.014.179.896.988 1.508.807.614 1.774.468 1.787.594.015.057-.209.188-.618.217a2.268 2.268 0 01-1.489-.445c-.494-.375-.722-.895-.77-1.267-.053-.38.039-.61.102-.607z"
                                                    ></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="Group_1243">
                                    <g id="Group_1244">
                                        <path
                                            id="Vector_195"
                                            fill="#263238"
                                            d="M56.288 39.332c-.207-.37-.686-.47-1.108-.516-5.87-.636-11.99 2.002-15.56 6.708l.944 5.715c-.4.711 1.477 2.14 2.291 2.09.815-.051 1.45-.764 1.74-1.526.566-1.492.242-3.402 1.408-4.492.657-.614 1.605-.766 2.484-.96 1.674-.367 3.31-.99 4.704-1.989 1.394-.997 2.537-2.388 3.076-4.015.11-.332.19-.71.02-1.015z"
                                        ></path>
                                    </g>
                                </g>
                                <g id="Group_1245">
                                    <g id="Group_1246">
                                        <g id="Group_1247">
                                            <path
                                                id="Vector_196"
                                                fill="#455A64"
                                                d="M38.628 54.97c-.015.007-.138-.203-.322-.6-.184-.396-.415-.99-.624-1.746-.422-1.503-.674-3.725-.165-6.084.51-2.36 1.657-4.28 2.663-5.475.502-.603.957-1.048 1.29-1.333.33-.286.529-.425.54-.413.036.04-.727.644-1.681 1.865a13.576 13.576 0 00-2.547 5.413c-.5 2.309-.284 4.486.086 5.98.364 1.506.809 2.372.76 2.393z"
                                            ></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g id="Group_1248">
                                <g id="Group_1249">
                                    <g id="Group_1250">
                                        <g id="Group_1251">
                                            <g id="Group_1252">
                                                <g id="Group_1253">
                                                    <g id="Group_1254">
                                                        <g id="Group_1255">
                                                            <g id="Group_1256">
                                                                <g id="Group_1257">
                                                                    <g id="Group_1258">
                                                                        <g id="Group_1259">
                                                                            <path
                                                                                id="Vector_197"
                                                                                fill="#263238"
                                                                                d="M42.764 206.101l1.944 16.78 7.354-1.12.495-16.537-9.793.877z"
                                                                            ></path>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_1260">
                                        <g id="Group_1261">
                                            <g id="Group_1262">
                                                <g id="Group_1263">
                                                    <g id="Group_1264">
                                                        <g id="Group_1265">
                                                            <g id="Group_1266">
                                                                <g id="Group_1267">
                                                                    <g id="Group_1268">
                                                                        <path
                                                                            id="Vector_198"
                                                                            fill="#263238"
                                                                            d="M52.084 218.434s-4.936 4.186-7.685 1.11l-.139-.503-2.567 13.665c-.14.746.252 1.526.96 1.748.546.171 1.121.006 1.493-.414 1.993-2.251 9.3-10.932 9.3-10.932l-.29-2.189c-.048-.575-.602-1.786-1.072-2.485z"
                                                                        ></path>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <g id="Group_1269">
                                            <path
                                                id="Vector_199"
                                                fill="#E0E0E0"
                                                d="M53.214 221.469l-10.3 13.042a1.42 1.42 0 001.274-.473c5.515-6.285 9.258-10.93 9.258-10.93l-.232-1.639z"
                                            ></path>
                                        </g>
                                        <g id="Group_1270">
                                            <g id="Group_1271">
                                                <g id="Group_1272">
                                                    <g id="Group_1273">
                                                        <g id="Group_1274">
                                                            <g id="Group_1275">
                                                                <g id="Group_1276">
                                                                    <g id="Group_1277">
                                                                        <g id="Group_1278">
                                                                            <path
                                                                                id="Vector_200"
                                                                                fill="#455A64"
                                                                                d="M45.694 228.027c-.077.046-.442-.68-1.3-1.022-.847-.367-1.605-.109-1.629-.196-.014-.034.155-.159.479-.23.32-.073.796-.06 1.267.134.47.197.819.527.998.808.184.282.22.492.185.506z"
                                                                            ></path>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <g id="Group_1279">
                                            <g id="Group_1280">
                                                <g id="Group_1281">
                                                    <g id="Group_1282">
                                                        <g id="Group_1283">
                                                            <g id="Group_1284">
                                                                <g id="Group_1285">
                                                                    <g id="Group_1286">
                                                                        <g id="Group_1287">
                                                                            <path
                                                                                id="Vector_201"
                                                                                fill="#455A64"
                                                                                d="M44.826 230.015c-.062.066-.542-.365-1.279-.524-.733-.175-1.348.008-1.376-.08-.045-.07.607-.422 1.444-.228.839.186 1.281.788 1.21.832z"
                                                                            ></path>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <g id="Group_1288">
                                            <g id="Group_1289">
                                                <g id="Group_1290">
                                                    <g id="Group_1291">
                                                        <g id="Group_1292">
                                                            <g id="Group_1293">
                                                                <g id="Group_1294">
                                                                    <g id="Group_1295">
                                                                        <g id="Group_1296">
                                                                            <path
                                                                                id="Vector_202"
                                                                                fill="#455A64"
                                                                                d="M47.383 225.435c-.061.073-.795-.595-1.92-.945-1.119-.367-2.095-.246-2.105-.341-.028-.075.997-.35 2.198.041 1.205.377 1.892 1.2 1.827 1.245z"
                                                                            ></path>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <g id="Group_1297">
                                            <g id="Group_1298">
                                                <g id="Group_1299">
                                                    <g id="Group_1300">
                                                        <g id="Group_1301">
                                                            <g id="Group_1302">
                                                                <g id="Group_1303">
                                                                    <g id="Group_1304">
                                                                        <g id="Group_1305">
                                                                            <path
                                                                                id="Vector_203"
                                                                                fill="#263238"
                                                                                d="M47.385 221.978c-.032.088-.826-.185-1.833-.148-1.007.023-1.786.343-1.822.256-.044-.067.732-.543 1.814-.571 1.083-.037 1.88.393 1.84.463z"
                                                                            ></path>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="Group_1306">
                                    <g id="Group_1307">
                                        <g id="Group_1308">
                                            <g id="Group_1309">
                                                <path
                                                    id="Vector_204"
                                                    fill="#263238"
                                                    d="M71.894 219.322c-.17-.259-1.71-5.874-1.71-5.874l9.519-2.591 1.349 8.093-9.158.372z"
                                                ></path>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_1310">
                                        <g id="Group_1311">
                                            <g id="Group_1312">
                                                <g id="Group_1313">
                                                    <g id="Group_1314">
                                                        <g id="Group_1315">
                                                            <g id="Group_1316">
                                                                <g id="Group_1317">
                                                                    <g id="Group_1318">
                                                                        <g id="Group_1319">
                                                                            <g id="Group_1320">
                                                                                <g id="Group_1321">
                                                                                    <g id="Group_1322">
                                                                                        <g id="Group_1323">
                                                                                            <g id="Group_1324">
                                                                                                <g id="Group_1325">
                                                                                                    <path
                                                                                                        id="Vector_205"
                                                                                                        fill="#263238"
                                                                                                        d="M80.656 216.575l.322 1.428s10.477 4.268 10.612 6.346l-20.704.151.256-7.626c3.087 1.475 5.976 1.223 9.514-.299z"
                                                                                                    ></path>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="Group_1326">
                                                    <g id="Group_1327">
                                                        <g id="Group_1328">
                                                            <g id="Group_1329">
                                                                <g id="Group_1330">
                                                                    <g id="Group_1331">
                                                                        <g id="Group_1332">
                                                                            <g id="Group_1333">
                                                                                <g id="Group_1334">
                                                                                    <g id="Group_1335">
                                                                                        <g id="Group_1336">
                                                                                            <g id="Group_1337">
                                                                                                <g id="Group_1338">
                                                                                                    <path
                                                                                                        id="Vector_206"
                                                                                                        fill="#E0E0E0"
                                                                                                        d="M70.886 224.5l.056-1.742 19.823.494s.924.414.825 1.097l-20.704.151z"
                                                                                                    ></path>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="Group_1339">
                                                    <g id="Group_1340">
                                                        <g id="Group_1341">
                                                            <g id="Group_1342">
                                                                <g id="Group_1343">
                                                                    <g id="Group_1344">
                                                                        <g id="Group_1345">
                                                                            <g id="Group_1346">
                                                                                <g id="Group_1347">
                                                                                    <g id="Group_1348">
                                                                                        <g id="Group_1349">
                                                                                            <g id="Group_1350">
                                                                                                <g id="Group_1351">
                                                                                                    <g id="Group_1352">
                                                                                                        <path
                                                                                                            id="Vector_207"
                                                                                                            fill="#455A64"
                                                                                                            d="M81.05 218.063c-.002.106-.507.158-1.002.503-.504.332-.757.794-.85.752-.094-.019.053-.656.66-1.063.603-.412 1.214-.29 1.193-.192z"
                                                                                                        ></path>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="Group_1353">
                                                    <g id="Group_1354">
                                                        <g id="Group_1355">
                                                            <g id="Group_1356">
                                                                <g id="Group_1357">
                                                                    <g id="Group_1358">
                                                                        <g id="Group_1359">
                                                                            <g id="Group_1360">
                                                                                <g id="Group_1361">
                                                                                    <g id="Group_1362">
                                                                                        <g id="Group_1363">
                                                                                            <g id="Group_1364">
                                                                                                <g id="Group_1365">
                                                                                                    <g id="Group_1366">
                                                                                                        <path
                                                                                                            id="Vector_208"
                                                                                                            fill="#455A64"
                                                                                                            d="M83.23 218.991c.023.103-.413.287-.754.739-.353.441-.436.928-.537.924-.095.017-.169-.604.264-1.158.43-.557 1.024-.606 1.026-.505z"
                                                                                                        ></path>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="Group_1367">
                                                    <g id="Group_1368">
                                                        <g id="Group_1369">
                                                            <g id="Group_1370">
                                                                <g id="Group_1371">
                                                                    <g id="Group_1372">
                                                                        <g id="Group_1373">
                                                                            <g id="Group_1374">
                                                                                <g id="Group_1375">
                                                                                    <g id="Group_1376">
                                                                                        <g id="Group_1377">
                                                                                            <g id="Group_1378">
                                                                                                <g id="Group_1379">
                                                                                                    <g id="Group_1380">
                                                                                                        <path
                                                                                                            id="Vector_209"
                                                                                                            fill="#455A64"
                                                                                                            d="M84.48 221.713c-.092.024-.236-.506.02-1.09.252-.585.727-.818.77-.731.058.087-.247.402-.45.884-.213.478-.24.926-.34.937z"
                                                                                                        ></path>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="Group_1381">
                                    <g id="Group_1382">
                                        <g id="Group_1383">
                                            <g id="Group_1384">
                                                <path
                                                    id="Vector_210"
                                                    fill="#4A6361"
                                                    d="M65.442 117.852s5.256 23.554 9.697 50.715c3.652 22.338 5.27 46.523 5.27 46.523l-10.757.63s-6.554-27.702-11.658-47.618c-4.707-18.367-8.535-28.003-8.535-28.003l-2.155-24.237 18.138 1.99z"
                                                ></path>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_1385">
                                        <g id="Group_1386">
                                            <g id="Group_1387">
                                                <path
                                                    id="Vector_211"
                                                    fill="#4A6361"
                                                    d="M35.766 113.164s-9.435 12.754-8.64 26.067c.474 7.964 16.602 78.736 16.602 78.736l9.553-.325-3.053-75.18 3.235-24.611-17.697-4.687z"
                                                ></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g id="Group_1388">
                                <g id="Group_1389">
                                    <g id="Group_1390">
                                        <g id="Group_1391">
                                            <g id="Group_1392">
                                                <path
                                                    id="Vector_212"
                                                    fill="#263238"
                                                    d="M64.724 78.841c.01 0 .028.136.05.395l.085 1.137c.075.988.127 2.42.161 4.191.066 3.542-.1 8.437-.453 13.825-.353 5.39-.658 10.266-.726 13.802-.04 1.767-.053 3.198-.04 4.187v1.14c-.004.26-.011.397-.022.398-.01 0-.024-.136-.04-.396l-.058-1.139c-.05-.989-.07-2.423-.058-4.193.012-3.543.284-8.43.637-13.818.354-5.387.553-10.268.543-13.804a130.035 130.035 0 00-.063-4.186l-.027-1.14c-.003-.26 0-.398.011-.399z"
                                                ></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g id="Group_1393">
                                <g id="Group_1394">
                                    <g id="Group_1395">
                                        <g id="Group_1396">
                                            <g id="Group_1397">
                                                <g id="Group_1398">
                                                    <path
                                                        id="Vector_213"
                                                        fill="#FFBF9D"
                                                        d="M96.333 79.535l-5.753 2.114-6.283 8.506 1.716 2.974 1.697-1.594s6.47-1.204 6.933-2.126c.462-.923-.065-1.71-.065-1.71s.274-1.011.052-1.795c-.222-.784-.812-.894-.812-.894s.71-.293.407-1.198-.934-1.075-.934-1.075 3.86-1.837 4.202-2.357c.12-.182.072-.362-.04-.518a.962.962 0 00-1.12-.327z"
                                                    ></path>
                                                </g>
                                            </g>
                                        </g>
                                        <g id="Group_1399">
                                            <g id="Group_1400">
                                                <g id="Group_1401">
                                                    <g id="Group_1402">
                                                        <path
                                                            id="Vector_214"
                                                            fill="#FFBF9D"
                                                            d="M83.309 88.38l1.162-1.63 2.51-4.55 5.59-1.507s.941.44-.36 2.108c-1.3 1.668-3.122 1.33-3.122 1.33s.397 3.629-.59 5.249c-.987 1.62-3.653 1.762-3.653 1.762l-1.537-2.762z"
                                                        ></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <g id="Group_1403">
                                            <g id="Group_1404">
                                                <g id="Group_1405">
                                                    <g id="Group_1406">
                                                        <path
                                                            id="Vector_215"
                                                            fill="#FF9A6C"
                                                            d="M88.564 89.637c.011.007.105-.116.246-.36.14-.244.313-.618.46-1.108a6.236 6.236 0 00-.156-3.964l-.088.163.2-.032c.76-.124 1.5-.287 2.112-.617a2.793 2.793 0 001.274-1.323c.225-.495.24-.96.177-1.253-.062-.298-.16-.435-.168-.43-.02.01.049.16.08.444.033.282-.006.71-.234 1.163a2.684 2.684 0 01-1.224 1.202c-.583.3-1.298.454-2.054.577l-.199.033-.143.023.055.14c.555 1.426.465 2.875.214 3.828-.246.968-.591 1.491-.552 1.514z"
                                                        ></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <g id="Group_1407">
                                            <g id="Group_1408">
                                                <g id="Group_1409">
                                                    <g id="Group_1410">
                                                        <path
                                                            id="Vector_216"
                                                            fill="#FF9A6C"
                                                            d="M93.663 85.191c-.008-.016-.204.074-.572.206-.365.137-.902.31-1.559.566-.326.13-.687.281-1.043.517-.175.119-.37.273-.465.525-.097.252-.033.55.11.765.14.221.343.396.587.504.247.117.542.096.786.045.125-.023.247-.047.369-.064.136-.019.264-.053.391-.09.255-.075.504-.169.755-.265l.666-.262-.072-.225c-.492.177-.89.465-1.276.725l-.562.385c-.179.124-.376.216-.554.37-.177.146-.319.376-.316.624a.551.551 0 00.118.346.697.697 0 00.266.194 2.4 2.4 0 001.052.142c.642-.057 1.122-.258 1.447-.386.325-.136.496-.225.491-.239-.005-.015-.188.046-.519.156-.33.102-.814.274-1.428.308a2.318 2.318 0 01-.97-.157c-.157-.07-.268-.192-.261-.363a.618.618 0 01.25-.46c.147-.126.347-.219.537-.345l.57-.382c.383-.256.784-.535 1.224-.691l-.073-.226-.67.262a9.755 9.755 0 01-.737.26 2.556 2.556 0 01-.36.085c-.13.018-.256.044-.377.067-.244.05-.464.065-.665-.026a1.118 1.118 0 01-.494-.413c-.118-.178-.161-.39-.096-.57.063-.18.218-.32.38-.433.332-.23.68-.386.999-.526.642-.273 1.174-.472 1.532-.637.36-.16.546-.278.54-.292z"
                                                        ></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <g id="Group_1411">
                                            <g id="Group_1412">
                                                <g id="Group_1413">
                                                    <g id="Group_1414">
                                                        <path
                                                            id="Vector_217"
                                                            fill="#FF9A6C"
                                                            d="M93.23 82.782c-.008-.027-.479.084-1.188.416a9.004 9.004 0 00-1.854 1.173c-.21.18-.49.39-.537.785a.63.63 0 00.203.499c.125.12.284.185.433.213.3.053.571.019.83-.011a9.854 9.854 0 001.334-.29c.748-.21 1.183-.437 1.17-.468-.013-.03-.459.137-1.209.304a12.4 12.4 0 01-1.32.235c-.246.024-.514.047-.762 0-.24-.04-.468-.23-.45-.466.02-.238.24-.448.448-.617.214-.179.427-.342.635-.49a11.1 11.1 0 011.15-.714c.679-.365 1.128-.533 1.116-.57z"
                                                        ></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_1415">
                                        <g id="Group_1416">
                                            <g id="Group_1417">
                                                <path
                                                    id="Vector_218"
                                                    fill="#4AA09B"
                                                    d="M72.666 93.102L66.896 74.6c-.529-1.694-1.82-3.01-3.454-3.521l-3.525-.798 3.9 30.033.873 2.254c2.06 4.595 6.574 3.818 10.553 1.788.143-.073.282-.148.417-.226l11.642-9.38-3.851-6.938-10.785 5.29z"
                                                ></path>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_1418">
                                        <g id="Group_1419">
                                            <g id="Group_1420">
                                                <g id="Group_1421">
                                                    <path
                                                        id="Vector_219"
                                                        fill="#263238"
                                                        d="M71.381 98.383c.072.003.095-1.231.488-2.678.382-1.45.975-2.517.912-2.553-.043-.036-.74 1-1.132 2.49-.404 1.484-.323 2.748-.268 2.741z"
                                                    ></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g id="Group_1422">
                                    <g id="Group_1423">
                                        <g id="Group_1424">
                                            <g id="Group_1425">
                                                <g id="Group_1426">
                                                    <path
                                                        id="Vector_220"
                                                        fill="#FFEAAD"
                                                        d="M36.46 79.876l3.9 36.389 21.598 1.587 1.72-1.471-.598-27.478-7.758-19.57c-.923 1.75-2.69 2.343-4.822 2.18-1.452-.112-2.834-.674-4.016-1.525l-3.57-2.57-6.454 12.458z"
                                                    ></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_1427">
                                        <g id="Group_1428">
                                            <g id="Group_1429">
                                                <g id="Group_1430">
                                                    <g id="Group_1431">
                                                        <path
                                                            id="Vector_221"
                                                            fill="#4AA09B"
                                                            d="M42.916 67.419l-2.632 2.862-12.361 2.957 7.233 34.977-5.508 13.537a33.964 33.964 0 00-1.443 4.378l-3.393 13.278s11.686 3.181 14.693 1.446c3.007-1.736 6.681-18.623 7.795-22.632 1.114-4.01 2.784-15.925 2.005-22.273-.78-6.348-2.76-13.465-3.427-16.695-.669-3.23-2.962-11.835-2.962-11.835z"
                                                        ></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <g id="Group_1432">
                                            <path
                                                id="Vector_222"
                                                fill="#4AA09B"
                                                d="M30.155 103.974l2.78-10.81-5.012-19.925s-2.342 1.67-3.898 7.787c-1.629 6.405-7.074 24.04-6.74 28.16.334 4.12 22.109 11.597 22.109 11.597l.89-11.471-10.13-5.338z"
                                            ></path>
                                        </g>
                                        <g id="Group_1433">
                                            <g id="Group_1434">
                                                <g id="Group_1435">
                                                    <g id="Group_1436">
                                                        <path
                                                            id="Vector_223"
                                                            fill="#4AA09B"
                                                            d="M55.34 66.813l1.7 2.636 7.097 1.794s1.867 9.347 1.867 11.909c0 2.561-1.056 30.954-1.056 32.29 0 1.337 5.123 19.489 4.677 20.602-.445 1.114-5.79 1.894-5.79 1.894s-2.005-15.703-2.005-21.048c0-5.345-.223-18.486-1.336-24.945-1.114-6.46-5.124-22.496-5.124-22.496l-.03-2.636z"
                                                        ></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <g id="Group_1437">
                                            <g id="Group_1438">
                                                <g id="Group_1439">
                                                    <g id="Group_1440">
                                                        <g id="Group_1441">
                                                            <path
                                                                id="Vector_224"
                                                                fill="#263238"
                                                                d="M61.95 119.875a.485.485 0 01-.01-.134l-.001-.39.021-1.5.126-5.514c.108-4.655.297-11.087.412-18.19.008-.887.021-1.764.013-2.628.005-.855-.09-1.702-.154-2.54-.134-1.67-.267-3.28-.423-4.81-.156-1.53-.33-2.98-.56-4.325-.227-1.344-.619-2.551-1.039-3.634-.849-2.16-1.726-3.833-2.333-4.982l-.717-1.316-.187-.342a.468.468 0 01-.056-.12.443.443 0 01.081.105l.212.328c.181.291.443.725.764 1.292.639 1.135 1.545 2.795 2.424 4.961.434 1.082.845 2.31 1.083 3.667.242 1.353.426 2.807.589 4.34.163 1.533.302 3.144.44 4.814.065.833.163 1.683.16 2.56.008.868-.003 1.746-.012 2.635-.116 7.107-.336 13.537-.496 18.191l-.216 5.512-.074 1.498-.027.389a.492.492 0 01-.02.133z"
                                                            ></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <g id="Group_1442">
                                            <g id="Group_1443">
                                                <g id="Group_1444">
                                                    <g id="Group_1445">
                                                        <g id="Group_1446">
                                                            <path
                                                                id="Vector_225"
                                                                fill="#263238"
                                                                d="M48.749 109.186c-.01.001-.033-.137-.063-.401a27.35 27.35 0 01-.098-1.154 52.01 52.01 0 01-.098-4.26c.015-.9.054-1.886.125-2.942.045-1.038-.155-2.151-.418-3.303-.581-2.3-1.505-4.76-2.561-7.3-1.072-2.54-2.182-4.925-3.211-7.086-.5-1.085-1.044-2.093-1.371-3.119-.33-1.016-.544-1.983-.727-2.866a56.27 56.27 0 01-.678-4.21c-.06-.5-.102-.888-.125-1.152-.024-.264-.03-.403-.02-.404.01-.001.035.136.077.398l.178 1.144c.16.992.385 2.43.766 4.186.193.877.418 1.837.753 2.835.327.996.877 2 1.385 3.084 1.042 2.157 2.162 4.542 3.236 7.088 1.06 2.55 1.98 5.02 2.554 7.345.257 1.168.454 2.297.397 3.377a63.387 63.387 0 00-.159 2.93 67.048 67.048 0 00.007 4.25l.045 1.155c.011.265.015.404.006.405z"
                                                            ></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <g id="Group_1447">
                                            <g id="Group_1448">
                                                <g id="Group_1449">
                                                    <g id="Group_1450">
                                                        <path
                                                            id="Vector_226"
                                                            fill="#263238"
                                                            d="M80.691 108.309l-6.08 8.217c-.252.342-.679.509-1.097.43l-26.299-4.97a1.109 1.109 0 01-.848-1.432l2.837-8.734c.172-.529.71-.849 1.257-.747l29.541 5.486a1.109 1.109 0 01.69 1.75z"
                                                        ></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <g id="Group_1451">
                                            <g id="Group_1452">
                                                <g id="Group_1453">
                                                    <g id="Group_1454">
                                                        <g id="Group_1455">
                                                            <path
                                                                id="Vector_227"
                                                                fill="#263238"
                                                                d="M24.025 104.692c-.022-.022.273-.353.884-.714a5.456 5.456 0 012.645-.732 5.447 5.447 0 012.665.66c.62.344.924.666.903.69-.029.037-.372-.225-.993-.51a6.035 6.035 0 00-2.571-.555 6.028 6.028 0 00-2.555.625c-.612.302-.948.573-.978.536z"
                                                            ></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <g id="Group_1456" opacity="0.3">
                                            <g id="Group_1457">
                                                <g id="Group_1458">
                                                    <g id="Group_1459">
                                                        <path
                                                            id="Vector_228"
                                                            fill="#000"
                                                            d="M30.248 120.279l10.39 3.821 1.084-4.311 4.92.994.664-2.492-7.723-1.083-.077 3.575-8.073-3.047-1.184 2.543z"
                                                        ></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <g id="Group_1460">
                                            <g id="Group_1461">
                                                <g id="Group_1462">
                                                    <g id="Group_1463">
                                                        <g id="Group_1464">
                                                            <path
                                                                id="Vector_229"
                                                                fill="#FFBF9D"
                                                                d="M40.212 110.23s2.078-1.756 2.721-2.152c.644-.396 2.821-1.831 3.811-1.683l.99.149-.891 2.623s7.968-1.188 9.057.099c1.089 1.287-1.188 1.534-2.376 1.683-1.188.148-5.004.792-5.004.792s9.953-1.089 10.646 0c.693 1.089-.966 1.336-.966 1.336l-6.593 2.623-.755.297-.757.99s-2.707 1.88-3.549 1.633c-.84-.247-6.966-1.411-6.966-1.411l.632-6.979z"
                                                            ></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="Group_1465">
                                                <g id="Group_1466">
                                                    <g id="Group_1467">
                                                        <g id="Group_1468">
                                                            <g id="Group_1469">
                                                                <path
                                                                    id="Vector_230"
                                                                    fill="#FF9A6C"
                                                                    d="M47.136 114.728c-.01-.035.577-.229 1.544-.47a38.143 38.143 0 013.824-.733 37.936 37.936 0 013.878-.345c.996-.034 1.613-.008 1.614.028 0 .042-.613.088-1.602.173-.989.084-2.352.217-3.85.426a72.976 72.976 0 00-3.82.648c-.975.19-1.578.314-1.588.273z"
                                                                ></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="Group_1470">
                                                <g id="Group_1471">
                                                    <g id="Group_1472">
                                                        <g id="Group_1473">
                                                            <g id="Group_1474">
                                                                <path
                                                                    id="Vector_231"
                                                                    fill="#FF9A6C"
                                                                    d="M48.037 116.646c-.02-.077.619-.308 1.426-.518.807-.209 1.478-.318 1.498-.242.02.077-.619.308-1.426.518-.807.209-1.478.318-1.498.242z"
                                                                ></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <g id="Group_1475">
                                            <g id="Group_1476">
                                                <g id="Group_1477">
                                                    <g id="Group_1478">
                                                        <g id="Group_1479">
                                                            <path
                                                                id="Vector_232"
                                                                fill="#263238"
                                                                d="M65.824 79.254c.01 0 .026.125.047.365l.078 1.05c.07.913.118 2.236.15 3.873.06 3.272-.093 7.795-.42 12.773-.326 4.98-.608 9.486-.67 12.753a118.136 118.136 0 00-.038 3.869v1.052c-.004.241-.011.368-.02.368-.01 0-.023-.126-.038-.366l-.053-1.052c-.046-.914-.064-2.239-.053-3.874.011-3.274.261-7.789.587-12.768.327-4.977.511-9.487.502-12.754a119.447 119.447 0 00-.058-3.869l-.025-1.052c-.002-.24.001-.368.011-.368z"
                                                            ></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <g id="Group_1480">
                                            <g id="Group_1481">
                                                <g id="Group_1482">
                                                    <g id="Group_1483">
                                                        <g id="Group_1484">
                                                            <path
                                                                id="Vector_233"
                                                                fill="#263238"
                                                                d="M33.728 92.027c-.02 0-.036-.262-.047-.733a38.148 38.148 0 00-.094-1.99 34.892 34.892 0 00-1.075-6.464 34.843 34.843 0 00-2.295-6.139 38.177 38.177 0 00-.912-1.77c-.225-.415-.341-.648-.324-.66.016-.01.165.205.42.603.26.395.603.988.996 1.737.79 1.495 1.719 3.656 2.39 6.156.665 2.5.938 4.836 1 6.526.033.845.032 1.53.004 2.002-.022.472-.044.732-.063.732z"
                                                            ></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </>
    )
}

export default Transparency