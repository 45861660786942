import React, { useEffect, useRef, useState } from 'react'
import styles from './Header.module.css'
import logo from '../../images/logos/logo.svg'
import { Link, NavLink, useLocation } from 'react-router-dom'
import Hamburger from 'hamburger-react'
import { IoChevronDownOutline, IoChevronUpOutline, IoClose } from "react-icons/io5";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Partner from '../FORMS/Partner/Partner'
import Consumer from '../FORMS/Consumer/Consumer'
import Employee from '../FORMS/Employee/Employee'
import { scrollToTop } from '../../reusable/scrollToTop/ScrollToTopButton'
import { InputLabel, MenuItem, Select } from '@mui/material'

const styleMUIModal = {
    position: "absolute",
    top: "0%",
    right: "0%",
    width: '40%',
    height: '100%',
    bgcolor: "white",
    // border: "0px solid #000",
    boxShadow: 24,
    outline: 0,
    p: 4,
};

const styleMUIModal1 = {
    position: "absolute",
    top: "0%",
    right: "0%",
    width: '80%',
    height: '100vh',
    bgcolor: `var(--tan_teal)`,
    boxShadow: 24,
    outline: 0,
    p: 4,
};

const styleMUIModal2 = {
    position: "absolute",
    top: "0%",
    right: "0%",
    width: '50%',
    height: '100vh',
    bgcolor: `var(--tan_teal)`,
    boxShadow: 24,
    outline: 0,
    p: 2,
    overflowY: 'auto'
}

const styleMUIModalMobile = {
    width: "100%",
    p: "0",
}

const Header = () => {
    const [activeLink, setActiveLink] = useState('');
    const [isDropdownOpen, setDropdownOpen] = useState(true);
    const [isHovered, setHovered] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [modalType, setModalType] = useState(null);
    const [open, setOpen] = useState(false);
    const [selectedValue, setSelectedValue] = React.useState("");
    const [openMUI, setOpenMUI] = useState(false);
    const [openPartnerModal, setOpenPartnerModal] = useState(false);
    const [openConsumerModal, setOpenConsumerModal] = useState(false);
    const [openEmployeeModal, setOpenEmployeeModal] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const [showPopup, setShowPopup] = useState(true);

    const popup_login = localStorage.getItem('Authorization');
    const popupClosed = localStorage.getItem('popupClosed');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const handleToggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handleLinkClick = (link) => {
        setActiveLink(link);
    };
    const toggleMobileMenu = () => {
        setOpen(!open);
    };

    const handleMouseEnter = () => {
        setHovered(true);
    };
    const handleMouseLeave = () => {
        setHovered(false);
    };
    const handleOptionClick = (option) => {
        setActiveLink(option.value);
        setHovered(false);
    };

    const handleOpen = () => setOpenMUI(true);
    const handleClose = () => setOpenPartnerModal(false);
    const handleClose2 = () => {
        setOpenConsumerModal(false);
    }

    const handleMUIModal = (option) => {
        if (option.value === 'partner') {
            setOpenPartnerModal(true);
            window.history.pushState(null, null, '/partner-form')
        } else if (option.value === 'consumer') {
            setOpenConsumerModal(true);
            window.history.pushState(null, null, '/consumer-form')
        } else if (option.value === 'employee') {
            setOpenEmployeeModal(true);
        }
    }

    const handleToggleModal = () => {
        setOpen(!open);
    };

    const handleCloseModals = () => {
        setOpenPartnerModal(false);
        setOpenConsumerModal(false);
        setOpenEmployeeModal(false);
    };

    useEffect(() => {
        if (window.location.pathname === '/partner-form') {
            setOpenPartnerModal(true);
        }
        else if (window.location.pathname === '/consumer-form') {
            setOpenConsumerModal(true);
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('Authorization');
        localStorage.setItem('popupClosed', 'true');
        setHovered(false);
        setTimeout(() => {
            localStorage.removeItem('popupClosed');
        }, 30 * 60 * 1000)
    };

    useEffect(() => {
        if (popup_login?.length > 100 && popup_login !== undefined) {
            setIsLogin(true);
        }
        else if (popup_login == undefined) {
            setIsLogin(false);
        }
        else {
            setIsLogin(false);
        }
    }, [popup_login]);


    // FOR REMOVING THE TOKEN AFTER 30 MIN [USEEFFECT && USEREF]
    // LOGOUT AFTER 30 MIN
    const consumertoken = localStorage.getItem('Authorization');
    const timeoutRef = useRef(null);

    useEffect(() => {
        if (consumertoken) {
            const removeAuthorizationToken = () => {
                localStorage.removeItem('Authorization');
                localStorage.removeItem('countdownStart');
            };

            const countdown = (remainingTime) => {
                if (remainingTime <= 0) {
                    removeAuthorizationToken();
                } else {
                    timeoutRef.current = setTimeout(() => countdown(remainingTime - 1), 1000);
                }
            };

            const storedStartTime = localStorage.getItem('countdownStart');
            if (storedStartTime) {
                const startTime = parseInt(storedStartTime, 10);
                const currentTime = Date.now();
                const elapsedTime = Math.floor((currentTime - startTime) / 1000);
                const remainingTime = Math.max(1800 - elapsedTime, 0);
                countdown(remainingTime);
            } else if (consumertoken) {
                localStorage.setItem('countdownStart', Date.now().toString());
                countdown(1800);
            }

            return () => {
                clearTimeout(timeoutRef.current);
            };
        }
    }, []);

    const location = useLocation();
    const headerClass = location.pathname === '/' ? styles.homeHeader : styles.header;

    return (
        <>
            {/* web menu */}
            <div className={headerClass}>
                <div className={styles.left}>
                    <NavLink
                        to="/"
                        onClick={scrollToTop}
                    >
                        <img src={logo} alt="" />
                    </NavLink>
                </div>
                <div className={styles.right}>
                    <div className={styles.links}>
                        <NavLink
                            to="/used-car-loan"
                            onClick={scrollToTop}
                        >
                            <div className={`${styles.link} ${activeLink === 'used_car_loan' && styles.active}`}>
                                Used Car Loan
                            </div>
                        </NavLink>
                        <NavLink
                            to="/car_services"
                            onClick={scrollToTop}
                        >
                            <div className={`${styles.link} ${activeLink === 'car_services' && styles.active}`}>
                                Car Services
                            </div>
                        </NavLink>
                        <NavLink
                            to="/buy-sell"
                            onClick={scrollToTop}
                        >
                            <div className={`${styles.link} ${activeLink === 'buy-sell' && styles.active}`}>
                                Buy & Sell Car
                            </div>
                        </NavLink>
                        <NavLink
                            to="/calculator"
                            onClick={scrollToTop}
                        >
                            <div className={`${styles.link} ${activeLink === 'calculator' && styles.active}`}>
                                Calculators
                            </div>
                        </NavLink>


                        {
                            !isLogin ? (
                                <>
                                    <div
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <div className={styles.login_button}>
                                            Login/Signup  {isHovered ? <IoChevronUpOutline /> : <IoChevronDownOutline />}
                                        </div>
                                        {isHovered && (
                                            <div className={styles.dropdown_content}>
                                                <Button
                                                    classes={{ root: styles.dropdown_option }}
                                                    onClick={() => handleMUIModal({ value: 'consumer' })}
                                                // onClick={() => handleMUIModal({ value: 'partner' })}
                                                >
                                                    Partner
                                                </Button>
                                                <Button
                                                    classes={{ root: styles.dropdown_option }}
                                                    onClick={() => handleMUIModal({ value: 'consumer' })}
                                                >
                                                    Consumer
                                                </Button>
                                                {/* <Button
                                        classes={{ root: styles.dropdown_option }}
                                        onClick={() => handleMUIModal({ value: 'employee' })}
                                    >
                                        Employee
                                    </Button>
                                    <Button
                                        classes={{ root: styles.dropdown_option }}
                                    >
                                        <Link className={styles.login_button2} to='https://crm.nxfin.in/'>
                                            Employee
                                        </Link>
                                    </Button> */}
                                            </div>
                                        )}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <div className={styles.login_button}>
                                            Hello User{" "}
                                            {isHovered ? <IoChevronUpOutline /> : <IoChevronDownOutline />}
                                        </div>
                                        {isHovered && (
                                            <div className={styles.dropdown_content}>
                                                <Button
                                                    classes={{ root: styles.dropdown_option }}
                                                    onClick={() => window.location.pathname = '/consumer-dashboard'}
                                                >
                                                    Dashboard
                                                </Button>
                                                <Button
                                                    classes={{ root: styles.dropdown_option }}
                                                    onClick={handleLogout}
                                                >
                                                    Logout
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )
                        }

                    </div>
                </div>
            </div>

            {/* mobile menu*/}
            <div className={styles.mobile_container} >
                <div className={styles.top}>
                    <div className={styles.left}>
                        <NavLink
                            to="/"
                            onClick={scrollToTop}
                        >
                            <img src={logo} alt="" />
                        </NavLink>
                    </div>
                    <button onClick={toggleMobileMenu}>
                        <div className={styles.right_mobile}>
                            <Hamburger
                                style={{ zIndex: "99999" }}
                                toggled={open}
                                toggle={setOpen}
                            />
                        </div>
                    </button>
                </div>
                {
                    open && (
                        <Modal
                            open={open}
                            onClose={handleToggleModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{ zIndex: 999 }}
                        >
                            <Box
                                sx={{ ...styleMUIModal1, ...(window.innerHeight <= 500 && styleMUIModal2) }}>
                                <button
                                    onClick={handleToggleModal}
                                    className={styles.mui_close_button}
                                >
                                    <IoClose />
                                </button>
                                <div
                                    className={styles.bottom}
                                    onClick={toggleMobileMenu}
                                >
                                    <div className={styles.mobile_links}>
                                        <NavLink
                                            to="/about"
                                            onClick={scrollToTop}
                                        >
                                            <div className={`${styles.mobile_link} ${activeLink === 'about' && styles.active}`}>
                                                About Us
                                            </div>
                                        </NavLink>
                                        <NavLink
                                            to="/used-car-loan"
                                            onClick={scrollToTop}
                                        >
                                            <div className={`${styles.mobile_link} ${activeLink === 'used_car_loan' && styles.active}`}>

                                                Used Car Loan
                                            </div>
                                        </NavLink>
                                        <NavLink
                                            to="/car_services"
                                            onClick={scrollToTop}
                                        >
                                            <div className={`${styles.mobile_link} ${activeLink === 'car_services' && styles.active}`}>
                                                Car Services
                                            </div>
                                        </NavLink>

                                        <NavLink
                                            to="/partner"
                                            onClick={scrollToTop}
                                        >
                                            <div className={`${styles.mobile_link} ${activeLink === 'buy-sell' && styles.active}`}>
                                                Partners
                                            </div>
                                        </NavLink>
                                        <NavLink
                                            to="/buy-sell"
                                            onClick={scrollToTop}
                                        >
                                            <div className={`${styles.mobile_link} ${activeLink === 'buy-sell' && styles.active}`}>
                                                Buy & Sell Car
                                            </div>
                                        </NavLink>
                                        <NavLink
                                            to="/calculator"
                                            onClick={scrollToTop}
                                        >
                                            <div className={`${styles.mobile_link} ${activeLink === 'calculator' && styles.active}`}>
                                                Calculators
                                            </div>
                                        </NavLink>
                                        <NavLink
                                            to="/blogs-of-nxcar"
                                            onClick={scrollToTop}
                                        >
                                            <div className={`${styles.mobile_link} ${activeLink === 'blogs' && styles.active}`}>
                                                Blogs
                                            </div>
                                        </NavLink>
                                        <NavLink
                                            to="/contact_us"
                                            onClick={scrollToTop}
                                        >
                                            <div className={`${styles.mobile_link} ${activeLink === 'contact_us' && styles.active}`}>
                                                Contact Us
                                            </div>
                                        </NavLink>
                                        {/* <NavLink
                                            to="/partner"
                                            onClick={scrollToTop}
                                        >
                                            <div className={`${styles.mobile_link} ${activeLink === 'partner' && styles.active}`}>
                                                Partners 1
                                            </div>
                                        </NavLink> */}

                                        {
                                            isLogin ? (<>
                                                <Select
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    value={selectedValue}
                                                    className={styles.login_button}
                                                >
                                                    <MenuItem
                                                        classes={{ root: styles.dropdown_option }}
                                                        style={{ display: 'none' }}
                                                        disabled
                                                        value=""
                                                    >
                                                        Hello User
                                                    </MenuItem>
                                                    <MenuItem
                                                        classes={{ root: styles.dropdown_option }}
                                                        onClick={() => window.location.pathname = '/consumer-dashboard'}
                                                    >
                                                        Dashboard
                                                    </MenuItem>
                                                    <MenuItem
                                                        classes={{ root: styles.dropdown_option }}
                                                        onClick={() => localStorage.removeItem('Authorization')}
                                                    >
                                                        Logout
                                                    </MenuItem>
                                                    <MenuItem
                                                        classes={{ root: styles.dropdown_option }}
                                                        style={{ display: 'none' }}
                                                    >
                                                        Hello User
                                                    </MenuItem>
                                                </Select>
                                            </>) : (
                                                <>
                                                    <Select
                                                        onChange={handleChange}
                                                        displayEmpty
                                                        value={selectedValue}
                                                        className={styles.login_button}
                                                    >
                                                        <MenuItem
                                                            classes={{ root: styles.dropdown_option }}
                                                            style={{ display: 'none' }}
                                                            disabled
                                                            value=""
                                                        >
                                                            Login/Signup
                                                        </MenuItem>
                                                        <MenuItem
                                                            classes={{ root: styles.dropdown_option }}
                                                            // onClick={() => handleMUIModal({ value: 'partner' })}
                                                            onClick={() => handleMUIModal({ value: 'consumer' })}
                                                        >
                                                            Partner
                                                        </MenuItem>
                                                        <MenuItem
                                                            classes={{ root: styles.dropdown_option }}
                                                            onClick={() => handleMUIModal({ value: 'consumer' })}
                                                        >
                                                            Consumer
                                                        </MenuItem>
                                                        <MenuItem
                                                            classes={{ root: styles.dropdown_option }}
                                                            style={{ display: 'none' }}
                                                        >
                                                            Login/Signup
                                                        </MenuItem>
                                                    </Select>
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            </Box>
                        </Modal>
                    )
                }
            </div>

            {/* MODALS -- EMPLOYEE, CONSUMER, PARTNER */}
            <>
                {/* MODAL */}
                <Modal
                    open={openPartnerModal}
                    onClose={() => setOpenPartnerModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box
                        sx={{ ...styleMUIModal, ...(window.innerWidth <= 600 && styleMUIModalMobile) }}>
                        <Partner onClose={handleClose} />
                    </Box>
                </Modal>

                <Modal
                    open={openConsumerModal}
                    onClose={() => setOpenConsumerModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{ ...styleMUIModal, ...(window.innerWidth <= 600 && styleMUIModalMobile) }}>
                        <Consumer onClose={handleClose2} />
                    </Box>
                </Modal>

                <Modal
                    open={openEmployeeModal}
                    onClose={() => setOpenEmployeeModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{ ...styleMUIModal, ...(window.innerWidth <= 600 && styleMUIModalMobile) }}>
                        <Employee />
                    </Box>
                </Modal>
                {/* <MODAL  */}
            </>
        </>
    )
}

export default Header