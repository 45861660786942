import React, { useState } from 'react'
import styles from './SellCar.module.css';
import { IoClose, IoEyeOutline } from 'react-icons/io5';
import thankyou from '../../../../images/forms/sellcar/thank_you.svg'
import { Box, FormControl, InputLabel, MenuItem, Modal, Select, makeStyles, useMediaQuery } from '@material-ui/core';
import { MdDeleteOutline, MdKeyboardArrowDown, MdOutlineDeleteOutline } from "react-icons/md";
import { IoIosAdd } from "react-icons/io";
import { GoUpload } from 'react-icons/go';
import axios from 'axios';
import { IoCloseCircleSharp, IoCloseSharp } from "react-icons/io5";
import { pdfjs } from 'react-pdf';
import { Viewer, Worker } from '@react-pdf-viewer/core';
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString();

const useStyles = makeStyles((theme) => ({
    select: {
        color: 'black',
        outline: 'none',
        borderRadius: '8px',
        padding: '-13px',
        boxShadow: 'none',
        // backgroundColor: `white`,
        '&:focus': {
            // backgroundColor: `var(--light_tteal)`,
            color: 'white',
            border: '0',
            outline: 'none',
            '& .MuiOutlinedInput-notchedOutline': {
                border: '0',
                outline: 'none',
            },
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            backgroundColor: `transparent`,
            color: 'white',
            border: "0.01px solid var(--pale_white)",
        },

    },
    label: {
        fontSize: "18px",
        paddingLeft: "10px",
        color: `black`,
        '&.Mui-focused': {
            // color: 'white',
        },
    },
    option: {
        // backgroundColor: `var(--dark_green)`,
        color: 'black',
        margin: '-8px 0',
        padding: '16px 18px',
        width: '100%',
        fontSize: '18px',
        '&:focus': {
            // backgroundColor: `var(--dark_green)`,
            color: 'black',
            outline: 'none',
        },
        '&:hover': {
            // backgroundColor: `var(--green)`,
            color: 'black',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            outline: 'none',
        },
    },
    selectedOption: {
        fontSize: '18px',
        paddingLeft: "10px",
        border: 'none',
        outline: 'none',
        '&:hover': {
            // backgroundColor: `var(--dark_green)`,
            color: 'white',
        },
        '&:focus': {
            // backgroundColor: `var(--dark_green)`,
            color: 'white',
            outline: 'none',
        },

    },
    arrowIcon: {
        fill: `var(--pale_white)`,
        fontSize: '30px',
        marginRight: '24px',
        background: 'transparent',
    },
    filled: {
        color: 'black',
        backgroundColor: 'var(--light_teal)',
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            outline: 'none',
        },
    },
}));

const uploadMUIModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    boxShadow: 24,
    bgcolor: 'white',
    border: 'none',
    outline: 'none',
    borderRadius: '12px',
    p: 3,
};
const mobileUploadMUIModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    boxShadow: 24,
    bgcolor: `white`,
    border: 'none',
    outline: 'none',
    borderRadius: '12px',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '20px',
    paddingBottom: '20px',
};

const SellCar = ({ handleClose }) => {
    const [stage, setStage] = useState(1);
    const isMobile = useMediaQuery('(max-width: 940px)');
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    // const handleClose = () => setOpen(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [emptyFields, setEmptyFields] = useState([]);
    const [isFocused, setIsFocused] = useState(false);

    const handleInputFocus = () => {
        setIsFocused(true);
    };

    const handleInputBlur = () => {
        setIsFocused(false);
    };

    const isValidPincode = (pincode) => {
        const pincodeRegex = /^[1-9][0-9]{5}$/;
        return pincodeRegex.test(pincode);
    };

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const [fileNames, setFileNames] = useState({
        car_odometer: '',
        car_front: '',
        car_back: '',
        car_right: '',
        car_left: '',
    });

    const [formData, setFormData] = useState({
        full_name: '',
        mobile: '',
        email: '',
        pincode: '',
        district: '',
        city: '',

        vehicle_number: '',
        car_has_run: '',
        car_record: '',
        sole_owner: '',
        price_of_car: '',

        car_condition: '',
        consent: '1',
    });

    const [imagePreviews, setImagePreviews] = useState({
        car_odometer: null,
        car_front: null,
        car_back: null,
        car_right: null,
        car_left: null,
    });

    const clearAllPreviews = () => {
        setImagePreviews({
            car_odometer: null,
            car_front: null,
            car_back: null,
            car_right: null,
            car_left: null,
        });
        setFileNames({
            car_odometer: '',
            car_front: '',
            car_back: '',
            car_right: '',
            car_left: '',
        })
    };

    const [showMeterPreview, setShowMeterPreview] = useState(false);
    const [showLeftPreview, setShowLeftPreview] = useState(false);
    const [showRightPreview, setShowRightPreview] = useState(false);
    const [showFrontPreview, setShowFrontPreview] = useState(false);
    const [showBackPreview, setShowBackPreview] = useState(false);

    const validateStage1 = () => {
        // const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return (
            // emailPattern.test(formData.email) &&
            formData.full_name !== '' &&
            formData.pincode !== '' &&
            formData.district !== '' &&
            formData.mobile !== '' &&
            formData.city !== ''
        );
    };

    const validateStage2 = () => {
        return (
            formData.vehicle_number !== '' &&
            formData.car_has_run !== '' &&
            formData.car_record !== '' &&
            formData.price_of_car !== '' &&
            formData.sole_owner !== ''
        );
    };

    const validateStage3 = () => {
        return (
            formData.car_condition !== ''
        );
    };

    const handleNextStage = () => {
        const emptyFields = [];

        if (stage === 1 && !validateStage1()) {
            emptyFields.push("city", "district", "full_name", "pincode", "mobile");
        }

        if (stage === 2 && !validateStage2()) {
            emptyFields.push("vehicle_number", "car_has_run", "car_record", "sole_owner", "price_of_car");
        }

        if (stage === 3 && !validateStage3()) {
            emptyFields.push("car_condition");
        }

        setEmptyFields(emptyFields);

        if (emptyFields.length === 0) {
            setStage(stage + 1);
        }
    };

    const handleFetchDetails = async (e) => {
        try {
            const response = await axios.get(`/pincode?pincode=${formData.pincode}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response && response.data.district) {
                setFormData((prevData) => ({
                    ...prevData,
                    district: response.data.district,
                    city: response.data.state,
                }));
            } else {
                setFormData((prevData) => ({
                    ...prevData,
                    city: 'No Data',
                    district: 'No Data',
                }));
            }
        } catch (error) {
            console.log('error', error.message)
        }
    }


    const handleInputChange = (e, inputName) => {
        const newValue = e.target.files ? e.target.files[0] : e.target.value;

        setFormData((prevFormData) => ({
            ...prevFormData,
            [inputName]: newValue,
        }));
    };

    const handleSoleOwner = (fieldName, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [fieldName]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formDataWithFileNames = {
                ...formData,
                ...fileNames,
            };
            const response = await axios.post('/sell', formDataWithFileNames, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            // console.log("response", response);
            setIsFormSubmitted(true);
        } catch (error) {
            console.error('Error submitting form:', error.message);
        }
    };

    const handleFileUpload = async (e, fieldName) => {
        try {

            const file = e.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (readerEvent) => {
                    setImagePreviews((prevPreviews) => ({
                        ...prevPreviews,
                        [fieldName]: readerEvent.target.result,
                    }));
                };
            }

            const files = e.target.files[0]
            const formData = new FormData();
            formData.append('files', files);

            const response = await axios.post('/sellImageUpload', formData, {
                // headers: {
                //     'Content-Type': 'multipart/form-data',
                //     Authorization: `${token}`,
                // },
            })
            setFileNames((prevFileNames) => ({
                ...prevFileNames,
                [fieldName]: response.data.file_name,
            }));

        } catch (error) {
            console.error('File Upload Error -> ', error.message);
        }
    };

    const handleDelete = (fileType) => {
        setFileNames((prevFileNames) => ({
            ...prevFileNames,
            [fileType]: '',
        }));
        setImagePreviews((prevImagePreviews) => ({
            ...prevImagePreviews,
            [fileType]: null,
        }));
    };

    const handleClose2 = () => {
        setOpen(false);
    };

    function formatIndianNumber(number) {
        return number.replace(/\D/g, "").replace(/(\d+?)(?=(\d{2})+(\d)(?!\d))/g, "$1,");
    }

    return (
        <>
            <div className={styles.container}>
                {
                    isFormSubmitted ? (
                        <>
                            <div className={styles.thank_you_image}>
                                <img src={thankyou} alt="thank_you" />
                            </div>
                            <div
                                onClick={handleClose}
                                className={styles.mui_close_button}
                            >
                                <IoClose />
                            </div>
                        </>
                    ) : (
                        <div className={styles.form_stage}>
                            <form onSubmit={handleSubmit}>

                                {stage === 1 && (
                                    <>
                                        {/* WELCOME TEXT */}
                                        <div className={styles.heading_section}>
                                            <div className={styles.welcome_text}>
                                                <div className={styles.welcome_text1}>
                                                    Please share details of your car
                                                </div>
                                                <div className={styles.welcome_text2}>
                                                    Fill out your details
                                                </div>
                                            </div>
                                            <div
                                                onClick={handleClose}
                                                className={styles.mui_close_button}
                                            >
                                                <IoClose />
                                            </div>
                                        </div>

                                        <div className={styles.form}>
                                            <div className={styles.col}>
                                                <div className={styles.MUI_input}>
                                                    <input
                                                        className={formData.full_name.length > 0 ? styles.input_field1 : styles.input_field}
                                                        type="text"
                                                        value={formData.full_name}
                                                        onChange={(e) => handleInputChange(e, 'full_name')}
                                                        required
                                                    />
                                                    <div className={`${styles.float_label} ${formData.full_name && styles.labelActive}`}>
                                                        Enter Full Name <span style={{ color: `var(--red)` }}>*</span>
                                                    </div>
                                                </div>
                                                {emptyFields.includes("full_name") && formData.full_name === "" && (
                                                    <p className={styles.error}>Full Name is required</p>
                                                )}

                                                <div className={styles.MUI_input}>
                                                    <div className={`${styles.phoneNumberDisplay} ${formData.mobile && styles.phoneNumberActive}`}>
                                                        +91
                                                    </div>
                                                    <input
                                                        className={`${styles.input_field} ${formData.mobile.length > 0 ? styles.input_field2 : ''} ${isFocused ? styles.input_field2 : ''}`}
                                                        type="tel"
                                                        maxLength={10}
                                                        value={formData.mobile}
                                                        onChange={(e) => handleInputChange(e, 'mobile')}
                                                        required
                                                        onFocus={handleInputFocus}
                                                        onBlur={handleInputBlur}
                                                        style={{ padding: "12px 0px" }}
                                                    />
                                                    <div className={`${styles.float_label} ${formData.mobile && styles.labelActive}`}>
                                                        {isFocused ? "Enter your mobile number" : formData.mobile.length === 0 ? "+91 Enter your mobile number" : "Enter your mobile number"} <span style={{ color: `var(--red)` }}>*</span>
                                                    </div>
                                                </div>
                                                {emptyFields.includes("mobile") && (formData.mobile === 0 || formData.mobile === "") && (
                                                    <p className={styles.error}> Mobile is required</p>
                                                )}
                                                {formData.mobile && !/^[6789]\d{9}$/.test(formData.mobile) && (
                                                    <p className={styles.error}>
                                                        Please provide an Indian number
                                                    </p>
                                                )}

                                                <div className={styles.MUI_input}>
                                                    <input
                                                        className={formData.email.length > 0 ? styles.input_field1 : styles.input_field}
                                                        type="email"
                                                        value={formData.email}
                                                        onChange={(e) => handleInputChange(e, 'email')}
                                                        required
                                                    />
                                                    <div className={`${styles.float_label} ${formData.email && styles.labelActive}`}>
                                                        Enter Email
                                                    </div>
                                                </div>
                                                {/* {emptyFields.includes("email") && formData.email === "" && (
                                                    <p className={styles.error}>Email is required</p>
                                                )} */}
                                                {formData.email && !(emailRegex).test(formData.email) && (
                                                    <p className={styles.error}>
                                                        Please provide a valid email
                                                    </p>
                                                )}

                                                <div className={styles.MUI_input}>
                                                    <input
                                                        className={formData.pincode.length > 0 ? styles.input_field1 : styles.input_field}
                                                        type="tel"
                                                        maxLength={6}
                                                        minLength={6}
                                                        value={formData.pincode}
                                                        onChange={(e) => handleInputChange(e, 'pincode')}
                                                        onBlur={() => handleFetchDetails(formData.pincode)}
                                                        required
                                                    />
                                                    <div className={`${styles.float_label} ${formData.pincode && styles.labelActive}`}
                                                    >
                                                        Enter PIN Code <span style={{ color: `var(--red)` }}>*</span>
                                                    </div>
                                                </div>
                                                {emptyFields.includes("pincode") && formData.pincode === "" && (
                                                    <p className={styles.error}>Pincode is required</p>
                                                )}
                                                {formData.pincode !== '' && !isValidPincode(formData.pincode) && (
                                                    <p className={styles.error}>Invalid PIN code</p>
                                                )}



                                                <div className={styles.single_address}>
                                                    <div className={styles.single_address_error} style={{ width: '100%' }}>
                                                        <div className={styles.MUI_input} style={{ width: '100%' }}>
                                                            <input
                                                                className={formData.city.length > 0 ? styles.input_field1 : styles.input_field}
                                                                style={{ width: '95%' }}
                                                                type="text"
                                                                value={formData.city}
                                                                onChange={(e) => handleInputChange(e, 'city')}
                                                                required
                                                            />
                                                            <div className={`${styles.float_label} ${formData.city && styles.labelActive}`}
                                                            >
                                                                State <span style={{ color: `var(--red)` }}>*</span>
                                                            </div>
                                                        </div>
                                                        {emptyFields.includes("city") && formData.city === "" && (
                                                            <p className={styles.address_error}>State is required</p>
                                                        )}
                                                    </div>

                                                    <div className={styles.single_address_error} style={{ width: '100%' }}>
                                                        <div className={styles.MUI_input} style={{ width: '100%' }}>
                                                            <input
                                                                className={formData.district.length > 0 ? styles.input_field1 : styles.input_field}
                                                                style={{ width: '100%' }}
                                                                type="text"
                                                                value={formData.district}
                                                                onChange={(e) => handleInputChange(e, 'district')}
                                                                required
                                                            />
                                                            <div className={`${styles.float_label} ${formData.district && styles.labelActive}`}
                                                            >
                                                                City <span style={{ color: `var(--red)` }}>*</span>
                                                            </div>
                                                        </div>
                                                        {emptyFields.includes("district") && formData.district === "" && (
                                                            <p className={styles.address_error} >City is required</p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.final_button_container1}>
                                            <button
                                                className={styles.submit_button1}
                                                onClick={handleNextStage}
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </>
                                )}

                                {stage === 2 && (
                                    <>
                                        <div className={styles.heading_section}>
                                            <div className={styles.welcome_text}>
                                                <div className={styles.welcome_text1}>
                                                    Please share details of your car
                                                </div>
                                                <div className={styles.welcome_text2}>
                                                    Fill out your details
                                                </div>
                                            </div>
                                            <div
                                                onClick={handleClose}
                                                className={styles.mui_close_button}
                                            >
                                                <IoClose />
                                            </div>
                                        </div>
                                        <div className={styles.form}>
                                            <div className={styles.col}>
                                                <div className={styles.MUI_input}>
                                                    <input
                                                        className={formData.vehicle_number.length > 0 ? styles.input_field1 : styles.input_field}
                                                        type="text"
                                                        value={formData.vehicle_number}
                                                        onChange={(e) => handleInputChange(e, 'vehicle_number')}
                                                        pattern='^[A-Z]{2}[0-9]{1,2}(?:[A-Z])?(?:[A-Z]*)?[0-9]{4}$'
                                                        maxLength={10}
                                                        title='FORMAT DL AE 01 2321'
                                                        onInput={(e) => {
                                                            e.target.value = e.target.value.toUpperCase();
                                                        }}
                                                        required
                                                    />
                                                    <div className={`${styles.float_label} ${formData.vehicle_number && styles.labelActive}`}>
                                                        Enter Vehicle Number <span style={{ color: `var(--red)` }}>*</span>
                                                    </div>
                                                </div>
                                                {emptyFields.includes("vehicle_number") && formData.vehicle_number === "" && (
                                                    <p className={styles.error}>Vehicle Number is required</p>
                                                )}
                                                {formData.vehicle_number && !/^[A-Z]{2}[0-9]{1,2}(?:[A-Z])?(?:[A-Z]*)?[0-9]{4}$/.test(formData.vehicle_number) && (
                                                    <p className={styles.error}>
                                                        Please provide a valid Indian vehicle number
                                                    </p>
                                                )}

                                                <div className={styles.MUI_input}>
                                                    <input
                                                        className={formData.car_has_run.length > 0 ? styles.input_field1 : styles.input_field}
                                                        type="tel"
                                                        value={formData.car_has_run}
                                                        onChange={(e) => handleInputChange(e, 'car_has_run')}
                                                        required
                                                        maxLength={10}
                                                    />
                                                    <div className={`${styles.float_label} ${formData.car_has_run && styles.labelActive}`}>
                                                        How many kilometers car has run? <span style={{ color: `var(--red)` }}>*</span>
                                                    </div>
                                                </div>
                                                {emptyFields.includes("car_has_run") && formData.car_has_run === "" && (
                                                    <p className={styles.error}>Distance is required</p>
                                                )}

                                                <div className={styles.MUI_input}>
                                                    <div className={styles.input_field_boolean}>
                                                        <span>Do you have the car's service and maintenance records ?<span style={{ color: `var(--red)` }}>*</span></span>
                                                        <div className={styles.yes_no_container}>
                                                            <div
                                                                className={`${styles.yes_button} ${formData.car_record === 'yes' ? styles.selected : ''}`}
                                                                onClick={() => handleSoleOwner('car_record', 'yes')}
                                                            >
                                                                Yes
                                                            </div>
                                                            <div
                                                                className={`${styles.yes_button} ${formData.car_record === 'no' ? styles.selected : ''}`}
                                                                onClick={() => handleSoleOwner('car_record', 'no')}
                                                            >
                                                                No
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {emptyFields.includes("car_record") && formData.car_record === "" && (
                                                    <p className={styles.error}>Car Service is required</p>
                                                )}

                                                <div className={styles.MUI_input}>
                                                    <div className={styles.input_field_boolean}>
                                                        <span>Select Car Ownership <span style={{ color: `var(--red)` }}>*</span></span>
                                                        <div className={styles.yes_no_container}>
                                                            <div
                                                                className={`${styles.yes_button} ${formData.sole_owner === '1' ? styles.selected : ''}`}
                                                                onClick={() => handleSoleOwner('sole_owner', '1')}
                                                            >
                                                                1
                                                            </div>
                                                            <div
                                                                className={`${styles.yes_button} ${formData.sole_owner === '2' ? styles.selected : ''}`}
                                                                onClick={() => handleSoleOwner('sole_owner', '2')}
                                                            >
                                                                2
                                                            </div>
                                                            <div
                                                                className={`${styles.yes_button} ${formData.sole_owner === '3' ? styles.selected : ''}`}
                                                                onClick={() => handleSoleOwner('sole_owner', '3')}
                                                            >
                                                                3
                                                            </div>
                                                            <div
                                                                className={`${styles.yes_button} ${formData.sole_owner === '4' ? styles.selected : ''}`}
                                                                onClick={() => handleSoleOwner('sole_owner', '4')}
                                                            >
                                                                4
                                                            </div>
                                                            <div
                                                                className={`${styles.yes_button} ${formData.sole_owner === '4+' ? styles.selected : ''}`}
                                                                onClick={() => handleSoleOwner('sole_owner', '4+')}
                                                            >
                                                                4+
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {emptyFields.includes("sole_owner") && formData.sole_owner === "" && (
                                                    <p className={styles.error}>Sole Owner is required</p>
                                                )}

                                                <div className={styles.MUI_input}>
                                                    <input
                                                        required
                                                        className={formData.price_of_car.length > 0 ? styles.input_field1 : styles.input_field}
                                                        type="tel"
                                                        // value={formData.price_of_car}
                                                        value={formData.price_of_car ? formatIndianNumber(formData.price_of_car) : ""}
                                                        onChange={(e) => handleInputChange(e, 'price_of_car')}
                                                        maxLength="10"
                                                    />
                                                    <div className={`${styles.float_label} ${formData.price_of_car && styles.labelActive}`}>

                                                        {isMobile ?
                                                            (<>Expected selling price of the car <span style={{ color: `var(--red)` }}>*</span></>)
                                                            :
                                                            (<><span> What is your expected selling price for the car ?</span> <span style={{ color: `var(--red)` }}> *</span></>)}
                                                    </div>
                                                </div>
                                                {emptyFields.includes("price_of_car") && (formData.price_of_car === 0 || formData.price_of_car === "") && (
                                                    <p className={styles.error}>Selling Price is required</p>
                                                )}

                                            </div>
                                        </div>

                                        <div className={styles.final_button_container1}>
                                            <button
                                                className={styles.submit_button1}
                                                onClick={handleNextStage}
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </>
                                )}

                                {stage === 3 && (
                                    <>
                                        <div className={styles.heading_section}>
                                            <div className={styles.welcome_text}>
                                                <div className={styles.welcome_text1}>
                                                    Please share details of your car
                                                </div>
                                                <div className={styles.welcome_text2}>
                                                    Fill out your details
                                                </div>
                                            </div>
                                            <div
                                                onClick={handleClose}
                                                className={styles.mui_close_button}
                                            >
                                                <IoClose />
                                            </div>
                                        </div>
                                        <div className={styles.form}>
                                            <div className={styles.col}>

                                                <div className={styles.MUI_input}
                                                    onClick={handleOpen}
                                                >
                                                    <div className={styles.upload_images_input}>
                                                        Upload Images
                                                        <IoIosAdd size={24} />
                                                    </div>
                                                </div>


                                                <div className={styles.MUI_input}>
                                                    <div className={styles.option_container}>
                                                        <FormControl
                                                            variant="outlined"
                                                            fullWidth
                                                        >
                                                            <InputLabel
                                                                className={classes.label}
                                                                id="demo-simple-select-outlined-label"
                                                            >
                                                                Describe the overall conditions ? <span style={{ color: `var(--red)` }}>*</span>
                                                            </InputLabel>
                                                            <Select
                                                                sx={{ "& .MuiSvgIcon-root": { color: "red" } }}
                                                                labelId="demo-simple-select-outlined-label"
                                                                id="demo-simple-select-outlined"
                                                                value={formData.car_condition}
                                                                onChange={(e) => handleInputChange(e, 'car_condition')}
                                                                label="Describe the overall conditions ?"
                                                                renderValue={(selected) => <span className={classes.selectedOption}>{selected}</span>}
                                                                IconComponent={() => (
                                                                    <MdKeyboardArrowDown className={classes.arrowIcon} />
                                                                )}
                                                                className={`${classes.select} ${formData.car_condition.length > 0 ? classes.filled : ''}`}
                                                                MenuProps={{
                                                                    anchorOrigin: {
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left',
                                                                    },
                                                                    transformOrigin: {
                                                                        vertical: 'top',
                                                                        horizontal: 'left',
                                                                    },
                                                                    getContentAnchorEl: null,
                                                                    autowidth: "false",
                                                                }}
                                                                menulistprops={{
                                                                    autoFocus: true,
                                                                }}
                                                                onFocus={() => setIsFocused(true)}
                                                                onBlur={() => setIsFocused(false)}
                                                            >
                                                                <MenuItem
                                                                    value="Excellent"
                                                                    className={`${classes.option}`}>
                                                                    Excellent
                                                                </MenuItem>
                                                                <MenuItem
                                                                    value="Good"
                                                                    className={`${classes.option}`}>
                                                                    Good
                                                                </MenuItem>
                                                                <MenuItem
                                                                    value="Fair"
                                                                    className={`${classes.option}`}>
                                                                    Fair
                                                                </MenuItem>
                                                                <MenuItem
                                                                    value="Poor"
                                                                    className={`${classes.option}`}>
                                                                    Poor
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </div>
                                                {emptyFields.includes("car_condition") && formData.car_condition === "" && (
                                                    <p className={styles.error}>Car Condition is required</p>
                                                )}

                                                <div className={styles.consent_box}>
                                                    <div className={styles.consent_left}>
                                                        <input
                                                            type="checkbox"
                                                            required
                                                        />
                                                    </div>
                                                    <div className={styles.consent_right}>
                                                        By completing and submitting this form, you acknowledge that you have read and agreed to our terms and conditions, as well as our privacy policy. Your submission constitutes consent for our team to contact you for further discussion regarding the transaction.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={styles.final_button_container1}>
                                            <button
                                                className={styles.submit_button1}
                                                type='submit'
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </>
                                )}
                            </form>
                        </div>
                    )
                }
            </div>
            {
                <>
                    <Modal
                        open={open}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={isMobile ? mobileUploadMUIModal : uploadMUIModal}>
                            <div className={styles.modal_container}>
                                <div className={styles.modal_heading}>
                                    <div>Upload Images</div>
                                </div>


                                {/* Odometer */}
                                <div
                                    className={fileNames.car_front ? styles.my_upload_label : styles.none}
                                >
                                    Odometer
                                </div>
                                <input
                                    type="file"
                                    id='customFileInput5'
                                    accept=".pdf, .jpg, .jpeg"
                                    hidden
                                    onChange={(e) => handleFileUpload(e, 'car_odometer')}
                                />
                                <div className={styles.file_flex}>
                                    <label
                                        htmlFor="customFileInput5"
                                        className={fileNames.car_odometer ? styles.customFileInput1_upload : styles.customFileInput1}
                                    >
                                        <div className={styles.text}>
                                            {
                                                fileNames.car_odometer != "" ?
                                                    (
                                                        fileNames.car_odometer + '' + ''
                                                    ) :
                                                    (
                                                        'Odometer'
                                                    )
                                            }
                                        </div>
                                        <div className={styles.image}>
                                            {
                                                fileNames.car_odometer == "" ? <GoUpload /> : ""
                                            }
                                        </div>
                                    </label>
                                    <div className={styles.image}>
                                        {
                                            fileNames.car_odometer !== "" ?
                                                (
                                                    imagePreviews.car_odometer && (
                                                        <div className={styles.eye_icon_container}>
                                                            <div
                                                                className={styles.icon}
                                                                onClick={() => setShowMeterPreview(!showMeterPreview)}>
                                                                <IoEyeOutline size={24} />
                                                            </div>
                                                            <div
                                                                className={styles.icon}
                                                                onClick={() => handleDelete('car_odometer')}
                                                            >
                                                                <MdOutlineDeleteOutline size={24} />
                                                            </div>
                                                        </div>
                                                    )
                                                )
                                                :
                                                (
                                                    null
                                                )
                                        }
                                    </div>
                                    <Modal
                                        open={showMeterPreview}
                                        onClose={() => setShowMeterPreview(false)}
                                        aria-labelledby="upload-address-preview-modal"
                                        aria-describedby="upload-address-preview-description"
                                    >
                                        <div className={styles.preview}>
                                            <button
                                                className={styles.close_button_for_preview}
                                                onClick={() => setShowMeterPreview(false)}
                                            >
                                                <IoCloseCircleSharp />
                                            </button>
                                            {fileNames?.car_odometer &&
                                                fileNames?.car_odometer.toLowerCase().endsWith('.pdf') ?
                                                (
                                                    <div className={styles.modalImage}>
                                                        <Worker
                                                            workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
                                                        >
                                                            <Viewer
                                                                fileUrl={imagePreviews?.car_odometer}
                                                            >
                                                            </Viewer>
                                                        </Worker>
                                                    </div>
                                                ) : (
                                                    <img
                                                        className={styles.modalImage}
                                                        src={imagePreviews?.car_odometer}
                                                        alt="Upload Preview"
                                                    />
                                                )}
                                        </div>
                                    </Modal>
                                </div>


                                {/* Front of car */}
                                <div
                                    className={fileNames.car_front ? styles.my_upload_label : styles.none}
                                >
                                    Front of Car
                                </div>
                                <input
                                    type="file"
                                    id='customFileInput4'
                                    accept=".pdf, .jpg, .jpeg"
                                    // required
                                    hidden
                                    onChange={(e) => handleFileUpload(e, 'car_front')}
                                />
                                <div className={styles.file_flex}>
                                    <label
                                        htmlFor="customFileInput4"
                                        className={fileNames.car_front ? styles.customFileInput1_upload : styles.customFileInput1}
                                    >
                                        <div className={styles.text}>
                                            {
                                                fileNames.car_front != "" ?
                                                    (
                                                        fileNames.car_front + ' ' + ''
                                                    ) :
                                                    (
                                                        'Front of Car'
                                                    )
                                            }
                                        </div>
                                        <div className={styles.image}>
                                            {
                                                fileNames.car_front == "" ? <GoUpload /> : ""
                                            }
                                        </div>
                                    </label>
                                    <div className={styles.image}>
                                        {
                                            fileNames.car_front !== "" ?
                                                (
                                                    imagePreviews.car_front && (
                                                        <div className={styles.eye_icon_container}>
                                                            <div
                                                                className={styles.icon}
                                                                onClick={() => setShowFrontPreview(!showFrontPreview)}>
                                                                <IoEyeOutline size={24} />
                                                            </div>
                                                            <div
                                                                className={styles.icon}
                                                                onClick={() => handleDelete('car_front')}
                                                            >
                                                                <MdOutlineDeleteOutline size={24} />
                                                            </div>
                                                        </div>
                                                    )
                                                )
                                                :
                                                (
                                                    null
                                                )
                                        }
                                    </div>
                                    <Modal
                                        open={showFrontPreview}
                                        onClose={() => setShowFrontPreview(false)}
                                        aria-labelledby="upload-address-preview-modal"
                                        aria-describedby="upload-address-preview-description"
                                    >
                                        <div className={styles.preview}>
                                            <button
                                                className={styles.close_button_for_preview}
                                                onClick={() => setShowFrontPreview(false)}
                                            >
                                                <IoCloseCircleSharp />
                                            </button>
                                            {fileNames?.car_front &&
                                                fileNames?.car_front.toLowerCase().endsWith('.pdf') ?
                                                (
                                                    <div className={styles.modalImage}>
                                                        <Worker
                                                            workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
                                                        >
                                                            <Viewer
                                                                fileUrl={imagePreviews?.car_front}
                                                            >
                                                            </Viewer>
                                                        </Worker>
                                                    </div>
                                                ) : (
                                                    <img
                                                        className={styles.modalImage}
                                                        src={imagePreviews?.car_front}
                                                        alt="Upload Preview"
                                                    />
                                                )}
                                        </div>
                                    </Modal>
                                </div>


                                {/* Back of Car */}
                                <div
                                    className={fileNames.car_back ? styles.my_upload_label : styles.none}
                                >
                                    Back of Car
                                </div>
                                <input
                                    type="file"
                                    id='customFileInput3'
                                    accept=".pdf, .jpg, .jpeg"
                                    // required
                                    hidden
                                    onChange={(e) => handleFileUpload(e, 'car_back')}
                                />
                                <div className={styles.file_flex}>
                                    <label
                                        htmlFor="customFileInput3"
                                        className={fileNames.car_back ? styles.customFileInput1_upload : styles.customFileInput1}
                                    >
                                        <div className={styles.text}>
                                            {
                                                fileNames.car_back != "" ?
                                                    (
                                                        fileNames.car_back + ' ' + ''
                                                    ) :
                                                    (
                                                        'Back of Car'
                                                    )
                                            }
                                        </div>
                                        <div className={styles.image}>
                                            {
                                                fileNames.car_back == "" ? <GoUpload /> : ""
                                            }
                                        </div>
                                    </label>
                                    <div className={styles.image}>
                                        {
                                            fileNames.car_back !== "" ?
                                                (
                                                    imagePreviews.car_back && (
                                                        <div className={styles.eye_icon_container}>
                                                            <div
                                                                className={styles.icon}
                                                                onClick={() => setShowBackPreview(!showBackPreview)}>
                                                                <IoEyeOutline size={24} />
                                                            </div>
                                                            <div
                                                                className={styles.icon}
                                                                onClick={() => handleDelete('car_back')}
                                                            >
                                                                <MdOutlineDeleteOutline size={24} />
                                                            </div>
                                                        </div>
                                                    )
                                                )
                                                :
                                                (
                                                    null
                                                )
                                        }
                                    </div>
                                    <Modal
                                        open={showBackPreview}
                                        onClose={() => setShowBackPreview(false)}
                                        aria-labelledby="upload-address-preview-modal"
                                        aria-describedby="upload-address-preview-description"
                                    >
                                        <div className={styles.preview}>
                                            <button
                                                className={styles.close_button_for_preview}
                                                onClick={() => setShowBackPreview(false)}
                                            >
                                                <IoCloseCircleSharp />
                                            </button>
                                            {fileNames?.car_back &&
                                                fileNames?.car_back.toLowerCase().endsWith('.pdf') ?
                                                (
                                                    <div className={styles.modalImage}>
                                                        <Worker
                                                            workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
                                                        >
                                                            <Viewer
                                                                fileUrl={imagePreviews?.car_back}
                                                            >
                                                            </Viewer>
                                                        </Worker>
                                                    </div>
                                                ) : (
                                                    <img
                                                        className={styles.modalImage}
                                                        src={imagePreviews?.car_back}
                                                        alt="Upload Preview"
                                                    />
                                                )}
                                        </div>
                                    </Modal>
                                </div>


                                {/* Left Side of Car */}
                                <div
                                    className={fileNames.car_left ? styles.my_upload_label : styles.none}
                                >
                                    Left Side of Car
                                </div>
                                <input
                                    type="file"
                                    id='customFileInput2'
                                    accept=".pdf, .jpg, .jpeg"
                                    // required
                                    hidden
                                    onChange={(e) => handleFileUpload(e, 'car_left')}
                                />
                                <div className={styles.file_flex}>
                                    <label
                                        htmlFor="customFileInput2"
                                        className={fileNames.car_left ? styles.customFileInput1_upload : styles.customFileInput1}
                                    >
                                        <div className={styles.text}>
                                            {
                                                fileNames.car_left != "" ?
                                                    (
                                                        fileNames.car_left + ' ' + ''
                                                    ) :
                                                    (
                                                        'Left Side of Car'
                                                    )
                                            }
                                        </div>
                                        <div className={styles.image}>
                                            {
                                                fileNames.car_left == "" ? <GoUpload /> : ""
                                            }
                                        </div>
                                    </label>
                                    <div className={styles.image}>
                                        {
                                            fileNames.car_left !== "" ?
                                                (
                                                    imagePreviews.car_left && (
                                                        <div className={styles.eye_icon_container}>
                                                            <div
                                                                className={styles.icon}
                                                                onClick={() => setShowLeftPreview(!showLeftPreview)}>
                                                                <IoEyeOutline size={24} />
                                                            </div>
                                                            <div
                                                                className={styles.icon}
                                                                onClick={() => handleDelete('car_left')}
                                                            >
                                                                <MdOutlineDeleteOutline size={24} />
                                                            </div>
                                                        </div>
                                                    )
                                                )
                                                :
                                                (
                                                    null
                                                )
                                        }
                                    </div>
                                    <Modal
                                        open={showLeftPreview}
                                        onClose={() => setShowLeftPreview(false)}
                                        aria-labelledby="upload-address-preview-modal"
                                        aria-describedby="upload-address-preview-description"
                                    >
                                        <div className={styles.preview}>
                                            <button
                                                className={styles.close_button_for_preview}
                                                onClick={() => setShowLeftPreview(false)}
                                            >
                                                <IoCloseCircleSharp />
                                            </button>
                                            {fileNames?.car_left &&
                                                fileNames?.car_left.toLowerCase().endsWith('.pdf') ?
                                                (
                                                    <div className={styles.modalImage}>
                                                        <Worker
                                                            workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
                                                        >
                                                            <Viewer
                                                                fileUrl={imagePreviews?.car_left}
                                                            >
                                                            </Viewer>
                                                        </Worker>
                                                    </div>
                                                ) : (
                                                    <img
                                                        className={styles.modalImage}
                                                        src={imagePreviews?.car_left}
                                                        alt="Upload Preview"
                                                    />
                                                )}
                                        </div>
                                    </Modal>
                                </div>


                                {/* Right Side of Car */}
                                <div
                                    className={fileNames.car_right ? styles.my_upload_label : styles.none}
                                >
                                    Right Side of Car
                                </div>
                                <input
                                    type="file"
                                    id='customFileInput1'
                                    accept=".pdf, .jpg, .jpeg"
                                    hidden
                                    onChange={(e) => handleFileUpload(e, 'car_right')}
                                />
                                <div className={styles.file_flex}>
                                    <label
                                        htmlFor="customFileInput1"
                                        className={fileNames.car_right ? styles.customFileInput1_upload : styles.customFileInput1}
                                    >
                                        <div className={styles.text}>
                                            {
                                                fileNames.car_right != "" ?
                                                    (
                                                        fileNames.car_right + ' ' + ''
                                                    ) :
                                                    (
                                                        'Right Side of Car'
                                                    )
                                            }
                                        </div>
                                        <div className={styles.image}>
                                            {
                                                fileNames.car_right == "" ? <GoUpload /> : ""
                                            }
                                        </div>
                                    </label>
                                    <div className={styles.image}>
                                        {
                                            fileNames.car_right !== "" ?
                                                (
                                                    imagePreviews.car_right && (
                                                        <div className={styles.eye_icon_container}>
                                                            <div
                                                                className={styles.icon}
                                                                onClick={() => setShowRightPreview(!showRightPreview)}>
                                                                <IoEyeOutline size={24} />
                                                            </div>
                                                            <div
                                                                className={styles.icon}
                                                                onClick={() => handleDelete('car_right')}
                                                            >
                                                                <MdOutlineDeleteOutline size={24} />
                                                            </div>
                                                        </div>
                                                    )
                                                )
                                                :
                                                (
                                                    null
                                                )
                                        }
                                    </div>
                                    <Modal
                                        open={showRightPreview}
                                        onClose={() => setShowRightPreview(false)}
                                        aria-labelledby="upload-address-preview-modal"
                                        aria-describedby="upload-address-preview-description"
                                    >
                                        <div className={styles.preview}>
                                            <button
                                                className={styles.close_button_for_preview}
                                                onClick={() => setShowRightPreview(false)}
                                            >
                                                <IoCloseCircleSharp />
                                            </button>
                                            {fileNames?.car_right &&
                                                fileNames?.car_right.toLowerCase().endsWith('.pdf') ?
                                                (
                                                    <div className={styles.modalImage}>
                                                        <Worker
                                                            workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
                                                        >
                                                            <Viewer
                                                                fileUrl={imagePreviews?.car_right}
                                                            >
                                                            </Viewer>
                                                        </Worker>
                                                    </div>
                                                ) : (
                                                    <img
                                                        className={styles.modalImage}
                                                        src={imagePreviews?.car_right}
                                                        alt="Upload Preview"
                                                    />
                                                )}
                                        </div>
                                    </Modal>
                                </div>

                                {/* BUTTON */}
                                <div className={styles.file_flex}>
                                    <div
                                        onClick={handleClose2}
                                        className={styles.skip}
                                    >
                                        Skip
                                    </div>
                                    <div
                                        onClick={handleClose2}
                                        className={styles.submit_button2}
                                    >
                                        Submit
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </Modal>
                </>
            }
        </>
    )
}

export default SellCar  