import './big_card_style.css';

export const Car_buying = () => {
    return (
        <div className="my_big_card">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="553"
                height="285"
                fill="none"
                viewBox="0 0 353 285"
            >
                <g id="Property 1=Variant2">
                    <g id="Form1">
                        <g id="Form2">
                            <g id="Form3">
                                <path
                                    id="Vector"
                                    fill="url(#paint0_linear_3957_85229)"
                                    d="M153.745 4.599h10.644l16.876 189.97h-10.643c-4.91 0-9.244-3.979-9.68-8.89L145.645 13.488c-.436-4.91 3.191-8.89 8.1-8.89z"
                                ></path>
                                <path
                                    id="Vector_2"
                                    fill="url(#paint1_linear_3957_85229)"
                                    d="M289.989 194.569H173.776c-5.5 0-10.349-4.459-10.83-9.959L148.069 14.558c-.481-5.501 3.587-9.96 9.088-9.96h116.212c5.501 0 10.349 4.459 10.83 9.96l14.878 170.052c.481 5.5-3.587 9.959-9.088 9.959z"
                                ></path>
                                <path
                                    id="Vector_3"
                                    fill="#fff"
                                    d="M289.989 194.569H173.776c-5.5 0-10.349-4.459-10.83-9.959L148.069 14.558c-.481-5.501 3.587-9.96 9.088-9.96h116.212c5.501 0 10.349 4.459 10.83 9.96l14.878 170.052c.481 5.5-3.587 9.959-9.088 9.959z"
                                    opacity="0.5"
                                ></path>
                            </g>
                            <g id="Group_3">
                                <path
                                    id="Vector_4"
                                    fill="#002220"
                                    d="M174.931 172.863h113.834L274.373 10.858H160.538l14.393 162.005z"
                                    opacity="0.2"
                                ></path>
                                <path
                                    id="Vector_5"
                                    fill="url(#paint2_linear_3957_85229)"
                                    d="M179.908 170.646h113.834L274.373 10.858H160.538l19.37 159.788z"
                                ></path>
                                <path
                                    id="Vector_6"
                                    fill="#fff"
                                    d="M179.908 170.646h113.834L274.373 10.858H160.538l19.37 159.788z"
                                ></path>
                                <g id="Group_4">
                                    <g id="Group_5">
                                        <g id="Group_6">
                                            <path
                                                id="Vector_7"
                                                fill="#002220"
                                                d="M267.39 55.087h-71.208c-.816 0-1.536-.662-1.607-1.479a1.333 1.333 0 011.349-1.478h71.207c.817 0 1.537.661 1.608 1.478a1.334 1.334 0 01-1.349 1.479z"
                                            ></path>
                                        </g>
                                        <g id="Group_7">
                                            <path
                                                id="Vector_8"
                                                fill="#002220"
                                                d="M265.166 63.464h-80.817c-.816 0-1.536-.661-1.608-1.478a1.333 1.333 0 011.349-1.479h80.817c.816 0 1.536.662 1.607 1.479a1.332 1.332 0 01-1.348 1.478z"
                                            ></path>
                                        </g>
                                        <g id="Group_8">
                                            <path
                                                id="Vector_9"
                                                fill="#002220"
                                                d="M268.856 71.842h-83.775c-.816 0-1.536-.662-1.607-1.479a1.333 1.333 0 011.349-1.478h83.774c.816 0 1.536.661 1.608 1.478.071.817-.532 1.479-1.349 1.479z"
                                            ></path>
                                        </g>
                                        <g id="Group_9">
                                            <path
                                                id="Vector_10"
                                                fill="#002220"
                                                d="M259.24 80.22h-73.425c-.817 0-1.537-.662-1.609-1.48a1.334 1.334 0 011.349-1.478h73.425c.816 0 1.537.662 1.608 1.479a1.332 1.332 0 01-1.348 1.478z"
                                            ></path>
                                        </g>
                                        <g id="Group_10">
                                            <path
                                                id="Vector_11"
                                                fill="#002220"
                                                d="M270.322 88.597h-71.208c-.816 0-1.536-.662-1.608-1.479a1.333 1.333 0 011.349-1.478h71.208c.817 0 1.536.661 1.607 1.478a1.332 1.332 0 01-1.348 1.479z"
                                            ></path>
                                        </g>
                                        <g id="Group_11">
                                            <path
                                                id="Vector_12"
                                                fill="#002220"
                                                d="M267.359 96.974H187.28c-.816 0-1.536-.661-1.607-1.478a1.333 1.333 0 011.349-1.479h80.077c.817 0 1.537.662 1.608 1.479a1.332 1.332 0 01-1.348 1.478z"
                                            ></path>
                                        </g>
                                        <g id="Group_12">
                                            <path
                                                id="Vector_13"
                                                fill="#002220"
                                                d="M271.787 105.351h-83.774c-.816 0-1.536-.662-1.608-1.479a1.333 1.333 0 011.349-1.478h83.774c.816 0 1.536.661 1.607 1.478a1.332 1.332 0 01-1.348 1.479z"
                                            ></path>
                                        </g>
                                        <g id="Group_13">
                                            <path
                                                id="Vector_14"
                                                fill="#002220"
                                                d="M266.607 113.729h-77.86c-.816 0-1.537-.661-1.608-1.478-.071-.817.533-1.479 1.349-1.479h77.861c.816 0 1.536.662 1.608 1.479a1.334 1.334 0 01-1.35 1.478z"
                                            ></path>
                                        </g>
                                    </g>
                                    <g id="Group_14" opacity="0.3">
                                        <g id="Group_15">
                                            <path
                                                id="Vector_15"
                                                fill="#002220"
                                                d="M267.39 55.087h-71.208c-.816 0-1.536-.662-1.607-1.479a1.333 1.333 0 011.349-1.478h71.207c.817 0 1.537.661 1.608 1.478a1.334 1.334 0 01-1.349 1.479z"
                                            ></path>
                                        </g>
                                        <g id="Group_16">
                                            <path
                                                id="Vector_16"
                                                fill="#002220"
                                                d="M265.166 63.464h-80.817c-.816 0-1.536-.661-1.608-1.478a1.333 1.333 0 011.349-1.479h80.817c.816 0 1.536.662 1.607 1.479a1.332 1.332 0 01-1.348 1.478z"
                                            ></path>
                                        </g>
                                        <g id="Group_17">
                                            <path
                                                id="Vector_17"
                                                fill="#002220"
                                                d="M268.856 71.842h-83.775c-.816 0-1.536-.662-1.607-1.479a1.333 1.333 0 011.349-1.478h83.774c.816 0 1.536.661 1.608 1.478.071.817-.532 1.479-1.349 1.479z"
                                            ></path>
                                        </g>
                                        <g id="Group_18">
                                            <path
                                                id="Vector_18"
                                                fill="#002220"
                                                d="M259.24 80.22h-73.425c-.817 0-1.537-.662-1.609-1.48a1.334 1.334 0 011.349-1.478h73.425c.816 0 1.537.662 1.608 1.479a1.332 1.332 0 01-1.348 1.478z"
                                            ></path>
                                        </g>
                                        <g id="Group_19">
                                            <path
                                                id="Vector_19"
                                                fill="#002220"
                                                d="M270.322 88.597h-71.208c-.816 0-1.536-.662-1.608-1.479a1.333 1.333 0 011.349-1.478h71.208c.817 0 1.536.661 1.607 1.478a1.332 1.332 0 01-1.348 1.479z"
                                            ></path>
                                        </g>
                                        <g id="Group_20">
                                            <path
                                                id="Vector_20"
                                                fill="#002220"
                                                d="M267.359 96.974H187.28c-.816 0-1.536-.661-1.607-1.478a1.333 1.333 0 011.349-1.479h80.077c.817 0 1.537.662 1.608 1.479a1.332 1.332 0 01-1.348 1.478z"
                                            ></path>
                                        </g>
                                        <g id="Group_21">
                                            <path
                                                id="Vector_21"
                                                fill="#002220"
                                                d="M271.787 105.351h-83.774c-.816 0-1.536-.662-1.608-1.479a1.333 1.333 0 011.349-1.478h83.774c.816 0 1.536.661 1.607 1.478a1.332 1.332 0 01-1.348 1.479z"
                                            ></path>
                                        </g>
                                        <g id="Group_22">
                                            <path
                                                id="Vector_22"
                                                fill="#002220"
                                                d="M266.607 113.729h-77.86c-.816 0-1.537-.661-1.608-1.478-.071-.817.533-1.479 1.349-1.479h77.861c.816 0 1.536.662 1.608 1.479a1.334 1.334 0 01-1.35 1.478z"
                                            ></path>
                                        </g>
                                    </g>
                                </g>
                                <g id="Group_23">
                                    <g id="Group_24">
                                        <path
                                            id="Vector_23"
                                            fill="url(#paint3_linear_3957_85229)"
                                            d="M201.345 29.671h2.18l.668 7.644h4.724l.155 1.776h-6.903l-.824-9.42z"
                                        ></path>
                                        <path
                                            id="Vector_24"
                                            fill="url(#paint4_linear_3957_85229)"
                                            d="M212.012 38.62a5.33 5.33 0 01-1.997-1.742 5.119 5.119 0 01-.885-2.497c-.081-.923.069-1.755.448-2.496a4.132 4.132 0 011.691-1.743c.748-.42 1.611-.633 2.589-.633s1.876.212 2.693.633a5.423 5.423 0 011.996 1.743c.514.74.811 1.572.892 2.496.081.925-.071 1.756-.455 2.497a4.19 4.19 0 01-1.691 1.742c-.743.422-1.604.632-2.582.632-.978 0-1.878-.21-2.699-.632zm4.01-1.607a2.43 2.43 0 00.962-1.07c.215-.457.298-.978.247-1.561a3.365 3.365 0 00-.52-1.56 3.168 3.168 0 00-2.691-1.454c-.557 0-1.047.127-1.473.384a2.408 2.408 0 00-.963 1.07c-.216.457-.298.977-.247 1.56.052.583.225 1.104.52 1.561a3.14 3.14 0 001.15 1.07c.471.255.984.384 1.54.384s1.049-.13 1.475-.384z"
                                        ></path>
                                        <path
                                            id="Vector_25"
                                            fill="url(#paint5_linear_3957_85229)"
                                            d="M227.385 37.073h-4.374l-.658 2.018h-2.234l3.375-9.42h2.153l5.036 9.42h-2.288l-1.01-2.018zm-.831-1.656l-1.81-3.607-1.178 3.607h2.988z"
                                        ></path>
                                        <path
                                            id="Vector_26"
                                            fill="url(#paint6_linear_3957_85229)"
                                            d="M239.481 29.671l.824 9.42h-1.79l-5.196-5.718.5 5.718h-2.153l-.824-9.42h1.803l5.184 5.72-.501-5.72h2.153z"
                                        ></path>
                                    </g>
                                    <g id="Group_25" opacity="0.3">
                                        <path
                                            id="Vector_27"
                                            fill="url(#paint7_linear_3957_85229)"
                                            d="M201.345 29.671h2.18l.668 7.644h4.724l.155 1.776h-6.903l-.824-9.42z"
                                        ></path>
                                        <path
                                            id="Vector_28"
                                            fill="url(#paint8_linear_3957_85229)"
                                            d="M212.012 38.62a5.33 5.33 0 01-1.997-1.742 5.119 5.119 0 01-.885-2.497c-.081-.923.069-1.755.448-2.496a4.132 4.132 0 011.691-1.743c.748-.42 1.611-.633 2.589-.633s1.876.212 2.693.633a5.423 5.423 0 011.996 1.743c.514.74.811 1.572.892 2.496.081.925-.071 1.756-.455 2.497a4.19 4.19 0 01-1.691 1.742c-.743.422-1.604.632-2.582.632-.978 0-1.878-.21-2.699-.632zm4.01-1.607a2.43 2.43 0 00.962-1.07c.215-.457.298-.978.247-1.561a3.365 3.365 0 00-.52-1.56 3.168 3.168 0 00-2.691-1.454c-.557 0-1.047.127-1.473.384a2.408 2.408 0 00-.963 1.07c-.216.457-.298.977-.247 1.56.052.583.225 1.104.52 1.561a3.14 3.14 0 001.15 1.07c.471.255.984.384 1.54.384s1.049-.13 1.475-.384z"
                                        ></path>
                                        <path
                                            id="Vector_29"
                                            fill="url(#paint9_linear_3957_85229)"
                                            d="M227.385 37.073h-4.374l-.658 2.018h-2.234l3.375-9.42h2.153l5.036 9.42h-2.288l-1.01-2.018zm-.831-1.656l-1.81-3.607-1.178 3.607h2.988z"
                                        ></path>
                                        <path
                                            id="Vector_30"
                                            fill="url(#paint10_linear_3957_85229)"
                                            d="M239.481 29.671l.824 9.42h-1.79l-5.196-5.718.5 5.718h-2.153l-.824-9.42h1.803l5.184 5.72-.501-5.72h2.153z"
                                        ></path>
                                    </g>
                                </g>
                                <g id="approved">
                                    <g id="Group_27">
                                        <path
                                            id="Vector_31"
                                            fill="url(#paint11_linear_3957_85229)"
                                            d="M215.849 153.447c-6.2 0-11.954-4.697-13.167-10.999l-.454-2.361c-1.297-6.736 3.076-12.79 9.75-13.498l61.463-6.519c3.235-.346 6.518.605 9.246 2.668 2.731 2.064 4.58 4.997 5.209 8.261l.455 2.361c.629 3.262-.041 6.464-1.881 9.014-1.841 2.548-4.635 4.142-7.867 4.484l-61.464 6.521c-.434.044-.863.068-1.29.068zm59.081-31.245c-.357 0-.712.019-1.069.057l-61.463 6.519c-2.654.282-4.946 1.588-6.457 3.682-1.511 2.092-2.059 4.72-1.544 7.397l.455 2.361c1.065 5.527 6.39 9.536 11.862 8.97l61.464-6.521c2.653-.282 4.946-1.588 6.456-3.68 1.511-2.092 2.059-4.72 1.544-7.397l-.455-2.361c-.515-2.677-2.033-5.084-4.274-6.778-1.939-1.469-4.219-2.249-6.519-2.249z"
                                        ></path>
                                    </g>
                                    <g id="Group_28">
                                        <path
                                            id="Vector_32"
                                            fill="url(#paint12_linear_3957_85229)"
                                            d="M215.925 142.102l-4.674.495-.539 2.32-1.293.138 2.272-9.298 1.244-.132 5.704 8.451-1.319.14-1.395-2.114zm-.635-.968l-2.734-4.147-1.06 4.55 3.794-.403z"
                                        ></path>
                                        <path
                                            id="Vector_33"
                                            fill="url(#paint13_linear_3957_85229)"
                                            d="M224.512 135.239c.76.48 1.231 1.201 1.417 2.165.186.965.005 1.756-.543 2.374-.548.618-1.391.988-2.53 1.11l-2.173.23.517 2.688-1.257.132-1.709-8.874 3.43-.364c1.141-.12 2.09.06 2.848.539zm-.182 3.843c.353-.386.467-.901.344-1.543-.123-.642-.427-1.113-.913-1.412-.485-.298-1.126-.404-1.921-.32l-2.136.227.767 3.981 2.137-.226c.795-.085 1.369-.32 1.722-.707z"
                                        ></path>
                                        <path
                                            id="Vector_34"
                                            fill="url(#paint14_linear_3957_85229)"
                                            d="M233.456 134.291c.76.48 1.231 1.202 1.417 2.166.186.964.006 1.755-.543 2.371-.546.621-1.391.989-2.53 1.11l-2.173.23.517 2.688-1.256.133-1.71-8.875 3.43-.364c1.141-.119 2.088.061 2.848.541zm-.182 3.842c.354-.386.468-.902.344-1.544-.123-.642-.427-1.113-.912-1.411-.486-.298-1.126-.405-1.921-.32l-2.137.226.768 3.982 2.136-.226c.795-.085 1.369-.321 1.722-.707z"
                                        ></path>
                                        <path
                                            id="Vector_35"
                                            fill="url(#paint15_linear_3957_85229)"
                                            d="M243.711 141.417l-2.421-2.524a8.401 8.401 0 01-.549.084l-2.173.23.521 2.699-1.257.135-1.709-8.876 3.43-.365c1.139-.121 2.088.059 2.846.539.76.481 1.231 1.202 1.417 2.166.133.685.077 1.284-.166 1.801-.242.518-.651.919-1.229 1.206l2.659 2.758-1.369.147zm-1.494-4.226c.352-.39.466-.908.342-1.55-.123-.642-.426-1.113-.912-1.411-.486-.298-1.126-.405-1.921-.32l-2.136.226.769 3.995 2.136-.226c.797-.087 1.371-.324 1.722-.714z"
                                        ></path>
                                        <path
                                            id="Vector_36"
                                            fill="url(#paint16_linear_3957_85229)"
                                            d="M248.441 140.418a4.958 4.958 0 01-1.991-1.451 4.938 4.938 0 01-1.056-2.256c-.164-.854-.11-1.649.162-2.385a3.863 3.863 0 011.364-1.807c.636-.467 1.398-.749 2.285-.844a5.145 5.145 0 012.5.336 4.948 4.948 0 011.984 1.447 4.965 4.965 0 011.051 2.263c.165.863.114 1.658-.155 2.39a3.847 3.847 0 01-1.358 1.8c-.637.469-1.395.748-2.274.842a5.146 5.146 0 01-2.512-.335zm3.951-1.42a2.9 2.9 0 00.982-1.353c.197-.552.234-1.148.111-1.79a3.768 3.768 0 00-.782-1.694 3.714 3.714 0 00-1.454-1.095 3.64 3.64 0 00-1.825-.26c-.644.068-1.2.278-1.667.63a2.868 2.868 0 00-.996 1.354c-.197.55-.233 1.148-.11 1.79.123.642.385 1.207.782 1.697a3.71 3.71 0 003.305 1.35c.644-.07 1.196-.28 1.654-.629z"
                                        ></path>
                                        <path
                                            id="Vector_37"
                                            fill="url(#paint17_linear_3957_85229)"
                                            d="M263.167 130.298l-2.147 9.284-1.244.133-5.579-8.464 1.356-.144 4.569 6.956 1.787-7.629 1.258-.136z"
                                        ></path>
                                        <path
                                            id="Vector_38"
                                            fill="url(#paint18_linear_3957_85229)"
                                            d="M272.164 137.274l.212 1.102-6.381.677-1.709-8.874 6.206-.659.211 1.102-4.949.525.524 2.726 4.409-.467.208 1.078-4.409.468.552 2.865 5.126-.543z"
                                        ></path>
                                        <path
                                            id="Vector_39"
                                            fill="url(#paint19_linear_3957_85229)"
                                            d="M272.704 129.286l3.705-.393c.93-.1 1.791 0 2.584.296a4.652 4.652 0 011.978 1.389c.526.629.874 1.38 1.041 2.25.168.871.118 1.664-.151 2.378a3.636 3.636 0 01-1.374 1.742c-.65.449-1.437.721-2.368.821l-3.706.393-1.709-8.876zm5.126 7.388c.712-.076 1.31-.282 1.797-.618a2.68 2.68 0 001.025-1.305c.197-.534.232-1.132.105-1.79-.127-.659-.386-1.226-.776-1.697a3.41 3.41 0 00-1.476-1.039c-.594-.221-1.247-.295-1.958-.219l-2.373.252 1.284 6.67 2.372-.254z"
                                        ></path>
                                    </g>
                                </g>
                            </g>
                            <g id="Group_29">
                                <path
                                    id="Vector_40"
                                    fill="#002220"
                                    d="M195.406.14h8.915l1.445 16.262h-8.914c-3.007 0-5.661-2.437-5.928-5.444l-.477-5.374c-.269-3.007 1.952-5.444 4.959-5.444z"
                                ></path>
                                <path
                                    id="Vector_41"
                                    fill="#002220"
                                    d="M235.081 16.402h-36.026c-3.369 0-6.338-2.73-6.634-6.1l-.356-4.063c-.294-3.368 2.197-6.1 5.566-6.1h36.027c3.368 0 6.338 2.731 6.633 6.1l.355 4.064c.295 3.369-2.197 6.1-5.565 6.1z"
                                ></path>
                                <path
                                    id="Vector_42"
                                    fill="#fff"
                                    d="M235.081 16.402h-36.026c-3.369 0-6.338-2.73-6.634-6.1l-.356-4.063c-.294-3.368 2.197-6.1 5.566-6.1h36.027c3.368 0 6.338 2.731 6.633 6.1l.355 4.064c.295 3.369-2.197 6.1-5.565 6.1z"
                                    opacity="0.5"
                                ></path>
                                <g id="Group_30" fill="#002220">
                                    <path
                                        id="Vector_43"
                                        d="M234.802 9.996c.077.885-.577 1.602-1.462 1.602s-1.664-.717-1.741-1.602a1.444 1.444 0 011.462-1.601c.885 0 1.664.716 1.741 1.601z"
                                    ></path>
                                    <path
                                        id="Vector_44"
                                        d="M201.416 9.996c.077.885-.577 1.602-1.462 1.602-.884 0-1.664-.717-1.742-1.602-.077-.884.577-1.601 1.462-1.601s1.665.716 1.742 1.601z"
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                    <g id="Car">
                        <g id="Group_31">
                            <g id="Group_32" fill="#002220">
                                <path
                                    id="Vector_45"
                                    d="M28.159 263.349l57.674 1.891h127.573l55.047-2.799v-12.02l-7.054-10.196-19.193-6.781-24.353 6.781-9.66 9.117-109.238 1.079-21.402-15.597-16.725-6.035-24.522 6.341-5.98 10.961-3.527 11.046 1.36 6.212z"
                                ></path>
                                <path
                                    id="Vector_46"
                                    d="M28.159 263.349l57.674 1.891h127.573l55.047-2.799v-12.02l-7.054-10.196-19.193-6.781-24.353 6.781-9.66 9.117-109.238 1.079-21.402-15.597-16.725-6.035-24.522 6.341-5.98 10.961-3.527 11.046 1.36 6.212z"
                                    opacity="0.6"
                                ></path>
                            </g>
                            <path
                                id="Vector_47"
                                fill="url(#paint20_linear_3957_85229)"
                                d="M31.514 254.234c2.059-5.029 10.232-13.399 15.244-15.499 4.876-2.043 16.45-2.424 21.148 0 4.97 2.564 11.257 12.903 12.957 18.23.626 1.961.444 6.696.236 9.814l136.161 1.436c-.286-3.784-.476-9.006.414-11.178 2.059-5.03 10.232-13.399 15.245-15.5 4.876-2.043 16.449-2.424 21.148 0 4.968 2.564 11.256 12.903 12.956 18.23.409 1.284.473 3.757.41 6.186a13.31 13.31 0 002.719-2.707l1.281-1.731a13.373 13.373 0 002.629-7.962v-4.49c0-2.448-.672-4.848-1.942-6.94l-3.272-5.389v-36.527a13.37 13.37 0 00-1.067-5.234l-13.082-30.756a13.371 13.371 0 00-11.851-8.132c-14.045-.475-41.615-1.305-52.813-.925-16.28.552-59.946 5.005-72.957 6.359-2.41.25-4.668 1.27-6.452 2.909L71.97 199.934l-38.358 16.749a11.27 11.27 0 00-6.469 7.782l-3.628 15.644-1.197 1.566a11.275 11.275 0 00-2.236 8.205l.739 6.087a11.272 11.272 0 004.066 7.376l.477.388a11.26 11.26 0 005.795 2.449c-.315-3.808-.597-9.621.355-11.946z"
                            ></path>
                            <g id="Tire1">
                                <path
                                    id="Rim1"
                                    fill="#002220"
                                    d="M55.563 284.279c11.56 0 20.93-9.371 20.93-20.93 0-11.56-9.37-20.93-20.93-20.93s-20.93 9.37-20.93 20.93c0 11.559 9.37 20.93 20.93 20.93z"
                                ></path>
                                <path
                                    id="Vector_49"
                                    fill="#fff"
                                    d="M55.563 277.932c8.054 0 14.583-6.529 14.583-14.583s-6.53-14.583-14.583-14.583c-8.054 0-14.583 6.529-14.583 14.583s6.529 14.583 14.583 14.583z"
                                    opacity="0.5"
                                ></path>
                                <g id="Group_34" fill="#002220" opacity="0.5">
                                    <path
                                        id="Vector_50"
                                        d="M50.195 258.549l-1.018-3.035c-.474-1.413-2.318-1.832-3.304-.713a12.902 12.902 0 00-2.551 4.413c-.479 1.419.8 2.817 2.267 2.52l3.134-.635a1.965 1.965 0 001.472-2.55z"
                                    ></path>
                                    <path
                                        id="Vector_51"
                                        d="M57.035 256.3l2.122-2.402c.985-1.115.43-2.916-1.027-3.216a12.766 12.766 0 00-5.134 0c-1.457.3-2.01 2.101-1.026 3.216l2.121 2.402a1.964 1.964 0 002.944 0z"
                                    ></path>
                                    <path
                                        id="Vector_52"
                                        d="M62.403 261.099l3.134.635c1.467.297 2.745-1.101 2.268-2.52a12.895 12.895 0 00-2.551-4.413c-.987-1.118-2.83-.7-3.304.713l-1.018 3.035a1.963 1.963 0 001.471 2.55z"
                                    ></path>
                                    <path
                                        id="Vector_53"
                                        d="M60.931 268.148l1.018 3.036c.474 1.413 2.317 1.831 3.304.713a12.902 12.902 0 002.55-4.413c.478-1.418-.8-2.817-2.267-2.52l-3.133.635a1.963 1.963 0 00-1.472 2.549z"
                                    ></path>
                                    <path
                                        id="Vector_54"
                                        d="M54.09 270.398l-2.121 2.401c-.985 1.115-.43 2.916 1.026 3.216a12.694 12.694 0 005.134 0c1.457-.3 2.011-2.101 1.027-3.216l-2.122-2.401a1.963 1.963 0 00-2.944 0z"
                                    ></path>
                                    <path
                                        id="Vector_55"
                                        d="M48.722 265.598l-3.133-.635c-1.468-.297-2.746 1.102-2.268 2.52a12.895 12.895 0 002.55 4.413c.987 1.118 2.83.701 3.305-.713l1.018-3.035a1.964 1.964 0 00-1.472-2.55z"
                                    ></path>
                                </g>
                                <g id="Group_35" fill="#fff" opacity="0.5">
                                    <path
                                        id="Vector_56"
                                        d="M53.671 262.441a.984.984 0 10-.001-1.968.984.984 0 00.001 1.968z"
                                    ></path>
                                    <path
                                        id="Vector_57"
                                        d="M58.438 261.457a.984.984 0 11-1.969 0 .984.984 0 011.969 0z"
                                    ></path>
                                    <path
                                        id="Vector_58"
                                        d="M54.655 265.24a.984.984 0 11-1.969 0 .984.984 0 011.969 0z"
                                    ></path>
                                    <path
                                        id="Vector_59"
                                        d="M58.438 265.24a.984.984 0 11-1.969 0 .984.984 0 011.969 0z"
                                    ></path>
                                </g>
                            </g>
                            <g id="Tire2">
                                <path
                                    id="Vector_60"
                                    fill="#002220"
                                    d="M243.113 284.279c11.559 0 20.93-9.371 20.93-20.93 0-11.56-9.371-20.93-20.93-20.93s-20.93 9.37-20.93 20.93c0 11.559 9.371 20.93 20.93 20.93z"
                                ></path>
                                <path
                                    id="Vector_61"
                                    fill="#fff"
                                    d="M243.112 277.932c8.054 0 14.583-6.529 14.583-14.583s-6.529-14.583-14.583-14.583c-8.053 0-14.582 6.529-14.582 14.583s6.529 14.583 14.582 14.583z"
                                    opacity="0.5"
                                ></path>
                                <g id="Group_37" fill="#002220" opacity="0.5">
                                    <path
                                        id="Vector_62"
                                        d="M237.745 258.549l-1.019-3.035c-.473-1.413-2.317-1.832-3.303-.713a12.898 12.898 0 00-2.552 4.413c-.477 1.419.8 2.817 2.268 2.52l3.134-.635a1.964 1.964 0 001.472-2.55z"
                                    ></path>
                                    <path
                                        id="Vector_63"
                                        d="M244.586 256.3l2.121-2.402c.985-1.115.431-2.916-1.026-3.216a12.772 12.772 0 00-5.134 0c-1.457.3-2.011 2.101-1.027 3.216l2.122 2.402a1.964 1.964 0 002.944 0z"
                                    ></path>
                                    <path
                                        id="Vector_64"
                                        d="M249.955 261.099l3.132.635c1.468.297 2.746-1.101 2.268-2.52a12.902 12.902 0 00-2.551-4.413c-.986-1.118-2.83-.7-3.304.713l-1.018 3.035a1.964 1.964 0 001.473 2.55z"
                                    ></path>
                                    <path
                                        id="Vector_65"
                                        d="M248.482 268.148l1.019 3.036c.473 1.413 2.317 1.831 3.303.713a12.88 12.88 0 002.551-4.413c.479-1.418-.799-2.817-2.267-2.52l-3.133.635a1.963 1.963 0 00-1.473 2.549z"
                                    ></path>
                                    <path
                                        id="Vector_66"
                                        d="M241.641 270.398l-2.122 2.401c-.984 1.115-.43 2.916 1.027 3.216a12.693 12.693 0 005.134 0c1.457-.3 2.011-2.101 1.027-3.216l-2.122-2.401a1.963 1.963 0 00-2.944 0z"
                                    ></path>
                                    <path
                                        id="Vector_67"
                                        d="M236.273 265.598l-3.134-.635c-1.467-.297-2.745 1.102-2.267 2.52a12.87 12.87 0 002.551 4.413c.986 1.118 2.83.701 3.303-.713l1.019-3.035a1.964 1.964 0 00-1.472-2.55z"
                                    ></path>
                                </g>
                                <g id="Group_38" fill="#fff" opacity="0.5">
                                    <path
                                        id="Vector_68"
                                        d="M242.206 261.457a.985.985 0 01-1.967 0 .984.984 0 011.967 0z"
                                    ></path>
                                    <path
                                        id="Vector_69"
                                        d="M245.005 262.441a.984.984 0 10-.001-1.967.984.984 0 00.001 1.967z"
                                    ></path>
                                    <path
                                        id="Vector_70"
                                        d="M242.206 265.24a.985.985 0 01-1.967 0 .984.984 0 011.967 0z"
                                    ></path>
                                    <path
                                        id="Vector_71"
                                        d="M245.988 265.24a.984.984 0 11-1.969 0 .984.984 0 011.969 0z"
                                    ></path>
                                </g>
                            </g>
                            <path
                                id="Vector_72"
                                fill="#002220"
                                d="M109.506 170.384a3.676 3.676 0 00.251-5.159l-37.788 34.71-1.103.482c.069.091.126.188.205.273a3.68 3.68 0 005.2.221l33.235-30.527z"
                            ></path>
                            <path
                                id="headlight"
                                fill="#fff"
                                d="M28.578 242.419a.419.419 0 01-.417-.46l1.745-17.89a.42.42 0 01.548-.357l1.607.529a4.081 4.081 0 012.715 4.74l-2.208 10.218a4.083 4.083 0 01-3.99 3.22z"
                            ></path>
                            <g id="Group_39" opacity="0.2">
                                <path
                                    id="Vector_74"
                                    fill="#fff"
                                    d="M38.543 230.601a1.38 1.38 0 011.353-1.406l218.653-4.24a1.381 1.381 0 01.053 2.76l-218.653 4.24a1.38 1.38 0 01-1.406-1.354z"
                                ></path>
                            </g>
                            <path
                                id="Vector_75"
                                fill="#002220"
                                d="M176.571 204.755l40.69-.703c4.41-.076 7.749-4.014 7.102-8.378l-4.056-27.371a3.328 3.328 0 00-3.215-2.838c-7.055-.162-14.13-.281-19.755-.281-2.711 0-5.084.027-6.958.092-3.659.124-9.481.54-16.277 1.106a3.33 3.33 0 00-3.045 3.084l-1.902 27.478c-.296 4.275 3.131 7.885 7.416 7.811z"
                            ></path>
                            <path
                                id="Vector_76"
                                fill="#002220"
                                d="M258.225 198.356l-12.837-30.177a3.246 3.246 0 00-2.877-1.977c-2.258-.076-5.124-.17-8.365-.27a3.328 3.328 0 00-3.395 3.811l4.096 27.645a7.308 7.308 0 007.355 6.234l12.553-.217c1.678-.028 3.236-1.107 3.648-2.733a3.655 3.655 0 00-.178-2.316z"
                            ></path>
                            <path
                                id="Vector_77"
                                fill="#002220"
                                d="M157.145 167.899a3747.112 3747.112 0 00-36.46 3.643 9.775 9.775 0 00-5.593 2.524l-22.567 20.728c-4.424 4.063-1.476 11.438 4.529 11.334l54.693-.945a7.308 7.308 0 007.163-6.802l1.865-26.943a3.325 3.325 0 00-3.63-3.539z"
                            ></path>
                            <g id="Group_40" fill="#002220">
                                <path
                                    id="Vector_78"
                                    d="M219.603 211.878l-9.506.184a1.381 1.381 0 00.053 2.76l9.506-.184a1.38 1.38 0 10-.053-2.76z"
                                ></path>
                                <path
                                    id="Vector_79"
                                    d="M152.93 214.579a1.38 1.38 0 00-1.407-1.38l-9.506.184a1.38 1.38 0 10.053 2.76l9.506-.184a1.381 1.381 0 001.354-1.38z"
                                ></path>
                            </g>
                            <g id="Group_41" fill="#002220" opacity="0.2">
                                <path
                                    id="Vector_80"
                                    d="M31.514 254.234c.704-1.719 2.13-3.829 3.892-5.944l-11.509.224a3.813 3.813 0 01-3.618-2.422 11.246 11.246 0 00-.196 3.788l.739 6.087a11.271 11.271 0 004.066 7.376l.477.388a11.26 11.26 0 005.795 2.449c-.316-3.808-.598-9.621.354-11.946z"
                                ></path>
                                <path
                                    id="Vector_81"
                                    d="M217.26 268.215c-.286-3.784-.476-9.006.414-11.178 1.486-3.63 6.154-8.988 10.522-12.485l-152.137 2.95c2.2 3.379 4.007 6.969 4.804 9.463.626 1.961.444 6.696.236 9.814l136.161 1.436z"
                                ></path>
                                <path
                                    id="Vector_82"
                                    d="M267.023 259.766c.409 1.284.473 3.757.41 6.186a13.31 13.31 0 002.719-2.707l1.281-1.731a13.373 13.373 0 002.629-7.962v-4.49c0-2.448-.672-4.848-1.942-6.94l-1.152-1.897c-.131 1.958-1.722 3.53-3.721 3.568l-10.017.194c4.186 4.146 8.44 11.543 9.793 15.779z"
                                ></path>
                            </g>
                            <g id="Group_42" opacity="0.2">
                                <g id="Group_43">
                                    <path
                                        id="Vector_83"
                                        fill="#fff"
                                        d="M89.448 255.333a1.84 1.84 0 011.804-1.875l117.167-2.272a1.84 1.84 0 01.071 3.679l-117.166 2.272a1.84 1.84 0 01-1.876-1.804z"
                                    ></path>
                                </g>
                            </g>
                            <path
                                id="Vector_84"
                                fill="#002220"
                                d="M98.956 188.888l-6.431 5.907c-4.424 4.063-1.476 11.438 4.528 11.334l1.903-.033v-17.208z"
                                opacity="0.6"
                            ></path>
                            <g id="Group_44">
                                <path
                                    id="Vector_85"
                                    fill="url(#paint21_linear_3957_85229)"
                                    d="M107.755 190.429h-3.251a2.94 2.94 0 00-2.941 2.941v4.523l-5.436 1.051a1.43 1.43 0 00-1.158 1.404v1.286a1.43 1.43 0 001.946 1.334l5.162-1.998c.649.958 1.828 1.512 3.064 1.237l3.067-.682a2.087 2.087 0 001.634-2.037v-6.972a2.086 2.086 0 00-2.087-2.087z"
                                ></path>
                            </g>
                        </g>
                    </g>
                    <g id="Character">
                        <g id="Group_45">
                            <path
                                id="Vector_86"
                                fill="#F7F1EB"
                                d="M307.732 144.281a214.043 214.043 0 01-5.52-4.789l-5.439-4.877c-.893-.733-1.688-2.038-1.834-3.288-.174-1.245.07-2.262.355-3.088.606-1.652 1.48-2.782 2.351-3.85 1.79-2.088 3.745-3.688 5.924-5.155l2.072 2.424c-1.482 1.778-2.969 3.703-4.077 5.566-.549.916-.993 1.894-1.12 2.586-.071.339-.021.568.02.533.056-.037-.054-.237.007-.204l1.476 1.069 1.486 1.129c.985.755 1.953 1.519 2.908 2.297a134.74 134.74 0 015.619 4.787l-4.228 4.86z"
                            ></path>
                            <path
                                id="Vector_87"
                                fill="#FFDE7D"
                                d="M311.267 145.996c-3.271.48-10.838-7.19-10.838-7.19l5.187-5.938s4.07 2.529 7.002 5.599c3.008 3.153 2.239 7.001-1.351 7.529z"
                            ></path>
                            <path
                                id="Vector_88"
                                fill="#FFEAAD"
                                d="M311.267 145.996c-3.271.48-10.838-7.19-10.838-7.19l5.187-5.938s4.07 2.529 7.002 5.599c3.008 3.153 2.239 7.001-1.351 7.529z"
                            ></path>
                            <g id="Group_46" fill="#F9E8DA">
                                <path
                                    id="Vector_89"
                                    d="M302.873 120.61l.864-4.179 3.768 2.863s-.997 3.682-2.871 3.089l-1.761-1.773z"
                                ></path>
                                <path
                                    id="Vector_90"
                                    d="M306.621 113.342l2.404 3.561-1.521 2.391-3.767-2.863 2.884-3.089z"
                                ></path>
                            </g>
                            <path
                                id="Vector_91"
                                fill="#002220"
                                d="M309.847 141.852c-1.532 1.299-4.957.12-6.981-.76 2.387 2.115 5.954 4.909 8.086 4.903.252-2.299.357-5.384-1.105-4.143z"
                                opacity="0.2"
                            ></path>
                            <path
                                id="Vector_92"
                                fill="#F9E8DA"
                                d="M289.503 272.117l-4.22-1.533 2.324-10.171 4.22 1.534-2.324 10.17z"
                            ></path>
                            <path
                                id="Vector_93"
                                fill="#F9E8DA"
                                d="M322.791 277.722h-4.48l-1.271-10.375h4.479l1.272 10.375z"
                            ></path>
                            <path
                                id="Vector_94"
                                fill="#FFDE7D"
                                d="M317.724 277.203h5.462c.183 0 .343.127.384.306l.925 3.982a.652.652 0 01-.648.797c-1.757-.03-3.04-.133-5.254-.133-1.362 0-5.467.141-7.347.141-1.839 0-2.145-1.859-1.376-2.027 3.444-.756 6.035-1.797 7.13-2.79.196-.179.455-.276.724-.276z"
                            ></path>
                            <path
                                id="Vector_95"
                                fill="#FFDE7D"
                                d="M285.128 269.726l4.794 1.028c.18.039.309.196.313.379l.069 4.079a.644.644 0 01-.791.643c-1.723-.401-4.202-1.038-6.376-1.504-2.542-.545-4.117-1.059-7.098-1.698-1.802-.386-1.93-2.311-1.141-2.315 3.588-.013 5.761.69 9.102-.499.363-.128.749-.194 1.128-.113z"
                            ></path>
                            <g id="Group_47" fill="#002220">
                                <path
                                    id="Vector_96"
                                    d="M287.607 260.417l-1.199 5.241 4.222 1.534 1.198-5.241-4.221-1.534z"
                                    opacity="0.2"
                                ></path>
                                <path
                                    id="Vector_97"
                                    d="M321.522 267.35h-4.482l.658 5.348h4.481l-.657-5.348z"
                                    opacity="0.2"
                                ></path>
                            </g>
                            <path
                                id="Vector_98"
                                fill="#FFDE7D"
                                d="M335.886 141.455c-1.964 4.735-3.444 10.546-4.591 15.181a142.087 142.087 0 00-2.002 9.609c-1.214 6.942-1.705 12.293-2.131 14.071a51728.74 51728.74 0 01-23.503-4.758c-.172-25.723 7.568-37.951 7.568-37.951s2.687-.18 5.758.042c.405.026.815.066 1.231.112 2.692.294 7.019 1.271 9.993 2.022.625.158 1.255.336 1.866.518 3.111.933 5.811 1.154 5.811 1.154z"
                            ></path>
                            <path
                                id="Vector_99"
                                fill="#FFDE7D"
                                d="M335.886 141.455c-1.964 4.735-3.444 10.546-4.591 15.181a142.087 142.087 0 00-2.002 9.609c-1.214 6.942-1.705 12.293-2.131 14.071a51728.74 51728.74 0 01-23.503-4.758c-.172-25.723 7.568-37.951 7.568-37.951s2.687-.18 5.758.042c.405.026.815.066 1.231.112 2.692.294 7.019 1.271 9.993 2.022.625.158 1.255.336 1.866.518 3.111.933 5.811 1.154 5.811 1.154z"
                            ></path>
                            <path
                                id="Vector_100"
                                fill="#FFEAAD"
                                d="M318.531 142.645c-.921 1.776-6.405 23.713-7.889 28.46.175 1.058.403 2.126.683 3.205a60.887 60.887 0 012.385-2.715c1.009-4.631 4.892-26.497 5.915-28.782l-1.094-.168z"
                            ></path>
                            <path
                                id="Vector_101"
                                fill="#FFDE7D"
                                d="M318.498 144.018l-.382-2.371 1.726-.375 1.049.969-2.393 1.777z"
                            ></path>
                            <path
                                id="Vector_102"
                                fill="#F9E8DA"
                                d="M329.382 128.327c-1.249 3.089-3.011 8.854-1.175 11.456-1.975 1.364-4.696 2.316-8.292 1.506-2.754-.62-2.522-2.582-1.699-3.529 3.75-.131 4.168-2.81 4.025-5.435l7.141-3.998z"
                            ></path>
                            <g id="Group_48">
                                <path
                                    id="Vector_103"
                                    fill="#fff"
                                    d="M319.842 141.272s.776 1.552 1.735 3.125c2.015-.582 6.808-2.264 7.145-4.689-.089-.746-.923-1.362-.923-1.362s-3.222 1.983-7.957 2.926z"
                                ></path>
                            </g>
                            <g id="Group_49">
                                <path
                                    id="Vector_104"
                                    fill="#fff"
                                    d="M319.842 141.272s-1.668.858-2.915 2.133c-1.114-1.189.567-5.476 1.164-5.862 1.199-.446 1.9-.217 1.9-.217s-2.123 2.989-.149 3.946z"
                                ></path>
                            </g>
                            <path
                                id="Vector_105"
                                fill="#002220"
                                d="M326.47 129.958l-4.226 2.364c.035.622.036 1.245-.034 1.836 1.408.079 3.578-1.043 4-2.448.21-.701.313-1.395.26-1.752z"
                                opacity="0.2"
                            ></path>
                            <path
                                id="Vector_106"
                                fill="#002220"
                                d="M321.228 117.144c-1.221 1.333-2.448 4.67.222 4.645 2.67-.026 1.876-6.937-.222-4.645z"
                            ></path>
                            <path
                                id="Vector_107"
                                fill="#F9E8DA"
                                d="M331.505 124.508c-1.633 4.129-2.38 6.631-5.211 8.079-4.258 2.177-8.824-1.505-8.456-6.052.331-4.093 2.946-10.158 7.614-10.448 4.597-.284 7.686 4.292 6.053 8.421z"
                            ></path>
                            <path
                                id="Vector_108"
                                fill="#002220"
                                d="M322.357 118.771c-2.773-1.384-3.643-5.251.949-6.247.103-.022.207-.041.312-.058 1.037-.163 1.89.598 2.052 1.635.013.088.036.178.07.27.472-.485 1.542-1.173 2.859-1.266 1.071-.076 1.86.886 1.79 1.957-.047.72.281 1.42 1.19 1.445 2.408.065 5.207 1.815 3.811 4.234 2.034 2.545-1.136 6.079-5.631 6.44-1.221-2.468.754-6.117.754-6.117-2.261-.044-5.644-1.039-8.156-2.293z"
                            ></path>
                            <path
                                id="Vector_109"
                                fill="#002220"
                                d="M334.715 120.832s2.053-.288 1.355-2.217c1.536 1.284.414 2.468-1.355 2.217z"
                            ></path>
                            <path
                                id="Vector_110"
                                fill="#002220"
                                d="M334.294 121.148s1.99-.579 2.145 1.467c.873-1.801-.636-2.421-2.145-1.467z"
                            ></path>
                            <path
                                id="Vector_111"
                                fill="#F9E8DA"
                                d="M331.985 128.551a4.168 4.168 0 01-2.738 1.677c-1.461.214-2.018-1.146-1.465-2.435.497-1.16 1.857-2.668 3.252-2.293 1.376.371 1.742 1.937.951 3.051z"
                            ></path>
                            <g id="Group_50">
                                <path
                                    id="Vector_112"
                                    fill="#002220"
                                    d="M285.17 264.111l7.147 2.592s5.583-24.582 8.55-38.21c.904-4.146 2.719-9.846 4.868-15.92 2.712-7.651 5.949-15.885 8.562-22.322 2.757-6.779 4.809-11.564 4.809-11.564l-15.451-3.129s-11.79 33.928-14.439 47.337c-2.755 13.942-4.046 41.216-4.046 41.216z"
                                ></path>
                            </g>
                            <path
                                id="Vector_113"
                                fill="#002220"
                                d="M305.736 212.572c2.712-7.651 5.95-15.884 8.562-22.322l-1.196-3.698c-4.42 4.092-7.179 18.19-7.366 26.02z"
                                opacity="0.6"
                            ></path>
                            <g id="Group_51">
                                <path
                                    id="Vector_114"
                                    fill="#002220"
                                    d="M311.566 177.372s-2.759 38.684-2.926 51.829c-.183 14.395 6.549 42.587 6.549 42.587h8.205s-3.277-25.438-2.187-39.699c1.22-15.947 5.525-51.645 5.525-51.645l-15.166-3.072z"
                                ></path>
                            </g>
                            <path
                                id="Vector_115"
                                fill="#002220"
                                d="M314.636 271.84h9.662l-.029-2.887-10.088-.583.455 3.47z"
                            ></path>
                            <path
                                id="Vector_116"
                                fill="#002220"
                                d="M284.215 264.019l8.559 2.971.931-2.762-9.359-3.409-.131 3.2z"
                            ></path>
                            <path
                                id="Vector_117"
                                fill="#002220"
                                d="M324.737 123.535c-.112.343-.385.56-.608.485-.225-.076-.315-.415-.204-.758.113-.344.385-.561.608-.485.225.075.316.415.204.758z"
                            ></path>
                            <path
                                id="Vector_118"
                                fill="#002220"
                                d="M320.864 122.229c-.112.343-.384.561-.608.485-.223-.075-.315-.415-.202-.758.112-.343.384-.56.608-.485.223.075.313.414.202.758z"
                            ></path>
                            <path
                                id="Vector_119"
                                fill="#F7F1EB"
                                d="M322.04 122.814s-1.421 1.638-2.588 2.272c.423.738 1.539.825 1.539.825l1.049-3.097z"
                            ></path>
                            <path
                                id="Vector_120"
                                fill="#002220"
                                d="M326.339 122.684a.212.212 0 01-.161-.142c-.309-.9-1.063-1.106-1.07-1.108a.213.213 0 01-.154-.259.21.21 0 01.257-.152c.04.01.985.269 1.367 1.385a.211.211 0 01-.239.276z"
                            ></path>
                            <path
                                id="Vector_121"
                                fill="#002220"
                                d="M319.63 120.331a.215.215 0 01-.114-.358c.815-.84 1.772-.65 1.816-.647a.214.214 0 01.167.25.208.208 0 01-.246.167c-.04-.01-.779-.147-1.434.528a.203.203 0 01-.189.06z"
                            ></path>
                            <path
                                id="Vector_122"
                                fill="#FFDE7D"
                                d="M316.25 277.986c-.341 0-.66-.058-.864-.228-.162-.135-.238-.324-.226-.561.009-.177.102-.261.179-.3.492-.256 1.946.633 2.111.735a.107.107 0 01.05.107.106.106 0 01-.079.086c-.31.076-.757.161-1.171.161zm-.675-.927a.304.304 0 00-.137.026c-.029.015-.062.043-.066.125-.009.168.039.294.148.385.247.205.832.234 1.591.081-.508-.292-1.192-.617-1.536-.617z"
                            ></path>
                            <path
                                id="Vector_123"
                                fill="#FFDE7D"
                                d="M317.396 277.828a.102.102 0 01-.051-.013c-.466-.252-1.373-1.234-1.282-1.733.016-.091.082-.248.335-.279a.591.591 0 01.453.136c.525.425.645 1.719.65 1.773a.107.107 0 01-.105.116zm-.923-1.816a.387.387 0 00-.048.003c-.138.016-.149.08-.154.107-.053.295.526 1.037.99 1.387-.056-.377-.207-1.131-.542-1.404a.382.382 0 00-.246-.093z"
                            ></path>
                            <path
                                id="Vector_124"
                                fill="#FFDE7D"
                                d="M284.572 270.238l-.015.001c-.736-.011-2.029-.109-2.423-.637a.596.596 0 01-.091-.546.334.334 0 01.228-.24c.597-.186 2.176 1.09 2.354 1.235a.106.106 0 01.033.119.104.104 0 01-.086.068zm-2.196-1.227a.166.166 0 00-.041.008c-.047.015-.073.043-.089.096a.391.391 0 00.057.361c.222.296.935.493 1.938.541-.628-.481-1.51-1.05-1.865-1.006z"
                            ></path>
                            <path
                                id="Vector_125"
                                fill="#FFDE7D"
                                d="M284.571 270.238a.111.111 0 01-.076-.02c-.481-.352-1.337-1.523-1.155-2.001.044-.113.156-.239.448-.207a.786.786 0 01.54.301c.464.578.341 1.783.335 1.834a.101.101 0 01-.062.085c-.009.004-.02.006-.03.008zm-.915-2.019c-.092.012-.11.058-.116.075-.109.287.452 1.184.926 1.624.014-.357.002-1.096-.303-1.475a.575.575 0 00-.507-.224z"
                            ></path>
                            <path
                                id="Vector_126"
                                fill="#002220"
                                d="M333.143 144.018l-3.158 1.335v1.997s.547 4.032 2.271 5.493c.698-2.677 1.503-5.51 2.429-8.188-.853-.427-1.542-.637-1.542-.637z"
                                opacity="0.2"
                            ></path>
                            <path
                                id="Vector_127"
                                fill="#F7F1EB"
                                d="M333.811 142.654c1.289-.2 2.674-.386 3.999-.626a72.164 72.164 0 003.963-.722c1.282-.29 2.57-.572 3.673-.954.542-.185 1.062-.389 1.361-.551.04-.029.145-.075.139-.075-.007.01.058-.037-.003.027-.079.09-.131.166-.167.229-.131.251-.06.22-.033.098.074-.273.124-.836.105-1.409a18.45 18.45 0 00-.147-1.852c-.137-1.289-.391-2.616-.633-3.958a85.88 85.88 0 00-.866-4.038c-.284-1.355-.633-2.717-.938-4.055l2.984-1.127c.676 1.301 1.242 2.602 1.829 3.924a89.593 89.593 0 011.56 4.052 42.059 42.059 0 011.271 4.269c.169.746.319 1.51.423 2.331.095.828.184 1.684.038 2.803-.093.569-.211 1.232-.718 2.081-.13.211-.289.428-.49.646-.18.193-.255.253-.392.384-.258.246-.461.38-.7.565-.88.61-1.653.982-2.42 1.313a31.312 31.312 0 01-4.415 1.458 59.985 59.985 0 01-8.669 1.581l-.754-6.394z"
                            ></path>
                            <path
                                id="Vector_128"
                                fill="#FFDE7D"
                                d="M331.176 148.538c2.615 1.994 12.157-.064 12.157-.064l-1.583-8.236s-4.428.324-8.49 1.497c-4.425 1.279-5.252 4.387-2.084 6.803z"
                            ></path>
                            <path
                                id="Vector_129"
                                fill="#FFEAAD"
                                d="M331.176 148.538c2.615 1.994 12.157-.064 12.157-.064l-1.583-8.236s-4.428.324-8.49 1.497c-4.425 1.279-5.252 4.387-2.084 6.803z"
                            ></path>
                            <g id="Group_52" fill="#F9E8DA">
                                <path
                                    id="Vector_130"
                                    d="M347.748 124.67l.32-4.337-5.142 1.957s.549 3.744 2.576 3.658l2.246-1.278z"
                                ></path>
                                <path
                                    id="Vector_131"
                                    d="M346.09 117.369l-4.177 1.815 1.013 3.106 5.143-1.957-1.979-2.964z"
                                ></path>
                            </g>
                            <path
                                id="Vector_132"
                                fill="#fff"
                                d="M320.081 127.4s1.06-.154 3.957.108c-.446.875-1.166 1.557-2.648 1.409-1.481-.149-1.309-1.517-1.309-1.517z"
                            ></path>
                        </g>
                    </g>
                    <path
                        id="Light"
                        fill="#fff"
                        d="M-132.5 206c53.21 4.775 129.805 17.229 148.345 20.305A4.972 4.972 0 0120 231.223v6.882a5 5 0 01-3.776 4.848L-132.5 280.5V206z"
                    ></path>
                    <g id="Car_Keys1">
                        <g id="Car_Keys2">
                            <g id="Car_Keys3">
                                <path
                                    id="Vector_133"
                                    fill="#002220"
                                    d="M176.628 106.621l-2.006-.831a3.49 3.49 0 01-2.102-3.831l.406-2.306a3.505 3.505 0 00-.377-2.285l-3.76-6.853a3.488 3.488 0 00-1.725-1.547l-3.197-1.324a3.492 3.492 0 01-2.102-3.832l.683-3.883a3.492 3.492 0 00-.377-2.285L156 66.577l-7.697 4.222-7.696 4.223 6.072 11.067c.381.697.99 1.242 1.724 1.546l3.643 1.51a3.492 3.492 0 012.102 3.83l-.601 3.41a3.492 3.492 0 00.377 2.285l3.76 6.853c.381.697.99 1.242 1.724 1.546l2.163.896a3.491 3.491 0 012.102 3.832l-.376 2.138a3.493 3.493 0 002.102 3.832l1.355.561a3.491 3.491 0 012.102 3.832 3.491 3.491 0 002.102 3.831l4.235 1.756 3.345-1.835 3.346-1.835.796-4.515a3.493 3.493 0 00-2.102-3.832 3.49 3.49 0 01-2.102-3.831l.255-1.445a3.496 3.496 0 00-2.103-3.833z"
                                ></path>
                                <path
                                    id="Vector_134"
                                    fill="#fff"
                                    d="M176.628 106.621l-2.006-.831a3.49 3.49 0 01-2.102-3.831l.406-2.306a3.505 3.505 0 00-.377-2.285l-3.76-6.853a3.488 3.488 0 00-1.725-1.547l-3.197-1.324a3.492 3.492 0 01-2.102-3.832l.683-3.883a3.492 3.492 0 00-.377-2.285L156 66.577l-7.697 4.222-7.696 4.223 6.072 11.067c.381.697.99 1.242 1.724 1.546l3.643 1.51a3.492 3.492 0 012.102 3.83l-.601 3.41a3.492 3.492 0 00.377 2.285l3.76 6.853c.381.697.99 1.242 1.724 1.546l2.163.896a3.491 3.491 0 012.102 3.832l-.376 2.138a3.493 3.493 0 002.102 3.832l1.355.561a3.491 3.491 0 012.102 3.832 3.491 3.491 0 002.102 3.831l4.235 1.756 3.345-1.835 3.346-1.835.796-4.515a3.493 3.493 0 00-2.102-3.832 3.49 3.49 0 01-2.102-3.831l.255-1.445a3.496 3.496 0 00-2.103-3.833z"
                                    opacity="0.6"
                                ></path>
                                <path
                                    id="Vector_135"
                                    fill="#002220"
                                    d="M162.449 79.929a3.499 3.499 0 00-.377-2.286L156 66.576l-3.848 2.112-3.848 2.11-7.696 4.224 4.517 8.231 7.696-4.222 25.72 46.88 3.346-1.835.796-4.515a3.49 3.49 0 00-2.102-3.832 3.491 3.491 0 01-2.102-3.832l.255-1.445a3.49 3.49 0 00-2.102-3.831l-2.005-.832a3.491 3.491 0 01-2.103-3.831l.407-2.306a3.499 3.499 0 00-.378-2.285l-3.759-6.854a3.493 3.493 0 00-1.725-1.546l-3.197-1.324a3.49 3.49 0 01-2.102-3.832l.679-3.882z"
                                    opacity="0.2"
                                ></path>
                                <g id="Group_55" fill="#002220">
                                    <g id="Group_56">
                                        <path
                                            id="Vector_136"
                                            d="M158.75 42.21c-3.483-.605-14.388 2.082-19.232 4.739-4.844 2.658-12.97 10.41-14.331 13.674-3.023 7.248 1.883 19.84 10.888 19.844 3.643 0 13.527-4.848 17.96-7.059 4.432-2.21 13.523-8.266 15.602-11.354 5.137-7.632-3.151-18.499-10.887-19.844zm-8.293 8.397l-13.732 7.534c-1.011.555-2.009-.42-1.352-1.325 2.011-2.775 4.33-5.253 6.134-6.242 1.804-.99 5.14-1.614 8.56-1.819 1.115-.068 1.4 1.298.39 1.852z"
                                        ></path>
                                        <path
                                            id="Vector_137"
                                            d="M158.75 42.21c-3.483-.605-14.388 2.082-19.232 4.739-4.844 2.658-12.97 10.41-14.331 13.674-3.023 7.248 1.883 19.84 10.888 19.844 3.643 0 13.527-4.848 17.96-7.059 4.432-2.21 13.523-8.266 15.602-11.354 5.137-7.632-3.151-18.499-10.887-19.844zm-8.293 8.397l-13.732 7.534c-1.011.555-2.009-.42-1.352-1.325 2.011-2.775 4.33-5.253 6.134-6.242 1.804-.99 5.14-1.614 8.56-1.819 1.115-.068 1.4 1.298.39 1.852z"
                                            opacity="0.6"
                                        ></path>
                                    </g>
                                    <path
                                        id="Vector_138"
                                        d="M155.396 44.05c-3.483-.606-14.388 2.081-19.232 4.738-4.844 2.658-12.97 10.41-14.331 13.674-3.022 7.247 1.883 19.84 10.888 19.844 3.643 0 13.527-4.848 17.96-7.059 4.431-2.21 13.523-8.265 15.602-11.354 5.137-7.632-3.15-18.498-10.887-19.844zm-8.292 8.397l-13.733 7.535c-1.011.554-2.009-.42-1.352-1.325 2.011-2.775 4.33-5.253 6.134-6.242 1.804-.99 5.139-1.614 8.56-1.819 1.115-.068 1.4 1.297.391 1.851z"
                                    ></path>
                                </g>
                            </g>
                            <g id="Group_57">
                                <g id="Group_58">
                                    <path
                                        id="Vector_139"
                                        fill="#002220"
                                        d="M139.101 58.392c3.056 9.691-2.14 20.048-11.622 23.451-9.503 3.41-20.311-1.462-23.972-10.87-4.114-10.578 1.509-23.087 11.486-26.234l1.529-.481a1.382 1.382 0 01.832 2.636l-1.529.482c-8.391 2.645-13.145 13.145-9.891 22.193 2.822 7.845 11.508 12.284 19.53 10.016 8.773-2.48 13.721-11.73 10.998-20.363-2.964-9.402-12.298-10.126-19.39-7.89l-2.098.661a1.383 1.383 0 01-.831-2.636l2.097-.661c10.775-3.397 19.961.5 22.861 9.696z"
                                    ></path>
                                </g>
                                <g id="Group_59" opacity="0.5">
                                    <path
                                        id="Vector_140"
                                        fill="#fff"
                                        d="M139.101 58.392c3.056 9.691-2.14 20.048-11.622 23.451-9.503 3.41-20.311-1.462-23.972-10.87-4.114-10.578 1.509-23.087 11.486-26.234l1.529-.481a1.382 1.382 0 01.832 2.636l-1.529.482c-8.391 2.645-13.145 13.145-9.891 22.193 2.822 7.845 11.508 12.284 19.53 10.016 8.773-2.48 13.721-11.73 10.998-20.363-2.964-9.402-12.298-10.126-19.39-7.89l-2.098.661a1.383 1.383 0 01-.831-2.636l2.097-.661c10.775-3.397 19.961.5 22.861 9.696z"
                                    ></path>
                                </g>
                            </g>
                            <g id="Group_60">
                                <path
                                    id="Vector_141"
                                    fill="#002220"
                                    d="M141.44 81.447c-4.073-3.733-16.308-6.445-21.578-4.784-5.27 1.662-13.736 10.9-14.932 16.295-2.656 11.981 4.408 40.225 14.761 46.815 4.189 2.667 15.007.399 19.852-.688 4.846-1.087 14.616-6.296 16.656-10.823 5.047-11.186-5.712-38.525-14.759-46.815zm-11.736.385l-14.938 4.71c-1.099.347-1.951-1.005-1.155-1.837 2.444-2.55 5.152-4.687 7.114-5.306 1.963-.62 5.406-.421 8.87.266 1.13.225 1.208 1.82.109 2.167z"
                                ></path>
                                <path
                                    id="Vector_142"
                                    fill="#002220"
                                    d="M141.44 81.447c-4.073-3.733-16.308-6.445-21.578-4.784-5.27 1.662-13.736 10.9-14.932 16.295-2.656 11.981 4.408 40.225 14.761 46.815 4.189 2.667 15.007.399 19.852-.688 4.846-1.087 14.616-6.296 16.656-10.823 5.047-11.186-5.712-38.525-14.759-46.815zm-11.736.385l-14.938 4.71c-1.099.347-1.951-1.005-1.155-1.837 2.444-2.55 5.152-4.687 7.114-5.306 1.963-.62 5.406-.421 8.87.266 1.13.225 1.208 1.82.109 2.167z"
                                    opacity="0.6"
                                ></path>
                                <path
                                    id="Vector_143"
                                    fill="#002220"
                                    d="M137.792 82.597c-4.074-3.733-16.308-6.446-21.578-4.784s-13.737 10.9-14.932 16.295c-2.656 11.981 4.409 40.225 14.761 46.815 4.189 2.666 15.007.398 19.853-.689 4.846-1.087 14.615-6.296 16.656-10.823 5.046-11.186-5.712-38.524-14.76-46.814zm-11.736.385l-14.938 4.71c-1.099.346-1.951-1.005-1.154-1.837 2.444-2.55 5.152-4.687 7.114-5.306 1.963-.62 5.406-.422 8.871.266 1.128.225 1.206 1.821.107 2.167z"
                                ></path>
                                <g id="Group_61" fill="#C4EDDE">
                                    <path
                                        id="Vector_144"
                                        d="M139.567 100.438l-29.424 9.277a70.07 70.07 0 002.889 11.812l31.006-9.776a68.771 68.771 0 00-4.471-11.313z"
                                    ></path>
                                    <path
                                        id="Vector_145"
                                        d="M114.192 124.649c1.845 4.551 4.087 8.245 6.421 9.73.215.106 2.738 1.156 13.492-1.257 3.825-.847 10.211-4.883 11.03-6.595 1.121-2.49.858-6.776-.248-11.556l-30.695 9.678z"
                                    ></path>
                                    <path
                                        id="Vector_146"
                                        d="M137.949 97.46c-1.462-2.484-2.994-4.556-4.474-5.912-2.208-2.023-11.037-3.98-13.893-3.08-2.856.901-8.966 7.568-9.613 10.49-.429 1.935-.481 4.509-.233 7.398l28.213-8.896z"
                                    ></path>
                                </g>
                                <g id="Group_62">
                                    <g id="Group_63">
                                        <path
                                            id="Vector_147"
                                            fill="#FFDE7D"
                                            d="M129.581 109.367l-6.055 1.909 1.392 4.414 6.055-1.909-1.392-4.414z"
                                        ></path>
                                        <g id="Group_64">
                                            <path
                                                id="Vector_148"
                                                fill="#FFDE7D"
                                                d="M129.085 110.66l-.821.259-.686-2.176a1.458 1.458 0 00-2.78.877l.687 2.176-.822.259-.685-2.175a2.322 2.322 0 011.513-2.909 2.323 2.323 0 012.909 1.514l.685 2.175z"
                                            ></path>
                                        </g>
                                    </g>
                                    <path
                                        id="Vector_149"
                                        fill="#FFDE7D"
                                        d="M134.177 123.966l-6.055 1.909 1.392 4.413 6.055-1.909-1.392-4.413z"
                                    ></path>
                                    <g id="Group_65">
                                        <path
                                            id="Vector_150"
                                            fill="#FFDE7D"
                                            d="M130.086 126.386l-.822.259-.686-2.175a2.322 2.322 0 011.514-2.908 2.322 2.322 0 012.909 1.514l-.821.259a1.458 1.458 0 00-2.781.877l.687 2.174z"
                                        ></path>
                                    </g>
                                    <g id="Group_66">
                                        <path
                                            id="Vector_151"
                                            fill="#FFDE7D"
                                            d="M126.732 92.88l-2.515.793-3.688-1.224 2.398 7.607 2.319-3.118 2.515-.793-1.029-3.265z"
                                        ></path>
                                        <g id="Group_67">
                                            <path
                                                id="Vector_152"
                                                fill="#FFDE7D"
                                                d="M119.606 100.991a5.494 5.494 0 01-2.333-7.403l1.017.53a4.345 4.345 0 001.847 5.856l-.531 1.017z"
                                            ></path>
                                        </g>
                                        <g id="Group_68">
                                            <path
                                                id="Vector_153"
                                                fill="#FFDE7D"
                                                d="M120.858 98.588a2.759 2.759 0 01-1.367-1.63 2.76 2.76 0 01.185-2.118l1.018.53a1.612 1.612 0 00-.109 1.243c.131.415.416.754.802.956l-.529 1.019z"
                                            ></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
                <defs>
                    <linearGradient
                        id="paint0_linear_3957_85229"
                        x1="145.609"
                        x2="184.547"
                        y1="38.853"
                        y2="39.418"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#4AA09B"></stop>
                        <stop offset="1" stopColor="#41CFC7"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_3957_85229"
                        x1="148.03"
                        x2="312.436"
                        y1="38.853"
                        y2="48.956"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#4AA09B"></stop>
                        <stop offset="1" stopColor="#41CFC7"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear_3957_85229"
                        x1="160.538"
                        x2="305.433"
                        y1="39.67"
                        y2="49.003"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#4AA09B"></stop>
                        <stop offset="1" stopColor="#41CFC7"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint3_linear_3957_85229"
                        x1="201.345"
                        x2="209.751"
                        y1="31.37"
                        y2="31.903"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#4AA09B"></stop>
                        <stop offset="1" stopColor="#41CFC7"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint4_linear_3957_85229"
                        x1="209.109"
                        x2="220.339"
                        y1="31.266"
                        y2="32.188"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#4AA09B"></stop>
                        <stop offset="1" stopColor="#41CFC7"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint5_linear_3957_85229"
                        x1="220.119"
                        x2="231.572"
                        y1="31.37"
                        y2="32.362"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#4AA09B"></stop>
                        <stop offset="1" stopColor="#41CFC7"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint6_linear_3957_85229"
                        x1="230.842"
                        x2="241.117"
                        y1="31.37"
                        y2="32.167"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#4AA09B"></stop>
                        <stop offset="1" stopColor="#41CFC7"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint7_linear_3957_85229"
                        x1="201.345"
                        x2="209.751"
                        y1="31.37"
                        y2="31.903"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#4AA09B"></stop>
                        <stop offset="1" stopColor="#41CFC7"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint8_linear_3957_85229"
                        x1="209.109"
                        x2="220.339"
                        y1="31.266"
                        y2="32.188"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#4AA09B"></stop>
                        <stop offset="1" stopColor="#41CFC7"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint9_linear_3957_85229"
                        x1="220.119"
                        x2="231.572"
                        y1="31.37"
                        y2="32.362"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#4AA09B"></stop>
                        <stop offset="1" stopColor="#41CFC7"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint10_linear_3957_85229"
                        x1="230.842"
                        x2="241.117"
                        y1="31.37"
                        y2="32.167"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#4AA09B"></stop>
                        <stop offset="1" stopColor="#41CFC7"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint11_linear_3957_85229"
                        x1="202"
                        x2="292.933"
                        y1="126.031"
                        y2="144.218"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#4AA09B"></stop>
                        <stop offset="1" stopColor="#41CFC7"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint12_linear_3957_85229"
                        x1="209.419"
                        x2="219.432"
                        y1="137.325"
                        y2="138.082"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#4AA09B"></stop>
                        <stop offset="1" stopColor="#41CFC7"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint13_linear_3957_85229"
                        x1="218.234"
                        x2="226.681"
                        y1="136.336"
                        y2="136.883"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#4AA09B"></stop>
                        <stop offset="1" stopColor="#41CFC7"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint14_linear_3957_85229"
                        x1="227.178"
                        x2="235.625"
                        y1="135.387"
                        y2="135.933"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#4AA09B"></stop>
                        <stop offset="1" stopColor="#41CFC7"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint15_linear_3957_85229"
                        x1="236.123"
                        x2="245.853"
                        y1="134.436"
                        y2="135.162"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#4AA09B"></stop>
                        <stop offset="1" stopColor="#41CFC7"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint16_linear_3957_85229"
                        x1="245.302"
                        x2="255.647"
                        y1="133.291"
                        y2="134.125"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#4AA09B"></stop>
                        <stop offset="1" stopColor="#41CFC7"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint17_linear_3957_85229"
                        x1="254.197"
                        x2="263.942"
                        y1="131.996"
                        y2="132.713"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#4AA09B"></stop>
                        <stop offset="1" stopColor="#41CFC7"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint18_linear_3957_85229"
                        x1="264.286"
                        x2="273.085"
                        y1="131.239"
                        y2="131.816"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#4AA09B"></stop>
                        <stop offset="1" stopColor="#41CFC7"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint19_linear_3957_85229"
                        x1="272.704"
                        x2="282.914"
                        y1="130.533"
                        y2="131.331"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#4AA09B"></stop>
                        <stop offset="1" stopColor="#41CFC7"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint20_linear_3957_85229"
                        x1="20"
                        x2="289.397"
                        y1="175.467"
                        y2="222.202"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#4AA09B"></stop>
                        <stop offset="1" stopColor="#41CFC7"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint21_linear_3957_85229"
                        x1="94.969"
                        x2="111.081"
                        y1="192.707"
                        y2="194.172"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#4AA09B"></stop>
                        <stop offset="1" stopColor="#41CFC7"></stop>
                    </linearGradient>
                </defs>
            </svg>
        </div>
    )
}
