import Footer from '../../../../components/Footer/Footer';
import Header from '../../../../components/Header/Header';
import styles from '../Blogs.module.css';
import car from '../../../../images/vectors/blogs/car_with_shadow.svg'
import img1 from '../../../../images/vectors/homescreen/knoledge/img1.svg'
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import OtherBlogs from './OtherBlogs';

import car_with_path from '../../../../images/vectors/blogs/Car.svg'
import { Link } from 'react-router-dom';
import { scrollToTop } from '../../../../reusable/scrollToTop/ScrollToTopButton';

const Blog1 = () => {
    const carRef = useRef(null);
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        const car = carRef.current;
        gsap.to(car, {
            x: '-120%',
            y: '200%',
            scrollTrigger: {
                trigger: car,
                scroller: 'body',
                start: '100% 50%',
                end: '10% 90%',
                scrub: 2,
                pin: true,
            },
        });
    }, []);

    return (
        <>
            <div className={styles.curve_container}>
                <Header />
                <div className={styles.container}>
                    {/* <div className={styles.car_design} ref={carRef}>
                    <img src={car} alt="" />
                </div>
                <div className={styles.blog_design}></div> */}
                    <div className={styles.flex_box}>
                        <div className={styles.blog}>
                            <div className={styles.blog_container}>
                                <div className={styles.blog_heading_main}> Should you buy a used car?</div>
                                <div className={styles.blog_sub_heading}>A decision worth considering</div>

                                <div className={styles.mobile_image}>
                                    <img src={img1} alt="mobile_image_nxcar" />
                                </div>

                                <div className={styles.blog_content}>
                                    Imagine this: You love your bike. It's been great for getting you to work and dodging through traffic. But, you can't help missing how comfy a car ride is when you see cars passing by. It feels like a dream that's hard to reach.
                                    Even if you think about buying a car, you are unsure about spending a lot on a new car. The big question is, should you consider buying a used car?
                                </div>
                                <div className={styles.blog_content}>
                                    Buying a used car is becoming a popular choice in India with over 4.4 Mn used cars sold in 2023 and is expected to double in size by 2024. . This boom is a clear sign: used cars are in, and they're here to stay.Buying a used car is becoming a popular choice in India with over 4.4 Mn used cars sold in 2023 and is expected to double in size by 2024. . This boom is a clear sign: used cars are in, and they're here to stay.
                                </div>
                                <div className={styles.blog_content}>
                                    It's not just about saving money anymore; it's about making a smart decision.
                                </div>
                            </div>
                            <div className={styles.blog_container2}>
                                <div className={styles.blogs_right}>
                                    <div className={styles.blog_heading}>
                                        Why Consider a Used Car?
                                    </div>

                                    <div className={styles.blog_content}>
                                        First off, when you buy a new car, its value drops quickly in the first few years. But with a used car, this big drop in value has already happened, so what you pay is closer to the car's value over time. Plus, insurance for a used car can cost less. And with so many cars out there, finding one that fits your budget and style is easier than ever.
                                    </div>

                                    <div className={styles.blog_heading}>
                                        Making the Choice</div>
                                    <div className={styles.blog_content}>
                                        The thought of buying a car, especially a used one, can seem tricky. There are concerns about the car's past, its condition, and the paperwork. But with the right information and a bit of help from experts like Nxcar, it doesn't have to be. The benefits of buying used—like saving money, slower depreciation, and lower insurance costs—make it worth considering.
                                    </div>
                                </div>
                            </div>
                            <div className={styles.blog_container}>
                                <div className={styles.blog_heading}>
                                    How Nxcar Can Help
                                </div>

                                <div className={styles.blog_content}>
                                    Nxcar is here to make buying a used car as easy as buying a new one. Whether you buy a car from a friend or from a listing, we understand buying a used car comes with questions and that's why we offer services like helping with car loans, checking the car's condition, taking care of paperwork like RC and insurance transfer, and even providing extended warranties. With Nxcar, you get peace of mind knowing you're making a well-informed choice.
                                </div>
                                <div className={styles.blog_content}>
                                    In the end, buying a used car in India is becoming a story of smart shopping. It's about seeing the value in a car that's new to you, without the hefty price tag of a brand-new model. And with Nxcar ready to guide you, finding your dream car might be easier than you think.

                                    <div className={styles.green_line}>
                                        So, as you think about your next car purchase, maybe it's time to ask yourself: Is a used car the smart choice for me?
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.nxcar_handle}>
                            <div className={styles.box_heading}>
                                Buying a Used Car: Let Nxcar Handle It All
                            </div>
                            <div className={styles.car_image}>
                                <img src={car_with_path} alt="" />
                            </div>
                            <ul>
                                <li>Used Car Loan</li>
                                <li>Car Inspection</li>
                                <li>Document Verification</li>
                                <li>Insurance Transfer</li>
                                <li>RC Transfer</li>
                                <li>Extended Warranty</li>
                            </ul>
                            <Link to='/bundle' onClick={scrollToTop}>
                                <div className={styles.build_my_pack}>
                                    Build My Package Now
                                </div>
                            </Link>
                        </div>
                    </div>

                    <OtherBlogs />
                </div>
            </div>
            <div className={styles.footer}>
                <Footer />
            </div>
        </>
    )
}

export default Blog1