import React, { useEffect, useState } from 'react'
import styles from './Category.module.css'
import car_purchase from '../../../images/vectors/used_car/category/car_purchase.svg'
import car_purchase_2 from '../../../images/vectors/used_car/category/car_purchase_2.svg'
import balance from '../../../images/vectors/used_car/category/balance.svg'
import balance_2 from '../../../images/vectors/used_car/category/balance_2.svg'
import refiance from '../../../images/vectors/used_car/category/refiance.svg'
import { Link } from 'react-router-dom'
import { Modal, useMediaQuery } from '@mui/material'
import { Box } from '@mui/system'
import NewLoan from '../../../reusable/loanEligibility/NewLoanEligibility/NewLoan'



const styleMUIModal = {
    position: "absolute",
    top: "0%",
    right: "0%",
    width: '40%',
    height: '100%',
    bgcolor: "white",
    border: "0px solid #000",
    boxShadow: 24,
    outline: "none",
    p: 0,
};
const styleMUIModalMobile = {
    width: "100%",
    height: "100vh",
    top: "0%",
    right: "0%",
    height: '100vh',
    bgcolor: "white",
    border: "0px solid #000",
    boxShadow: 24,
    outline: "none",
    p: 0,
}


export default function CategoryUsedCarLoan() {
    const isMobile = useMediaQuery('(max-width: 940px)');
    const [isLoanFormVisible, setIsLoanFormVisible] = useState(false);

    const handleOpenLoanForm = () => {
        setIsLoanFormVisible(true);
        window.history.pushState(null, null, '/used-car-loan/eligibility')
    };

    const handleCloseLoanForm = () => {
        setIsLoanFormVisible(false);
    };

    useEffect(() => {
        if (window.location.pathname === '/used-car-loan/eligibility') {
            setIsLoanFormVisible(true);
        }
    }, []);


    return (
        <div className={styles.container}>

            <div className={styles.heading}>
                Explore Our Used Car Loan Options
            </div>

            <div className={styles.main_container}>

                <div className={styles.card}>
                    <div className={styles.car_image}>
                        <img className={styles.default_image} src={refiance} alt="" />
                        <img className={styles.hover_image} src={refiance} alt="" />
                    </div>
                    <div className={styles.card_heading}>
                        Refinance
                    </div>
                    <div className={styles.card_sub_heading}>
                        Refinancing your used car involves replacing your existing loan with a new one for better terms.
                    </div>
                    {/* <div
                        onClick={handleOpenLoanForm}
                        className={styles.card_cta}>
                        Apply Now
                    </div> */}
                </div>

                <div className={styles.card}>
                    <div className={styles.car_image}>
                        <img className={styles.default_image} src={car_purchase_2} alt="" />
                        <img className={styles.hover_image} src={car_purchase} alt="" />
                    </div>
                    <div className={styles.card_heading}>
                        Pre Owned Car Purchase
                    </div>
                    <div className={styles.card_sub_heading}>
                        Now apply for a used car loan from the comfort of your home and ride your car with ease.
                    </div>
                    {/* <div
                        onClick={handleOpenLoanForm}
                        className={styles.card_cta}>
                        Apply Now
                    </div> */}
                </div>

                <div className={styles.card}>
                    <div className={styles.car_image}>
                        <img className={styles.default_image} src={balance} alt="" />
                        <img className={styles.hover_image} src={balance_2} alt="" />
                    </div>
                    <div className={styles.card_heading}>
                        Top - Up
                    </div>
                    <div className={styles.card_sub_heading}>
                        Our Balance Transfer (BT) top-up lets you transfer your existing loan balance to our lending partner and top it up with additional funds.
                    </div>
                    {/* <div
                        onClick={handleOpenLoanForm}
                        className={styles.card_cta}>
                        Apply Now
                    </div> */}
                </div>

            </div>

            <div
                onClick={handleOpenLoanForm}
                className={styles.card_cta_alone}>
                Apply Now
            </div>
            {
                isLoanFormVisible && (
                    <Modal
                        open={isLoanFormVisible}
                        onClose={handleCloseLoanForm}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={isMobile ? styleMUIModalMobile : styleMUIModal}>
                            <NewLoan handleClose={handleCloseLoanForm} />
                        </Box>
                    </Modal>
                )
            }
        </div>
    )
}
