import Footer from '../../../../components/Footer/Footer';
import Header from '../../../../components/Header/Header';
import styles from '../Blogs.module.css';
import car from '../../../../images/vectors/blogs/car_with_shadow.svg'
import img2 from '../../../../images/vectors/homescreen/knoledge/img2.svg'
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import OtherBlogs from './OtherBlogs';

import car_with_path from '../../../../images/vectors/blogs/Car.svg'
import { Link } from 'react-router-dom';
import { scrollToTop } from '../../../../reusable/scrollToTop/ScrollToTopButton';


const Blog2 = () => {
  const carRef = useRef(null);
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const car = carRef.current;
    gsap.to(car, {
      x: '-120%',
      y: '200%',
      scrollTrigger: {
        trigger: car,
        scroller: 'body',
        start: '100% 50%',
        end: '10% 90%',
        scrub: 2,
        pin: true,
      },
    });
  }, []);

  return (
    <>
      <div className={styles.curve_container}>
        <Header />
        <div className={styles.container}>
          {/* <div className={styles.car_design} ref={carRef}>
          <img src={car} alt="" />
        </div>
        <div className={styles.blog_design}></div> */}

          <div className={styles.flex_box}>
            <div className={styles.blog}>

              <div className={styles.blog_container}>
                <div className={styles.blog_heading_main}> How to buy a used car?</div>
                <div className={styles.blog_sub_heading}>A Guide for the best selection</div>

                <div className={styles.mobile_image}>
                  <img src={img2} alt="mobile_image_nxcar" />
                </div>

                <div className={styles.blog_content}>
                  You've made up your mind: buying a used car is the smart move for you. Buying a second-hand car isn't just about saving money—it's a decision that can also get you behind the wheel of your dream car at a fraction of the cost. So, now that you're set on purchasing a pre-owned car, you might be wondering, what's the first step? Let's look at how you can start this process and make sure you end up with a car that's perfect for you.
                </div>
                <div className={styles.blog_heading}>
                  Finding the Right Car
                </div>
                <div className={styles.blog_content}>
                  So, where do you start looking for that perfect used car? You've got options like online listings, local dealers, or buying directly from someone you know. Each has its perks. Online, you can browse lots of cars quickly. Buying directly from someone can mean getting a car from someone you trust and up to 20% cost savings.
                </div>
              </div>
              <div className={styles.blog_container2}>
                <div className={styles.blogs_right}>
                  <div className={styles.blog_content}>
                    But, diving deeper is key. You're not just buying any car; you're finding the car that's right for you. Here's where Nxcar can help out. We offer checks on the car's condition, help with paperwork like RC and insurance transfers, and even sort out car loans. We make sure you're not just getting a good deal but the right car for you.
                  </div>
                  {/* <div className={styles.blog_heading}>
                  Finding the Right Car
                </div>
                <div className={styles.blog_content}>
                  So, where do you start looking for that perfect used car? You've got options like online listings, local dealers, or buying directly from someone you know. Each has its perks. Online, you can browse lots of cars quickly. Buying directly from someone can mean getting a car from someone you trust and up to 20% cost savings.
                </div> */}

                </div>
              </div>
              <div className={styles.blog_container}>
                {/* <div className={styles.blog_content}>
                But, diving deeper is key. You're not just buying any car; you're finding the car that's right for you. Here's where Nxcar can help out. We offer checks on the car's condition, help with paperwork like RC and insurance transfers, and even sort out car loans. We make sure you're not just getting a good deal but the right car for you.
              </div> */}
                <div className={styles.blog_heading}>
                  What to Check Before You Buy
                </div>
                <div className={styles.blog_content}>
                  When buying a used car, especially from someone you know or a local seller, remember these tips to avoid any surprises or hiccups at any point in time.
                </div>
                <div className={styles.blog_content}>
                  <ol>
                    <li>
                      Inspection: Always start with a thorough inspection, preferably by an expert. Look beyond the exterior to the engine, suspension, and interiors for signs of wear and tear.
                    </li>
                    <li>
                      History Check: Find out about the car’s past. Ensure you check the vehicle's service history, accident history, and previous ownership details.
                    </li>
                    <li>
                      Documents: Check all the car's papers, like its RC book, insurance, and pollution certificate, to make sure everything's correct and up-to-date.
                    </li>
                    <li>
                      Test Drive: Drive the car yourself. It’s the best way to see if there are any issues and if you like how it drives.
                    </li>
                    <li>
                      Price Validation: Use what you learn from the checks to talk about the price. Knowing the car's condition helps you figure out a fair price.
                    </li>
                  </ol>
                </div>
              </div>
              <div className={styles.blog_container2}>
                <div className={styles.blogs_right}>
                  <div className={styles.blog_heading}>
                    Assurance with Nxcar
                  </div>
                  <div className={styles.blog_content}>
                    While it looks like a huge checklist, having someone like Nxcar by your side can make it super simple and smooth. We're here to guide you through the process, making sure everything from the loan to the paperwork is sorted out. Let's break down how they make the journey even smoother:
                  </div>
                  <div className={styles.blog_content}>
                    <ol>
                      <li>
                        Used Car Loans: Nxcar looks into how much loan you can get, finds the best deals, talks to the bank about the car's value, takes care of all the paperwork, and handles the RC and insurance transfers for you.
                      </li>
                      <li>
                        Vehicle Inspection: We'll set up a time for a professional to check the car at your location and give you a full report. And guess what? If you decide to buy an extended warranty, the inspection comes free.
                      </li>
                      <li>
                        Extended Warranty: You can pick from different warranty plans, covering everything or just the major parts for up to a year. Once you choose, Nxcar will check the car and get you the warranty if all looks good.
                      </li>
                      <li>
                        Document Verification: We check everything - from the car's registration and who owns it to any loans or fines pending, and even if the car's on any blacklist. And this service? It's free when you get a used car loan through Nxcar.
                      </li>
                      <li>
                        Insurance Transfer: Need to move the insurance to your name? Nxcar helps with that too, including updating the premium if needed. And if you're buying through Nxcar, there's no charge for this.
                      </li>
                      <li>
                        RC Transfer: We also help with getting the No Objection Certificate (NOC), sorting out the paperwork, and changing the car's ownership, depending on where the RTO is located.
                      </li>
                    </ol>
                  </div>
                  <div className={styles.blog_content}>
                    With all these services, buying a used car becomes more than just a transaction. It's about feeling confident in your purchase, and knowing you're supported every step of the way. And with Nxcar, it's not just about getting from point A to point B; it's about enjoying every drive along the way.
                  </div>
                  <div className={styles.green_line}>
                    So, are you ready to hit the roads in a car that’s perfect for you?
                  </div>

                </div>
              </div>
            </div>
            <div className={styles.nxcar_handle}>
              <div className={styles.box_heading}>
                Buying a Used Car: Let Nxcar Handle It All
              </div>
              <div className={styles.car_image}>
                <img src={car_with_path} alt="" />
              </div>
              <ul>
                <li>Used Car Loan</li>
                <li>Car Inspection</li>
                <li>Document Verification</li>
                <li>Insurance Transfer</li>
                <li>RC Transfer</li>
                <li>Extended Warranty</li>
              </ul>
              <Link to='/bundle' onClick={scrollToTop}>
                <div className={styles.build_my_pack}>
                  Build My Package Now
                </div>
              </Link>
            </div>
          </div>
          <OtherBlogs />
        </div>
      </div>
      <div className={styles.footer}>
        <Footer />
      </div>
    </>
  )
}

export default Blog2