import React from 'react'
import styles from './Criteria.module.css';
import { Fade } from 'react-reveal';

import img1 from '../../../images/vectors/used_car/criteria/age.svg'
import img2 from '../../../images/vectors/used_car/criteria/valid.svg'
import img3 from '../../../images/vectors/used_car/criteria/history.svg'
import img4 from '../../../images/vectors/used_car/criteria/ownership.svg'
import img5 from '../../../images/vectors/used_car/criteria/residence.svg'

export default function Criteria() {
    return (
        <div className={styles.container}>
            <div className={styles.card}>
                <Fade bottom>
                    <div className={styles.left}>
                        <div className={styles.heading}>
                            Eligibility Criteria for <br />
                            <span style={{ color: "teal" }}>Used Car Loan</span>
                        </div>
                        <div className={styles.sub_heading}>
                            To be eligible for used car loan with Nxcar, you are required to meet the following criteria:
                        </div>
                    </div>
                </Fade>

                <div className={styles.right}>
                    <Fade bottom>
                        <div className={styles.my_card}>
                            <div className={styles.image}>
                                <img src={img1} alt="" />
                                <div className={styles.my_gradient}></div>
                            </div>
                            <div className={styles.text}>
                                <div className={styles.text1}>
                                    Minimum age of 21 years old
                                </div>
                                <div className={styles.text2}>
                                    Proof of identity (ID card, passport, pan card, Aadhar card or driver's license)
                                </div>
                            </div>
                        </div>
                    </Fade>


                    <Fade bottom>
                        <div className={styles.my_card}>
                            <div className={styles.image}>
                                <img src={img2} alt="" />
                                <div className={styles.my_gradient}></div>
                            </div>
                            <div className={styles.text}>
                                <div className={styles.text1}>
                                    Valid proof of income
                                </div>
                                <div className={styles.text2}>
                                    Proof of income (pay slips, bank statements, or ITR)
                                </div>
                            </div>
                        </div>
                    </Fade>


                    <Fade bottom>
                        <div className={styles.my_card}>
                            <div className={styles.image}>
                                <img src={img3} alt="" />
                                <div className={styles.my_gradient}></div>
                            </div>
                            <div className={styles.text}>
                                <div className={styles.text1}>
                                    Clear credit history
                                </div>
                                <div className={styles.text2}>
                                    Credit report for credit score
                                </div>
                            </div>
                        </div>
                    </Fade>

                    <Fade bottom>
                        <div className={styles.my_card}>
                            <div className={styles.image}>
                                <img src={img4} alt="" />
                                <div className={styles.my_gradient}></div>
                            </div>
                            <div className={styles.text}>
                                <div className={styles.text1}>
                                    Ownership of the vehicle
                                </div>
                                <div className={styles.text2}>
                                    Vehicle registration documents & insurance of the vehicle
                                </div>
                            </div>
                        </div>
                    </Fade>

                    <Fade bottom>
                        <div className={styles.my_card}>
                            <div className={styles.image}>
                                <img src={img5} alt="" />
                                <div className={styles.my_gradient}></div>
                            </div>
                            <div className={styles.text}>
                                <div className={styles.text1}>
                                    Proof of residence
                                </div>
                                <div className={styles.text2}>
                                    Owned house proof - Utility bill & rent agreement in case of rented house
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>

            </div>

        </div>
    )
}
