import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './SideNavUnique.css';
import styles from './SideNav.module.css'
import logo from '../../../images/logos/logo.svg';
import Hamburger from 'hamburger-react';
// logout modal
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import door from '../../../images/logos/logout_door.svg'
import Profile from '../../../screens/AllDashboards/Dealer/Profile/Profile';
// qrcode
import axios from 'axios';
import QRCode from 'qrcode.react';
import { MdContentCopy, MdOutlineContentCopy } from "react-icons/md";
import { FiShare2 } from "react-icons/fi";
import copy from '../../../images/button/content_copy.svg'
import {
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    XIcon,
} from "react-share";
import { jwtDecode } from "jwt-decode";

const SideNav = () => {
    //
    const [id, setId] = useState('');
    const [dealerData, setDealerData] = useState('');
    const [qrData, setQrData] = useState('');

    const token = localStorage.getItem('token');
    const decoded = jwtDecode(token);
    const dealerId = decoded.uid || decoded.id;
    // console.log('decoded', decoded)

    const [openShare, setOpenShare] = useState(false);

    const handleShare = () => setOpenShare(true);
    const handleCloseShare = () => {
        setOpenShare(false);
    };

    const dealer_url = 'https://dev.nxfin.in/api/uat.app'

    const fetchData = async () => {
        try {
            if (token) {
                const response = await axios.get(`${dealer_url}/profile?dealer_id=${dealerId}`, {
                    // const response = await axios.get(`profile?dealer_id=49`, {
                    headers: {
                        Authorization: token,
                    },
                });
                // console.log(`my dealer profile --> `, response.data);

                // if (response.data.type === 'Partnership')   {
                const fetchedId = response.data.id;

                if (fetchedId) {
                    setDealerData(response.data);
                    setId(fetchedId);
                    // FOR PROD
                    // const qrData = `https://www.nxcar.in/consumer-login?id=${fetchedId}`;
                    // setQrData(qrData);

                    // FOR DEV
                    const qrData = `https://www.nxcar.in/consumer-login?id=${fetchedId}`;
                    setQrData(qrData);

                    localStorage.setItem('my-dealer-id', fetchedId);
                    setTimeout(() => {
                        localStorage.removeItem('scannedID');
                    }, 24 * 60 * 60 * 1000);

                }
                // else if (fetchedId === undefined) {
                //     alert('You are not a Dealer or Partner');
                // }
                // }
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [token]);

    const dealerName = dealerData.name;
    const firstLetter = dealerName ? dealerName.substring(0, 1) : '';
    // 
    const [openLogoutModal, setOpenLogoutModal] = useState(false);
    const handleOpen = () => {
        setOpenLogoutModal(true);
    }
    const handleClose = () => setOpenLogoutModal(false);

    const [open, setOpen] = useState(false);
    const toggleMobileMenu = (e) => {
        e.preventDefault();
        setOpen(!open);
    };


    const handleLogout = () => {
        localStorage.removeItem('token');
    }

    return (
        <>
            {/* web view */}
            <div className="left">
                <NavLink to="/">
                    <div className="logo">
                        <img src={logo} alt="" />
                    </div>
                </NavLink>

                {/* LINKS */}
                <div className="links">
                    <NavLink className="nav-bar-link" to='/dealer-dashboard' >
                        Dashboard
                    </NavLink>
                    <NavLink className="nav-bar-link" to='/dealer-loan-form' >
                        Loan Application Form
                    </NavLink>
                    <NavLink className="nav-bar-link" to='/dealer-loan-status' >
                        Loan Status
                    </NavLink>
                    <NavLink className="nav-bar-link" to='/dealer-application-status' >
                        Application Status
                    </NavLink>
                    <NavLink className="nav-bar-link" to='/dealer-profile' >
                        My Profile
                    </NavLink>

                    <div
                        className="nav-bar-link"
                        onClick={handleOpen}
                        style={{ bottom: "5vh", position: "absolute" }}
                    >
                        Logout
                    </div>
                </div>
            </div>

            {/* mobile view */}
            <div className={styles.left_mobile}>
                <div className={styles.top}>
                    <div className={styles.left}>
                        <Link to='/'>
                            <img src={logo} alt="" />
                        </Link>
                    </div>
                    <button
                        onClick={toggleMobileMenu}
                    >
                        <div className={styles.right_mobile}>
                            <Hamburger
                                toggled={open}
                                toggle={setOpen}
                            />
                        </div>
                    </button>
                </div>

                {
                    open &&
                    <Modal
                        open={open}
                        onClose={toggleMobileMenu}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box className={styles.modalContent}>
                            <div className={styles.qr_code_container}>
                                <div className={styles.left_qrcode}>
                                    <div className={styles.name}>
                                        <div className={styles.name_circle}>
                                            {firstLetter}
                                        </div>
                                        {dealerName}
                                    </div>
                                    <div className={styles.name_line}>
                                        Unlock Your Dream Car: Scan Now  for easy loan
                                    </div>

                                    {id && (
                                        <>
                                            <div>
                                                <QRCode
                                                    size={240}
                                                    value={qrData}
                                                    className={styles.qrcode_line}
                                                />
                                            </div>
                                        </>
                                    )}

                                    <div className={styles.share_button}>
                                        <Modal
                                            open={openShare}
                                            onClose={handleCloseShare}
                                            aria-labelledby="modal-modal-title"
                                            aria-describedby="modal-modal-description"
                                        >
                                            <Box
                                                className={styles.share_modal}
                                            >
                                                <div>
                                                    <WhatsappShareButton
                                                        url={qrData}
                                                    >
                                                        <WhatsappIcon size={32} round />
                                                    </WhatsappShareButton>
                                                </div>
                                                <div>
                                                    <TelegramShareButton
                                                        url={qrData}
                                                    >
                                                        <TelegramIcon size={32} round />
                                                    </TelegramShareButton>
                                                </div>
                                                <div>
                                                    <TwitterShareButton
                                                        url={qrData}
                                                    >
                                                        <XIcon size={32} round />
                                                    </TwitterShareButton>
                                                </div>
                                                <div>
                                                    <FacebookMessengerShareButton
                                                        url={qrData}
                                                    >
                                                        <FacebookMessengerIcon size={32} round />
                                                    </FacebookMessengerShareButton>
                                                </div>
                                                <div
                                                    onClick={handleCloseShare}
                                                    className={styles.close_button}
                                                >
                                                    x
                                                </div>
                                            </Box>
                                        </Modal>
                                    </div>
                                </div>

                                <div className={styles.right_qrcode}>
                                    <div className={styles.word_link}>
                                        <Link
                                            to={qrData}
                                            target="_blank"
                                            style={{ textAlign: "left", color: "black", fontSize: "16px" }}
                                        >
                                            {qrData} &nbsp;
                                        </Link>
                                        <button
                                            className={styles.copy}
                                            onClick={() => {
                                                navigator.clipboard.writeText(qrData);
                                            }}
                                        >
                                            <img src={copy} alt="" />
                                        </button>
                                    </div>

                                    <ol style={{ textAlign: "left", marginTop: '30px', listStyleType: 'decimal' }}>
                                        <li>Be ready with Identity proofs (PAN, Aadhaar)</li>
                                        <li>Residence proofs ( Aadhaar , Voter id , etc) </li>
                                        <li>Income proofs (3 month Salary slips, 3 yrs ITR) </li>
                                        <li>Others (Bank Statement, Electricity Bill)</li>
                                    </ol>
                                </div>
                                <div
                                    onClick={handleShare}
                                    className={styles.share_button_2}
                                >
                                    <span ><FiShare2 size={24} /></span> <span>Share</span>
                                </div>
                            </div>
                            <div className={styles.bottom} onClick={toggleMobileMenu}>
                                <div className="links">
                                    <NavLink className="nav-bar-link" to='/dealer-dashboard' >
                                        Dashboard
                                    </NavLink>
                                    <NavLink className="nav-bar-link" to='/dealer-loan-form' >
                                        Loan Application Form
                                    </NavLink>
                                    <NavLink className="nav-bar-link" to='/dealer-loan-status' >
                                        Loan Status
                                    </NavLink>
                                    <NavLink className="nav-bar-link" to='/dealer-application-status' >
                                        Application Status
                                    </NavLink>
                                    <NavLink className="nav-bar-link" to='/dealer-profile' >
                                        My Profile
                                    </NavLink>

                                    <div
                                        className={styles.logout_button}
                                        onClick={handleOpen}
                                    >
                                        Logout
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </Modal>

                }
            </div>


            {/* LOGOUT MODAL */}
            <Modal
                open={openLogoutModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={styles.logoutModal}>
                    <div className={styles.image}>
                        <img src={door} alt="" />
                    </div>
                    <span>Are You Sure ?</span>

                    <div className={styles.button_container}>
                        <Link to='/'>
                            <div
                                className={styles.yes_button}
                                onClick={handleLogout}
                            >
                                Yes
                            </div>
                        </Link>
                        <div className={styles.no_button}
                            onClick={handleClose}
                        >
                            No
                        </div>
                    </div>
                </Box>
            </Modal>
            {/* LOGOUT MODAL */}
        </>
    );
};

export default SideNav;
