
import React, { useState, useEffect } from 'react';
import ConsumerMain from '../../../../Layout/Consumer/Main/ConsumerMain'
import Table from '../../../../reusable/table/Table';
import styles from './LoanHistory.module.css'
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

export default function LoanHistory() {
  const [name, setName] = useState('');
  const [loanData, setLoanData] = useState([]);
  const token = localStorage.getItem('Authorization')
  const info = jwtDecode(token);
  const consumer_id = info.consumer_id;

  const fetchData = async () => {
    try {
      const response = await axios.get(`/lead_status?consumer_id=${consumer_id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`,
        },
      });

      if (response?.data?.status) {
        setLoanData(response?.data?.data);
        setName(response?.data?.data[0]?.name);
      } else {
        console.error('API request failed:', response);
      }

      if (response?.data?.data?.length !== 0) {
        localStorage.setItem('my_consumer_stored_id',
          response?.data?.data && response?.data?.lead_id)
      }

    } catch (error) {
      console.error('Error fetching data:', error?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [consumer_id]);

  const columns = [
    { label: 'APPLICATION NO.', field: 'application_no' },
    { label: 'CUSTOMER NAME', field: 'name' },
    { label: 'MOBILE', field: 'mobile' },
    { label: 'MAKE MODEL', field: 'make_model_variant' },
    { label: 'LOAN AMOUNT', field: 'loan_amount_requested' },
    { label: 'AMOUNT APPROVED', field: 'amount_approved' },
    { label: 'LEAD STATUS', field: 'lead_status ' },
    { label: 'KYC STATUS', field: 'kyc_status' },
    { label: 'APPLIED ON', field: 'applied_on' },
    { label: 'EDIT', field: '0' },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const range = 2;
  const totalItems = loanData.length;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = loanData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <ConsumerMain>
      <div className={styles.right}>
        <div className={styles.welcome_text}>
          <div className={styles.welcome_text1}>Hello,  {name === (undefined || null) ? 'User' : name}</div>
          <div className={styles.welcome_text2}> </div>
        </div>

        {
          loanData && loanData.length > 0 ? (
            <>
              <div className={styles.table}>
                <Table data={currentData} columns={columns} />


                {/* PAGINATION */}
                <div className={styles.pagination}>
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Prev
                  </button>

                  {[...Array(Math.ceil(totalItems / itemsPerPage))].map((_, index) => {
                    const page = index + 1;
                    const isCurrentPage = currentPage === page;
                    if (page >= currentPage - range && page <= currentPage + range) {
                      return (
                        <button
                          key={index}
                          onClick={() => handlePageChange(page)}
                          className={isCurrentPage ? styles.activePage : ''}
                        >
                          {page}
                        </button>
                      );
                    }
                    return null;
                  })}

                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
                  >
                    Next
                  </button>
                </div>
              </div>
            </>
          ) : (null)
        }
      </div>
    </ConsumerMain>
  )
}
