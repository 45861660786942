import React from 'react'
import styles from './ApniCarFAQ.module.css'

export default function ApniCarFAQ() {
  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <div className={styles.heading}>FAQs</div>


        <div className={styles.single}>
          <div className={styles.q}>Q1. How do I apply for the loan?</div>
          <div className={styles.a}>Simple fill the form by clicking on the button below. You will receive a call from executive to guide you throughout the process.</div>
        </div>
        <div className={styles.single}>
          <div className={styles.q}>Q2. Can I choose any used car and get the loan?</div>
          <div className={styles.a}>Yes, you can choose any car from your preferred seller and apply for loan with Nxcar for best deals. </div>
        </div>
        <div className={styles.single}>
          <div className={styles.q}>Q3. How long does the loan approval process take?</div>
          <div className={styles.a}>If you meet the eligibility criteria, our loan approval process takes just a few hours. Don't worry our executives will keep it simple for you. </div>
        </div>
        <div className={styles.single}>
          <div className={styles.q}>Q4. Do you take any charges for processing the loan?</div>
          <div className={styles.a}>No, we don't charge any fees for providing this facility. Nxcar acts as a facilitator for the loan by partnering with banks and NBFCs.</div>
        </div>
      </div>


    </div>
  )
}
