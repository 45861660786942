import './big_card_style.css';

export const EMI_calcultor = () => {
    return (
        <div className="my_big_card">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="235"
                height="300"
                fill="none"
                viewBox="0 0 235 300"
            >
                <g id="EMI SVG">
                    <g id="Calculator">
                        <g id="Group">
                            <g id="Group_2">
                                <path
                                    id="Vector"
                                    fill="url(#paint0_linear_4553_104946)"
                                    d="M50.844 185.604s49.695 19.475 63.812 15.112c14.116-4.363 89.385-27.498 81.672-29.783-7.713-2.286-66.079-30.902-66.079-30.902l-79.404 45.573z"
                                    opacity="0.2"
                                ></path>
                                <path
                                    id="Vector_2"
                                    fill="#DDDCDB"
                                    d="M26.019 80.083l95.662-38.785V6.422L26.019 45.207v34.876z"
                                ></path>
                                <path
                                    id="Vector_3"
                                    fill="url(#paint1_linear_4553_104946)"
                                    d="M50.687 185.465L25.861 79.945l95.662-38.861 24.826 105.521-95.662 38.86z"
                                ></path>
                                <path
                                    id="Vector_4"
                                    fill="#F4F1EF"
                                    d="M109.498 0l12.183 6.422-95.662 38.785-12.184-6.422L109.498 0z"
                                ></path>
                                <path
                                    id="Vector_5"
                                    fill="url(#paint2_linear_4553_104946)"
                                    d="M13.835 38.785l12.183 6.422v34.876l24.827 105.521-13.292-4.988L13.835 73.661V38.785z"
                                ></path>
                                <g id="Group_3">
                                    <g id="Group_4">
                                        <g id="Group_5">
                                            <g id="Group_6">
                                                <g id="Group_7">
                                                    <path
                                                        id="Vector_6"
                                                        fill="#A8A7A7"
                                                        d="M44.483 114.404l-3.057-13.25 15.337-6.23 3.057 13.25-15.337 6.23z"
                                                    ></path>
                                                    <path
                                                        id="Vector_7"
                                                        fill="#706969"
                                                        d="M44.48 114.405l-2.11-.56-2.847-13.342 1.907.649 3.05 13.253z"
                                                    ></path>
                                                </g>
                                                <path
                                                    id="Vector_8"
                                                    fill="#E8E8E8"
                                                    d="M39.523 100.503l14.898-5.986 2.342.408-15.333 6.227-1.907-.649z"
                                                ></path>
                                            </g>
                                            <g id="Group_8">
                                                <g id="Group_9">
                                                    <path
                                                        id="Vector_9"
                                                        fill="#A8A7A7"
                                                        d="M66.502 105.839l-3.056-13.25 15.336-6.23 3.057 13.25-15.337 6.23z"
                                                    ></path>
                                                    <path
                                                        id="Vector_10"
                                                        fill="#706969"
                                                        d="M66.5 105.84l-2.11-.56-2.847-13.342 1.907.65 3.05 13.252z"
                                                    ></path>
                                                </g>
                                                <path
                                                    id="Vector_11"
                                                    fill="#E8E8E8"
                                                    d="M61.543 91.938l14.898-5.986 2.341.408-15.332 6.228-1.907-.65z"
                                                ></path>
                                            </g>
                                            <g id="Group_10">
                                                <g id="Group_11">
                                                    <path
                                                        id="Vector_12"
                                                        fill="#A8A7A7"
                                                        d="M88.521 97.274l-3.057-13.25 15.337-6.23 3.057 13.25-15.337 6.23z"
                                                    ></path>
                                                    <path
                                                        id="Vector_13"
                                                        fill="#706969"
                                                        d="M88.52 97.275l-2.112-.56-2.847-13.342 1.907.65 3.051 13.252z"
                                                    ></path>
                                                </g>
                                                <path
                                                    id="Vector_14"
                                                    fill="#E8E8E8"
                                                    d="M83.561 83.373l14.898-5.986 2.342.408-15.333 6.227-1.907-.649z"
                                                ></path>
                                            </g>
                                            <g id="Group_12">
                                                <g id="Group_13">
                                                    <path
                                                        id="Vector_15"
                                                        fill="#A8A7A7"
                                                        d="M110.539 88.71l-3.057-13.25 15.337-6.23 3.057 13.25-15.337 6.23z"
                                                    ></path>
                                                    <path
                                                        id="Vector_16"
                                                        fill="#706969"
                                                        d="M110.538 88.71l-2.111-.56-2.847-13.341 1.907.649 3.051 13.252z"
                                                    ></path>
                                                </g>
                                                <path
                                                    id="Vector_17"
                                                    fill="#E8E8E8"
                                                    d="M105.581 74.809l14.897-5.986 2.342.407-15.333 6.228-1.906-.65z"
                                                ></path>
                                            </g>
                                        </g>
                                        <g id="Group_14">
                                            <g id="Group_15">
                                                <g id="Group_16">
                                                    <path
                                                        id="Vector_18"
                                                        fill="#A8A7A7"
                                                        d="M48.856 133.116l-3.057-13.249 15.336-6.23 3.057 13.249-15.336 6.23z"
                                                    ></path>
                                                    <path
                                                        id="Vector_19"
                                                        fill="#706969"
                                                        d="M48.854 133.117l-2.111-.56-2.847-13.342 1.907.649 3.05 13.253z"
                                                    ></path>
                                                </g>
                                                <path
                                                    id="Vector_20"
                                                    fill="#E8E8E8"
                                                    d="M43.896 119.215l14.898-5.986 2.342.408-15.334 6.227-1.906-.649z"
                                                ></path>
                                            </g>
                                            <g id="Group_17">
                                                <g id="Group_18">
                                                    <path
                                                        id="Vector_21"
                                                        fill="#A8A7A7"
                                                        d="M70.874 124.551l-3.057-13.249 15.337-6.23 3.057 13.249-15.337 6.23z"
                                                    ></path>
                                                    <path
                                                        id="Vector_22"
                                                        fill="#706969"
                                                        d="M70.872 124.551l-2.11-.559-2.847-13.342 1.906.649 3.051 13.252z"
                                                    ></path>
                                                </g>
                                                <path
                                                    id="Vector_23"
                                                    fill="#E8E8E8"
                                                    d="M65.915 110.65l14.897-5.986 2.342.408-15.333 6.227-1.906-.649z"
                                                ></path>
                                            </g>
                                            <g id="Group_19">
                                                <g id="Group_20">
                                                    <path
                                                        id="Vector_24"
                                                        fill="#A8A7A7"
                                                        d="M92.892 115.986l-3.057-13.249 15.337-6.23 3.057 13.25-15.337 6.229z"
                                                    ></path>
                                                    <path
                                                        id="Vector_25"
                                                        fill="#706969"
                                                        d="M92.89 115.988l-2.11-.56-2.847-13.342 1.907.649 3.05 13.253z"
                                                    ></path>
                                                </g>
                                                <path
                                                    id="Vector_26"
                                                    fill="#E8E8E8"
                                                    d="M87.934 102.086L102.83 96.1l2.342.407-15.333 6.228-1.906-.649z"
                                                ></path>
                                            </g>
                                            <g id="Group_21">
                                                <g id="Group_22">
                                                    <path
                                                        id="Vector_27"
                                                        fill="#A8A7A7"
                                                        d="M114.912 107.422l-3.057-13.25 15.337-6.23 3.057 13.25-15.337 6.23z"
                                                    ></path>
                                                    <path
                                                        id="Vector_28"
                                                        fill="#706969"
                                                        d="M114.91 107.422l-2.111-.559-2.847-13.342 1.907.649 3.051 13.252z"
                                                    ></path>
                                                </g>
                                                <path
                                                    id="Vector_29"
                                                    fill="#E8E8E8"
                                                    d="M109.952 93.52l14.898-5.985 2.342.407-15.333 6.228-1.907-.65z"
                                                ></path>
                                            </g>
                                        </g>
                                        <g id="Group_23">
                                            <g id="Group_24">
                                                <g id="Group_25">
                                                    <path
                                                        id="Vector_30"
                                                        fill="#A8A7A7"
                                                        d="M53.227 151.828l-3.057-13.249 15.337-6.23 3.057 13.249-15.337 6.23z"
                                                    ></path>
                                                    <path
                                                        id="Vector_31"
                                                        fill="#706969"
                                                        d="M53.225 151.829l-2.11-.56-2.847-13.342 1.906.649 3.051 13.253z"
                                                    ></path>
                                                </g>
                                                <path
                                                    id="Vector_32"
                                                    fill="#E8E8E8"
                                                    d="M48.268 137.927l14.898-5.986 2.342.408-15.334 6.227-1.906-.649z"
                                                ></path>
                                            </g>
                                            <g id="Group_26">
                                                <g id="Group_27">
                                                    <path
                                                        id="Vector_33"
                                                        fill="#A8A7A7"
                                                        d="M75.246 143.263l-3.057-13.249 15.336-6.23 3.057 13.249-15.336 6.23z"
                                                    ></path>
                                                    <path
                                                        id="Vector_34"
                                                        fill="#706969"
                                                        d="M75.244 143.264l-2.11-.56-2.848-13.342 1.907.649 3.05 13.253z"
                                                    ></path>
                                                </g>
                                                <path
                                                    id="Vector_35"
                                                    fill="#E8E8E8"
                                                    d="M70.287 129.362l14.897-5.986 2.342.408-15.333 6.227-1.906-.649z"
                                                ></path>
                                            </g>
                                            <g id="Group_28">
                                                <g id="Group_29">
                                                    <path
                                                        id="Vector_36"
                                                        fill="#A8A7A7"
                                                        d="M97.265 134.699l-3.057-13.249 15.337-6.23 3.057 13.249-15.337 6.23z"
                                                    ></path>
                                                    <path
                                                        id="Vector_37"
                                                        fill="#706969"
                                                        d="M97.263 134.7l-2.11-.56-2.848-13.342 1.907.649 3.051 13.253z"
                                                    ></path>
                                                </g>
                                                <path
                                                    id="Vector_38"
                                                    fill="#E8E8E8"
                                                    d="M92.305 120.797l14.898-5.985 2.342.407-15.333 6.228-1.907-.65z"
                                                ></path>
                                            </g>
                                            <g id="Group_30">
                                                <g id="Group_31">
                                                    <path
                                                        id="Vector_39"
                                                        fill="#A8A7A7"
                                                        d="M119.284 126.134l-3.057-13.25 15.336-6.23 3.057 13.25-15.336 6.23z"
                                                    ></path>
                                                    <path
                                                        id="Vector_40"
                                                        fill="#706969"
                                                        d="M119.282 126.134l-2.111-.559-2.847-13.342 1.906.649 3.052 13.252z"
                                                    ></path>
                                                </g>
                                                <path
                                                    id="Vector_41"
                                                    fill="#E8E8E8"
                                                    d="M114.324 112.233l14.898-5.986 2.342.407-15.333 6.228-1.907-.649z"
                                                ></path>
                                            </g>
                                        </g>
                                        <g id="Group_32">
                                            <g id="Group_33">
                                                <g id="Group_34">
                                                    <path
                                                        id="Vector_42"
                                                        fill="#A8A7A7"
                                                        d="M57.598 170.54l-3.057-13.249 15.337-6.23 3.057 13.249-15.337 6.23z"
                                                    ></path>
                                                    <path
                                                        id="Vector_43"
                                                        fill="#706969"
                                                        d="M57.597 170.54l-2.111-.559-2.847-13.342 1.906.649 3.052 13.252z"
                                                    ></path>
                                                </g>
                                                <path
                                                    id="Vector_44"
                                                    fill="#E8E8E8"
                                                    d="M52.64 156.639l14.897-5.986 2.342.408-15.333 6.227-1.907-.649z"
                                                ></path>
                                            </g>
                                            <g id="Group_35">
                                                <g id="Group_36">
                                                    <path
                                                        id="Vector_45"
                                                        fill="#A8A7A7"
                                                        d="M79.617 161.975l-3.057-13.25 15.337-6.229 3.057 13.249-15.337 6.23z"
                                                    ></path>
                                                    <path
                                                        id="Vector_46"
                                                        fill="#706969"
                                                        d="M79.615 161.976l-2.11-.56-2.847-13.342 1.906.649 3.051 13.253z"
                                                    ></path>
                                                </g>
                                                <path
                                                    id="Vector_47"
                                                    fill="#E8E8E8"
                                                    d="M74.658 148.074l14.898-5.986 2.342.408-15.333 6.227-1.907-.649z"
                                                ></path>
                                            </g>
                                            <g id="Group_37">
                                                <g id="Group_38">
                                                    <path
                                                        id="Vector_48"
                                                        fill="#A8A7A7"
                                                        d="M101.636 153.41l-3.057-13.249 15.337-6.23 3.057 13.249-15.337 6.23z"
                                                    ></path>
                                                    <path
                                                        id="Vector_49"
                                                        fill="#706969"
                                                        d="M101.634 153.411l-2.11-.56-2.848-13.342 1.907.649 3.051 13.253z"
                                                    ></path>
                                                </g>
                                                <path
                                                    id="Vector_50"
                                                    fill="#E8E8E8"
                                                    d="M96.677 139.509l14.898-5.985 2.342.407-15.334 6.228-1.906-.65z"
                                                ></path>
                                            </g>
                                            <g id="Group_39">
                                                <g id="Group_40">
                                                    <path
                                                        id="Vector_51"
                                                        fill="#A8A7A7"
                                                        d="M123.657 144.845l-3.057-13.249 15.336-6.23 3.057 13.25-15.336 6.229z"
                                                    ></path>
                                                    <path
                                                        id="Vector_52"
                                                        fill="#706969"
                                                        d="M123.654 144.846l-2.111-.559-2.847-13.342 1.907.649 3.051 13.252z"
                                                    ></path>
                                                </g>
                                                <path
                                                    id="Vector_53"
                                                    fill="#E8E8E8"
                                                    d="M118.696 130.945l14.898-5.986 2.342.407-15.333 6.228-1.907-.649z"
                                                ></path>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_41">
                                        <path
                                            id="Vector_54"
                                            fill="#fff"
                                            d="M48.848 102.2l-.027.01-1.603 1.453-.86-1.482 2.23-1.99 1.881-.632 2.777 9.174-2.198.737-2.2-7.27z"
                                        ></path>
                                    </g>
                                    <g id="Group_42">
                                        <path
                                            id="Vector_55"
                                            fill="#fff"
                                            d="M69.996 101.875l-.392-1.298.928-1.494c1.58-2.502 2.298-3.878 2.004-4.96-.226-.749-.883-1.182-2.004-.807-.836.28-1.448.921-1.846 1.459l-1.106-1.323c.573-.768 1.58-1.573 2.895-2.013 2.196-.736 3.772.072 4.277 1.738.465 1.54-.342 3.161-1.389 4.82l-.68 1.005.007.027 3.66-1.224.534 1.762-6.888 2.308z"
                                        ></path>
                                    </g>
                                    <g id="Group_43">
                                        <path
                                            id="Vector_56"
                                            fill="#fff"
                                            d="M91.608 91.071c.465.062 1.5.12 2.427-.192 1.18-.396 1.616-1.129 1.407-1.823-.274-.902-1.355-.992-2.356-.656l-.925.311-.466-1.539.88-.296c.76-.27 1.65-.862 1.414-1.639-.166-.55-.768-.8-1.724-.479-.792.266-1.53.87-1.865 1.232l-.934-1.4c.477-.547 1.54-1.276 2.793-1.697 2.078-.695 3.54-.052 3.92 1.206.295.972-.057 1.931-1.133 2.728l.009.028c1.224-.193 2.418.325 2.78 1.524.493 1.622-.658 3.315-3.122 4.14-1.255.42-2.411.466-3.08.316l-.025-1.764z"
                                        ></path>
                                    </g>
                                    <g id="Group_44">
                                        <path
                                            id="Vector_57"
                                            fill="#fff"
                                            d="M57.342 127.837l-.662-2.186-4.303 1.442-.422-1.398 1.983-6.823 2.779-.93 1.628 5.379 1.165-.391.489 1.609-1.166.391.663 2.187-2.154.72zm-1.148-3.795l-.615-2.033a23.27 23.27 0 01-.442-1.732l-.06.019c-.134.697-.227 1.319-.38 2.008l-.692 2.439.007.029 2.181-.73z"
                                        ></path>
                                    </g>
                                    <g id="Group_45">
                                        <path
                                            id="Vector_58"
                                            fill="#fff"
                                            d="M79.412 111.035l-3.765 1.262.218 1.482c.2-.099.378-.159.618-.239.925-.311 1.928-.427 2.757-.192.873.22 1.55.832 1.876 1.907.516 1.707-.581 3.738-3.194 4.614-1.182.396-2.244.471-2.864.383l-.085-1.744c.496.052 1.454.011 2.335-.286.94-.314 1.813-1.073 1.519-2.047-.287-.945-1.252-1.257-3.195-.607a9.38 9.38 0 00-1.29.526l-.813-5.03 5.348-1.793.535 1.764z"
                                        ></path>
                                    </g>
                                    <g id="Group_46">
                                        <path
                                            id="Vector_59"
                                            fill="#fff"
                                            d="M100.899 102.327a7.123 7.123 0 00-.873.325c-1.968.83-2.572 2.104-2.501 3.262l.046-.015c.335-.627.929-1.119 1.839-1.425 1.628-.546 3.332.082 3.907 1.987.552 1.821-.473 3.813-2.581 4.518-2.584.867-4.406-.529-5.067-2.716-.522-1.724-.285-3.387.483-4.655.72-1.16 1.839-2.032 3.341-2.613a7.19 7.19 0 01.907-.318l.499 1.65zm.152 5.019c-.256-.847-.955-1.422-1.928-1.096a1.625 1.625 0 00-1.095 1.268c-.026.134-.004.313.072.567.339.96 1.097 1.668 2.127 1.323.809-.271 1.107-1.131.824-2.062z"
                                        ></path>
                                    </g>
                                    <g id="Group_47">
                                        <path
                                            id="Vector_60"
                                            fill="#fff"
                                            d="M62.681 136.421l.411 1.355-1.635 9.162-2.407.806 1.77-8.725-.01-.028-4.45 1.492-.535-1.764 6.856-2.298z"
                                        ></path>
                                    </g>
                                    <g id="Group_48">
                                        <path
                                            id="Vector_61"
                                            fill="#fff"
                                            d="M79.466 137.013c-.333-1.101.11-2.088 1.038-2.881l-.013-.042c-1.078-.137-1.768-.73-2.013-1.534-.47-1.554.698-3.079 2.64-3.73 2.272-.759 3.59.168 3.926 1.282.239.791.026 1.716-.792 2.487l.012.045c1.043.021 2.11.503 2.47 1.691.504 1.664-.639 3.275-2.892 4.031-2.468.826-4-.106-4.376-1.349zm1.129-5.244c.187.622.904.818 1.75.782.48-.315.807-.924.63-1.502-.17-.565-.684-1.015-1.52-.735-.777.261-1.036.877-.86 1.455zm3.87 3.49c-.244-.803-1.016-1.073-1.981-.996-.688.446-.966 1.101-.766 1.762.185.669.91 1.111 1.763.826.808-.271 1.18-.942.984-1.592z"
                                        ></path>
                                    </g>
                                    <g id="Group_49">
                                        <path
                                            id="Vector_62"
                                            fill="#fff"
                                            d="M102.434 129.007c.294-.069.547-.153.988-.331.675-.27 1.321-.692 1.741-1.205a3.108 3.108 0 00.714-2.104h-.052c-.304.57-.859 1.019-1.739 1.314-1.645.55-3.364-.072-3.902-1.85-.542-1.792.525-3.812 2.66-4.528 2.496-.836 4.224.575 4.873 2.721.576 1.906.357 3.519-.427 4.792-.686 1.1-1.824 1.963-3.315 2.527a9.552 9.552 0 01-1.033.345l-.508-1.681zm-.017-5.062c.239.79.894 1.317 1.819 1.006.612-.205.96-.632 1.073-1.026.035-.153.037-.311-.045-.578-.294-.974-.973-1.803-2.004-1.456-.776.259-1.148 1.099-.843 2.054z"
                                        ></path>
                                    </g>
                                    <g id="Group_50">
                                        <path
                                            id="Vector_63"
                                            fill="#fff"
                                            d="M123.96 114.654l1.064.906c.315.282.616.57.91.829l.028-.01c.085-.431.157-.845.248-1.249l.305-1.331 2.464-.826-1.409 4.095 3.53 2.798-2.57.86-1.108-.995c-.311-.27-.6-.562-.885-.855l-.044.015c-.068.425-.161.83-.237 1.229l-.31 1.473-2.494.835 1.415-4.344-3.447-2.578 2.54-.852z"
                                        ></path>
                                    </g>
                                    <g id="Group_51">
                                        <path
                                            id="Vector_64"
                                            fill="#fff"
                                            d="M122.71 96.855l.433 1.425-3.915 1.312-.433-1.426 3.915-1.31z"
                                        ></path>
                                    </g>
                                    <g id="Group_52">
                                        <path
                                            id="Vector_65"
                                            fill="#fff"
                                            d="M69.44 159.64c.858 2.838.239 5.191-2.242 6.022-2.511.842-4.261-.92-5.062-3.513-.803-2.654-.242-5.157 2.266-5.998 2.6-.871 4.278.976 5.038 3.489zm-5.018 1.743c.622 2.108 1.464 2.93 2.344 2.635.881-.296 1.044-1.5.411-3.589-.614-2.033-1.402-2.952-2.314-2.646-.836.281-1.101 1.475-.44 3.6z"
                                        ></path>
                                    </g>
                                    <g id="Group_53">
                                        <path
                                            id="Vector_66"
                                            fill="#fff"
                                            d="M108.086 139.478l.931 3.077 3.123-1.047.401 1.329-3.121 1.045.94 3.105-1.465.49-.94-3.104-3.123 1.047-.401-1.329 3.123-1.046-.931-3.077 1.463-.49z"
                                        ></path>
                                    </g>
                                    <g id="Group_54">
                                        <path
                                            id="Vector_67"
                                            fill="#fff"
                                            d="M85.386 154.402c-.23-.764.156-1.5.948-1.765.792-.266 1.49.091 1.726.869.225.746-.144 1.478-.951 1.748-.777.261-1.497-.104-1.722-.852z"
                                        ></path>
                                    </g>
                                    <g id="Group_55">
                                        <path
                                            id="Vector_68"
                                            fill="#fff"
                                            d="M126.4 135.374l-.397-1.313 7.709-2.583.396 1.312-7.708 2.584zm.885 2.922l-.398-1.313 7.709-2.583.396 1.312-7.707 2.584z"
                                        ></path>
                                    </g>
                                    <path
                                        id="Vector_69"
                                        fill="#fff"
                                        d="M118.94 73.803l-5.625 9.846.885.374 5.996-9.931-1.256-.288z"
                                    ></path>
                                    <path
                                        id="Vector_70"
                                        fill="#fff"
                                        d="M120.796 80.823c0 .823-.498 1.49-1.112 1.49-.614 0-1.112-.667-1.112-1.49s.498-1.49 1.112-1.49c.614 0 1.112.666 1.112 1.49z"
                                    ></path>
                                    <path
                                        id="Vector_71"
                                        fill="#fff"
                                        d="M114.656 77.842c0 .823-.498 1.49-1.113 1.49-.614 0-1.112-.667-1.112-1.49s.498-1.49 1.112-1.49c.615 0 1.113.667 1.113 1.49z"
                                    ></path>
                                </g>
                                <g id="Group_56">
                                    <path
                                        id="Vector_72"
                                        fill="url(#paint3_linear_4553_104946)"
                                        d="M32.537 48.882l82.624-33.499v22.24L32.537 71.122v-22.24z"
                                    ></path>
                                    <path
                                        id="Vector_73"
                                        fill="url(#paint4_linear_4553_104946)"
                                        d="M110.497 17.274v17.919l4.665 2.43v-22.24l-4.665 1.891z"
                                    ></path>
                                    <path
                                        id="Vector_74"
                                        fill="url(#paint5_linear_4553_104946)"
                                        d="M110.497 35.193l-77.96 31.67v4.26l82.625-33.5-4.665-2.43z"
                                    ></path>
                                </g>
                                <g id="Group_57">
                                    <path
                                        id="Vector_75"
                                        fill="url(#paint6_linear_4553_104946)"
                                        d="M34.401 83.734L74.79 67.242l1.923 10.95-40.388 16.491-1.923-10.949z"
                                    ></path>
                                    <path
                                        id="Vector_76"
                                        fill="url(#paint7_linear_4553_104946)"
                                        d="M72.508 68.174l1.55 8.821 2.653 1.197-1.922-10.95-2.28.932z"
                                    ></path>
                                    <path
                                        id="Vector_77"
                                        fill="url(#paint8_linear_4553_104946)"
                                        d="M74.058 76.995L35.956 92.587l.368 2.097 40.388-16.492-2.654-1.197z"
                                    ></path>
                                    <g id="Group_58">
                                        <g id="Group_59">
                                            <path
                                                id="Vector_78"
                                                fill="url(#paint9_linear_4553_104946)"
                                                d="M43.624 89.272l-1.71-8.898.4-.06 1.711 8.9-.401.058z"
                                            ></path>
                                        </g>
                                        <g id="Group_60">
                                            <path
                                                id="Vector_79"
                                                fill="url(#paint10_linear_4553_104946)"
                                                d="M51.655 86.045l-1.71-8.898.4-.06 1.711 8.9-.401.058z"
                                            ></path>
                                        </g>
                                        <g id="Group_61">
                                            <path
                                                id="Vector_80"
                                                fill="url(#paint11_linear_4553_104946)"
                                                d="M59.685 82.818l-1.71-8.898.4-.059 1.711 8.899-.4.058z"
                                            ></path>
                                        </g>
                                        <g id="Group_62">
                                            <path
                                                id="Vector_81"
                                                fill="url(#paint12_linear_4553_104946)"
                                                d="M67.716 79.592l-1.711-8.898.4-.06 1.712 8.9-.401.058z"
                                            ></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g id="Group_63">
                                <path
                                    id="Vector_82"
                                    fill="url(#paint13_linear_4553_104946)"
                                    d="M118.068 122.166l-1.296-20.893c-.045-.721-.905-1.172-1.646-.863l-10.898 4.539c-.574.239-.923.763-.88 1.321l1.684 21.854c.06.778 1.025 1.231 1.792.842l10.259-5.2c.645-.327 1.026-.945.985-1.6z"
                                ></path>
                                <path
                                    id="Vector_83"
                                    fill="#fff"
                                    d="M129.006 117.637l-6.744-2.146s-.919-.194-2.069 1.8c-1.149 1.994-.459 2.897-.459 2.897l1.114 1.026 8.158-3.577z"
                                ></path>
                                <path
                                    id="Vector_84"
                                    fill="url(#paint14_linear_4553_104946)"
                                    d="M118.761 122.494l-1.296-20.894c-.045-.721-.905-1.172-1.646-.863l-10.898 4.54c-.574.239-.923.763-.88 1.32l1.684 21.855c.06.777 1.025 1.231 1.792.842l10.259-5.201c.645-.327 1.026-.945.985-1.599z"
                                ></path>
                                <path
                                    id="Vector_85"
                                    fill="url(#paint15_linear_4553_104946)"
                                    d="M107.119 128.257a.55.55 0 00.262-.065l9.805-4.969c.418-.212.661-.606.635-1.03l-1.238-19.967c-.016-.255-.261-.408-.49-.408a.54.54 0 00-.208.042l-10.415 4.339c-.322.134-.515.423-.491.737l1.609 20.884c.021.273.287.437.531.437z"
                                ></path>
                                <path
                                    id="Vector_86"
                                    fill="url(#paint16_linear_4553_104946)"
                                    d="M149.903 219.212s.976 3.671.436 4.358c-.541.687-4.022 2.219-5.402 1.691-1.381-.529-2.341-2.325-5.702-3.699-3.361-1.374-5.462-1.638-5.882-2.642-.42-1.004-.802-2.695 3.02-2.378 3.822.317 5.556.844 5.556.844s1.536 3.651 7.974 1.826z"
                                ></path>
                                <path
                                    id="Vector_87"
                                    fill="url(#paint17_linear_4553_104946)"
                                    d="M151.693 152.338s.679 3.92-.347 9.041c-.683 3.405-2.441 6.254-2.441 6.254l1.054 20.675s2.572 5.391 2.692 12.685c.12 7.293-2.348 17.758-2.348 17.758s-.534 1.374-4.256 1.374c-3.722 0-4.082-2.008-4.082-2.008l-.961-19.661s-2.881-7.4-3.121-9.937c-.24-2.537-.258-20.295-1.51-20.506-1.252-.212-1.492 23.572-1.492 23.572s2.641 5.179 2.762 14.481c.12 9.302-.721 15.116-.721 15.116s.48 1.902-3.842 1.48c-4.322-.423-4.851-2.326-4.851-2.326s-1.993-18.287-3.073-23.36c-1.081-5.074-3.242-9.598-3.362-14.26-.12-4.661-.876-27.026-.876-27.026l30.775-3.352z"
                                ></path>
                                <path
                                    id="Vector_88"
                                    fill="url(#paint18_linear_4553_104946)"
                                    d="M125.596 96.515s-1.141 3.734-.652 4.26c.489.527.491.981.328 1.507-.163.527-.056 5.98.977 6.999 1.033 1.019 4.119.964 4.678 1.542.559.578 1.193 3.912 1.193 3.912l10.547-3.573s-1.884-4.5-1.975-6.079c-.091-1.58-2.755-8.855-4.603-9.046-1.848-.192-10.493.478-10.493.478z"
                                ></path>
                                <path
                                    id="Vector_89"
                                    fill="url(#paint19_linear_4553_104946)"
                                    d="M124.183 94.936c-.05 1.847 1.74 2.536 3.316 3.158 1.577.623.109 3.399.87 4.021.761.622 1.359.862 1.305 1.962-.054 1.101.137 1.867.694 2.154.557.287.067-2.01 1.753-2.201 1.685-.192 1.794 2.393 1.359 3.494-.435 1.1 1.033 1.435 3.642 1.34 2.61-.096 4.676.143 4.785-2.25.108-2.393 2.935-7.419 2.12-9.525-.816-2.106-1.903-2.154-1.903-2.154s.093-4.499-7.945-5.169c-8.039-.67-9.942 3.16-9.996 5.17z"
                                ></path>
                                <path
                                    id="Vector_90"
                                    fill="#FFDE7D"
                                    d="M147.971 110.602s-1.955-1.148-5.54-.344c-3.585.805-10.478 2.765-11.609 3.784-1.131 1.019-.762 3.162-1.816 3.595-1.055.433-6.823 2.463-8.158 3.577-1.336 1.114-11.236 10.739-11.665 10.507-.428-.233-6.193-12.091-6.193-12.091s-1.125-.715-3.515.558c-2.39 1.273-3.164 3.023-3.164 3.023s5.695 17.933 9.281 18.304c3.585.372 15.326-4.984 15.326-4.984v19.159s1.938 2.475 14.608 2.29c12.671-.186 16.607-6.19 16.607-6.19v-12.131s3.797-1.008 3.938-4.837c.14-3.829 3.023-24.248-1.547-24.22-4.57.028-6.553 0-6.553 0z"
                                ></path>
                                <path
                                    id="Vector_91"
                                    fill="#fff"
                                    d="M102.095 119.432l-.488-2.133s-2.636-.169-4.176 1.066-1.766 2.033-1.812 2.192c-.045.16.946 2.207.946 2.207s1.636-3.062 5.53-3.332z"
                                ></path>
                                <path
                                    id="Vector_92"
                                    fill="url(#paint20_linear_4553_104946)"
                                    d="M100.866 117.301s1.035-1.729.585-3.62c-.45-1.891-1.018-3.709-1.018-3.709s-1.095-.623-1.13.506a6.595 6.595 0 01-.447 2.105s-.35-7.536-1.563-7.017c-1.213.519-.624 5.491-1.143 5.674-.52.183-1.178.244-1.248 1.007-.069.763-.762-.097-1.178.561-.416.659-.192 1.117-.192 1.117s-.709-.213-1.09.458c-.381.671 1.49 4.423 1.94 4.789.45.366 1.388 1.016 1.388 1.016s1.162-2.475 5.096-2.887z"
                                ></path>
                                <path
                                    id="Vector_93"
                                    fill="url(#paint21_linear_4553_104946)"
                                    d="M136.373 222.46s1.601 4.069 1.061 4.65c-.54.582-4.321 2.801-6.422 2.272-2.1-.528-3.421-2.642-8.522-4.28-5.102-1.638-7.263-3.117-5.282-4.227 1.981-1.109 3.481-1.268 5.102-.792 1.62.475 4.381 1.268 5.161 1.268.78 0 .961-.635.961-.635s4.9 2.326 7.941 1.744z"
                                ></path>
                                <path
                                    id="Vector_94"
                                    fill="#060616"
                                    d="M152.134 139.659l.521-17.624 2.027 16.081s-1.54 1.46-2.548 1.543z"
                                    opacity="0.3"
                                ></path>
                                <path
                                    id="Vector_95"
                                    fill="#060616"
                                    d="M120.919 136.532s1.843-4.92 2.198-6.648c.356-1.728.445-2.198.445-2.198s1.187 16.859 1.26 20.994c.074 4.134.846 8.715.846 8.715s-3.391-.004-4.749-1.705v-19.158z"
                                    opacity="0.3"
                                ></path>
                                <path
                                    id="Vector_96"
                                    fill="url(#paint22_linear_4553_104946)"
                                    d="M121.296 115.894s-1.135-3.965-1.46-3.991c-.326-.026-5.289-.572-5.289-.572s-.591 1.665 1.004 1.743c1.596.078 2.317.312 2.317.312l.704 6.061h.957s.302-1.93 1.767-3.553z"
                                ></path>
                                <g id="Group_64">
                                    <g id="Group_65">
                                        <path
                                            id="Vector_97"
                                            fill="url(#paint23_linear_4553_104946)"
                                            d="M113.858 109.576l1.606-.649-.069-.567-1.607.649.07.567z"
                                        ></path>
                                        <path
                                            id="Vector_98"
                                            fill="url(#paint24_linear_4553_104946)"
                                            d="M113.738 108.595l1.606-.649-.069-.567-1.607.649.07.567z"
                                        ></path>
                                        <path
                                            id="Vector_99"
                                            fill="url(#paint25_linear_4553_104946)"
                                            d="M113.617 107.615l1.607-.649-.07-.567-1.606.649.069.567z"
                                        ></path>
                                    </g>
                                    <g id="Group_66">
                                        <path
                                            id="Vector_100"
                                            fill="url(#paint26_linear_4553_104946)"
                                            d="M111.544 110.512l1.606-.65-.069-.567-1.607.65.07.567z"
                                        ></path>
                                        <path
                                            id="Vector_101"
                                            fill="url(#paint27_linear_4553_104946)"
                                            d="M111.423 109.531l1.607-.649-.07-.567-1.606.649.069.567z"
                                        ></path>
                                        <path
                                            id="Vector_102"
                                            fill="url(#paint28_linear_4553_104946)"
                                            d="M111.303 108.55l1.607-.649-.07-.567-1.606.649.069.567z"
                                        ></path>
                                        <path
                                            id="Vector_103"
                                            fill="url(#paint29_linear_4553_104946)"
                                            d="M111.183 107.569l1.606-.649-.069-.567-1.607.649.07.567z"
                                        ></path>
                                    </g>
                                    <g id="Group_67">
                                        <path
                                            id="Vector_104"
                                            fill="url(#paint30_linear_4553_104946)"
                                            d="M109.228 111.447l1.607-.649-.07-.567-1.606.649.069.567z"
                                        ></path>
                                        <path
                                            id="Vector_105"
                                            fill="url(#paint31_linear_4553_104946)"
                                            d="M109.109 110.466l1.606-.649-.069-.567-1.607.649.07.567z"
                                        ></path>
                                        <path
                                            id="Vector_106"
                                            fill="url(#paint32_linear_4553_104946)"
                                            d="M108.989 109.485l1.606-.649-.069-.567-1.607.649.07.567z"
                                        ></path>
                                        <path
                                            id="Vector_107"
                                            fill="url(#paint33_linear_4553_104946)"
                                            d="M108.869 108.504l1.606-.649-.069-.567-1.607.649.07.567z"
                                        ></path>
                                        <path
                                            id="Vector_108"
                                            fill="url(#paint34_linear_4553_104946)"
                                            d="M108.748 107.523l1.607-.649-.069-.567-1.607.649.069.567z"
                                        ></path>
                                    </g>
                                    <g id="Group_68">
                                        <path
                                            id="Vector_109"
                                            fill="url(#paint35_linear_4553_104946)"
                                            d="M106.915 112.382l1.606-.649-.069-.567-1.607.649.07.567z"
                                        ></path>
                                        <path
                                            id="Vector_110"
                                            fill="url(#paint36_linear_4553_104946)"
                                            d="M106.795 111.402l1.606-.65-.069-.567-1.607.649.07.568z"
                                        ></path>
                                        <path
                                            id="Vector_111"
                                            fill="url(#paint37_linear_4553_104946)"
                                            d="M106.674 110.421l1.606-.65-.069-.567-1.607.65.07.567z"
                                        ></path>
                                        <path
                                            id="Vector_112"
                                            fill="url(#paint38_linear_4553_104946)"
                                            d="M106.553 109.439l1.607-.649-.07-.567-1.606.649.069.567z"
                                        ></path>
                                        <path
                                            id="Vector_113"
                                            fill="url(#paint39_linear_4553_104946)"
                                            d="M106.434 108.458l1.606-.649-.069-.567-1.607.649.07.567z"
                                        ></path>
                                        <path
                                            id="Vector_114"
                                            fill="url(#paint40_linear_4553_104946)"
                                            d="M106.314 107.478l1.606-.649-.069-.568-1.607.65.07.567z"
                                        ></path>
                                    </g>
                                </g>
                                <g id="Group_69">
                                    <path
                                        id="Vector_115"
                                        fill="url(#paint41_linear_4553_104946)"
                                        d="M110.702 119.853c0 .783.721 1.159 1.61.84.889-.318 1.609-1.211 1.609-1.994 0-.782-.72-1.158-1.609-.84-.889.319-1.61 1.212-1.61 1.994z"
                                    ></path>
                                    <path
                                        id="Vector_116"
                                        fill="url(#paint42_linear_4553_104946)"
                                        d="M109.512 120.28c0 1.361 1.253 2.015 2.799 1.461s2.799-2.107 2.799-3.468c0-1.361-1.253-2.015-2.799-1.461s-2.799 2.107-2.799 3.468zm.425-.152c0-1.155 1.063-2.472 2.374-2.942 1.312-.47 2.375.085 2.375 1.239 0 1.155-1.063 2.472-2.375 2.942-1.311.47-2.374-.085-2.374-1.239z"
                                    ></path>
                                    <g id="Group_70">
                                        <path
                                            id="Vector_117"
                                            fill="url(#paint43_linear_4553_104946)"
                                            d="M112.091 115.788v.603a3.465 3.465 0 01.44-.158v-.603a8.207 8.207 0 00-.22.075c-.075.027-.147.055-.22.083z"
                                        ></path>
                                        <path
                                            id="Vector_118"
                                            fill="url(#paint44_linear_4553_104946)"
                                            d="M109.686 117.497l.412.329c.109-.123.227-.245.349-.359l-.412-.331a6.569 6.569 0 00-.349.361z"
                                        ></path>
                                        <path
                                            id="Vector_119"
                                            fill="url(#paint45_linear_4553_104946)"
                                            d="M110.432 116.784l.283.446c.13-.109.261-.208.4-.305l-.284-.443c-.138.097-.27.196-.399.302z"
                                        ></path>
                                        <path
                                            id="Vector_120"
                                            fill="url(#paint46_linear_4553_104946)"
                                            d="M111.259 116.204l.141.537.002-.001c.138-.084.281-.163.426-.233l-.14-.536a6.11 6.11 0 00-.429.233z"
                                        ></path>
                                        <path
                                            id="Vector_121"
                                            fill="url(#paint47_linear_4553_104946)"
                                            d="M109.044 118.331l.526.193c.087-.137.179-.268.279-.398l-.526-.192c-.099.13-.192.263-.279.397z"
                                        ></path>
                                        <path
                                            id="Vector_122"
                                            fill="url(#paint48_linear_4553_104946)"
                                            d="M116.39 117.814c0 .635-.189 1.299-.518 1.934l-.33-.025c.305-.584.481-1.193.481-1.777 0-.207-.024-.4-.065-.582l-.341.123c-.254-.991-1.175-1.527-2.372-1.4v-.638c1.802-.273 3.145.666 3.145 2.365z"
                                        ></path>
                                    </g>
                                    <g id="Group_71">
                                        <path
                                            id="Vector_123"
                                            fill="url(#paint49_linear_4553_104946)"
                                            d="M112.091 122.319v.602c.072-.024.145-.048.22-.075.075-.026.147-.054.22-.082v-.603a3.465 3.465 0 01-.44.158z"
                                        ></path>
                                        <path
                                            id="Vector_124"
                                            fill="url(#paint50_linear_4553_104946)"
                                            d="M114.174 121.087l.413.329c.122-.117.238-.237.349-.358l-.413-.331a5.634 5.634 0 01-.349.36z"
                                        ></path>
                                        <path
                                            id="Vector_125"
                                            fill="url(#paint51_linear_4553_104946)"
                                            d="M113.51 121.624l.282.445c.138-.096.269-.195.399-.303l-.281-.444a5.507 5.507 0 01-.4.302z"
                                        ></path>
                                        <path
                                            id="Vector_126"
                                            fill="url(#paint52_linear_4553_104946)"
                                            d="M112.793 122.045l.141.536c.147-.073.288-.149.428-.233l-.14-.537-.003.001a5.027 5.027 0 01-.426.233z"
                                        ></path>
                                        <path
                                            id="Vector_127"
                                            fill="url(#paint53_linear_4553_104946)"
                                            d="M114.773 120.425l.526.193c.1-.129.192-.263.279-.397l-.526-.193a4.32 4.32 0 01-.279.397z"
                                        ></path>
                                        <path
                                            id="Vector_128"
                                            fill="url(#paint54_linear_4553_104946)"
                                            d="M108.429 119.571l.341-.005a3.47 3.47 0 00-.17 1.04c0 1.526 1.19 2.378 2.797 2.161l-.047.34c-1.787.259-3.117-.678-3.117-2.369 0-.383.07-.776.196-1.167z"
                                        ></path>
                                    </g>
                                    <path
                                        id="Vector_129"
                                        fill="url(#paint55_linear_4553_104946)"
                                        d="M112.484 120.005c.423-.223.743-.664.743-1.057 0-.445-.41-.659-.915-.478a1.477 1.477 0 00-.855.832l-.635-.028c.24-.603.817-1.174 1.49-1.415.889-.318 1.609.058 1.609.84 0 .783-.72 1.675-1.609 1.994-.04.014-.079.025-.118.037l.29-.725z"
                                    ></path>
                                    <path
                                        id="Vector_130"
                                        fill="url(#paint56_linear_4553_104946)"
                                        d="M109.405 119.58c-.077.262-.12.525-.12.781 0 1.21.918 1.902 2.174 1.775l-.053.499c-1.531.196-2.662-.622-2.662-2.08 0-.322.055-.651.157-.979l.504.004z"
                                    ></path>
                                    <path
                                        id="Vector_131"
                                        fill="url(#paint57_linear_4553_104946)"
                                        d="M110.471 120.806c.353.45 1.044.595 1.84.309 1.154-.413 2.089-1.572 2.089-2.588 0-1.015-.935-1.503-2.089-1.09a3.3 3.3 0 00-.922.521c-.102-.008-.244-.022-.397-.037a3.928 3.928 0 011.319-.813c1.36-.487 2.462.088 2.462 1.286 0 1.197-1.102 2.563-2.462 3.05-.92.33-1.721.172-2.144-.335l.304-.303z"
                                    ></path>
                                </g>
                            </g>
                            <g id="coin">
                                <g id="Group_73">
                                    <g id="Group_74">
                                        <path
                                            id="Vector_132"
                                            fill="url(#paint58_linear_4553_104946)"
                                            d="M62.89 206.323v4.001c0 .716-.358 1.412-1.02 2.064a7.33 7.33 0 01-1.424 1.059c-.447.268-.957.524-1.513.77-.438.193-.913.375-1.423.546a31.7 31.7 0 01-1.513.47c-.456.129-.93.246-1.423.354-.492.117-1.003.224-1.522.331-.456.086-.93.161-1.414.235-.493.075-1.003.15-1.522.214a52.98 52.98 0 01-1.415.14c-.501.053-1.002.085-1.522.117-.465.032-.94.054-1.414.075-.501.021-1.003.032-1.522.032-.233.011-.465.011-.707.011-.242 0-.484 0-.716-.011-.51 0-1.02-.011-1.513-.032a56.747 56.747 0 01-1.424-.075c-.519-.032-1.02-.064-1.521-.117a56.813 56.813 0 01-1.415-.14 53.83 53.83 0 01-1.522-.214 42.18 42.18 0 01-1.414-.235 46.573 46.573 0 01-1.522-.331 22.691 22.691 0 01-1.414-.354 22.955 22.955 0 01-1.522-.47 16.78 16.78 0 01-1.424-.546 14.052 14.052 0 01-1.513-.77 7.327 7.327 0 01-1.423-1.059c-.662-.652-1.02-1.348-1.02-2.064v-4.001h4.216c3.545-1.476 9.005-2.428 15.146-2.428 6.132 0 11.593.952 15.138 2.428h4.217z"
                                        ></path>
                                        <g id="Group_75">
                                            <path
                                                id="Vector_133"
                                                fill="url(#paint59_linear_4553_104946)"
                                                d="M43.531 213.211c10.693 0 19.361-2.875 19.361-6.422 0-3.547-8.668-6.423-19.36-6.423-10.694 0-19.362 2.876-19.362 6.423s8.668 6.422 19.361 6.422z"
                                            ></path>
                                            <path
                                                id="Vector_134"
                                                fill="url(#paint60_linear_4553_104946)"
                                                d="M58.663 206.794c0 .299-.072.588-.224.866-1.226-2.364-7.439-4.161-14.905-4.161-7.474 0-13.678 1.797-14.905 4.161a1.777 1.777 0 01-.224-.866c0-2.781 6.768-5.028 15.13-5.028 8.351 0 15.128 2.247 15.128 5.028z"
                                            ></path>
                                            <path
                                                id="Vector_135"
                                                fill="url(#paint61_linear_4553_104946)"
                                                d="M58.439 207.661c-1.227 2.353-7.44 4.15-14.905 4.15-7.475 0-13.678-1.797-14.905-4.15 1.227-2.364 7.43-4.161 14.905-4.161 7.466 0 13.678 1.797 14.905 4.161z"
                                            ></path>
                                        </g>
                                        <g id="Group_76">
                                            <path
                                                id="Vector_136"
                                                fill="#fff"
                                                d="M42.592 209.603v-.601c-1.603-.007-3.262-.113-4.23-.263l.664-.398c.995.143 2.433.256 3.982.256 1.963 0 3.289-.245 3.289-.584 0-.328-1.078-.53-3.125-.709-2.818-.238-4.56-.512-4.56-1.03 0-.494 1.63-.869 4.173-.959v-.602h1.716v.579c1.659.011 2.79.107 3.592.207l-.69.394a27.122 27.122 0 00-3.483-.203c-2.13 0-2.931.275-2.931.513 0 .309 1.022.464 3.428.678 2.848.251 4.284.56 4.284 1.09 0 .471-1.52.912-4.367 1.013v.62h-1.742v-.001z"
                                            ></path>
                                        </g>
                                    </g>
                                    <g id="Group_77">
                                        <path
                                            id="Vector_137"
                                            fill="url(#paint62_linear_4553_104946)"
                                            d="M62.89 202.006v4.001c0 .717-.358 1.412-1.02 2.064a7.29 7.29 0 01-1.424 1.059c-.447.268-.957.525-1.513.771-.438.192-.913.374-1.423.545-.474.161-.985.321-1.513.471a24.72 24.72 0 01-1.423.353c-.492.117-1.003.224-1.522.331-.456.086-.93.161-1.414.236-.493.075-1.003.15-1.522.214a57.53 57.53 0 01-1.415.139c-.501.053-1.002.085-1.522.117-.465.032-.94.054-1.414.075-.501.022-1.003.032-1.522.032-.233.011-.465.011-.707.011-.242 0-.484 0-.716-.011-.51 0-1.02-.01-1.513-.032a56.747 56.747 0 01-1.424-.075c-.519-.032-1.02-.064-1.521-.117a62.044 62.044 0 01-1.415-.139c-.519-.065-1.03-.14-1.522-.214-.483-.075-.958-.15-1.414-.236a46.573 46.573 0 01-1.522-.331 22.702 22.702 0 01-1.414-.353c-.538-.15-1.039-.3-1.522-.471-.51-.171-.985-.353-1.424-.545a14.05 14.05 0 01-1.513-.771 7.287 7.287 0 01-1.423-1.059c-.662-.652-1.02-1.347-1.02-2.064v-4.001h4.216c3.545-1.476 9.005-2.428 15.146-2.428 6.132 0 11.593.952 15.138 2.428h4.217z"
                                        ></path>
                                        <g id="Group_78">
                                            <path
                                                id="Vector_138"
                                                fill="url(#paint63_linear_4553_104946)"
                                                d="M43.531 208.894c10.693 0 19.361-2.875 19.361-6.422 0-3.547-8.668-6.422-19.36-6.422-10.694 0-19.362 2.875-19.362 6.422 0 3.547 8.668 6.422 19.361 6.422z"
                                            ></path>
                                            <path
                                                id="Vector_139"
                                                fill="url(#paint64_linear_4553_104946)"
                                                d="M58.663 202.477c0 .3-.072.588-.224.866-1.226-2.364-7.439-4.161-14.905-4.161-7.474 0-13.678 1.797-14.905 4.161a1.774 1.774 0 01-.224-.866c0-2.781 6.768-5.028 15.13-5.028 8.351 0 15.128 2.247 15.128 5.028z"
                                            ></path>
                                            <path
                                                id="Vector_140"
                                                fill="url(#paint65_linear_4553_104946)"
                                                d="M58.439 203.344c-1.227 2.353-7.44 4.15-14.905 4.15-7.475 0-13.678-1.797-14.905-4.15 1.227-2.364 7.43-4.161 14.905-4.161 7.466 0 13.678 1.797 14.905 4.161z"
                                            ></path>
                                        </g>
                                        <g id="Group_79">
                                            <path
                                                id="Vector_141"
                                                fill="#fff"
                                                d="M42.592 205.286v-.602c-1.603-.006-3.262-.113-4.23-.262l.664-.398c.995.142 2.433.256 3.982.256 1.963 0 3.289-.245 3.289-.584 0-.328-1.078-.53-3.125-.709-2.818-.238-4.56-.512-4.56-1.03 0-.494 1.63-.869 4.173-.959v-.602h1.716v.579c1.659.011 2.79.106 3.592.207l-.69.394a27.122 27.122 0 00-3.483-.203c-2.13 0-2.931.274-2.931.513 0 .309 1.022.464 3.428.678 2.848.251 4.284.56 4.284 1.09 0 .471-1.52.911-4.367 1.013v.619h-1.742z"
                                            ></path>
                                        </g>
                                    </g>
                                    <g id="Group_80">
                                        <path
                                            id="Vector_142"
                                            fill="url(#paint66_linear_4553_104946)"
                                            d="M62.89 197.689v4.001c0 .717-.358 1.412-1.02 2.064a7.29 7.29 0 01-1.424 1.059c-.447.268-.957.525-1.513.771-.438.192-.913.374-1.423.545-.474.161-.985.321-1.513.471a24.72 24.72 0 01-1.423.353c-.492.117-1.003.224-1.522.331-.456.086-.93.161-1.414.236-.493.075-1.003.15-1.522.214a57.53 57.53 0 01-1.415.139c-.501.053-1.002.085-1.522.117-.465.032-.94.054-1.414.075-.501.022-1.003.032-1.522.032-.233.011-.465.011-.707.011-.242 0-.484 0-.716-.011-.51 0-1.02-.01-1.513-.032a56.747 56.747 0 01-1.424-.075c-.519-.032-1.02-.064-1.521-.117a62.044 62.044 0 01-1.415-.139 53.83 53.83 0 01-1.522-.214c-.483-.075-.958-.15-1.414-.236a41.86 41.86 0 01-1.522-.331 22.702 22.702 0 01-1.414-.353c-.538-.15-1.039-.3-1.522-.471-.51-.171-.985-.353-1.424-.545a14.05 14.05 0 01-1.513-.771 7.287 7.287 0 01-1.423-1.059c-.662-.652-1.02-1.347-1.02-2.064v-4.001h4.216c3.545-1.476 9.005-2.428 15.146-2.428 6.132 0 11.593.952 15.138 2.428h4.217z"
                                        ></path>
                                        <g id="Group_81">
                                            <path
                                                id="Vector_143"
                                                fill="url(#paint67_linear_4553_104946)"
                                                d="M43.531 204.577c10.693 0 19.361-2.875 19.361-6.422 0-3.547-8.668-6.422-19.36-6.422-10.694 0-19.362 2.875-19.362 6.422 0 3.547 8.668 6.422 19.361 6.422z"
                                            ></path>
                                            <path
                                                id="Vector_144"
                                                fill="url(#paint68_linear_4553_104946)"
                                                d="M58.663 198.16c0 .3-.072.588-.224.867-1.226-2.364-7.439-4.161-14.905-4.161-7.474 0-13.678 1.797-14.905 4.161a1.78 1.78 0 01-.224-.867c0-2.781 6.768-5.027 15.13-5.027 8.351 0 15.128 2.246 15.128 5.027z"
                                            ></path>
                                            <path
                                                id="Vector_145"
                                                fill="url(#paint69_linear_4553_104946)"
                                                d="M58.439 199.027c-1.227 2.353-7.44 4.15-14.905 4.15-7.475 0-13.678-1.797-14.905-4.15 1.227-2.364 7.43-4.162 14.905-4.162 7.466.001 13.678 1.798 14.905 4.162z"
                                            ></path>
                                        </g>
                                        <g id="Group_82">
                                            <path
                                                id="Vector_146"
                                                fill="#fff"
                                                d="M42.592 200.97v-.602c-1.603-.006-3.262-.113-4.23-.262l.664-.399c.995.143 2.433.256 3.982.256 1.963 0 3.289-.244 3.289-.584 0-.327-1.078-.53-3.125-.708-2.818-.239-4.56-.513-4.56-1.031 0-.494 1.63-.869 4.173-.958v-.602h1.716v.578c1.659.011 2.79.107 3.592.208l-.69.393a27.186 27.186 0 00-3.483-.202c-2.13 0-2.931.274-2.931.512 0 .309 1.022.465 3.428.678 2.848.251 4.284.56 4.284 1.09 0 .471-1.52.912-4.367 1.013v.62h-1.742z"
                                            ></path>
                                        </g>
                                    </g>
                                    <g id="Group_83">
                                        <path
                                            id="Vector_147"
                                            fill="url(#paint70_linear_4553_104946)"
                                            d="M66.305 197.69v4c0 .717-.358 1.412-1.02 2.065-.377.363-.851.727-1.424 1.059-.448.267-.958.524-1.513.77-.439.192-.913.374-1.423.545-.475.161-.985.321-1.513.471-.457.128-.931.246-1.423.353a49.01 49.01 0 01-1.522.332c-.457.085-.931.16-1.415.235a53.83 53.83 0 01-1.522.214 57.53 57.53 0 01-1.414.139c-.501.053-1.003.086-1.522.118-.465.032-.94.053-1.414.074-.502.022-1.003.033-1.522.033-.233.01-.466.01-.707.01-.242 0-.484 0-.717-.01-.51 0-1.02-.011-1.512-.033a56.801 56.801 0 01-1.424-.074c-.519-.032-1.02-.065-1.522-.118a62.109 62.109 0 01-1.414-.139c-.52-.064-1.03-.139-1.522-.214a48.01 48.01 0 01-1.414-.235 44.054 44.054 0 01-1.522-.332 22.702 22.702 0 01-1.415-.353 22.928 22.928 0 01-1.522-.471c-.51-.171-.984-.353-1.423-.545a14.238 14.238 0 01-1.513-.77 7.368 7.368 0 01-1.423-1.059c-.662-.653-1.02-1.348-1.02-2.065v-4.001h4.216c3.545-1.476 9.005-2.428 15.146-2.428 6.132 0 11.593.952 15.138 2.428l4.217.001z"
                                        ></path>
                                        <g id="Group_84">
                                            <path
                                                id="Vector_148"
                                                fill="url(#paint71_linear_4553_104946)"
                                                d="M46.947 204.577c10.693 0 19.361-2.875 19.361-6.422 0-3.547-8.668-6.422-19.361-6.422-10.693 0-19.362 2.875-19.362 6.422 0 3.547 8.669 6.422 19.362 6.422z"
                                            ></path>
                                            <path
                                                id="Vector_149"
                                                fill="url(#paint72_linear_4553_104946)"
                                                d="M62.079 198.16c0 .3-.072.588-.224.867-1.226-2.364-7.439-4.161-14.905-4.161-7.475 0-13.678 1.797-14.905 4.161a1.78 1.78 0 01-.224-.867c0-2.781 6.768-5.027 15.13-5.027 8.351 0 15.128 2.246 15.128 5.027z"
                                            ></path>
                                            <path
                                                id="Vector_150"
                                                fill="url(#paint73_linear_4553_104946)"
                                                d="M61.855 199.027c-1.226 2.353-7.439 4.15-14.904 4.15-7.475 0-13.679-1.797-14.905-4.15 1.226-2.364 7.43-4.162 14.904-4.162 7.466.001 13.679 1.798 14.905 4.162z"
                                            ></path>
                                        </g>
                                        <g id="Group_85">
                                            <path
                                                id="Vector_151"
                                                fill="#fff"
                                                d="M46.006 200.97v-.602c-1.603-.006-3.262-.113-4.23-.262l.664-.399c.995.143 2.434.256 3.983.256 1.963 0 3.289-.244 3.289-.584 0-.327-1.079-.53-3.125-.708-2.818-.239-4.56-.513-4.56-1.031 0-.494 1.629-.869 4.173-.958v-.602h1.715v.578c1.66.011 2.791.107 3.593.208l-.691.393a27.186 27.186 0 00-3.483-.202c-2.13 0-2.93.274-2.93.512 0 .309 1.021.465 3.428.678 2.847.251 4.284.56 4.284 1.09 0 .471-1.52.912-4.367 1.013v.62h-1.743z"
                                            ></path>
                                        </g>
                                    </g>
                                    <g id="Group_86">
                                        <path
                                            id="Vector_152"
                                            fill="url(#paint74_linear_4553_104946)"
                                            d="M62.89 193.373v4c0 .717-.358 1.412-1.02 2.065a7.33 7.33 0 01-1.424 1.059c-.447.267-.957.524-1.513.77-.438.193-.913.375-1.423.546a31.7 31.7 0 01-1.513.47 23.92 23.92 0 01-1.423.353c-.492.118-1.003.225-1.522.332-.456.086-.93.16-1.414.235-.493.075-1.003.15-1.522.214-.466.054-.931.097-1.415.139a33.29 33.29 0 01-1.522.118c-.465.032-.94.053-1.414.075-.501.021-1.003.032-1.522.032-.233.011-.465.011-.707.011-.242 0-.484 0-.716-.011-.51 0-1.02-.011-1.513-.032-.484-.022-.958-.043-1.424-.075-.519-.032-1.02-.064-1.521-.118a52.429 52.429 0 01-1.415-.139 53.83 53.83 0 01-1.522-.214 44.907 44.907 0 01-1.414-.235 44.054 44.054 0 01-1.522-.332 22.018 22.018 0 01-1.414-.353 23.566 23.566 0 01-1.522-.47 16.78 16.78 0 01-1.424-.546 14.238 14.238 0 01-1.513-.77 7.327 7.327 0 01-1.423-1.059c-.662-.653-1.02-1.348-1.02-2.065v-4h4.216c3.545-1.476 9.005-2.428 15.146-2.428 6.132 0 11.593.952 15.138 2.428h4.217z"
                                        ></path>
                                        <g id="Group_87">
                                            <path
                                                id="Vector_153"
                                                fill="url(#paint75_linear_4553_104946)"
                                                d="M43.531 200.261c10.693 0 19.361-2.875 19.361-6.422 0-3.547-8.668-6.423-19.36-6.423-10.694 0-19.362 2.876-19.362 6.423s8.668 6.422 19.361 6.422z"
                                            ></path>
                                            <path
                                                id="Vector_154"
                                                fill="url(#paint76_linear_4553_104946)"
                                                d="M58.663 193.843c0 .3-.072.589-.224.867-1.226-2.364-7.439-4.161-14.905-4.161-7.474 0-13.678 1.797-14.905 4.161a1.778 1.778 0 01-.224-.867c0-2.781 6.768-5.027 15.13-5.027 8.351 0 15.128 2.246 15.128 5.027z"
                                            ></path>
                                            <path
                                                id="Vector_155"
                                                fill="url(#paint77_linear_4553_104946)"
                                                d="M58.439 194.71c-1.227 2.353-7.44 4.15-14.905 4.15-7.475 0-13.678-1.797-14.905-4.15 1.227-2.364 7.43-4.161 14.905-4.161 7.466 0 13.678 1.797 14.905 4.161z"
                                            ></path>
                                        </g>
                                        <g id="Group_88">
                                            <path
                                                id="Vector_156"
                                                fill="#fff"
                                                d="M42.592 196.653v-.602c-1.603-.006-3.262-.113-4.23-.262l.664-.399c.995.143 2.433.256 3.982.256 1.963 0 3.289-.244 3.289-.583 0-.328-1.078-.53-3.125-.709-2.818-.238-4.56-.513-4.56-1.031 0-.494 1.63-.869 4.173-.958v-.602h1.716v.578c1.659.012 2.79.107 3.592.208l-.69.393a27.186 27.186 0 00-3.483-.202c-2.13 0-2.931.274-2.931.512 0 .31 1.022.465 3.428.679 2.848.25 4.284.56 4.284 1.09 0 .47-1.52.911-4.367 1.013v.619h-1.742z"
                                            ></path>
                                        </g>
                                    </g>
                                    <g id="Group_89">
                                        <path
                                            id="Vector_157"
                                            fill="url(#paint78_linear_4553_104946)"
                                            d="M62.89 189.056v4c0 .717-.358 1.412-1.02 2.065-.377.364-.851.727-1.424 1.059-.447.267-.958.524-1.513.77a16.78 16.78 0 01-1.423.546c-.475.16-.985.321-1.513.47-.456.129-.93.246-1.423.353-.492.118-1.003.225-1.522.332-.456.086-.93.16-1.414.235-.493.075-1.003.15-1.522.214-.466.054-.931.096-1.415.139a33.29 33.29 0 01-1.522.118c-.465.032-.94.053-1.414.075-.501.021-1.003.032-1.522.032-.233.011-.465.011-.707.011-.242 0-.484 0-.716-.011-.51 0-1.02-.011-1.513-.032-.484-.022-.958-.043-1.424-.075-.519-.032-1.02-.064-1.521-.118-.475-.043-.95-.085-1.415-.139a53.83 53.83 0 01-1.522-.214 42.18 42.18 0 01-1.414-.235 44.054 44.054 0 01-1.522-.332 22.018 22.018 0 01-1.414-.353 22.955 22.955 0 01-1.522-.47 16.78 16.78 0 01-1.424-.546 14.238 14.238 0 01-1.513-.77 7.327 7.327 0 01-1.423-1.059c-.662-.653-1.02-1.348-1.02-2.064v-4.001h4.216c3.545-1.476 9.005-2.428 15.146-2.428 6.132 0 11.593.952 15.138 2.428h4.217z"
                                        ></path>
                                        <g id="Group_90">
                                            <path
                                                id="Vector_158"
                                                fill="url(#paint79_linear_4553_104946)"
                                                d="M43.531 195.944c10.693 0 19.361-2.876 19.361-6.423s-8.668-6.422-19.36-6.422c-10.694 0-19.362 2.875-19.362 6.422 0 3.547 8.668 6.423 19.361 6.423z"
                                            ></path>
                                            <path
                                                id="Vector_159"
                                                fill="url(#paint80_linear_4553_104946)"
                                                d="M58.663 189.527c0 .299-.072.588-.224.866-1.226-2.364-7.439-4.161-14.905-4.161-7.474 0-13.678 1.797-14.905 4.161a1.777 1.777 0 01-.224-.866c0-2.782 6.768-5.028 15.13-5.028 8.351 0 15.128 2.246 15.128 5.028z"
                                            ></path>
                                            <path
                                                id="Vector_160"
                                                fill="url(#paint81_linear_4553_104946)"
                                                d="M58.439 190.393c-1.227 2.353-7.44 4.15-14.905 4.15-7.475 0-13.678-1.797-14.905-4.15 1.227-2.364 7.43-4.161 14.905-4.161 7.466 0 13.678 1.797 14.905 4.161z"
                                            ></path>
                                        </g>
                                        <g id="Group_91">
                                            <path
                                                id="Vector_161"
                                                fill="#fff"
                                                d="M42.592 192.336v-.602c-1.603-.006-3.262-.113-4.23-.262l.664-.399c.995.143 2.433.256 3.982.256 1.963 0 3.289-.244 3.289-.583 0-.328-1.078-.531-3.125-.709-2.818-.239-4.56-.513-4.56-1.031 0-.494 1.63-.869 4.173-.958v-.602h1.716v.578c1.659.012 2.79.107 3.592.208l-.69.393a27.186 27.186 0 00-3.483-.202c-2.13 0-2.931.274-2.931.512 0 .31 1.022.465 3.428.679 2.848.25 4.284.56 4.284 1.09 0 .47-1.52.911-4.367 1.013v.619h-1.742z"
                                            ></path>
                                        </g>
                                    </g>
                                    <g id="Group_92">
                                        <path
                                            id="Vector_162"
                                            fill="url(#paint82_linear_4553_104946)"
                                            d="M62.89 184.739v4.001c0 .716-.358 1.411-1.02 2.064a7.33 7.33 0 01-1.424 1.059c-.448.267-.958.524-1.513.77-.438.193-.913.375-1.423.546-.475.16-.985.321-1.513.47a23.92 23.92 0 01-1.423.353c-.493.118-1.003.225-1.522.332-.457.086-.931.161-1.414.235a53.78 53.78 0 01-1.522.214c-.466.054-.931.097-1.415.139a33.3 33.3 0 01-1.522.118c-.465.032-.94.053-1.414.075-.501.021-1.003.032-1.522.032-.233.011-.466.011-.707.011-.242 0-.484 0-.717-.011-.51 0-1.02-.011-1.512-.032a56.772 56.772 0 01-1.424-.075c-.519-.032-1.02-.064-1.522-.118-.474-.043-.948-.085-1.414-.139-.52-.064-1.03-.139-1.522-.214a44.948 44.948 0 01-1.414-.235 44.054 44.054 0 01-1.522-.332 22.001 22.001 0 01-1.415-.353 23.523 23.523 0 01-1.521-.47c-.51-.171-.985-.353-1.424-.546a14.226 14.226 0 01-1.513-.77 7.325 7.325 0 01-1.423-1.059c-.662-.653-1.02-1.348-1.02-2.065v-4h4.216c3.545-1.476 9.005-2.428 15.146-2.428 6.132 0 11.593.952 15.138 2.428h4.217z"
                                        ></path>
                                        <g id="Group_93">
                                            <path
                                                id="Vector_163"
                                                fill="url(#paint83_linear_4553_104946)"
                                                d="M43.531 191.627c10.693 0 19.361-2.875 19.361-6.422 0-3.547-8.668-6.423-19.36-6.423-10.694 0-19.362 2.876-19.362 6.423s8.668 6.422 19.361 6.422z"
                                            ></path>
                                            <path
                                                id="Vector_164"
                                                fill="url(#paint84_linear_4553_104946)"
                                                d="M58.663 185.209c0 .3-.072.589-.224.867-1.226-2.364-7.439-4.161-14.905-4.161-7.474 0-13.678 1.797-14.905 4.161a1.778 1.778 0 01-.224-.867c0-2.781 6.768-5.027 15.13-5.027 8.351 0 15.128 2.246 15.128 5.027z"
                                            ></path>
                                            <path
                                                id="Vector_165"
                                                fill="url(#paint85_linear_4553_104946)"
                                                d="M58.439 186.076c-1.227 2.353-7.44 4.15-14.905 4.15-7.475 0-13.678-1.797-14.905-4.15 1.227-2.364 7.43-4.161 14.905-4.161 7.466 0 13.678 1.797 14.905 4.161z"
                                            ></path>
                                        </g>
                                        <g id="Group_94">
                                            <path
                                                id="Vector_166"
                                                fill="#fff"
                                                d="M42.592 188.019v-.602c-1.603-.006-3.262-.113-4.23-.262l.664-.398c.995.142 2.433.255 3.982.255 1.963 0 3.289-.244 3.289-.583 0-.328-1.078-.53-3.125-.709-2.818-.238-4.56-.512-4.56-1.03 0-.495 1.63-.87 4.173-.959v-.602h1.716v.578c1.659.012 2.79.107 3.592.208l-.69.394a27.122 27.122 0 00-3.483-.203c-2.13 0-2.931.274-2.931.513 0 .309 1.022.464 3.428.678 2.848.251 4.284.56 4.284 1.09 0 .471-1.52.911-4.367 1.013v.619h-1.742z"
                                            ></path>
                                        </g>
                                    </g>
                                    <g id="Group_95">
                                        <path
                                            id="Vector_167"
                                            fill="url(#paint86_linear_4553_104946)"
                                            d="M68.686 184.739v4c0 .717-.359 1.412-1.021 2.065-.376.364-.85.727-1.423 1.059-.448.267-.958.524-1.513.77-.439.193-.913.375-1.423.546-.475.16-.985.321-1.513.47-.457.129-.931.246-1.424.353-.492.118-1.002.225-1.522.332-.456.086-.93.16-1.414.235-.492.075-1.002.15-1.522.214-.465.054-.93.096-1.414.139a33.3 33.3 0 01-1.522.118c-.466.032-.94.053-1.415.075-.5.021-1.002.032-1.522.032-.232.011-.465.011-.707.011-.241 0-.483 0-.716-.011-.51 0-1.02-.011-1.513-.032-.483-.022-.958-.043-1.423-.075-.52-.032-1.02-.064-1.522-.118-.475-.043-.949-.085-1.414-.139a53.83 53.83 0 01-1.522-.214 44.907 44.907 0 01-1.415-.235 44.054 44.054 0 01-1.522-.332 22.018 22.018 0 01-1.414-.353 23.566 23.566 0 01-1.522-.47 16.78 16.78 0 01-1.423-.546 14.226 14.226 0 01-1.513-.77 7.321 7.321 0 01-1.423-1.059c-.663-.653-1.021-1.348-1.021-2.065v-4h4.216c3.545-1.476 9.006-2.428 15.147-2.428 6.132 0 11.592.952 15.137 2.428h4.218z"
                                        ></path>
                                        <g id="Group_96">
                                            <path
                                                id="Vector_168"
                                                fill="url(#paint87_linear_4553_104946)"
                                                d="M49.328 191.627c10.692 0 19.36-2.875 19.36-6.422 0-3.547-8.668-6.423-19.36-6.423-10.694 0-19.362 2.876-19.362 6.423s8.669 6.422 19.361 6.422z"
                                            ></path>
                                            <path
                                                id="Vector_169"
                                                fill="url(#paint88_linear_4553_104946)"
                                                d="M64.46 185.209c0 .3-.072.589-.224.867-1.227-2.364-7.44-4.161-14.905-4.161-7.475 0-13.679 1.797-14.905 4.161a1.778 1.778 0 01-.224-.867c0-2.781 6.768-5.027 15.129-5.027 8.352 0 15.129 2.246 15.129 5.027z"
                                            ></path>
                                            <path
                                                id="Vector_170"
                                                fill="url(#paint89_linear_4553_104946)"
                                                d="M64.235 186.076c-1.226 2.353-7.439 4.15-14.905 4.15-7.474 0-13.678-1.797-14.904-4.15 1.226-2.364 7.43-4.161 14.904-4.161 7.466 0 13.679 1.797 14.905 4.161z"
                                            ></path>
                                        </g>
                                        <g id="Group_97">
                                            <path
                                                id="Vector_171"
                                                fill="#fff"
                                                d="M48.388 188.019v-.601c-1.603-.007-3.262-.113-4.23-.263l.664-.398c.995.143 2.434.256 3.982.256 1.963 0 3.29-.245 3.29-.584 0-.328-1.079-.53-3.125-.709-2.819-.238-4.561-.512-4.561-1.03 0-.494 1.63-.869 4.174-.959v-.602h1.715v.579c1.659.011 2.791.107 3.593.207l-.691.394a27.125 27.125 0 00-3.483-.203c-2.13 0-2.93.275-2.93.513 0 .309 1.021.464 3.428.678 2.847.251 4.284.56 4.284 1.09 0 .471-1.52.912-4.368 1.013v.62h-1.742v-.001z"
                                            ></path>
                                        </g>
                                    </g>
                                    <g id="Group_98">
                                        <path
                                            id="Vector_172"
                                            fill="url(#paint90_linear_4553_104946)"
                                            d="M62.89 180.422v4.001c0 .716-.358 1.411-1.02 2.064-.377.364-.851.727-1.424 1.059-.447.267-.958.524-1.513.77a16.78 16.78 0 01-1.423.546c-.474.16-.985.321-1.513.47-.456.129-.93.246-1.423.353a49.01 49.01 0 01-1.522.332c-.456.086-.931.161-1.414.235-.493.075-1.003.15-1.522.214-.466.054-.931.097-1.415.139a33.3 33.3 0 01-1.522.118c-.465.032-.94.053-1.414.075a36.13 36.13 0 01-1.522.032c-.233.011-.465.011-.707.011-.242 0-.484 0-.717-.011-.51 0-1.02-.011-1.512-.032-.484-.022-.958-.043-1.424-.075-.519-.032-1.02-.064-1.522-.118-.474-.043-.948-.085-1.414-.139-.52-.064-1.03-.139-1.522-.214a42.217 42.217 0 01-1.414-.235 44.054 44.054 0 01-1.522-.332 22.001 22.001 0 01-1.415-.353 22.915 22.915 0 01-1.521-.47 16.78 16.78 0 01-1.424-.546 14.238 14.238 0 01-1.513-.77 7.327 7.327 0 01-1.423-1.059c-.662-.653-1.02-1.348-1.02-2.064v-4.001h4.216c3.545-1.476 9.005-2.428 15.146-2.428 6.132 0 11.593.952 15.138 2.428h4.217z"
                                        ></path>
                                        <g id="Group_99">
                                            <path
                                                id="Vector_173"
                                                fill="url(#paint91_linear_4553_104946)"
                                                d="M43.531 187.31c10.693 0 19.361-2.876 19.361-6.423s-8.668-6.422-19.36-6.422c-10.694 0-19.362 2.875-19.362 6.422 0 3.547 8.668 6.423 19.361 6.423z"
                                            ></path>
                                            <path
                                                id="Vector_174"
                                                fill="url(#paint92_linear_4553_104946)"
                                                d="M58.663 180.893c0 .299-.072.588-.224.866-1.226-2.364-7.439-4.161-14.905-4.161-7.474 0-13.678 1.797-14.905 4.161a1.777 1.777 0 01-.224-.866c0-2.782 6.768-5.028 15.13-5.028 8.351 0 15.128 2.246 15.128 5.028z"
                                            ></path>
                                            <path
                                                id="Vector_175"
                                                fill="url(#paint93_linear_4553_104946)"
                                                d="M58.439 181.759c-1.227 2.354-7.44 4.151-14.905 4.151-7.475 0-13.678-1.797-14.905-4.151 1.227-2.364 7.43-4.161 14.905-4.161 7.466 0 13.678 1.797 14.905 4.161z"
                                            ></path>
                                        </g>
                                        <g id="Group_100">
                                            <path
                                                id="Vector_176"
                                                fill="#fff"
                                                d="M42.592 183.702v-.602c-1.603-.006-3.262-.113-4.23-.262l.664-.399c.995.143 2.433.256 3.982.256 1.963 0 3.289-.244 3.289-.583 0-.328-1.078-.531-3.125-.709-2.818-.239-4.56-.513-4.56-1.031 0-.494 1.63-.869 4.173-.958v-.602h1.716v.578c1.659.011 2.79.107 3.592.208l-.69.393a27.186 27.186 0 00-3.483-.202c-2.13 0-2.931.274-2.931.512 0 .31 1.022.465 3.428.678 2.848.251 4.284.56 4.284 1.091 0 .47-1.52.911-4.367 1.012v.62h-1.742z"
                                            ></path>
                                        </g>
                                    </g>
                                    <g id="Group_101">
                                        <path
                                            id="Vector_177"
                                            fill="url(#paint94_linear_4553_104946)"
                                            d="M62.89 176.105v4.001c0 .717-.358 1.412-1.02 2.064a7.29 7.29 0 01-1.424 1.059c-.447.268-.957.525-1.513.771-.438.192-.913.374-1.423.545-.474.161-.985.321-1.513.471a24.72 24.72 0 01-1.423.353c-.492.117-1.003.224-1.522.331a57.622 57.622 0 01-2.936.45 57.53 57.53 0 01-1.415.139c-.5.053-1.002.085-1.522.117-.465.033-.94.054-1.414.075-.501.022-1.003.032-1.522.032-.233.011-.465.011-.707.011-.242 0-.484 0-.716-.011-.51 0-1.02-.01-1.513-.032a52.365 52.365 0 01-1.424-.075c-.519-.032-1.02-.064-1.521-.117a62.044 62.044 0 01-1.415-.139 53.83 53.83 0 01-1.522-.214 48.01 48.01 0 01-1.414-.235 44.054 44.054 0 01-1.522-.332 22.702 22.702 0 01-1.414-.353c-.538-.15-1.039-.3-1.522-.471-.51-.171-.985-.353-1.424-.545a14.05 14.05 0 01-1.513-.771 7.287 7.287 0 01-1.423-1.059c-.662-.652-1.02-1.347-1.02-2.064v-4.001h4.216c3.545-1.476 9.005-2.428 15.146-2.428 6.132 0 11.593.952 15.138 2.428h4.217z"
                                        ></path>
                                        <g id="Group_102">
                                            <path
                                                id="Vector_178"
                                                fill="url(#paint95_linear_4553_104946)"
                                                d="M43.531 182.993c10.693 0 19.361-2.875 19.361-6.422 0-3.547-8.668-6.422-19.36-6.422-10.694 0-19.362 2.875-19.362 6.422 0 3.547 8.668 6.422 19.361 6.422z"
                                            ></path>
                                            <path
                                                id="Vector_179"
                                                fill="url(#paint96_linear_4553_104946)"
                                                d="M58.663 176.576c0 .299-.072.588-.224.866-1.226-2.364-7.439-4.161-14.905-4.161-7.474 0-13.678 1.797-14.905 4.161a1.777 1.777 0 01-.224-.866c0-2.781 6.768-5.028 15.13-5.028 8.351 0 15.128 2.247 15.128 5.028z"
                                            ></path>
                                            <path
                                                id="Vector_180"
                                                fill="url(#paint97_linear_4553_104946)"
                                                d="M58.439 177.442c-1.227 2.354-7.44 4.151-14.905 4.151-7.475 0-13.678-1.797-14.905-4.151 1.227-2.364 7.43-4.161 14.905-4.161 7.466 0 13.678 1.797 14.905 4.161z"
                                            ></path>
                                        </g>
                                        <g id="Group_103">
                                            <path
                                                id="Vector_181"
                                                fill="#fff"
                                                d="M42.592 179.386v-.602c-1.603-.006-3.262-.113-4.23-.262l.664-.399c.995.143 2.433.256 3.982.256 1.963 0 3.289-.244 3.289-.584 0-.327-1.078-.53-3.125-.708-2.818-.239-4.56-.513-4.56-1.031 0-.494 1.63-.869 4.173-.958v-.602h1.716v.578c1.659.011 2.79.107 3.592.208l-.69.393a27.186 27.186 0 00-3.483-.202c-2.13 0-2.931.274-2.931.512 0 .309 1.022.465 3.428.678 2.848.251 4.284.56 4.284 1.091 0 .47-1.52.911-4.367 1.012v.62h-1.742z"
                                            ></path>
                                        </g>
                                    </g>
                                    <g id="coin6">
                                        <path
                                            id="Vector_182"
                                            fill="url(#paint98_linear_4553_104946)"
                                            d="M62.89 171.788v4.001c0 .717-.358 1.412-1.02 2.064-.377.364-.851.728-1.424 1.059a14.04 14.04 0 01-1.513.771c-.438.192-.913.374-1.423.545-.475.161-.985.321-1.513.471-.456.128-.93.246-1.423.353a52.13 52.13 0 01-1.522.331 57.622 57.622 0 01-2.936.45 57.53 57.53 0 01-1.415.139 34.8 34.8 0 01-1.522.117c-.465.033-.94.054-1.414.075-.501.022-1.003.032-1.522.032-.233.011-.465.011-.707.011-.242 0-.484 0-.716-.011-.51 0-1.02-.01-1.513-.032a52.365 52.365 0 01-1.424-.075c-.519-.032-1.02-.064-1.521-.117a62.044 62.044 0 01-1.415-.139 57.74 57.74 0 01-2.936-.45 41.86 41.86 0 01-1.522-.331 22.702 22.702 0 01-1.414-.353c-.538-.15-1.039-.3-1.522-.471-.51-.171-.985-.353-1.424-.545a14.05 14.05 0 01-1.513-.771 7.287 7.287 0 01-1.423-1.059c-.662-.652-1.02-1.347-1.02-2.064v-4.001h4.216c3.545-1.476 9.005-2.428 15.146-2.428 6.132 0 11.593.952 15.138 2.428h4.217z"
                                        ></path>
                                        <g id="Group_105">
                                            <path
                                                id="Vector_183"
                                                fill="url(#paint99_linear_4553_104946)"
                                                d="M43.531 178.676c10.693 0 19.361-2.875 19.361-6.422 0-3.547-8.668-6.422-19.36-6.422-10.694 0-19.362 2.875-19.362 6.422 0 3.547 8.668 6.422 19.361 6.422z"
                                            ></path>
                                            <path
                                                id="Vector_184"
                                                fill="url(#paint100_linear_4553_104946)"
                                                d="M58.663 172.259c0 .3-.072.588-.224.866-1.226-2.364-7.439-4.161-14.905-4.161-7.474 0-13.678 1.797-14.905 4.161a1.774 1.774 0 01-.224-.866c0-2.781 6.768-5.028 15.13-5.028 8.351 0 15.128 2.247 15.128 5.028z"
                                            ></path>
                                            <path
                                                id="Vector_185"
                                                fill="url(#paint101_linear_4553_104946)"
                                                d="M58.439 173.125c-1.227 2.354-7.44 4.151-14.905 4.151-7.475 0-13.678-1.797-14.905-4.151 1.227-2.364 7.43-4.161 14.905-4.161 7.466 0 13.678 1.797 14.905 4.161z"
                                            ></path>
                                        </g>
                                        <g id="Group_106">
                                            <path
                                                id="Vector_186"
                                                fill="#fff"
                                                d="M42.592 175.069v-.602c-1.603-.006-3.262-.113-4.23-.262l.664-.399c.995.143 2.433.256 3.982.256 1.963 0 3.289-.245 3.289-.584 0-.327-1.078-.53-3.125-.709-2.818-.238-4.56-.512-4.56-1.03 0-.494 1.63-.869 4.173-.959v-.602h1.716v.579c1.659.011 2.79.107 3.592.207l-.69.394a27.122 27.122 0 00-3.483-.203c-2.13 0-2.931.275-2.931.513 0 .309 1.022.464 3.428.678 2.848.251 4.284.56 4.284 1.09 0 .471-1.52.911-4.367 1.013v.62h-1.742z"
                                            ></path>
                                        </g>
                                    </g>
                                    <g id="coin5">
                                        <path
                                            id="Vector_187"
                                            fill="url(#paint102_linear_4553_104946)"
                                            d="M56.094 171.789v4c0 .717-.358 1.412-1.02 2.065-.376.363-.85.727-1.424 1.059-.447.267-.957.524-1.513.77-.438.192-.913.374-1.423.546-.474.16-.985.32-1.513.47-.456.129-.93.246-1.423.353-.492.118-1.003.225-1.522.332-.456.085-.93.16-1.414.235-.493.075-1.003.15-1.522.214-.466.054-.931.096-1.415.139-.5.054-1.002.086-1.521.118-.466.032-.94.053-1.415.075-.501.021-1.002.032-1.522.032-.233.011-.465.011-.707.011-.242 0-.483 0-.716-.011-.51 0-1.02-.011-1.513-.032-.483-.022-.958-.043-1.423-.075-.52-.032-1.02-.064-1.522-.118-.475-.043-.95-.085-1.415-.139-.519-.064-1.03-.139-1.521-.214a42.217 42.217 0 01-1.415-.235 43.939 43.939 0 01-1.522-.332 21.985 21.985 0 01-1.414-.353 22.933 22.933 0 01-1.522-.47c-.51-.171-.984-.353-1.423-.546a14.238 14.238 0 01-1.513-.77 7.327 7.327 0 01-1.423-1.059c-.663-.653-1.02-1.348-1.02-2.065v-4h4.215c3.546-1.476 9.006-2.429 15.147-2.428 6.132 0 11.593.952 15.137 2.428h4.217z"
                                        ></path>
                                        <g id="Group_108">
                                            <path
                                                id="Vector_188"
                                                fill="url(#paint103_linear_4553_104946)"
                                                d="M36.736 178.676c10.693 0 19.361-2.875 19.361-6.422 0-3.547-8.668-6.422-19.361-6.422-10.693 0-19.362 2.875-19.362 6.422 0 3.547 8.669 6.422 19.362 6.422z"
                                            ></path>
                                            <path
                                                id="Vector_189"
                                                fill="url(#paint104_linear_4553_104946)"
                                                d="M51.868 172.259c0 .3-.071.588-.224.866-1.226-2.364-7.439-4.161-14.904-4.161-7.475 0-13.679 1.797-14.905 4.161a1.774 1.774 0 01-.224-.866c0-2.781 6.767-5.028 15.128-5.028 8.353 0 15.13 2.247 15.13 5.028z"
                                            ></path>
                                            <path
                                                id="Vector_190"
                                                fill="url(#paint105_linear_4553_104946)"
                                                d="M51.643 173.125c-1.226 2.354-7.439 4.151-14.904 4.151-7.475 0-13.679-1.797-14.905-4.151 1.226-2.364 7.43-4.161 14.905-4.161 7.465 0 13.678 1.797 14.904 4.161z"
                                            ></path>
                                        </g>
                                        <g id="Group_109">
                                            <path
                                                id="Vector_191"
                                                fill="#fff"
                                                d="M35.796 175.069v-.602c-1.602-.006-3.261-.113-4.23-.262l.665-.399c.995.143 2.433.256 3.982.256 1.963 0 3.289-.245 3.289-.584 0-.327-1.079-.53-3.125-.709-2.819-.238-4.56-.512-4.56-1.03 0-.494 1.629-.869 4.173-.959v-.602h1.715v.579c1.66.011 2.791.107 3.593.207l-.691.394a27.125 27.125 0 00-3.483-.203c-2.13 0-2.93.275-2.93.513 0 .309 1.021.464 3.428.678 2.847.251 4.284.56 4.284 1.09 0 .471-1.52.911-4.367 1.013v.62h-1.743z"
                                            ></path>
                                        </g>
                                    </g>
                                    <g id="coin4">
                                        <path
                                            id="Vector_192"
                                            fill="url(#paint106_linear_4553_104946)"
                                            d="M62.89 167.471v4.001c0 .717-.358 1.412-1.02 2.065-.377.363-.851.727-1.424 1.059-.447.267-.958.524-1.513.77-.438.192-.913.374-1.423.545-.475.161-.985.321-1.513.471-.456.128-.93.246-1.423.353a49.01 49.01 0 01-1.522.332c-.456.085-.931.16-1.414.235-.493.075-1.003.15-1.522.214a57.53 57.53 0 01-1.415.139 34.8 34.8 0 01-1.522.117c-.465.033-.94.054-1.414.075-.501.022-1.003.032-1.522.032-.233.011-.465.011-.707.011-.242 0-.484 0-.716-.011-.51 0-1.02-.01-1.513-.032a52.365 52.365 0 01-1.424-.075c-.519-.032-1.02-.064-1.521-.117a62.044 62.044 0 01-1.415-.139 53.83 53.83 0 01-1.522-.214 48.01 48.01 0 01-1.414-.235 44.054 44.054 0 01-1.522-.332 22.702 22.702 0 01-1.414-.353c-.538-.15-1.039-.3-1.522-.471-.51-.171-.985-.353-1.424-.545a14.238 14.238 0 01-1.513-.77 7.368 7.368 0 01-1.423-1.059c-.662-.653-1.02-1.348-1.02-2.065v-4.001h4.216c3.545-1.476 9.005-2.428 15.146-2.428 6.132 0 11.593.952 15.138 2.428h4.217z"
                                        ></path>
                                        <g id="Group_111">
                                            <path
                                                id="Vector_193"
                                                fill="url(#paint107_linear_4553_104946)"
                                                d="M43.531 174.36c10.693 0 19.361-2.876 19.361-6.423s-8.668-6.422-19.36-6.422c-10.694 0-19.362 2.875-19.362 6.422 0 3.547 8.668 6.423 19.361 6.423z"
                                            ></path>
                                            <path
                                                id="Vector_194"
                                                fill="url(#paint108_linear_4553_104946)"
                                                d="M58.663 167.942c0 .3-.072.588-.224.867-1.226-2.364-7.439-4.161-14.905-4.161-7.474 0-13.678 1.797-14.905 4.161a1.778 1.778 0 01-.224-.867c0-2.781 6.768-5.027 15.13-5.027 8.351 0 15.128 2.246 15.128 5.027z"
                                            ></path>
                                            <path
                                                id="Vector_195"
                                                fill="url(#paint109_linear_4553_104946)"
                                                d="M58.439 168.809c-1.227 2.353-7.44 4.15-14.905 4.15-7.475 0-13.678-1.797-14.905-4.15 1.227-2.364 7.43-4.162 14.905-4.162 7.466 0 13.678 1.798 14.905 4.162z"
                                            ></path>
                                        </g>
                                        <g id="Group_112">
                                            <path
                                                id="Vector_196"
                                                fill="#fff"
                                                d="M42.592 170.752v-.602c-1.603-.007-3.262-.113-4.23-.262l.664-.399c.995.143 2.433.256 3.982.256 1.963 0 3.289-.245 3.289-.584 0-.327-1.078-.53-3.125-.708-2.818-.239-4.56-.513-4.56-1.031 0-.494 1.63-.869 4.173-.958v-.602h1.716v.578c1.659.011 2.79.107 3.592.208l-.69.393a27.186 27.186 0 00-3.483-.202c-2.13 0-2.931.274-2.931.512 0 .309 1.022.465 3.428.678 2.848.251 4.284.56 4.284 1.09 0 .471-1.52.912-4.367 1.013v.62h-1.742z"
                                            ></path>
                                        </g>
                                    </g>
                                    <g id="coin3">
                                        <path
                                            id="Vector_197"
                                            fill="url(#paint110_linear_4553_104946)"
                                            d="M62.89 163.154v4.001c0 .717-.358 1.412-1.02 2.065-.377.363-.851.727-1.424 1.059-.448.267-.958.524-1.513.77-.438.192-.913.374-1.423.545-.475.161-.985.321-1.513.471a24.72 24.72 0 01-1.423.353c-.493.118-1.003.225-1.522.332-.457.085-.931.16-1.415.235-.492.075-1.002.15-1.522.214a57.53 57.53 0 01-1.414.139 33.29 33.29 0 01-1.522.118c-.465.032-.94.053-1.414.075a36.14 36.14 0 01-1.522.032c-.233.01-.466.01-.707.01-.242 0-.484 0-.717-.01-.51 0-1.02-.011-1.512-.032-.484-.022-.958-.043-1.424-.075-.519-.032-1.02-.065-1.522-.118a62.156 62.156 0 01-1.414-.139c-.52-.064-1.03-.139-1.522-.214-.483-.075-.958-.15-1.414-.235a44.054 44.054 0 01-1.522-.332 22.702 22.702 0 01-1.414-.353c-.538-.15-1.039-.3-1.522-.471-.51-.171-.985-.353-1.424-.545a14.226 14.226 0 01-1.513-.77 7.366 7.366 0 01-1.423-1.059c-.662-.653-1.02-1.348-1.02-2.065v-4.001h4.216c3.545-1.476 9.005-2.428 15.146-2.428 6.132 0 11.593.952 15.138 2.429l4.217-.001z"
                                        ></path>
                                        <g id="Group_114">
                                            <path
                                                id="Vector_198"
                                                fill="url(#paint111_linear_4553_104946)"
                                                d="M43.531 170.042c10.693 0 19.361-2.875 19.361-6.422 0-3.547-8.668-6.422-19.36-6.422-10.694 0-19.362 2.875-19.362 6.422 0 3.547 8.668 6.422 19.361 6.422z"
                                            ></path>
                                            <path
                                                id="Vector_199"
                                                fill="url(#paint112_linear_4553_104946)"
                                                d="M58.663 163.625c0 .299-.072.588-.224.866-1.226-2.364-7.439-4.161-14.905-4.161-7.474 0-13.678 1.797-14.905 4.161a1.777 1.777 0 01-.224-.866c0-2.781 6.768-5.028 15.13-5.028 8.351 0 15.128 2.247 15.128 5.028z"
                                            ></path>
                                            <path
                                                id="Vector_200"
                                                fill="url(#paint113_linear_4553_104946)"
                                                d="M58.439 164.492c-1.227 2.353-7.44 4.15-14.905 4.15-7.475 0-13.678-1.797-14.905-4.15 1.227-2.364 7.43-4.161 14.905-4.161 7.466 0 13.678 1.797 14.905 4.161z"
                                            ></path>
                                        </g>
                                        <g id="Group_115">
                                            <path
                                                id="Vector_201"
                                                fill="#fff"
                                                d="M42.592 166.435v-.602c-1.603-.007-3.262-.113-4.23-.262l.664-.399c.995.143 2.433.256 3.982.256 1.963 0 3.289-.245 3.289-.584 0-.327-1.078-.53-3.125-.709-2.818-.238-4.56-.512-4.56-1.03 0-.494 1.63-.869 4.173-.959v-.602h1.716v.579c1.659.011 2.79.107 3.592.208l-.69.393a27.122 27.122 0 00-3.483-.203c-2.13 0-2.931.275-2.931.513 0 .309 1.022.464 3.428.678 2.848.251 4.284.56 4.284 1.09 0 .471-1.52.912-4.367 1.013v.62h-1.742z"
                                            ></path>
                                        </g>
                                    </g>
                                    <g id="coin2">
                                        <path
                                            id="Vector_202"
                                            fill="url(#paint114_linear_4553_104946)"
                                            d="M62.89 158.838v4c0 .717-.358 1.412-1.02 2.065-.377.363-.851.727-1.424 1.059-.448.267-.958.524-1.513.77-.439.192-.913.374-1.423.545-.475.161-.985.321-1.513.471-.457.129-.931.246-1.423.353-.493.118-1.003.225-1.522.332-.457.085-.931.16-1.415.235-.492.075-1.002.15-1.522.214-.465.054-.93.096-1.414.139a33.29 33.29 0 01-1.522.118c-.465.032-.94.053-1.414.075-.502.021-1.003.032-1.522.032-.233.01-.466.01-.707.01-.242 0-.484 0-.717-.01-.51 0-1.02-.011-1.512-.032-.484-.022-.958-.043-1.424-.075-.519-.032-1.02-.064-1.522-.118-.474-.043-.948-.085-1.414-.139-.52-.064-1.03-.139-1.522-.214-.483-.075-.958-.15-1.414-.235a44.054 44.054 0 01-1.522-.332 22.018 22.018 0 01-1.414-.353 22.928 22.928 0 01-1.522-.471c-.51-.171-.985-.353-1.424-.545a14.226 14.226 0 01-1.513-.77 7.366 7.366 0 01-1.423-1.059c-.662-.653-1.02-1.348-1.02-2.065v-4h4.216c3.545-1.477 9.005-2.429 15.146-2.429 6.132 0 11.593.953 15.138 2.429h4.217z"
                                        ></path>
                                        <g id="Group_117">
                                            <path
                                                id="Vector_203"
                                                fill="url(#paint115_linear_4553_104946)"
                                                d="M43.531 165.725c10.693 0 19.361-2.875 19.361-6.422 0-3.547-8.668-6.422-19.36-6.422-10.694 0-19.362 2.875-19.362 6.422 0 3.547 8.668 6.422 19.361 6.422z"
                                            ></path>
                                            <path
                                                id="Vector_204"
                                                fill="url(#paint116_linear_4553_104946)"
                                                d="M58.663 159.308c0 .3-.072.588-.224.867-1.226-2.364-7.439-4.162-14.905-4.162-7.474 0-13.678 1.798-14.905 4.162a1.778 1.778 0 01-.224-.867c0-2.781 6.768-5.027 15.13-5.027 8.351 0 15.128 2.246 15.128 5.027z"
                                            ></path>
                                            <path
                                                id="Vector_205"
                                                fill="url(#paint117_linear_4553_104946)"
                                                d="M58.439 160.174c-1.227 2.354-7.44 4.151-14.905 4.151-7.475 0-13.678-1.797-14.905-4.151 1.227-2.364 7.43-4.161 14.905-4.161 7.466 0 13.678 1.797 14.905 4.161z"
                                            ></path>
                                        </g>
                                    </g>
                                    <g id="coin1">
                                        <path
                                            id="Vector_206"
                                            fill="url(#paint118_linear_4553_104946)"
                                            d="M27.393 207.05c-3.67 8.561-11.917 12.965-18.422 9.836-6.505-3.128-8.803-12.604-5.133-21.165 3.67-8.561 11.918-12.964 18.423-9.836 6.504 3.128 8.802 12.604 5.132 21.165z"
                                        ></path>
                                        <path
                                            id="Vector_207"
                                            fill="url(#paint119_linear_4553_104946)"
                                            d="M25.303 206.465c-3.67 8.561-11.918 12.964-18.423 9.836-6.504-3.129-8.802-12.605-5.132-21.165 3.67-8.561 11.917-12.965 18.422-9.836 6.505 3.128 8.803 12.604 5.133 21.165z"
                                        ></path>
                                        <path
                                            id="Vector_208"
                                            fill="url(#paint120_linear_4553_104946)"
                                            d="M15.186 213.304c-2.319.891-4.762.91-6.955-.142-5.193-2.494-7.028-10.051-4.1-16.879 2.058-4.797 5.908-7.955 9.81-8.543-3.141 1.223-6.033 4.058-7.716 7.984-2.927 6.827-1.092 14.385 4.091 16.879 1.549.739 3.214.948 4.87.701z"
                                        ></path>
                                        <path
                                            id="Vector_209"
                                            fill="url(#paint121_linear_4553_104946)"
                                            d="M22.92 205.32c-1.692 3.935-4.583 6.77-7.734 7.984-1.656.247-3.321.038-4.87-.702-5.183-2.494-7.018-10.051-4.091-16.878 1.683-3.926 4.574-6.761 7.716-7.984 1.665-.256 3.33-.038 4.88.701 5.191 2.494 7.027 10.052 4.1 16.879z"
                                        ></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                    <g id="Pie">
                        <g id="Group_119" fill="#2F2F46" opacity="0.18">
                            <path
                                id="Vector_210"
                                d="M173.629 289.274c17.461 0 31.617-6.263 31.617-13.988s-14.156-13.988-31.617-13.988c-17.462 0-31.618 6.263-31.618 13.988s14.156 13.988 31.618 13.988z"
                                opacity="0.037"
                            ></path>
                            <path
                                id="Vector_211"
                                d="M173.628 289.043c17.174 0 31.096-6.159 31.096-13.757s-13.922-13.757-31.096-13.757c-17.173 0-31.096 6.159-31.096 13.757s13.923 13.757 31.096 13.757z"
                                opacity="0.074"
                            ></path>
                            <path
                                id="Vector_212"
                                d="M173.628 288.812c16.886 0 30.575-6.056 30.575-13.526 0-7.471-13.689-13.527-30.575-13.527s-30.575 6.056-30.575 13.527c0 7.47 13.689 13.526 30.575 13.526z"
                                opacity="0.111"
                            ></path>
                            <path
                                id="Vector_213"
                                d="M173.629 288.582c16.598 0 30.053-5.953 30.053-13.296 0-7.343-13.455-13.296-30.053-13.296s-30.054 5.953-30.054 13.296c0 7.343 13.456 13.296 30.054 13.296z"
                                opacity="0.148"
                            ></path>
                            <path
                                id="Vector_214"
                                d="M173.628 288.351c16.311 0 29.533-5.85 29.533-13.065 0-7.216-13.222-13.066-29.533-13.066-16.31 0-29.532 5.85-29.532 13.066 0 7.215 13.222 13.065 29.532 13.065z"
                                opacity="0.185"
                            ></path>
                            <path
                                id="Vector_215"
                                d="M173.628 288.12c16.023 0 29.011-5.746 29.011-12.834 0-7.089-12.988-12.835-29.011-12.835-16.022 0-29.011 5.746-29.011 12.835 0 7.088 12.989 12.834 29.011 12.834z"
                                opacity="0.222"
                            ></path>
                            <path
                                id="Vector_216"
                                d="M173.629 287.89c15.734 0 28.489-5.643 28.489-12.604 0-6.961-12.755-12.604-28.489-12.604-15.735 0-28.49 5.643-28.49 12.604 0 6.961 12.755 12.604 28.49 12.604z"
                                opacity="0.259"
                            ></path>
                            <path
                                id="Vector_217"
                                d="M173.629 287.659c15.446 0 27.968-5.539 27.968-12.373s-12.522-12.373-27.968-12.373c-15.447 0-27.969 5.539-27.969 12.373s12.522 12.373 27.969 12.373z"
                                opacity="0.296"
                            ></path>
                            <path
                                id="Vector_218"
                                d="M173.628 287.429c15.159 0 27.448-5.437 27.448-12.143s-12.289-12.143-27.448-12.143c-15.158 0-27.447 5.437-27.447 12.143s12.289 12.143 27.447 12.143z"
                                opacity="0.333"
                            ></path>
                            <path
                                id="Vector_219"
                                d="M173.629 287.198c14.871 0 26.926-5.333 26.926-11.912 0-6.579-12.055-11.912-26.926-11.912-14.871 0-26.926 5.333-26.926 11.912 0 6.579 12.055 11.912 26.926 11.912z"
                                opacity="0.37"
                            ></path>
                            <path
                                id="Vector_220"
                                d="M173.629 286.967c14.582 0 26.404-5.23 26.404-11.681 0-6.452-11.822-11.682-26.404-11.682-14.583 0-26.405 5.23-26.405 11.682 0 6.451 11.822 11.681 26.405 11.681z"
                                opacity="0.407"
                            ></path>
                            <path
                                id="Vector_221"
                                d="M173.629 286.737c14.295 0 25.883-5.127 25.883-11.451 0-6.324-11.588-11.451-25.883-11.451s-25.884 5.127-25.884 11.451c0 6.324 11.589 11.451 25.884 11.451z"
                                opacity="0.444"
                            ></path>
                            <path
                                id="Vector_222"
                                d="M173.628 286.506c14.008 0 25.363-5.023 25.363-11.22 0-6.197-11.355-11.22-25.363-11.22-14.007 0-25.362 5.023-25.362 11.22 0 6.197 11.355 11.22 25.362 11.22z"
                                opacity="0.481"
                            ></path>
                            <path
                                id="Vector_223"
                                d="M173.629 286.276c13.719 0 24.841-4.921 24.841-10.99 0-6.07-11.122-10.99-24.841-10.99-13.719 0-24.841 4.92-24.841 10.99 0 6.069 11.122 10.99 24.841 10.99z"
                                opacity="0.518"
                            ></path>
                            <path
                                id="Vector_224"
                                d="M173.629 286.045c13.431 0 24.32-4.817 24.32-10.759 0-5.943-10.889-10.76-24.32-10.76-13.431 0-24.32 4.817-24.32 10.76 0 5.942 10.889 10.759 24.32 10.759z"
                                opacity="0.556"
                            ></path>
                            <path
                                id="Vector_225"
                                d="M173.628 285.815c13.144 0 23.799-4.714 23.799-10.529 0-5.815-10.655-10.529-23.799-10.529-13.143 0-23.798 4.714-23.798 10.529 0 5.815 10.655 10.529 23.798 10.529z"
                                opacity="0.593"
                            ></path>
                            <path
                                id="Vector_226"
                                d="M173.628 285.584c12.856 0 23.277-4.611 23.277-10.298 0-5.688-10.421-10.298-23.277-10.298-12.855 0-23.277 4.61-23.277 10.298 0 5.687 10.422 10.298 23.277 10.298z"
                                opacity="0.63"
                            ></path>
                            <path
                                id="Vector_227"
                                d="M173.629 285.353c12.567 0 22.755-4.507 22.755-10.067 0-5.56-10.188-10.067-22.755-10.067-12.568 0-22.756 4.507-22.756 10.067 0 5.56 10.188 10.067 22.756 10.067z"
                                opacity="0.667"
                            ></path>
                            <path
                                id="Vector_228"
                                d="M173.629 285.122c12.279 0 22.234-4.404 22.234-9.836 0-5.433-9.955-9.837-22.234-9.837-12.28 0-22.235 4.404-22.235 9.837 0 5.432 9.955 9.836 22.235 9.836z"
                                opacity="0.704"
                            ></path>
                            <path
                                id="Vector_229"
                                d="M173.628 284.892c11.992 0 21.714-4.301 21.714-9.606 0-5.306-9.722-9.606-21.714-9.606-11.992 0-21.713 4.3-21.713 9.606 0 5.305 9.721 9.606 21.713 9.606z"
                                opacity="0.741"
                            ></path>
                            <path
                                id="Vector_230"
                                d="M173.629 284.661c11.704 0 21.192-4.197 21.192-9.375s-9.488-9.376-21.192-9.376-21.192 4.198-21.192 9.376c0 5.178 9.488 9.375 21.192 9.375z"
                                opacity="0.778"
                            ></path>
                            <path
                                id="Vector_231"
                                d="M173.629 284.431c11.416 0 20.67-4.094 20.67-9.145 0-5.051-9.254-9.145-20.67-9.145-11.417 0-20.671 4.094-20.671 9.145 0 5.051 9.254 9.145 20.671 9.145z"
                                opacity="0.815"
                            ></path>
                            <path
                                id="Vector_232"
                                d="M173.629 284.2c11.128 0 20.149-3.991 20.149-8.914s-9.021-8.914-20.149-8.914c-11.129 0-20.15 3.991-20.15 8.914s9.021 8.914 20.15 8.914z"
                                opacity="0.852"
                            ></path>
                            <path
                                id="Vector_233"
                                d="M173.629 283.969c10.84 0 19.628-3.887 19.628-8.683 0-4.796-8.788-8.684-19.628-8.684-10.841 0-19.628 3.888-19.628 8.684 0 4.796 8.787 8.683 19.628 8.683z"
                                opacity="0.889"
                            ></path>
                            <path
                                id="Vector_234"
                                d="M173.629 283.739c10.552 0 19.107-3.785 19.107-8.453 0-4.669-8.555-8.453-19.107-8.453-10.553 0-19.107 3.784-19.107 8.453 0 4.668 8.554 8.453 19.107 8.453z"
                                opacity="0.926"
                            ></path>
                            <path
                                id="Vector_235"
                                d="M173.629 283.508c10.264 0 18.585-3.681 18.585-8.222 0-4.541-8.321-8.223-18.585-8.223-10.265 0-18.586 3.682-18.586 8.223s8.321 8.222 18.586 8.222z"
                                opacity="0.963"
                            ></path>
                            <path
                                id="Vector_236"
                                d="M173.629 283.278c9.976 0 18.064-3.578 18.064-7.992s-8.088-7.992-18.064-7.992c-9.977 0-18.065 3.578-18.065 7.992s8.088 7.992 18.065 7.992z"
                            ></path>
                        </g>
                        <g id="Group_120">
                            <path
                                id="orange1"
                                fill="url(#paint122_linear_4553_104946)"
                                d="M214.807 242.522v13.639c0 8.338-10.817 15.448-26.025 18.234v-13.639c8.973-1.642 16.436-4.803 21.018-8.815a17.197 17.197 0 002.456-2.599c1.653-2.121 2.551-4.408 2.551-6.82z"
                            ></path>
                            <path
                                id="Vector_238"
                                fill="url(#paint123_linear_4553_104946)"
                                d="M196.646 239.861l-22.246 2.661 9.824 12.475 4.558 5.759c-3.117.583-6.4.978-9.848 1.165a81.402 81.402 0 01-4.534.124c-17.382 0-32.164-5.28-37.88-12.703-1.653-2.121-2.55-4.428-2.55-6.819 0-10.791 18.09-19.523 40.43-19.523 18.987 0 34.904 6.32 39.226 14.845l-16.98 2.016z"
                            ></path>
                            <path
                                id="orange2"
                                fill="url(#paint124_linear_4553_104946)"
                                d="M214.807 242.522c0 2.412-.898 4.699-2.551 6.82a17.197 17.197 0 01-2.456 2.599c-4.582 4.012-12.045 7.172-21.018 8.815l-4.535-5.759h-.023l-9.824-12.475 22.246-2.661h.023l16.957-2.017c.708 1.352 1.11 2.786 1.157 4.242.024.145.024.291.024.436z"
                            ></path>
                            <path
                                fill="#79D4CE"
                                d="M188.782 260.756v13.639c-4.464.832-9.305 1.289-14.382 1.289-22.341 0-40.431-8.732-40.431-19.523v-13.639c0 2.391.898 4.699 2.551 6.82 5.715 7.423 20.498 12.703 37.88 12.703 1.535 0 3.046-.041 4.534-.124a76.097 76.097 0 009.848-1.165z"
                            ></path>
                        </g>
                    </g>
                    <g id="Steps">
                        <g id="Steps1">
                            <path
                                id="Vector_241"
                                fill="url(#paint125_linear_4553_104946)"
                                d="M68.718 205.83l13.558-5.559 12.878 5.559-13.218 6.16-13.219-6.16z"
                            ></path>
                            <path
                                id="Vector_242"
                                fill="url(#paint126_linear_4553_104946)"
                                d="M95.154 205.831v31.348l-13.218 6.16V211.99l13.218-6.159z"
                            ></path>
                            <path
                                id="Vector_243"
                                fill="url(#paint127_linear_4553_104946)"
                                d="M68.718 205.831v31.348l13.218 6.16V211.99l-13.219-6.159z"
                            ></path>
                        </g>
                        <g id="Steps2">
                            <path
                                id="Vector_244"
                                fill="url(#paint128_linear_4553_104946)"
                                d="M51.498 223.229l13.559-5.56 12.877 5.56-13.218 6.159-13.218-6.159z"
                            ></path>
                            <path
                                id="Vector_245"
                                fill="url(#paint129_linear_4553_104946)"
                                d="M77.934 223.229v20.738l-13.218 6.159v-20.738l13.218-6.159z"
                            ></path>
                            <path
                                id="Vector_246"
                                fill="url(#paint130_linear_4553_104946)"
                                d="M51.498 223.229v20.738l13.218 6.159v-20.738l-13.218-6.159z"
                            ></path>
                        </g>
                        <g id="Steps3">
                            <path
                                id="Vector_247"
                                fill="url(#paint131_linear_4553_104946)"
                                d="M34.278 234.309l13.559-5.559 12.877 5.56-13.218 6.159-13.218-6.16z"
                            ></path>
                            <path
                                id="Vector_248"
                                fill="url(#paint132_linear_4553_104946)"
                                d="M60.715 234.31v16.444l-13.219 6.159v-16.444l13.219-6.159z"
                            ></path>
                            <path
                                id="Vector_249"
                                fill="url(#paint133_linear_4553_104946)"
                                d="M34.278 234.31v16.444l13.218 6.159v-16.444l-13.218-6.159z"
                            ></path>
                        </g>
                        <g id="Steps4">
                            <path
                                id="Vector_250"
                                fill="url(#paint134_linear_4553_104946)"
                                d="M17.058 250.126l13.559-5.56 12.878 5.56-13.219 6.159-13.218-6.159z"
                            ></path>
                            <path
                                id="Vector_251"
                                fill="url(#paint135_linear_4553_104946)"
                                d="M43.494 250.125v7.416L30.276 263.7v-7.415l13.218-6.16z"
                            ></path>
                            <path
                                id="Vector_252"
                                fill="url(#paint136_linear_4553_104946)"
                                d="M17.058 250.125v7.416l13.218 6.159v-7.415l-13.218-6.16z"
                            ></path>
                        </g>
                    </g>
                    <g id="Numbers">
                        <text
                            id="digital_numbers"
                            fill="#fff"
                            fontFamily="Noto Sans"
                            fontSize="16"
                            fontWeight="500"
                            letterSpacing="0.15"
                            transform="rotate(-21.567 128.465 -54.275)"
                            xmlSpace="preserve"
                            style={{ whiteSpace: "pre" }}
                        >
                            <tspan x="0.394" y="18.208">
                                1200.00
                            </tspan>
                        </text>
                        <path
                            id="digital_tick"
                            fill="#fff"
                            d="M0 0H2V14H0z"
                            transform="rotate(-21 110.653 -224.898)"
                        ></path>
                    </g>
                    <g id="Tick">
                        <g id="Group_121" fill="#2F2F46" opacity="0.18">
                            <path
                                id="Vector_253"
                                d="M192.442 235.568c23.059 0 41.753-9.394 41.753-20.982 0-11.587-18.694-20.981-41.753-20.981-23.06 0-41.754 9.394-41.754 20.981 0 11.588 18.694 20.982 41.754 20.982z"
                                opacity="0.037"
                            ></path>
                            <path
                                id="Vector_254"
                                d="M192.442 235.222c22.679 0 41.065-9.239 41.065-20.636 0-11.396-18.386-20.635-41.065-20.635-22.68 0-41.065 9.239-41.065 20.635 0 11.397 18.385 20.636 41.065 20.636z"
                                opacity="0.074"
                            ></path>
                            <path
                                id="Vector_255"
                                d="M192.442 234.876c22.299 0 40.376-9.084 40.376-20.29 0-11.205-18.077-20.289-40.376-20.289-22.3 0-40.377 9.084-40.377 20.289 0 11.206 18.077 20.29 40.377 20.29z"
                                opacity="0.111"
                            ></path>
                            <path
                                id="Vector_256"
                                d="M192.442 234.53c21.919 0 39.688-8.929 39.688-19.944 0-11.014-17.769-19.943-39.688-19.943-21.919 0-39.688 8.929-39.688 19.943 0 11.015 17.769 19.944 39.688 19.944z"
                                opacity="0.148"
                            ></path>
                            <path
                                id="Vector_257"
                                d="M192.442 234.185c21.538 0 38.999-8.775 38.999-19.598 0-10.824-17.461-19.598-38.999-19.598-21.539 0-39 8.774-39 19.598 0 10.823 17.461 19.598 39 19.598z"
                                opacity="0.185"
                            ></path>
                            <path
                                id="Vector_258"
                                d="M192.441 233.838c21.159 0 38.312-8.619 38.312-19.252 0-10.632-17.153-19.252-38.312-19.252-21.158 0-38.311 8.62-38.311 19.252 0 10.633 17.153 19.252 38.311 19.252z"
                                opacity="0.222"
                            ></path>
                            <path
                                id="Vector_259"
                                d="M192.442 233.492c20.778 0 37.623-8.464 37.623-18.906 0-10.441-16.845-18.906-37.623-18.906-20.779 0-37.623 8.465-37.623 18.906 0 10.442 16.844 18.906 37.623 18.906z"
                                opacity="0.259"
                            ></path>
                            <path
                                id="Vector_260"
                                d="M192.442 233.147c20.398 0 36.934-8.31 36.934-18.561 0-10.25-16.536-18.56-36.934-18.56-20.399 0-36.935 8.31-36.935 18.56 0 10.251 16.536 18.561 36.935 18.561z"
                                opacity="0.296"
                            ></path>
                            <path
                                id="Vector_261"
                                d="M192.442 232.801c20.018 0 36.246-8.155 36.246-18.215 0-10.059-16.228-18.214-36.246-18.214s-36.246 8.155-36.246 18.214c0 10.06 16.228 18.215 36.246 18.215z"
                                opacity="0.333"
                            ></path>
                            <path
                                id="Vector_262"
                                d="M192.442 232.455c19.638 0 35.558-8 35.558-17.869 0-9.868-15.92-17.868-35.558-17.868-19.638 0-35.558 8-35.558 17.868 0 9.869 15.92 17.869 35.558 17.869z"
                                opacity="0.37"
                            ></path>
                            <path
                                id="Vector_263"
                                d="M192.442 232.109c19.258 0 34.869-7.845 34.869-17.522 0-9.678-15.611-17.523-34.869-17.523s-34.87 7.845-34.87 17.523c0 9.677 15.612 17.522 34.87 17.522z"
                                opacity="0.407"
                            ></path>
                            <path
                                id="Vector_264"
                                d="M192.442 231.763c18.877 0 34.181-7.69 34.181-17.176 0-9.487-15.304-17.177-34.181-17.177-18.878 0-34.182 7.69-34.182 17.177 0 9.486 15.304 17.176 34.182 17.176z"
                                opacity="0.444"
                            ></path>
                            <path
                                id="Vector_265"
                                d="M192.442 231.417c18.498 0 33.493-7.535 33.493-16.83 0-9.296-14.995-16.831-33.493-16.831-18.498 0-33.493 7.535-33.493 16.831 0 9.295 14.995 16.83 33.493 16.83z"
                                opacity="0.481"
                            ></path>
                            <path
                                id="Vector_266"
                                d="M192.442 231.071c18.117 0 32.804-7.38 32.804-16.485 0-9.104-14.687-16.484-32.804-16.484-18.118 0-32.805 7.38-32.805 16.484 0 9.105 14.687 16.485 32.805 16.485z"
                                opacity="0.518"
                            ></path>
                            <path
                                id="Vector_267"
                                d="M192.442 230.725c17.737 0 32.116-7.226 32.116-16.139s-14.379-16.139-32.116-16.139c-17.738 0-32.116 7.226-32.116 16.139s14.378 16.139 32.116 16.139z"
                                opacity="0.556"
                            ></path>
                            <path
                                id="Vector_268"
                                d="M192.441 230.379c17.358 0 31.428-7.07 31.428-15.792 0-8.723-14.07-15.793-31.428-15.793-17.357 0-31.427 7.07-31.427 15.793 0 8.722 14.07 15.792 31.427 15.792z"
                                opacity="0.593"
                            ></path>
                            <path
                                id="Vector_269"
                                d="M192.442 230.033c16.976 0 30.739-6.916 30.739-15.447 0-8.531-13.763-15.447-30.739-15.447-16.977 0-30.74 6.916-30.74 15.447 0 8.531 13.763 15.447 30.74 15.447z"
                                opacity="0.63"
                            ></path>
                            <path
                                id="Vector_270"
                                d="M192.441 229.687c16.597 0 30.051-6.761 30.051-15.101s-13.454-15.101-30.051-15.101c-16.597 0-30.051 6.761-30.051 15.101s13.454 15.101 30.051 15.101z"
                                opacity="0.667"
                            ></path>
                            <path
                                id="Vector_271"
                                d="M192.442 229.341c16.216 0 29.362-6.606 29.362-14.755 0-8.149-13.146-14.755-29.362-14.755-16.217 0-29.363 6.606-29.363 14.755 0 8.149 13.146 14.755 29.363 14.755z"
                                opacity="0.704"
                            ></path>
                            <path
                                id="Vector_272"
                                d="M192.442 228.996c15.836 0 28.674-6.451 28.674-14.409 0-7.958-12.838-14.41-28.674-14.41-15.837 0-28.675 6.452-28.675 14.41s12.838 14.409 28.675 14.409z"
                                opacity="0.741"
                            ></path>
                            <path
                                id="Vector_273"
                                d="M192.441 228.65c15.457 0 27.986-6.297 27.986-14.064 0-7.766-12.529-14.063-27.986-14.063-15.456 0-27.985 6.297-27.985 14.063 0 7.767 12.529 14.064 27.985 14.064z"
                                opacity="0.778"
                            ></path>
                            <path
                                id="Vector_274"
                                d="M192.441 228.304c15.076 0 27.298-6.142 27.298-13.718 0-7.575-12.222-13.717-27.298-13.717-15.076 0-27.297 6.142-27.297 13.717 0 7.576 12.221 13.718 27.297 13.718z"
                                opacity="0.815"
                            ></path>
                            <path
                                id="Vector_275"
                                d="M192.442 227.958c14.695 0 26.609-5.987 26.609-13.372 0-7.384-11.914-13.371-26.609-13.371-14.696 0-26.61 5.987-26.61 13.371 0 7.385 11.914 13.372 26.61 13.372z"
                                opacity="0.852"
                            ></path>
                            <path
                                id="Vector_276"
                                d="M192.442 227.612c14.315 0 25.92-5.832 25.92-13.025 0-7.194-11.605-13.026-25.92-13.026-14.316 0-25.921 5.832-25.921 13.026 0 7.193 11.605 13.025 25.921 13.025z"
                                opacity="0.889"
                            ></path>
                            <path
                                id="Vector_277"
                                d="M192.442 227.266c13.935 0 25.232-5.677 25.232-12.68 0-7.002-11.297-12.679-25.232-12.679-13.936 0-25.233 5.677-25.233 12.679 0 7.003 11.297 12.68 25.233 12.68z"
                                opacity="0.926"
                            ></path>
                            <path
                                id="Vector_278"
                                d="M192.442 226.92c13.555 0 24.544-5.522 24.544-12.334 0-6.812-10.989-12.334-24.544-12.334-13.556 0-24.544 5.522-24.544 12.334 0 6.812 10.988 12.334 24.544 12.334z"
                                opacity="0.963"
                            ></path>
                            <path
                                id="Vector_279"
                                d="M192.442 226.574c13.175 0 23.855-5.367 23.855-11.988 0-6.62-10.68-11.988-23.855-11.988s-23.856 5.368-23.856 11.988c0 6.621 10.681 11.988 23.856 11.988z"
                            ></path>
                        </g>
                        <g id="Group_122">
                            <g id="Group_123">
                                <path
                                    id="Vector_280"
                                    fill="#79D4CE"
                                    d="M222.454 180.749v8.668h-.033c-.128 1.222-.628 2.392-1.442 3.511a12.462 12.462 0 01-1.858 1.96c-.551.466-1.162.916-1.823 1.348-.577.382-1.196.738-1.858 1.086-.577.305-1.179.594-1.815.874-.594.262-1.213.517-1.857.746-.586.22-1.196.432-1.824.636a48.36 48.36 0 01-1.857.534c-.594.17-1.196.314-1.815.458-.611.145-1.23.272-1.858.391-.602.118-1.204.22-1.824.313a56.512 56.512 0 01-3.672.475c-.611.06-1.23.111-1.858.153-.602.043-1.212.076-1.823.093-.611.026-1.23.043-1.858.051-.297.009-.602.009-.907.009-.306 0-.611 0-.908-.009a62.41 62.41 0 01-1.857-.051 42.082 42.082 0 01-1.824-.093 61.697 61.697 0 01-1.849-.153 56.718 56.718 0 01-1.823-.203 41.702 41.702 0 01-1.858-.272 41.45 41.45 0 01-1.823-.313 54.236 54.236 0 01-1.849-.382c-.62-.144-1.23-.297-1.824-.467a48.36 48.36 0 01-1.857-.534 42.983 42.983 0 01-1.824-.636 34.064 34.064 0 01-1.849-.738 33.724 33.724 0 01-1.823-.882 21.66 21.66 0 01-1.858-1.094 16.223 16.223 0 01-1.815-1.341c-.729-.627-1.357-1.28-1.857-1.95-.823-1.12-1.324-2.299-1.451-3.52h-.034v-8.668h3.681c5.098-4.105 14.962-6.888 26.302-6.888s21.212 2.783 26.301 6.888h3.681z"
                                ></path>
                                <path
                                    id="Vector_281"
                                    fill="url(#paint137_linear_4553_104946)"
                                    d="M192.473 194.503c16.559 0 29.983-5.933 29.983-13.251 0-7.319-13.424-13.252-29.983-13.252s-29.982 5.933-29.982 13.252c0 7.318 13.423 13.251 29.982 13.251z"
                                ></path>
                                <path
                                    id="white_tick"
                                    fill="#fff"
                                    d="M167.714 180.149l24.004 10.366 17.996-18.915-3.881-1.496-15.676 15.282-18.809-7.588-3.634 2.351z"
                                ></path>
                            </g>
                        </g>
                    </g>
                </g>
                <defs>
                    <linearGradient
                        id="paint0_linear_4553_104946"
                        x1="50.844"
                        x2="196.881"
                        y1="170.692"
                        y2="170.692"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#0C5C93"></stop>
                        <stop offset="1" stopColor="#0C5C93"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_4553_104946"
                        x1="25.861"
                        x2="146.349"
                        y1="113.275"
                        y2="113.275"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#fff"></stop>
                        <stop offset="1" stopColor="#DDDCDB"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear_4553_104946"
                        x1="15.591"
                        x2="29.558"
                        y1="35.93"
                        y2="133.623"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FCFCFC"></stop>
                        <stop offset="1" stopColor="#8DA9B5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint3_linear_4553_104946"
                        x1="32.537"
                        x2="115.162"
                        y1="43.252"
                        y2="43.252"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#38ACA8"></stop>
                        <stop offset="1" stopColor="#5F637A"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint4_linear_4553_104946"
                        x1="110.497"
                        x2="115.162"
                        y1="26.503"
                        y2="26.503"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#535366"></stop>
                        <stop offset="1" stopColor="#26263D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint5_linear_4553_104946"
                        x1="122.471"
                        x2="38.732"
                        y1="34.074"
                        y2="74.538"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#38ACA8"></stop>
                        <stop offset="1" stopColor="#5F637A"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint6_linear_4553_104946"
                        x1="33.914"
                        x2="75.904"
                        y1="80.963"
                        y2="73.588"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#38ACA8"></stop>
                        <stop offset="1" stopColor="#5F637A"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint7_linear_4553_104946"
                        x1="72.508"
                        x2="76.712"
                        y1="72.717"
                        y2="72.717"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#535366"></stop>
                        <stop offset="1" stopColor="#26263D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint8_linear_4553_104946"
                        x1="133.34"
                        x2="49.602"
                        y1="56.57"
                        y2="97.034"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#38ACA8"></stop>
                        <stop offset="1" stopColor="#5F637A"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint9_linear_4553_104946"
                        x1="41.913"
                        x2="44.025"
                        y1="84.835"
                        y2="84.835"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FCFCFC"></stop>
                        <stop offset="1" stopColor="#8DA9B5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint10_linear_4553_104946"
                        x1="49.944"
                        x2="52.056"
                        y1="81.608"
                        y2="81.608"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FCFCFC"></stop>
                        <stop offset="1" stopColor="#8DA9B5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint11_linear_4553_104946"
                        x1="57.974"
                        x2="60.086"
                        y1="78.381"
                        y2="78.381"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FCFCFC"></stop>
                        <stop offset="1" stopColor="#8DA9B5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint12_linear_4553_104946"
                        x1="66.005"
                        x2="68.117"
                        y1="75.155"
                        y2="75.155"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FCFCFC"></stop>
                        <stop offset="1" stopColor="#8DA9B5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint13_linear_4553_104946"
                        x1="118.071"
                        x2="103.344"
                        y1="114.71"
                        y2="114.71"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#fff"></stop>
                        <stop offset="1" stopColor="#DDDCDB"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint14_linear_4553_104946"
                        x1="118.764"
                        x2="104.037"
                        y1="115.037"
                        y2="115.037"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#535366"></stop>
                        <stop offset="1" stopColor="#26263D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint15_linear_4553_104946"
                        x1="111.839"
                        x2="111.377"
                        y1="103.422"
                        y2="118.55"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#38ACA8"></stop>
                        <stop offset="1" stopColor="#5F637A"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint16_linear_4553_104946"
                        x1="149.259"
                        x2="135.736"
                        y1="217.822"
                        y2="224.075"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#535366"></stop>
                        <stop offset="1" stopColor="#26263D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint17_linear_4553_104946"
                        x1="152.655"
                        x2="120.918"
                        y1="187.531"
                        y2="187.531"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#535366"></stop>
                        <stop offset="1" stopColor="#26263D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint18_linear_4553_104946"
                        x1="139.86"
                        x2="125.721"
                        y1="101.785"
                        y2="108.323"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD9D9"></stop>
                        <stop offset="1" stopColor="#EFB4B4"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint19_linear_4553_104946"
                        x1="143.599"
                        x2="127.828"
                        y1="96.129"
                        y2="103.422"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#535366"></stop>
                        <stop offset="1" stopColor="#26263D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint20_linear_4553_104946"
                        x1="101.03"
                        x2="92.352"
                        y1="111.264"
                        y2="115.278"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD9D9"></stop>
                        <stop offset="1" stopColor="#EFB4B4"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint21_linear_4553_104946"
                        x1="135.791"
                        x2="119.608"
                        y1="221.202"
                        y2="228.686"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#535366"></stop>
                        <stop offset="1" stopColor="#26263D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint22_linear_4553_104946"
                        x1="121.296"
                        x2="114.44"
                        y1="115.389"
                        y2="115.389"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD9D9"></stop>
                        <stop offset="1" stopColor="#EFB4B4"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint23_linear_4553_104946"
                        x1="114.146"
                        x2="115.615"
                        y1="105.051"
                        y2="108.685"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint24_linear_4553_104946"
                        x1="114.147"
                        x2="115.615"
                        y1="105.051"
                        y2="108.685"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint25_linear_4553_104946"
                        x1="114.147"
                        x2="115.615"
                        y1="105.052"
                        y2="108.686"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint26_linear_4553_104946"
                        x1="111.832"
                        x2="113.301"
                        y1="105.987"
                        y2="109.621"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint27_linear_4553_104946"
                        x1="111.832"
                        x2="113.301"
                        y1="105.987"
                        y2="109.621"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint28_linear_4553_104946"
                        x1="111.832"
                        x2="113.301"
                        y1="105.987"
                        y2="109.621"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint29_linear_4553_104946"
                        x1="111.832"
                        x2="113.3"
                        y1="105.987"
                        y2="109.621"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint30_linear_4553_104946"
                        x1="109.517"
                        x2="110.986"
                        y1="106.922"
                        y2="110.556"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint31_linear_4553_104946"
                        x1="109.518"
                        x2="110.986"
                        y1="106.922"
                        y2="110.556"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint32_linear_4553_104946"
                        x1="109.518"
                        x2="110.986"
                        y1="106.922"
                        y2="110.556"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint33_linear_4553_104946"
                        x1="109.518"
                        x2="110.986"
                        y1="106.922"
                        y2="110.556"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint34_linear_4553_104946"
                        x1="109.518"
                        x2="110.986"
                        y1="106.922"
                        y2="110.556"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint35_linear_4553_104946"
                        x1="107.204"
                        x2="108.672"
                        y1="107.857"
                        y2="111.491"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint36_linear_4553_104946"
                        x1="107.204"
                        x2="108.672"
                        y1="107.858"
                        y2="111.491"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint37_linear_4553_104946"
                        x1="107.203"
                        x2="108.671"
                        y1="107.858"
                        y2="111.491"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint38_linear_4553_104946"
                        x1="107.202"
                        x2="108.671"
                        y1="107.857"
                        y2="111.491"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint39_linear_4553_104946"
                        x1="107.203"
                        x2="108.671"
                        y1="107.857"
                        y2="111.491"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint40_linear_4553_104946"
                        x1="107.203"
                        x2="108.672"
                        y1="107.858"
                        y2="111.491"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint41_linear_4553_104946"
                        x1="113.921"
                        x2="110.702"
                        y1="118.699"
                        y2="118.699"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FF871A"></stop>
                        <stop offset="1" stopColor="#FFC900"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint42_linear_4553_104946"
                        x1="111.235"
                        x2="112.024"
                        y1="121.867"
                        y2="119.417"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint43_linear_4553_104946"
                        x1="111.177"
                        x2="115.676"
                        y1="115.885"
                        y2="118.123"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint44_linear_4553_104946"
                        x1="110.366"
                        x2="114.865"
                        y1="117.514"
                        y2="119.752"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint45_linear_4553_104946"
                        x1="110.698"
                        x2="115.197"
                        y1="116.847"
                        y2="119.085"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint46_linear_4553_104946"
                        x1="110.974"
                        x2="115.473"
                        y1="116.293"
                        y2="118.531"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint47_linear_4553_104946"
                        x1="109.981"
                        x2="114.48"
                        y1="118.288"
                        y2="120.526"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint48_linear_4553_104946"
                        x1="110.575"
                        x2="115.074"
                        y1="117.095"
                        y2="119.333"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint49_linear_4553_104946"
                        x1="107.919"
                        x2="112.469"
                        y1="122.231"
                        y2="124.332"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint50_linear_4553_104946"
                        x1="108.648"
                        x2="113.197"
                        y1="120.654"
                        y2="122.755"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint51_linear_4553_104946"
                        x1="108.347"
                        x2="112.896"
                        y1="121.306"
                        y2="123.408"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint52_linear_4553_104946"
                        x1="108.097"
                        x2="112.647"
                        y1="121.844"
                        y2="123.946"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint53_linear_4553_104946"
                        x1="109.003"
                        x2="113.552"
                        y1="119.887"
                        y2="121.988"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint54_linear_4553_104946"
                        x1="108.367"
                        x2="112.916"
                        y1="121.263"
                        y2="123.365"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint55_linear_4553_104946"
                        x1="113.921"
                        x2="110.822"
                        y1="118.699"
                        y2="118.699"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint56_linear_4553_104946"
                        x1="111.459"
                        x2="108.744"
                        y1="120.637"
                        y2="120.637"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FF871A"></stop>
                        <stop offset="1" stopColor="#FFC900"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint57_linear_4553_104946"
                        x1="114.774"
                        x2="110.168"
                        y1="118.394"
                        y2="118.394"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint58_linear_4553_104946"
                        x1="65.775"
                        x2="28.628"
                        y1="210.318"
                        y2="210.318"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9E6321"></stop>
                        <stop offset="0.265" stopColor="#FED39E"></stop>
                        <stop offset="0.394" stopColor="#F1B770"></stop>
                        <stop offset="1" stopColor="#E2973D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint59_linear_4553_104946"
                        x1="45.175"
                        x2="42.259"
                        y1="212.089"
                        y2="199.959"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E2973D"></stop>
                        <stop offset="0.136" stopColor="#E9A758"></stop>
                        <stop offset="0.443" stopColor="#FFD5A1"></stop>
                        <stop offset="0.75" stopColor="#EEB36C"></stop>
                        <stop offset="1" stopColor="#FFD6A9"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint60_linear_4553_104946"
                        x1="57.726"
                        x2="27.402"
                        y1="203.006"
                        y2="210.604"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9E6321"></stop>
                        <stop offset="0.265" stopColor="#FED39E"></stop>
                        <stop offset="1" stopColor="#E2973D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint61_linear_4553_104946"
                        x1="57.746"
                        x2="29.697"
                        y1="204.895"
                        y2="211.923"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E2973D"></stop>
                        <stop offset="0.136" stopColor="#E9A758"></stop>
                        <stop offset="0.443" stopColor="#FFD5A1"></stop>
                        <stop offset="0.75" stopColor="#EEB36C"></stop>
                        <stop offset="1" stopColor="#FFD6A9"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint62_linear_4553_104946"
                        x1="65.775"
                        x2="28.628"
                        y1="206.001"
                        y2="206.001"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9E6321"></stop>
                        <stop offset="0.265" stopColor="#FED39E"></stop>
                        <stop offset="0.394" stopColor="#F1B770"></stop>
                        <stop offset="1" stopColor="#E2973D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint63_linear_4553_104946"
                        x1="45.175"
                        x2="42.259"
                        y1="207.772"
                        y2="195.642"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E2973D"></stop>
                        <stop offset="0.136" stopColor="#E9A758"></stop>
                        <stop offset="0.443" stopColor="#FFD5A1"></stop>
                        <stop offset="0.75" stopColor="#EEB36C"></stop>
                        <stop offset="1" stopColor="#FFD6A9"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint64_linear_4553_104946"
                        x1="57.726"
                        x2="27.402"
                        y1="198.689"
                        y2="206.287"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9E6321"></stop>
                        <stop offset="0.265" stopColor="#FED39E"></stop>
                        <stop offset="1" stopColor="#E2973D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint65_linear_4553_104946"
                        x1="57.746"
                        x2="29.697"
                        y1="200.578"
                        y2="207.606"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E2973D"></stop>
                        <stop offset="0.136" stopColor="#E9A758"></stop>
                        <stop offset="0.443" stopColor="#FFD5A1"></stop>
                        <stop offset="0.75" stopColor="#EEB36C"></stop>
                        <stop offset="1" stopColor="#FFD6A9"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint66_linear_4553_104946"
                        x1="65.775"
                        x2="28.628"
                        y1="201.685"
                        y2="201.685"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9E6321"></stop>
                        <stop offset="0.265" stopColor="#FED39E"></stop>
                        <stop offset="0.394" stopColor="#F1B770"></stop>
                        <stop offset="1" stopColor="#E2973D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint67_linear_4553_104946"
                        x1="45.175"
                        x2="42.259"
                        y1="203.455"
                        y2="191.325"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E2973D"></stop>
                        <stop offset="0.136" stopColor="#E9A758"></stop>
                        <stop offset="0.443" stopColor="#FFD5A1"></stop>
                        <stop offset="0.75" stopColor="#EEB36C"></stop>
                        <stop offset="1" stopColor="#FFD6A9"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint68_linear_4553_104946"
                        x1="57.726"
                        x2="27.402"
                        y1="194.372"
                        y2="201.97"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9E6321"></stop>
                        <stop offset="0.265" stopColor="#FED39E"></stop>
                        <stop offset="1" stopColor="#E2973D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint69_linear_4553_104946"
                        x1="57.746"
                        x2="29.697"
                        y1="196.261"
                        y2="203.289"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E2973D"></stop>
                        <stop offset="0.136" stopColor="#E9A758"></stop>
                        <stop offset="0.443" stopColor="#FFD5A1"></stop>
                        <stop offset="0.75" stopColor="#EEB36C"></stop>
                        <stop offset="1" stopColor="#FFD6A9"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint70_linear_4553_104946"
                        x1="69.189"
                        x2="32.042"
                        y1="201.685"
                        y2="201.685"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9E6321"></stop>
                        <stop offset="0.265" stopColor="#FED39E"></stop>
                        <stop offset="0.394" stopColor="#F1B770"></stop>
                        <stop offset="1" stopColor="#E2973D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint71_linear_4553_104946"
                        x1="48.591"
                        x2="45.674"
                        y1="203.455"
                        y2="191.326"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E2973D"></stop>
                        <stop offset="0.136" stopColor="#E9A758"></stop>
                        <stop offset="0.443" stopColor="#FFD5A1"></stop>
                        <stop offset="0.75" stopColor="#EEB36C"></stop>
                        <stop offset="1" stopColor="#FFD6A9"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint72_linear_4553_104946"
                        x1="61.142"
                        x2="30.818"
                        y1="194.372"
                        y2="201.97"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9E6321"></stop>
                        <stop offset="0.265" stopColor="#FED39E"></stop>
                        <stop offset="1" stopColor="#E2973D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint73_linear_4553_104946"
                        x1="61.162"
                        x2="33.114"
                        y1="196.261"
                        y2="203.289"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E2973D"></stop>
                        <stop offset="0.136" stopColor="#E9A758"></stop>
                        <stop offset="0.443" stopColor="#FFD5A1"></stop>
                        <stop offset="0.75" stopColor="#EEB36C"></stop>
                        <stop offset="1" stopColor="#FFD6A9"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint74_linear_4553_104946"
                        x1="65.775"
                        x2="28.628"
                        y1="197.368"
                        y2="197.368"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9E6321"></stop>
                        <stop offset="0.265" stopColor="#FED39E"></stop>
                        <stop offset="0.394" stopColor="#F1B770"></stop>
                        <stop offset="1" stopColor="#E2973D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint75_linear_4553_104946"
                        x1="45.175"
                        x2="42.259"
                        y1="199.138"
                        y2="187.009"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E2973D"></stop>
                        <stop offset="0.136" stopColor="#E9A758"></stop>
                        <stop offset="0.443" stopColor="#FFD5A1"></stop>
                        <stop offset="0.75" stopColor="#EEB36C"></stop>
                        <stop offset="1" stopColor="#FFD6A9"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint76_linear_4553_104946"
                        x1="57.726"
                        x2="27.402"
                        y1="190.055"
                        y2="197.653"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9E6321"></stop>
                        <stop offset="0.265" stopColor="#FED39E"></stop>
                        <stop offset="1" stopColor="#E2973D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint77_linear_4553_104946"
                        x1="57.746"
                        x2="29.697"
                        y1="191.944"
                        y2="198.972"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E2973D"></stop>
                        <stop offset="0.136" stopColor="#E9A758"></stop>
                        <stop offset="0.443" stopColor="#FFD5A1"></stop>
                        <stop offset="0.75" stopColor="#EEB36C"></stop>
                        <stop offset="1" stopColor="#FFD6A9"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint78_linear_4553_104946"
                        x1="65.775"
                        x2="28.628"
                        y1="193.051"
                        y2="193.051"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9E6321"></stop>
                        <stop offset="0.265" stopColor="#FED39E"></stop>
                        <stop offset="0.394" stopColor="#F1B770"></stop>
                        <stop offset="1" stopColor="#E2973D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint79_linear_4553_104946"
                        x1="45.175"
                        x2="42.259"
                        y1="194.821"
                        y2="182.692"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E2973D"></stop>
                        <stop offset="0.136" stopColor="#E9A758"></stop>
                        <stop offset="0.443" stopColor="#FFD5A1"></stop>
                        <stop offset="0.75" stopColor="#EEB36C"></stop>
                        <stop offset="1" stopColor="#FFD6A9"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint80_linear_4553_104946"
                        x1="57.726"
                        x2="27.402"
                        y1="185.738"
                        y2="193.336"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9E6321"></stop>
                        <stop offset="0.265" stopColor="#FED39E"></stop>
                        <stop offset="1" stopColor="#E2973D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint81_linear_4553_104946"
                        x1="57.746"
                        x2="29.697"
                        y1="187.628"
                        y2="194.655"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E2973D"></stop>
                        <stop offset="0.136" stopColor="#E9A758"></stop>
                        <stop offset="0.443" stopColor="#FFD5A1"></stop>
                        <stop offset="0.75" stopColor="#EEB36C"></stop>
                        <stop offset="1" stopColor="#FFD6A9"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint82_linear_4553_104946"
                        x1="65.774"
                        x2="28.628"
                        y1="188.734"
                        y2="188.734"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9E6321"></stop>
                        <stop offset="0.265" stopColor="#FED39E"></stop>
                        <stop offset="0.394" stopColor="#F1B770"></stop>
                        <stop offset="1" stopColor="#E2973D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint83_linear_4553_104946"
                        x1="45.175"
                        x2="42.259"
                        y1="190.505"
                        y2="178.375"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E2973D"></stop>
                        <stop offset="0.136" stopColor="#E9A758"></stop>
                        <stop offset="0.443" stopColor="#FFD5A1"></stop>
                        <stop offset="0.75" stopColor="#EEB36C"></stop>
                        <stop offset="1" stopColor="#FFD6A9"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint84_linear_4553_104946"
                        x1="57.726"
                        x2="27.402"
                        y1="181.421"
                        y2="189.019"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9E6321"></stop>
                        <stop offset="0.265" stopColor="#FED39E"></stop>
                        <stop offset="1" stopColor="#E2973D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint85_linear_4553_104946"
                        x1="57.746"
                        x2="29.697"
                        y1="183.311"
                        y2="190.338"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E2973D"></stop>
                        <stop offset="0.136" stopColor="#E9A758"></stop>
                        <stop offset="0.443" stopColor="#FFD5A1"></stop>
                        <stop offset="0.75" stopColor="#EEB36C"></stop>
                        <stop offset="1" stopColor="#FFD6A9"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint86_linear_4553_104946"
                        x1="71.57"
                        x2="34.423"
                        y1="188.734"
                        y2="188.734"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9E6321"></stop>
                        <stop offset="0.265" stopColor="#FED39E"></stop>
                        <stop offset="0.394" stopColor="#F1B770"></stop>
                        <stop offset="1" stopColor="#E2973D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint87_linear_4553_104946"
                        x1="50.972"
                        x2="48.055"
                        y1="190.505"
                        y2="178.375"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E2973D"></stop>
                        <stop offset="0.136" stopColor="#E9A758"></stop>
                        <stop offset="0.443" stopColor="#FFD5A1"></stop>
                        <stop offset="0.75" stopColor="#EEB36C"></stop>
                        <stop offset="1" stopColor="#FFD6A9"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint88_linear_4553_104946"
                        x1="63.523"
                        x2="33.198"
                        y1="181.421"
                        y2="189.019"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9E6321"></stop>
                        <stop offset="0.265" stopColor="#FED39E"></stop>
                        <stop offset="1" stopColor="#E2973D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint89_linear_4553_104946"
                        x1="63.542"
                        x2="35.494"
                        y1="183.311"
                        y2="190.338"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E2973D"></stop>
                        <stop offset="0.136" stopColor="#E9A758"></stop>
                        <stop offset="0.443" stopColor="#FFD5A1"></stop>
                        <stop offset="0.75" stopColor="#EEB36C"></stop>
                        <stop offset="1" stopColor="#FFD6A9"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint90_linear_4553_104946"
                        x1="65.775"
                        x2="28.628"
                        y1="184.417"
                        y2="184.417"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9E6321"></stop>
                        <stop offset="0.265" stopColor="#FED39E"></stop>
                        <stop offset="0.394" stopColor="#F1B770"></stop>
                        <stop offset="1" stopColor="#E2973D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint91_linear_4553_104946"
                        x1="45.175"
                        x2="42.259"
                        y1="186.187"
                        y2="174.058"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E2973D"></stop>
                        <stop offset="0.136" stopColor="#E9A758"></stop>
                        <stop offset="0.443" stopColor="#FFD5A1"></stop>
                        <stop offset="0.75" stopColor="#EEB36C"></stop>
                        <stop offset="1" stopColor="#FFD6A9"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint92_linear_4553_104946"
                        x1="57.726"
                        x2="27.402"
                        y1="177.104"
                        y2="184.702"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9E6321"></stop>
                        <stop offset="0.265" stopColor="#FED39E"></stop>
                        <stop offset="1" stopColor="#E2973D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint93_linear_4553_104946"
                        x1="57.746"
                        x2="29.697"
                        y1="178.994"
                        y2="186.022"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E2973D"></stop>
                        <stop offset="0.136" stopColor="#E9A758"></stop>
                        <stop offset="0.443" stopColor="#FFD5A1"></stop>
                        <stop offset="0.75" stopColor="#EEB36C"></stop>
                        <stop offset="1" stopColor="#FFD6A9"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint94_linear_4553_104946"
                        x1="65.775"
                        x2="28.628"
                        y1="180.1"
                        y2="180.1"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9E6321"></stop>
                        <stop offset="0.265" stopColor="#FED39E"></stop>
                        <stop offset="0.394" stopColor="#F1B770"></stop>
                        <stop offset="1" stopColor="#E2973D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint95_linear_4553_104946"
                        x1="45.175"
                        x2="42.259"
                        y1="181.871"
                        y2="169.742"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E2973D"></stop>
                        <stop offset="0.136" stopColor="#E9A758"></stop>
                        <stop offset="0.443" stopColor="#FFD5A1"></stop>
                        <stop offset="0.75" stopColor="#EEB36C"></stop>
                        <stop offset="1" stopColor="#FFD6A9"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint96_linear_4553_104946"
                        x1="57.726"
                        x2="27.402"
                        y1="172.787"
                        y2="180.385"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9E6321"></stop>
                        <stop offset="0.265" stopColor="#FED39E"></stop>
                        <stop offset="1" stopColor="#E2973D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint97_linear_4553_104946"
                        x1="57.746"
                        x2="29.697"
                        y1="174.677"
                        y2="181.705"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E2973D"></stop>
                        <stop offset="0.136" stopColor="#E9A758"></stop>
                        <stop offset="0.443" stopColor="#FFD5A1"></stop>
                        <stop offset="0.75" stopColor="#EEB36C"></stop>
                        <stop offset="1" stopColor="#FFD6A9"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint98_linear_4553_104946"
                        x1="65.775"
                        x2="28.628"
                        y1="175.784"
                        y2="175.784"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9E6321"></stop>
                        <stop offset="0.265" stopColor="#FED39E"></stop>
                        <stop offset="0.394" stopColor="#F1B770"></stop>
                        <stop offset="1" stopColor="#E2973D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint99_linear_4553_104946"
                        x1="45.175"
                        x2="42.259"
                        y1="177.554"
                        y2="165.425"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E2973D"></stop>
                        <stop offset="0.136" stopColor="#E9A758"></stop>
                        <stop offset="0.443" stopColor="#FFD5A1"></stop>
                        <stop offset="0.75" stopColor="#EEB36C"></stop>
                        <stop offset="1" stopColor="#FFD6A9"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint100_linear_4553_104946"
                        x1="57.726"
                        x2="27.402"
                        y1="168.471"
                        y2="176.069"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9E6321"></stop>
                        <stop offset="0.265" stopColor="#FED39E"></stop>
                        <stop offset="1" stopColor="#E2973D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint101_linear_4553_104946"
                        x1="57.746"
                        x2="29.697"
                        y1="170.36"
                        y2="177.388"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E2973D"></stop>
                        <stop offset="0.136" stopColor="#E9A758"></stop>
                        <stop offset="0.443" stopColor="#FFD5A1"></stop>
                        <stop offset="0.75" stopColor="#EEB36C"></stop>
                        <stop offset="1" stopColor="#FFD6A9"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint102_linear_4553_104946"
                        x1="58.979"
                        x2="21.832"
                        y1="175.784"
                        y2="175.784"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9E6321"></stop>
                        <stop offset="0.265" stopColor="#FED39E"></stop>
                        <stop offset="0.394" stopColor="#F1B770"></stop>
                        <stop offset="1" stopColor="#E2973D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint103_linear_4553_104946"
                        x1="38.38"
                        x2="35.463"
                        y1="177.554"
                        y2="165.425"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E2973D"></stop>
                        <stop offset="0.136" stopColor="#E9A758"></stop>
                        <stop offset="0.443" stopColor="#FFD5A1"></stop>
                        <stop offset="0.75" stopColor="#EEB36C"></stop>
                        <stop offset="1" stopColor="#FFD6A9"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint104_linear_4553_104946"
                        x1="50.931"
                        x2="20.607"
                        y1="168.471"
                        y2="176.069"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9E6321"></stop>
                        <stop offset="0.265" stopColor="#FED39E"></stop>
                        <stop offset="1" stopColor="#E2973D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint105_linear_4553_104946"
                        x1="50.951"
                        x2="22.902"
                        y1="170.36"
                        y2="177.388"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E2973D"></stop>
                        <stop offset="0.136" stopColor="#E9A758"></stop>
                        <stop offset="0.443" stopColor="#FFD5A1"></stop>
                        <stop offset="0.75" stopColor="#EEB36C"></stop>
                        <stop offset="1" stopColor="#FFD6A9"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint106_linear_4553_104946"
                        x1="65.775"
                        x2="28.628"
                        y1="171.467"
                        y2="171.467"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9E6321"></stop>
                        <stop offset="0.265" stopColor="#FED39E"></stop>
                        <stop offset="0.394" stopColor="#F1B770"></stop>
                        <stop offset="1" stopColor="#E2973D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint107_linear_4553_104946"
                        x1="45.175"
                        x2="42.259"
                        y1="173.237"
                        y2="161.108"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E2973D"></stop>
                        <stop offset="0.136" stopColor="#E9A758"></stop>
                        <stop offset="0.443" stopColor="#FFD5A1"></stop>
                        <stop offset="0.75" stopColor="#EEB36C"></stop>
                        <stop offset="1" stopColor="#FFD6A9"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint108_linear_4553_104946"
                        x1="57.726"
                        x2="27.402"
                        y1="164.154"
                        y2="171.752"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9E6321"></stop>
                        <stop offset="0.265" stopColor="#FED39E"></stop>
                        <stop offset="1" stopColor="#E2973D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint109_linear_4553_104946"
                        x1="57.746"
                        x2="29.697"
                        y1="166.043"
                        y2="173.071"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E2973D"></stop>
                        <stop offset="0.136" stopColor="#E9A758"></stop>
                        <stop offset="0.443" stopColor="#FFD5A1"></stop>
                        <stop offset="0.75" stopColor="#EEB36C"></stop>
                        <stop offset="1" stopColor="#FFD6A9"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint110_linear_4553_104946"
                        x1="65.774"
                        x2="28.628"
                        y1="167.15"
                        y2="167.15"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9E6321"></stop>
                        <stop offset="0.265" stopColor="#FED39E"></stop>
                        <stop offset="0.394" stopColor="#F1B770"></stop>
                        <stop offset="1" stopColor="#E2973D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint111_linear_4553_104946"
                        x1="45.175"
                        x2="42.259"
                        y1="168.92"
                        y2="156.79"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E2973D"></stop>
                        <stop offset="0.136" stopColor="#E9A758"></stop>
                        <stop offset="0.443" stopColor="#FFD5A1"></stop>
                        <stop offset="0.75" stopColor="#EEB36C"></stop>
                        <stop offset="1" stopColor="#FFD6A9"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint112_linear_4553_104946"
                        x1="57.726"
                        x2="27.402"
                        y1="159.837"
                        y2="167.435"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9E6321"></stop>
                        <stop offset="0.265" stopColor="#FED39E"></stop>
                        <stop offset="1" stopColor="#E2973D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint113_linear_4553_104946"
                        x1="57.746"
                        x2="29.697"
                        y1="161.726"
                        y2="168.754"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E2973D"></stop>
                        <stop offset="0.136" stopColor="#E9A758"></stop>
                        <stop offset="0.443" stopColor="#FFD5A1"></stop>
                        <stop offset="0.75" stopColor="#EEB36C"></stop>
                        <stop offset="1" stopColor="#FFD6A9"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint114_linear_4553_104946"
                        x1="65.774"
                        x2="28.628"
                        y1="162.833"
                        y2="162.833"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9E6321"></stop>
                        <stop offset="0.265" stopColor="#FED39E"></stop>
                        <stop offset="0.394" stopColor="#F1B770"></stop>
                        <stop offset="1" stopColor="#E2973D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint115_linear_4553_104946"
                        x1="45.175"
                        x2="42.259"
                        y1="164.603"
                        y2="152.473"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E2973D"></stop>
                        <stop offset="0.136" stopColor="#E9A758"></stop>
                        <stop offset="0.443" stopColor="#FFD5A1"></stop>
                        <stop offset="0.75" stopColor="#EEB36C"></stop>
                        <stop offset="1" stopColor="#FFD6A9"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint116_linear_4553_104946"
                        x1="57.726"
                        x2="27.402"
                        y1="155.52"
                        y2="163.118"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9E6321"></stop>
                        <stop offset="0.265" stopColor="#FED39E"></stop>
                        <stop offset="1" stopColor="#E2973D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint117_linear_4553_104946"
                        x1="57.746"
                        x2="29.697"
                        y1="157.409"
                        y2="164.437"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E2973D"></stop>
                        <stop offset="0.136" stopColor="#E9A758"></stop>
                        <stop offset="0.443" stopColor="#FFD5A1"></stop>
                        <stop offset="0.75" stopColor="#EEB36C"></stop>
                        <stop offset="1" stopColor="#FFD6A9"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint118_linear_4553_104946"
                        x1="2.091"
                        x2="29.141"
                        y1="201.386"
                        y2="201.386"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9E6321"></stop>
                        <stop offset="0.265" stopColor="#FED39E"></stop>
                        <stop offset="1" stopColor="#E2973D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint119_linear_4553_104946"
                        x1="0"
                        x2="27.05"
                        y1="200.8"
                        y2="200.8"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E2973D"></stop>
                        <stop offset="0.136" stopColor="#E9A758"></stop>
                        <stop offset="0.443" stopColor="#FFD5A1"></stop>
                        <stop offset="0.75" stopColor="#EEB36C"></stop>
                        <stop offset="1" stopColor="#FFD6A9"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint120_linear_4553_104946"
                        x1="7.998"
                        x2="14.51"
                        y1="208.57"
                        y2="189.453"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9E6321"></stop>
                        <stop offset="0.265" stopColor="#FED39E"></stop>
                        <stop offset="1" stopColor="#E2973D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint121_linear_4553_104946"
                        x1="15.083"
                        x2="14.108"
                        y1="214.034"
                        y2="180.752"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E2973D"></stop>
                        <stop offset="0.136" stopColor="#E9A758"></stop>
                        <stop offset="0.443" stopColor="#FFD5A1"></stop>
                        <stop offset="0.75" stopColor="#EEB36C"></stop>
                        <stop offset="1" stopColor="#FFD6A9"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint122_linear_4553_104946"
                        x1="188.782"
                        x2="214.807"
                        y1="258.459"
                        y2="258.459"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FF871A"></stop>
                        <stop offset="1" stopColor="#FFC900"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint123_linear_4553_104946"
                        x1="133.97"
                        x2="213.626"
                        y1="242.523"
                        y2="242.523"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint124_linear_4553_104946"
                        x1="222.835"
                        x2="168.544"
                        y1="247.781"
                        y2="252.701"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FF871A"></stop>
                        <stop offset="1" stopColor="#FFC900"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint125_linear_4553_104946"
                        x1="19.319"
                        x2="77.517"
                        y1="263.408"
                        y2="194.364"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint126_linear_4553_104946"
                        x1="80.295"
                        x2="94.365"
                        y1="209.643"
                        y2="242.52"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#30D6B6"></stop>
                        <stop offset="1" stopColor="#567384"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint127_linear_4553_104946"
                        x1="65.24"
                        x2="77.22"
                        y1="208.019"
                        y2="233.403"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#3BB9A7"></stop>
                        <stop offset="1" stopColor="#26263D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint128_linear_4553_104946"
                        x1="20.206"
                        x2="78.405"
                        y1="264.156"
                        y2="195.113"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint129_linear_4553_104946"
                        x1="60.965"
                        x2="75.036"
                        y1="217.916"
                        y2="250.792"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#30D6B6"></stop>
                        <stop offset="1" stopColor="#567384"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint130_linear_4553_104946"
                        x1="46.144"
                        x2="58.124"
                        y1="217.031"
                        y2="242.415"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#3BB9A7"></stop>
                        <stop offset="1" stopColor="#26263D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint131_linear_4553_104946"
                        x1="17.509"
                        x2="75.708"
                        y1="261.883"
                        y2="192.839"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint132_linear_4553_104946"
                        x1="43.047"
                        x2="57.117"
                        y1="225.584"
                        y2="258.461"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#30D6B6"></stop>
                        <stop offset="1" stopColor="#567384"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint133_linear_4553_104946"
                        x1="28.543"
                        x2="40.523"
                        y1="225.339"
                        y2="250.723"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#3BB9A7"></stop>
                        <stop offset="1" stopColor="#26263D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint134_linear_4553_104946"
                        x1="17.499"
                        x2="75.698"
                        y1="261.874"
                        y2="192.831"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint135_linear_4553_104946"
                        x1="24.071"
                        x2="38.141"
                        y1="233.705"
                        y2="266.582"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#30D6B6"></stop>
                        <stop offset="1" stopColor="#567384"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint136_linear_4553_104946"
                        x1="9.821"
                        x2="21.801"
                        y1="234.174"
                        y2="259.558"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#3BB9A7"></stop>
                        <stop offset="1" stopColor="#26263D"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint137_linear_4553_104946"
                        x1="162.491"
                        x2="222.456"
                        y1="181.252"
                        y2="181.252"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82D7DB"></stop>
                        <stop offset="1" stopColor="#5DC9A5"></stop>
                    </linearGradient>
                </defs>
            </svg>
        </div>
    )
}

