import styles from './Update.module.css';
import team_1 from '../../../../images/vectors/about/hero/team_1.jpg'
import team_2 from '../../../../images/vectors/about/hero/team_2.jpg'
import team_3 from '../../../../images/vectors/about/hero/team_3.jpg'
import reactangle from '../../../../images/vectors/about/hero/circles.svg'
import circle from '../../../../images/vectors/about/hero/circle.svg'


const UpdateAbout = () => {
    return (
        <>
            <div className={styles.shape1}></div>
            <div className={styles.shape2}></div>
            <div className={styles.container}>
                <div className={styles.left}>
                    <div className={styles.heading}>
                        Nxcar at a glance
                    </div>
                    <div className={styles.sub_heading}>
                        At Nxcar, we specialize in tailored financial solutions for used car buyers and dealers, aiming to make the buying experience as transparent and enjoyable as purchasing a new car. We offer online loans for both direct and dealer purchases, along with services like inspections, extended warranties, insurance, and RC transfers.
                    </div>
                </div>
                <div className={styles.right}>
                    <img src={team_1} alt="" />
                    <img src={team_2} alt="" />

                    <div className={styles.right_bottom}>
                        <img src={team_3} alt="" />
                    </div>
                </div>
                <div className={styles.rectangle}>
                    <img src={reactangle} alt="" />
                    <img src={circle} alt="" />
                </div>
            </div>
        </>
    )
}

export default UpdateAbout