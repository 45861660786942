import React from 'react'
import styles from './Acko.module.css';
import low_premium from '../../../../../images/vectors/homescreen/ACKO/low_premium.svg';
import claim from '../../../../../images/vectors/homescreen/ACKO/claim.svg';
import renew from '../../../../../images/vectors/homescreen/ACKO/renew.svg';
import stress from '../../../../../images/vectors/homescreen/ACKO/stress.svg';
import time from '../../../../../images/vectors/homescreen/ACKO/time.svg';
import total from '../../../../../images/vectors/homescreen/ACKO/total.svg';
import zero from '../../../../../images/vectors/homescreen/ACKO/zero.svg';

export default function Acko() {

  const arr = [
    {
      img: low_premium,
      name: 'Incredibly Low Premiums',
    },
    {
      img: zero,
      name: 'Zero Paperwork',
    },
    {
      img: stress,
      name: 'Stress-free Claims',
    },
    {
      img: claim,
      name: 'Instant Claim Settlement',
    },
  ]
  const arr2 = [
    {
      img: total,
      name: 'Total Convenience',
    },
    {
      img: time,
      name: '24x7 Support',
    },
    {
      img: renew,
      name: 'Easy Insurance Renewal',
    },

  ]

  return (
    <>
      <div className={styles.container}>
        <div className={styles.main_heading}>Key Features of ACKO’s Online Car Insurance</div>
        <div className={styles.main_container}>
          {
            arr.map((item) => (
              <div className={styles.card} key={item.name}>
                <div className={styles.image}>
                  <img src={item.img} alt="" />
                </div>
                <div className={styles.name}>{item.name}</div>
              </div>
            ))
          }
        </div>
        <div className={styles.main_container}>
          {
            arr2.map((item) => (
              <div className={styles.card} key={item.name}>
                <div className={styles.image}>
                  <img src={item.img} alt="" />
                </div>
                <div className={styles.name}>{item.name}</div>
              </div>
            ))
          }
        </div>
      </div>
    </>
  )
}
