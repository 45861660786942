import React, { useState, useEffect } from 'react';
import styles from './Life.module.css';
import team1 from '../../../images/vectors/about/life/team.jpg';
import team2 from '../../../images/vectors/about/life/team2.jpg';
import team3 from '../../../images/vectors/about/life/team3.jpg';
import team4 from '../../../images/vectors/about/life/team4.jpg';


import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';



const Life = () => {
    return (
        <>
            <div className={styles.container}>
                <div className={styles.heading}>
                    Life at Nxcar
                </div>

                <div className={styles.image}>
                    <Carousel
                        autoPlay={true}
                        infiniteLoop={true}
                        swipeable={true}
                        emulateTouch={true}
                        showArrows={false}
                        showThumbs={false}
                        showStatus={false}
                        showIndicators={false}
                    >
                        <img src={team1} alt="" />
                        <img src={team2} alt="" />
                        <img src={team3} alt="" />
                        <img src={team4} alt="" />
                    </Carousel>
                </div>
            </div>
        </>
    )
}

export default Life