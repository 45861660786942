import React from 'react'
import styles from './Landing.module.css';
import facebook from '../../../images/logos/facebook.svg'
import instagram from '../../../images/logos/instagram.svg'
import whatsapp from '../../../images/logos/whatsapp.svg'
import mail from '../../../images/logos/mail.svg'
import phone from '../../../images/logos/green_phone.svg'
import { Link } from 'react-router-dom';
import { scrollToTop } from '../../scrollToTop/ScrollToTopButton';

export default function LandingFooter() {
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank');
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  const handleEmailClick = () => {
    openInNewTab('mailto:Contact@nxfin.in');
  };

  const handleWhatsappClick = () => {
    openInNewTab('https://wa.me/+919355924132');
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.tagline1}>
          For any enquires please reach out us at
        </div>
        {/* <div className={styles.tagline}>
          <a href="https://www.facebook.com/nxfin" target='_blank'>
            <img src={facebook} alt="" />
          </a>
          <a href="https://www.instagram.com/nxcarindia/" target='_blank'>
            <img src={instagram} alt="" />
          </a>
          <a onClick={handleWhatsappClick}>
            <img src={whatsapp} alt="" />
          </a>
          <a onClick={handleEmailClick}>
            <img src={mail} alt="" />
          </a>
        </div> */}
        <div className={styles.tagline3}>
          <img src={phone} alt="" />
          <p>+91 9355924132</p>
          <p>+91 9355924133</p>
        </div>
        {/* <Link to='/'
          onClick={scrollToTop}>
          <div className={styles.tagline2}>
            You can also visit us at nxcar.in
          </div>
        </Link> */}
      </div>
    </>
  )
}
