import React, { useEffect, useState } from 'react'
import styles from './Dashboard.module.css'
import { Link } from 'react-router-dom'
import Table from '../../../../reusable/table/Table'
import Main from '../../../../Layout/Dealer/Main/Main'
import { jwtDecode } from 'jwt-decode'
import axios from 'axios'

const Dashboard = () => {
    const [dealerData, setDealerData] = useState('');
    const [loanData, setLoanData] = useState({});
    const token = localStorage.getItem('token');
    const dealer_id = localStorage.getItem('my-dealer-id')

    const dealer_url = 'https://dev.nxfin.in/api/uat.app'


    const decoded = jwtDecode(token);
    const dealerId = decoded.uid || decoded.id;

    const fetchData = async () => {
        try {
            const response = await axios.get(`/partner_dashboard?dealer_id=${dealer_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${token}`,
                },
            });
            if (response.data) {
                setLoanData(response.data);
                // console.log('data', response.data);
            } else {
                console.error('API request failed:', response);
            }
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    };

    const fetchData1 = async () => {
        try {
            if (token) {
                const response = await axios.get(`${dealer_url}/profile?dealer_id=${dealerId}`, {
                    headers: {
                        Authorization: token,
                    },
                });
                // console.log(`my dealer profile --> `, response.data.name);
                const fetchedId = response.data.id;

                if (fetchedId) {
                    setDealerData(response.data);
                }
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        fetchData();
        fetchData1();
    }, []);


    const columns = [
        { label: 'No. of Application', field: 'no_of_application' },
        { label: 'No. of Approved', field: 'no_of_approved' },
        { label: 'No. of Inprocess', field: 'no_of_inprocess' },
        { label: 'No. of Rejected', field: 'no_of_rejected' },
    ];
    const data = [loanData]

    return (
        <>
            <Main>
                <div className={styles.container}>
                    <div className={styles.right}>
                        {/* WELCOME TEXT */}
                        <div className={styles.welcome_text}>
                            <div className={styles.welcome_text1}
                                style={{ color: 'var(--teal)' }}
                            >
                                Hello {dealerData.name}!
                            </div>
                            <div className={styles.welcome_text2}>
                                Product Team Nxcar
                            </div>
                        </div>

                        {/* DASHBOARD */}
                        <div className={styles.table}>
                            <Table data={data} columns={columns} />
                        </div>
                    </div>
                </div>
            </Main>
        </>
    )
}

export default Dashboard