import React, { useRef, useEffect } from 'react';
import styles from './Our_vision.module.css';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const Our_vision = () => {
    const useArrayRef = () => {
        const lettersRef = useRef([]);
        lettersRef.current = [];
        return [lettersRef, (ref) => ref && lettersRef.current.push(ref)]
    }

    const [lettersRef, setLettersRef] = useArrayRef();

    const triggerRef = useRef(null);
    gsap.registerPlugin(ScrollTrigger)

    useEffect(() => {
        const reveal = gsap.to(
            lettersRef.current,
            {
                scrollTrigger: {
                    trigger: triggerRef.current,
                    scrub: true,
                    start: "top 65%",
                    end: "bottom 60%",
                    // markers: true,
                },
                color: `var(--text_grey)`,
                // duration: 1,
                stagger: 1,
            }
        )
        return () => {
            reveal.kill();
        }
    }, [])
    const text = "Nxcar vision is to transform the pre-owned car business by enabling used car buyers to purchase a pre-owned vehicle confidently from anywhere with seamless access to loans, warranty, insurance and ownership transfer services."

    return (
        <div className={styles.container}>
            <div className={styles.reveal}>
                <div ref={triggerRef}>
                    {text.split(" ").map((letter, index) => (
                        <span
                            className={styles.reveal_text}
                            ref={setLettersRef}
                            key={index}
                        >
                            {letter} &nbsp;
                        </span>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Our_vision;
