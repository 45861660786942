import React, { useState } from 'react';
import styles from './Animation.module.css';

import img1 from '../../../images/vectors/used_car/benefits/online.svg'
import img2 from '../../../images/vectors/used_car/benefits/transparent.svg'
import img3 from '../../../images/vectors/used_car/benefits/flexible.svg'
import img4 from '../../../images/vectors/used_car/benefits/payment.svg'
import img5 from '../../../images/vectors/used_car/benefits/interest.svg'
import img6 from '../../../images/vectors/used_car/benefits/options.svg'
import img7 from '../../../images/vectors/used_car/benefits/quick_approval.svg'
import img8 from '../../../images/vectors/used_car/benefits/service.svg'

import Fade from 'react-reveal/Fade';


const Benefits = () => {

    return (
        <div className={styles.dark}>
            <div className={styles.container}>
                <div className={styles.heading}>
                    Features
                </div>

                <div className={styles.main_container}>
                    {/* 1ST */}
                    <Fade left>
                        <div className={styles.col}>
                            <div className={styles.row}>
                                <div className={styles.image}>
                                    <img
                                        src={img1}
                                        alt=""
                                    />
                                </div>
                                <div className={styles.heading_1}>
                                    Online Application and Management
                                </div>
                                <div
                                    className={styles.text1}>
                                    Now apply for a used car loan from the comfort of your home and ride your car with ease.
                                </div>
                            </div>
                            <div
                                className={styles.row2}
                            >
                                <div className={styles.image2}>
                                    <img
                                        src={img2}
                                        alt=""
                                    />
                                </div>
                                <div className={styles.heading_1}>
                                    Transparent Fees and Terms
                                </div>
                                <div className={styles.text1}>
                                    No hidden surprises. We believe in transparent fees and terms. Nxcar does not charge any fees or commission from our customers for used car loans.
                                </div>
                            </div>
                        </div>
                    </Fade>
                    {/* 2ND */}
                    <Fade right>
                        <div className={styles.col}>
                            <div className={styles.row}>
                                <div
                                    className={styles.image}
                                >
                                    <img
                                        src={img3}
                                        alt=""
                                    />
                                </div>
                                <div className={styles.heading_1}>
                                    Flexible Loan Terms
                                </div>
                                <div
                                    className={styles.text1}>
                                    Tailor your loans to fit your lifestyle. Choose from a range of flexible loan terms that suit your budget and credit profile
                                </div>
                            </div>
                            <div
                                className={styles.row2}
                            >
                                <div className={styles.image2}>
                                    <img
                                        src={img4}
                                        alt=""
                                    />
                                </div>
                                <div className={styles.heading_1}>
                                    Low or No Down Payment Options
                                </div>
                                <div className={styles.text1}>
                                    Get your dream car easily with low or no down payment options.
                                </div>
                            </div>
                        </div>
                    </Fade>
                    {/* 3RD */}
                    <Fade left>
                        <div className={styles.col}>
                            <div className={styles.row}>
                                <div
                                    className={styles.image}
                                >
                                    <img
                                        src={img5}
                                        alt=""
                                    />
                                </div>
                                <div className={styles.heading_1}>
                                    Competitive Interest Rate
                                </div>
                                <div className={styles.text1}>
                                    Pick the lending partner that aligns best with your needs and get the best interest rates.
                                </div>
                            </div>
                            <div
                                className={styles.row2} >
                                <div className={styles.image2}>
                                    <img
                                        src={img6}
                                        alt=""
                                    />
                                </div>
                                <div className={styles.heading_1}>
                                    Pre-Approval Options
                                </div>
                                <div className={styles.text1}>
                                    Pick your perfect used car with our pre-approved loans. Your financing is now sorted.
                                </div>
                            </div>
                        </div>
                    </Fade>
                    {/* 4TH */}
                    <Fade right
                    >
                        <div className={styles.col}>
                            <div className={styles.row}>
                                <div
                                    className={styles.image}
                                >
                                    <img
                                        src={img7}
                                        alt=""
                                    />
                                </div>
                                <div className={styles.heading_1}>
                                    Quick Approval Process
                                </div>
                                <div className={styles.text1}>
                                    Get on the road sooner with our fast approvals. Experience swift and efficient loans.
                                </div>
                            </div>
                            <div
                                className={styles.row2}
                            >
                                <div className={styles.image2}>
                                    <img
                                        src={img8}
                                        alt=""
                                    />
                                </div>
                                <div className={styles.heading_1}>
                                    Excellent Customer  Service
                                </div>
                                <div className={styles.text1}>
                                    Your satisfaction is our priority. Our dedicated customer service team is ready to assist you ensuring a smooth and enjoyable experience.
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>
        </div>
    )
}
export default Benefits;
