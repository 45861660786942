import { useEffect, useState } from 'react'
import { Box, Modal, useMediaQuery } from '@mui/material'
import video from '../../../images/videos/ListingHomeScreen.mp4';
import styles from './ListingHomeScreen.module.css';
import { CiSearch } from "react-icons/ci";
import { Link } from 'react-router-dom';
import UpdatedServiceForm from '../UpdatedServiceForm/buyForm/UpdatedServiceForm';

const styleMUIModal = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  margin: 'auto',
  width: '500px',
  height: 'fitContent',
  bgcolor: "white",
  border: "none",
  borderRadius: 2,
  boxShadow: 0,
  outline: "none",
  p: 0,
};
const styleMUIModalMobile = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: '86%',
  height: 'fitContent',
  bgcolor: "white",
  border: "none",
  borderRadius: 2,
  boxShadow: 0,
  outline: "none",
  p: 0,
}

const styleMUIModalForm = {
  position: "absolute",
  top: "0%",
  right: "0%",
  width: '40%',
  height: '100%',
  bgcolor: "white",
  border: "0px solid #000",
  boxShadow: 24,
  outline: "none",
  overflowY: "auto",
  p: 0,
};
const styleMUIModalMobileForm = {
  width: "100%",
  height: "100vh",
  top: "0%",
  right: "0%",
  height: '100vh',
  bgcolor: "white",
  border: "0px solid #000",
  boxShadow: 24,
  outline: "none",
  overflowY: "auto",
  p: 0,
}


export default function ListingHomeScreen() {
  const isMobile = useMediaQuery('(max-width: 940px)');
  const [open, setOpen] = useState(false);

  const [isLoanFormVisible, setIsLoanFormVisible] = useState(false);
  const popup_login = localStorage.getItem('Authorization');
  const popupClosed = localStorage.getItem('popupClosed');

  const toggleLoanForm = () => {
    setIsLoanFormVisible(!isLoanFormVisible);
  };

  useEffect(() => {
    if (popup_login?.length > 100 && popup_login !== undefined) {
      setIsLoanFormVisible(false);
    }
  }, [popup_login]);

  const currentURL = window.location.pathname;

  useEffect(() => {
    if (currentURL === '/consumer-form' || currentURL === '/consumer-login') {
      setIsLoanFormVisible(false);
    }
    else if (
      (currentURL !== '/consumer-form' || currentURL !== '/consumer-login') &&
      (popup_login == null || popup_login == undefined) &&
      popupClosed != 'true'
    ) {
      const timer = setTimeout(() => {
        setIsLoanFormVisible(true);
      }, 10000);
      return () => clearTimeout(timer);
    }
    else {
      setIsLoanFormVisible(false);
    }
  }, [currentURL]);

  useEffect(() => {
    if (currentURL === '/consumer-form' || currentURL === '/consumer-login') {
      setIsLoanFormVisible(false);
    }
  }, [currentURL, popupClosed])

  const top = () => {
    setOpen(true);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    localStorage.setItem('used_car_loan', true)
    localStorage.setItem('extended_warranty', true)
    localStorage.setItem('document_verification', true)
    localStorage.setItem('rc_transfer', true)
    localStorage.setItem('inspection', true)
    localStorage.setItem('insurance_transfer', true)
  }
  const handleClose = () => {
    setOpen(false);
  }

  return (
    <>
      <div className={styles.main_container}>

        <div className={styles.hero_video}>
          <video
            muted
            loop
            preload="auto"
            controlsList="nodownload"
            src={video}
            playsInline
            autoPlay={true}
            controls={false}
            className={styles.hero_video}
          >
          </video>
        </div>

        <div className={styles.container}>
          <div className={styles.heading}>
            Ab Hogi Apni Car
          </div>
          <div className={styles.sub_heading}>
            Now buy a pre-owned vehicle directly from a
            friend, neighbour, classified listing, or a dealer.
            Let Nxcar handle the loan and all transfer
            processes hassle-free.
          </div>
          <div className={styles.sub_heading_mobile}>
            Now buy a pre-owned vehicle directly from a <br />
            friend, neighbour, classified listing, or a dealer. <br />
            Let Nxcar handle the loan and all transfer <br />
            processes hassle-free.
          </div>
          <div className={styles.search}>
            <div className={styles.flex}>
              {/* <div className={styles.search_container}>
              <input type="text" placeholder="Search Here" />
              <span className={styles.search_icon}>
                <CiSearch />
              </span>
            </div> */}

              <div className={styles.button} onClick={top}>
                Apply for Used Car Loan
              </div>

            </div>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={isMobile ? styleMUIModalMobileForm : styleMUIModalForm}>
          <UpdatedServiceForm handleCloseBuy={handleClose} />
        </Box>
      </Modal>
    </>
  )
}
