import styles from './Docs.module.css';
import form from '../../../images/vectors/buy-sell/doc.svg';
import download from '../../../images/vectors/buy-sell/download_icon.svg';
import form26 from '../../../images/forms/buy-sell-form/FORM-26.pdf';
import form28 from '../../../images/forms/buy-sell-form/FORM-28.pdf';
import form29 from '../../../images/forms/buy-sell-form/FORM-29.pdf';
import form30 from '../../../images/forms/buy-sell-form/FORM-30.pdf';
import form34 from '../../../images/forms/buy-sell-form/FORM-34.pdf';
import form35 from '../../../images/forms/buy-sell-form/FORM-35.pdf';
import { Slide } from 'react-awesome-reveal';
import { Zoom } from 'react-reveal';
import WholeDownload from './animation/WholeDownload';

export default function DownloadDocs() {
    const arr = [
        {
            heading: 'Form 26',
            text: 'For issue of duplicate certificate of registration.',
            form_link: form26,
            form_name: 'Form_26',
        },
        {
            heading: 'Form 28',
            text: 'For grant of no objection certificate. (not required in Same RTO) with chassis print.',
            form_link: form28,
            form_name: 'Form_28',
        },
        {
            heading: 'Form 29',
            text: 'For notice of transfer of ownership of a motor vehicle.',
            form_link: form29,
            form_name: 'Form_29',
        },
        {
            heading: 'Form 30',
            text: 'For intimation and transfer of ownership of a motor vehicle.',
            form_link: form30,
            form_name: 'Form_30',
        },
        {
            heading: 'Form 34',
            text: 'For making entry of an agreement of hire-purchase/lease/hypothecation subsequent to registration.',
            form_link: form34,
            form_name: 'Form_34',
        },
        {
            heading: 'Form 35',
            text: 'For notice of termination of an agreement hire-purchase/lease/hypothecation.',
            form_link: form35,
            form_name: 'Form_35',
        },
    ]

    return (
        <>
            <div className={styles.container}>
                <div className={styles.heading}>
                    Download Important Documents
                </div>
                <div className={styles.main_container}>
                    <div className={styles.left}>
                        {
                            arr.map((item, id) => (
                                <Slide
                                    triggerOnce={true}
                                    direction='up'
                                >
                                    <a
                                        href={item.form_link}
                                        download={item.form_name}
                                        key={id}
                                    >
                                        <div className={styles.form}>
                                            <div className={styles.icon1}>
                                                <img src={form} alt="nxcar-form-downloads-according-to-it" />
                                            </div>
                                            <div className={styles.content_container}>
                                                <div className={styles.content_heading}>{item.heading}</div>
                                                <div className={styles.content_text}>{item.text}</div>
                                            </div>
                                            <div className={styles.icon2}>
                                                <img src={download} alt="nxcar-form-downloads-according-to-it" />
                                            </div>
                                        </div>
                                    </a>
                                </Slide>
                            ))
                        }
                    </div>

                    <>
                        <div className={styles.right}>
                            <WholeDownload />
                        </div>
                    </>
                </div>
            </div>
        </>
    )
}
