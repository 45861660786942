import React from 'react'
import styles from './Insurance.module.css'
import Header from '../../../Header/Header'
import Footer from '../../../Footer/Footer'
import ins from '../../../../images/vectors/homescreen/weofferServices/Insurance1.svg'
import InsuranceForm from './InsuranceForm'
import wave1 from '../../../../images/vectors/homescreen/weofferServices/wave.svg'
import Acko from '../similarCompo/acko/Acko'
import ProductPolicy from '../similarCompo/productPolicy/ProductPolicy'

export default function InsuranceCheck() {
  return (
    <>
      <div className={styles.header_container}>
        <Header />
        <div className={styles.white_container}>
          <div className={styles.container}>
            <div className={styles.left}>
              <InsuranceForm />
            </div>
            <div className={styles.right}>
              <img src={ins} alt="" />
            </div>
          </div>
          <div className={styles.wave}><img src={wave1} alt="" /></div>
          <Acko />
          <ProductPolicy />
        </div>
      </div>
      <div className={styles.footer}>
        <Footer />
      </div>
    </>
  )
}
