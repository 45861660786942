import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './SideNavUnique.css';
import styles from './SideNav.module.css'
import logo from '../../../images/logos/logo.svg';
import Hamburger from 'hamburger-react';
import { IoMdClose } from "react-icons/io";



// logout modal
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import door from '../../../images/logos/logout_door.svg'


const SideNav = () => {
    const [openLogoutModal, setOpenLogoutModal] = useState(false);
    const handleOpen = () => {
        setOpenLogoutModal(true);
    }
    const handleClose = () => setOpenLogoutModal(false);

    const [open, setOpen] = useState(false);
    const toggleMobileMenu = () => {
        setOpen(!open);
    };

    const handleLogout = () => {
        localStorage.clear();
    }


    return (
        <>
            {/* web view */}
            <div className="left">
                <div className="logo">
                    <NavLink to="/">
                        <img src={logo} alt="" />
                    </NavLink>
                </div>

                {/* LINKS */}
                <div className="links">
                    <NavLink
                        className="nav-bar-link"
                        to='/consumer-dashboard'
                    >
                        Dashboard
                    </NavLink>
                    <NavLink
                        className="nav-bar-link"
                        to='/consumer-history'
                    >
                        Loan History
                    </NavLink>
                    <NavLink
                        className="nav-bar-link"
                        to='/consumer-loan-form'
                    >
                        Loan Apply
                    </NavLink>
                    <NavLink
                        className="nav-bar-link"
                        to='/consumer-profile'
                    >
                        Profile
                    </NavLink>
                    <div
                        className="nav-bar-link"
                        style={{ position: 'absolute', bottom: "5%" }}
                        onClick={handleOpen}
                    >
                        Log Out
                    </div>
                </div>
            </div>

            {/* mobile view */}
            <div className={styles.left_mobile}>
                <div className={styles.top}>
                    <div className={styles.left}>
                        <Link to='/'>
                            <img src={logo} alt="" />
                        </Link>
                    </div>
                    <button
                        onClick={toggleMobileMenu}
                    >
                        <div className={styles.right_mobile}>
                            <Hamburger
                                toggled={open}
                                toggle={setOpen}
                            />
                        </div>
                    </button>
                </div>

                {
                    open &&
                    <Modal
                        open={open}
                        onClose={toggleMobileMenu}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box className={styles.modalContent} >
                            <div className={styles.bottom} onClick={toggleMobileMenu}>
                                <div className="links">
                                    <div className={styles.flex}>
                                        <NavLink
                                            className="nav-bar-link"
                                            to='/consumer-dashboard'
                                        >
                                            Dashboard
                                        </NavLink>
                                        <NavLink
                                            className="nav-bar-link1"
                                            to='/consumer-dashboard'
                                        >
                                            <IoMdClose />
                                        </NavLink>
                                    </div>
                                    <NavLink
                                        className="nav-bar-link"
                                        to='/consumer-history'
                                    >
                                        Loan History
                                    </NavLink>
                                    <NavLink
                                        className="nav-bar-link"
                                        to='/consumer-loan-form'
                                    >
                                        Loan Apply
                                    </NavLink>
                                    <NavLink
                                        className="nav-bar-link"
                                        to='/consumer-profile'
                                    >
                                        Profile
                                    </NavLink>
                                    <NavLink
                                        className="nav-bar-link"
                                        style={{ color: "black" }}
                                        onClick={handleOpen}
                                    >
                                        Logout
                                    </NavLink>
                                </div>
                            </div>
                        </Box>
                    </Modal>
                }
            </div>


            {/* LOGOUT MODAL */}
            <Modal
                open={openLogoutModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={styles.logoutModal}>
                    <div className={styles.image}>
                        <img src={door} alt="" />
                    </div>
                    <span>Are You Sure ?</span>

                    <div className={styles.button_container}>
                        <Link to='/'>
                            <div
                                className={styles.yes_button}
                                onClick={handleLogout}
                            >
                                Yes
                            </div>
                        </Link>
                        <div className={styles.no_button}
                            onClick={handleClose}
                        >
                            No
                        </div>
                    </div>
                </Box>
            </Modal>
            {/* LOGOUT MODAL */}
        </>
    );
};

export default SideNav;
