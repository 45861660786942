import React, { useState, useEffect } from 'react';
import styles from './Partner.module.css';
import axios from 'axios';
import { IoClose } from "react-icons/io5";
import { toast } from 'react-toastify';
import OtpInput from 'react-otp-input';
import Swal from 'sweetalert2';
import { jwtDecode } from "jwt-decode";
import loader from '../../../images/loading/loader.gif'

const Partner = ({ onClose }) => {
    const [step, setStep] = useState(1);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [isFocused, setIsFocused] = useState(false);
    const [seconds, setSeconds] = useState(120);
    const [isTimeExpired, setIsTimeExpired] = useState(false);
    const [otp, setOtp] = useState('');
    // const [otp, setOtp] = useState(new Array(4).fill(""));
    const [isOtpValid, setIsOtpValid] = useState(true);
    const [otpErrorMessage, setOtpErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const token = localStorage.getItem('token');
    const [isValidToken, setIsValidToken] = useState(false);

    const dealer_url = 'https://dev.nxfin.in/api/uat.app/'

    useEffect(() => {
        let interval

        if (step == 2) {
            interval = setInterval(() => {
                setSeconds((prevSeconds) => {
                    if (prevSeconds === 1) {
                        setIsTimeExpired(true);
                        clearInterval(interval);
                        return 0;
                    }
                    return prevSeconds - 1;
                });
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [step]);


    // THE FORMATTED DATE FOR THE DATE AND TIME
    const formattedTime = () => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        return `${hours < 10 ? '0' : ''}${hours} : ${minutes < 10 ? '0' : ''}${minutes} : 
        ${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}s`;
    };

    // THIS 3 FUNCTIONS ARE JUST FOR UI PURPOSE 
    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value);
        setIsValid(true);
    };

    const handleInputFocus = () => {
        setIsFocused(true);
    };

    const handleInputBlur = () => {
        setIsFocused(false);
    };

    // APIS HERE ----------------------------------------------------------
    const loginAPI = async () => {
        try {
            const response = await axios.post(`/signup`, {
                mobile: phoneNumber,
                signature: "Team Nxcar"
            });
            if (response.statusText === "OK") {
                // console.log('hi login API');
                setStep(2);
            } else {
                // console.log('hi failed API');
                setIsValid(false);
            }
        } catch (error) {
            console.error('Error during login API call:', error);
        }
    };
    const resendOTP = async () => {
        try {
            const response = await axios.post(`/signup`, {
                mobile: phoneNumber,
                signature: "Team Nxcar"
            });
            if (response.statusText === "OK") {
                // console.log(response, 'resend OTP works');
                toast.dismiss();
                toast.success('OTP sent');
                setStep(2);
            } else {
                toast.dismiss();
                toast.error('error OTP sent');
                // console.log('hi failed OTP API');
                setIsValid(false);
            }
        } catch (error) {
            console.error('Error during login API call:', error);
        }
    };

    // LOGIN API USING HERE
    const handleSubmit = async (e) => {
        e.preventDefault();
        const indianPhoneNumberRegex = /^[6-9]\d{9}$/;
        if (indianPhoneNumberRegex.test(phoneNumber)) {
            setLoading(true);
            try {
                await loginAPI();
                setLoading(false);
                setStep(2);
            } catch (error) {
                console.error('Error during login:', error);
                setLoading(false);
            }
        } else {
            setIsValid(false);
        }
    };

    // OTP VERIFICATION API HERE
    const otpVerificationAPI = async () => {
        try {
            const response = await axios.post(`/otpverify`, {
                mobile: phoneNumber,
                otp: otp
            });

            // console.log("128", response)
            localStorage.setItem('token', response.data.access_token);
            if (response.data.new_user === false) {
                const dealerId = response.data.dealer_id;
                await validToken(dealerId);

            } else {
                // console.log('hi 200 status wrong');
                setIsOtpValid(false);
                Swal.fire({
                    title: "You Are Not Regular User",
                    text: "No Data Found",
                    icon: "error"
                });
            }
        } catch (error) {
            setIsOtpValid(false);
            // console.log('hi 500 status');
            setOtp('');
        }
    };

    const validToken = async (dealerId) => {
        const token = localStorage.getItem('token');
        console.log('daler_id', dealerId)
        try {
            // const response = await axios.get(`${dealer_url}/user?dealer_id=${dealerId}`, {
            const response = await axios.get(`/user?dealer_id=${dealerId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${token}`,
                },
            });
            // console.log('main response ->', response);
            if (response.data.length === 0) {
                setIsValidToken(false);
                Swal.fire({
                    title: "No Data Found",
                    text: "No Data Found",
                    icon: "error"
                });
            } else {
                if (response.data.status === 'active') {
                    localStorage.setItem("token", token);
                    // console.log('localstorage', token)
                    setIsValidToken(true);
                    window.location.href = '/dealer-dashboard';
                }
                else if (!token) {
                    Swal.fire({
                        title: "No Token Found",
                        text: "Check with Nxcar Team",
                        icon: "error"
                    });
                }
                else {
                    setIsValidToken(false);
                    Swal.fire({
                        title: "You Are Not An Active Dealer",
                        text: "Check with Nxcar Team",
                        icon: "error"
                    });
                }
            }
        } catch (error) {
            alert(error.message);
            Swal.fire({
                title: "Token Is Not Valid!",
                text: "Something went wrong",
                icon: "error"
            });
            setIsValidToken(false);
        }
    };


    // OTP VERIFICATION API
    // const handleOTPChange = (element, index) => {
    //     if (isNaN(element.value)) return false;

    //     setOtp(otp);

    //     // Focus next input
    //     if (element.nextSibling) {
    //         element.nextSibling.focus();
    //     }
    // };
    // const handleOTPChange = (element, index) => {
    //     if (isNaN(element.value)) return false;

    //     setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    //     // Focus next input
    //     if (element.nextSibling) {
    //         element.nextSibling.focus();
    //     }
    // };
    // const handleOTPChange = (otp) => {
    //     setOtp(otp);
    // };


    const handleOTPVerification = async (e) => {
        e.preventDefault();
        await otpVerificationAPI();
    };

    const handleOTPChange = (e) => {
        const newValue = e.target.value;
        setOtp(newValue);
    }



    const renderStep = () => {
        switch (step) {

            // FOR MOBILE NUMBER LOGIN 
            case 1:
                return (
                    <>
                        <div className={styles.heading_section}>
                            <button
                                onClick={onClose}
                                className={styles.mui_close_button}
                            >
                                <IoClose />
                            </button>
                            <div className={styles.heading}>
                                <span>Welcome back!</span>
                            </div>
                            <div className={styles.sub_heading}>
                                Enter Your Mobile Number
                            </div>
                        </div>

                        <form onSubmit={handleSubmit}>
                            {/* <div
                                className={`${styles.country_code} ${!isValid ? styles.inputWithError : ''
                                    } ${isFocused ? styles.inputFocused : ''}`}
                            > */}
                            {/* <div className={styles.country_code}> */}
                            <div className={styles.MUI_input}>
                                <div className={styles.phoneNumberDisplay}>
                                    +91
                                </div>
                                <input
                                    type="tel"
                                    required
                                    maxLength={10}
                                    value={phoneNumber}
                                    onChange={handlePhoneNumberChange}
                                    onFocus={handleInputFocus}
                                    onBlur={handleInputBlur}
                                />
                                <div className={styles.float_label}>
                                    {isFocused ? 'Enter Mobile Number' : '+91 Enter Mobile Number'}
                                </div>
                            </div>
                            {/* </div> */}
                            {
                                !isValid && (
                                    <div className={styles.error_message}>
                                        Please Fill Valid Details
                                    </div>
                                )
                            }
                            <button
                                type="submit"
                                style={{ marginTop: "40px" }}
                                className={styles.continue_button}
                            >
                                {
                                    loading ?
                                        <div className={styles.loading}>
                                            <img src={loader} alt="loader" />
                                        </div>
                                        : 'Send OTP'
                                }
                            </button>
                        </form>
                    </>
                );

            // FOR OTP VERIFICATION
            case 2:
                return (
                    <>
                        <div className={styles.heading_section}>
                            <div
                                onClick={onClose}
                                className={styles.mui_close_button}
                            >
                                <IoClose />
                            </div>
                            <div className={styles.heading}>
                                <span>Welcome back!</span>
                            </div>
                            <div className={styles.sub_heading}>
                                Enter OTP to verify your number
                            </div>
                        </div>


                        <form onSubmit={handleOTPVerification}>
                            {/* <div className={styles.otp_fields}> */}
                            {/* <OtpInput
                                    className={`otp-field ${!isOtpValid ? styles.error_message_otp : ''}`}
                                    type="tel"
                                    name="otp"
                                    required
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={4}
                                    renderSeparator={<span></span>}
                                    renderInput={(props) => <input {...props} />}
                                /> */}
                            {/* </div> */}

                            <div className={styles.new_otp}>
                                <input
                                    placeholder='● ● ● ●'
                                    required
                                    maxLength={4}
                                    value={otp}
                                    onChange={handleOTPChange}
                                    type="tel" />
                            </div>

                            <div className={styles.otp_will_expire}>
                                {!isOtpValid && (
                                    <div className={styles.error_message_otp}>
                                        {/* {otpErrorMessage} */}
                                    </div>
                                )}
                                {!isTimeExpired ? (
                                    <>
                                        <button
                                            className={styles.resend_button}
                                            onClick={resendOTP}>
                                            Resend OTP
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        {/* <span style={{ color: "grey" }}>
                                            OTP is expired. <br />
                                            Please Re-Enter Your Mobile Number
                                        </span> */}
                                        <button
                                            className={styles.resend_button}
                                            onClick={resendOTP}>
                                            Resend OTP
                                        </button>
                                    </>
                                )}
                            </div>

                            <div className={styles.consent_box}>
                                <div className={styles.consent_left}>
                                    <input
                                        type="checkbox"
                                        required
                                    />
                                </div>
                                <div className={styles.consent_right}>
                                    I agree that I have read, understood, and accepted the <span style={{ color: 'teal', textDecoration: 'underline' }}> <a href="https://nxcar.in/privacy-policy" target='_blank'>Privacy Policy</a>, <a href="https://nxcar.in/terms-of-use" target="_blank">Terms of Use</a> and Consent Declaration</span> and hereby appoint Nxcar as my authorized representative to receive my credit information from any certified Credit Information Companies
                                </div>
                            </div>

                            {isOtpValid ? (

                                <button
                                    type="submit"
                                    className={styles.continue_button}
                                >
                                    Continue
                                </button>
                            ) : (
                                <>
                                    <button
                                        type="submit"
                                        className={styles.continue_button}
                                    >
                                        Continue
                                    </button>
                                    <span
                                        style={{ color: "red", margin: "10px 0" }}
                                    >
                                        Wrong OTP
                                    </span>
                                </>
                            )}
                        </form>
                    </>
                );


            default:
                return null;
        }
    };

    return (
        <div className={styles.container}>
            {renderStep()}
        </div>
    )
};

export default Partner;
