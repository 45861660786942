import styles from './BuyCarProcess.module.css';
import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import used_car_loan from '../../images/vectors/buy-sell/used_car_loan.svg'
import collection from '../../images/vectors/buy-sell/collection.svg'
import document from '../../images/vectors/buy-sell/document.svg'
import inspection from '../../images/vectors/buy-sell/inspection.svg'
import rc from '../../images/vectors/buy-sell/rc.svg'
import warranty from '../../images/vectors/buy-sell/warranty.svg'
import form26 from '../../images/forms/buy-sell-form/FORM-26.pdf'
import form29 from '../../images/forms/buy-sell-form/FORM-29.pdf'
import form30 from '../../images/forms/buy-sell-form/FORM-30.pdf'
import form35 from '../../images/forms/buy-sell-form/FORM-35.pdf'
import up_arrow from '../../images/vectors/homescreen/arrows/up_arrow.svg';
import down_arrow from '../../images/vectors/homescreen/arrows/down_arrow.svg';


const BuyCarProcess = () => {
    
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        const tl = gsap.timeline();

        // tl.from(`.${styles.box1}`, {
        //     x: 100,
        //     opacity: 0.5,
        //     duration: 1,
        //     scrollTrigger: {
        //         trigger: `.${styles.box1}`,
        //         start: "top 80%",
        //         end: "top 50%",
        //         scrub: true,
        //     },
        // });

        // tl.from(`.${styles.box2}`, {
        //     x: 100,
        //     opacity: 0.5,
        //     duration: 2,
        //     scrollTrigger: {
        //         trigger: `.${styles.box2}`,
        //         start: "top 75%",
        //         end: "top 45%",
        //         scrub: true,
        //     },
        // });

        // tl.from(`.${styles.box3}`, {
        //     x: 100,
        //     opacity: 0.5,
        //     duration: 3,
        //     scrollTrigger: {
        //         trigger: `.${styles.box3}`,
        //         start: "top 70%",
        //         end: "top 40%",
        //         scrub: true,
        //     },
        // });

        // tl.from(`.${styles.box4}`, {
        //     x: 100,
        //     opacity: 0.5,
        //     duration: 3,
        //     scrollTrigger: {
        //         trigger: `.${styles.box4}`,
        //         start: "top 65%",
        //         end: "top 35%",
        //         scrub: true,
        //     },
        // });

        // tl.from(`.${styles.box5}`, {
        //     x: 100,
        //     opacity: 0.5,
        //     duration: 3,
        //     scrollTrigger: {
        //         trigger: `.${styles.box5}`,
        //         start: "top 60%",
        //         end: "top 30%",
        //         scrub: true,
        //     },
        // });

        // tl.from(`.${styles.box6}`, {
        //     x: 100,
        //     opacity: 0.5,
        //     duration: 3,
        //     scrollTrigger: {
        //         trigger: `.${styles.box6}`,
        //         start: "top 55%",
        //         end: "top 25%",
        //         scrub: true,
        //     },
        // });

        // tl.from(`.${styles.arrow_down1}`, {
        //     opacity: 0,
        //     opacity: 0.5,
        //     duration: 1,
        //     scrollTrigger: {
        //         trigger: `.${styles.arrow_down1}`,
        //         start: "top 80%",
        //         end: "top 50%",
        //         scrub: true,
        //     },
        // });

        // tl.from(`.${styles.arrow_up1}`, {
        //     opacity: 0.5,
        //     duration: 1,
        //     scrollTrigger: {
        //         trigger: `.${styles.arrow_up1}`,
        //         start: "top 80%",
        //         end: "top 50%",
        //         scrub: true,
        //     },
        // });

        // tl.from(`.${styles.arrow_down2}`, {
        //     opacity: 0.5,
        //     duration: 1,
        //     scrollTrigger: {
        //         trigger: `.${styles.arrow_down2}`,
        //         start: "top 80%",
        //         end: "top 50%",
        //         scrub: true,
        //     },
        // });

        // tl.from(`.${styles.arrow_up2}`, {
        //     opacity: 0.5,
        //     duration: 1,
        //     scrollTrigger: {
        //         trigger: `.${styles.arrow_up2}`,
        //         start: "top 80%",
        //         end: "top 50%",
        //         scrub: true,
        //     },
        // });

        // tl.from(`.${styles.arrow_down3}`, {
        //     opacity: 0.5,
        //     duration: 1,
        //     scrollTrigger: {
        //         trigger: `.${styles.arrow_down3}`,
        //         start: "top 80%",
        //         end: "top 50%",
        //         scrub: true,
        //     },
        // });

        // WHITE TAPE
        tl.to(`.${styles.white_tape}`, {
            width: '0px',
            duration: 1,
            transformOrigin: 'right',
            scrollTrigger: {
                trigger: `.${styles.white_tape}`,
                start: 'top 70%',
                end: 'top 20%',
                scrub: 5,
            }
        });

    }, []);

    return (
        <>
            {/* WEB */}
            <div className={styles.container}>
                <div className={styles.heading}>
                    Process of buying used car
                </div>
                <div className={styles.main_container}>
                    <div className={styles.white_tape}></div>

                    <div className={styles.box1}>
                        <div className={styles.box_image}>
                            <img src={inspection} alt="" />
                        </div>
                        <div className={styles.box_content}>
                            <div className={styles.box_heading}>
                                Physical Inspection &
                                Price Validation
                            </div>
                            <ol>
                                <li>
                                    Check the car condition
                                </li>
                                <li>
                                    Check previous owner's maintenance reports
                                </li>
                                <li>
                                    Collect owner's manual and warranty information
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className={styles.arrow_down1}>
                        <img src={down_arrow} alt="" />
                    </div>
                    <div className={styles.box2}>
                        <div className={styles.box_image}>
                            <img src={document} alt="" />
                        </div>
                        <div className={styles.box_content}>
                            <div className={styles.box_heading}>
                                Document Verification
                            </div>
                            <ol>
                                <li>
                                    Check if the insurance is valid
                                </li>
                                <li>
                                    Collect the PUC certificate
                                </li>
                                <li>
                                    Check for any pending challans
                                </li>
                                <li>
                                    Verify if the RC is active or blacklisted or Buyer KYC available or not ?
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className={styles.arrow_up1}>
                        <img src={up_arrow} alt="" />
                    </div>
                    <div className={styles.box3}>
                        <div className={styles.box_image}>
                            <img src={collection} alt="" />
                        </div>
                        <div className={styles.box_content}>
                            <div className={styles.box_heading}>
                                Document Collection
                            </div>
                            <ol>
                                <li>
                                    Original RC & seller KYC required.
                                </li>
                                <li>
                                    If RC has hypothecation then
                                    <span className={styles.form_download}>
                                        <a href={form35} download="Form_35.pdf">
                                            {" "} Form 35{" "}
                                        </a>
                                    </span>
                                    & bank NOC required.
                                </li>
                                <li>
                                    Set of Transferor Transfer of Ownership(TTO) required.
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className={styles.arrow_down2}>
                        <img src={down_arrow} alt="" />
                    </div>
                    <div className={styles.box4}>
                        <div className={styles.box_image}>
                            <img src={used_car_loan} alt="" />
                        </div>
                        <div className={styles.box_content}>
                            <div className={styles.box_heading}>
                                Used Car Loan
                            </div>
                            <ol>
                                <li>
                                    Banks normally does not provide used car loans.
                                </li>
                                <li>
                                    Nxcar offers multiple options for loan.
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className={styles.arrow_up2}>
                        <img src={up_arrow} alt="" />
                    </div>
                    <div className={styles.box5}>
                        <div className={styles.box_image}>
                            <img src={warranty} alt="" />
                        </div>
                        <div className={styles.box_content}>
                            <div className={styles.box_heading}>
                                Insurance Transfer &
                                Extended Warranty
                            </div>
                            <ol>
                                <li>
                                    You will be required to reach out to a third party.
                                </li>
                                <li>
                                    Nxcar provides both extended warranty & insurance transfer.
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className={styles.arrow_down3}>
                        <img src={down_arrow} alt="" />
                    </div>
                    <div className={styles.box6}>
                        <div className={styles.box_image}>
                            <img src={rc} alt="" />
                        </div>
                        <div className={styles.box_content}>
                            <div className={styles.box_heading}>
                                RC Transfer
                            </div>
                            <ol>
                                <li>
                                    It's mandatory to fill out
                                    <span className={styles.form_download}>
                                        <a href={form29} download="Form_29.pdf">
                                            {" "} Form 29{" "}
                                        </a>
                                    </span>
                                    for ownership transfer and
                                    <span className={styles.form_download}>
                                        <a href={form30} download="Form_30.pdf">
                                            {" "} Form 30{" "}
                                        </a>
                                    </span>
                                    for intimation of ownership transfer.
                                </li>
                                <li>
                                    In case the RC is lost, it's mandatory to provide a duplicate certificate of registration. Fill out
                                    <span className={styles.form_download}>
                                        <a href={form26} download="Form_26.pdf">
                                            {" "} Form 26{" "}
                                        </a>
                                    </span>
                                    for the same.
                                </li>
                            </ol>
                        </div>
                    </div>

                </div>
            </div>


            {/* MOBILE */}
            <div className={styles.mobile_container}>
                <div className={styles.mobile_heading}>
                    Process of buying used car
                </div>

                <div className={styles.mobile_main_container}>
                    <div className={styles.mobile_arrow_1}>
                        <img src={up_arrow} alt="mobile-arrow-nxcar" />
                    </div>
                    <div className={styles.box1}>
                        <div className={styles.box_image}>
                            <img src={inspection} alt="" />
                        </div>
                        <div className={styles.box_content}>
                            <div className={styles.box_heading}>
                                Physical Inspection &
                                Price Validation
                            </div>
                        </div>
                    </div>
                    <div className={styles.mobile_arrow_2}>
                        <img src={up_arrow} alt="mobile-arrow-nxcar" />
                    </div>
                    <div className={styles.box2}>
                        <div className={styles.box_image}>
                            <img src={document} alt="" />
                        </div>
                        <div className={styles.box_content}>
                            <div className={styles.box_heading}>
                                Document Verification
                            </div>
                        </div>
                    </div>
                    <div className={styles.box3}>
                        <div className={styles.box_image}>
                            <img src={collection} alt="" />
                        </div>
                        <div className={styles.box_content}>
                            <div className={styles.box_heading}>
                                Document Collection
                            </div>
                        </div>
                    </div>
                    <div className={styles.mobile_arrow_3}>
                        <img src={up_arrow} alt="mobile-arrow-nxcar" />
                    </div>
                    <div className={styles.box4}>
                        <div className={styles.box_image}>
                            <img src={used_car_loan} alt="" />
                        </div>
                        <div className={styles.box_content}>
                            <div className={styles.box_heading}>
                                Used Car Loan
                            </div>
                        </div>
                    </div>
                    <div className={styles.mobile_arrow_4}>
                        <img src={up_arrow} alt="mobile-arrow-nxcar" />
                    </div>
                    <div className={styles.box5}>
                        <div className={styles.box_image}>
                            <img src={warranty} alt="" />
                        </div>
                        <div className={styles.box_content}>
                            <div className={styles.box_heading}>
                                Insurance Transfer &
                                Extended Warranty
                            </div>
                        </div>
                    </div>
                    <div className={styles.mobile_arrow_5}>
                        <img src={down_arrow} alt="" />
                    </div>
                    <div className={styles.box6}>
                        <div className={styles.box_image}>
                            <img src={rc} alt="" />
                        </div>
                        <div className={styles.box_content}>
                            <div className={styles.box_heading}>
                                RC Transfer
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BuyCarProcess