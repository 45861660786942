import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import DisableDevtool from 'disable-devtool';

if (
  window.location.origin == "https://dev.nxcar.in" ||
  window.location.origin == "http://localhost:3000" ||
  window.location.origin == "http://localhost:3001" ||
  window.location.origin == "http://dev.nxcar.in") {
  axios.defaults.baseURL = process.env.REACT_APP_BACKEND_DEV_URL;
}
else if (
  window.location.origin == "https://www.nxcar.in" ||
  window.location.origin == "http://www.nxcar.in" ||
  window.location.origin == "https://nxcar.in" ||
  window.location.origin == "http://nxcar.in"
) {
  axios.defaults.baseURL = process.env.REACT_APP_BACKEND_PROD_URL;
  // DisableDevtool();
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <BrowserRouter>
    <App />
    <ToastContainer
      position="top-center"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
  </BrowserRouter>

);

reportWebVitals();