import styles from './Testing.module.css';
import React from 'react';
import white_logo from '../images/logos/white_nxcar.svg';
import green_logo from '../images/logos/logo.svg';
import QRCode from 'qrcode.react';
import { useReactToPrint } from 'react-to-print';

const dealer_id = (text) => {
    const urlParts = text.split('?');
    if (urlParts.length > 1) {
        const params = new URLSearchParams(urlParts[1]);
        return params.get('dealerid');
    }
    return null;
}

const DealerCard = ({ dealer }) => (

    <div className={styles.one_page}>
        {/* Light Theme */}
        <div className={styles.flex}>

            <div className={styles.first_card}>
                <div className={styles.my_company_name}>
                    {/* Dealer ID: {dealer_id(dealer.content.login_id)} */}
                </div>
                <div className={styles.my_company_name}> {dealer.mobile}</div>
                <div className={styles.my_company_name}>{dealer.city}</div>
                <div className={styles.my_company_name}>{dealer.name}</div>
                <div className={styles.my_company_name}>{dealer.company_name}</div>
            </div>


            <div className={styles.left}>
                <div className={styles.qrcode}>
                    <div className={styles.white_bg}>
                        <QRCode
                            // size={320}
                            bgColor={"white"}
                            fgColor={"#000000"}
                            level={"L"}
                            renderAs={"svg"}
                            value={dealer.content.login_id}
                            style={{
                                width: "240px",
                                height: "240px",
                                // borderRadius: "1px",
                                // border: '10px solid white'
                            }}
                        />
                    </div>

                    <div className={styles.nxfin_logo} style={{
                        background: `white`
                    }}>
                        <img src={green_logo} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const DealersList = ({ dealers }) => (
    <div className={styles.container}>
        {dealers.map((dealer, index) => (
            <DealerCard key={index} dealer={dealer} />
        ))}
    </div>
);

const Testing = () => {
    const dealers = [
        {
            "company_name": "NXCAR ",
            "content": {
                "login_id": "https://www.nxcar.in"
            }
        },
        {
            "company_name": "AUTO ADS",
            "content": {
                "login_id": "https://www.nxcar.in/apni_car_apni_marzi?utm_source=QR_Code&utm_medium=Auto_Ad&utm_campaign=ACAM"
            }
        },
        {
            "company_name": "BUS ADS",
            "content": {
                "login_id": "https://www.nxcar.in/apni_car_apni_marzi?utm_source=QR_Code&utm_medium=Bus_Ad&utm_campaign=ACAM"
            }
        },
    ]


    const componentRef = React.useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <button className={styles.button} onClick={handlePrint}>Print All QR Codes</button>
            <div className={styles.container} ref={componentRef}>
                <DealersList dealers={dealers} />
            </div>
        </>
    );
};

export default Testing;