import React from 'react'
import styles from './ApniCarWhy.module.css';
import icon1 from '../../../../../images/vectors/landing/APNI_CAR/services.svg'
import icon2 from '../../../../../images/vectors/landing/APNI_CAR/any-car.svg'
import icon3 from '../../../../../images/vectors/landing/APNI_CAR/quick.svg'
import icon4 from '../../../../../images/vectors/landing/APNI_CAR/multiple.svg'

export default function ApniCarWhy() {
  return (
    <>
      <div className={styles.page}>
        <div className={styles.main_heading}>
          Why Choose Nxcar?
        </div>

        <div className={styles.container}>

          <div className={styles.box}>
            <div className={styles.icon}>
              <img src={icon1} alt="" />
            </div>
            <div className={styles.heading}>
              One Place, All Services
            </div>
            <div className={styles.content}>
              Used car loans, extended warranty, car inspection, RC Transfer
            </div>
          </div>

          <div className={styles.box}>
            <div className={styles.icon}>
              <img src={icon2} alt="" />
            </div>
            <div className={styles.heading}>
              Any Car, Any Seller
            </div>
            <div className={styles.content}>
              Buy used car from anywhere, get easy loans from Nxcar
            </div>
          </div>

          <div className={styles.box}>
            <div className={styles.icon}>
              <img src={icon3} alt="" />
            </div>
            <div className={styles.heading}>
              Quick and Transparent Process
            </div>
            <div className={styles.content}>
              Digital application for all services from the comfort of your home
            </div>
          </div>

          <div className={styles.box}>
            <div className={styles.icon}>
              <img src={icon4} alt="" />
            </div>
            <div className={styles.heading}>
              Multiple Offers to Choose
            </div>
            <div className={styles.content}>
              Get quotations from multiple banks for best used car loan offers
            </div>
          </div>

        </div>
      </div>
    </>
  )
}
