import Footer from '../../../../components/Footer/Footer';
import Header from '../../../../components/Header/Header';
import styles from '../Blogs.module.css';
import car from '../../../../images/vectors/blogs/car_with_shadow.svg'
import img4 from '../../../../images/vectors/homescreen/knoledge/img4.svg'
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import OtherBlogs from './OtherBlogs';

import car_with_path from '../../../../images/vectors/blogs/Car.svg'
import { Link } from 'react-router-dom';
import { scrollToTop } from '../../../../reusable/scrollToTop/ScrollToTopButton';

const Blog4 = () => {
    const carRef = useRef(null);
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        const car = carRef.current;
        gsap.to(car, {
            x: '-120%',
            y: '200%',
            scrollTrigger: {
                trigger: car,
                scroller: 'body',
                start: '100% 50%',
                end: '10% 90%',
                scrub: 2,
                pin: true,
            },
        });
    }, []);

    return (
        <>
            <div className={styles.curve_container}>
                <Header />
                <div className={styles.container}>
                    {/* <div className={styles.car_design} ref={carRef}>
                    <img src={car} alt="" />
                </div>
                <div className={styles.blog_design}></div> */}
                    <div className={styles.flex_box}>
                        <div className={styles.blog}>

                            <div className={styles.blog_container}>
                                <div className={styles.blog_heading_main}>
                                    When is the right time to buy a used car?
                                </div>

                                <div className={styles.mobile_image}>
                                    <img src={img4} alt="mobile_image_nxcar" />
                                </div>

                                <div className={styles.blog_content}>
                                    A car is like a reliable friend that makes your daily life easier. Once it's yours, it turns into your cosy spot, a place to have fun, and sometimes, it feels like home. Good news! You don’t need to buy a new car; a second-hand car makes more sense if you're concerned about your budget.
                                </div>
                                <div className={styles.blog_content}>
                                    You might think purchasing a two-wheeler is convenient, but it's not the safest mode of transportation, especially when you have a family. It might be surprising, but according to the Ministry of Road Transport and Highways, cars are much safer than two-wheelers. A report from 2022 revealed that 43% of road accidents involve two-wheelers, while only 11% involve cars. So, if you've been delaying getting a car because of financial concerns, consider a second-hand one. It's a smart choice!
                                </div>
                                <div className={styles.blog_content}>
                                    While budget constraints might be one reason to buy a used car, there are other scenarios where buying a used is a wise decision.
                                </div>
                            </div>
                            <div className={styles.blog_container2}>
                                <div className={styles.blogs_right}>
                                    <div className={styles.blog_heading}>
                                        College Commute
                                    </div>

                                    <div className={styles.blog_content}>
                                        If you are a student who constantly misses the metro or bus to college, having a car would mean freedom and ease in reaching your classes on time. But if your parents are hesitant to spend a fortune on a new car, a second-hand car might appeal to them as it will be a much cheaper option.
                                    </div>

                                    <div className={styles.blog_heading}>
                                        Client Meetings
                                    </div>
                                    <div className={styles.blog_content}>
                                        If you are a young professional, having a car can simplify your commute for day-to-day client meetings. Buying a used car in this case will be a wise option as it will be less expensive and will have lesser depreciation value. Also, if you're an entrepreneur or freelancer, you might need a car for business but want to save money. Buying a used car can make your commute easy and affordable.
                                    </div>
                                </div>
                            </div>
                            <div className={styles.blog_container}>
                                <div className={styles.blog_heading}>
                                    Family Expansion
                                </div>
                                <div className={styles.blog_content}>
                                    Families grow, and so do their needs with time. You might need a bigger car, like an SUV or minivan for that love of adventure or even picking up your children up from school. Used cars can give you more space for less money, which is great for trips or carrying sports equipment.
                                </div>

                                <div className={styles.blog_heading}>
                                    Post Retirement
                                </div>
                                <div className={styles.blog_content}>
                                    If you're retired and need a car for daily chores or simply enjoy long drives with your partner without overspending, the used car market offers many options that can make this possible and more sensible than purchasing a new car.
                                    There are many reasons why buying a used car is the right decision. When you decide to buy a used car, Nxcar can help make the process easy. We offer loans, assist with paperwork like RC transfer and insurance, check the car's condition for you, and even offer warranties. This means you can buy a used car without too much worry.
                                </div>
                            </div>
                            <div className={styles.blog_container2}>
                                <div className={styles.blogs_right}>
                                    <div className={styles.blog_content}>
                                        Buying a used car suits different people's needs and dreams. With Nxcar's help, you can find the right car effortlessly.
                                    </div>
                                    <div className={styles.blog_content}>
                                        If we have convinced you that buying a used car is a good decision and you have made up your mind, we suggest that you buy used cars not only based on your needs but also keeping in mind the following periods:
                                    </div>
                                    <div className={styles.green_line}>
                                        So, as you think about your life and what you need, maybe it's a good time to look at used cars.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.nxcar_handle}>
                            <div className={styles.box_heading}>
                                Buying a Used Car: Let Nxcar Handle It All
                            </div>
                            <div className={styles.car_image}>
                                <img src={car_with_path} alt="" />
                            </div>
                            <ul>
                                <li>Used Car Loan</li>
                                <li>Car Inspection</li>
                                <li>Document Verification</li>
                                <li>Insurance Transfer</li>
                                <li>RC Transfer</li>
                                <li>Extended Warranty</li>
                            </ul>
                            <Link to='/bundle' onClick={scrollToTop}>
                                <div className={styles.build_my_pack}>
                                    Build My Package Now
                                </div>
                            </Link>
                        </div>
                    </div>
                    <OtherBlogs />
                </div>
            </div>
            <div className={styles.footer}>
                <Footer />
            </div>
        </>
    )
}

export default Blog4