import React from 'react'
import styles from './Terms.module.css'
import Footer from '../../../components/Footer/Footer'
import Header from '../../../components/Header/Header'

const Terms_of_use = () => {
    return (
        <>
            <div className={styles.container1}>
                <Header />
                <div className={styles.dark}>
                    <div className={styles.container}>
                        <div className={styles.main_heading}>
                            TERMS OF USE
                        </div>
                        <div className={styles.sub_main_heading}>
                            The terms and conditions contained herein (“T&C”) contain the complete terms and conditions that apply to a User and govern the User’s access to and use of the Service (as defined below) . However, it is made clear that some of the Services may require the User to agree to additional terms and conditions. Unless otherwise provided, those additional terms shall be deemed to be incorporated into the T&C.
                        </div>


                        {/* 1 */}
                        <div className={styles.heading}>
                            1. ACCEPTANCE OF TERMS
                        </div>
                        <div className={styles.points_container}>
                            <div className={styles.points}>
                                (a) By (i) using the Platform or availing the Services provided through the Platform in any way; or (ii) browsing the Platform, the user is deemed to have read, understood and accepted the T&C as well as the privacy policy which is available on the Platform.
                            </div>
                            <div className={styles.points}>
                                (b)The user acknowledges that by accessing and using the Platform and availing any of the Services, it agrees to be bound by the T&Cs. The T&C establishes a relationship between the company and the user, whereby the user shall act in the capacity as may be mutually agreed between the user and company.
                            </div>
                            <div className={styles.points}>
                                (c)In the event the user is not agreeable to the T&C, it shall not access the Platform or avail the Services.
                            </div>
                            <div className={styles.points}>
                                (d) The T&C contained herein expressly supersede all prior agreements or arrangements between the company and the user.
                            </div>
                            <div className={styles.points}>
                                (e)The user understands that the access to the Platform and the offer of Services is conditional upon the user’s irrevocable consent and acceptance of all the terms, conditions and obligations contained in the T&C (as may be amended from time to time). For utilising the Services, the user agrees to enter into or execute any document, agreement or terms and conditions which is required by the company and agrees to abide by such document or agreement or terms and conditions while utilising the services on the Platform.
                            </div>
                            <div className={styles.points}>
                                (f) By accessing the Platform, the user hereby agrees to receive communication regarding registration on the Platform or Platform Services (including but not limited to any promotional, transactional messages) through Email and/or SMS and/ or any other electronic medium, third party channels (including WhatsApp) from the company.
                            </div>
                        </div>

                        {/* 2 */}
                        <div className={styles.heading}>
                            2. USER REPRESENTATIONS
                        </div>
                        <div className={styles.points_container}>
                            <div className={styles.points}>
                                (a)By agreeing to the T&C and by availing the Services, the user represents that it is lawfully able to enter into contracts (if an individual) or has the legal authority to enter into contracts on behalf of the body corporate/company/partnership.
                            </div>
                            <div className={styles.points}>
                                (b)The user acknowledges in relation to the Services that the company is merely providing a platform for facilitating the transactions between the user and the financial institutions(FIs) on the Platform and shall in no manner be liable or responsible for any such transactions or be construed as an agent of the user in relation to the Services.
                            </div>
                            <div className={styles.points}>
                                (c)The user acknowledges and agrees that any use of the Platform and the availing of the Services is at the sole risk of the user.
                            </div>
                            <div className={styles.points}>
                                (d)The user acknowledges and agrees that it is solely responsible for its data uploaded to or transmitted through the Platform, as well as, the consequences of uploading or transmitting its data onto or through the Platform.
                            </div>
                            <div className={styles.points}>
                                (e)The user acknowledges, represents, and warrants that with respect to any data posted on or transmitted through the Platform:
                            </div>
                            <div className={styles.sub_points}>
                                (i)It has all necessary licences, rights, consents, and permissions to upload, transmit, or publish such data and that it grants company, express, irrevocable licence and authorization to use such data for the Services; and
                            </div>
                            <div className={styles.sub_points}>
                                (ii)Any use by the company of the data uploaded, transmitted, or published by it on the Platform will not constitute infringement of any third party’s rights, over such data posted or transmitted through the Platform.
                            </div>
                            <div className={styles.points}>
                                (f)The user agrees and acknowledges that it is solely responsible to the company and to any counterparties for any breach of its obligations under the T&C and for the consequences of any such breach (including any loss or damage which company may suffer).
                            </div>
                        </div>

                        {/* 3 */}
                        <div className={styles.heading}>
                            3. USE OF PLATFORM AND SERVICES
                        </div>
                        <div className={styles.points_container}>
                            <div className={styles.points}>
                                (a)In order to access the Platform and to obtain the benefit of the Services, the user will have to register and create an account by providing accurate and complete information. The company is not responsible for verifying the accuracy and completeness of any such information. The user agrees and acknowledges that the onus of ensuring the completeness and accuracy of such information shall, at all times, lie with the user.
                            </div>
                            <div className={styles.points}>
                                (b) The user agrees that its usage of the Platform and accessing various financial products offered by third parties on the Platform, shall not in any way imply, suggest, or constitute any sponsorship, recommendation, opinion, advice (whether investment or legal) or approval of company in favour of or against such third parties or their products.
                            </div>
                            <div className={styles.points}>
                                (c) The user agrees, undertakes and declares that it has made its own assessment of the Platform and has determined that it is fit for its purposes. The user will be using the Platform at its own risk, irrespective of the capacity in which it uses the Platform and it is expressly clarified that company shall owe no fiduciary or other duty or obligation to the user other than as expressly agreed in writing by company.
                            </div>
                            <div className={styles.points}>
                                (d) The user agrees and acknowledges that any transactions entered into through the Platform shall be at the sole discretion of the user and the relevant counterparties on the Platform as applicable and that company shall not be liable for any delay, rejection or approval of any application/proposal made through the Platform to any counterparty, or any failure on part of the user or counterparties to settle/conclude any transactions with any counterparty.
                            </div>
                            <div className={styles.points}>
                                (e) The user agrees and acknowledges that all reports and documents provided on Nxcar(if any), and any information transmitted through Nxcar has been prepared based on the information, data and documents furnished by the user and relevant counterparties onboarded onto the Platform. The user hereby agrees and acknowledges that company does not warrant the genuineness of such information and shall not be liable for any error, deviation or omission in such reports, documents or information on account of any error, deviation or omission of whatsoever nature in the information collected from/furnished by the user, or any other person in this regard. The user further acknowledges that company has not carried out any “know your customer” or credit underwriting or any other similar or related checks in relation to any counterparty registered on the Platform or any information shared by such counterparty, and all such diligences and checks required under applicable law to verify the genuineness or lack thereof in relation to the user, or information shared by the user shall at all times, be the sole responsibility of the user.
                            </div>
                            <div className={styles.points}>
                                (f) Without prejudice to the above, the user hereby agrees, acknowledges and accepts that company may, for the purpose of rendering the Services, seek to obtain such necessary and requisite information and data as required by it from various authoritative sources including but not limited to statutory and regulatory filings, credit bureau records and such other sources as determined by it at its sole discretion, as required by it to effectively render the Services and the user hereby accepts and provides consent for the same.
                            </div>

                            <div className={styles.points}>
                                (g) The user hereby agrees and understands that its account created for the purposes of using the Services shall be visible to all counterparties and platform users of the Platform and irrevocably consents to the same. Provided that it is clear that nothing in these T&C shall be construed in a manner as to required by the company to obtain any specific or additional consent from the user with respect to featuring the user’s account on its Platform and/or displaying the same to other counterparties, along with the information furnished by the user to company as regards the Platform. It is understood that the information shared by the user with the company as reflected on the Platform shall be in accordance with applicable laws in force.
                            </div>
                            <div className={styles.points}>
                                (h)company reserves the right to reject the request for creation of the account, at its sole discretion, if the data or information submitted appears to be incomplete, incorrect or fraudulent.
                            </div>
                            <div className={styles.points}>
                                (i) On the creation of the account, the company grants to the user a non-exclusive, limited privilege to access and use the Platform and the Services offered on the Platform, subject to compliance with the T&C. The account created by the user for use of the Platform shall be non-transferable and its use is restricted to the user creating the account.
                            </div>
                            <div className={styles.points}>
                                (j)The user is solely responsible for its account and any activity on it, and must keep the log-in credentials in respect of its account secure at all times. The user shall not sell, transfer or sublicense the log-in credentials in relation to its account on the Platform to any other person. The user shall ensure that only its duly authorised personnel shall access and operate its account. The user undertakes that the user shall promptly on becoming aware of any unauthorised use of its account, notify the company at of such unauthorised use or access . It is expressly understood that company shall not be liable to ensure or verify that the usage of the user’s account is by its authorised persons and that the onus of ensuring that only authorised personnel access and operate the said account shall, at all times, lies with the user. The user agrees and acknowledges that failure to notify the company regarding any such unauthorised use of its account on the Platform may attract liability on the user for any losses/damages caused by any unauthorised use of the account.
                            </div>
                            <div className={styles.points}>
                                (k)The availing of the Services and the use of Platform is only for purposes permitted by: (i) the T&C; and (ii) any applicable law, regulation or generally accepted practices or guidelines and the same shall not be for any other illegal or other purpose which is expressly or impliedly barred by any applicable law, statute, ordinance or regulation in force from time to time.
                            </div>

                        </div>

                        {/* 4 */}
                        <div className={styles.heading}>
                            4. GENERAL UNDERTAKINGS OF THE User
                        </div>
                        <div className={styles.points_container}>
                            <div className={styles.points}>
                                (a)The user agrees to access any information, material, documents on Platform, provided by itself or any third party but accessible to the user, or avail any Service only through the authorised access provided by the company.
                            </div>
                            <div className={styles.points}>
                                (b)The user shall not use or attempt to use any unauthorised means, including but not limited to, use of any deep link, program, automatic device, algorithm or methodology, or any similar processes, or hacking to access, utilise, copy or monitor any part/feature of the Platform or its content, or avail any Services or in any way reproduce or circumvent the source code, structure or layout of the Platform, or obtain or attempt to obtain any information not openly available on the Platform.
                            </div>
                            <div className={styles.points}>
                                (c)The user undertakes not to attempt to gain any unauthorised access to the Platform, the server on which the Platform is hosted or any other database, server or computer connected to the Platform.
                            </div>
                            <div className={styles.points}>
                                (d)The user shall not misuse the Services or corrupt or try to corrupt the Platform by introducing any bugs, viruses, worms, malwares, spyware or any similar program which is malicious or harmful.
                            </div>
                            <div className={styles.points}>
                                (e)The user undertakes to ensure that any material/information/data uploaded by it on the Platform is strictly of nature and context as required by the Platform for the relevant Service, and is not in violation of any applicable law.
                            </div>
                            <div className={styles.points}>
                                (f)The user further undertakes not to:
                            </div>
                            <div className={styles.sub_points}>
                                (i) impersonate any person or entity, or misrepresent its affiliation with a person or entity;
                            </div>
                            <div className={styles.sub_points}>
                                (ii) publish, post, upload or distribute files that contain bugs, viruses, worms, malwares, spyware or any similar program, or corrupted files that may disrupt the operation of the Platform or a Counterparty’s computer;
                            </div>
                            <div className={styles.sub_points}>
                                (iii) engage in any activity that disrupts access to the Platform or the Services;
                            </div>
                            <div className={styles.sub_points}>
                                (iv) disrupt or interfere with the security of, or otherwise cause harm to, the Platform, accounts, passwords, servers or networks connected to or accessible through the Platform;
                            </div>
                            <div className={styles.sub_points}>
                                (v) cause any interference or attempt to interfere with the proper working of the Platform or any transaction being conducted on the Platform, or with any other person’s use of the Platform;
                            </div>
                            <div className={styles.sub_points}>
                                (vi) upload or distribute files that contain such material which is infringing the copyrights, trademarks, confidentiality, privacy or any other proprietary information protected by law, unless the user has the rights or the necessary consents thereto;
                            </div>
                            <div className={styles.sub_points}>
                                (vii) use the Platform or Services for any purpose that is fraudulent, unlawful or prohibited by the T&C and by applicable law, or to solicit the performance of any illegal activity or other activity which infringes the rights of company or any Counterparties;
                            </div>
                            <div className={styles.sub_points}>
                                (viii) upload or distribute any material or information that is, or contains tags or keywords which are, illegal, inappropriate, profane, obscene, offensive, abusive, harassing, misleading, indecent, defamatory, disparaging, or menacing, or is otherwise injurious to company or any Counterparty, or objectionable in any manner whatsoever;
                            </div>
                            <div className={styles.sub_points}>
                                (ix) download any file posted by the Counterparty that the user should know, or reasonably should know, cannot be legally distributed in such manner;
                            </div>
                            <div className={styles.sub_points}>
                                (x) violate the T&C or any applicable laws or regulations for the time being in force;
                            </div>
                            <div className={styles.sub_points}>
                                (xi) decompose, modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information or software obtained from the Platform.
                            </div>
                        </div>

                        {/* 5 */}
                        <div className={styles.heading}>
                            5. CONFIDENTIALITY
                        </div>
                        <div className={styles.points_container}>
                            <div className={styles.points}>
                                (a)The user acknowledges and agrees that all information, data or details in any form with respect to the Platform, its designs, structure and arrangement, visual interfaces, specifications, documentation, components, source code, object code, images, icons, audio-visual components and objects, schematics, drawings, protocols, processes, and other visual depictions, in whole or in part in addition to all documents, data, papers, statements, any business/customer information, trade secrets and process of company relating to its business practices or in connection with the provision of services by company, trade and business of company, or otherwise, any information including names, assets, details, documents, transaction records, potential transactions, negotiations, pending negotiations, data, applications, software, systems, papers, statements, business information, marketing and financial information, databases, manuals, records and reports, articles, systems, material, sources of material, and any other data pertaining to Counterparties and company, available to it through Platform <b>(“Confidential Information”)</b> is of a sensitive and confidential nature.
                            </div>
                            <div className={styles.points}>
                                (b)The user undertakes to keep all Confidential Information confidential and shall ensure that none of its employees, agents, representatives do anything to breach the confidentiality. The user undertakes to use all Confidential Information with such care and discretion, but not less than reasonable care, to avoid disclosure, publication or dissemination of Confidential Information as it uses with its own similar information that it does not wish to disclose, publish or disseminate.
                            </div>
                            <div className={styles.points}>
                                (c)The user may disclose Confidential Information only to:
                            </div>
                            <div className={styles.sub_points}>
                                (i) its employees, representatives, agents or contractors on a ‘need to know’ basis and only for the purposes of performance of its obligations under the transaction/Services;
                            </div>
                            <div className={styles.sub_points}>
                                (ii) the extent required by any regulatory authority or governmental authority under applicable law; and
                            </div>
                            <div className={styles.sub_points}>
                                (iii) any other party with the user’s prior written consent.
                            </div>
                            <div className={styles.points}>
                                (d)The user agrees:
                            </div>
                            <div className={styles.sub_points}>
                                (i) to take all necessary action to protect the Confidential Information against misuse, sale, loss, destruction, deletion and/or alteration;
                            </div>
                            <div className={styles.sub_points}>
                                (ii) use the Confidential Information only in connection with the Services/transaction for which the Confidential Information is obtained; and
                            </div>
                            <div className={styles.sub_points}>
                                (iii) not to misuse or permit misuse directly or indirectly, commercially exploit the Confidential Information of the company or any other Counterparty available to it through the Platform for economic or other benefit or in a manner prejudicial to the company.
                            </div>
                        </div>

                        {/* 6 */}
                        <div className={styles.heading}>
                            6. DATA PROTECTION
                        </div>
                        <div className={styles.points_container}>
                            <div className={styles.points}>
                                (a)The storage, usage and transmission of all information obtained by it through the Platform, shall at all times be in accordance with the Information Technology Act, 2000 read with Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 and all other applicable law.
                            </div>
                            <div className={styles.points}>
                                (b) In the event company obtains any Personal Data or Confidential Information pursuant to any transaction/Services, it shall only undertake the processing of Personal Data/Confidential Information reasonably required in connection with the performance of its obligations under the transaction/Services. In the event any such Personal Data and/or Confidential Information is required to be used by company for any purpose not expressly set out herein post termination/consummation of the transactions/Services contemplated hereunder, the same shall take place pursuant to obtaining prior consent from the user in this regard.
                            </div>
                            <div className={styles.points}>
                                (c) company shall at all times have appropriate technical and organisational measures in place to:
                            </div>
                            <div className={styles.sub_points}>
                                (i) prevent unauthorised or unlawful processing of any Personal Data/Confidential Information;
                            </div>
                            <div className={styles.sub_points}>
                                (ii) protect any Personal Data/Confidential Information against accidental loss, destruction or damage;
                            </div>
                            <div className={styles.sub_points}>
                                (iii) ensure the reliability of its employees/contractor having access to the Personal Data/Confidential Information;
                            </div>
                            <div className={styles.points}>
                                (d) company shall comply with all applicable law, including without limitation the Data Protection Laws to safeguard the Personal Data/Confidential Information. For the purposes of this clause, Personal Data shall mean the information related to the users (borrowers) to whom credit facilities may be extended including their personally identifiable information and sensitive personal information (as envisaged under the Information Technology Act, 2008, and the rules made thereunder, each as amended from time to time).
                            </div>
                        </div>

                        {/* 7 */}
                        <div className={styles.heading}>
                            7. LIMITATION OF LIABILITY
                        </div>
                        <div className={styles.points_container}>
                            <div className={styles.points}>
                                (a) company, its, employees, agents, and associates, will not be liable with respect to any subject matter relating to the T&C under any contract or torts, or on account of negligence, strict liability or other legal or equitable rights for: (i) any special or consequential damages; (ii) for interruption of use; (iii) loss or corruption of data.
                            </div>
                            <div className={styles.points}>
                                (b) company shall not be liable or responsible for:
                            </div>
                            <div className={styles.sub_points}>
                                (i) any faulty operation of system during accessing of Platform or during the transmission/transfer of any data/information;
                            </div>
                            <div className={styles.sub_points}>
                                (ii) any damage that occurs due to any information provided by the user but not being received by company or any Counterparty or not being received promptly or appropriately, as a consequence of technical faults with the Platform (whether or not in company’s control);
                            </div>
                            <div className={styles.sub_points}>
                                (iii) any Confidential Information of the user being used in any manner contrary to Clauses 5(c) and 5(d) by any Counterparty;
                            </div>
                            <div className={styles.sub_points}>
                                (iv) any loss or damage caused due any bugs, viruses, worms, malwares, spyware or any similar program that may infect the user’s computer, software, data or any other property caused by accessing or using the Platform or availing any Services, or from any emails, communication or attachment received from company/Platform;
                            </div>
                            <div className={styles.sub_points}>
                                (v) any failure to perform, or delay in performance of, any of obligations or Services by company or any Counterparty due events outside company’s reasonable control;
                            </div>
                            <div className={styles.sub_points}>
                                (vi) any breach of the T&C by any Counterparty;
                            </div>
                            <div className={styles.points}>
                                (c) company is not responsible for any indirect losses including, without limitation, loss of income, business, profits, data or any injury to reputation, however arising.
                            </div>
                            <div className={styles.points}>
                                (d)company is not responsible for any liability for any loss, costs or damage arising out of or in connection with the use of the Platform or the inability to use it or for any delay or failure in the performance of the Services on account of the Counterparty or due to any Force Majeure Event or any other circumstances beyond the control of company.
                            </div>
                            <div className={styles.points}>
                                (e)The user acknowledges that the company aims to facilitate seamless data and transaction processing and that company is not responsible for any failure of the Platform to store, transfer or delete data or for the corruption or loss of any data, information or content contained therein.
                            </div>
                        </div>

                        {/* 8 */}
                        <div className={styles.heading}>
                            8. REFUNDS
                        </div>
                        <div className={styles.points_container}>
                            <div className={styles.points}>
                                All sales are final and no refund will be issued.
                            </div>
                            <div className={styles.points}>
                                (a) Refund Eligibility: We understand that circumstances may arise where you may need a refund. Our refund policy is straightforward: we only provide refunds in cases where the payment has been successfully processed but our services could not be delivered. Refunds will not be issued for any other reasons, as our platform operates on a Platform as a Service (PaaS) model, and traditional refund scenarios do not apply.
                            </div>
                            <div className={styles.points}>
                                (b) Eligibility Period: Refund requests must be made within 3 days of the date of the successful payment transaction. Any refund requests received after this period will not be considered.
                            </div>
                            <div className={styles.points}>
                                (c) Refund Process: To request a refund, please contact our customer support team at contact@nxcar.in. Provide the following information in your refund request: Transaction ID, Date of the transaction & Description of the issue, Our team will review your request and respond within 2 business day.
                            </div>
                            <div className={styles.points}>
                                (d) Non-Refundable Circumstances: Refunds will not be issued under the following circumstances: Payment failures: If the payment transaction fails for any reason, we will not issue a refund. It is the responsibility of the user to ensure that their payment information is accurate and up to date. Violation of Terms of Service: Refunds will not be provided if a user has violated our Terms of Service.
                            </div>
                            <div className={styles.points}>
                                (e) Payment Disputes: If you believe there has been an error in the processing of your payment, please contact our customer support team immediately. We will investigate and, if necessary, rectify any payment discrepancies.
                                <div className={styles.points}>
                                    (f) Changes to Refund Policy: NXFIN TECHNOLOGIES PRIVATE LIMITED reserves the right to modify or amend this Refund Policy at any time. Any changes will be effective immediately upon posting the updated policy on our website. It is your responsibility to review this policy periodically for changes.
                                </div>
                            </div>
                        </div>

                        {/* 9 */}
                        <div className={styles.heading}>
                            9. TRANSFER OF RIGHTS
                        </div>
                        <div className={styles.points_container}>
                            <div className={styles.points}>
                                (a)The T&C are binding on the user, its successors and permitted assignees. It may not transfer, assign, charge or otherwise dispose of any of its rights, benefits, liabilities or obligations, under the T&C without the prior written consent of the company.
                            </div>
                            <div className={styles.points}>
                                (b)However, the company may transfer, assign, sub-contract or otherwise dispose of any of its rights, benefits, liabilities or obligations under the T&C at any time or may involve or appoint any third party to satisfy its obligations, without any necessity to notify the user.
                            </div>
                        </div>

                        {/* 10 */}
                        <div className={styles.heading}>
                            10. SECURITY OF DATA
                        </div>
                        <div className={styles.points_container}>
                            <div className={styles.points}>
                                (a) All facilities used to store data and process the transaction will adhere to reasonable security standards, which will be at par with company’s security standards for processing and storing its own information of a similar nature. The company has implemented industry standard systems and procedures to ensure the security and confidentiality of a transaction and related data, protect the user against anticipated threats or breaches to the security or integrity of a transaction and related data, and protect the user against unauthorised access to its data.
                            </div>
                            <div className={styles.points}>
                                (b) The user further acknowledges and agrees that it has granted the company the permission as well as the non-exclusive right to store and transfer its data, as part of the Services.
                            </div>
                            <div className={styles.points}>
                                (c) company does not claim any ownership, intellectual property rights or other rights whatsoever outside the scope of what is necessary to provide the Services.
                            </div>
                            <div className={styles.points}>
                                (d) company reserves the right to perform statistical analysis of the activity on Platform in order to measure interests in the various sections of the Platform for the purpose of product and Service development. Any non-personal information such as internet domain, host names, IP addresses, clickstream patterns, and the dates and times on which Platform is accessed may be recorded for the purpose of analysis. The analytical data collected is for internal use only.
                            </div>
                        </div>

                        {/* 10 */}
                        <div className={styles.heading}>
                            11. INTELLECTUAL PROPERTY
                        </div>
                        <div className={styles.points_container}>
                            <div className={styles.points}>
                                (a)The Platform and the arrangement and processes thereof, including, but not limited to, all text, graphics, user interfaces, visual interfaces, and the computer code , source code, object code and is owned by company, and the design, structure, and arrangement of thereof is protected by copyright, patent and trademark laws, and all other applicable intellectual property laws and the user shall not use the aforesaid without the prior written consent of company.
                            </div>
                            <div className={styles.points}>
                                (b)The trademarks, logos and marks displayed on the Platform are the property of the company, or the property of the user(s) or of the Counterparty(ies), as the case may be. The user shall not use any trademarks, logos and marks not belonging to it, without the prior consent of the company or the Counterparty, as the case may be.
                            </div>
                            <div className={styles.points}>
                                (c)In the event the user infringes or attempts to infringe the copyrights or other intellectual property rights of the company, the company may, in its sole discretion, deny the user access to and use of the Platform.
                            </div>
                        </div>

                        {/* 11 */}
                        <div className={styles.heading}>
                            12. COMMUNICATION WITH COUNTERPARTIES
                        </div>
                        <div className={styles.points_container}>
                            <div className={styles.points}>
                                (a) company does not control, and is not responsible for any information, data, documents etc. appearing on the Platform or for any communication between the user and the Counterparties inter se, including, without limitation, e-mails sent.
                            </div>
                            <div className={styles.points}>
                                (b) The user acknowledges that any communication received from Platform, including, without limitation, emails are automated communications and company is not responsible for the inaccuracy etc. of the content thereof.
                            </div>
                            <div className={styles.points}>
                                (c)company does not endorse, guarantee, or assume any responsibility for any Services offered to the user, and company will not be a party to, or in any way be responsible or liable for any transaction between the user and the Counterparties on or through the Platform.
                            </div>
                        </div>

                        {/* 12 */}
                        <div className={styles.heading}>
                            13. TAXES
                        </div>
                        <div className={styles.points_container}>
                            <div className={styles.points}>
                                (a)The user shall be responsible for identifying and paying all taxes, duties, cess and other fees and charges (and any penalties, interest, and other additions thereto) that are payable by the user with respect to the transactions through the Platform.
                            </div>
                            <div className={styles.points}>
                                (b)company will not be liable to pay any taxes which are payable by the user to any governmental authority for any such transaction.
                            </div>
                        </div>

                        {/* 13 */}
                        <div className={styles.heading}>
                            14. MODIFICATION OF T&C
                        </div>
                        <div className={styles.points_container}>
                            <div className={styles.points}>
                                (a)The user acknowledges and agrees that company has the right to revise, modify, amend or discontinue any of the T&C from time to time including without limitation to reflect, inter alia, changes in market conditions, technology, requirements, laws etc. and company shall not be obligated to obtain any approval/consent of the user on the revised T&C.
                            </div>
                            <div className={styles.points}>
                                (b)The user is deemed to be aware of the latest T&C and the use of Platform and the availing of the Services is subject to the most current version of the T&C available on the Platform at the time of such use. The use of Platform and Services subsequent to any modification in the T&C will constitute the user’s acceptance of the modification.
                            </div>
                        </div>

                        {/* 14 */}
                        <div className={styles.heading}>
                            15. TEMPORARY SUSPENSION AND TERMINATION
                        </div>
                        <div className={styles.points_container}>
                            <div className={styles.points}>
                                (a)company does not guarantee uninterrupted access to the Platform. The access to Platform or the Services may be disrupted:
                            </div>
                            <div className={styles.sub_points}>
                                (i)on the occurrence of Force Majeure Event;
                            </div>
                            <div className={styles.sub_points}>
                                (ii)for the purposes of repair, maintenance, upgrading or for other similar activities,the company undertakes to take all reasonable measures to minimise any such periods during which the Platform and the Services are unavailable and shall put best effort in resuming the Services and the Platform.
                            </div>
                            <div className={styles.points}>
                                (b)company shall not be responsible or liable for any losses or damages suffered due to any of the events specified in sub-Clause (a) above due to the suspension of Services or due to the inability to cure such events.
                            </div>
                            <div className={styles.points}>
                                (c)In the event of a system failure due to any unforeseeable circumstances or disruption, the access to Platform and Services may be temporarily suspended and no information may be accepted during such suspension.
                            </div>
                            <div className={styles.points}>
                                (d)company has the right to temporarily or permanently disable the user’s account when, in the company's opinion, without any cause or notice. On such a breach, the company may also block access to the user’s account and/or procure that any outstanding transactions are cancelled/suspended immediately.
                            </div>
                            <div className={styles.points}>
                                (e)company reserves the right to restrict or suspend access to the Platform or terminate the Platform completely, at its sole discretion.
                            </div>
                            <div className={styles.points}>
                                (f)All provisions of the T&C, which by their nature should survive termination, shall survive termination, including, without limitation, ownership, warranty, limitations of liability, confidentiality, disclaimers, indemnity.
                            </div>
                        </div>

                        {/* 15 */}
                        <div className={styles.heading}>
                            16. DISCLAIMER OF WARRANTIES
                        </div>
                        <div className={styles.points_container}>
                            <div className={styles.points}>
                                ‍(a)The Platform is provided on an “as is” and “as available” basis. company hereby disclaims all warranties of any kind, express or implied, including without limitation, the warranties of merchantability, satisfactory quality, fitness for a particular purpose, non-infringement, availability, accuracy, completeness, security or reliability of the Services and/or any data uploaded, used or transmitted on or through the Platform. The user uses the data and/or the Services through the Platform at its own discretion and risk and the company is not responsible for the accuracy, usefulness, safety, or intellectual property rights of or relating to such data and the user assumes all liability for its use.
                            </div>
                            <div className={styles.points}>
                                (b)The information contained in the Platform is provided on “as is” and “as available” basis. The accuracy of the information is the sole responsibility of the user and/or Counterparty providing the same and the Platform and company are not in any manner whatsoever liable for the accuracy of the information contained in the Platform. The user specifically acknowledges and agrees that the application and impact of laws in relation to the information provided on the Platform can vary widely based on the specific facts involved. Given the changing nature of laws, rules and regulations, and the inherent hazards of electronic communication, there may be delays, omissions or inaccuracies in information contained in this Platform. Accordingly, the user acknowledges and accepts that the information in this Platform is provided with the understanding that the company is not herein engaged in rendering legal, accounting, tax, credit/investment or other professional advice and services. As such, it should not be used as a substitute for consultation with professional accounting, tax, legal, credit/investment or other competent advisers and the user/Counterparty utilising the Platform is solely responsible for all investment decisions, made in relation to any information accessed through the Platform without any reference to, or recourse against the Platform and/or company
                            </div>
                            <div className={styles.points}>
                                (c) Unless otherwise expressly agreed in writing, the company will not be involved for any interactions between the user and the Counterparties on the Platform or in connection with the Services. These interactions are solely between the user and the Counterparties and the company merely provides a common platform to facilitate such transactions. The company is not responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or interactions.
                            </div>
                            <div className={styles.points}>
                                (d)The company is not obligated to be involved in or facilitate the resolution of any dispute between the user and the Counterparties on the Platform, the user hereby releases company, its employees, agents, and associates from any claims, demands and damages, arising out of or in any way related to such disputes. Without prejudice to the above, the user hereby waives any legal or equitable rights or remedies that it may possess against the company in relation to the dispute.
                            </div>
                        </div>

                        {/* 16 */}
                        <div className={styles.heading}>
                            17. INDEMNIFICATION
                        </div>
                        <div className={styles.points_container}>
                            <div className={styles.points}>
                                The user hereby agrees to indemnify and hold harmless company (including its directors, employees, representatives and agents) from time to time, against any and all losses, liabilities, obligations, damages, judgments, costs, expenses (including, without limitation, advisors’ fees), claims, fines, penalties, proceedings, actions or demands, of any kind or nature incurred by company/caused to company on account of user’s use of the Platform or the Services, including but not limited to the violation of the T&C.
                            </div>
                        </div>

                        {/* 17 */}
                        <div className={styles.heading}>
                            18. GOVERNING LAW AND JURISDICTION
                        </div>
                        <div className={styles.points_container}>
                            <div className={styles.points}>
                                The T&C are governed and construed in accordance with the laws of India and the user hereby submits himself to the exclusive jurisdiction of courts and tribunals at Delhi, India. The user irrevocably waives any objection it may have now or in the future to the choice of courts and tribunal of Delhi, India as an inconvenient forum.
                            </div>
                        </div>

                        {/* 18 */}
                        <div className={styles.heading}>
                            19. SEVERABILITY
                        </div>
                        <div className={styles.points_container}>
                            <div className={styles.points}>
                                ‍Every provision contained in the T&C shall be severable and distinct from every other provision and if at any time any one or more of such provisions is or becomes invalid illegal or unenforceable, the validity, legality and enforceability of the remaining provisions hereof shall not be in any way affected or impaired thereby.
                            </div>
                        </div>

                        {/* 19 */}
                        <div className={styles.heading}>
                            20. WAIVERS
                        </div>
                        <div className={styles.points_container}>
                            <div className={styles.points}>
                                (a)Neither the failure to exercise nor any delay in exercising any right, power, privilege or remedy under the T&C shall in any way impair or affect the exercise thereof or operate as a waiver thereof in whole or in part.
                            </div>
                            <div className={styles.points}>
                                (b)No single or partial exercise of any right, power, or privilege under the T&C shall prevent any further or other exercise thereof or the exercise of any other right, power, privilege or remedy.
                            </div>
                        </div>

                        {/* 20 */}
                        <div className={styles.heading}>
                            21. DEFINITIONS
                        </div>
                        <div className={styles.points_container}>
                            <div className={styles.points}>
                                Unless repugnant to the context, the terms used in the T&C have the following meaning: <b>“company”</b> means Propsera Services Private Limited (including its successors and assigns) ‍ <b>”user”</b> means
                            </div>
                            <div className={styles.points}>
                                (a)In case of an individual, any person who has an account on the Platform; or
                            </div>
                            <div className={styles.points}>
                                (b)In case of persons other than individuals,
                            </div>
                            <div className={styles.sub_points}>
                                (i) An entity who holds an account on the Platform, and who may access the Platform through its representatives or authorised officials; or
                            </div>
                            <div className={styles.sub_points}>
                                (ii) An entity on whose behalf any authorised representative or person may hold an account on and access the Platform. ‍”Counterparty” means any person with which the user:
                            </div>
                            <div className={styles.points}>
                                (a)enters into any arrangement/contract/agreement with, pursuant to or by way of the Services or the Platform; and/or
                            </div>
                            <div className={styles.points}>
                                (b)may potentially enter into any arrangement/contract/agreement with, by availing the Services on the Platform. ‍”Force Majeure Event” includes any act, event, non-happening, omission or accident beyond the reasonable control of company and includes, without limitation, the following events:
                            </div>
                            <div className={styles.sub_points}>
                                (a)strikes, lock-outs or other industrial action;
                            </div>
                            <div className={styles.sub_points}>
                                (b)riot, invasion, terrorist attack or threat of terrorist attack, war (whether declared or not) or threat or preparation for war;
                            </div>
                            <div className={styles.sub_points}>
                                (c)fire, explosion, storm, flood, earthquake, subsidence, epidemic or other natural disaster;
                            </div>
                            <div className={styles.sub_points}>
                                (d)impossibility of the use of public or private telecommunications networks or internet services; and
                            </div>
                            <div className={styles.sub_points}>
                                (e)the acts, decrees, legislation, regulations or restrictions of any government, as may be applicable.
                            </div>
                            <div className={styles.points}>
                                “Platform” means the Nxcar platform launched by the company for offering Services to the user. ‍<b>”Personal Data”</b> shall have the same meaning as ascribed to the term “Sensitive Personal Data or Information” under the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 (as amended from time to time).
                            </div>
                            <div className={styles.points}>
                                ‍<b>”Services”</b> means the product or services offered by the company through the Platform, including but not limited to facilitation interaction between the user and Counter parties to enable them to enter into transactions, including (without limitation) for securitisation, direct assignments, or any other product or services offered.
                            </div>
                        </div>

                        {/* 20 */}
                        <div className={styles.heading}>
                            GRIEVANCE REDRESSAL POLICY
                        </div>
                        <div className={styles.points_container}>
                            <div className={styles.points}>
                                In accordance with Information Technology Act 2000 and rules made there under, the name and contact details of the Grievance Officer are provided below:
                            </div>
                            <div className={styles.points}>
                                If you have any grievance or complaint, questions, comments, concerns or feedback in relation to the processing of information or regarding this Privacy Policy or any other privacy or security concern related to the App/Website, you can reach out to:
                            </div>
                            <div className={styles.sub_points}>
                                To: Grievance Redressal Officer
                            </div>
                            <div className={styles.sub_points}>
                                Email: grievance@Nxcar.in
                            </div>
                            <div className={styles.sub_points}>
                                Mobile: 9711202816
                            </div>
                            <div className={styles.points}>
                                For any concerns related to NBFC, You can reach out to :
                            </div>
                            <div className={styles.sub_points}>
                                <b>TSC Finserv Private Limited</b>
                            </div>
                            <div className={styles.sub_points}>
                                Grievance Officer
                            </div>
                            <div className={styles.sub_points}>
                                Email: vinay.gupta@tscfinserv.com
                            </div>
                            <div className={styles.sub_points}>
                                Mobile: 9872877550
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <Footer />
            </div>
        </>
    )
}

export default Terms_of_use
