import styles from './Buy_Sell.module.css'
import Header from '../../../components/Header/Header'
import Footer from '../../../components/Footer/Footer'

import NewHero from '../../../reusable/hero/HomeHero/NewHero'
import Check_now from '../../../reusable/check_now/Check_now'
import BuyCarProcess from '../../../reusable/buyCarProcess/BuyCarProcess'
import BuySellCards from '../../../components/buySell/buySellCards/BuySellCards'
import Disclaimer from '../../../reusable/disclaimer/Disclaimer'
import DownloadDocs from '../../../components/buySell/downloadDocs/DownloadDocs'

const Buy_Sell = () => {
    return (
        <>
            <div className={styles.container}>
                {/* <div className={styles.circles1}></div> */}
                <Header />
                <NewHero />
                <BuyCarProcess />
                <BuySellCards />
                <DownloadDocs />
                <Check_now />
                <Disclaimer />
            </div>
            <div className={styles.footer}>
                <Footer />
            </div>
        </>
    )
}

export default Buy_Sell