
import styles from '../Blogs.module.css'
import instagram from '../../../../images/logos/instagram.svg'
import whatsapp from '../../../../images/logos/whatsapp.svg'
import facebook from '../../../../images/logos/facebook.svg'
import linkdin from '../../../../images/logos/linkdin.svg'
import img1 from '../../../../images/vectors/homescreen/knoledge/img2.svg'
import img2 from '../../../../images/vectors/homescreen/knoledge/img4.svg'
import img3 from '../../../../images/vectors/homescreen/knoledge/img3.svg'
import hand_with_arrow from '../../../../images/vectors/blogs/Arrow.svg'



const OtherBlogs = () => {

    return (
        <>
            <div className={styles.ref_links}>
                <div className={styles.ref_heading}>
                    You may also like these
                </div>
                <div className={styles.ref_container}>
                    <div className={styles.ref_card}>
                        <div className={styles.ref_image}>
                            <img src={img1} alt="" />
                        </div>
                        <div className={styles.ref_sub_heading}>How to buy a used car?</div>
                        <a href="/blog-how-to-buy-a-used-car">
                            <div className={styles.ref_button}>Read More</div>
                        </a>
                    </div>
                    <div className={styles.ref_card}>
                        <div className={styles.ref_image}>
                            <img src={img2} alt="" />
                        </div>
                        <div className={styles.ref_sub_heading}>
                            When is the right time to buy a used car?</div>
                        <a href="/blog-right-time-to-buy-car">
                            <div className={styles.ref_button}>Read More</div>
                        </a>
                    </div>
                    <div className={styles.ref_card}>
                        <div className={styles.ref_image}>
                            <img src={img3} alt="" />
                        </div>
                        <div className={styles.ref_sub_heading}>When not to buy a used car?</div>
                        <a href="/blog-when-not-to-buy-a-used-car">
                            <div className={styles.ref_button}>Read More</div>
                        </a>
                    </div>
                </div>
            </div>

            <div className={styles.bottom_tape}>
                <div className={styles.tape_heading}>
                    You navigated through the entire blog like a pro!
                </div>
                <div className={styles.tape_sub_heading}>
                    You can also share with your friends
                    {/* <img src={hand_with_arrow} alt="" /> */}
                    <div className={styles.logo_container}>
                        <a href="https://www.facebook.com/nxfin" target='_blank'>
                            <img src={facebook} alt="" />
                        </a>
                        <a href="https://www.instagram.com/nxcarindia/" target='_blank'>
                            <img src={instagram} alt="" />
                        </a>
                        <a href="https://www.linkedin.com/company/nxfin/mycompany/?viewAsMember=true" target='_blank'>
                            <img src={linkdin} alt="" />
                        </a>
                        <a href="https://api.whatsapp.com/send/?phone=%2B919355924132&text&type=phone_number&app_absent=0" target='_blank'>
                            <img src={whatsapp} alt="" />
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OtherBlogs