import React from 'react'
import styles from './Disclaimer.module.css';

export default function Disclaimer() {
    return (
        <div className={styles.container}>
            <div className={styles.heading}>
                Disclaimer
            </div>
            <div className={styles.sub_heading}>
                Nxcar does not directly buy or sell cars. Instead, we offer a user-friendly platform that facilitates used car transactions between buyers and sellers.
            </div>
        </div>
    )
}
