
import React, { useState, useEffect } from 'react';
import styles from './Consumer.module.css';
import axios from 'axios';
import { IoClose } from "react-icons/io5";
import { toast } from 'react-toastify';
import OtpInput from 'react-otp-input';
import loader from '../../../images/loading/loader.gif';
import nxcarLogo from '../../../images/logos/logo.svg';
import { Link } from 'react-router-dom';

const Consumer = ({ onClose }) => {

  const [step, setStep] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [isTimeExpired, setIsTimeExpired] = useState(false);
  const [isOtpValid, setIsOtpValid] = useState(true);
  const [isValid, setIsValid] = useState(true);
  const [isFocused, setIsFocused] = useState(false);
  const [seconds, setSeconds] = useState(120);
  const [loading, setLoading] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState("");


  const setParamsInLocalStorage = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const dealerId = urlParams.get('id');
    if (dealerId) {
      const data = {
        value: dealerId,
        timestamp: new Date().getTime(),
      };
      localStorage.setItem('my-dealer-id', data.value);
    }
  };

  useEffect(() => {
    setParamsInLocalStorage();
    const storedData = localStorage.getItem('my-dealer-id');
    if (storedData) {
      const data = JSON.parse(storedData);
    }
  }, []);


  useEffect(() => {
    let interval

    if (step == 2) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds === 1) {
            setIsTimeExpired(true);
            clearInterval(interval);
            return 0;
          }
          return prevSeconds - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [step]);


  // THE FORMATTED DATE FOR THE DATE AND TIME
  const formattedTime = () => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours < 10 ? '0' : ''}${hours} : ${minutes < 10 ? '0' : ''}${minutes} : 
    ${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}s`;
  };

  // THIS 3 FUNCTIONS ARE JUST FOR UI PURPOSE 
  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    setIsValid(true);
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  // APIS HERE ----------------------------------------------------------
  const loginAPI = async () => {
    try {
      const response = await axios.post(`/signup`, {
        mobile: phoneNumber,
        signature: "nxcar",
      });
      if (response.data.status === "OTP sent") {
        setStep(2);
      } else {
        setIsValid(false);
      }
    } catch (error) {
      console.error('Error during login API call:', error);
    }
  };
  const resendOTP = async () => {
    try {
      const response = await axios.post(`/signup`, {
        mobile: phoneNumber,
      });
      if (response.statusText === "OK") {
        // console.log('resend OTP works');
        toast.dismiss();
        toast.success('OTP sent');
        setStep(2);
      } else {
        toast.dismiss();
        toast.error('error OTP sent');
        // console.log('hi failed OTP API');
        setIsValid(false);
      }
    } catch (error) {
      console.error('Error during login API call:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const indianPhoneNumberRegex = /^[6-9]\d{9}$/;
    if (indianPhoneNumberRegex.test(phoneNumber)) {
      setLoading(true);
      try {
        await loginAPI();
        setLoading(false);
        setStep(2);
      } catch (error) {
        console.error('Error during login:', error);
        setLoading(false);
      }
    } else {
      setIsValid(false);
    }
  };

  // OTP VERIFICATION API HERE
  const otpVerificationAPI = async () => {
    if (otp.length < 4) {
      toast.dismiss();
      toast.error('Please fill all fields');
    }
    else {
      try {
        const response = await axios.post(`/otpverify`, {
          mobile: phoneNumber,
          otp: otp
        });
        console.log('response', response);
        if (response.status === 200) {
          localStorage.setItem("Authorization", response?.data?.access_token)
          localStorage.setItem("consumer_id", response?.data?.consumer_id)
          if (response.data.status === true) {
            window.location.href = '/consumer-dashboard';
          }
        } else {
          // console.log('OTP');
          setIsOtpValid(false);
          toast.dismiss();
          toast.error('Wrong OTP');
        }
        // console.log('my response of ',response.data.new_user);
        localStorage.setItem('new-old', response?.data?.new_user)

      } catch (error) {
        // console.log('OTP', error.message);
        setIsOtpValid(false);
        setOtp('');
        toast.dismiss();
        toast.error('Wrong OTP');
      }
    }
  };

  const handleOTPVerification = async (e) => {
    e.preventDefault();
    await otpVerificationAPI();
  };

  const handleOTPChange = (e) => {
    const newValue = e.target.value;
    setOtp(newValue);
  }



  const renderStep = () => {
    switch (step) {

      case 1:
        return (
          <>
            <div className={styles.heading_section}>
              <div className={styles.flex}>
                <Link to='/'>
                  <div className={styles.logo}>
                    <img src={nxcarLogo} alt="nxcar-logo" />
                  </div>
                </Link>
                <div
                  className={styles.mui_close_button}
                  onClick={onClose}
                ><IoClose /></div>
              </div>

              <div className={styles.heading}>Welcome To Nxcar !</div>
              {/* <div className={styles.sub_heading}>
                Enter Your Mobile Number To Login
              </div> */}
              {/* <div className={styles.sub_heading_1} style={{ fontWeight: '400px' }}>
                Please add your mobile number
              </div> */}
              <div className={styles.sub_heading_2} style={{ fontWeight: '300px' }}>
                We'll need to confirm it by sending a text
              </div>
            </div>


            <form onSubmit={handleSubmit}>
              <div className={styles.MUI_input} style={{ marginTop: "14px" }}>
                <div className={styles.phoneNumberDisplay}>
                  +91
                </div>
                <input
                  type="tel"
                  required
                  maxLength={10}
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                // id='phone_number'
                // style={{ padding: "12px 0px" }}
                />
                <div className={styles.float_label}>
                  {isFocused ? 'Enter Mobile Number' : '+91 Enter Mobile Number'}
                </div>
              </div>
              {!isValid && (
                <div className={styles.error_message}>
                  Please Fill Valid Details
                </div>
              )}
              <button
                type="submit"
                className={styles.continue_button}
                style={{ marginTop: "40px" }}
                disabled={loading}
              >
                {
                  loading ?
                    <div className={styles.loading}>
                      <img src={loader} alt="loader" />
                    </div>
                    : 'Send OTP'
                }

              </button>
            </form>
          </>
        );

      case 2:
        return (
          <>
            <div className={styles.heading_section}>
              <div className={styles.flex}>
                <Link to='/'>
                  <div className={styles.logo}>
                    <img src={nxcarLogo} alt="nxcar-logo" />
                  </div>
                </Link>
                <div
                  className={styles.mui_close_button}
                  onClick={onClose}
                ><IoClose /></div>
              </div>
              <div className={styles.heading}>Welcome To Nxcar !</div>
              <div className={styles.sub_heading_1}>
                Enter OTP to verify your number
              </div>
            </div>


            <form onSubmit={handleOTPVerification}>
              {/* <div className={styles.otp_fields}> */}
              {/* <OtpInput
                  className={`otp-field ${!isOtpValid ? styles.error_message_otp : ''}`}
                  type="tel"
                  name="otp"
                  required
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  renderSeparator={<span></span>}
                  renderInput={(props) => <input {...props} />}
                /> */}
              {/* </div> */}

              <div className={styles.new_otp} style={{ marginTop: '40px' }}>
                <input
                  placeholder='● ● ● ●'
                  required
                  maxLength={4}
                  value={otp}
                  onChange={handleOTPChange}
                  type="tel" />
              </div>

              <div className={styles.otp_will_expire}>
                {!isOtpValid && (
                  <div className={styles.error_message_otp}>
                    {/* {otpErrorMessage} */}
                  </div>
                )}
                {!isTimeExpired ? (
                  <>
                    <button
                      className={styles.resend_button}
                      onClick={resendOTP}>
                      Resend OTP
                    </button>
                    {/* OTP will expire in
                    <span style={{ color: 'teal' }}>{' ' + formattedTime()}</span> */}
                  </>
                ) : (
                  <>
                    {/* <span style={{ color: "grey" }}>
                                            OTP is expired. <br />
                                            Please Re-Enter Your Mobile Number
                                        </span> */}
                    <button
                      className={styles.resend_button}
                      onClick={resendOTP}>
                      Resend OTP
                    </button>
                  </>
                )}
              </div>

              <div className={styles.consent_box}>
                <div className={styles.consent_left}>
                  <input
                    type="checkbox"
                    required
                  />
                </div>
                <div className={styles.consent_right}>
                  I agree that I have read, understood, and accepted the <span style={{ color: 'teal', textDecoration: 'underline' }}> <a href="https://nxcar.in/privacy-policy" target='_blank'>Privacy Policy</a>, <a href="https://nxcar.in/terms-of-use" target="_blank">Terms of Use</a> and Consent Declaration</span> and hereby appoint Nxcar as my authorized representative to receive my credit information from any certified Credit Information Companies
                </div>
              </div>

              {isOtpValid ? (

                <button
                  type="submit"
                  className={styles.continue_button}
                >
                  Continue
                </button>
              ) : (
                <>
                  <button
                    type="submit"
                    className={styles.continue_button}
                  >
                    Continue
                  </button>
                  {/* <span style={{ color: "red", margin: "10px 0" }}>
                    Wrong OTP
                  </span> */}
                </>
              )}
            </form>
          </>
        );

      default:
        return null;
    }
  };

  return <div className={styles.container}>{renderStep()}</div>;
};


export default Consumer