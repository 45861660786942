import styles from './Knowledge.module.css'
import img1 from '../../images/vectors/homescreen/knoledge/img1.svg'
import img2 from '../../images/vectors/homescreen/knoledge/img2.svg'
import img3 from '../../images/vectors/homescreen/knoledge/img3.svg'
import img4 from '../../images/vectors/homescreen/knoledge/img4.svg'
import img5 from '../../images/vectors/homescreen/knoledge/img5.svg'

import { scrollToTop } from '../../reusable/scrollToTop/ScrollToTopButton'

const Knowledge = () => {
    const arr = [
        {
            id: 1,
            heading: "Should you buy a used car?",
            subHeading: "Buying a used car is smart—it's cheaper than new ones, saving you money. Plus, since it has already lost value, you won't lose much more later. Just check its condition, maintenance, and mileage for a good buy.",
            imageUrl: img1,
            link: '/blog-should-you-buy-a-used-car',
            data: [
                { label: "Data", value: "Subhead" },
                { label: "Data", value: "Subhead" },
                { label: "Data", value: "Subhead" }
            ]
        },
        {
            id: 2,
            heading: "How to buy a used car?",
            subHeading: "Research, inspect, and test drive potential vehicles, review history reports, and consider professional inspections for a confident purchase.",
            imageUrl: img2,
            link: '/blog-how-to-buy-a-used-car',
            data: [
                { label: "Data", value: "Subhead" },
                { label: "Data", value: "Subhead" },
                { label: "Data", value: "Subhead" }
            ]
        },
        {
            id: 3,
            heading: "When is the right time to buy a used car?",
            subHeading: "Whether you're a student, professional, or growing family, a pre-owned vehicle buying a used car can be a smart choice for different needs and life stages. It's normally 30-50% more bang for the buck in terms of total cost of ownership.",
            imageUrl: img3,
            link: '/blog-right-time-to-buy-car',
            data: [
                { label: "Data", value: "Subhead" },
                { label: "Data", value: "Subhead" },
                { label: "Data", value: "Subhead" }
            ]
        },
        {
            id: 4,
            heading: "When Not to Buy a Used Car?",
            subHeading: "When you use a car less than 5000 Km a year and plan to keep one for 10-15 years, you are better off buying a new one. Also, avoid buying a used car that you don’t feel good about at the first glance. Buying a used car should be a smart choice, not a poor compromise.",
            imageUrl: img4,
            link: '/blog-when-not-to-buy-a-used-car',
            data: [
                { label: "Data", value: "Subhead" },
                { label: "Data", value: "Subhead" },
                { label: "Data", value: "Subhead" }
            ]
        },
        {
            id: 5,
            heading: "What to look for before you buy a used car?",
            subHeading: "When buying a used car you must cover everything from exterior and interior cosmetic condition to under-the-hood technical checks and a test drive for a feel, ensuring you make a confident purchase. You can always purchase an extended warranty for peace of mind.",
            imageUrl: img5,
            link: '/blog-before-you-buy-used-car',
            data: [
                { label: "Data", value: "Subhead" },
                { label: "Data", value: "Subhead" },
                { label: "Data", value: "Subhead" }
            ]
        },
    ];

    return (
        <div className={styles.main_container1} >
                <div className={styles.mobile_heading}> Blogs</div>
            <div className={styles.container}>
                {arr.map((item, index) => (
                    <div className={styles.main_container} key={item.id}>
                        <div className={styles.left}>
                            <div className={styles.heading}>
                                {item.heading}
                            </div>
                            <div className={styles.sub_heading}>
                                {item.subHeading}
                            </div>
                            <a href={item.link}>
                                <div className={styles.button} onClick={scrollToTop}>
                                    Read More
                                </div>
                            </a>
                            {/* {item.data.map((dataItem, dataIndex) => (
                                <div className={styles.button2} key={dataIndex}>
                                    <div className={styles.data_bar}>
                                        <div className={styles.data}>
                                            {dataItem.label}
                                        </div>
                                    </div>
                                    <div className={styles.sub_head}>
                                        {dataItem.value}
                                    </div>
                                </div>
                            ))} */}
                        </div>
                        <div className={styles.right}>
                            <img
                                key={index}
                                src={item.imageUrl} alt=""
                            />
                            <div className={styles.gradient}>
                                <div className={styles.my_gradient}></div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Knowledge