import React from 'react'
import styles from './FAQ.module.css';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';

import logo from '../../../images/logos/white_logo_bg.svg'

import AccordianItem from './AccordianItem';

const FAQ = () => {
    // const arr = [
    //     {
    //         id: 1,
    //         question: "hen",
    //         answer: "hen",
    //     },
    //     {
    //         id: 2,
    //         question: "cow",
    //         answer: "cow",
    //     },
    // ]
    return (
        <>
            <div className={styles.container1}>
                <Header />

                <div className={styles.dark}>
                    <div className={styles.logo}> <img src={logo} alt="" /> </div>
                    <div className={styles.faq_section}>
                        <AccordianItem
                            title="What is the Nxcar Website, and what is its primary purpose?"
                            content="Nxcar is a used car  platform designed to facilitate used car buying process and enable dealers to offer superior customer experience, provide financing options for consumers purchasing used cars and avail other services like extended warranty and RC Transfer."
                        />
                        <AccordianItem
                            title="What is the loan application process for consumers?"
                            content="Step 1: Eligibility Check"
                            content1="• On the homepage go to check eligibility"
                            content2='• Visit the Nxcar Web App and navigate to the "Used Car Loan" section. Click on Apply now'
                            content3='• On the Calculator Page go check the eligibility'
                            content4="• On the homepage go to check eligibility"
                            content5='• Visit the Nxcar Web App and navigate to the "Used Car Loan" section. Click on Apply now'
                            content6='• On the Calculator Page go check the eligibility'

                            content7="Step 2: Start the Application"
                            content8="• Click on the Sign-up and go to consumer"
                            content9="• Signup by the number used to check eligibility"
                            content10="• Fill Income, Address, Employment and Vehicle Details."
                            content11="• Submit the form"

                            content12="Step 3: Application Processing"
                            content13="• Nxcar's team will review your application."
                            content14="• You may be contacted for additional information or clarification."

                            content15="Step 4: Loan Approval"
                            content16="• Check the status of your loan application through the customer dashboard."

                            content17="Step 5: Disbursement"
                            content18="• Once approved, follow the instructions for the disbursement process."
                            content19="• View the loan details, including the disbursement status and the status of the first three EMIs."

                            content20="Step 6:  Post-Approval Support"
                            content21="• Monitor your loan status through the customer dashboard."
                            content22="• Receive support for any inquiries or concerns through customer support channels."
                        />

                        <AccordianItem
                            title="What is the loan application process for Partners?"
                            content23="Step 1: Explore the Nxcar Website"
                            content24='• Visit the Nxcar Web App and navigate to the "Become a Partner" or "Channel Partners" section.'
                            content25='• Understand the financial products available and the benefits of becoming a partner.'

                            content26="Step 2: Inquiry Form"
                            content27="• Complete the inquiry form with your name, mobile number, email ID, and state."
                            content28='• Click on the "Submit" button.'

                            content29="Step 3: Partner Confirmation"
                            content30="• Nxcar's team will review your inquiry and contact you to confirm your partnership."
                            content31="• Receive information on accessing partner resources and the Dealers Application."

                            content32="Step 4: Loan Application Process"
                            content33="• Log in to the partner dashboard and create a unique Loan ID for each customer."
                            content34="• Upload required documents, including Aadhaar, PAN, bank statement, salary slip, ITR, and address proof of your consumers"

                            content35="Step 5: Loan Status"
                            content36="• Monitor the loan status for each Loan ID, including approval, rejection, or in-process status."

                            content37="Step 6: Disbursement and Documentation"
                            content38="• See disbursement status once the loan is approved."
                            content39="• See the status of the RC transfer and the first three EMIs."

                            content40="Step 7-Refer a consumer"
                            content41="• Go to Profile and Share your QR"

                            content42="Step 8: Post-Approval Support"
                            content43="• Use partner dashboard overall status and Payout"
                            content44="• Contact Nxcar's support for any additional assistance."

                        />

                        <AccordianItem
                            title="What services related to cars are offered by Nxcar?"
                            content="Nxcar provides services such as car insurance, extended warranty, and assistance with RC transfer. You can find more details and application forms under the 'Car Services' section."
                        />
                        <AccordianItem
                            title="How can I log in as a partner, employee, or customer?"
                            content="Partners can log in through their Registered Mobile numbers with OTP. Customers can log in with their mobile numbers used for loan eligibility checks and OTP verification. Employees will be redirected to their login"
                        />
                        <AccordianItem
                            title="What information is available on the partner and customer dashboards?"
                            content="Partner and customer dashboards display relevant information such as the number of applications sent, approval status, disbursement status, RC transfer status, and more."
                        />
                        <AccordianItem
                            title="How can I check the status of my loan application as a customer?"
                            content="After logging in, customers can view the status of their loan applications, including approval status, disbursement status, and the status of the first three EMIs under the 'Loan Application' section."
                        />
                        <AccordianItem
                            title="What are benefits of a pre approved loan?"
                            content="A preapproved used car loan based on your credit profile allows you to narrow down on the car choices. You can also drive a better deal if you have complete visibility of your loan amount."
                        />
                        <AccordianItem
                            title=" I have a car to sell before I purchase a replacement vehicle, how can Nxcar help?"
                            content="Nxcar offers a hassle-free solution for selling your car, connecting you with authorized partners in your area to secure the best deal. Our platform simplifies the listing process, ensuring your car receives maximum exposure across various online platforms. Through our extensive network of authorized dealers and buyers, we strive to obtain the most favorable offer for your vehicle. Our focus is on facilitating a straightforward and transparent transaction. With Nxcar, you can trust that your car-selling experience will be efficient and yield a competitive deal, allowing you to seamlessly transition to your next vehicle."
                        />

                        <AccordianItem
                            title="How does the extended warranty work?"
                            content="Our used car extended warranty is designed to provide you with peace of mind and comprehensive coverage beyond the standard warranty period. With our extended warranty, you can enjoy an added layer of protection against unexpected repair costs, ensuring that you drive with confidence. Our warranty plans typically cover a range of components, and we offer flexible options to suit your specific needs and budget. Whether it's engine, transmission, or other critical components, we've got you covered."
                        />
                        <AccordianItem
                            title=" My credit score is not great, how does it impact my ability to avail of a used car loan?"
                            content="If your credit score is less than ideal, we'll use your credit report to understand the reasons. If found rational, we'll consider those factors and analyze your profile. We'll then forward it to our banking partners, increasing your chances of loan approval. Additionally, we'll provide guidance on improving your credit score for better financial health."
                        />
                        <AccordianItem
                            title="Who will issue me the loan? "
                            content="Our banking partners will be responsible for issuing the loan."
                        />
                        <AccordianItem
                            title="Does checking my credit score with Nxcar affect my credit score?"
                            content="No, checking your credit score with Nxcar will not impact your credit score as it constitutes a soft inquiry."
                        />

                        <AccordianItem
                            title="How can I provide feedback or contact customer support?"
                            content="For feedback or assistance, please visit the Contact Us page, where you'll find contact information for customer support. Or you can email us at nxcar@nxcar.in or call/Whatsapp at 9355924132"
                        />


                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <Footer />
            </div>
        </>
    )
}

export default FAQ