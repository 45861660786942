import React, { useState } from 'react'
import axios from 'axios';
import styles from './Insurance.module.css'

export default function InsuranceForm() {

  const [stage, setStage] = useState(1);
  const [emptyFields, setEmptyFields] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [formData, setFormData] = useState({
    vehiclenumber: '',
    mobilenumber: ''
  })

  const validateStage1 = () => {
    return (
      formData.mobilenumber !== '' &&
      formData.vehiclenumber !== ''
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emptyFields = [];

    if (stage === 1 && !validateStage1()) {
      emptyFields.push("vehiclenumber", "mobilenumber");
    }

    else {
      if (emptyFields.length === 0) {
        try {
          const result = await axios.post(`/insurance_query`, formData);
          const url = result.data.url;
          window.location.href = url;
        } catch (error) {
          console.log('Error in handleNextStage', error);
        }
      }
    }

    setEmptyFields(emptyFields);

  };

  const handleInputChange = (e, inputName) => {
    let newValue = e.target.files ? e.target.files[0] : e.target.value;

    if (e.target.tagName === 'INPUT') {
      newValue = e.target.value;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [inputName]: newValue,
    }));
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  return (
    <>
      <div className={styles.form}>
        <div className={styles.heading}>
          Buy or Renew Car Insurance
        </div>
        <div className={styles.sub_heading}>
          Explore customizable insurance options that suit your needs and budget. Get extensive coverage
          that protects against a wide array of risks.
        </div>

        {/* FORM */}
        <form onSubmit={handleSubmit}>
          {/* VEHICLE NUMBER */}
          <>
            <div className={styles.MUI_input}>
              <input
                className={formData.vehiclenumber.length > 0 ? styles.input_field1 : styles.input_field}
                type="text"
                id='vehiclenumber'
                value={formData.vehiclenumber}
                onChange={(e) => handleInputChange(e, 'vehiclenumber')}
                pattern='^[A-Z]{2}[0-9]{1,2}(?:[A-Z])?(?:[A-Z]*)?[0-9]{4}$'
                maxLength={10}
                title='DLAE012321'
                onInput={(e) => {
                  e.target.value = e.target.value.toUpperCase();
                }}
                required
              />
              <div className={`${styles.float_label} ${formData.vehiclenumber && styles.labelActive}`}>
                Enter Vehicle Number <span style={{ color: `var(--red)` }}>*</span>
              </div>
            </div>
            {emptyFields.includes("vehiclenumber") && formData.vehiclenumber === "" && (
              <p className={styles.error}>Vehicle Number is required</p>
            )}
            {formData.vehiclenumber && !/^[A-Z]{2}[0-9]{1,2}(?:[A-Z])?(?:[A-Z]*)?[0-9]{4}$/.test(formData.vehiclenumber) && (
              <p className={styles.error}>
                Please provide a valid Indian vehicle number
              </p>
            )}
          </>
          {/* MOBILE NUMBER */}
          <>
            <div className={styles.MUI_input}>
              <div className={`${styles.phoneNumberDisplay} ${formData.mobilenumber && styles.phoneNumberActive}`}>
                +91
              </div>
              <input
                className={`${styles.input_field} ${formData.mobilenumber.length > 0 ? styles.input_field2 : ''} ${isFocused ? styles.input_field2 : ''}`}
                style={{ padding: "12px 0px" }}
                maxLength={10}
                value={formData.mobilenumber}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                id='mobilenumber'
                type="tel"
                required
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const sanitizedValue = inputValue.replace(/\D/g, '');
                  handleInputChange({ target: { value: sanitizedValue } }, 'mobilenumber');
                }}
              />
              <div className={`${styles.float_label} ${formData.mobilenumber && styles.labelActive}`}>
                {isFocused ? "Enter your mobile number" : formData.mobilenumber.length === 0 ? "+91 Enter your mobile number" : "Enter your mobile number"} <span style={{ color: `var(--red)` }}>*</span>
              </div>
            </div>
            {emptyFields.includes("mobilenumber") && (formData.mobilenumber === 0 || formData.mobilenumber === "") && (
              <p className={styles.error}> mobile is required</p>
            )}
            {formData.mobilenumber && !/^[6789]\d{9}$/.test(formData.mobilenumber) && (
              <p className={styles.error}>
                Please provide an Indian number
              </p>
            )}
          </>
          <button type='submit' className={styles.button}>
            Get a Quote
          </button>
        </form>
      </div>
    </>
  )
}