import React from 'react'
import styles from './Privacy_policy.module.css';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';


function Privacy_policy() {
    const arr = [
        {
            id: 1,
            heading: "USE OF USER'S INFORMATION:",
            sub_heading: "The Company uses the information that it collects to set up services for its Users. Users' email addresses and any personal customer information will not be distributed or shared with third parties except to the extent necessary to provide the User with a requested service, or under circumstances required by law. The Company will disclose User's personal information when we believe in good faith that any applicable law, regulation, or legal process requires it, or where the Company believes disclosure is necessary to protect or enforce its rights or the rights of another user. We also disclose aggregated User statistics in order to describe our services and reach to prospective partners, advertisers, and other third parties. However, aggregate data is not traceable to the User specifically. The Company may use contractors to help us with our operations. Some or all of these contractors may access our databases of user information. These contractors will enter into an agreement with the Company that places restrictive provisions on their use and disclosure of all information they obtain through the relationship. The Company may share personal information with global partners for the purpose of providing Users with high quality, localised service. The Company may use Non-Personal Information to track the use of the Platform and for other internal purposes, such as providing, maintaining, evaluating, and improving the Services and the Platform. If for any reason you wish to delete your Personal Information, you may delete your profile by sending an email to . Upon your request to us, the Company shall remove your Personal Information from the Service reasonably after deleting your account on Nxcar in which the company shall only an archival copy which is not accessible on the internet for legal compliance."
        },
        {
            id: 2,
            heading: "THIRD PARTY LINKS :",
            sub_heading: "Nxcar may contain links to other platforms. The Company is not responsible for the privacy practices or the content of these other platforms. Users and visitors must check the policy statement of these other platforms to understand their policies. Users and visitors who access a linked platform may be disclosing their private information. It is your responsibility to keep such information private and confident."
        },
        {
            id: 3,
            heading: "DIRECT MARKETING :",
            sub_heading: "You hereby agree that we shall use your contact details for the purpose of informing you regarding our products and services (offered by the Company and/or by third parties) which may interest you and to send to you advertisements and other marketing material, transmitted to the e-mail address you have provided."
        },
        {
            id: 4,
            heading: "THIRD PARTY LINKS, WEBSITES AND SERVICES :",
            sub_heading: "The Website/Platform/Service may include links to third party websites, advertisers, services, special offers, or other events or activities that are not controlled and maintained by us. Any link to such third party websites is not an endorsement of such website, advertisers, services, special offers, or other events or activities and you acknowledge and agree that we are not responsible for the content or availability of any such sites."
        },
        {
            id: 6,
            heading: "USER'S USE OF CONTENT :",
            sub_heading: "Except for content posted or submitted by the User or any other visitors to the Platform, all of the content available on or through the Services and/or the Platform, including without limitation, text, photographs, graphics and video and audio content, is owned by the Company and our licensors and is protected by copyright, trademark, patent, and trade secret laws, other proprietary rights, and international treaties. User acknowledges that the Services and any underlying technology or software used in connection with the Services contain the Company's proprietary information. The Company gives the User permission to use the aforementioned content for personal, non-commercial purposes only and does not transfer any intellectual property rights to the User. User may print, download, and store information from the Platform for your own convenience, but User may not distribute, republish (except as permitted in this paragraph), sell, or exploit any of the content, or exploit the Platform in whole or in part, for any commercial gain or purpose whatsoever. Except as is expressly and unambiguously provided herein, the Company does not grant the User any express or implied rights and all rights in the Platform and the Services not expressly granted by the Company to the User are retained by the Company."
        },
        {
            id: 7,
            heading: "DISCLAIMER OF WARRANTIES :",
            sub_heading: "The Company provides the Platform and the services `as is`, `with all faults and` `as available.` The Company makes no express or implied warranties or guarantees about the services. To the maximum extent permitted by law, the Company disclaims all such warranties, including all statutory warranties, with respect to the services and the Platform, including without limitation any warranties that the services are merchantable, of satisfactory quality, accurate, fit for a particular purpose or need, or non-infringing. The Company does not guarantee that the results that may be obtained from the use of the services will be effective, reliable or accurate or will meet your requirements. We incorporate commercially reasonable safeguards to help protect and secure your Personal and Financial Information. However, no data transmission over the Internet, wireless transmission or electronic storage of information can be guaranteed to be 100% secure. Please note that we cannot ensure the security of any information you transmit to us you provide us with your information at your own risk."
        },
        {
            id: 8,
            heading: "LIMITATION OF LIABILITY :",
            sub_heading: "User's sole and exclusive remedy for any dispute with the company is the cancellation of User's registration. In no event shall the Company's total cumulative liability to the User for any and all claims relating to or arising out of User's use of the services or the Platform, regardless of the form of action, exceed the greater of : (a) the total amount of fees, if any, that you paid us to utilize the Platform or the services or (b) Rs.1,000/-. The Company shall in no event be liable to the User (or to any third party claiming under or through the User) for any indirect, special, incidental, consequential or exemplary damages arising from User's use of, or inability to use, the Platform and/or the services. These exclusions apply to any claims for lost profits, lost data, loss of goodwill, work stoppage, computer failure or malfunction, or any other commercial damages or losses, even if the Company knew or should have known of the possibility of such damages, because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the extent permitted by law."
        },
        {
            id: 9,
            heading: "MINOR USERS :",
            sub_heading: "The Company does not intend to and will not purposely collect, require or request personal information from persons under 18 years of age without permission from parent or legal guardian. If the User is a minor, he/she should not enter his/her personal information or use the Platform in any way prior to obtaining the consent of the parent or legal guardian."
        },
        {
            id: 10,
            heading: "CHANGES TO THESE RULES :",
            sub_heading: "The Company reserves the right to change rules of Privacy Policy at any time without notice. In case of a substantial change of confidentiality rules and policies of use of the User's personal information, the Company shall publish the changes on the Platform. The User is advised to refer to the text of this page at each visit to the Platform.."
        },
        {
            id: 11,
            heading: "GOVERNING LAW & JURISDICTION :",
            sub_heading: "For all matters involving customers of Propsera Services Private Limited , this document shall be governed by, construed and enforced under the laws of India and Courts in Delhi shall have exclusive jurisdiction."
        },

    ]
    return (
        <>
            <div className={styles.container1}>
                <Header />
                <div className={styles.dark}>
                    <div className={styles.container}>
                        <div className={styles.main_heading}>
                            Privacy Policy
                        </div>
                        <div className={styles.sub_heading}>
                            a. The Company requires Users who register to use its services ("Services") offered on the Nxcar Platform ("Platform") (www.Nxcar.in) to give contact information, such as their personal and communication details like (name, company name, address, phone number, and email address, etc.)
                            <br /><br /><br />
                            b. In order to transact using the Platform and we collect Financial and KYC information, that may be from registration or other completed forms/questionnaires/document that you provide to us and Aadhar, Pan, credit report 26AS, TDS, ITR, Form 16, etc that you authorise us to obtain on your behalf from Authorities, Credit Information Companies, etc. All such use of information by the Company shall be in compliance with law.
                            <br /><br /><br />
                        </div>


                        <div className={styles.main_heading}>
                            Terms & Conditions
                        </div>
                        <div className={styles.heading}>
                            NON-PERSONAL INFORMATION :
                        </div>
                        <div className={styles.sub_heading}>
                            a. The Company also collects non-personal information from you, such as your browser type, operating system, and your Internet protocol (IP) Address ("Non-Personal Information"). Non-Personal Information cannot be easily used to personally identify the User.
                            <br /><br />
                            b. Cookies are very small text files that are stored on your computer when you visit some websites. We use cookies to help identify your computer so we can tailor your user experience, management of the signup process and user management, general administration including tracking login and usage, managing newsletter or email subscription/surveys, shopping and payment facilities. In some special cases we also use cookies provided by trusted third parties for Analytics, advertising, social media, you can disable any cookies already stored on your computer, but these may stop our Platform from functioning properly.
                            <br /><br />
                            c. In general, you can visit www.Nxcar.in without telling us who you are or revealing any information about yourself. However, our web servers automatically collect the domain names/IP Address of visitors. This information is used to measure the number of visits, average time spent on the site, pages viewed and similar information, which is used to measure our services, evaluate performance and improve our content/services.
                            <br />
                        </div>
                        {arr.map((item) => (
                            <div className={styles.array_container}>
                                <div className={styles.heading}>
                                    {item.heading}
                                </div>
                                <div className={styles.sub_heading}>
                                    {item.sub_heading}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <Footer />
            </div>
        </>
    )
}

export default Privacy_policy
