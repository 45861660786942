import { useEffect, useState } from 'react';
import styles from './WhyUseCars.module.css'
import { Box, Modal } from '@mui/material';
import NewLoan from '../../../reusable/loanEligibility/NewLoanEligibility/NewLoan';
import img1 from '../../../images/vectors/used_car/category/car_purchase_2.svg'
import img2 from '../../../images/vectors/used_car/category/refiance.svg'
import img3 from '../../../images/vectors/used_car/category/balance.svg'

const styleMUIModal = {
    position: "absolute",
    top: "0%",
    right: "0%",
    width: '40%',
    height: '100%',
    bgcolor: `white`,
    border: "0px solid #000",
    boxShadow: 24,
    outline: "none",
    overflowY: "auto",
};
const styleMUIModalMobile = {
    width: "100%"
}

const WhyUseCars = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const handleSlideChange = (index) => {
        setCurrentSlide(index);
    };

    const [readMore, setReadMore] = useState(false);
    const [readMore2, setReadMore2] = useState(false);
    const [readMore3, setReadMore3] = useState(false);
    const [open, setOpen] = useState(false);

    const showContent = () => {
        setReadMore(!readMore);
    }
    const showContent2 = () => {
        setReadMore2(!readMore2);
    }
    const showContent3 = () => {
        setReadMore3(!readMore3);
    }
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };



    useEffect(() => {
        const handleScroll = () => {
            const container = document.querySelector(`.${styles.container}`);
            const scrollPosition = container.scrollTop;
            const containerHeight = container.clientHeight;
            const totalScrollHeight = container.scrollHeight;
            const currentSlideIndex = Math.floor((scrollPosition + containerHeight / 2) / containerHeight);
            setCurrentSlide(currentSlideIndex);
        };

        const container = document.querySelector(`.${styles.container}`);
        container.addEventListener('scroll', handleScroll);

        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
    }, [currentSlide]);

    return (
        <div className={styles.box}>
            <div className={styles.container}>

                <div className={styles.main_container}>
                    <div className={styles.right}>
                        <img src={img1} alt="" />
                        <div className={styles.my_gradient}></div>
                    </div>
                    <div className={styles.left}>
                        <div className={styles.heading}>
                            Why you should go for used car loan ?
                        </div>
                        <div className={styles.content}>
                            Used car loans help you buy second-hand cars affordably with the flexibility to pay over time and avoid big upfront costs. With Nxcar, you can get a flexible loan whether you're buying from an individual, online, a dealer, or an aggregator. Our loans are designed to meet different needs, making it easy and confident for you to get the car you want, no matter where you find it.
                            <br />
                            <br />
                            Here’s how we do it: <br />
                            We work with leading banks and NBFCs to arrange used car loans that best suit your requirements and credit profile. We DO NOT charge any fees for arranging the loan, as we receive service fees from the lenders

                            {
                                readMore && (
                                    <ol>
                                        <li>
                                            Nxcar checks your loan eligibility and shares offers from different lenders. Once you select an offer, we will initiate the loan process. You can review the required documents and keep them ready.
                                        </li>
                                        <li>
                                            We will coordinate the vehicle valuation by the bank or NBFC issuing you the loan.
                                        </li>
                                        <li>
                                            We'll share the list of documents to be collected from the seller and the transfer forms to be filled out by the seller.
                                        </li>
                                        <li>
                                            Nxcar will assist in organizing the NOC from the seller's bank if there is an existing loan hypothecation, and also arrange the NOC from the RTO for title transfer.
                                        </li>
                                        <li>
                                            We'll arrange loan disbursement to your or the seller's account.
                                        </li>
                                        <li>
                                            We'll handle the RC and insurance transfer as required by the lender
                                        </li>
                                    </ol>
                                )
                            }
                            <span className={styles.read_more} onClick={showContent}>
                                {readMore ? ' ...Read Less' : ' ...Read More'}
                            </span>
                        </div>
                        {/* <div className={styles.cta} onClick={handleOpen}>
                            Check Offers Now
                        </div> */}
                    </div>
                </div>
                <div className={styles.main_container}>
                    <div className={styles.right}>
                        <img src={img2} alt="" />
                        <div className={styles.my_gradient}></div>
                    </div>
                    <div className={styles.left}>
                        <div className={styles.heading}>
                            When should you Refinance?
                        </div>
                        <div className={styles.content}>
                            Consider refinancing your car loan if you have any of these objectives in mind: seeking lower interest rates to save on overall costs, aiming to decrease your monthly EMIs to manage budget, or desiring to adjust the loan duration to align with your financial condition. Refinancing offers the flexibility to tailor your loan terms according to your evolving financial needs and preferences.
                            <br />
                            <br />
                            Here’s how we do it: <br />

                            {
                                readMore2 && (
                                    <ol>
                                        <li>
                                            We research and compare various lenders to find the best refinancing option for your needs.
                                        </li>
                                        <li>
                                            We check your credit score and report to ensure you qualify for the best loan terms.
                                        </li>
                                        <li>
                                            We coordinate with your selected lender for all necessary documents, including current loan details and identification.
                                        </li>
                                        <li>
                                            We assist you in submitting a refinancing application with our selected lenders.
                                        </li>
                                        <li>
                                            Once approved, your new lender will pay off your existing car loan directly with the previous lender.
                                        </li>
                                        <li>
                                            You begin making payments on the new loan according to the agreed-upon terms.
                                        </li>
                                    </ol>
                                )
                            }
                            <span className={styles.read_more} onClick={showContent2}>
                                {readMore2 ? '...Read Less' : '...Read More'}
                            </span>
                        </div>
                        {/* <div className={styles.cta} onClick={handleOpen}>
                            Check Offers Now
                        </div> */}
                    </div>
                </div>
                <div className={styles.main_container}>
                    <div className={styles.right}>
                        <img src={img3} alt="" />
                        <div className={styles.my_gradient}></div>
                    </div>
                    <div className={styles.left}>
                        <div className={styles.heading}>
                            When should you opt for Balance Transfer Top Up ?
                        </div>
                        <div className={styles.content}>
                            Consider opting for a Balance Transfer (BT) top-up when you need more money and are okay using your car as security. This way, you can get extra funds by using your car's value to secure a loan, helping you manage your urgent financial needs more easily.
                            <br />
                            <br />
                            Here’s how we do it: <br />

                            {
                                readMore3 && (
                                    <ol>
                                        <li>
                                            We assess your financial needs and determine if a BT top-up is the right option for you.
                                        </li>
                                        <li>
                                            Our experts consult with you to understand your requirements and preferences.
                                        </li>
                                        <li>
                                            We help you choose from our network of lenders offering competitive terms for BT top-ups.
                                        </li>
                                        <li>
                                            We assist you in completing the application process, ensuring all necessary documents are submitted.
                                        </li>
                                        <li>
                                            We manage the approval process and negotiate terms with lenders on your behalf.
                                        </li>
                                        <li>
                                            We ensure you understand the repayment terms and help you stay on track with your new loan.
                                        </li>
                                    </ol>
                                )
                            }
                            <span className={styles.read_more} onClick={showContent3}>
                                {readMore3 ? '...Read Less' : '...Read More'}
                            </span>
                        </div>
                        {/* <div className={styles.cta} onClick={handleOpen}>
                                Check Offers Now
                            </div> */}
                    </div>
                </div>

            </div>

            <div className={styles.left_indicator}>
                <div
                    className={`${styles.indicator_dot} ${currentSlide === 0 ? styles.active : ''}`}
                    onClick={() => handleSlideChange(0)}
                >
                    0{currentSlide + 1}
                </div>
                <div className={styles.main_line}>
                    <div
                        className={styles.line}
                        style={{
                            background: 'linear-gradient(to bottom, #ffffff, teal)',
                            height: `${(currentSlide + 1) * 33}%`
                        }}
                    ></div>
                </div>
                <div
                    className={`${styles.indicator_dot} ${currentSlide === 0 ? styles.active : ''}`}
                >
                    03
                </div>
            </div>


            {/* MODAL */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
            >
                <Box
                    sx={{ ...styleMUIModal, ...(window.innerWidth <= 600 && styleMUIModalMobile) }}>
                    <NewLoan
                        handleClose={handleClose}
                    />
                </Box>
            </Modal>
            {/* MODAL */}
        </div>
    )
}

export default WhyUseCars