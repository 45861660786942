import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';
import styles from './HeroLoanForm.module.css';
import { IoClose } from 'react-icons/io5';
import { Modal } from '@material-ui/core';
import NewLoan from '../../../reusable/loanEligibility/NewLoanEligibility/NewLoan';
import { useMediaQuery } from '@material-ui/core';
import dayjs from 'dayjs';
import green_tick from '../../../images/forms/green_tick.svg'
import { Box } from '@mui/material';
import thank_you from '../../../images/vectors/landing/thankyou.svg'
import { Link } from 'react-router-dom';
import Header from '../../../components/Header/Header';
import { scrollToTop } from '../../scrollToTop/ScrollToTopButton';

export default function HeroLoanForm({ firstInputRef }) {

    const [stage, setStage] = useState(1);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [emptyFields, setEmptyFields] = useState([]);
    const [isFocused, setIsFocused] = useState(false);
    const [showLoanComponent, setShowLoanComponent] = useState(false);
    const [consentChecked, setConsentChecked] = useState(false);
    const [otp, setOtp] = useState('');
    const [isTimeExpired, setIsTimeExpired] = useState(false);
    const [isOtpValid, setIsOtpValid] = useState(true);
    const [seconds, setSeconds] = useState(120);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [invalidCoupon, setInvalidCoupon] = useState(true);
    const isMobile = useMediaQuery('(max-width: 940px)');


    const url = 'https://ipapi.co/json/'

    useEffect(() => {
        let interval
        if (stage == 3) {
            interval = setInterval(() => {
                setSeconds((prevSeconds) => {
                    if (prevSeconds === 1) {
                        setIsTimeExpired(true);
                        clearInterval(interval);
                        return 0;
                    }
                    return prevSeconds - 1;
                });
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [stage]);
    // THE FORMATTED DATE FOR THE DATE AND TIME
    const formattedTime = () => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        return `${hours < 10 ? '0' : ''}${hours} : ${minutes < 10 ? '0' : ''}${minutes} : 
    ${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}s`;
    };

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const handleInputFocus = () => {
        setIsFocused(true);
    };

    const handleInputBlur = () => {
        setIsFocused(false);
    };

    const isValidPincode = (pincode) => {
        const pincodeRegex = /^[1-9][0-9]{5}$/;
        return pincodeRegex.test(pincode);
    };

    const [user_ip, setUser_ip] = useState('');

    const getIP = async () => {
        try {
            const res = await axios.get(url);
            const IP_Data = res.data.ip;
            setUser_ip(IP_Data);
            setFormData(prevState => ({
                ...prevState,
                ip: IP_Data
            }));
        } catch (error) {
            console.error("Error fetching IP:", error);
        }
    }
    useEffect(() => {
        getIP();
    }, [])

    let couponCode = '';
    let currentURL = window.location.href;
    const pageUrl1 = currentURL?.split('/');
    const pageUrl2 = pageUrl1[3]?.split('?')[1];
    const pageUrl3 = pageUrl2?.split('&')[1];
    const pageUrl4 = pageUrl3?.split('=')[1];
    const pageUrl5 = pageUrl4?.split('+')[0];

    const paramsArray = pageUrl2?.split('&');
    let utmSource;
    paramsArray?.forEach(param => {
        if (param.startsWith('utm_source=')) {
            utmSource = param.split('=')[1];
            localStorage.setItem('utmSource', utmSource)
        }
    });

    if (pageUrl5 == 'extended') {
        couponCode = 'EXTW2024'
    }
    else if (pageUrl5 == 'insurance') {
        couponCode = 'INSUR2024'
    } else {
        couponCode = ''
    }

    const current_url = window.location.href;

    let dealer_id;
    if (current_url.includes("dealerid")) {
        dealer_id = current_url.split('=')[1];
    } else {
        console.log("URL does not");
    }

    const localUtm = localStorage.getItem('utmSource');

    const [formData, setFormData] = useState({
        // Marketing Purpose 
        ip: user_ip,
        utm_source: localUtm || 'Website',
        utm_medium: dealer_id ? 'Scanner' : 'Website',

        type: "",
        time: "",
        page: "Landing-Page",

        // Visible 
        consumer_id: dealer_id || '',
        consent: '0',
        full_name: '',
        mobile: '',
        email: '',
        pincode: '',
        pan: "",
        existing_emi: "0",
        monthly_income: "",
        vehicle_no: "",
        loan_amount: "",
        inspection_date: null,
        address: "",
        coupon: couponCode,
        services: {
            loan: localStorage.getItem('used_car_loan'),
            inspection: localStorage.getItem('inspection'),
            docs_verification: localStorage.getItem('document_verification'),
            Extended_warranty: localStorage.getItem('extended_warranty'),
            insurence_transfer: localStorage.getItem('insurance_transfer'),
            rc_transfer: localStorage.getItem('rc_transfer'),
            car_insurance: localStorage.getItem('car_insurance')
        },
    });

    const validateStage1 = () => {
        return (
            formData.full_name !== '' &&
            /^[6789]\d{9}$/.test(formData.mobile) &&
            formData.mobile !== '' &&
            formData.email !== ''
        );
    };

    const validateStage2 = () => {
        return (
            formData.pincode !== '' &&
            formData.consent == 1
        );
    };

    const handleNextStage = async (e) => {
        e.preventDefault();
        const emptyFields = [];

        if (stage === 1 && !validateStage1()) {
            emptyFields.push("full_name", "mobile", "email");

        }
        if (stage === 2 && !validateStage2()) {
            emptyFields.push("pincode", "consent");
        }

        setEmptyFields(emptyFields);

        if (emptyFields.length === 0) {
            try {
                setStage(stage + 1);
                scrollToTop();
            } catch (error) {
                console.log('Error in handleSubmit', error);
            }
        }

        if (stage === 2) {
            if (emptyFields.length === 0) {
                try {
                    const result = await axios.post(`/leads`, formData);
                    localStorage.setItem('OTP_login_id', result.data.login_id)
                    setStage(stage + 1);
                    scrollToTop();
                } catch (error) {
                    console.log('Error in handleSubmit', error);
                }
            }
        }
    };

    const resendOTP = async () => {
        try {
            const response = await axios.post(`/resend_otp`, {
                mobile: formData.mobile,
                signature: "Team Nxcar",
                login_id: localStorage.getItem('OTP_login_id')
            });
            if (response.status === 200) {
                toast.dismiss();
                toast.success('OTP sent');
                scrollToTop();
            } else {
                toast.dismiss();
                toast.error('error OTP sent');
                setIsValid(false);
            }
        } catch (error) {
            console.error('Error during login API call:', error);
        }
    };

    const handleOTPChange = (e) => {
        const newValue = e.target.value;
        setOtp(newValue);
    }

    if (stage === 3) {
        window.history.pushState(null, null, '/used-car-loan-special-offer/otp-verify');
    }

    const handleOTPVerification = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`/otpverify`, {
                mobile: formData.mobile,
                otp: otp,
                login_id: localStorage.getItem('OTP_login_id')
            });
            window.history.pushState({}, '', '/used-car-loan-special-offer/thank-you');
            localStorage.removeItem('utmSource');
            if (response.data.status === true) {
                setIsFormSubmitted(true);
                setStage(1);
                setFormData({
                    full_name: '',
                    mobile: '',
                    email: '',
                    pincode: '',
                    pan: "",
                    monthly_income: "",
                    vehicle_no: "",
                    loan_amount: "",
                    inspection_date: null,
                    address: "",
                })
            } else {
                toast.error('Wrong OTP');
                toast.dismiss();
                setIsOtpValid(false);
            }
        } catch (error) {
            toast.error('Wrong OTP');
            toast.dismiss();
            setIsOtpValid(false);
            setOtp('');
        }
    };

    const handleInputChange = (e, inputName) => {
        let newValue;
        if (e.$d) {
            newValue = e.$d;
        } else {
            newValue = e.target.value;
        }

        if (
            inputName === 'coupon' &&
            !["INSUR2024", "EXTW2024", "EXTWNAME", "EXTWNIRBHAY", "EXTWSHIV", "EXTWNAMASTE"].includes(newValue)
        ) {
            setInvalidCoupon(true);
        } else {
            setInvalidCoupon(false);
        }

        setFormData((prevFormData) => ({
            ...prevFormData,
            [inputName]: newValue,
        }));
    };

    const handleClose = () => {
        setIsFormSubmitted(false);
    };

    const handleCheckBox = (e) => {
        const checked = e.target.checked;
        setConsentChecked(checked);
        setFormData((prevData) => ({
            ...prevData,
            consent: checked ? "1" : "0",
        }));
    }

    const currentDate = dayjs();
    const minDate = currentDate.add(1, 'day');

    const isValidPAN = (pan) => {
        const panRegex = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;

        return panRegex.test(pan.toUpperCase());
    }

    const toggleCheckbox = (service) => {
        const newValue = !formData.services[service];
        localStorage.setItem(service, JSON.stringify(newValue));
        setFormData((prevFormData) => ({
            ...prevFormData,
            services: {
                ...prevFormData.services,
                [service]: newValue
            }
        }));
    };



    return (
        <>
            {showLoanComponent ? (
                <NewLoan handleClose={handleClose} />
            ) : (
                <div className={styles.container}>
                    {
                        isFormSubmitted ? (
                            <Modal
                                open={isFormSubmitted}
                                onClose={handleClose}
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                            >
                                <Box>
                                    <Header />
                                    <div className={styles.thank_you_image}>
                                        <div
                                            onClick={handleClose}
                                            className={styles.close_icon}>
                                            <IoClose />
                                        </div>
                                        <div className={styles.t_left}>
                                            <img src={thank_you} alt="thank-you" />
                                        </div>
                                        <div className={styles.t_right}>
                                            <div className={styles.thank_you_text}>
                                                Thank You
                                            </div>
                                            <div className={styles.thank_you_line}>
                                                Thank you for choosing us.
                                                <br />
                                                Our team will connect with you shortly.
                                            </div>
                                            <Link to='/'>
                                                <div className={styles.thank_you_button}>
                                                    Go Back To Nxcar.in
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </Box>
                            </Modal>
                        ) : (
                            <div className={styles.form_stage}>
                                <form onSubmit={handleOTPVerification}>
                                    {stage === 1 && (
                                        <>
                                            {/* WELCOME TEXT */}
                                            <div className={styles.heading_section}>
                                                <div className={styles.welcome_text}>
                                                    <div className={styles.welcome_text1}>
                                                        Loan Application Form
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={styles.form}>
                                                <div className={styles.col}>
                                                    <>
                                                        {/* FULL NAME */}
                                                        <>
                                                            <div className={styles.MUI_input}>
                                                                <input
                                                                    ref={firstInputRef}
                                                                    className={formData.full_name.length > 0 ? styles.input_field1 : styles.input_field}
                                                                    type="text"
                                                                    value={formData.full_name}
                                                                    onChange={(e) => handleInputChange(e, 'full_name')}
                                                                    required
                                                                    autoComplete="off"
                                                                    id='name'
                                                                    onKeyDown={(e) => {
                                                                        const keyPressed = e.key;
                                                                        const isValidInput = /^[A-Za-z\s]*$/.test(keyPressed);
                                                                        const isNavigationKey = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete', 'Backspace'].includes(keyPressed);
                                                                        const isCtrlKey = e.ctrlKey || e.metaKey;
                                                                        const isCopyPaste = isCtrlKey && ['a', 'c', 'v'].includes(keyPressed.toLowerCase());
                                                                        if (!isValidInput && !isNavigationKey && !isCopyPaste) {
                                                                            e.preventDefault();
                                                                        }
                                                                    }}
                                                                />
                                                                <div className={`${styles.float_label} ${formData.full_name && styles.labelActive}`}>
                                                                    Enter Full Name <span style={{ color: `var(--red)` }}>*</span>
                                                                </div>
                                                            </div>
                                                            {emptyFields.includes("full_name") && formData.full_name === "" && (
                                                                <p className={styles.error}>Full Name is required</p>
                                                            )}
                                                        </>

                                                        {/* MOBILE NUMBER */}
                                                        <>
                                                            <div className={styles.MUI_input}>
                                                                <div className={`${styles.phoneNumberDisplay} ${formData.mobile && styles.phoneNumberActive}`}>
                                                                    +91
                                                                </div>
                                                                <input
                                                                    className={`${styles.input_field} ${formData.mobile.length > 0 ? styles.input_field2 : ''} ${isFocused ? styles.input_field2 : ''}`}
                                                                    type="tel"
                                                                    maxLength={10}
                                                                    value={formData.mobile}
                                                                    onFocus={handleInputFocus}
                                                                    onBlur={handleInputBlur}
                                                                    id='phone_number'
                                                                    autoComplete="off"
                                                                    style={{ padding: "12px 0px" }}
                                                                    required
                                                                    onChange={(e) => {
                                                                        const inputValue = e.target.value;
                                                                        const sanitizedValue = inputValue.replace(/\D/g, '');
                                                                        handleInputChange({ target: { value: sanitizedValue } }, 'mobile');
                                                                    }}
                                                                />
                                                                <div className={`${styles.float_label} ${formData.mobile && styles.labelActive}`}>
                                                                    {isFocused ? "Enter your mobile number" : formData.mobile.length === 0 ? "+91 Enter your mobile number" : "Enter your mobile number"} <span style={{ color: `var(--red)` }}>*</span>
                                                                </div>
                                                            </div>
                                                            {emptyFields.includes("mobile") && (formData.mobile === 0 || formData.mobile === "") && (
                                                                <p className={styles.error}> Mobile is required</p>
                                                            )}
                                                            {formData.mobile && !/^[6789]\d{9}$/.test(formData.mobile) && (
                                                                <p className={styles.error}>
                                                                    Please provide an Indian number
                                                                </p>
                                                            )}
                                                        </>

                                                        {/* EMAIL..  */}
                                                        <>
                                                            <div className={styles.MUI_input}>
                                                                <input
                                                                    className={formData.email.length > 0 ? styles.input_field1 : styles.input_field}
                                                                    type="email"
                                                                    value={formData.email}
                                                                    onChange={(e) => handleInputChange(e, 'email')}
                                                                    id='email'
                                                                    autoComplete="off"
                                                                    required
                                                                />
                                                                <div className={`${styles.float_label} ${formData.email && styles.labelActive}`}>
                                                                    Enter Email<span style={{ color: `var(--red)` }}> *</span>
                                                                </div>
                                                            </div>
                                                            {emptyFields.includes("email") && formData.email === "" && (
                                                                <p className={styles.error}>Email is required</p>
                                                            )}
                                                            {formData.email && !(emailRegex).test(formData.email) && (
                                                                <p className={styles.error}>
                                                                    Please provide a valid email
                                                                </p>
                                                            )}
                                                        </>

                                                        {/* VEHICLE NUMBER */}
                                                        <>
                                                            <div className={styles.MUI_input}>
                                                                <input
                                                                    className={formData.vehicle_no.length > 0 ? styles.input_field1 : styles.input_field}
                                                                    type="text"
                                                                    value={formData.vehicle_no}
                                                                    onChange={(e) => handleInputChange(e, 'vehicle_no')}
                                                                    pattern='^[A-Z]{2}[0-9]{1,2}(?:[A-Z])?(?:[A-Z]*)?[0-9]{4}$'
                                                                    maxLength={10}
                                                                    autoComplete="off"
                                                                    title='FORMAT DL AE 01 2321'
                                                                    onInput={(e) => {
                                                                        e.target.value = e.target.value.toUpperCase();
                                                                    }}
                                                                    required
                                                                />
                                                                <div className={`${styles.float_label} ${formData.vehicle_no && styles.labelActive}`}>
                                                                    Enter Vehicle Number (If Available)<span style={{ color: `var(--red)` }}></span>
                                                                </div>
                                                            </div>
                                                            {formData.vehicle_no && !/^[A-Z]{2}[0-9]{1,2}(?:[A-Z])?(?:[A-Z]*)?[0-9]{4}$/.test(formData.vehicle_no) && (
                                                                <p className={styles.error}>
                                                                    Please provide a valid Indian vehicle number
                                                                </p>
                                                            )}
                                                        </>

                                                        <>
                                                            {/* LOAN AMOUNT  */}
                                                            <div className={styles.MUI_input}>
                                                                <input
                                                                    className={formData.loan_amount.length > 0 ? styles.input_field1 : styles.input_field}
                                                                    type="tel"
                                                                    maxLength={10}
                                                                    value={formData.loan_amount}
                                                                    id='loan_amount'
                                                                    required
                                                                    autoComplete="off"
                                                                    onChange={(e) => {
                                                                        const inputValue = e.target.value;
                                                                        const sanitizedValue = inputValue.replace(/\D/g, '');
                                                                        handleInputChange({ target: { value: sanitizedValue } }, 'loan_amount');
                                                                    }}
                                                                />
                                                                <div className={`${styles.float_label} ${formData.loan_amount && styles.labelActive}`}>
                                                                    Enter Loan Amount <span style={{ color: `var(--red)` }}></span>
                                                                </div>
                                                            </div>
                                                            {emptyFields.includes("loan_amount") && formData.loan_amount === "" && (
                                                                <p className={styles.error}>Enter Loan is required</p>
                                                            )}
                                                        </>

                                                        {/* ADD CAR SERVICES */}
                                                        <>
                                                            <div className={styles.sub_heading}>
                                                                Add Car Services
                                                            </div>
                                                            <div className={styles.flex}>
                                                                <div className={styles.consent_left}>
                                                                    <input
                                                                        type="checkbox"
                                                                        // checked={formData.services.car_insurance === 'true'}
                                                                        onChange={() => toggleCheckbox('car_insurance')}
                                                                        required
                                                                    />
                                                                </div>
                                                                <div className={styles.consent_right}>
                                                                    Insurance
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex}>
                                                                <div className={styles.consent_left}>
                                                                    <input
                                                                        type="checkbox"
                                                                        // checked={formData.services.Extended_warranty == true}
                                                                        onChange={() => toggleCheckbox('extended_warranty')}
                                                                        required
                                                                    />
                                                                </div>
                                                                <div className={styles.consent_right}>
                                                                    Extended Warranty
                                                                </div>
                                                            </div>
                                                        </>
                                                    </>
                                                </div>
                                            </div>
                                            <div className={styles.final_button_container1}>
                                                <button
                                                    className={styles.submit_button1}
                                                    onClick={handleNextStage}
                                                >
                                                    Next
                                                </button>
                                            </div>
                                        </>
                                    )}

                                    {stage === 2 && (
                                        <div className={styles.form}>
                                            <div className={styles.col}>
                                                {/* WELCOME TEXT */}
                                                <div className={styles.heading_section}>
                                                    <div className={styles.welcome_text}>
                                                        <div className={styles.welcome_text1}>
                                                            Loan Application Form
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* PAN  */}
                                                <>
                                                    <div className={styles.MUI_input}>
                                                        <input
                                                            className={formData.pan.length > 0 ? styles.input_field1 : styles.input_field}
                                                            type="text"
                                                            maxLength={10}
                                                            autoComplete="off"
                                                            value={formData.pan.toUpperCase()}
                                                            onChange={(e) => handleInputChange(e, 'pan')}
                                                            required
                                                        />
                                                        <div className={`${styles.float_label} ${formData.pan && styles.labelActive}`}>
                                                            Enter PAN <span style={{ color: "#CD1C1C" }}></span>
                                                        </div>
                                                    </div>
                                                    {
                                                        !isValidPAN(formData.pan) && (
                                                            formData?.pan?.length > 0 ? <p className={styles.error_pan}>
                                                                Invalid PAN Card Number
                                                            </p> : null
                                                        )
                                                    }
                                                    <div className={styles.new_tagline}>
                                                        Required for pre approved Loan offers
                                                    </div>

                                                </>

                                                {/* INCOME */}
                                                <>
                                                    <div className={styles.MUI_input}>
                                                        <input
                                                            className={formData.monthly_income.length > 0 ? styles.input_field1 : styles.input_field}
                                                            type="tel"
                                                            maxLength={15}
                                                            value={formData.monthly_income}
                                                            autoComplete="off"
                                                            required
                                                            onChange={(e) => {
                                                                const inputValue = e.target.value;
                                                                const sanitizedValue = inputValue.replace(/\D/g, '');
                                                                handleInputChange({ target: { value: sanitizedValue } }, 'monthly_income');
                                                            }}
                                                        />
                                                        <div className={`${styles.float_label} ${formData.monthly_income && styles.labelActive}`}>
                                                            Enter Monthly Income<span style={{ color: "#CD1C1C" }}></span>
                                                        </div>
                                                    </div>
                                                    <div className={styles.new_tagline}>
                                                        Required for pre approved Loan offers
                                                    </div>
                                                </>



                                                {/* PINCODE */}
                                                <>
                                                    <div className={styles.MUI_input}>
                                                        <input
                                                            className={formData.pincode.length > 0 ? styles.input_field1 : styles.input_field}
                                                            type="tel"
                                                            maxLength={6}
                                                            minLength={6}
                                                            value={formData.pincode}
                                                            autoComplete="off"
                                                            required
                                                            onChange={(e) => {
                                                                const inputValue = e.target.value;
                                                                const sanitizedValue = inputValue.replace(/\D/g, '');
                                                                handleInputChange({ target: { value: sanitizedValue } }, 'pincode');
                                                            }}
                                                        />
                                                        <div className={`${styles.float_label} ${formData.pincode && styles.labelActive}`}
                                                        >
                                                            Enter PIN Code <span style={{ color: `var(--red)` }}>*</span>
                                                        </div>
                                                    </div>
                                                    {emptyFields.includes("pincode") && formData.pincode.length < 6 && (
                                                        <p className={styles.error}>Pincode is required</p>
                                                    )}

                                                </>

                                                {/* COUPON CODE */}
                                                <div className={styles.MUI_input}>
                                                    <input
                                                        style={{
                                                            borderRadius: invalidCoupon ? '4px' : '4px 0 0 4px',
                                                            borderRight: invalidCoupon ? '1px solid transparent' : 'none',
                                                        }}
                                                        className={formData.coupon.length > 0 ? styles.input_field1 : styles.input_field}
                                                        type="text"
                                                        maxLength={15}
                                                        minLength={5}
                                                        value={formData.coupon.toUpperCase()}
                                                        required
                                                        autoComplete="off"
                                                        onChange={(e) => handleInputChange(e, 'coupon')}
                                                    />
                                                    {
                                                        formData.coupon.length > 0 ? (<>
                                                            {
                                                                invalidCoupon ? (
                                                                    <div className={styles.invalid_emoji}>
                                                                        !
                                                                    </div>
                                                                ) : (
                                                                    <div className={styles.valid_emoji}>
                                                                        <img src={green_tick} alt="green_tick" />
                                                                    </div>
                                                                )
                                                            }
                                                        </>) : (null)
                                                    }
                                                    <div className={`${styles.float_label} ${formData.coupon && styles.labelActive}`}
                                                    >
                                                        Enter Coupon Code
                                                    </div>
                                                </div>
                                                {
                                                    formData.coupon.length > 1 ?
                                                        (
                                                            <>
                                                                {
                                                                    invalidCoupon ? (
                                                                        <div
                                                                            style={{ color: `var(--red)` }}
                                                                            className={styles.new_tagline}>
                                                                            Invalid  Coupon Code
                                                                        </div>
                                                                    ) : (
                                                                        <div className={styles.new_tagline}>
                                                                            {formData.coupon == 'EXTW2024' ?
                                                                                'You Will Get Free Extended Warranty Worth ₹5999.'
                                                                                : '1 Year Comprehensive Insurance in just Rs 999'}
                                                                        </div>
                                                                    )
                                                                }
                                                            </>
                                                        ) : (null)
                                                }

                                                <div className={styles.consent_box}>
                                                    <div className={styles.consent_left}>
                                                        <input
                                                            type="checkbox"
                                                            required
                                                            onClick={handleCheckBox}
                                                        />
                                                    </div>
                                                    {
                                                        <>
                                                            <div className={styles.consent_right}>
                                                                I agree that I have read, understood, and accepted the <span style={{ color: 'teal', textDecoration: 'underline' }}> <a href="https://nxcar.in/privacy-policy" target='_blank'>Privacy Policy</a>, <a href="https://nxcar.in/terms-of-use" target="_blank"> & Terms of Use</a> and Consent Declaration</span> and hereby appoint Nxcar as my authorized representative to receive my credit information from any certified Credit Information Companies
                                                            </div>
                                                        </>
                                                    }

                                                </div>
                                                {emptyFields.includes("consent") && formData.consent === '0' && (
                                                    <p className={styles.error} style={{
                                                        marginTop: "5px",
                                                        marginLeft: "25px",
                                                        fontWeight: '500'
                                                    }}>Consent is required</p>
                                                )}

                                                <div className={styles.final_button_container1}>
                                                    <button
                                                        className={styles.submit_button1}
                                                        onClick={handleNextStage}
                                                    >
                                                        Verify OTP
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {stage === 3 && (
                                        <div>
                                            <div className={styles.heading_section}>
                                                <div className={styles.welcome_text}>
                                                    <div className={styles.welcome_text1}>
                                                        Verify your OTP
                                                    </div>
                                                    <div className={styles.welcome_text2}>
                                                        OTP has been sent to your number
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.new_otp}>
                                                <input
                                                    placeholder='● ● ● ●'
                                                    required
                                                    maxLength={4}
                                                    value={otp}
                                                    onChange={handleOTPChange}
                                                    type="tel" />
                                            </div>
                                            <div className={styles.otp_will_expire}>
                                                {!isOtpValid && (
                                                    <div className={styles.error_message_otp}>
                                                        {/* {otpErrorMessage} */}
                                                    </div>
                                                )}
                                                <>
                                                    <button
                                                        className={styles.resend_button}
                                                        onClick={resendOTP}>
                                                        Resend OTP
                                                    </button>
                                                </>
                                            </div>

                                            {isOtpValid ? (
                                                <button
                                                    type="submit"
                                                    className={styles.submit_button1}
                                                >
                                                    Submit
                                                </button>
                                            ) : (
                                                <>
                                                    <button
                                                        className={styles.submit_button1}
                                                        type='submit'
                                                    >
                                                        Submit
                                                    </button>
                                                    <span style={{ color: "red", margin: "10px 0" }}>
                                                        Wrong OTP
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    )}
                                </form>
                            </div>
                        )
                    }
                </div>
            )}

        </>
    )
}
