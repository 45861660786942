import React, { useState } from 'react'
import styles from './Rates.module.css';
import { Fade } from 'react-reveal';
import Inspection from '../../inspection/Inspection';
import mobile_warranty from '../../../../../images/vectors/carscope/mobile_warranty.jpg';
import blur_inspection from '../../../../../images/vectors/homescreen/weofferServices/blur_Inspection.jpg'
import { Link } from 'react-router-dom';
import { scrollToTop } from '../../../../scrollToTop/ScrollToTopButton';

export default function Rates({ data1 }) {
  const line2 = data1['6mnthEngCr'];
  const line1 = data1['6mnthEngTr'];
  const line4 = data1['12mnthEngCr'];
  const line3 = data1['12mnthEngTr'];

  const vehicle_cc = data1?.vehicle_cc;
  const rc_expiry = data1?.rc_report_generate?.rcExpiryDate;
  const norms = data1?.rc_report_generate?.normsType;
  const insur_upto = data1?.rc_report_generate?.vehicleInsuranceUpto;
  const insur_name = data1?.rc_report_generate?.vehicleManufacturerName;

  const [view, setView] = useState(false);

  return (
    <>
      {
        (data1.eligibleWarranty === null || data1.eligibleWarranty === 'no') ?
          (
            <>
              <div className={styles.blue_border}>
                <div className={styles.mobile_image}>
                  <img src={mobile_warranty} alt="" />
                </div>
                <div className={styles.heading}>
                  Vehicle Details
                </div>
                <div className={styles.web_image}>
                  <img src={mobile_warranty} alt="" />
                </div>


                <div className={styles.web_image1}>
                  <div className={styles.flex_box}>
                    <div className={styles.left_part}>
                      Vehicle CC :
                    </div>
                    <div className={styles.right_part}>
                      {vehicle_cc}
                    </div>
                  </div>
                  <div className={styles.flex_box}>
                    <div className={styles.left_part}>
                      RC Expiry Date :
                    </div>
                    <div className={styles.right_part}>
                      {rc_expiry}
                    </div>
                  </div>
                  <div className={styles.flex_box}>
                    <div className={styles.left_part}>
                      Norms Type :
                    </div>
                    <div className={styles.right_part}>
                      {norms}
                    </div>
                  </div>
                  <div className={styles.flex_box}>
                    <div className={styles.left_part}>
                      Insurance Upto :
                    </div>
                    <div className={styles.right_part}>
                      {insur_upto}
                    </div>
                  </div>
                  <div className={styles.flex_box}>
                    <div className={styles.left_part}>
                      Insurer Name :
                    </div>
                    <div className={styles.right_part}>
                      {insur_name}
                    </div>
                  </div>
                </div>
              </div>

              {
                (data1?.inspectionReportName || data1?.inspection_report) && (
                  <Inspection data2={data1} />
                )
              }
            </>
          ) :
          (
            <div className={styles.white}>
              <Fade>
                <div className={styles.blue_border}>
                  <div className={styles.mobile_image}>
                    <img src={mobile_warranty} alt="" />
                  </div>
                  <div className={styles.sub_heading}>
                    Warranty Packages Approved
                  </div>
                  <div className={styles.web_image}>
                    <img src={mobile_warranty} alt="" />
                  </div>
                  <div className={styles.web_image1}>
                    <div className={styles.rate}>
                      {
                        line1 && (
                          <div className={styles.flex_box}>
                            <div className={styles.left_part}>
                              6 months Engine Transmission
                            </div>
                            <div className={styles.right_part}>
                              ₹ {line1}
                            </div>
                          </div>
                        )
                      }
                      {
                        line2 && (
                          <div className={styles.flex_box}>
                            <div className={styles.left_part}>
                              6 months Comprehensive
                            </div>
                            <div className={styles.right_part}>
                              ₹ {line2}
                            </div>
                          </div>
                        )
                      }
                      {
                        line3 && (
                          <div className={styles.flex_box}>
                            <div className={styles.left_part}>
                              12 months Engine Transmission
                            </div>
                            <div className={styles.right_part}>
                              ₹ {line3}
                            </div>
                          </div>
                        )
                      }
                      {
                        line4 && (
                          <div className={styles.flex_box}>
                            <div className={styles.left_part}>
                              12 months Comprehensive
                            </div>
                            <div className={styles.right_part}>
                              ₹  {line4}
                            </div>
                          </div>
                        )
                      }
                      <Link onClick={scrollToTop} to='/nxcar-extended-warranty-terms-and-conditions'>
                        <div className={styles.terms_and_condition}>
                          Terms & conditions applied for extended warranty
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </Fade>

              {
                (data1?.inspectionReportName || data1?.inspection_report) && (
                  <Inspection data2={data1} />
                )
              }

              {/* <div className={styles.single_container}>
                <div className={styles.sub_heading2}>
                  Terms And Conditions
                </div>
                <div className={styles.sub_heading1}>
                  Warranty Eligibility Criteria
                </div>
                <div className={styles.single_points}>
                  <ol>
                    <li>
                      Vehicle is not more than 10 years old or run more than 1,00,000 kms.
                    </li>
                    <li>
                      Not more than 3 owners
                    </li>
                    <li>
                      Non accidental
                    </li>
                    <li>
                      Must be inspected & certified before issuance of warranty
                    </li>
                  </ol>
                </div>
              </div> */}
            </div>
          )

      }
    </>
  )
}