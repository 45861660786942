import styles from './About.module.css'
import Our_vision from '../../../components/about/our_vision/Our_vision';
import Milestone from '../../../components/about/milestone/Milestone';
import Culture from '../../../components/about/culture/Culture';
import Life from '../../../components/about/life/Life';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';
import NewHero from '../../../reusable/hero/AboutUsHero/NewHero';
import { Background, Parallax } from 'react-parallax';
import triangle_effect from '../../../images/vectors/parallax_effect/web.svg'
import TechDriven from '../../../components/about/techDriven/TechDriven';
import UpdateAbout from '../../../reusable/hero/AboutUsHero/updateAbout/UpdateAbout';


const About = () => {

    return (
        <>
            <div className={styles.container}>
                <Header />
                {/* <NewHero /> */}
                <UpdateAbout />
                <Our_vision />
                <Culture />
                <TechDriven />
                {/* <Milestone /> */}
                <Life />
            </div>


            <div className={styles.footer}>
                <Footer />
            </div>
        </>
    )
}

export default About