import styles from './Servicess.module.css'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import used_car from '../../../images/vectors/homescreen/services/used_car.svg'
import inspection_image from '../../../images/vectors/homescreen/services/inspection.svg'
import docs from '../../../images/vectors/homescreen/services/docs_verify.svg'
import extended_warranty_image from '../../../images/vectors/homescreen/services/extended_warranty.svg'
import insurance_transfer_image from '../../../images/vectors/homescreen/services/insurane_transfer.svg'
import rc_tranfer_image from '../../../images/vectors/homescreen/services/rc_transfer.svg'

const Servicess = () => {
    const [formData, setFormData] = useState({
        used_car_loan: false,
        inspection: false,
        document_verification: false,
        extended_warranty: false,
        insurance_transfer: false,
        rc_transfer: false,
        car_insurance: false,
    })

    // only when if a user change the page then to make them false
    const used_car_loan = localStorage.setItem('used_car_loan', formData.used_car_loan);
    const inspection = localStorage.setItem('inspection', formData.inspection);
    const document_verification = localStorage.setItem('document_verification', formData.document_verification);
    const extended_warranty = localStorage.setItem('extended_warranty', formData.extended_warranty);
    const insurance_transfer = localStorage.setItem('insurance_transfer', formData.insurance_transfer);
    const rc_transfer = localStorage.setItem('rc_transfer', formData.rc_transfer);
    const car_insurance = localStorage.setItem('car_insurance', formData.car_insurance);

    const custom_package = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        if (
            formData.used_car_loan == true ||
            formData.extended_warranty == true ||
            formData.document_verification == true ||
            formData.rc_transfer == true ||
            formData.inspection == true ||
            formData.insurance_transfer == true) {

        }
        else {
            localStorage.setItem('used_car_loan', true)
            localStorage.setItem('extended_warranty', false)
            localStorage.setItem('document_verification', false)
            localStorage.setItem('rc_transfer', false)
            localStorage.setItem('inspection', false)
            localStorage.setItem('insurance_transfer', false)
        }
    }

    const serviceSelected = (serviceName) => {
        setFormData({
            ...formData,
            [serviceName]: !formData[serviceName]
        });
    };

    return (
        <div className={styles.main_container}>
            <div className={styles.container}>
                <div className={styles.heading}>Nxcar Advantage Package</div>

                <div className={styles.service_container1}>
                    <div className={styles.card}>
                        <div className={styles.card_top}>
                            <img src={used_car} alt="" />
                        </div>
                        <div className={styles.card_bottom}>
                            <div className={styles.card_heading}>
                                <div className={styles.card_name}>Used Car Loan</div>
                                <div className={styles.card_price}>Upto 90% of price</div>
                            </div>
                            <div className={styles.card_sub_heading}>
                                For the first time in India: Now get used car loans for direct purchases!. Get loans up to 90% of the price, starting at just 12% pa.
                            </div>
                            <div
                                className={`${styles.CTA} ${formData.used_car_loan ? styles.active : ''}`}
                                onClick={() => serviceSelected('used_car_loan')}
                            >
                                Add
                            </div>
                        </div>
                    </div>
                    <div className={styles.card}>
                        <div className={styles.card_top}>
                            <img src={inspection_image} alt="" />
                        </div>
                        <div className={styles.card_bottom}>
                            <div className={styles.card_heading}>
                                <div className={styles.card_name}>Inspection & Valuation</div>
                                <div className={styles.card_price}>₹ 499</div>
                            </div>
                            <div className={styles.card_sub_heading}>
                                Nxcar offers onsite inspections to check the car's condition and confirm its price. If you choose our warranty package, we'll refund the inspection cost.
                            </div>
                            <div
                                className={`${styles.CTA} ${formData.inspection ? styles.active : ''}`}
                                onClick={() => serviceSelected('inspection')}
                            >
                                Add
                            </div>
                        </div>
                    </div>
                    <div className={styles.card}>
                        <div className={styles.card_top}>
                            <img src={docs} alt="" />
                        </div>
                        <div className={styles.card_bottom}>
                            <div className={styles.card_heading}>
                                <div className={styles.card_name}>Document  Verification</div>
                                <div className={styles.card_price}> ₹ 299</div>
                            </div>
                            <div className={styles.card_sub_heading}>
                                We verify ownership status, pending fines, blacklist status, and check for existing loans. If you opt for the loan facility, we will provide these services complimentary.
                            </div>
                            <div
                                className={`${styles.CTA} ${formData.document_verification ? styles.active : ''}`}
                                onClick={() => serviceSelected('document_verification')}
                            >
                                Add
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.service_container2}>
                    <div className={styles.card}>
                        <div className={styles.card_top}>
                            <img src={extended_warranty_image} alt="" />
                        </div>
                        <div className={styles.card_bottom}>
                            <div className={styles.card_heading}>
                                <div className={styles.card_name}>Extended Warranty</div>
                                <div className={styles.card_price}>From ₹3999</div>
                            </div>
                            <div className={styles.card_sub_heading}>
                                We ensure that for a nominal fee, you enjoy complete peace of mind with our extended warranty options. Choose from engine & transmission or comprehensive coverage. Available for periods ranging from  6 months to 1 year.
                            </div>
                            <div
                                className={`${styles.CTA} ${formData.extended_warranty ? styles.active : ''}`}
                                onClick={() => serviceSelected('extended_warranty')}
                            >
                                Add
                            </div>
                        </div>
                    </div>
                    <div className={styles.card}>
                        <div className={styles.card_top}>
                            <img src={insurance_transfer_image} alt="" />
                        </div>
                        <div className={styles.card_bottom}>
                            <div className={styles.card_heading}>
                                <div className={styles.card_name}>Insurance Transfer </div>
                                <div className={styles.card_price}>Offered Free </div>
                            </div>
                            <div className={styles.card_sub_heading}>
                                It's possible to transfer the remaining insurance coverage to the new buyer by simply paying the NCB adjustment amount. At Nxcar, we handle the entire process for you at no cost.
                            </div>
                            <div
                                className={`${styles.CTA} ${formData.insurance_transfer ? styles.active : ''}`}
                                onClick={() => serviceSelected('insurance_transfer')}
                            >
                                Add
                            </div>
                        </div>
                    </div>
                    <div className={styles.card}>
                        <div className={styles.card_top}>
                            <img src={rc_tranfer_image} alt="" />
                        </div>
                        <div className={styles.card_bottom}>
                            <div className={styles.card_heading}>
                                <div className={styles.card_name}>RC Transfer </div>
                                <div className={styles.card_price}>From ₹2999</div>
                            </div>
                            <div className={styles.card_sub_heading}>
                                Nxcar arranges the legal transfer of ownership to your name at various RTOs, facilitating both interstate and intrastate transfers.
                            </div>
                            <div
                                className={`${styles.CTA} ${formData.rc_transfer ? styles.active : ''}`}
                                onClick={() => serviceSelected('rc_transfer')}
                            >
                                Add
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.black_tape}>
                <Link to='/bundle' onClick={custom_package}>
                    <div className={styles.build_button}>
                        Build My Package And Show Details
                    </div>
                </Link>
            </div>
        </div>

    )
}

export default Servicess
