import styles from './Process.module.css'
import car from '../../../images/vectors/homescreen/four_step_process/process.svg'
import CarLineAnimation from './animation/CarLineAnimation'
import Icon from "./animation_compo/Image"
import CarLineAnimation2 from './animation/CarLineAnimation2'

const Process = () => {
    return (
        <>
            <div className={styles.container}>
                <div className={styles.heading}>
                    Experience Nxcar Convenience
                </div>
                <div className={styles.sub_heading}>
                    Save 10-30% by purchasing directly from owners or listings, with the assurance of professional verification, documentation, and used car loans from Nxcar.
                </div>
                <div className={styles.sub_heading2}>
                    Sellers can also speed up the sales process by incorporating Nxcar's expert services
                </div>
                <div className={styles.image_container}>
                    {/* <CarLineAnimation /> */}
                    <CarLineAnimation2 />
                </div>


                {/* NEW MODIFY CODE FOR PROCESS ANIMATION */}
                {/* <div className="w-full grid grid-cols-2 gap-4 h-screen">
                    <Icon></Icon>
                </div> */}
            </div>
        </>
    )
}

export default Process