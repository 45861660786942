import React, { useState, useEffect } from 'react';
import loader from '../../images/loading/loader.gif';
import styles from './PdfViewer.module.css';
import { useMediaQuery } from '@material-ui/core';


export default function PdfViewer({ pdfUrl }) {
  const [loading, setLoading] = useState(true);
  const [iframeSrc, setIframeSrc] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const isMobile = useMediaQuery('(max-width: 940px)');

  const getDeviceType = () => {
    if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      return 'IOS';
    } else if (navigator.userAgent.match(/Android/i)) {
      return 'Android';
    } else {
      return 'Other';
    }
  };

  let deviceType = getDeviceType();

  useEffect(() => {
    if (deviceType === 'IOS') {
      window.open(`https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(pdfUrl)}`, '_blank')
    } else {
      setIframeSrc(`https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(pdfUrl)}`);
    }
  }, [pdfUrl]);

  useEffect(() => {
    const iframe = document.getElementById('pdf-iframe');

    const handleLoad = () => {
      setLoading(false);
      setErrorMessage('');
    };

    const handleError = () => {
      setLoading(false);
      setErrorMessage('Failed to load PDF. Please try again.');
    };

    if (iframe) {
      iframe.addEventListener('load', handleLoad);
      iframe.addEventListener('error', handleError);

      return () => {
        iframe.removeEventListener('load', handleLoad);
        iframe.removeEventListener('error', handleError);
      };
    }
  }, [iframeSrc, deviceType]);

  return (
    <div className={styles.container} style={{ height: '70vh', width: '100%', position: 'relative' }}>


      {isMobile ? (
        <>
          {loading && !errorMessage && (
            <div
              className={styles.loading}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 1,
                fontSize: '14px',
                color: '#000',
              }}
            >
              <img
                src={loader}
                alt="Please Wait PDF Is Loading ..."
              />
            </div>
          )}
          <iframe
            id="pdf-iframe"
            src={iframeSrc}
            style={{ zIndex: '99', border: 'none', width: '100%', height: '70vh', display: loading ? 'none' : 'block' }}
            allowFullScreen
          />
        </>
      ) : (
        <object
          data={pdfUrl}
          type="application/pdf"
          style={{ height: '600px', width: "100%" }}
          frameBorder="0"
        >
          <embed src="https://drive.google.com/file/d/1CRFdbp6uBDE-YKJFaqRm4uy9Z4wgMS7H/preview?usp=sharing" width="100%" height="600px" />
        </object>
      )}
    </div>
  );
}