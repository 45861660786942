import styles from './ReferForm.module.css'
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { IoClose } from 'react-icons/io5';
import { Box, Modal, useMediaQuery } from '@material-ui/core';
import thank_you from '../../images/forms/referral/thank_you.svg';

const styleMUIModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '500px',
    height: 'fitContent',
};
const styleMUIModalMobile = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: 'fitContent',
    width: '96%',
}

export default function ReferFormPopup({ handleClose }) {
    const isMobile = useMediaQuery('(max-width: 940px)');
    const [stage, setStage] = useState(1);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [isFocused1, setIsFocused1] = useState(false);
    const [emptyFields, setEmptyFields] = useState([]);
    const [thankYouModal, setThankYouModal] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        ref_person_name: '',
        ref_person_mobile: '',
    });

    const handleInputFocus = () => {
        setIsFocused(true);
    };
    const handleInputBlur = () => {
        setIsFocused(false);
    };
    const handleInputFocus1 = () => {
        setIsFocused1(true);
    };
    const handleInputBlur1 = () => {
        setIsFocused1(false);
    };

    const handleInputChange = (e, inputName) => {
        let newValue = e.target.files ? e.target.files[0] : e.target.value;

        if (e.target.tagName === 'INPUT') {
            newValue = e.target.value;
        }

        setFormData((prevFormData) => ({
            ...prevFormData,
            [inputName]: newValue,
        }));
    };

    const validateStage1 = () => {
        const phonePattern = /^\d{10}$/;
        const namePattern = /^[A-Za-z ]+$/;
        return (
            formData.name.trim() !== '' &&
            formData.ref_person_name.trim() !== '' &&
            namePattern.test(formData.name.trim()) &&
            namePattern.test(formData.ref_person_name.trim()) &&
            phonePattern.test(formData.mobile.trim()) &&
            formData.mobile.trim() !== formData.ref_person_mobile.trim() &&
            phonePattern.test(formData.ref_person_mobile.trim())
        );
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateStage1()) {
            setEmptyFields(["name", "mobile", "ref_person_name", "ref_person_mobile"]);
            return;
        }

        try {
            const response = await axios.post('/reference', formData);
            setIsFormSubmitted(true);
            setThankYouModal(true);
            setFormData({
                name: '',
                mobile: '',
                ref_person_name: '',
                ref_person_mobile: '',
            });


        } catch (error) {
            if (error.response && error.response.data && error.response.data.errors) {
                setEmptyFields(error.response.data.errors);
            }
        }
    };

    const close_modal = () => {
        setThankYouModal(false);
        setIsFormSubmitted(false);
        if (isMobile) {
            window.location.reload();
        }
    }

    useEffect(() => {
        const timer = thankYouModal ? setTimeout(close_modal, 2000) : null;
        return () => clearTimeout(timer);
    }, [thankYouModal])


    return (
        <>
            <div className={styles.form_stage}>
                <form onSubmit={handleSubmit}>
                    {stage === 1 && (
                        <>
                            {/* WELCOME TEXT */}
                            <div className={styles.heading_section}>
                                <div className={styles.welcome_text}>
                                    <div className={styles.welcome_text1}>
                                        Get Amazon Voucher Worth <br /> ₹2000
                                    </div>
                                    <div className={styles.welcome_text2}>
                                        Refer Your Friends Now
                                    </div>
                                </div>
                                <div
                                    className={styles.mui_close_button1}
                                    onClick={handleClose}
                                >
                                    <IoClose size={24} />
                                </div>
                            </div>

                            <div className={styles.form}>
                                <div className={styles.col}>
                                    <div className={styles.MUI_input}>
                                        <input
                                            className={formData.name.length > 0 ? styles.input_field1 : styles.input_field}
                                            type="text"
                                            value={formData.name}
                                            onChange={(e) => handleInputChange(e, 'name')}
                                            required
                                            id='full-name'
                                            name='full-name'
                                            onInput={(e) => {
                                                const inputValue = e.target.value;
                                                const sanitizedValue = inputValue.replace(/[^A-Za-z\s]/g, '');
                                                if (inputValue !== sanitizedValue) {
                                                    e.target.value = sanitizedValue;
                                                    handleInputChange(e, 'name');
                                                }
                                            }}
                                        />
                                        <div className={`${styles.float_label} ${formData.name && styles.labelActive}`}>
                                            Full Name <span style={{ color: `var(--red)` }}>*</span>
                                        </div>
                                    </div>
                                    {emptyFields.includes("name") && formData.name === "" && (
                                        <p className={styles.error}>Full Name is required</p>
                                    )}


                                    {/* MOBILE NUMBER */}
                                    <div className={styles.MUI_input}>
                                        <div className={`${styles.phoneNumberDisplay} ${formData.mobile && styles.phoneNumberActive}`}>
                                            +91
                                        </div>

                                        <input
                                            className={`${styles.input_field} ${formData.mobile?.length > 0 ? styles.input_field2 : ''} ${isFocused ? styles.input_field2 : ''}`}

                                            type="tel"
                                            maxLength={10}
                                            value={formData.mobile}
                                            required
                                            onFocus={handleInputFocus}
                                            onBlur={handleInputBlur}
                                            id='phone_number'
                                            name='phone_number'
                                            style={{ padding: "12px 0px" }}
                                            onChange={(e) => {
                                                const inputValue = e.target.value;
                                                const sanitizedValue = inputValue.replace(/\D/g, '');
                                                handleInputChange({ target: { value: sanitizedValue } }, 'mobile');
                                            }}
                                        />
                                        <div className={`${styles.float_label} ${formData.mobile && styles.labelActive}`}>
                                            {isFocused ? "Mobile Number" : formData.mobile?.length === 0 ? "Mobile Number" : "Mobile Number"} <span style={{ color: `var(--red)` }}>*</span>
                                        </div>
                                    </div>
                                    {emptyFields.includes("mobile") && (formData.mobile.length === 0 || formData.mobile === "") && (
                                        <p className={styles.error}> Mobile is required</p>
                                    )}
                                    {formData.mobile && !/^[6789]\d{9}$/.test(formData.mobile) && (
                                        <p className={styles.error}>
                                            Please provide an Indian number
                                        </p>
                                    )}

                                    {/* REF PERSON NAME */}
                                    <div className={styles.MUI_input}>
                                        <input
                                            className={formData.ref_person_name.length > 0 ? styles.input_field1 : styles.input_field}
                                            type="text"
                                            value={formData.ref_person_name}
                                            onChange={(e) => handleInputChange(e, 'ref_person_name')}
                                            required
                                            id='ref_name'
                                            name='ref_name'
                                            onInput={(e) => {
                                                const inputValue = e.target.value;
                                                const sanitizedValue = inputValue.replace(/[^A-Za-z\s]/g, '');
                                                if (inputValue !== sanitizedValue) {
                                                    e.target.value = sanitizedValue;
                                                    handleInputChange(e, 'ref_person_name');
                                                }
                                            }}
                                        />
                                        <div className={`${styles.float_label} ${formData.ref_person_name && styles.labelActive}`}>
                                            Name of Person Being Referred <span style={{ color: `var(--red)` }}>*</span>
                                        </div>
                                    </div>
                                    {emptyFields.includes("ref_person_name") && formData.ref_person_name === "" && (
                                        <p className={styles.error}>Person's name is required</p>
                                    )}


                                    {/*REF PERSON MOBILE NUMBER */}
                                    <div className={styles.MUI_input}>
                                        <div className={`${styles.phoneNumberDisplay} ${formData.ref_person_mobile && styles.phoneNumberActive}`}>
                                            +91
                                        </div>

                                        <input
                                            className={`${styles.input_field} ${formData.ref_person_mobile?.length > 0 ? styles.input_field2 : ''} ${isFocused1 ? styles.input_field2 : ''}`}

                                            type="tel"
                                            maxLength={10}
                                            value={formData.ref_person_mobile}
                                            required
                                            onFocus={handleInputFocus1}
                                            onBlur={handleInputBlur1}
                                            id='ref_person_mobile'
                                            name='ref_person_mobile'
                                            style={{ padding: "12px 0px" }}
                                            onChange={(e) => {
                                                const inputValue = e.target.value;
                                                const sanitizedValue = inputValue.replace(/\D/g, '');
                                                handleInputChange({ target: { value: sanitizedValue } }, 'ref_person_mobile');
                                            }}
                                        />
                                        <div className={`${styles.float_label} ${formData.ref_person_mobile && styles.labelActive}`}>
                                            {isFocused1 ? "Mobile No. of Person Being Referred" : formData.ref_person_mobile?.length === 0 ? "Mobile No. of Person Being Referred" : "Mobile No. of Person Being Referred"} <span style={{ color: `var(--red)` }}>*</span>
                                        </div>
                                    </div>
                                    {emptyFields.includes("ref_person_mobile") && (
                                        <p className={styles.error}>
                                            {formData.ref_person_mobile ? "" : "Person's Mobile is required"}
                                        </p>
                                    )}
                                    {formData.ref_person_mobile && (
                                        <>
                                            {!/^[6789]\d{9}$/.test(formData.ref_person_mobile) && (
                                                <p className={styles.error}>
                                                    Please provide a valid Indian mobile number
                                                </p>
                                            )}
                                            {formData.mobile && formData.ref_person_mobile === formData.mobile && (
                                                <p className={styles.error}>
                                                    Person's Mobile cannot be same as your Mobile
                                                </p>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className={styles.tagline}>
                                *If the loan is disbursed then only you will get the voucher.
                            </div>

                            <div className={styles.final_button_container1}>
                                <button
                                    className={styles.submit_button1}
                                    type='submit'
                                >
                                    Refer Now
                                </button>
                            </div>
                        </>
                    )}
                </form>
            </div>

            {thankYouModal && (
                <Modal
                    open={thankYouModal}
                    onClose={close_modal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={isMobile ? styleMUIModalMobile : styleMUIModal}>
                        <div className={styles.modal_screen}>
                            <div
                                className={styles.mui_close_button2}
                                onClick={close_modal}
                            >
                                <IoClose size={24} />
                            </div>
                            <img src={thank_you} alt="nxcar-thank-you-pop-up-image" />
                            <div className={styles.modal_heading}>
                                Thank you for referring your
                                friend our team will get in touch with you shortly.
                            </div>
                        </div>
                    </Box>
                </Modal>
            )}
        </>
    )
}
