import React from 'react'
import styles from './ProductPolicy.module.css';

export default function ProductPolicy() {
  const arr = [
    {
      text1: 'Third Party Car Insurance Policy',
      text2: "A Third-party Liability Four Wheeler Insurance Policy covers financial liabilities when you accidentally damage other people's property or cause injuries/death of a third party. A third-party policy is mandatory as per law; however, it does not cover damages to your car."
    },
    {
      text1: 'Comprehensive Car Insurance Policy',
      text2: 'The Comprehensive car insurance plan all the coverage benefits the Third Party Plan offers. In addition, this plan also covers damages to your car caused by accidents, calamities, fire, rat bites and theft of the insured vehicle. This plan also allows you to top for add-on covers to enhance the policy coverage.'
    },
    {
      text1: 'Zero Depreciation Policy - Super Saver (Bumper-to-Bumper)',
      text2: 'This car policy provides all the coverage benefits offered by the Comprehensive Plan. In addition, this plan also covers 100% of the cost of the car parts if a part needs to be replaced during repair. In other words, this plan will cover the depreciation cost incurred by car parts and will offer you the maximum claim amount.'
    },
    {
      text1: 'Stand Alone Own Damage Cover Policy',
      text2: 'Third-party Liability Car Insurance cover proves to be inadequate when it comes to damage to your car from accidents, natural disasters, fire, or theft. Such coverages are provided under an Own Damage Insurance policy.'
    },
  ]
  return (
    <>
      <div className={styles.container}>
        <div className={styles.main_heading}>
          Different Types of Car Insurance Policies/Products
        </div>

        {
          arr.map((item) => (
            <div className={styles.text_container} key={item.text1}>
              <div className={styles.text1}>✓ {item.text1}</div>
              <div className={styles.text2}>{item.text2}</div>
            </div>
          ))
        }

      </div>
    </>
  )
}
