import React, { useState, useEffect } from 'react';
import TextTransition, { presets } from "react-text-transition";
import styles from './NewHero.module.css';
import SellCar from '../PopUpForms/SellCar/SellCar';
import { Box, Modal } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import BuyCar from '../PopUpForms/BuyCar/BuyCar';
import buy_sell_image from '../../../images/vectors/homescreen/buy_sell/coin.svg'

const styleMUIModal = {
    position: "absolute",
    top: "0%",
    right: "0%",
    width: '40%',
    height: '100%',
    bgcolor: "white",
    boxShadow: 24,
    outline: "none",
    overflowY: "auto",
    p: 0,
};
const styleMUIModalMobile = {
    width: "100%",
    height: "100vh",
    top: "0%",
    right: "0%",
    bgcolor: "white",
    boxShadow: 24,
    outline: "none",
    overflowY: "scroll",
    p: 0,
}

const TEXTS = ["Get Used Car Loan"]

const NewHero = () => {
    const [isSellModal, setIsSellModal] = useState(false);
    const [isBuyModal, setIsBuyModal] = useState(false);
    const [index, setIndex] = useState(0);

    const handleSellCar = () => {
        setIsSellModal(true);
    };

    const handleBuyCar = () => {
        setIsBuyModal(true);
    };

    const handleCloseModals = () => {
        setIsSellModal(false);
        setIsBuyModal(false);
    };

    const isMobile = useMediaQuery('(max-width: 940px)');

    useEffect(() => {
        const intervalId = setInterval(
            () => setIndex((index) => index + 1),
            1000,
        );
        return () => clearTimeout(intervalId);
    }, []);

    return (
        <div className={styles.dark}>

            <div className={styles.left}>
                <div className={styles.heading}>
                    Buy Right, Sell Fast
                </div>
                <div className={styles.middle_heading}>
                    Experience seamless deals, and maximum value with Nxcar's expertise.
                </div>
                <div className={styles.sub_heading}>
                    <TextTransition
                        springConfig={presets.wobbly}
                        delay={300}
                        direction='down'
                    >
                        {TEXTS[index % TEXTS.length]}

                    </TextTransition>
                </div>
                <div className={styles.button_container}>
                    <div
                        onClick={handleBuyCar}
                        className={styles.button1}
                    >
                        Buy Car
                    </div>
                    <div
                        onClick={handleSellCar}
                        className={styles.button2}
                    >
                        Sell Car
                    </div>
                </div>
            </div>

            <div className={styles.right}>
                <img src={buy_sell_image} alt="buy-sell-nxcar" />
            </div>

            {
                isSellModal && (
                    <Modal
                        open={isSellModal}
                        onClose={handleCloseModals}
                    >
                        <Box sx={isMobile ? styleMUIModalMobile : styleMUIModal}>
                            <SellCar handleClose={handleCloseModals} />
                        </Box>
                    </Modal>
                )
            }
            {
                isBuyModal && (
                    <Modal
                        open={isBuyModal}
                        onClose={handleCloseModals}
                    >
                        <Box sx={isMobile ? styleMUIModalMobile : styleMUIModal}>
                            <BuyCar handleCloseBuy={handleCloseModals} />
                        </Box>
                    </Modal>
                )
            }
        </div>
    );
};

export default NewHero