import React, { useEffect, useRef, useState } from 'react'
import TextTransition, { presets } from "react-text-transition";
import styles from './NewHero.module.css';
import { Box, Modal, useMediaQuery } from '@material-ui/core';
import PartnerForm from '../PopUpForms/PartnerForm/PartnerForm';
import PartnerAnimation from './animations/PartnerAnimation';

const styleMUIModal = {
    position: "absolute",
    top: "0%",
    right: "0%",
    width: '40%',
    height: '100vh',
    bgcolor: "white",
    border: "0px solid #000",
    boxShadow: 24,
    outline: "none",
    overflowY: "auto",
    p: 0,
};
const styleMUIModalMobile = {
    width: "100%",
    height: "100vh",
    top: "0%",
    right: "0%",
    height: '100vh',
    bgcolor: "white",
    border: "0px solid #000",
    boxShadow: 24,
    outline: "none",
    overflowY: "auto",
    p: 0,
}

const TEXTS = [
    "Nxfin is a pioneering venture that specialises in facilitating pre-owned car acquisitions and sales.",
    "Our unique focus is on collaborating exclusively with used car dealers.",
    "Nxcar enables used car dealers to source their desired vehicles.",
    "We offer extended payment options to make the acquisition process more flexible.",
]
const NewHero = ({ single_button, handleFormFocus }) => {
    const isMobile = useMediaQuery('(max-width: 940px)');
    const [isPartner, setIsPartner] = useState(false);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(
            () => setIndex((index) => index + 1),
            2000,
        );
        return () => clearTimeout(intervalId);
    }, []);

    // FOR POPUP FORM
    // const handlePartner = () => {
    //     setIsPartner(true);
    // }

    const handlePartner = () => {
        window.scrollBy(0, 600);
    };

    const handleCloseModals = () => {
        setIsPartner(false);
    };

    // CANVAS
    const canvasRef = useRef(null);
    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        const col = (x, y, r, g, b) => {
            ctx.fillStyle = `rgb(${r},${g},${b})`;
            ctx.fillRect(x, y, 1, 1);
        };

        const colors = [
            [155, 81, 224, 1],
            [10, 211, 903, 1],
            [70, 211, 503, 1],
        ];

        const R = (x, y, t) => colors[0][0] + Math.floor(64 * Math.cos((x * x - y * y) / 300 + t));
        const G = (x, y, t) => colors[1][1] + Math.floor(64 * Math.sin((x * x * Math.cos(t / 4) + y * y * Math.sin(t / 3)) / 300));
        const B = (x, y, t) => colors[2][2] + Math.floor(64 * Math.sin(5 * Math.sin(t / 9) + ((x - 100) * (x - 100) + (y - 100) * (y - 100)) / 1100));


        let t = 0;

        const run = () => {
            for (let x = 0; x <= 35; x++) {
                for (let y = 0; y <= 35; y++) {
                    col(x, y, R(x, y, t), G(x, y, t), B(x, y, t));
                }
            }
            t = t + 0.01;
            window.requestAnimationFrame(run);
        };

        run();

    }, []);
    // CANVAS

    return (
        <>
            <div className={styles.my_canvas}>
                <canvas ref={canvasRef}
                    width="36" height="36" className={styles.canvas}>
                </canvas>
            </div>
            <div className={styles.dark}>
                <div className={styles.left}>
                    <div className={styles.heading1}>
                        Transform pre-owned car acquisitions & sales with Nxcar
                    </div>
                    <div className={styles.heading3}>
                        <TextTransition
                            direction='down'
                        >
                            {TEXTS[index % TEXTS.length]}
                        </TextTransition>
                    </div>
                    {single_button && (
                        <a
                            className={styles.single_button}
                            onClick={handleFormFocus}
                        >
                            {single_button}
                        </a>
                    )}
                </div>
                <div className={styles.right}>
                    <PartnerAnimation />
                </div>

                {
                    isPartner && (
                        <Modal
                            open={isPartner}
                            onClose={handleCloseModals}
                        >
                            <Box sx={isMobile ? styleMUIModalMobile : styleMUIModal}>
                                <PartnerForm handleClose={handleCloseModals} />
                            </Box>
                        </Modal>
                    )
                }
            </div>
        </>
    );
};

export default NewHero