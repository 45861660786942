import styles from './ReferForm.module.css';
import hero from '../../images/forms/referral/hero.svg'
import wave from '../../images/forms/referral/wave.svg'
import wave2 from '../../images/forms/referral/wave2.svg'
import ReferFormPopup from './ReferFormPopup';
import { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import { useMediaQuery } from '@material-ui/core';
import { Box } from '@mui/material';

const styleMUIModalMobile = {
    position: 'absolute',
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "fitContent",
    border: "none",
    outline: "none",
}

export default function ReferForm() {
    const [isFormVisible, setIsLoanFormVisible] = useState(false);
    const isMobile = useMediaQuery('(max-width: 940px)');

    const showForm = () => {
        setIsLoanFormVisible(!isFormVisible);
    }

    const handleClose = () => {
        setIsLoanFormVisible(false);
    }

    return (
        <>
            <div className={styles.main_container}>
                <div className={styles.wave2}>
                    <img src={wave2} alt="nxcar-wave-2-refer-2000-rs" />
                </div>
                <div className={styles.container}>
                    <div className={styles.left}>
                        <ReferFormPopup />
                    </div>
                    <div className={styles.left_mobile}>
                        <div className={styles.mobile_heading}>Get Amazon Voucher <br /> Worth ₹2000</div>
                        <div className={styles.mobile_sub_heading}>Refer Your Friends Now</div>
                        <div
                            className={styles.mobile_button}
                            onClick={showForm}
                        >
                            Refer Now
                        </div>
                    </div>
                    <div className={styles.right}>
                        <img
                            src={hero}
                            alt="nxcar-referral-form-with-rs-2000"
                        />
                    </div>
                </div>
                <div className={styles.wave}>
                    <img src={wave} alt="nxcar-wave-refer-2000-rs" />
                </div>
            </div>
            {isFormVisible && (
                <Modal
                    open={isFormVisible}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={isMobile ? styleMUIModalMobile : ''}>
                        <ReferFormPopup
                            handleClose={handleClose}
                        />
                    </Box>
                </Modal>
            )}
        </>
    )
}
