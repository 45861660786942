import { useState, useRef, useEffect } from 'react';
import styles from './RcReport.module.css';
import { BsDownload } from "react-icons/bs";
import logo from '../../images/logos/logo.svg';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import car from '../../images/vectors/rc_details/car.svg'
import path from '../../images/vectors/rc_details/path.svg'
import thread from '../../images/vectors/rc_details/thread.svg'
import plus1 from '../../images/vectors/rc_details/plus1.svg'
import plus2 from '../../images/vectors/rc_details/plus2.svg'
import half_circle1 from '../../images/vectors/rc_details/halfcircle1.svg'
import half_circle2 from '../../images/vectors/rc_details/halfcircle2.svg'
import triangle from '../../images/vectors/rc_details/triangle.svg'
import { IoCloseSharp, IoLogoWhatsapp, IoShareSocialSharp } from "react-icons/io5";
import { useReactToPrint } from 'react-to-print';
import { FaPrint } from 'react-icons/fa';
import { Box, Modal, useMediaQuery } from '@material-ui/core';
import {
  WhatsappShareButton, WhatsappIcon,
  FacebookShareButton, FacebookIcon,
  TwitterShareButton, TwitterIcon,
  LinkedinShareButton, LinkedinIcon,
  EmailShareButton, EmailIcon,
  TelegramShareButton, TelegramIcon
} from 'react-share';
import { useLocation } from 'react-router-dom';


const styleMUIModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: '30%',
  height: 'fitContent',
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  border: "0px solid #000",
  borderRadius: '16px',
  boxShadow: 24,
  outline: "none",
  overflowY: "auto",
  p: 2,
};

const styleMUIModalMobile = {
  position: "absolute",
  width: "70%",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  border: "0px solid #000",
  borderRadius: '16px',
  boxShadow: 24,
  outline: "none",
  overflowY: "auto",
  p: 1,
}

export default function RcReport({ data, data1, view, handleViewPDF }) {
  const componentRef = useRef();
  const regNo = data1?.regNo;
  const vehicleClass = data1?.vehicleClass;
  const chassis = data1?.chassis;
  const engine = data1?.engine;
  const vehicleManufacturerName = data1?.vehicleManufacturerName;
  const model = data1?.model;
  const vehicleColour = data1?.vehicleColour;
  const type = data1?.type;
  const normsType = data1?.normsType;
  const bodyType = data1?.bodyType;
  const ownerCount = data1?.ownerCount;
  const owner = data1?.owner;
  const ownerFatherName = data1?.ownerFatherName;
  const mobileNumber = data1?.mobileNumber;
  const status = data1?.status;
  const statusAsOn = data1?.statusAsOn;
  const regAuthority = data1?.regAuthority;
  const regDate = data1?.regDate;
  const vehicleManufacturingMonthYear = data1?.vehicleManufacturingMonthYear;
  const rcExpiryDate = data1?.rcExpiryDate;
  const vehicleTaxUpto = data1?.vehicleTaxUpto;
  const vehicleInsuranceCompanyName = data1?.vehicleInsuranceCompanyName;
  const vehicleInsuranceUpto = data1?.vehicleInsuranceUpto;
  const vehicleInsurancePolicyNumber = data1?.vehicleInsurancePolicyNumber;
  const rcFinancer = data1?.rcFinancer;
  const presentAddress = data1?.presentAddress;
  const permanentAddress = data1?.permanentAddress;
  const vehicleCubicCapacity = data1?.vehicleCubicCapacity;
  const grossVehicleWeight = data1?.grossVehicleWeight;
  const unladenWeight = data1?.unladenWeight;
  const vehicleCategory = data1?.vehicleCategory;
  const rcStandardCap = data1?.rcStandardCap;
  const vehicleCylindersNo = data1?.vehicleCylindersNo;
  const vehicleSeatCapacity = data1?.vehicleSeatCapacity;
  const vehicleSleeperCapacity = data1?.vehicleSleeperCapacity;
  const vehicleStandingCapacity = data1?.vehicleStandingCapacity;
  const wheelbase = data1?.wheelbase;
  const vehicleNumber = data1?.vehicleNumber;
  const puccNumber = data1?.puccNumber;
  const puccUpto = data1?.puccUpto;
  const blacklistStatus = data1?.blacklistStatus;
  const blacklistDetails = data1?.blacklistDetails;
  const permitIssueDate = data1?.permitIssueDate;
  const permitNumber = data1?.permitNumber;
  const permitType = data1?.permitType;
  const permitValidFrom = data1?.permitValidFrom;
  const permitValidUpto = data1?.permitValidUpto;
  const nonUseStatus = data1?.nonUseStatus;
  const nonUseFrom = data1?.nonUseFrom;
  const nonUseTo = data1?.nonUseTo;
  const nationalPermitNumber = data1?.nationalPermitNumber;
  const nationalPermitUpto = data1?.nationalPermitUpto;
  const nationalPermitIssuedBy = data1?.nationalPermitIssuedBy;
  const isCommercial = data1?.isCommercial;
  const nocDetails = data1?.nocDetails;
  const dbResult = data1?.dbResult;
  const partialdata1 = data1?.partialdata1;
  const mmvResponse = data1?.mmvResponse;
  const financed = data1?.financed;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `RcDetail_${regNo}`,
    onAfterPrint: () => console.log('Print success!'),
  });

  const pdfUrl = `https://www.nxcar.in/rc-details/${regNo}`;
  const [openWhatsApp, setOpenWhatsApp] = useState(false);

  const handleWhatsappModal = () => {
    setOpenWhatsApp(!openWhatsApp)
  };

  const isMobile = useMediaQuery('(max-width: 940px)');

  const location = useLocation();
  const path = location.pathname;
  const isCarscopePath = path.startsWith("/carscope");

  function replaceConsonantsWithAsterisk(sentence) {
    if (!sentence) return sentence;
    let result = '';
    for (let i = 0; i < sentence.length; i++) {
      if (sentence[i] === ' ') {
        result += ' ';
      } else {
        result += (i % 2 === 0) ? sentence[i] : '*';
      }
    }
    return result;
  }

  function maskAndReplaceString(value) {
    if (typeof value !== 'string' || value.length === 0) {
      return value; // If the value is not a string or is empty, return it as is
    }

    if (value.length === 1) {
      return '*'; // If the string has only one character, mask it completely
    }

    const lastFiveChars = value.slice(-5); // Get the last 5 characters
    const maskedPart = '*'.repeat(value.length - 5); // Mask all characters except the last 5

    return `${maskedPart}${lastFiveChars}`;
  }


  function applyMasking(condition, value) {
    return condition ? replaceConsonantsWithAsterisk(value) : value;
  }
  function applyMasking2(condition, value) {
    return condition ? maskAndReplaceString(value) : value;
  }

  const maskedChassisNumber = applyMasking2(data?.chasisNumber === "1", chassis);
  const maskedOwnerCount = applyMasking2(data?.ownerCount === "1", ownerCount);

  const maskedOwner = applyMasking(data?.ownerName === "1", owner);
  const maskedOwnerFatherName = applyMasking(isCarscopePath, ownerFatherName);
  const maskedMobileNumber = applyMasking(isCarscopePath, mobileNumber);
  const maskedPresentAddress = applyMasking(data?.presentAddress === "1", presentAddress);
  const maskedPermanentAddress = applyMasking(data?.permanentAddress === "1", permanentAddress);



  return (
    <div className={styles.main_container}>
      <>
        {/* HEADER */}
        <div className={styles.my_container}>
          <div className={styles.container}>
            <div className={styles.flex1}>
              <div
                className={styles.button1}
                onClick={handlePrint}>
                <FaPrint />
              </div>
              <div
                className={styles.button1}
                onClick={handleWhatsappModal}>
                <IoShareSocialSharp />
              </div>
              <div className={styles.button1} onClick={handleViewPDF}>
                <IoCloseSharp />
              </div>
            </div>
            <div ref={componentRef}>
              {/* HEADER */}
              <div className={styles.header}>
                <div className={styles.logo}>
                  <img src={logo} alt="" />
                </div>
                {/* <div className={styles.plus1}>
                <img src={plus2} alt="" />
              </div>
              <div className={styles.plus2}>
                <img src={plus1} alt="" />
              </div> */}
                <div className={styles.circle1}>
                  <img src={half_circle1} alt="" />
                </div>
                <div className={styles.circle2}>
                  <img src={half_circle2} alt="" />
                </div>
                <div className={styles.thread1}>
                  <img src={thread} alt="" />
                </div>
                <div className={styles.path}>
                  <img src={path} alt="" />
                </div>
                <div className={styles.heading1}>
                  RC Details
                </div>
              </div>
              {/* GENERAL DETAILS */}
              <div className={styles.color_container}>
                <div className={styles.gen1}>Owner:  {maskedOwner}</div>
                <div className={styles.gen1}>Reg No:  {regNo}</div>
                <div className={styles.gen1}>Status As On: {statusAsOn}</div>
              </div>
              <div className={styles.color_container}>
                <div className={styles.gen2}>Vehicle Manufacturer Name: {vehicleManufacturerName}</div>
                <div className={styles.gen2}>Model: {model}</div>
              </div>
              {/* VEHICLE */}
              <div className={styles.heading}>Vehicle Information</div>
              <div className={styles.inner_container}>
                <div className={styles.dark}>
                  <div className={styles.left}>Reg No</div>
                  <div className={styles.right}>{regNo}</div>
                </div>
                <div className={styles.light}>
                  <div className={styles.left}>Status As On</div>
                  <div className={styles.right}>{statusAsOn}</div>
                </div>
                <div className={styles.dark}>
                  <div className={styles.left}>Reg Authority</div>
                  <div className={styles.right}>{regAuthority}</div>
                </div>
                <div className={styles.light}>
                  <div className={styles.left}>Reg Date</div>
                  <div className={styles.right}>{regDate}</div>
                </div>
                <div className={styles.dark}>
                  <div className={styles.left}>Vehicle Class</div>
                  <div className={styles.right}>{vehicleClass}</div>
                </div>
                <div className={styles.light}>
                  <div className={styles.left}>Chassis</div>
                  <div className={styles.right}>{maskedChassisNumber}</div>
                </div>
                <div className={styles.dark}>
                  <div className={styles.left}>Engine</div>
                  <div className={styles.right}>{engine}</div>
                </div>
                <div className={styles.light}>
                  <div className={styles.left}>Vehicle Manufacturer Name</div>
                  <div className={styles.right}>{vehicleManufacturerName}</div>
                </div>
                <div className={styles.dark}>
                  <div className={styles.left}>Model</div>
                  <div className={styles.right}>{model}</div>
                </div>
                <div className={styles.light}>
                  <div className={styles.left}>Vehicle Colour</div>
                  <div className={styles.right}>{vehicleColour}</div>
                </div>
                <div className={styles.dark}>
                  <div className={styles.left}>Type</div>
                  <div className={styles.right}>{type}</div>
                </div>
                <div className={styles.light}>
                  <div className={styles.left}>Norms Type</div>
                  <div className={styles.right}>{normsType}</div>
                </div>
                <div className={styles.dark}>
                  <div className={styles.left}>Status</div>
                  <div className={styles.right}>{status}</div>
                </div>
                <div className={styles.light}>
                  <div className={styles.left}>NOC Details</div>
                  <div className={styles.right}>{nocDetails === "NA" ? 'Information Not Available' : nocDetails}</div>
                </div>
                <div className={styles.dark}>
                  <div className={styles.left}>Vehicle Manufacturing Month Year</div>
                  <div className={styles.right}>{vehicleManufacturingMonthYear}</div>
                </div>
                <div className={styles.light}>
                  <div className={styles.left}>RC Expiry Date</div>
                  <div className={styles.right}>{rcExpiryDate}</div>
                </div>
                <div className={styles.dark}>
                  <div className={styles.left}>Vehicle Tax Upto</div>
                  <div className={styles.right}>{vehicleTaxUpto}</div>
                </div>
                <div className={styles.light}>
                  <div className={styles.left}>Vehicle Cubic Capacity</div>
                  <div className={styles.right}>{vehicleCubicCapacity}</div>
                </div>
                <div className={styles.dark}>
                  <div className={styles.left}>Gross Vehicle Weight</div>
                  <div className={styles.right}>{grossVehicleWeight}</div>
                </div>
                <div className={styles.light}>
                  <div className={styles.left}>Unladen Weigh</div>
                  <div className={styles.right}>{unladenWeight}</div>
                </div>
                <div className={styles.dark}>
                  <div className={styles.left}>Vehicle Category</div>
                  <div className={styles.right}>{vehicleCategory}</div>
                </div>
                <div className={styles.light}>
                  <div className={styles.left}>RC Standard Cap</div>
                  <div className={styles.right}>{rcStandardCap}</div>
                </div>
                <div className={styles.dark}>
                  <div className={styles.left}>Vehicle Cylinders No</div>
                  <div className={styles.right}>{vehicleCylindersNo}</div>
                </div>
                <div className={styles.light}>
                  <div className={styles.left}>Vehicle Seat Capacity</div>
                  <div className={styles.right}>{vehicleSeatCapacity}</div>
                </div>
                <div className={styles.dark}>
                  <div className={styles.left}>Vehicle Sleeper Capacity</div>
                  <div className={styles.right}>{vehicleSleeperCapacity}</div>
                </div>
                <div className={styles.light}>
                  <div className={styles.left}>Vehicle Standing Capacity</div>
                  <div className={styles.right}>{vehicleStandingCapacity}</div>
                </div>
                <div className={styles.dark}>
                  <div className={styles.left}>Wheelbase</div>
                  <div className={styles.right}>{wheelbase}</div>
                </div>
                <div className={styles.light}>
                  <div className={styles.left}>Blacklist Status</div>
                  <div className={styles.right}>{blacklistStatus}</div>
                </div>

              </div>
              {/* OWNER */}
              <div className={styles.heading}>Ownership Details</div>
              <div className={styles.inner_container}>
                <div className={styles.dark}>
                  <div className={styles.left}>Owner</div>
                  <div className={styles.right}>{maskedOwner}</div>
                </div>
                <div className={styles.light}>
                  <div className={styles.left}>Owner Count</div>
                  <div className={styles.right}>{maskedOwnerCount}</div>
                </div>
                <div className={styles.dark}>
                  <div className={styles.left}>Owner Father Name</div>
                  <div className={styles.right}>{maskedOwnerFatherName === null ? 'Information Not Available' : maskedOwnerFatherName}</div>
                </div>
                <div className={styles.light}>
                  <div className={styles.left}>Mobile Number</div>
                  <div className={styles.right}>{maskedMobileNumber === null ? 'Information Not Available' : maskedMobileNumber}</div>
                </div>
                <div className={styles.dark}>
                  <div className={styles.left}>Present Address</div>
                  <div className={styles.right}>{maskedPresentAddress}</div>
                </div>
                <div className={styles.light}>
                  <div className={styles.left}>Permanent Address</div>
                  <div className={styles.right}>{maskedPermanentAddress}</div>
                </div>
              </div>
              {/* INSURANCE */}
              <div className={styles.heading}>Insurance Details</div>
              <div className={styles.inner_container}>
                <div className={styles.dark}>
                  <div className={styles.left}>Vehicle Insurance Company Name</div>
                  <div className={styles.right}>{vehicleInsuranceCompanyName}</div>
                </div>
                <div className={styles.light}>
                  <div className={styles.left}>Vehicle Insurance Upto</div>
                  <div className={styles.right}>{vehicleInsuranceUpto}</div>
                </div>
                <div className={styles.dark}>
                  <div className={styles.left}>Vehicle Insurance Policy Number</div>
                  <div className={styles.right}>{vehicleInsurancePolicyNumber}</div>
                </div>
              </div>
              {/* HYPOTHECATION */}
              <div className={styles.heading}>Hypothecation Status</div>
              <div className={styles.inner_container}>
                <div className={styles.light}>
                  <div className={styles.left}>Financed</div>
                  <div className={styles.right}>{financed === false ? 'No' : 'Yes'}</div>
                </div>
                <div className={styles.dark}>
                  <div className={styles.left}>RC Financer</div>
                  <div className={styles.right}>{rcFinancer}</div>
                </div>
              </div>
              {/* PERMIT */}
              <div className={styles.heading}>Permit Details</div>
              <div className={styles.inner_container}>
                <div className={styles.dark}>
                  <div className={styles.left}>Is Commercial</div>
                  <div className={styles.right}>{isCommercial === false ? 'No' : 'Yes'}</div>
                </div>
                <div className={styles.light}>
                  <div className={styles.left}>Permit Issue Date</div>
                  <div className={styles.right}>{permitIssueDate === null ? 'Not Applicable' : permitIssueDate}</div>
                </div>
                <div className={styles.dark}>
                  <div className={styles.left}>Permit Number</div>
                  <div className={styles.right}>{permitNumber === null ? 'Not Applicable' : permitNumber}</div>
                </div>
                <div className={styles.light}>
                  <div className={styles.left}>Permit Type</div>
                  <div className={styles.right}>{permitType === null ? 'Not Applicable' : permitType}</div>
                </div>
                <div className={styles.dark}>
                  <div className={styles.left}>Permit Valid From</div>
                  <div className={styles.right}>{permitValidFrom === null ? 'Not Applicable' : permitValidFrom}</div>
                </div>
                <div className={styles.light}>
                  <div className={styles.left}>Permit Valid Upto</div>
                  <div className={styles.right}>{permitValidUpto === null ? 'Not Applicable' : permitValidUpto}</div>
                </div>
                <div className={styles.dark}>
                  <div className={styles.left}>Non Use Status</div>
                  <div className={styles.right}>{nonUseStatus === null ? 'Not Applicable' : nonUseStatus}</div>
                </div>
                <div className={styles.light}>
                  <div className={styles.left}>Non Use From</div>
                  <div className={styles.right}>{nonUseFrom === null ? 'Not Applicable' : nonUseFrom}</div>
                </div>
                <div className={styles.dark}>
                  <div className={styles.left}>Non Use To</div>
                  <div className={styles.right}>{nonUseTo === null ? 'Not Applicable' : nonUseTo}</div>
                </div>
                <div className={styles.light}>
                  <div className={styles.left}>National Permit Number</div>
                  <div className={styles.right}>{nationalPermitNumber === null ? 'Not Applicable' : nationalPermitNumber}</div>
                </div>
                <div className={styles.dark}>
                  <div className={styles.left}>National Permit Upto</div>
                  <div className={styles.right}>{nationalPermitUpto === null ? 'Not Applicable' : nationalPermitUpto}</div>
                </div>
                <div className={styles.light}>
                  <div className={styles.left}>National Permit Issued By</div>
                  <div className={styles.right}>{nationalPermitIssuedBy === null ? 'Not Applicable' : nationalPermitIssuedBy}</div>
                </div>
              </div>
              {/* FOOTER */}
              <div className={styles.footer}>
                <div className={styles.triangle}>
                  <img src={triangle} alt="" />
                </div>
                <div className={styles.thread1}>
                  <img src={thread} alt="" />
                </div>
                <div className={styles.thread2}>
                  <img src={thread} alt="" />
                </div>
                <div className={styles.car}>
                  <img src={car} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {
          openWhatsApp && (
            <Modal
              open={handleWhatsappModal}
              onClose={handleWhatsappModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={isMobile ? styleMUIModalMobile : styleMUIModal}>
                <div className={styles.share}>
                  <div className={styles.share_heading}>Share PDF</div>

                  <div className={styles.flex}>
                    <div className={styles.share_icon}>
                      <WhatsappShareButton url={pdfUrl}>
                        <WhatsappIcon size={50} round={true} />
                      </WhatsappShareButton>
                    </div>
                    <div className={styles.share_icon}>
                      <FacebookShareButton url={pdfUrl}>
                        <FacebookIcon size={50} round={true} />
                      </FacebookShareButton>
                    </div>
                    <div className={styles.share_icon}>
                      <TwitterShareButton url={pdfUrl}>
                        <TwitterIcon size={50} round={true} />
                      </TwitterShareButton>
                    </div>
                    <div className={styles.share_icon}>
                      <LinkedinShareButton url={pdfUrl}>
                        <LinkedinIcon size={50} round={true} />
                      </LinkedinShareButton>
                    </div>
                    <div className={styles.share_icon}>
                      <EmailShareButton url={pdfUrl}>
                        <EmailIcon size={50} round={true} />
                      </EmailShareButton>
                    </div>
                    <div className={styles.share_icon}>
                      <TelegramShareButton url={pdfUrl}>
                        <TelegramIcon size={50} round={true} />
                      </TelegramShareButton>
                    </div>
                  </div>

                </div>
              </Box>
            </Modal>
          )
        }
      </>
    </div>
  );
}