import React, { useEffect, useRef, useState } from 'react'
import styles from './NewCards.module.css'
import img2 from '../../../images/vectors/channel_partner/newCards/loan_for_you.svg'
import img1 from '../../../images/vectors/channel_partner/newCards/loan_customer.svg'
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Partner from '../../FORMS/Partner/Partner'

const styleMUIModal = {
    position: "absolute",
    top: "0%",
    right: "0%",
    width: '40%',
    height: '100%',
    bgcolor: "white",
    // border: "0px solid #000",
    boxShadow: 24,
    outline: 0,
    p: 4,
};

const styleMUIModal1 = {
    position: "absolute",
    top: "0%",
    right: "0%",
    width: '80%',
    height: '100vh',
    // marginTop: '7vh',
    // border: "0px solid #000",
    bgcolor: `var(--tan_teal)`,
    boxShadow: 24,
    outline: 0,
    p: 4,
};

const styleMUIModalMobile = {
    width: "100%",
}


export default function NewCards() {
    const [openPartnerModal, setOpenPartnerModal] = useState(false);
    const handleClose = () => setOpenPartnerModal(false);

    const getAppStoreLink = () => {
        if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
            return 'https://apps.apple.com/in/app/nxcar-partners/id6474630163';
        } else {
            return 'https://play.google.com/store/apps/details?id=com.applore.nxfin';
        }
    };

    const handlePartnerForm = () => {
        setOpenPartnerModal(true);
        // window.location.href = "partner-form";
    }

    return (
        <>
        
            <div className={styles.dark}>
                <div className={styles.container}>
                    <div className={styles.heading}>
                        We are your trusted partners
                    </div>
                    <div className={styles.sub_heading}>
                        Nxcar is a pioneering venture that specializes in facilitating pre-owned car acquisitions.
                    </div>
                    <div className={styles.card_container}>
                        <div className={styles.card}>
                            <div className={styles.card_image}>
                                <img src={img1} alt="nxcar_form_loans_used_car" />
                            </div>
                            <div className={styles.card_heading}>
                                Used Car Loans for Your Customers
                            </div>
                            <div className={styles.buy_card_sub_heading}>
                                <ol>
                                    <li>
                                        2X customer satisfaction
                                    </li>
                                    <li>
                                        Boost revenue with payouts per transaction
                                    </li>
                                    <li>
                                        Speed up your sales
                                    </li>
                                    <li>
                                        Expand service portfolio
                                    </li>
                                </ol>
                            </div>

                            <div
                                className={styles.CTA2}
                                onClick={handlePartnerForm}
                            >
                                Apply Now
                            </div>
                        </div>

                        <div className={styles.card}>
                            <div className={styles.card_image}>
                                <img src={img2} alt="nxcar_form_loans_used_car" />
                            </div>
                            <div className={styles.card_heading}>
                                Used Car Loans for You
                            </div>
                            <div className={styles.buy_card_sub_heading}>
                                <ol>
                                    <li>
                                        Flexible loan limit
                                    </li>
                                    <li>
                                        Quick loan disbursement
                                    </li>
                                    <li>
                                        Nxcar doesn’t compete with its partners
                                    </li>
                                    <li>
                                        Loan for cars from multiple sources
                                    </li>
                                </ol>
                            </div>
                            <a href={getAppStoreLink()} target='_blank'>
                                <div className={styles.CTA2}>
                                    Download App
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            {/* MODAL */}
            <Modal
                open={openPartnerModal}
                onClose={() => setOpenPartnerModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{ ...styleMUIModal, ...(window.innerWidth <= 600 && styleMUIModalMobile) }}>
                    <Partner onClose={handleClose} />
                </Box>
            </Modal>
            {/* MODAL */}
        </>
    )
}
