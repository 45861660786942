import Footer from '../../../components/Footer/Footer'
import Header from '../../../components/Header/Header'
import Table from '../../../reusable/table/Table'
import styles from './Extended.module.css'

const ExtendedWarranty = () => {
    const columns = [
        { label: 'S.No.', field: 's_no' },
        { label: 'Model', field: 'model' },
        { label: '6 months Engine Transmission', field: 'six_1' },
        { label: '6 months Comprehensive', field: 'six_2' },
        { label: '12 months Engine Transmission', field: 'two_1' },
        { label: '12 months Engine Comprehensive', field: 'two_2' },
    ];

    const currentData = [
        {
            s_no: '1',
            model: 'Up to 1300 cc',
            six_1: '3999',
            six_2: '5999',
            two_1: '6499',
            two_2: '9999'
        },
        {
            s_no: '2',
            model: '1301 to 1600 cc',
            six_1: '4999',
            six_2: '7499',
            two_1: '7999',
            two_2: '11999'
        },
        {
            s_no: '3',
            model: '1601 to 2000 cc',
            six_1: '5999',
            six_2: '8999',
            two_1: '9499',
            two_2: '13999'
        },
        {
            s_no: '4',
            model: '2001 cc to 2500 cc (normal segment)',
            six_1: '6999',
            six_2: '9999',
            two_1: '11499',
            two_2: '16999'
        },
    ];
    return (
        <>
            <div className={styles.container1}>
                <Header />
                <div className={styles.container}>
                    <div className={styles.heading}>
                        Eligibility criteria for extended warranty with terms and conditions
                    </div>
                    <div className={styles.single_container}>
                        <div className={styles.single_heading}>
                            Warranty Eligibility Criteria
                        </div>
                        <div className={styles.single_points}>
                            <ol>
                                <li>
                                    Vehicle is not more than 10 years old or run more than 1,00,000 kms.
                                </li>
                                <li>
                                    Not more than 3 owners
                                </li>
                                <li>
                                    Non accidental
                                </li>
                                <li>
                                    Must be inspected & certified before issuance of warranty
                                </li>
                            </ol>
                        </div>
                    </div>

                    <div className={styles.single_heading}>
                        Warranty Pricing
                    </div>
                    <Table data={currentData} columns={columns} />

                    <div className={styles.main_container}>
                        <div className={styles.sub_heading}>
                            Parts Covered List (Engine & Transmission Warranty)
                        </div>
                        <ol>
                            <li className={styles.bold_points}>
                                Engine
                            </li>
                            <div className={styles.sub_points}>
                                Oil pump, crankshaft & pulley, big end & main bearings, connecting rods, gudgeon pins, piston and rings, inlet and exhaust valves (excluding burnt and pitted valves), springs and guides, cylinder block and cylinder head, head gasket, camshaft, rocker arms and shaft, timing gears, tensioner bearing, inter cooler engine, water Pump (failure due to external damage or corrosion is not covered), inlet and exhaust manifold, turbo charger, elastic bolts.
                            </div>
                            <li className={styles.bold_points}>
                                Manual Gearbox
                            </li>
                            <div className={styles.sub_points}>
                                Gears, shafts, synchromesh hubs, rings, selectors, bearings, transfer gears
                            </div>
                            <li className={styles.bold_points}>
                                Automatic Gearbox
                            </li>
                            <div className={styles.sub_points}>
                                Shafts, gears, brake / clutch bands, oil pump, bearings, bushes, valve, drive plate, transfer gears, transmission gears and transmission unit
                            </div>
                            <li className={styles.bold_points}>
                                Front & Rear Wheel Drives
                            </li>
                            <div className={styles.sub_points}>
                                Gears, differential units, wheel bearings, Axle Shafts, CV Joints
                            </div>
                            <li className={styles.bold_points}>
                                Propeller shaft
                            </li>
                            <div className={styles.sub_points}>
                                Propeller shaft, universal joints
                            </div>
                        </ol>
                    </div>

                    <div className={styles.main_container}>
                        <div className={styles.sub_heading}>
                            Following additional parts are covered under Comprehensive Warranty
                        </div>
                        <ol>
                            <li className={styles.bold_points}>
                                Steering
                            </li>
                            <div className={styles.sub_points}>
                                Rack & pinion, steering column, steering box, and power steering pump, EPS
                            </div>
                            <li className={styles.bold_points}>
                                Braking System
                            </li>
                            <div className={styles.sub_points}>
                                Master cylinder, brake booster and vacuum pump, Wheel bearing, Callipers
                            </div>
                            <li className={styles.bold_points}>
                                Fuel System
                            </li>
                            <div className={styles.sub_points}>
                                Mechanical & Electrical Fuel Pump, Fuel Injection Pump, Fuel accumulator, fuel distributor, fuel rail, warm up regulator, cold start valve, pressure damper, throttle body, High and low pressure pumps
                            </div>
                            <li className={styles.bold_points}>
                                Diesel Injection System
                            </li>
                            <div className={styles.sub_points}>
                                Injection pump, electromagnetic cut off
                            </div>
                            <li className={styles.bold_points}>
                                Air Conditioning
                            </li>
                            <div className={styles.sub_points}>
                                Compressor, condenser, evaporator, reservoir, thermostat switch, AC fan motor, heater core & AC switch, Control unit , Blower motor , High and Low pressure pipelines , Clutch magnate
                            </div>
                            <li className={styles.bold_points}>
                                Electrical System
                            </li>
                            <div className={styles.sub_points}>
                                Engine management sensors & actuators, starter motor, solenoid switch, alternator, rectifier, regulator, ignition coil, cooling fan motor, power window motor, power window switch, combination switch, ignition switch, fuel rail, thermostat switch, oil Pressure switch, temperature gauge, fuel gauge, Hazard switch, Electronic control unit, Body control unit, HT cable, Wiper washer motor, Immobilizer ECU, ECM, Speedometer
                            </div>
                        </ol>
                    </div>

                    <div className={styles.main_container}>
                        <div className={styles.sub_heading}>
                            Warranty Shall Not Apply To
                        </div>
                        <ol>
                            <li className={styles.light_points}>
                                Failure to maintain the vehicle as per recommended warranty service policy.
                            </li>
                            <li className={styles.light_points}>
                                Any damage that results from hard driving due to race, rally.
                            </li>
                            <li className={styles.light_points}>
                                On any part that is not mentioned in the Parts Covered List.
                            </li>
                            <li className={styles.light_points}>
                                Any damage that results from use of non-genuine parts or accessories other than those approved by respective manufacturer.
                            </li>
                            <li className={styles.light_points}>
                                Any non-manufacturer approved accessories which are not supplied with vehicle as OE fitment and manufacturer rubber and plastic accessories.
                            </li>
                            <li className={styles.light_points}>
                                Inconsequential aspects such as noises, vibrations, oil seepage and sensations that could not lead to dismal product function or performance.
                            </li>
                            <li className={styles.light_points}>
                                Any loss arising out of accident caused by external impact.
                            </li>
                            <li className={styles.light_points}>
                                Aesthetic parts including sheet metal & plastic parts requiring replacement/repair due to wear & tear, depreciation, moth, vermin, process of cleaning, restoring or renovating of scratches, effect of light, sun or any atmospheric conditions, bird droppings.
                            </li>
                            <li className={styles.light_points}>
                                Mechanical & electrical breakdown caused by overloading, strain, overrunning, freezing, excessive pressure, short-circuiting heating.
                            </li>
                            <li className={styles.light_points}>
                                Routine maintenance service jobs, including cleaning, polishing, minor adjustment, engine tuning, carbon cleaning, AC Servicing, AC gas leak, wheel alignment, tire rotation, calibration /reconditioning of diesel equipment’s including injector/nozzles and fuel pump etc
                            </li>
                            <li className={styles.light_points}>
                                All consumables, including, engine oils, gear oil, brake oil, power steering oil, coolant, AC gas oil, grease, battery electrolyte, radiator coolant.
                            </li>
                            <li className={styles.light_points}>
                                Rusting/Corrosion to metal parts.
                            </li>
                            <li className={styles.light_points}>
                                Damage to engine parts & fuel injection as a result of use of poor quality of fuel, coolant or lubricating oil to fuel system including fuel pump, injector nozzle, water pump as well as other parts.
                            </li>
                            <li className={styles.light_points}>
                                Damage to engine due to hydrostatic lock or starvation.
                            </li>
                            <li className={styles.light_points}>
                                Failure to maintain the odometer in working order or disconnecting or tampering with it will invalidate the Warranty
                            </li>
                            <li className={styles.light_points}>
                                Driving even after a fault or defect has been noticed resulting in consequential damage and loss.
                            </li>
                            <li className={styles.light_points}>
                                Interior and exterior linings, covers, absorbers and upholstery.
                            </li>
                            <li className={styles.light_points}>
                                Immobile gaskets, such as flat- and paper gaskets, which are not involved in mechanical movements (except e.g. leak on water-bearing technical units as bonnet, water hoses, cylinder head gasket, radiator as well as air-conditioning)
                            </li>
                            <li className={styles.light_points}>
                                All frame and body parts, convertible and fold cover (except mechanical and electronic parts), glass, headlight casings, lighting inside and outside.
                            </li>
                            <li className={styles.light_points}>
                                Clutch disk, brake linings and drums, disks and blocks, tires, wheel balancing, feathers/springs, pneumatic springs and pneumatic spring absorbers.
                            </li>
                            <li className={styles.light_points}>
                                Body and Paint work including any losses due to accidental damages.
                            </li>
                        </ol>
                    </div>

                    <div className={styles.main_container}>
                        <div className={styles.sub_heading}>
                            Terms and Conditions
                        </div>
                        <ol>
                            <li className={styles.points}>
                                Vehicles, which are up to 10 years old or run less than 1,00,000 km from the date of first sale, are eligible for this warranty product and the vehicle sale invoice shall be the conclusive proof of same.
                            </li>
                            <li className={styles.points}>
                                Vehicles to undergo inspection before issuance of warranty. Previous history/odometer reading of the vehicle will not be the basis of any claim rejection, warranty will be issued based on the actual odometer reading showing at the time of inspection.
                            </li>
                            <li className={styles.points}>
                                Vehicles owned, temporarily or otherwise, by a business formed for the purpose of selling or servicing motor vehicles are excluded.
                            </li>
                            <li className={styles.points}>
                                Warranty has no surrender value, no refund of cost is available, and it cannot be cancelled.
                            </li>
                            <li className={styles.points}>
                                Services must be completed at the correct time/s and mileage/s. The beneficiary must retain all the service invoices and relevant receipts. Failure to service the vehicle in accordance with the recommended service schedule will render the Warranty invalid.
                            </li>
                            <li className={styles.points}>
                                The mileage quoted in no way guarantees the true distances covered by vehicle and is indicated only as a guide to when servicing is due. Failure to maintain the odometer, mileage recorder in working order or disconnecting it or tampering with it will invalidate Warranty.
                            </li>
                            <li className={styles.points}>
                                No liability will be accepted for damage caused by neglect, corrosion, intrusion of foreign or deleterious matter, lack of servicing, overheating, freezing or abuse by the continued use of vehicle after a fault has become evident or for consequential loss on the failure of parts not covered by Warranty.
                            </li>
                            <li className={styles.points}>
                                No liability will be accepted for any failure due to wear and tear, components incorrectly fitted, or faulty or defective at the time of sale, or faulty manufacture/design and alterations/modifications from the manufacturer’s specification.
                            </li>
                            <li className={styles.points}>
                                If it shall be established following the receipt of the claim that the conditions of Warranty as laid down have not been fully complied with, then it is hereby expressly agreed and declared that the Extended Warranty Dealer / Franchise shall be released from all liability for that particular claim.
                            </li>
                            <li className={styles.points}>
                                All Disputes / differences regarding claim settlement, arising for whatsoever reason shall be subject to jurisdiction of New Delhi courts only.
                            </li>
                            <li className={styles.points}>
                                The pricing mentioned above is not applicable to luxury cars.
                            </li>
                            <li className={styles.points}>
                                General Motors cars are not eligible for warranty.
                            </li>
                            <li className={styles.points}>
                                For Ford cars warranty depends on inspection.
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <Footer />
            </div>
        </>
    )
}

export default ExtendedWarranty