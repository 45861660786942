import Fade from 'react-reveal/Fade';
import React, { useEffect, useState } from "react";
import { useMediaQuery } from '@material-ui/core';
import { MdCurrencyRupee } from "react-icons/md";
import { FaRegClock } from "react-icons/fa6";
import { AiOutlinePercentage } from "react-icons/ai";
import styles from './PieChart.module.css';
import { Link } from "react-router-dom";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Box, Modal } from '@material-ui/core';
import NewLoan from '../../../reusable/loanEligibility/NewLoanEligibility/NewLoan';
ChartJS.register(ArcElement, Tooltip, Legend);

const styleMUIModal = {
  position: "absolute",
  top: "0%",
  right: "0%",
  width: '40%',
  height: '100%',
  bgcolor: "white",
  border: "0px solid #000",
  boxShadow: 24,
  overflowY: "auto",
  outline: "none",
  p: 0,
};
const styleMUIModalMobile = {
  width: "100%",
  height: "100vh",
  top: "0%",
  right: "0%",
  height: '100vh',
  bgcolor: "white",
  border: "0px solid #000",
  boxShadow: 24,
  outline: "none",
  overflowY: "auto",
  p: 0,
}


// Utility function to format numbers with commas
const formatNumberWithCommas = (number) => {
  return number.toString().replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
};


// RANGES OR SLIDER REUABLE COMPONENT
const SliderInput = ({ label, value, onChange, min, max, step, placeholder }) => {
  const getGradient = () => {
    const percentage = ((value - min) / (max - min)) * 100;
    return `linear-gradient(to right, #95F6F0 0%, #4AA09B ${percentage}%, whitesmoke ${percentage}%, whitesmoke 100%)`;
  };

  return (
    <div className={styles.right_container}>
      <div className={styles.label}>
        <div className={styles.text}>
          {label}
        </div>
        <div className={styles.number}>
          <div className={styles.icon}>
            {label === "Loan Amount" ? <MdCurrencyRupee /> : label === "Duration Of Loan" ? <FaRegClock /> : <AiOutlinePercentage />}
          </div>
          <input
            type="text"
            // value={label === "Loan Amount" ? formatNumberWithCommas(value) : value}
            value={isNaN(value) ? "0" : label === "Loan Amount" ? formatNumberWithCommas(value) : value}
            onChange={(e) => onChange(parseFloat(e.target.value.replace(/,/g, '')))}
            placeholder={placeholder}
            min={min}
            max={max}
            step={step}
          />
        </div>
      </div>

      {/* SLIDER */}
      <div className={styles.slider_container}>
        <div className={styles.slider}>
          <input
            className={styles.slider_main}
            type="range"
            value={value}
            onChange={(e) => onChange(parseFloat(e.target.value))}
            min={min}
            max={max}
            step={step}
            style={{ background: getGradient() }}
          />
        </div>
        {/* NUMBERS TO BE SHOWN */}
        <div className={styles.slider_number}>
          <div className={styles.icon}>
            {label === "Loan Amount" ? "₹" : ""}
            {formatNumberWithCommas(min)}
            {label === "Loan Amount" ? "" : label === "Duration Of Loan" ? " Months" : <AiOutlinePercentage />}
          </div>
          <div className={styles.icon}>
            {label === "Loan Amount" ? "₹" : ""}
            {formatNumberWithCommas(max)}
            {label === "Loan Amount" ? "" : label === "Duration Of Loan" ? " Months" : <AiOutlinePercentage />}
          </div>
        </div>
      </div>
    </div>
  );
};

// EMI CALCULATIONS
const EMIResults = ({ loanAmount, duration, interest }) => {
  const isMobile = useMediaQuery('(max-width: 940px)');
  const [isLoanFormVisible, setIsLoanFormVisible] = useState(false);

  const handleOpenLoanForm = () => {
    setIsLoanFormVisible(true);
  };

  const handleCloseLoanForm = () => {
    setIsLoanFormVisible(false);
  };

  const [totalInterest, setTotalInterest] = useState(0);
  const [totalPrincipal, setTotalPrincipal] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [emi, setEMI] = useState(0);

  useEffect(() => {
    const calculateEMI = () => {
      const P = loanAmount;
      const r = interest / (12 * 100);
      const n = duration;

      const emiValue = (P * r * Math.pow((1 + r), n)) / (Math.pow((1 + r), n) - 1);

      const totalAmountPaid = Math.round(emiValue) * n;
      const interestAmount = totalAmountPaid - P;

      setEMI(Math.round(emiValue));
      setTotalInterest(Math.round(interestAmount));
      setTotalPrincipal(Math.round(loanAmount));
      setTotalAmount(Math.round(totalAmountPaid));
    };

    calculateEMI();
  }, [loanAmount, duration, interest]);

  return (
    <>
      <div className={styles.EMI_left}>
        <div className={styles.main_pie_chart}>
          <Pie
            data={{
              datasets: [{
                data: [totalPrincipal, totalInterest],
                backgroundColor: ['#addbd8', '#4AA09B']
              }]
            }}
            className={styles.my_pie}
          />
          <div className={styles.pie_heading1}>
            <MdCurrencyRupee /> {isNaN(emi) ? formatNumberWithCommas(0) : formatNumberWithCommas(emi)}
          </div>
          <div className={styles.pie_heading2}>
            EMI Per Month
          </div>
        </div>

        <div className={styles.left_text}>
          <div className={styles.left_text_1}>
            Total Interest Amount
          </div>
          <div className={styles.left_text_2}>
            <MdCurrencyRupee /> {isNaN(totalInterest) ? formatNumberWithCommas(0) : formatNumberWithCommas(totalInterest)}
          </div>
        </div>

        <div className={styles.left_text}>
          <div className={styles.left_text_1}>
            Total Principal Amount
          </div>
          <div className={styles.left_text_2}>
            <MdCurrencyRupee /> {isNaN(totalPrincipal) ? formatNumberWithCommas(0) : formatNumberWithCommas(totalPrincipal)}
          </div>
        </div>

        <div className={styles.left_text}
          style={{
            borderTop: '0.5px solid #6F7978',
            paddingTop: ' 10px'
          }}>
          <div className={styles.left_text_1} >
            Payable Amount
          </div>
          <div className={styles.left_text_2}>
            <MdCurrencyRupee /> {isNaN(totalAmount) ? formatNumberWithCommas(0) : formatNumberWithCommas(totalAmount)}
          </div>
        </div>


        <div
          onClick={handleOpenLoanForm}
          className={styles.button}>
          Apply Now
        </div>
      </div>
      {
        isLoanFormVisible && (
          <Modal
            open={isLoanFormVisible}
            onClose={handleCloseLoanForm}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={isMobile ? styleMUIModalMobile : styleMUIModal}>
              <NewLoan handleClose={handleCloseLoanForm} />
            </Box>
          </Modal>
        )
      }
    </>
  );
};

// PIE CHARTS
const PieChart = () => {
  const [loanAmount, setLoanAmount] = useState(100000);
  const [duration, setDuration] = useState(12);
  const [interest, setInterest] = useState(12);


  return (
    <div className={styles.container}>
      <Fade bottom>
        <div className={styles.heading}>
          {/* Car Loan EMI Calculator */}
          Car EMI Calculator
          <div className={styles.sub_heading}>
            {/* Customize Your Loan Amount with Ease */}
            Customize your loan amount with ease
          </div>
        </div>
        <div className={styles.main_container}>

          <div className={styles.left}>
            <EMIResults loanAmount={loanAmount} duration={duration} interest={interest} />
          </div>

          <div className={styles.right}>
            <SliderInput label="Loan Amount" value={loanAmount} onChange={setLoanAmount} min={100000} max={2000000} placeholder="10000" />
            <SliderInput label="Duration Of Loan" value={duration} onChange={setDuration} min={12} max={84} placeholder="12 Months" />
            <SliderInput label="Interest Rate" value={interest} onChange={setInterest} min={12} max={24} step={0.5} placeholder="12 %" />
          </div>
        </div>
      </Fade>
    </div>
  );
};



export default PieChart;
