import { useEffect } from 'react';
import styles from './Footer.module.css';
import logo from '../../images/logos/logo.svg'
import instagram from '../../images/logos/instagram.svg'
import whatsapp from '../../images/logos/whatsapp.svg'
import facebook from '../../images/logos/facebook.svg'
import linkdin from '../../images/logos/linkdin.svg'
import apple from '../../images/logos/apple.svg'
import playstore from '../../images/logos/playstore.svg'
import { Link } from 'react-router-dom';
import { scrollToTop } from '../../reusable/scrollToTop/ScrollToTopButton';

const Footer = () => {
    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.src = 'https://d3mkw6s8thqya7.cloudfront.net/integration-plugin.js';
    //     script.id = 'aisensy-wa-widget';
    //     script.setAttribute('widget-id', 'PIOHBD');
    //     script.async = true;
    //     document.body.appendChild(script);

    //     return () => {
    //         document.body.removeChild(script);
    //     };
    // }, []);
    return (
        <>
            <div className={styles.main_footer_container}>
                <div className={styles.footer}>
                    <div className={styles.right}>
                        <div className={styles.logo}>
                            <Link to='/'>
                                <img src={logo} alt="" />
                            </Link>
                        </div>
                        <div className={styles.tagline}>
                            Nxcar is upgrading the used car buying experience to be as transparent and delightful as buying a new car. We offer used car loans, inspection, extended warranty, Insurance Transfer, RC Transfer and other used car-specific services so that you can buy a pre-owned vehicle with confidence directly from any seller, be it a dealer or an individual.
                        </div>
                        <div className={styles.logo_container}>
                            <a href="https://www.facebook.com/nxfin" target='_blank'>
                                <img src={facebook} alt="" />
                            </a>
                            <a href="https://www.instagram.com/nxcarindia/" target='_blank'>
                                <img src={instagram} alt="" />
                            </a>
                            <a href="https://www.linkedin.com/company/nxfin/mycompany/?viewAsMember=true" target='_blank'>
                                <img src={linkdin} alt="" />
                            </a>
                            <a href="https://api.whatsapp.com/send/?phone=%2B919355924132&text&type=phone_number&app_absent=0" target='_blank'>
                                <img src={whatsapp} alt="" />
                            </a>
                        </div>


                        <div className={styles.button_container2}>
                            <a href="https://apps.apple.com/in/app/nxcar-partners/id6474630163" target='_blank'>
                                <div className={styles.ios_app}>
                                    <img src={apple} alt="" />
                                    <p>
                                        Get it on
                                    </p>
                                </div>
                            </a>

                            <a href="https://play.google.com/store/apps/details?id=com.applore.nxfin" target='_blank'>
                                <div className={styles.ios_app}>
                                    <img src={playstore} alt="" />
                                    <p>
                                        Get it on
                                    </p>
                                </div>
                            </a>
                        </div>

                    </div>

                    <div className={styles.left}>
                        <div className={styles.mobile_column}>
                            <div className={styles.heading}>
                                General
                            </div>
                            <div className={styles.sub_heading}>
                                <Link
                                    to="/about"
                                    onClick={scrollToTop}
                                >
                                    About Us
                                </Link>
                            </div>

                            <div className={styles.sub_heading}>
                                <Link to="/used-car-loan-special-offer" onClick={scrollToTop}>
                                    Special Offers
                                </Link>
                            </div>
                            <div className={styles.sub_heading}>
                                <Link to="/buy-sell" onClick={scrollToTop}>
                                    Buy & Sell Car
                                </Link>
                            </div>

                            <div className={styles.sub_heading}>
                                <Link to="/used-car-loan" onClick={scrollToTop}>
                                    Used Car Loan
                                </Link>
                            </div>
                            <div className={styles.sub_heading}>
                                <Link to="/car_services" onClick={scrollToTop}>
                                    Car Services
                                </Link>
                            </div>
                            <div className={styles.sub_heading}>
                                <Link to="/partner" onClick={scrollToTop}>
                                    Partners
                                </Link>
                            </div>
                            <div className={styles.sub_heading}>
                                <Link to="/calculator" onClick={scrollToTop}>
                                    Calculators
                                </Link>
                            </div>
                            <div className={styles.sub_heading}>
                                <Link to="/contact_us" onClick={scrollToTop}>
                                    Contact us
                                </Link>
                            </div>
                            <div className={styles.sub_heading}>
                                <Link to="/faq" onClick={scrollToTop}>
                                    FAQs
                                </Link>
                            </div>
                            <div className={styles.sub_heading}>
                                <Link to="/blogs-of-nxcar" onClick={scrollToTop}>
                                    Blogs
                                </Link>
                            </div>
                        </div>

                        <div className={styles.column_one}>
                            <div className={styles.heading}>
                                General
                            </div>
                            <div className={styles.sub_heading}>
                                <Link
                                    to="/about"
                                    onClick={scrollToTop}
                                >
                                    About Us
                                </Link>
                            </div>
                            <div className={styles.sub_heading}>
                                <Link to="/calculator" onClick={scrollToTop}>
                                    Calculators
                                </Link>
                            </div>
                            <div className={styles.sub_heading}>
                                <Link to="/blogs-of-nxcar" onClick={scrollToTop}>
                                    Blogs
                                </Link>
                            </div>
                            <div className={styles.sub_heading}>
                                <Link to="/contact_us" onClick={scrollToTop}>
                                    Contact us
                                </Link>
                            </div>
                            <div className={styles.sub_heading}>
                                <Link to="/faq" onClick={scrollToTop}>
                                    FAQs
                                </Link>
                            </div>

                            {/* <div className={styles.sub_heading}>Contact@nxcar.in</div>
                            <div className={styles.sub_heading}>9355924132</div> */}
                        </div>

                        <div className={styles.column_one}>
                            <div className={styles.heading}> Services </div>
                            <div className={styles.sub_heading}>
                                <Link to="/used-car-loan-special-offer" onClick={scrollToTop}>
                                    Special Offers
                                </Link>
                            </div>
                            <div className={styles.sub_heading}>
                                <Link to="/buy-sell" onClick={scrollToTop}>
                                    Buy & Sell Car
                                </Link>
                            </div>
                            <div className={styles.sub_heading}>
                                <Link to="/used-car-loan" onClick={scrollToTop}>
                                    Used Car Loan
                                </Link>
                            </div>
                            <div className={styles.sub_heading}>
                                <Link to="/car_services" onClick={scrollToTop}>
                                    Car Services
                                </Link>
                            </div>
                            <div className={styles.sub_heading}>
                                <Link to="/partner" onClick={scrollToTop}>
                                    Partners
                                </Link>
                            </div>
                        </div>

                        <div className={styles.column}>
                            <div className={styles.heading}>Legal</div>
                            <div className={styles.sub_heading}>
                                <Link to="/privacy-policy" onClick={scrollToTop}>
                                    Privacy Policy
                                </Link>
                            </div>
                            <div className={styles.sub_heading}>
                                <Link to="/terms-of-use" onClick={scrollToTop}>
                                    Terms Of Use
                                </Link>
                            </div>
                            <div className={styles.sub_heading}>
                                <Link to="/grievance-policy" onClick={scrollToTop}>
                                    Grievance Policy
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.button_container}>
                    <a href="https://apps.apple.com/in/app/nxcar-partners/id6474630163" target='_blank'>
                        <div className={styles.ios_app}>
                            <div className={styles.ios_app_image}>
                                <img src={apple} alt="" />
                            </div>
                            <div className={styles.ios_app_text}>
                                Get Nxcar
                                <br />
                                Partner App
                            </div>
                        </div>
                    </a>

                    <a href="https://play.google.com/store/apps/details?id=com.applore.nxfin" target='_blank'>
                        <div className={styles.ios_app}>
                            <div className={styles.ios_app_image}>
                                <img src={playstore} alt="" />
                            </div>
                            <div className={styles.ios_app_text}>
                                Get Nxcar
                                <br />
                                Partner App
                            </div>
                        </div>
                    </a>
                </div>

                <div className={styles.copyright}>
                    © 2023 Nxcar.in All rights reserved
                </div>
            </div>
        </>
    )
}

export default Footer
