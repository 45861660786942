import React from 'react'
import styles from './Culture.module.css';
import { Fade } from 'react-reveal';
import Transparency from './animations/Transparency';
import Empathy from './animations/Empathy';
import Integrity from './animations/Integrity';
import Frugality from './animations/Frugality';
import Growth from './animations/Growth';
import PartnerGrowth from './animations/PartnerGrowth';


const Culture = () => {
    return (
        <>
            <div className={styles.container}>
                <div className={styles.heading}>
                    Value & culture at  Nxcar
                </div>

                <div className={styles.cards}>
                    <Fade left>
                        <div className={styles.card}>
                            <div className={styles.image}>
                                <Transparency />
                            </div>
                            <div className={styles.sub_heading}>
                                Transparency
                            </div>
                            <div className={styles.intro}>
                                <div className={styles.sub_heading2}>
                                    Transparency
                                </div>
                                At Nxcar, we uphold transparency as a cornerstone of our operations. We believe in conducting our processes and interactions with clarity and openness, fostering trust and confidence in our relationships with customers and partners
                            </div>
                        </div>
                    </Fade>
                    <Fade bottom>
                        <div className={styles.card}>
                            <div className={styles.image}>
                                <Empathy />
                            </div>
                            <div className={styles.sub_heading}>
                                Empathy
                            </div>
                            <div className={styles.intro}>
                                <div className={styles.sub_heading2}>
                                    Empathy
                                </div>
                                We understand the challenges for participants transacting in unorganised spaces like the used car market, empathise with customer constraints, and commit to meeting their needs. Our dedication includes embracing inclusivity and respecting individual differences in our efforts to fulfil customer expectations.
                            </div>
                        </div>
                    </Fade>
                    <Fade right>
                        <div className={styles.card}>
                            <div className={styles.image}>
                                <Integrity />
                            </div>
                            <div className={styles.sub_heading}>
                                Integrity
                            </div>
                            <div className={styles.intro}>
                                <div className={styles.sub_heading2}>
                                    Integrity
                                </div>
                                We seek to be honest and objective while operating in an industry characterised by unknowns. We promote integrity and maintain a high level of professionalism in all our dealings, both within the organisation and with external stakeholders
                            </div>
                        </div>
                    </Fade>
                    <Fade left>
                        <div className={styles.card}>
                            <div className={styles.image}>
                                <Frugality />
                            </div>
                            <div className={styles.sub_heading}>
                                Efficiency and Frugality
                            </div>
                            <div className={styles.intro}>
                                <div className={styles.sub_heading2}>
                                    Efficiency and Frugality
                                </div>
                                We aim to be efficient and productive in our processes and business design. We empower our people to be driven and take initiatives that make their efforts deliver better value for the company and its customers
                            </div>
                        </div>
                    </Fade>

                    <Fade bottom>
                        <div className={styles.card}>
                            <div className={styles.image}>
                                <Growth />
                            </div>
                            <div className={styles.sub_heading}>
                                Growth Orientation
                            </div>
                            <div className={styles.intro}>
                                <div className={styles.sub_heading2}>
                                    Growth Orientation
                                </div>
                                Our commitment to growth drives us in all business aspects—revenue, market share, and personal & professional development of our people. We foster a collaborative environment, promoting partner growth through a non-competition policy and shared business opportunities.
                            </div>
                        </div>
                    </Fade>

                    {/* <Fade right>
                        <div className={styles.card}>
                            <div className={styles.image}>
                                <PartnerGrowth />
                            </div>
                            <div className={styles.sub_heading}>
                                Collaborative Expansion
                            </div>
                            <div className={styles.intro}>
                                <div className={styles.sub_heading2}>
                                    Collaborative Expansion
                                </div>
                                We actively contribute to the growth and success of our partners through collaboration and support, creating lasting, mutually beneficial relationships.
                            </div>
                        </div>
                    </Fade> */}
                </div>
            </div>
        </>
    )
}

export default Culture

