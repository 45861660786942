
import React, { useEffect, useRef, useState } from 'react'
import styles from './ApniCar.module.css';
import logo from '../../../../images/logos/logo.svg'
import ApniCarFAQ from './faq/ApniCarFAQ';
import ApniCarWhy from './why/ApniCarWhy';
import ApniCarTestimonial from './ApniCartestimonial/ApniCarTestimonial';

import axios from 'axios';
import { toast } from 'react-toastify';

import green_tick from '../../../../images/forms/green_tick.svg'
import thank_web from '../../../../images/vectors/landing/APNI_CAR/thank_web.svg'
import thank_mobile from '../../../../images/vectors/landing/APNI_CAR/thank_mobile.svg'


export default function ApniCar() {
  const url = 'https://ipapi.co/json/'
  const isMobile = window.innerWidth <= 940;

  let currentURL = window.location.href;

  function getUrlParameter(name) {
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(currentURL);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  let utm_source_name = getUrlParameter('utm_source');
  let utm_medium_name = getUrlParameter('utm_medium');
  let page_name = currentURL.split('?')[0].split('/').pop().replace(/_/g, ' ');


  const [stage, setStage] = useState(1);
  const [emptyFields, setEmptyFields] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [user_ip, setUser_ip] = useState('');
  const [invalidCoupon, setInvalidCoupon] = useState(true);
  const [otp, setOtp] = useState('');
  const [isOtpValid, setIsOtpValid] = useState(true);
  const [isValid, setIsValid] = useState(true);

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  useEffect(() => {
    getIP();
  }, [])



  if (page_name === '') {
    page_name = 'homescreen';
  }
  if (utm_source_name == '') {
    utm_source_name = 'website';
  }
  if (utm_medium_name == '') {
    utm_medium_name = 'website';
  }

  const getIP = async () => {
    try {
      const res = await axios.get(url);
      const IP_Data = res.data.ip;
      setUser_ip(IP_Data);
      setFormData(prevState => ({
        ...prevState,
        ip: IP_Data
      }));
    } catch (error) {
      console.error("Error fetching IP:", error);
    }
  }

  const [formData, setFormData] = useState({
    // Marketing Purpose 
    ip: user_ip,
    page: page_name,
    utm_medium: utm_medium_name,
    utm_source: utm_source_name,
    type: "",
    time: "",

    full_name: '',
    mobile: '',
    email: '',
    pincode: '',
    coupon: '',
    monthly_income: '',
    existing_emi: '',
    consent: 1,
    loan_amount: ''
  })

  const validateStage1 = () => {
    return (
      formData.full_name !== '' &&
      formData.email !== '' &&
      formData.mobile !== '' &&
      formData.pincode !== ''
    );
  };

  const handleNextStage = async (e) => {
    e.preventDefault();
    const emptyFields = [];

    if (stage === 1 && !validateStage1()) {
      emptyFields.push("full_name", "mobile", "email", "pincode");
    }

    setEmptyFields(emptyFields);

    if (emptyFields.length === 0) {
      try {
        const result = await axios.post(`/leads`, formData);
        // console.log(result);
        localStorage.setItem('OTP_login_id', result.data.login_id)
        setStage(stage + 1);
      } catch (error) {
        console.log('Error in handleSubmit', error);
      }
    }
  };

  const handleInputChange = (e, inputName) => {
    let newValue;
    if (e.$d) {
      newValue = e.$d;
    } else {
      newValue = e.target.value;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [inputName]: newValue,
    }));
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  const handleOTPChange = (e) => {
    const newValue = e.target.value;
    setOtp(newValue);
  }


  const resendOTP = async () => {
    try {
      const response = await axios.post(`/resend_otp`, {
        mobile: formData.mobile,
        signature: "Team Nxcar",
        login_id: localStorage.getItem('OTP_login_id')
      });
      if (response.status === 200) {
        toast.dismiss();
        toast.success('OTP sent');
      } else {
        toast.dismiss();
        toast.error('error OTP sent');
        setIsValid(false);
      }
    } catch (error) {
      console.error('Error during login API call:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await axios.post(`/otpverify`, {
        mobile: formData.mobile,
        otp: otp,
        login_id: localStorage.getItem('OTP_login_id')
      });
      if (response.data.status === true) {
        setStage(3);
      } else {
        toast.error('Wrong OTP');
        setIsOtpValid(false);
      }
    } catch (error) {
      toast.error('Wrong OTP');
      setIsOtpValid(false);
      setOtp('');
    }
  };

  const validCoupons = new Set([
    "EXTW2024",
    "EXTWSHIV",
    "INSUR2024",
    "EXTWNAME",
    "EXTWNIRBHAY",
    "EXTWNAMASTE"
  ]);

  useEffect(() => {
    const couponValue = formData.coupon.toUpperCase();
    if (couponValue && !validCoupons.has(couponValue)) {
      setInvalidCoupon(true);
    } else {
      setInvalidCoupon(false);
    }
  }, [formData.coupon]);


  const formRef = useRef(null);

  const handleButtonClick = () => {
    formRef.current.scrollIntoView({ behavior: 'smooth' });
    setStage(1);
    setFormData({
      full_name: '',
      mobile: '',
      email: '',
      pincode: '',
      coupon: '',
      monthly_income: '',
      existing_emi: '',
      consent: 1,
      loan_amount: ''
    })
  };



  return (
    <>
      <div className={styles.page}>
        <div className={styles.header}>
          <img src={logo} alt="" />
        </div>

        {/* hero */}
        <div className={styles.hero}>
          <div className={styles.main_heading}>
            Thinking About a Second-Hand Car? <br /> Wise Move!
          </div>
          <div className={styles.main_heading_mobile}>
            Thinking About a <br /> Second-Hand Car? <br /> Wise Move!
          </div>
          <div className={styles.sub_heading}>
            With Nxcar's Flexible Financing, Experience the Joy of Riding Your Apni Car.
          </div>
          <div className={styles.yt_video}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/R7eMip7QGh0?si=gWbLqA0ndMKyw27p" title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
          </div>
        </div>

        {/* form */}
        <div className={styles.form} >

          <form onSubmit={handleSubmit} ref={formRef}>

            {stage === 1 && (
              <>
                {/* FULL NAME */}
                <>
                  <div className={styles.MUI_input}>
                    <input
                      className={formData.full_name.length > 0 ? styles.input_field1 : styles.input_field}
                      type="text"
                      value={formData.full_name}
                      onChange={(e) => handleInputChange(e, 'full_name')}
                      required
                      id='name'
                      onKeyDown={(e) => {
                        const keyPressed = e.key;
                        const isValidInput = /^[A-Za-z\s]*$/.test(keyPressed);
                        const isNavigationKey = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete', 'Backspace'].includes(keyPressed);
                        const isCtrlKey = e.ctrlKey || e.metaKey;
                        const isCopyPaste = isCtrlKey && ['a', 'c', 'v'].includes(keyPressed.toLowerCase());
                        if (!isValidInput && !isNavigationKey && !isCopyPaste) {
                          e.preventDefault();
                        }
                      }}
                    />
                    <div className={`${styles.float_label} ${formData?.full_name && styles.labelActive}`}>
                      Full Name <span style={{ color: `var(--red)` }}>*</span>
                    </div>
                  </div>
                  {emptyFields.includes("full_name") && formData.full_name === "" && (
                    <p className={styles.error}>Full Name is required</p>
                  )}
                </>

                {/* EMAIL..  */}
                <>
                  <div className={styles.MUI_input}>
                    <input
                      className={formData.email.length > 0 ? styles.input_field1 : styles.input_field}
                      type="email"
                      value={formData.email}
                      onChange={(e) => handleInputChange(e, 'email')}
                      id='email'
                      required
                    />
                    <div className={`${styles.float_label} ${formData.email && styles.labelActive}`}>
                      Email Id<span style={{ color: `var(--red)` }}> *</span>
                    </div>
                  </div>
                  {emptyFields.includes("email") && formData.email === "" && (
                    <p className={styles.error}>Email Id is required</p>
                  )}
                  {formData.email && !(emailRegex).test(formData.email) && (
                    <p className={styles.error}>
                      Please provide a valid email
                    </p>
                  )}
                </>

                {/* MOBILE NUMBER */}
                <>
                  <div className={styles.MUI_input}>
                    {/* <div className={`${styles.phoneNumberDisplay} ${formData.mobile && styles.phoneNumberActive}`}>
                  +91
                </div> */}
                    <input
                      // className={formData.mobile.length > 0 ? styles.input_field1 : styles.input_field}
                      className={`${formData.mobile.length > 0 ? `${styles.input_field1}` : styles.input_field}`}
                      type="tel"
                      maxLength={10}
                      value={formData.mobile}
                      // onFocus={handleInputFocus}
                      // onBlur={handleInputBlur}
                      id='phone_number'
                      required
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const sanitizedValue = inputValue.replace(/\D/g, '');
                        handleInputChange({ target: { value: sanitizedValue } }, 'mobile');
                      }}
                    />
                    <div className={`${styles.float_label} ${formData.mobile && styles.labelActive1}`}
                    >
                      Mobile Number <span style={{ color: `var(--red)` }}>*</span>
                    </div>
                  </div>
                  {emptyFields.includes("mobile") && (formData.mobile === 0 || formData.mobile === "") && (
                    <p className={styles.error}> Mobile is required</p>
                  )}
                  {formData.mobile && !/^[6789]\d{9}$/.test(formData.mobile) && (
                    <p className={styles.error}>
                      Please provide an Indian number
                    </p>
                  )}
                </>

                {/* PINCODE */}
                <>
                  <div className={styles.MUI_input}>
                    <input
                      className={formData.pincode.length > 0 ? styles.input_field1 : styles.input_field}
                      type="tel"
                      maxLength={6}
                      minLength={6}
                      value={formData.pincode}
                      required
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const sanitizedValue = inputValue.replace(/\D/g, '');
                        handleInputChange({ target: { value: sanitizedValue } }, 'pincode');
                      }}
                    />
                    <div className={`${styles.float_label} ${formData.pincode && styles.labelActive}`}
                    >
                      PIN Code <span style={{ color: `var(--red)` }}>*</span>
                    </div>
                  </div>
                  {emptyFields.includes("pincode") && (formData.pincode === 0 || formData.pincode === "") && (
                    <p className={styles.error}> PIN Code is required</p>
                  )}
                  {formData.pincode && !/^\d{6}$/.test(formData.pincode) && (
                    <p className={styles.error}>
                      Please provide PIN Code
                    </p>
                  )}
                </>

                {/* COUPON CODE */}
                <>
                  <div className={styles.MUI_input}>
                    <input
                      className={formData.coupon.length > 0 ? styles.input_field1 : styles.input_field}
                      type="text"
                      maxLength={15}
                      minLength={5}
                      value={formData.coupon.toUpperCase()}
                      required
                      onChange={(e) => handleInputChange(e, 'coupon')}
                    />

                    <div className={`${styles.float_label} ${formData.coupon && styles.labelActive}`}
                    >
                      Coupon Code
                    </div>

                    {
                      formData.coupon.length > 0 ? (<>
                        {
                          invalidCoupon ? (
                            <div className={styles.invalid_emoji}>
                              !
                            </div>
                          ) : (
                            <div className={styles.valid_emoji}>
                              <img src={green_tick} alt="green_tick" />
                            </div>
                          )
                        }
                      </>) : (null)
                    }

                  </div>
                </>

                {/* BUTTON */}
                <>
                  <div className={styles.button} onClick={handleNextStage}>
                    Proceed
                  </div>
                  <div className={styles.tagline}>
                    We value your privacy and will never share your information with anyone.
                  </div>
                </>

              </>
            )}

            {stage === 2 && (
              <>
                <div className={styles.OTP_heading}>Enter OTP</div>
                <div className={styles.OTP_sub_heading}>OTP has been sent to your number</div>
                <div className={styles.OTP_box}>
                  <div className={styles.new_otp}>
                    <input
                      placeholder='● ● ● ●'
                      required
                      maxLength={4}
                      value={otp}
                      onChange={handleOTPChange}
                      type="tel" />
                  </div>
                </div>
                <div className={styles.otp_will_expire}>
                  {!isOtpValid && (
                    <div className={styles.error_message_otp}>
                      {/* {otpErrorMessage} */}
                    </div>
                  )}
                  <>
                    <button
                      className={styles.resend_button}
                      onClick={resendOTP}>
                      Resend OTP
                    </button>
                  </>
                </div>

                {isOtpValid ? (
                  <div className={styles.center}>
                    <button
                      type="submit"
                      className={styles.button}
                    >
                      Get a Call back
                    </button>
                  </div>
                ) : (
                  <>
                    <div className={styles.center}>
                      <button
                        type="submit"
                        className={styles.button}
                      >
                        Get a Call back
                      </button>
                    </div>
                    {/* <span style={{ color: "red", margin: "10px 0" }}>
                      Wrong OTP
                    </span> */}
                  </>
                )}
              </>
            )}

            {stage === 3 && (
              <>
                <div className={styles.thank_web}>
                  <img src={isMobile ? thank_mobile : thank_web} alt="" />
                </div>
              </>
            )}

          </form>

        </div>


        <ApniCarWhy />
        <ApniCarTestimonial />
        <ApniCarFAQ />

        <div className={styles.CTA} onClick={handleButtonClick}>
          Apply Now
        </div>
        <div className={styles.footer}></div>
      </div >
    </>
  )
}
