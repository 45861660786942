import { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import styles from './Explore.module.css';
import car_purchase from '../../../images/vectors/used_car/category/car_purchase.svg'
import car_purchase_2 from '../../../images/vectors/used_car/category/car_purchase_2.svg'
import balance from '../../../images/vectors/used_car/category/balance.svg'
import balance_2 from '../../../images/vectors/used_car/category/balance_2.svg'
import refiance from '../../../images/vectors/used_car/category/refiance.svg'
import img1 from '../../../images/vectors/landing/Banner.svg'
import img2 from '../../../images/vectors/landing/banner2.svg'

import img3 from '../../../images/vectors/landing/mobile_banner1.svg'
import img4 from '../../../images/vectors/landing/mobile_banner2.svg'
import { Slide } from 'react-awesome-reveal';

import { useMediaQuery } from '@material-ui/core';


export default function Explore() {
    const isMobile = useMediaQuery('(max-width: 940px)');
    let images = isMobile ? [img3, img4] : [img1, img2];
    const [knowMore, setKnowMore] = useState(false);

    const handleKnowMore = () => {
        setKnowMore(!knowMore);
    }

    return (
        <>
            <Carousel
                autoPlay={knowMore ? false : true}
                infiniteLoop={true}
                swipeable={true}
                emulateTouch={true}
                showArrows={false}
                showThumbs={false}
                showIndicators={false}
                showStatus={false}
                interval={5000}
            >
                {images.map((image, index) => (
                    <div key={index} className={styles.image} onClick={handleKnowMore}>
                        <img src={image} alt={`Slide ${index + 1}`} />
                    </div>
                ))}
            </Carousel>

            {
                knowMore && (
                    <div className={styles.white}>
                        <Slide direction='up'>
                            <div className={styles.rate}>
                                <div className={styles.flex_box}>
                                    <div className={styles.left_part}>6 Months Engine & Transmission</div>
                                    <div className={styles.right_part}>Starting From ₹3999</div>
                                </div>
                                <div className={styles.flex_box}>
                                    <div className={styles.left_part}>6 Months Comprehensive</div>
                                    <div className={styles.right_part}>Starting From ₹5999</div>
                                </div>
                                <div className={styles.flex_box}>
                                    <div className={styles.left_part}>12 Months Engine & Transmission</div>
                                    <div className={styles.right_part}>Starting From ₹6499</div>
                                </div>
                                <div className={styles.flex_box}>
                                    <div className={styles.left_part}>12 Months Comprehensive</div>
                                    <div className={styles.right_part}>Starting From ₹9999</div>
                                </div>
                            </div>
                        </Slide>
                    </div>
                )
            }

            <div className={styles.container}>
                <div className={styles.heading}>
                    Explore Our Used Car Loan Options
                </div>
                <div className={styles.main_container}>
                    <div className={styles.card}>
                        <div className={styles.car_image}>
                            <img className={styles.default_image} src={refiance} alt="" />
                            <img className={styles.hover_image} src={refiance} alt="" />
                        </div>
                        <div className={styles.card_heading}>
                            Refinance
                        </div>
                        <div className={styles.card_sub_heading}>
                            Refinancing your used car involves replacing your existing loan with a new one for better terms.
                        </div>
                    </div>

                    <div className={styles.card}>
                        <div className={styles.car_image}>
                            <img className={styles.default_image} src={car_purchase_2} alt="" />
                            <img className={styles.hover_image} src={car_purchase} alt="" />
                        </div>
                        <div className={styles.card_heading}>
                            Pre Owned Car Purchase
                        </div>
                        <div className={styles.card_sub_heading}>
                            Now apply for a used car loan from the comfort of your home and ride your car with ease.
                        </div>
                    </div>

                    <div className={styles.card}>
                        <div className={styles.car_image}>
                            <img className={styles.default_image} src={balance} alt="" />
                            <img className={styles.hover_image} src={balance_2} alt="" />
                        </div>
                        <div className={styles.card_heading}>
                            Top - Up
                        </div>
                        <div className={styles.card_sub_heading}>
                            Our Balance Transfer (BT) top-up lets you transfer your existing loan balance to our lending partner and top it up with additional funds.
                        </div>
                    </div>
                </div>
                {/* <div    
                    className={styles.card_cta_alone}>
                    Apply Now
                </div> */}
            </div>
        </>
    )
}
