import Footer from '../../Footer/Footer'
import Header from '../../Header/Header'
import styles from './Cart.module.css'
import { Box, Modal, alertTitleClasses } from '@mui/material';
import { useEffect, useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { IoClose } from 'react-icons/io5';
import UpdatedServiceForm from '../UpdatedServiceForm/buyForm/UpdatedServiceForm';
import team1 from '../../../images/vectors/homescreen/services/used_car.svg'
import team2 from '../../../images/vectors/homescreen/services/inspection.svg'
import team3 from '../../../images/vectors/homescreen/services/docs_verify.svg'
import team4 from '../../../images/vectors/homescreen/services/extended_warranty.svg'
import team5 from '../../../images/vectors/homescreen/services/insurane_transfer.svg'
import team6 from '../../../images/vectors/homescreen/services/rc_transfer.svg'
import Switch from 'react-js-switch';

const styleMUIModal = {
    position: "absolute",
    top: "0%",
    right: "0%",
    width: '40%',
    height: '100%',
    bgcolor: "white",
    border: "0px solid #000",
    boxShadow: 24,
    outline: "none",
    overflowY: "auto",
    p: 0,
};
const styleMUIModalMobile = {
    width: "100%",
    height: "100vh",
    top: "0%",
    right: "0%",
    height: '100vh',
    bgcolor: "white",
    border: "0px solid #000",
    boxShadow: 24,
    outline: "none",
    overflowY: "auto",
    p: 0,
}

const Cart = () => {
    const [formData, setFormData] = useState({
        used_car_loan: false,
        inspection: false,
        document_verification: false,
        extended_warranty: false,
        insurance_transfer: false,
        rc_transfer: false,
        car_insurance: false,
    });

    const isMobile = useMediaQuery('(max-width: 940px)');
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
        window.history.pushState(null, null, '/nxcarpackage')
    }
    const handleClose = () => {
        setOpen(false);
        window.history.pushState(null, null, '/bundle')
    }

    useEffect(() => {
        if (window.location.pathname === '/nxcarpackage') {
            setOpen(true);
        }
    }, []);

    useEffect(() => {
        const storedData = {
            used_car_loan: localStorage.getItem('used_car_loan') === 'true',
            inspection: localStorage.getItem('inspection') === 'true',
            document_verification: localStorage.getItem('document_verification') === 'true',
            extended_warranty: localStorage.getItem('extended_warranty') === 'true',
            insurance_transfer: localStorage.getItem('insurance_transfer') === 'true',
            rc_transfer: localStorage.getItem('rc_transfer') === 'true',
        };
        setFormData(storedData);
    }, []);

    const handleCheckboxChange = (fieldName) => {
        setFormData(prevState => {
            const updatedFormData = {
                ...prevState,
                [fieldName]: !prevState[fieldName]
            };
            localStorage.setItem(fieldName, updatedFormData[fieldName].toString());
            return updatedFormData;
        });
    };

    const handleHide = () => {
        console.log('hide')
    }
    const handleOnCarLoan = () => {
        localStorage.setItem('used_car_loan', true)
    }

    return (
        <>
            <div className={styles.container1}>
                <Header />
                <div className={styles.container}>
                    <div className={styles.services_container}>
                        <div className={styles.service}>
                            <div className={styles.left}>
                                <img src={team1} alt="" />
                            </div>
                            <div className={styles.right}>
                                <div className={styles.heading}>
                                    <div className={styles.main_heading}>Used Car Loan</div>
                                    <div>
                                        <label>
                                            <Switch
                                                value={formData.used_car_loan}
                                                onChange={() => handleCheckboxChange('used_car_loan')}
                                                size={70}
                                                borderColor={{ on: 'white', off: 'white' }}
                                                color='#4AA09B'
                                                backgroundColor={{ on: '#E0FFFA', off: '#F2F4F3' }}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className={styles.sub_heading}>
                                    Up to 90% value of the used car ( Service includes Bank NOC and valuation) Nxcar does not charge any fee from user
                                </div>
                                <div className={styles.service_price}>
                                    {/* ₹499 */}
                                </div>
                            </div>
                        </div>
                        <div className={styles.service}>
                            <div className={styles.left}>
                                <img src={team2} alt="" />
                            </div>
                            <div className={styles.right}>
                                <div className={styles.heading}>
                                    <div className={styles.main_heading}>Inspection & Valuation</div>
                                    <div className={styles.my_flex_box}>
                                        <div className={styles.service_price}>
                                            ₹499
                                        </div>
                                        <div>
                                            <label>
                                                <Switch
                                                    value={formData.inspection}
                                                    onChange={() => handleCheckboxChange('inspection')}
                                                    size={70}
                                                    borderColor={{ on: 'white', off: 'white' }}
                                                    color='#4AA09B'
                                                    backgroundColor={{ on: '#E0FFFA', off: '#F2F4F3' }}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.sub_heading}>
                                    Refunded if Extended warranty package is purchased
                                </div>

                            </div>
                        </div>
                        <div className={styles.service}>
                            <div className={styles.left}>
                                <img src={team3} alt="" />
                            </div>
                            <div className={styles.right}>
                                <div className={styles.heading}>
                                    <div className={styles.main_heading}>Document  Verification (RC, Challans)</div>
                                    <div className={styles.my_flex_box}>
                                        <div className={styles.service_price}>
                                            ₹199
                                        </div>
                                        <div>
                                            <label>
                                                <Switch
                                                    value={formData.document_verification}
                                                    onChange={() => handleCheckboxChange('document_verification')}
                                                    size={70}
                                                    borderColor={{ on: 'white', off: 'white' }}
                                                    color='#4AA09B'
                                                    backgroundColor={{ on: '#E0FFFA', off: '#F2F4F3' }}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.sub_heading}>
                                    Refunded  if Car Loan is obtained through Nxcar
                                </div>

                            </div>
                        </div>
                        <div className={styles.service}>
                            <div className={styles.left}>
                                <img src={team4} alt="" />
                            </div>
                            <div className={styles.right}>
                                <div className={styles.heading}>
                                    <div className={styles.main_heading}>Extended warranty </div>
                                    <div>
                                        <label>
                                            <Switch
                                                value={formData.extended_warranty}
                                                onChange={() => handleCheckboxChange('extended_warranty')}
                                                size={70}
                                                borderColor={{ on: 'white', off: 'white' }}
                                                color='#4AA09B'
                                                backgroundColor={{ on: '#E0FFFA', off: '#F2F4F3' }}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className={styles.flex_box}>
                                    <div className={styles.left_part}>6 Months Engine & Transmission</div>
                                    <div className={styles.right_part}>Starting From ₹3999</div>
                                </div>
                                <div className={styles.flex_box}>
                                    <div className={styles.left_part}>6 Months Comprehensive</div>
                                    <div className={styles.right_part}>Starting From ₹5999</div>
                                </div>
                                <div className={styles.flex_box}>
                                    <div className={styles.left_part}>12 Months Engine & Transmission</div>
                                    <div className={styles.right_part}>Starting From ₹6499</div>
                                </div>
                                <div className={styles.flex_box}>
                                    <div className={styles.left_part}>12 Months Comprehensive</div>
                                    <div className={styles.right_part}>Starting From ₹9999</div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.service}>
                            <div className={styles.left}>
                                <img src={team5} alt="" />
                            </div>
                            <div className={styles.right}>
                                <div className={styles.heading}>
                                    <div className={styles.main_heading}>Insurance Transfer</div>
                                    <div>
                                        <label>
                                            <Switch
                                                value={formData.insurance_transfer}
                                                onChange={() => handleCheckboxChange('insurance_transfer')}
                                                size={70}
                                                borderColor={{ on: 'white', off: 'white' }}
                                                color='#4AA09B'
                                                backgroundColor={{ on: '#E0FFFA', off: '#F2F4F3' }}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className={styles.sub_heading}>
                                    Free Doumentation (Only NCB adjustment payable to insurance Co)
                                </div>
                                <div className={styles.service_price}>
                                    {/* ₹499 */}
                                </div>
                            </div>
                        </div>
                        <div className={styles.service}>
                            <div className={styles.left}>
                                <img src={team6} alt="" />
                            </div>
                            <div className={styles.right}>
                                <div className={styles.heading}>
                                    <div className={styles.main_heading}>RC Transfer</div>
                                    <div className={styles.my_flex_box}>
                                        <div className={styles.service_price} >
                                            Starting From ₹2999
                                        </div>
                                        <div>
                                            <label >
                                                <Switch
                                                    size={70}
                                                    borderColor={{ on: 'white', off: 'white' }}
                                                    color='#4AA09B'
                                                    backgroundColor={{ on: '#E0FFFA', off: '#F2F4F3' }}
                                                    value={formData.rc_transfer}
                                                    onChange={() => handleCheckboxChange('rc_transfer')}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.sub_heading}>
                                    From Rs 2999 (Dependent upon RTO location and inter/intra state transfer)
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* {
                    formData.used_car_loan == false ? (
                        <div className={styles.obtain_car_loan}>
                            <div className={styles.obtain_car_loan_heading}>
                                Are you sure you don't wish to consider
                                <br />
                                obtaining a car loan ?
                            </div>
                            <div className={styles.obtain_car_loan_buttons}>
                                <div className={styles.obtain_button} onClick={handleHide}>Yes</div>
                                <div className={styles.obtain_button} onClick={handleOnCarLoan}>No</div>
                            </div>
                        </div>
                    ) : null
                } */}
                    {
                        formData.used_car_loan === true ||
                            formData.inspection === true ||
                            formData.document_verification === true ||
                            formData.extended_warranty === true ||
                            formData.insurance_transfer === true ||
                            formData.rc_transfer === true
                            ? (
                                <div className={styles.black_tape1}>
                                    <div className={styles.confirm_button1} onClick={handleOpen} >
                                        Continue To Enter Details
                                    </div>
                                </div>
                            ) : null
                    }
                </div>
            </div>
            <div className={styles.footer}>
                <Footer />
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={isMobile ? styleMUIModalMobile : styleMUIModal}>
                    <UpdatedServiceForm
                        handleCloseBuy={handleClose}
                    />
                </Box>
            </Modal>
        </>
    )
}

export default Cart