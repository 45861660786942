import React, { useState } from 'react'
import ConsumerMain from '../../../../Layout/Consumer/Main/ConsumerMain'
import LoanApplication from '../LoanAppplication/LoanApplication'
import styles from './Dashboard.module.css'
import Details from '../Consumer_details/Details'
import { jwtDecode } from "jwt-decode";

const Dashboard = () => {
    const [myTokenData, setMyTokenData] = useState();
    const token = localStorage.getItem('Authorization');
    const user_status = localStorage.getItem('new-old');
    const info = jwtDecode(token)
    // console.log('status', info);


    return (
        <ConsumerMain>
            {/* NEW DESIGN AS PER APP FLOW */}
            <Details />

            {/* OLD FLOW */}
            {/* <Details /> */}
            {/* <LoanApplication3 /> */}
        </ConsumerMain>
    )
}

export default Dashboard