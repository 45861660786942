import React, { useEffect, useState } from 'react';
import styles from './NewLoan.module.css';
import { IoClose } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import bar from '../../../images/vectors/loan_eligibility/bar.svg'
import check from '../../../images/vectors/loan_eligibility/check.svg'
import axios from 'axios';
import { toast } from 'react-toastify';
import { scrollToTop } from '../../../reusable/scrollToTop/ScrollToTopButton'
import loader from '../../../images/loading/loader.gif'
import oops_image from '../../../images/loading/oops.svg'
import OtpInput from 'react-otp-input';

const NewLoan = ({ handleClose }) => {
    const [stage, setStage] = useState(1);
    // const [otp, setOtp] = useState(new Array(4).fill(""));
    const [otp, setOtp] = useState('');
    const [isTimeExpired, setIsTimeExpired] = useState(false);
    const [isOtpValid, setIsOtpValid] = useState(true);
    const [seconds, setSeconds] = useState(120);
    const [isValid, setIsValid] = useState(true);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [loanData, setLoanData] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showMorePoints, setShowMorePoints] = useState(false);

    // const C_id = localStorage.getItem('')
    // const token = localStorage.getItem('token')
    const D_id = localStorage.getItem('my-dealer-id')
    const buyer_name = localStorage.getItem('buyer_name')
    const buyer_phone = localStorage.getItem('buyer_phone')


    const [isFocused, setIsFocused] = useState(false);
    const handleInputFocus = () => {
        setIsFocused(true);
    };
    const handleInputBlur = () => {
        setIsFocused(false);
    };


    const [formData, setFormData] = useState({
        consumer_id: "",
        dealer_id: D_id,
        // full_name: "" || buyer_name,
        // mobile: "" || buyer_phone,
        full_name: "",
        mobile: "",
        salary: "",
        existing_emi: "",
        pancard: "",
        consent: "1",

        // full_name: "SHRADDHA NAND",
        // mobile: "8920892992",
        // salary: "9002",
        // existing_emi: "920",
        // pancard: "BZBPN9007P",
    });
    const [formErrors, setFormErrors] = useState({
        full_name: '',
        pancard: '',
        salary: '',
        existing_emi: '',
        mobile: '',
    });
    const regexMap = {
        full_name: /^[A-Za-z0-9 ]+$/,
        pancard: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/gm,
        salary: /^[0-9]{1,15}$/,
        existing_emi: /^[0-9]{1,10}$/,
        mobile: /^[6-9]\d{9}$/,
    };
    const errorMessages = {
        full_name: 'Please enter a valid name',
        pancard: 'Please enter a valid PAN number',
        salary: 'Please enter a valid salary',
        existing_emi: 'Please enter a valid existing EMI',
        mobile: 'Please enter a valid 10-digit mobile number',
    };

    const handleInputChange = (e, field) => {
        const value = e.target.value;

        // if (!regexMap[field].test(value)) {
        //     toast.dismiss();
        //     toast.error(errorMessages[field]);
        // }

        setFormData({
            ...formData,
            [field]: value
        });

        if (!regexMap[field].test(value)) {
            setFormErrors({
                ...formErrors,
                [field]: errorMessages[field]
            });
        } else {
            setFormErrors({
                ...formErrors,
                [field]: ''
            });
        }
    }

    useEffect(() => {
        let interval
        if (stage == 2) {
            interval = setInterval(() => {
                setSeconds((prevSeconds) => {
                    if (prevSeconds === 1) {
                        setIsTimeExpired(true);
                        clearInterval(interval);
                        return 0;
                    }
                    return prevSeconds - 1;
                });
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [stage]);
    // THE FORMATTED DATE FOR THE DATE AND TIME
    const formattedTime = () => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        return `${hours < 10 ? '0' : ''}${hours} : ${minutes < 10 ? '0' : ''}${minutes} : 
    ${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}s`;
    };

    const resendOTP = async () => {
        try {
            const response = await axios.post('/eligibility', formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            if (response.statusText === "OK") {
                console.log('resend OTP works', response);
                toast.dismiss();
                toast.success('OTP sent');
                setStage(2);
            } else {
                toast.dismiss();
                toast.error('error OTP sent');
                console.log('hi failed OTP API');
                setIsValid(false);
            }
        } catch (error) {
            console.error('Error during login API call:', error.message);
        }
    };

    const otpVerificationAPI = async (e) => {
        e.preventDefault()
        try {
            const response = await axios.post('/eligibilityotpverify', {
                mobile: formData.mobile,
                otp: otp
            });
            if (response.status == 200) {
                localStorage.setItem("Authorization", response.data.access_token)
                setStage(3);
            } else {
                toast.dismiss();
                toast.error('Wrong OTP');
                // console.log('OTP');
                setIsOtpValid(false);
            }
        } catch (error) {
            // console.log('OTP', error.response.data);
            toast.dismiss();
            toast.error('Wrong OTP');
            setIsOtpValid(false);
            setOtp('');
        }
    };

    // OTP VERIFICATION API
    // const handleOTPChange = (element, index) => {
    //     if (isNaN(element.value)) return false;

    //     setOtp(otp);

    //     // Focus next input
    //     if (element.nextSibling) {
    //         element.nextSibling.focus();
    //     }
    // };


    // const handleOTPChange = (element, index) => {
    //     if (isNaN(element.value)) return false;

    //     setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    //     // Focus next input
    //     if (element.nextSibling) {
    //         element.nextSibling.focus();
    //     }
    // };
    const handleOTPChange = (e) => {
        const newValue = e.target.value;
        setOtp(newValue);
    }


    const handleOTPVerification = async (e) => {
        e.preventDefault();
        await otpVerificationAPI();
        if (isOtpValid) {
            setStage(3); // Move to the next screen
        } else {
            isOtpValid();
            // console.log('Invalid OTP. Please try again.');
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            const response = await axios.post('/eligibility', formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.status === 200) {
                console.log('handle-submit', response);
                setStage(2);
                setLoanData(response.data);
                setIsLoading(false);
            } else if (response.status === 400) {
                setStage(2);
                setIsLoading(false);
                toast.dismiss();
                // console.log('handle-submit-fail', response);
                // toast.error('error OTP sent');
            }
        } catch (error) {
            setStage(2);
            setIsLoading(false);
            toast.dismiss();
            // console.log('handle-submit-fail-last', error.message);
            // toast.error('error OTP sent');
        }
    };
    const indianFinanceNumber = (number) => {
        const formatter = new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            maximumFractionDigits: 2,
        });

        return formatter.format(number);
    };


    const handleViewMoreClick = () => {
        setShowMorePoints(!showMorePoints);
    };

    if (stage === 1) {
        window.history.pushState(null, null, '/used-car-loan/eligibility')
    } else if (stage === 2) {
        window.history.pushState(null, null, '/used-car-loan/otp/verify')
    } else if (stage === 3) {
        window.history.pushState(null, null, '/used-car-loan/thank-you/nxcar')
    }

    return (

        <div className={styles.container}>

            {stage === 1 && (
                <div>
                    <div className={styles.heading_section}>

                        <div className={styles.mui_heading}>
                            <span>Check your loan eligibility </span>
                            <span>
                                <div
                                    onClick={handleClose}
                                    className={styles.mui_close_button}
                                >
                                    <IoClose size={30} />
                                </div>
                            </span>
                        </div>
                        <div className={styles.mui_sub_heading}>
                            Please fill out details
                        </div>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className={styles.col}>
                            <div className={styles.MUI_input} >
                                <input
                                    className={formData.full_name?.length > 0 ? styles.input_field1 : styles.input_field}
                                    type="text"
                                    maxLength={51}
                                    value={formData.full_name}
                                    onChange={(e) => handleInputChange(e, 'full_name')}
                                    required
                                    onKeyDown={(e) => {
                                        const keyPressed = e.key;
                                        const isValidInput = /^[A-Za-z\s]*$/.test(keyPressed);
                                        const isNavigationKey = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete', 'Backspace'].includes(keyPressed);
                                        const isCtrlKey = e.ctrlKey || e.metaKey;
                                        const isCopyPaste = isCtrlKey && ['a', 'c', 'v'].includes(keyPressed.toLowerCase());
                                        if (!isValidInput && !isNavigationKey && !isCopyPaste) {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                                <div className={`${styles.float_label} ${formData.full_name && styles.labelActive}`}>
                                    Enter Your Name <span style={{ color: "#CD1C1C" }}>*</span>
                                </div>
                                {
                                    localStorage.setItem('buyer_name', formData.full_name)
                                }
                            </div>
                            {formErrors.full_name && <div className={styles.warning}>{formErrors.full_name}</div>}
                            <div className={styles.tag_line}>
                                Enter full name as per your PAN card
                            </div>

                            <div className={styles.MUI_input}>
                                <input
                                    className={formData?.pancard?.length > 0 ? styles.input_field1 : styles.input_field}
                                    type="text"
                                    maxLength={10}
                                    value={formData.pancard.toUpperCase()}
                                    onChange={(e) => handleInputChange(e, 'pancard')}
                                    required
                                />
                                <div className={`${styles.float_label} ${formData.pancard && styles.labelActive}`}>
                                    Enter Your PAN Number <span style={{ color: "#CD1C1C" }}>*</span>
                                </div>
                            </div>
                            {formErrors.pancard && <div className={styles.warning}>{formErrors.pancard}</div>}

                            <div className={styles.MUI_input}>
                                <input
                                    className={formData.salary?.length > 0 ? styles.input_field1 : styles.input_field}
                                    type="tel"
                                    maxLength={15}
                                    value={formData.salary}
                                    required
                                    // onChange={(e) => handleInputChange(e, 'salary')}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const sanitizedValue = inputValue.replace(/\D/g, '');
                                        handleInputChange({ target: { value: sanitizedValue } }, 'salary');
                                    }}
                                />
                                <div className={`${styles.float_label} ${formData.salary && styles.labelActive}`}>
                                    Enter Monthly Income <span style={{ color: "#CD1C1C" }}>*</span>
                                </div>

                                {
                                    localStorage.setItem('buyer_salary', formData.salary)
                                }
                            </div>
                            {formErrors.salary && <div className={styles.warning}>{formErrors.salary}</div>}

                            <div className={styles.MUI_input}>
                                <input
                                    className={formData.existing_emi?.length > 0 ? styles.input_field1 : styles.input_field}
                                    type="tel"
                                    maxLength={10}
                                    value={formData.existing_emi}
                                    required
                                    // onChange={(e) => handleInputChange(e, 'existing_emi')}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const sanitizedValue = inputValue.replace(/\D/g, '');
                                        handleInputChange({ target: { value: sanitizedValue } }, 'existing_emi');
                                    }}
                                />
                                <div className={`${styles.float_label} ${formData.existing_emi && styles.labelActive}`}>
                                    Existing EMI <span style={{ color: "#CD1C1C" }}>*</span>
                                </div>
                                {
                                    localStorage.setItem('buyer_EMI', formData.existing_emi)
                                }
                            </div>
                            {formErrors.existing_emi && <div className={styles.warning}>{formErrors.existing_emi}</div>}

                            <div className={styles.MUI_input}>
                                <div className={`${styles.phoneNumberDisplay} ${formData.mobile && styles.phoneNumberActive}`}>
                                    +91
                                </div>

                                <input
                                    // className={formData.mobile.length > 0 ? styles.input_field2 : styles.input_field}
                                    className={`${styles.input_field} ${formData.mobile?.length > 0 ? styles.input_field2 : ''} ${isFocused ? styles.input_field2 : ''}`}

                                    type="tel"
                                    maxLength={10}
                                    value={formData.mobile}
                                    required
                                    onFocus={handleInputFocus}
                                    onBlur={handleInputBlur}
                                    id='phone_number'
                                    style={{ padding: "12px 0px" }}
                                    // onChange={(e) => handleInputChange(e, 'mobile')}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const sanitizedValue = inputValue.replace(/\D/g, '');
                                        handleInputChange({ target: { value: sanitizedValue } }, 'mobile');
                                    }}
                                />
                                <div className={`${styles.float_label} ${formData.mobile && styles.labelActive}`}>
                                    {isFocused ? "Enter your mobile number" : formData.mobile?.length === 0 ? "+91 Enter your mobile number" : "Enter your mobile number"} <span style={{ color: `var(--red)` }}>*</span>
                                </div>
                                {
                                    localStorage.setItem('buyer_phone', buyer_phone)
                                }
                            </div>
                            {formErrors.mobile && <div className={styles.warning}>{formErrors.mobile}</div>}
                            <div className={styles.tag_line}>
                                Enter number which is linked with your PAN card
                            </div>
                        </div>
                        <div className={styles.final_button_container1}>
                            <button
                                type='submit'
                                disabled={isLoading}
                                className={styles.submit_button1}
                            >
                                {
                                    isLoading ?
                                        <div
                                            disabled={isLoading}
                                            className={styles.loading_animation}
                                        >
                                            <img src={loader} alt="" />
                                        </div>
                                        :
                                        <div
                                            type='submit'
                                            className=""
                                        >
                                            Next
                                        </div>
                                }
                            </button>
                        </div>

                        {/* <div className={styles.final_button_container1}>
                            {Object.values(formErrors.full_name).every(error => error === '') && (
                                <button
                                    type='submit'
                                    disabled={isLoading}
                                    className={styles.submit_button1}
                                >
                                    {isLoading ? (
                                        <div className={styles.loading_animation}>
                                            <img src={loader} alt="" />
                                        </div>
                                    ) : (
                                        <div type='submit' className="">
                                            Next
                                        </div>
                                    )}
                                </button>
                            )}
                        </div> */}

                    </form>
                </div>
            )}

            {stage === 2 && (
                <div>
                    <div className={styles.heading_section}>
                        <div
                            onClick={handleClose}
                            className={styles.mui_close_button}
                        >
                            <IoClose />
                        </div>
                        <div className={styles.mui_heading}>
                            <span>Check your loan eligibility </span>
                        </div>
                        <div className={styles.mui_sub_heading}>
                            OTP has been sent to your number
                        </div>
                    </div>
                    <form
                        onSubmit={otpVerificationAPI}
                    >
                        {/* <div className={styles.otp_fields}> */}
                        {/* <OtpInput
                                className={`otp-field ${!isOtpValid ? styles.error_message_otp : ''}`}
                                type="tel"
                                name="otp"
                                required
                                value={otp}
                                onChange={setOtp}
                                numInputs={4}
                                renderSeparator={<span></span>}
                                renderInput={(props) => <input {...props} />}
                            />
                        </div> */}

                        <div className={styles.new_otp}>
                            <input
                                placeholder='● ● ● ●'
                                required
                                maxLength={4}
                                value={otp}
                                onChange={handleOTPChange}
                                type="tel" />
                        </div>
                        <div className={styles.otp_will_expire}>
                            {!isOtpValid && (
                                <div className={styles.error_message_otp}>
                                    {/* {otpErrorMessage} */}
                                </div>
                            )}
                            {!isTimeExpired ? (
                                <>
                                    <button
                                        className={styles.resend_button}
                                        onClick={resendOTP}>
                                        Resend OTP
                                    </button>
                                    {/* OTP will expire in
                                    <span style={{ color: 'teal' }}>{' ' + formattedTime()}</span> */}
                                </>
                            ) : (
                                <>
                                    <button
                                        className={styles.resend_button}
                                        onClick={resendOTP}>
                                        Resend OTP
                                    </button>
                                </>
                            )}
                        </div>

                        <div className={styles.consent_box}>
                            <div className={styles.consent_left}>
                                <input
                                    type="checkbox"
                                    required
                                />
                            </div>
                            <div className={styles.consent_right}>
                                I agree that I have read, understood, and accepted the <span style={{ color: 'teal', textDecoration: 'underline' }}> <a href="https://nxcar.in/privacy-policy" target='_blank'>Privacy Policy</a>, <a href="https://nxcar.in/terms-of-use" target="_blank">Terms of Use</a> and Consent Declaration</span> and hereby appoint Nxcar as my authorized representative to receive my credit information from any certified Credit Information Companies
                            </div>
                        </div>

                        {isOtpValid ? (

                            <button
                                type="submit"
                                className={styles.submit_button1}
                            >
                                Submit
                            </button>
                        ) : (
                            <>
                                <button
                                    className={styles.submit_button1}
                                    type='submit'
                                >
                                    Submit
                                </button>
                                <span style={{ color: "red", margin: "10px 0" }}>
                                    Wrong OTP
                                </span>
                            </>
                        )}
                    </form>
                    {/* <div className={styles.final_button_container1}>
                        <button
                            className={styles.submit_button1}
                            onClick={() => setStage(3)}
                        >
                            Submit
                        </button>
                    </div> */}
                </div>
            )}

            {stage === 3 && (
                <div className={styles.scrollable_loan}>
                    <div className={styles.heading_section}>
                        <div
                            onClick={handleClose}
                            className={styles.mui_close_button}
                        >
                            <IoClose />
                        </div>
                    </div>

                    {
                        (
                            (loanData.score == undefined || loanData.eligibility == 0)
                            || loanData.score < 0 ||
                            (loanData.score >= 1 && loanData.score < 650))
                            ?
                            <>
                                <div className={styles.illustration_img}>
                                    <img src={oops_image} alt="oops_image" />
                                </div>
                                <div className={styles.guide}>
                                    <div className={styles.point1}>
                                        Thank you for using our loan eligibility Calculator.
                                        <br /> Unfortunately, your credit score doesn't meet our current loan criteria
                                    </div>
                                    <div className={styles.point2}>
                                        Here's a brief guide to help improve your <span style={{ color: `var(--teal)` }} >credit score</span>
                                    </div>
                                    <div className={styles.more_points}>
                                        <ul>
                                            <li>
                                                1. Check Your Credit Report:
                                                <br />
                                                &nbsp;&nbsp;&nbsp; Review for errors and dispute inaccuracies
                                            </li>
                                            <li>
                                                2. Pay Bills on Time:
                                                <br />
                                                &nbsp;&nbsp;&nbsp; Set up reminders or automatic payments
                                            </li>
                                            <li>
                                                3. Reduce Credit Card Balances:
                                                <br />
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lower outstanding balances for a healthier credit utilization ratio.
                                            </li>
                                        </ul>

                                        {
                                            showMorePoints && (
                                                <ul>

                                                    <li>
                                                        4. Create a Budget:
                                                        <br />
                                                        &nbsp;&nbsp;&nbsp; Manage finances effectively to prioritize debts.
                                                    </li>
                                                    <li>
                                                        5. Avoid Opening New Credit Accounts:
                                                        <br />
                                                        &nbsp;&nbsp;&nbsp; Limit new applications to maintain your score.
                                                    </li>
                                                    <li>
                                                        6. Build a Positive Credit History:
                                                        <br />
                                                        &nbsp;&nbsp;&nbsp; Use credit responsibly over time.
                                                    </li>
                                                    <li>
                                                        7. Seek Professional Advice:
                                                        <br />
                                                        &nbsp;&nbsp;&nbsp;&nbsp;Consult financial advisors or credit counselling services for personalized guidance.
                                                    </li>
                                                </ul>
                                            )
                                        }
                                        <div className={styles.view_more_ol} onClick={handleViewMoreClick}>
                                            {showMorePoints ? 'View Less' : 'View More'}
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className={styles.main_form}>
                                    <div className={styles.loan_bar1}>
                                        <img src={check} alt="" />
                                    </div>
                                    <div
                                        className={styles.mui_heading1}
                                    >
                                        Congratulations
                                    </div>
                                    <div
                                        className={styles.mui_sub_heading1}
                                    >
                                        {formData.full_name} You are eligible for an approval of
                                    </div>


                                    <div className={styles.score}>
                                        {
                                            (loanData.eligibility === undefined || loanData.eligibility < 0 || (loanData.eligibility >= 1 && loanData.eligibility <= 650))
                                                ? "You Are Not Eligible"
                                                : indianFinanceNumber(loanData.eligibility)
                                        }
                                        <br />
                                    </div>
                                    <div
                                        className={styles.mui_sub_heading1}
                                    >
                                        With Good Credit Score of {loanData.score} points

                                    </div>


                                    <div className={styles.loan_bar2}
                                        style={{ padding: "10px 0" }}
                                    >
                                        <img src={bar} alt="" />
                                    </div>


                                    <div className={styles.final_button_container1}>
                                        <div className={styles.apply_loan_button}>
                                            <Link to='/consumer-form'>
                                                <button className={styles.submit_button2}>
                                                    Apply For Loan
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </>
                    }
                </div>
            )}
        </div>
    );
};

export default NewLoan;
