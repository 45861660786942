import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SideNav from '../SideNav/SideNav';
import logo from '../../../images/logos/logo.svg';
import styles from './Main.module.css';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { jwtDecode } from "jwt-decode";

const Main = ({ children }) => {
    const token = localStorage.getItem('token');

    return (
        <>
            {!token ? (
                <>
                    <div className={styles.no_token_container}>
                        <div className={styles.no_token}>
                            Plese Login with right credentials
                        </div>
                        <Link to='https://nxcar.in/ ' className={styles.link}>
                            LOGIN
                        </Link>
                    </div>
                </>
            ) : (
                <>
                    <div className={styles.container}>
                        <div className={styles.left}>
                            <SideNav />
                        </div>
                        <div className={styles.right}>
                            {children}
                        </div>
                    </div>
                </>
            )}

        </>
    );
};

export default Main;
