import React, { useEffect, useState } from 'react'
import styles from './BubbleInfo.module.css';
import img1 from '../../../images/vectors/channel_partner/bubble/bubble.svg'
import circle from '../../../images/vectors/about/hero/circles.svg'
import Tilt from 'react-parallax-tilt';




export default function BubbleInfo() {

    return (
        <>
            <div className={styles.circle}>
                <img src={circle} alt="nxcar-used-car-wesbites" />
            </div>
            <div className={styles.container}>
                <div className={styles.side1}>
                    <div className={styles.heading}>
                        Used Car Loans for Your Customers
                    </div>
                    <div className={styles.sub_heading}>
                        With Nxcar's partnership, now offer your customers used car loans along with car sales so that they can secure the funds they need to purchase their desired vehicle. But that's not all - in addition to financing, you can also offer services like extended warranty, insurance transfer, and RC transfer, making the car buying process even more convenient for your customers. This means that by teaming up with Nxcar, you can enhance your offerings, attract more customers, and provide an all-encompassing car buying experience that sets you apart from the competition.
                    </div>
                </div>


                {/* BUBBLE INFO */}

                <Tilt
                    className={styles.tiltImg}
                    tiltMaxAngleX={35}
                    tiltMaxAngleY={35}
                    perspective={900}
                    scale={1.1}
                    transitionSpeed={2000}
                    gyroscope={true}
                >
                    <div className={styles.middle}>
                        <img src={img1} alt="nxcar-moving-card-images" />
                    </div>
                </Tilt>


                <div className={styles.side2}>
                    <div className={styles.heading}>
                        Used Car Loans for You
                    </div>
                    <div className={styles.sub_heading}>
                        Introducing Nxcar's dealer financing services, your gateway to hassle-free financing for your car inventory. With Nxcar, now easily secure financing for your car inventory, ensuring seamless transactions and quick turnover. Nxcar streamlines the financing process, allowing you to focus on what you do best: selling cars. Experience the convenience of dealer financing with Nxcar today.
                    </div>
                </div>
            </div>
        </>
    )
}
