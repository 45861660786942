import React, { useEffect, useRef, useState } from 'react'
import styles from './Testimonial.module.css'
import { FaAngleLeft } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa";

export default function Testimonials() {
    const arr = [
        {
            text: "Thanks to Nxcar, financing is no longer a hurdle for our customers. Their service is fast, and customer-friendly. We've seen a noticeable increase in sales!",
            profilePic: 'K',
            profileName: "Yes Cars, Bangalore"
        },
        {
            text: "The feedback from customers who've used Nxcar for loans has been positive. It's great to have a finance partner who's truly on our side.",
            profilePic: 'K',
            profileName: "Satguru Traders, Delhi"
        },
        {
            text: "Nxcar has simplified the loan part of buying a used car for our customers, that has led to more confident purchases and happier customers.",
            profilePic: "l",
            profileName: "Trusted Autoz, Delhi"
        },

    ];
    const containerRef = useRef(null);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [lineWidth, setLineWidth] = useState(300);
    const [expandedIndex, setExpandedIndex] = useState(null);

    const toggleExpand = (index) => {
        if (expandedIndex === index) {
            setExpandedIndex(null); // Close if already open
        } else {
            setExpandedIndex(index); // Open if closed
        }
    };


    const handleScroll = (scrollOffset) => {
        containerRef.current.scrollLeft += scrollOffset;
        setScrollLeft(containerRef.current.scrollLeft);
    };

    useEffect(() => {
        const container = containerRef.current;
        const updateLineWidth = () => {
            const containerWidth = container.offsetWidth;
            const scrollWidth = container.scrollWidth;
            const maxScroll = scrollWidth - containerWidth;
            const percentage = (container.scrollLeft / maxScroll) * 100;
            setLineWidth((percentage * containerWidth) / 100);
        };
        updateLineWidth();

        container.addEventListener('scroll', updateLineWidth);
        return () => {
            container.removeEventListener('scroll', updateLineWidth);
        };
    }, []);

    return (
        <>
            <div className={styles.container}>
                <div className={styles.heading}>
                    Listen from our customers
                </div>

                <div className={styles.green_container}>
                    <div className={styles.main_container} ref={containerRef}>
                        {arr.map((testimonial, index) => (
                            <div className={styles.view} key={index}>
                                <div className={styles.big_inverted_commas_left}>"</div>
                                <div className={styles.view_text}>
                                    {expandedIndex === index ? testimonial.text : `${testimonial.text.slice(0, 150)}...`}
                                    {testimonial.text.length > 150 && (
                                        <span className={styles.read_more} onClick={() => toggleExpand(index)}>
                                            {expandedIndex === index ? ' ...Read Less' : 'Read More'}
                                        </span>
                                    )}
                                </div>
                                <div className={styles.big_inverted_commas_right}>"</div>
                                <div className={styles.profile}>
                                    <div className={styles.profile_pic}>
                                        {testimonial.profileName.charAt(0)}
                                    </div>
                                    <div className={styles.profile_name}>{testimonial.profileName}</div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className={styles.scroll_line} style={{ width: lineWidth }}>
                        <div className={styles.scroll_line_label} style={{ width: lineWidth, color: "red" }}> </div>
                    </div>
                </div>
                {arr.length > 1 && (
                    <div className={styles.button}>
                        <div className={styles.left} onClick={(e) => { e.stopPropagation(); handleScroll(-420) }}>
                            <FaAngleLeft />
                        </div>

                        <div className={styles.right} onClick={(e) => { e.stopPropagation(); handleScroll(420) }}>
                            <FaAngleRight />
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}
