import React from 'react';
import QRCode from 'qrcode.react';
import styles from './Scanner.module.css';

export default function AppQR() {
  const landingPageUrl = 'https://www.nxcar.in/redirect';

  return (
    <div className={styles.container}>
      <QRCode
        bgColor={"white"}
        fgColor={"#000000"}
        level={"L"}
        renderAs={"svg"}
        style={{
          width: "240px",
          height: "240px",
        }}
        value={landingPageUrl} />
      {/* <p>Scan the QR code to be download APP.</p> */}
    </div>
  )
}
