import Footer from '../../../../components/Footer/Footer';
import Header from '../../../../components/Header/Header';
import styles from '../Blogs.module.css';
import car from '../../../../images/vectors/blogs/car_with_shadow.svg'
import img5 from '../../../../images/vectors/homescreen/knoledge/img5.svg'
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap'
import OtherBlogs from './OtherBlogs';
import car_with_path from '../../../../images/vectors/blogs/Car.svg'
import { Link } from 'react-router-dom';
import { scrollToTop } from '../../../../reusable/scrollToTop/ScrollToTopButton';

const Blog5 = () => {
    const carRef = useRef(null);
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        const car = carRef.current;
        gsap.to(car, {
            x: '-120%',
            y: '200%',
            scrollTrigger: {
                trigger: car,
                scroller: 'body',
                start: '100% 50%',
                end: '10% 90%',
                scrub: 2,
                pin: true,
            },
        });
    }, []);

    return (
        <>
            <div className={styles.curve_container}>
                <Header />
                <div className={styles.container}>
                    {/* <div className={styles.car_design} ref={carRef}>
                    <img src={car} alt="" />
                </div>
                <div className={styles.blog_design}></div> */}

                    <div className={styles.flex_box}>
                        {/* blogs */}
                        <div className={styles.blog}>
                            <div className={styles.blog_container}>
                                <div className={styles.blog_heading_main}>Used Car Inspection Checklist</div>
                                <div className={styles.blog_sub_heading}>What to Look for Before You Buy</div>

                                <div className={styles.mobile_image}>
                                    <img src={img5} alt="mobile_image_nxcar" />
                                </div>

                                <div className={styles.blog_content}>
                                    Thinking about buying a used car? Great choice! You can save some cash and still get a fantastic ride. But before you shake hands and take the keys, you gotta make sure everything's in good shape. Here's a simple guide to help you check out a used car, making sure you're getting a good deal.
                                </div>

                                <div className={styles.blog_sub_heading}>Look Over the Outside</div>
                                <div className={styles.blog_heading}>Body and Paint:</div>
                                <div className={styles.blog_content}>
                                    Take a walk around the car. Look for any dents, scratches, or spots where rust is starting. If the paint looks different in some places, that might mean it's been fixed up after an accident.
                                </div>
                                <div className={styles.blog_heading}>Windows and Lights:</div>
                                <div className={styles.blog_content}>
                                    Make sure there are no big cracks in the windows, and check that all the lights are working right. Foggy lights or cracked covers are a no-go.
                                </div>
                                <div className={styles.blog_heading}>Tyres:</div>
                                <div className={styles.blog_content}>
                                    Peek at the tyres . They should wear out evenly. If they don't, the car might have alignment problems, which can be a pain (and expensive) to fix.
                                </div>

                            </div>

                            <div className={styles.blog_container2}>
                                <div className={styles.blogs_right}>
                                    <div className={styles.blog_sub_heading}>Inside the Car</div>
                                    <div className={styles.blog_heading}>Seats and Smells:</div>
                                    <div className={styles.blog_content}>
                                        Jump inside and have a look at the seats and carpets. Stains, tears, or a bad smell? That could mean trouble. And if it smells too much like air freshener, they might be trying to hide something.
                                    </div>
                                    <div className={styles.blog_heading}>Gadgets and Buttons:</div>
                                    <div className={styles.blog_content}>
                                        Try out everything inside. The air conditioning, radio, and all those buttons should work fine. Any dashboard warning lights should turn off after you start the car.
                                    </div>
                                    <div className={styles.blog_heading}>Seat Belts:</div>
                                    <div className={styles.blog_content}>
                                        Pull on the seat belts. They should click in easily and pull out smoothly.
                                    </div>
                                </div>
                            </div>

                            <div className={styles.blog_container}>
                                <div className={styles.blog_sub_heading}>Under the Hood</div>
                                <div className={styles.blog_heading}>Engine Condition:</div>
                                <div className={styles.blog_content}>
                                    Open up the hood and check for any leaks or rust. The oil and coolant should be at the right levels, showing the car's been taken care of.
                                </div>
                                <div className={styles.blog_heading}>Battery:</div>
                                <div className={styles.blog_content}>
                                    Look at the battery for any signs of old age or leaks. Everything should be clean and tight.
                                </div>
                                <div className={styles.blog_heading}>Belts and Hoses:</div>
                                <div className={styles.blog_content}>
                                    The belts shouldn't have any cracks, and the hoses should feel firm, not squishy or cracked.
                                </div>
                            </div>

                            <div className={styles.blog_container2}>
                                <div className={styles.blogs_right}>
                                    <div className={styles.blog_sub_heading}>Under the Car</div>
                                    <div className={styles.blog_heading}>Bouncing Test:</div>
                                    <div className={styles.blog_content}>
                                        Push down on each corner of the car. It should stop bouncing right away. If it keeps going, the shocks might be worn out.
                                    </div>
                                    <div className={styles.blog_heading}>Exhaust Check:</div>
                                    <div className={styles.blog_content}>
                                        The exhaust system shouldn't be rusty or have holes. Listen for any loud noises that could mean leaks.
                                    </div>
                                    <div className={styles.blog_heading}>Look for Drips:</div>
                                    <div className={styles.blog_content}>
                                        Park the car somewhere clean, let it run a bit, then move it and see if it left any spots. Leaks are a bad sign.
                                    </div>
                                </div>
                            </div>

                            <div className={styles.blog_container}>
                                <div className={styles.blog_sub_heading}>Taking It for a Spin</div>
                                <div className={styles.blog_heading}>Listen to the Engine:</div>
                                <div className={styles.blog_content}>
                                    The engine should run smoothly without any weird noises. Knocking or pinging sounds are bad news.
                                </div>
                                <div className={styles.blog_heading}>Brake Check:</div>
                                <div className={styles.blog_content}>
                                    Brakes should feel solid and not make any strange noises. If the car shakes when you brake, that's not good.
                                </div>
                                <div className={styles.blog_heading}>Steering and Driving:</div>
                                <div className={styles.blog_content}>
                                    Driving should feel easy. The car shouldn't pull to one side, and the steering shouldn't feel loose or shaky.
                                </div>
                                <div className={styles.blog_heading}>How It Feels:</div>
                                <div className={styles.blog_content}>
                                    Lastly, does the car feel right to you? It should be comfortable and drive smoothly.
                                </div>
                            </div>

                            <div className={styles.blog_container2}>
                                <div className={styles.blogs_right}>
                                    <div className={styles.blog_content}>
                                        This guide should help you determine if the car you're considering is a keeper. However, it's always wise to have a professional inspect it too, just to be certain. Don't worry, Nxcar can handle it all from the comfort of your own home. We assign a car expert and schedule a visit at your convenience and location for your car's inspection. You'll receive a detailed report from our team about the physical condition of the car you plan to buy. And if you're concerned about financing, Nxcar can assist you with a loan to purchase the car.
                                    </div>
                                    <div className={styles.green_line}>
                                        So, are you ready to go car shopping?
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* car image */}
                        <div className={styles.nxcar_handle}>
                            <div className={styles.box_heading}>
                                Buying a Used Car: Let Nxcar Handle It All
                            </div>
                            <div className={styles.car_image}>
                                <img src={car_with_path} alt="" />
                            </div>
                            <ul>
                                <li>Used Car Loan</li>
                                <li>Car Inspection</li>
                                <li>Document Verification</li>
                                <li>Insurance Transfer</li>
                                <li>RC Transfer</li>
                                <li>Extended Warranty</li>
                            </ul>
                            <Link to='/bundle' onClick={scrollToTop}>
                                <div className={styles.build_my_pack}>
                                    Build My Package Now
                                </div>
                            </Link>
                        </div>
                    </div>

                    <OtherBlogs />
                </div>
            </div>
            <div className={styles.footer}>
                <Footer />
            </div>
        </>
    )
}

export default Blog5