import React, { useEffect } from 'react';
import styles from './Scanner.module.css';
import logo from '../../images/logos/logo.svg'

export default function Redirect() {
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.applore.nxfin';
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = 'https://apps.apple.com/in/app/nxcar-partners/id6474630163';
    } else {
      window.location.href = 'https://www.nxcar.in';
    }
  }, []);
  return (
    <div className={styles.container}>
      <img src={logo} alt="" />
    </div>
  )
}
