import React from 'react'
import styles from './Landing.module.css'
import Criteria from '../../../components/used_car_loan/criteria/Criteria';
import WhyUseCars from '../../../components/used_car_loan/whyUseCars/WhyUseCars';
import Testimonial from '../../../reusable/testimonial/Testimonial';
import LendingPartner from '../../../reusable/heroLandingPage/lendingPartner2/LendingPartner';
import Explore from '../../../reusable/heroLandingPage/explore/Explore';
import HeroLandingPartner from '../../../reusable/heroLandingPage/hero/HeroLandingPage';
import Knowledge from '../../../components/knowledge_section/Knowledge';
import LandingFooter from '../../../reusable/heroLandingPage/landingFooter/LandingFooter';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';

export default function Landing() {
    return (
        <div className={styles.container}>
            <div className={styles.main_container}>
                <div className={styles.header}
                    style={{ position: 'sticky', top: '0', zIndex: '99' }}
                >
                    <Header />
                </div>
                <HeroLandingPartner />
                <Explore />
                <Criteria />
                <div className={styles.blogs1}>
                    <WhyUseCars />
                </div>
                <div className={styles.blogs2}>
                    <Knowledge />
                </div>
                <Testimonial />
                <LendingPartner />
                <LandingFooter />
            </div>

            <div className={styles.footer}>
                <Footer />
            </div>
        </div>
    )
}
