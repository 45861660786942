import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header/Header';
import styles from './Your_journey.module.css';
import { IoCloseSharp } from "react-icons/io5";
import { Link } from 'react-router-dom';
import img1 from '../../../images/vectors/homescreen/services/used_car.svg';
import img2 from '../../../images/vectors/homescreen/services/inspection.svg';
import img3 from '../../../images/vectors/homescreen/services/extended_warranty.svg';
import img4 from '../../../images/vectors/homescreen/services/docs_verify.svg';
import img5 from '../../../images/vectors/homescreen/services/insurane_transfer.svg';
import img6 from '../../../images/vectors/homescreen/services/rc_transfer.svg';
import path from '../../../images/vectors/your_journey/path.svg'
import dark_arrow from '../../../images/vectors/your_journey/dark_arrow.svg'
import light_arrow from '../../../images/vectors/your_journey/light_arrow.svg'
import { FaPlay } from "react-icons/fa";
import { scrollToTop } from '../../../reusable/scrollToTop/ScrollToTopButton';


const Your_journey = () => {
    return (
        <>
            <Header />
            <div className={styles.container}>
                <div className={styles.top}>
                    <div className={styles.heading}>
                        Next steps in the process
                        <div className={styles.sub_heading}>
                            Here is how we will ensure best experience for you:
                        </div>
                    </div>

                    <Link to='/'>
                        <div className={styles.close_button}>
                            <IoCloseSharp />
                        </div>
                    </Link>
                </div>
                <div className={styles.main_container}>
                    <div className={styles.path}>
                        <img src={path} alt="" />
                    </div>

                    <div className={styles.loan_container1}>
                        <div className={styles.left}>
                            <div className={styles.left_inner}>
                                <img src={img1} alt="" />
                            </div>
                        </div>
                        <div className={styles.right}>
                            <div className={styles.right_heading}>Used Car Loans</div>
                            <div className={styles.right_sub_heading}>
                                <ol>
                                    <li>
                                        We will check your loan eligibility and share offers from leading banks/NBFCs
                                    </li>
                                    <li>
                                        We coordinate with lenders for your vehicle’s valuation
                                    </li>
                                    <li>
                                        Ensure smooth transition by sharing document details and transfer forms
                                    </li>
                                    <li>
                                        Assist in obtaining NOC from seller’s bank and RTO
                                    </li>
                                    <li>
                                        Arrange loan disbursement to your seller’s account
                                        <br />
                                        We DO NOT charge any fees for arranging the loan

                                    </li>
                                    <Link onClick={scrollToTop}
                                        to='/used-car-loan/eligibility'>
                                        <div className={styles.link_for_page}>
                                            &gt;
                                            &gt;
                                            &gt;

                                            Check Loan Eligibility
                                        </div>
                                    </Link>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className={styles.loan_container2}>
                        <div className={styles.left}>
                            <div className={styles.left_inner}>
                                <img src={img2} alt="" />
                            </div>
                        </div>
                        <div className={styles.right}>
                            <div className={styles.right_heading}> Vehicle Inspection</div>
                            <div className={styles.right_sub_heading}>
                                <ol>
                                    <li>
                                        We will ascertain your vehicle’s condition through Physical inspection at your location
                                    </li>
                                    <li>
                                        Our team will share a detailed report with over 150 checkpoints to uncover any surprises
                                    </li>
                                    <li>
                                        If you buy extended warranty package, charges for vehicle inspection will be waived off
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className={styles.loan_container3}>
                        <div className={styles.left}>
                            <div className={styles.left_inner}>
                                <img src={img3} alt="" />
                            </div>
                        </div>
                        <div className={styles.right}>
                            <div className={styles.right_heading}> Extended Warranty</div>
                            <div className={styles.right_sub_heading}>
                                <ol>
                                    <li>
                                        Nxcar facilitates 6 months to 1-year extended warranty by India's leading vehicle warranty provider, available nationwide.
                                    </li>
                                    <li>
                                        You can also enjoy extended warranty to vehicles up to 7 years old that pass inspection.
                                    </li>
                                    <li>
                                        Feel free to choose from comprehensive and powertrain-only (engine and transmission) packages.
                                    </li>
                                    <Link onClick={scrollToTop} to='/nxcar-extended-warranty-terms-and-conditions'>
                                        <div className={styles.link_for_page}>
                                            &gt;
                                            &gt;
                                            &gt;

                                            Terms & Condition applied for extended warranty
                                        </div>
                                    </Link>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className={styles.loan_container4}>
                        <div className={styles.left}>
                            <div className={styles.left_inner}>
                                <img src={img4} alt="" />
                            </div>
                        </div>
                        <div className={styles.right}>
                            <div className={styles.right_heading}> Document Verification</div>
                            <div className={styles.right_sub_heading}>
                                <ol>
                                    <li>
                                        No need to worry about the registration status, ownership details, bank loans, outstanding challans, and blacklist entries we will validate them.
                                    </li>
                                    <li>
                                        This service is free if you apply for a used car loan with us.
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className={styles.loan_container5}>
                        <div className={styles.left}>
                            <div className={styles.left_inner}>
                                <img src={img5} alt="" />
                            </div>
                        </div>
                        <div className={styles.right}>
                            <div className={styles.right_heading}> Insurance Transfer</div>
                            <div className={styles.right_sub_heading}>
                                <ol>
                                    <li>
                                        We will help you with the insurance policy transfer after you have purchased the vehicle.
                                    </li>
                                    <li>
                                        You can also use the already paid premium by the seller, if he/she hasn’t enjoyed a claim bonus.
                                    </li>
                                    <li>
                                        We will also share offers from leading insurers for new policy, if required.
                                    </li>
                                    <li>
                                        We don’t charge any fee for insurance transfer service from Nxcar customers.
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className={styles.loan_container6}>
                        <div className={styles.left}>
                            <div className={styles.left_inner}>
                                <img src={img6} alt="" />
                            </div>
                        </div>
                        <div className={styles.right}>
                            <div className={styles.right_heading}> RC Transfer</div>
                            <div className={styles.right_sub_heading}>
                                <ol>
                                    <li>
                                        We believe in making RC transfer hassle-free for you by taking the entire responsibility
                                    </li>
                                    <li>
                                        We will obtain NOC from the seller's RTO.
                                    </li>
                                    <li>
                                        Will prepare a document set for sale and purchase of a used car.
                                    </li>
                                    <li>
                                        Facilitate transfer in the name of the new owner after submitting the old RC and requisite forms.
                                    </li>
                                    <li>
                                        Handle bank hypothecation and insurance transfer for the new owner.
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>

                </div>
                <div className={styles.tag_line}>
                    <span className={styles.play_icon}>
                        <FaPlay />
                    </span>

                    Our team will reach out to you shortly to arrange service delivery at a time that best suits you. We're thrilled at the opportunity to assist you and ensure a seamless transition with our support. Looking forward to connecting soon! or you can contact us at
                    <Link to='/contact_us'>
                        <span style={{ color: `var(--teal)` }}> contact@nxcar.in  9355924132</span>
                    </Link>
                </div>
            </div>
            {/* <Footer /> */}
        </>
    )
}

export default Your_journey