import React, { useEffect, useState } from 'react'
import ConsumerMain from '../../../../Layout/Consumer/Main/ConsumerMain'
import styles from './Profile.module.css';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

export default function Profile() {
  const [profile, setProfile] = useState();
  const token = localStorage.getItem('Authorization')
  const info = jwtDecode(token);
  const consumer_id = info.consumer_id;

  const fetchData = async () => {
    try {
      const response = await axios.get(`/profile?consumer_id=${consumer_id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`,
        },
      });
      const dataLength = Object.keys(response.data).length;
      if (dataLength > 0) {
        setProfile(response?.data);
      }
    } catch (error) {
      console.error('Error fetching profile:', error?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [consumer_id]);

  const profileFields = [
    { label: 'Name', value: profile?.name },
    { label: 'Company', value: profile?.company_name },
    { label: 'Consumer ID', value: profile?.consumer_id },
    { label: 'Email', value: profile?.email },
    { label: 'Phone', value: profile?.phoneNumber },
    { label: 'Residence ', value: profile?.residence_address },
    { label: 'Type', value: profile?.type },
    { label: 'City', value: profile?.city },
  ];

  return (
    <ConsumerMain>
      <div className={styles.right_panel}>
        <div className={styles.welcome_text1}>
          Profile
        </div>

        <div className={styles.table}>
          {profileFields.map((field, index) => (
            <div className={styles.flex} key={index}>
              <div className={styles.left}>{field.label} :</div>
              <div className={styles.right}>{field.value}</div>
            </div>
          ))}
        </div>

      </div>
    </ConsumerMain>
  )
}
