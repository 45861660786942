import React, { useEffect, useState } from 'react'
import styles from './RcCheck.module.css'
import axios from 'axios';
import { toast } from 'react-toastify';
import loader from '../../../../images/loading/loader.gif'
import RcReport from '../../../../reusable/rcReport/RcReport';
import { scrollToTop } from '../../../../reusable/scrollToTop/ScrollToTopButton';


export default function RcCheckForm({ stage, setStage }) {

  const [rcData, setRcData] = useState("");
  const [loading, setLoading] = useState(false);
  const [emptyFields, setEmptyFields] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [otp, setOtp] = useState('');
  const [isOtpValid, setIsOtpValid] = useState(true);
  const [isValid, setIsValid] = useState(true);

  const [formData, setFormData] = useState({
    vehiclenumber: '',
    mobile: '',
    signature: 'nxcar',
  })

  const validateStage1 = () => {
    return (
      formData.mobile !== '' &&
      formData.vehiclenumber !== ''
    );
  };

  const handleNextStage = async (e) => {
    e.preventDefault();
    scrollToTop();
    const emptyFields = [];

    if (stage === 1 && !validateStage1()) {
      emptyFields.push("vehiclenumber", "mobile");
    }
    else {
      if (emptyFields.length === 0) {
        try {
          setLoading(true);
          const result = await axios.post(`/signup_services`, formData);
          if (result.status == 200) {
            setStage(stage + 1);
          } else {
            toast.error('Oops There Is An Issue With The Vechile Number');
          }
        } catch (error) {
          toast.warning('Oops There Is An Issue With Number');
        } finally {
          setLoading(false);
        }
      }
    }
    setEmptyFields(emptyFields);
  };

  const handleOTPVerification = async (e) => {
    e.preventDefault()
    scrollToTop();
    try {
      const response = await axios.post(`/signup_services_otp_verify`, {
        mobile: formData.mobile,
        otp: otp,
      });
      if (response.status === 200) {
        const rcdata = response?.data?.data;
        setRcData(rcdata)
        setStage(stage + 1);
      } else {
        toast.error('Wrong OTP');
        setIsOtpValid(false);
      }
    } catch (error) {
      toast.error('Wrong OTP');
      setIsOtpValid(false);
      setOtp('');
    }
  };

  const handleInputChange = (e, inputName) => {
    let newValue = e.target.files ? e.target.files[0] : e.target.value;

    if (e.target.tagName === 'INPUT') {
      newValue = e.target.value;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [inputName]: newValue,
    }));
  };

  const handleOTPChange = (e) => {
    const newValue = e.target.value;
    setOtp(newValue);
  }

  const resendOTP = async () => {
    try {
      const response = await axios.post(`/resend_otp`, {
        mobile: formData.mobile,
        signature: "Team Nxcar",
        login_id: localStorage.getItem('OTP_login_id')
      });
      if (response.status === 200) {
        toast.dismiss();
        toast.success('OTP sent');
      } else {
        toast.dismiss();
        toast.error('error OTP sent');
        setIsValid(false);
      }
    } catch (error) {
      console.error('Error during login API call:', error);
    }
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  const handleViewPDF = () => {
    setStage(1);
    setFormData({
      vehiclenumber: '',
      mobile: '',
    });
  }


  return (
    <>
      <div className={styles.form}>

        {/* FORM */}
        <form>

          {stage === 1 && (
            <>
              <div className={styles.heading}>
                Check RC Details
              </div>
              <div className={styles.sub_heading}>
                Access & verify your RC Details ,Insurance, Ownership, Permit & hypothecation details effortlessly with our seamless service.
              </div>
              {/* VEHICLE NUMBER */}
              <>
                <div className={styles.MUI_input}>
                  <input
                    className={formData.vehiclenumber.length > 0 ? styles.input_field1 : styles.input_field}
                    type="text"
                    id='vehiclenumber'
                    value={formData.vehiclenumber}
                    onChange={(e) => handleInputChange(e, 'vehiclenumber')}
                    // pattern='^[A-Z]{2}[0-9]{1,2}(?:[A-Z])?(?:[A-Z]*)?[0-9]{4}$'
                    maxLength={10}
                    onInput={(e) => {
                      e.target.value = e.target.value.toUpperCase();
                    }}
                    required
                  />
                  <div className={`${styles.float_label} ${formData.vehiclenumber && styles.labelActive}`}>
                    Enter Vehicle Number <span style={{ color: `var(--red)` }}>*</span>
                  </div>
                </div>
                {emptyFields.includes("vehiclenumber") && formData.vehiclenumber === "" && (
                  <p className={styles.error}>Vehicle Number is required</p>
                )}
                {formData.vehiclenumber && !/^[A-Z]{2}[0-9]{1,2}(?:[A-Z])?(?:[A-Z]*)?[0-9]{4}$/.test(formData.vehiclenumber) && (
                  <p className={styles.error}>
                    Please provide a valid Indian vehicle number
                  </p>
                )}
              </>
              {/* MOBILE NUMBER */}
              <>
                <div className={styles.MUI_input}>
                  <div className={`${styles.phoneNumberDisplay} ${formData.mobile && styles.phoneNumberActive}`}>
                    +91
                  </div>
                  <input
                    className={`${styles.input_field} ${formData.mobile.length > 0 ? styles.input_field2 : ''} ${isFocused ? styles.input_field2 : ''}`}
                    type="tel"
                    maxLength={10}
                    value={formData.mobile}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    id='phone_number'
                    style={{ padding: "12px 0px" }}
                    required
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const sanitizedValue = inputValue.replace(/\D/g, '');
                      handleInputChange({ target: { value: sanitizedValue } }, 'mobile');
                    }}
                  />
                  <div className={`${styles.float_label} ${formData.mobile && styles.labelActive}`}>
                    {isFocused ? "Enter your mobile number" : formData.mobile.length === 0 ? "+91 Enter your mobile number" : "Enter your mobile number"} <span style={{ color: `var(--red)` }}>*</span>
                  </div>
                </div>
                {emptyFields.includes("mobile") && (formData.mobile === 0 || formData.mobile === "") && (
                  <p className={styles.error}> Mobile is required</p>
                )}
                {formData.mobile && !/^[6789]\d{9}$/.test(formData.mobile) && (
                  <p className={styles.error}>
                    Please provide an Indian number
                  </p>
                )}
              </>

              <button
                type="submit"
                className={styles.button}
                onClick={handleNextStage}
                disabled={loading}>
                {
                  loading ?
                    <div className={styles.loading}>
                      <img src={loader} alt="loader" />
                    </div>
                    : 'Send OTP'
                }
              </button>
            </>
          )}

          {stage === 2 && (
            <>
              <div className={styles.heading}>
                Verify your OTP
              </div>
              <div className={styles.sub_heading}>
                OTP has been sent to your number
              </div>
              {/* OTP */}
              <>
                <div className={styles.new_otp}>
                  <input
                    placeholder='● ● ● ●'
                    required
                    maxLength={4}
                    value={otp}
                    autoComplete='one-time-code'
                    onChange={handleOTPChange}
                    type="tel" />
                </div>

                {isOtpValid ? (
                  <button
                    className={styles.button}
                    type='submit'
                    onClick={handleOTPVerification}
                  >
                    Submit
                  </button>
                ) : (
                  <>
                    <button
                      className={styles.button}
                      type='submit'
                      onClick={handleOTPVerification}
                    >
                      Submit
                    </button>
                    <span style={{ color: "red", margin: "10px 0" }}>
                      Wrong OTP
                    </span>
                  </>
                )}

                <div className={styles.otp_will_expire}>
                  {!isOtpValid && (
                    <div className={styles.error_message_otp}></div>
                  )}
                  <>
                    <button
                      className={styles.resend_button}
                      onClick={resendOTP}>
                      Resend OTP
                    </button>
                  </>
                </div>
              </>
            </>
          )}

          {stage === 3 && (
            <>
              {rcData && <RcReport data1={rcData} handleViewPDF={handleViewPDF} />}
            </>
          )}

        </form>
      </div>
    </>
  )
}