import React from 'react';
import styles from './Package.module.css';
import { Link } from 'react-router-dom';
import insurance from '../../../../images/vectors/carscope/car_insurance.jpg';

export default function PackageWithPrice({ data1 }) {
  const a = data1?.insurance_comprehensive;
  const b = data1?.insurance_third_party;
  const c = data1?.insurance_zero_dep;
  const d = data1?.insurance_stand_alone_own_damage;
  const ACKO_LINK = 'https://www.acko.com/gi/falcon/widgets/car-info/car?utm_source=partnership&utm_campaign=carinfo&irclid=QWJzcvbP6Iwl&utm_content=QWJzcvbP6Iwl&utm_term=nxfin&phone=nxfin'

  return (
    <>
      {/* {
        data1?.insurance_dv > 0 && (
          <div className={styles.heading}>
            IDV @ ₹ {data1?.insurance_dv}
          </div>
        )
      } */}

      {
        (a || b || c || d) ? (
          <>
            <div className={styles.flex}>
              <div className={styles.web_image}>
                <img src={insurance} alt="" />
              </div>
              <div className={styles.web_image1}>
                {
                  data1?.insurance_comprehensive && (
                    <div className={styles.text1}>
                      Comprehensive
                      <span className={styles.color}>₹ {data1.insurance_comprehensive}</span>
                    </div>
                  )
                }

                {
                  data1.insurance_third_party && (
                    <div className={styles.text1}>
                      Third Party
                      <span className={styles.color}>₹  {data1.insurance_third_party}</span>
                    </div>
                  )
                }

                {
                  data1.insurance_zero_dep && (
                    <div className={styles.text1}>
                      Zero Depreciation
                      <span className={styles.color}>₹  {data1.insurance_zero_dep}</span>
                    </div>
                  )
                }

                {
                  data1.insurance_stand_alone_own_damage && (
                    <div className={styles.text1}>
                      Stand alone own damage
                      <span className={styles.color}>₹ {data1.insurance_stand_alone_own_damage}</span>
                    </div>
                  )
                }
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.flex}>
              <div className={styles.web_image}>
                <img src={insurance} alt="" />
              </div>
                <div className={styles.text2}>
                Insurance Quote available on Requests
              </div>
            </div>
          </>
        )
      }
    </>
  )
}