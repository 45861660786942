import React from 'react'
import styles from './Marquee.module.css';
import Marquee from "react-fast-marquee";
import img1 from '../../../images/vectors/used_car/cars/baleno.svg'
import img2 from '../../../images/vectors/used_car/cars/citro.svg'
// import img3 from '../../../images/vectors/used_car/cars/innova.svg'
import img4 from '../../../images/vectors/used_car/cars/jaguar.svg'
import img5 from '../../../images/vectors/used_car/cars/kia.svg'
import img6 from '../../../images/vectors/used_car/cars/maruti.svg'
import img7 from '../../../images/vectors/used_car/cars/nexon.svg'
import img8 from '../../../images/vectors/used_car/cars/toyota.svg'
import img9 from '../../../images/vectors/used_car/cars/xuv.svg'
import img10 from '../../../images/vectors/used_car/cars/creta.svg'
import img11 from '../../../images/vectors/used_car/cars/range_rover.svg'
// import img12 from '../../../images/vectors/used_car/cars/taigun.svg'

const Marquee_component = () => {
    return (
        <Marquee
            pauseOnHover
            pauseOnClick
            direction='right'
        >
            <div className={styles.car_container}>
                {/* <img src={img1} alt="" /> */}
                <img src={img2} alt="" />
                {/* <img src={img3} alt="" /> */}
                <img src={img4} alt="" />
                <img src={img5} alt="" />
                <img src={img6} alt="" />
                <img src={img7} alt="" />
                <img src={img8} alt="" />
                <img src={img9} alt="" />
                <img src={img10} alt="" />
                <img src={img11} alt="" />
                {/* <img src={img12} alt="" /> */}
            </div>
        </Marquee>
    )
}

export default Marquee_component