import React from 'react'
import styles from './ApplicationQR.module.css';
import QR from '../../../images/vectors/channel_partner/QR.svg'

export default function ApplicationQR() {
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.left}>
          Scan to download our <br />
          <span className={styles.text}>Nxcar partner’s</span> app
        </div>
        <div className={styles.right}>
          <img src={QR} alt="" />
        </div>
      </div>
    </div>
  )
}
