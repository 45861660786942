import styles from './Check.module.css';
import img1 from '../../images/vectors/homescreen/big_card/loan_eligibility.svg'
import img2 from '../../images/vectors/homescreen/big_card/EMI_calc.svg'
import { Link } from 'react-router-dom';
import { Button, Modal, Backdrop, Fade, Box } from '@mui/material';
import { useState, useEffect } from 'react';
import { IoClose } from 'react-icons/io5';
import axios from 'axios';
import { scrollToTop } from '../scrollToTop/ScrollToTopButton';
import LoanEligibility from '../loanEligibility/LoanEligibility';
import Faade from 'react-reveal/Fade';
import NewLoan from '../loanEligibility/NewLoanEligibility/NewLoan';
import { EMI_calcultor } from './animations/EMI_calcultor';
import { Car_buying } from './animations/Car_buying';


const styleMUIModal = {
    position: "absolute",
    top: "0%",
    right: "0%",
    width: '40%',
    height: '100%',
    bgcolor: `white`,
    border: "0px solid #000",
    boxShadow: 24,
    outline: "none",
    overflowY: "auto",
    // p: 4,
};
const styleMUIModalMobile = {
    width: "100%"
}


const Check_now = () => {
    const [step, setStep] = useState(1);
    const [open, setOpen] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [isFocused, setIsFocused] = useState(false);
    const [focusedInput, setFocusedInput] = useState(null);

    const [phoneNumber, setPhoneNumber] = useState('');
    const [seconds, setSeconds] = useState(120);
    const [isTimeExpired, setIsTimeExpired] = useState(false);
    const [otp, setOtp] = useState(new Array(4).fill(""));
    const [isOtpValid, setIsOtpValid] = useState(true);
    const [otpErrorMessage, setOtpErrorMessage] = useState("");

    const handleOpen = () => {
        setStep(1);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setStep(4);
    }

    useEffect(() => {
        let interval

        if (step == 2) {
            interval = setInterval(() => {
                setSeconds((prevSeconds) => {
                    if (prevSeconds === 1) {
                        setIsTimeExpired(true);
                        clearInterval(interval);
                        return 0;
                    }
                    return prevSeconds - 1;
                });
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [step]);


    // THE FORMATTED DATE FOR THE DATE AND TIME
    const formattedTime = () => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        return `${hours < 10 ? '0' : ''}${hours} : ${minutes < 10 ? '0' : ''}${minutes} : 
        ${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}s`;
    };

    // THIS 3 FUNCTIONS ARE JUST FOR UI PURPOSE 
    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value);
        setIsValid(true);
    };

    const handleInputFocus = () => {
        setIsFocused(true);
    };

    const handleInputBlur = () => {
        setIsFocused(false);
    };

    // APIS HERE ----------------------------------------------------------
    const loginAPI = async () => {
        try {
            const response = await axios.post('/mobile', {
                mobile: phoneNumber,
                signature: "Team Nxcar"
            });
            if (response === 200) {
                setStep(2);
            } else {
                setIsValid(false);
            }
        } catch (error) {
            console.error('Error during login API call:', error);
        }
    };

    // LOGIN API USING HERE
    const handleLogin = async (e) => {
        e.preventDefault();
        const indianPhoneNumberRegex = /^[6-9]\d{9}$/;
        if (indianPhoneNumberRegex.test(phoneNumber)) {
            await loginAPI();
            setStep(2);
        } else {
            setIsValid(false);
        }
    };

    // OTP VERIFICATION API HERE
    const otpVerificationAPI = async () => {
        try {
            const response = await axios.post('/otpVerification', {
                mobile: phoneNumber,
                otp: otp.join('')
            });

            if (response.status == 200) {
                localStorage.setItem("token", response.data.access_token)
                setStep(3)
            } else {
                setIsOtpValid(false);
            }
        } catch (error) {
            setIsOtpValid(false);
        }
    };

    // OTP VERIFICATION API
    const handleOTPChange = (element, index) => {
        if (isNaN(element.value)) return false;

        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

        // Focus next input
        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };

    const handleOTPVerification = async (e) => {
        e.preventDefault();
        await otpVerificationAPI();
    };


    return (
        <>
            <div className={styles.dark}>
                <div className={styles.container}>
                    <Faade left>
                        <div className={styles.card}>
                            <div className={styles.image_container}>
                                {/* <img src={img2} alt="" /> */}
                                <EMI_calcultor />
                            </div>
                            <div className={styles.content}>
                                <div className={styles.tagline}>
                                    Optimize your loan tenure and EMI
                                </div>
                                <div className={styles.heading}>
                                    With EMI Calculator
                                </div>
                            </div>
                            <Link to='/calculator'>
                                <div
                                    className={styles.button}
                                    onClick={scrollToTop}
                                >
                                    Calculate EMI
                                </div>
                            </Link>
                        </div>
                    </Faade>
                    <Faade left>
                        <div className={styles.card}>
                            <div className={styles.image_container}>
                                {/* <img src={img1} alt="" /> */}
                                <Car_buying />
                            </div>
                            <div className={styles.content}>
                                <div className={styles.tagline}>
                                    Expand your car buying Options
                                </div>
                                <div className={styles.heading}>
                                    With Pre Approved Loans
                                </div>
                            </div>
                            <div onClick={handleOpen}>
                                <div className={styles.button}>
                                    Check Offers
                                </div>
                            </div>
                        </div>
                    </Faade>


                </div>

                {/* MODAL */}
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                >
                    <Box
                        sx={{ ...styleMUIModal, ...(window.innerWidth <= 600 && styleMUIModalMobile) }}>
                        <NewLoan
                            handleClose={handleClose}
                        />
                    </Box>
                </Modal>
                {/* MODAL */}
            </div>
        </>
    )
}

export default Check_now