import { useState, useEffect, useRef } from 'react';
import styles from './NewHero.module.css';
import { useMediaQuery } from '@material-ui/core';
import { Box, Modal } from '@mui/material';
import NewLoan from '../../loanEligibility/NewLoanEligibility/NewLoan';
import imageSrc from '../../../images/vectors/used_car/hero/new.svg'
import UsedCar_Animation from './animations/UsedCar_Animation';
import hand_arrow from '../../../images/vectors/homescreen/arrows/simple_arrow.svg'
import Keys from './animations/Keys';
const styleMUIModal = {
    position: "absolute",
    top: "0%",
    right: "0%",
    width: '40%',
    height: '100%',
    bgcolor: "white",
    border: "0px solid #000",
    boxShadow: 24,
    outline: "none",
    p: 0,
};
const styleMUIModalMobile = {
    width: "100%",
    height: "100vh",
    top: "0%",
    right: "0%",
    height: '100vh',
    bgcolor: "white",
    border: "0px solid #000",
    boxShadow: 24,
    outline: "none",
    p: 0,
}

const NewHero = ({ heading1, heading2, heading3, buttonText1, buttonText2, single_button }) => {
    // CANVAS
    const canvasRef = useRef(null);
    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        const col = (x, y, r, g, b) => {
            ctx.fillStyle = `rgb(${r},${g},${b})`;
            ctx.fillRect(x, y, 1, 1);
        };

        const colors = [
            [155, 81, 224, 1],
            [10, 211, 903, 1],
            [70, 211, 503, 1],
        ];

        const R = (x, y, t) => colors[0][0] + Math.floor(64 * Math.cos((x * x - y * y) / 300 + t));
        const G = (x, y, t) => colors[1][1] + Math.floor(64 * Math.sin((x * x * Math.cos(t / 4) + y * y * Math.sin(t / 3)) / 300));
        const B = (x, y, t) => colors[2][2] + Math.floor(64 * Math.sin(5 * Math.sin(t / 9) + ((x - 100) * (x - 100) + (y - 100) * (y - 100)) / 1100));


        let t = 0;

        const run = () => {
            for (let x = 0; x <= 35; x++) {
                for (let y = 0; y <= 35; y++) {
                    col(x, y, R(x, y, t), G(x, y, t), B(x, y, t));
                }
            }
            t = t + 0.01;
            window.requestAnimationFrame(run);
        };

        run();

    }, []);
    // CANVAS


    const isMobile = useMediaQuery('(max-width: 940px)');
    const [isLoanFormVisible, setIsLoanFormVisible] = useState(false);

    const handleOpenLoanForm = () => {
        setIsLoanFormVisible(true);
        window.history.pushState(null, null, '/used-car-loan/eligibility')
    };

    const handleCloseLoanForm = () => {
        setIsLoanFormVisible(false);
        window.history.pushState(null, null, '/used-car-loan')
    };

    useEffect(() => {
        if (window.location.pathname === '/used-car-loan/eligibility') {
            setIsLoanFormVisible(true);
        }
    }, []);


    return (
        <>
            <div className={styles.my_canvas}>
                <canvas ref={canvasRef}
                    width="36" height="36" className={styles.canvas}>
                </canvas>
            </div>
            <div className={styles.dark}>
                <div className={styles.left}>
                    <div className={styles.heading1}>{heading1}</div>
                    <div className={styles.heading2}>{heading2}</div>
                    <div className={styles.heading3}>{heading3}</div>
                    <div className={styles.heading4}>
                        Whether you purchase a car from a dealer, an online listing, or an individual, get a loan from Nxcar.
                    </div>

                    {single_button && (
                        <>
                            <div className={styles.CTA_homescreen}>
                                <div
                                    onClick={handleOpenLoanForm}
                                    className={styles.single_button}>
                                    {single_button}
                                </div>
                                <div className={styles.CTA_image}>
                                    {/* <img src={hand_arrow} alt="" /> */}
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className={styles.right}>
                    <UsedCar_Animation />
                    <span className={styles.right_key}> <Keys /></span>
                </div>
                {isLoanFormVisible && (
                    <Modal
                        open={isLoanFormVisible}
                        onClose={handleCloseLoanForm}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={isMobile ? styleMUIModalMobile : styleMUIModal}>
                            <NewLoan handleClose={handleCloseLoanForm} />
                        </Box>
                    </Modal>
                )}
            </div>
        </>
    );
};

export default NewHero