import Footer from '../../../../components/Footer/Footer';
import Header from '../../../../components/Header/Header';
import styles from '../Blogs.module.css';
import car from '../../../../images/vectors/blogs/car_with_shadow.svg'
import img3 from '../../../../images/vectors/homescreen/knoledge/img3.svg'
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import OtherBlogs from './OtherBlogs';

import car_with_path from '../../../../images/vectors/blogs/Car.svg'
import { Link } from 'react-router-dom';
import { scrollToTop } from '../../../../reusable/scrollToTop/ScrollToTopButton';

const Blog3 = () => {
  const carRef = useRef(null);
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const car = carRef.current;
    gsap.to(car, {
      x: '-120%',
      y: '200%',
      scrollTrigger: {
        trigger: car,
        scroller: 'body',
        start: '100% 50%',
        end: '10% 90%',
        scrub: 2,
        pin: true,
      },
    });
  }, []);

  return (
    <>
      <div className={styles.curve_container}>
        <Header />
        <div className={styles.container}>
          {/* <div className={styles.car_design} ref={carRef}>
          <img src={car} alt="" />
        </div> */}
          {/* <div className={styles.blog_design}></div> */}

          <div className={styles.flex_box}>
            <div className={styles.blog}>

              <div className={styles.blog_container}>
                <div className={styles.blog_heading_main}>When not to buy a used car?</div>
                <div className={styles.blog_sub_heading}>The wise ownership!</div>

                <div className={styles.mobile_image}>
                  <img src={img3} alt="mobile_image_nxcar" />
                </div>

                <div className={styles.blog_content}>
                  Imagine riding down the coastal roads of Goa in a car you just bought, the wind in your hair, music playing, and not a worry in sight. This could be you, but what if the car turns out to be less dreamy and more of a nightmare? While buying a used car in India can be a wallet-friendly and smart choice, there are times when stepping on the brakes before making the purchase is wiser. Let’s look at the scenarios when buying a used car might not be an option for you.
                </div>
                <div className={styles.blog_heading}>
                  High Mileage
                </div>
                <div className={styles.blog_content}>
                  If you need a car for daily use, picking a car that has travelled a long distance might not be the right pick for you. High mileage often means more maintenance issues down the line and might not adhere to your needs.
                </div>
              </div>
              <div className={styles.blog_container2}>
                <div className={styles.blogs_right}>
                  <div className={styles.blog_heading}>
                    Sketchy History
                  </div>

                  <div className={styles.blog_content}>
                    A car with a hidden past is like a mystery novel with missing pages. Lack of service records, a history of major accidents, or a salvage title are all red flags. These cars might come with problems that aren't worth solving. Transparency is key, and without it, you're better off walking away.
                  </div>

                  <div className={styles.blog_heading}>
                    Immediate Repairs Needed</div>
                  <div className={styles.blog_content}>
                    If your dream car needs immediate repairs, calculate the costs first. Sometimes, the money you save on the purchase price can quickly be eaten up by repair bills. A car that needs a lot of work right away might not be the bargain you thought it was.
                  </div>
                </div>
              </div>
              <div className={styles.blog_container}>
                <div className={styles.blog_heading}>
                  How to Stay on the Safe Side?
                </div>

                <div className={styles.blog_content}>
                  While Nxcar is dedicated to making used car buying as safe and transparent as possible, offering services like vehicle inspections, document verification, and more, it’s essential to know when to walk away. Nxcar can help you remove the complexities of the used car purchase experience, but if you encounter any of the red flags mentioned, it might be wise to reconsider your options.
                  Remember, the goal is to enjoy the journey, feeling confident and secure in your purchase.
                </div>
                <div className={styles.green_line}>
                  So, the next time you’re tempted by a used car deal, ask yourself: Is this the smooth ride I’ve been dreaming of, or a detour I should avoid?
                </div>
              </div>
            </div>
            <div className={styles.nxcar_handle}>
              <div className={styles.box_heading}>
                Buying a Used Car: Let Nxcar Handle It All
              </div>
              <div className={styles.car_image}>
                <img src={car_with_path} alt="" />
              </div>
              <ul>
                <li>Used Car Loan</li>
                <li>Car Inspection</li>
                <li>Document Verification</li>
                <li>Insurance Transfer</li>
                <li>RC Transfer</li>
                <li>Extended Warranty</li>
              </ul>
              <Link to='/bundle' onClick={scrollToTop}>
                <div className={styles.build_my_pack}>
                  Build My Package Now
                </div>
              </Link>
            </div>
          </div>

          <OtherBlogs />
        </div>
      </div>
      <div className={styles.footer}>
        <Footer />
      </div>
    </>
  )
}

export default Blog3