import React, { useRef } from 'react';
import styles from './HeroLandingPage.module.css';
import logo from '../../../images/logos/logo.svg';
import { Link } from 'react-router-dom';
import HeroLoanForm from '../loan/HeroLoanForm';
import used_car_loan from '../../../images/vectors/used_car/hero/new.svg';

export default function LandingPage() {
  const firstInputRef = useRef(null);

  const handleClickApplyNow = () => {
    if (firstInputRef.current) {
      firstInputRef.current.focus();
    }
  };

  return (
    <div className={styles.container}>
      {/* MAIN CONTAINER */}
      <div className={styles.main_container}>
        <div className={styles.left}>
          <div className={styles.heading}>
            Now you can get a second hand car loan even when you buy car from an individual, dealer and listing.
          </div>
          {/* <div className={styles.sub_heading}>
            Whether you purchase a car from a individual, an online listing, or an dealer, get a loan from Nxcar.
          </div> */}
        </div>

        <div className={styles.mobile_left}>
          {/* <div className={styles.mobile_image}>
            <img src={used_car_loan} alt="" />
          </div> */}
          <div className={styles.mobile_heading}>
            Now you can get a second hand car loan even when you buy car from an individual, dealer and listing.
          </div>
          {/* <div className={styles.mobile_sub_heading}>
            Whether you purchase a car from a individual, an online listing, or an dealer, get a loan from Nxcar.
          </div> */}
          <div
            onClick={handleClickApplyNow}
            className={styles.mobile_apply_button}>
            Apply Now
          </div>
        </div>

        {/* FORM */}
        <div className={styles.right}>
          <HeroLoanForm firstInputRef={firstInputRef} />
        </div>
      </div>
    </div>
  )
}
