import React, { useState, useRef, useEffect } from 'react';
import Header from '../../components/Header/Header';
import styles from './DriveAway.module.css';
import axios from 'axios';
import RcReport from '../rcReport/RcReport';
import WarrantyTerms from './driveComponents/warranty/WarrantyTerms';
import Rates from './driveComponents/warranty/rates/Rates';
import DriveAwayForm from './driveComponents/loanForm/DriveAwayForm';
import PackageWithPrice from './driveComponents/insurance/PackageWithPrice';
import Footer from '../../components/Footer/Footer';
import { LuEye } from "react-icons/lu";
import { FiEyeOff } from "react-icons/fi";
import hero from '../../images/vectors/carscope/hero.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Modal, useMediaQuery } from '@material-ui/core';
import {
  WhatsappShareButton, WhatsappIcon,
  FacebookShareButton, FacebookIcon,
  TwitterShareButton, TwitterIcon,
  LinkedinShareButton, LinkedinIcon,
  EmailShareButton, EmailIcon,
  TelegramShareButton, TelegramIcon
} from 'react-share';
import blur_rc from '../../images/vectors/carscope/blur_rc.png';
import mobile_insurance from '../../images/vectors/carscope/car_insurance.jpg';
import rc from '../../images/vectors/carscope/offers/rc.svg';
import insurance from '../../images/vectors/carscope/offers/insurance.svg';
import warranty from '../../images/vectors/carscope/offers/warranty.svg';
import loan from '../../images/vectors/carscope/offers/loan.svg';
import { CiShare2 } from 'react-icons/ci';
import { BsDownload } from 'react-icons/bs';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ReportComponent from './driveComponents/ReportDown/ReportComponent';
import { BiLoader } from "react-icons/bi";


const styleMUIModal = {
  position: "absolute",
  top: "0%",
  left: "0",
  right: "0",
  width: '100%',
  height: '100%',
  bgcolor: "white",
  border: "0px solid #000",
  boxShadow: 24,
  outline: "none",
  overflowY: "auto",
  p: 0,
};

const styleMUIModalMobile = {
  width: "100%",
  height: "100vh",
  top: "0%",
  right: "0%",
  height: '100vh',
  bgcolor: "white",
  border: "0px solid #000",
  boxShadow: 24,
  outline: "none",
  overflowY: "auto",
  p: 0,
}

const styleMUIModal1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: '30%',
  height: 'fitContent',
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  border: "0px solid #000",
  borderRadius: '16px',
  boxShadow: 24,
  outline: "none",
  overflowY: "auto",
  p: 2,
};

const styleMUIModalMobile1 = {
  position: "absolute",
  width: "70%",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  border: "0px solid #000",
  borderRadius: '16px',
  boxShadow: 24,
  outline: "none",
  overflowY: "auto",
  p: 1,
}

export default function DriveAway() {
  const { vehicleNumber } = useParams();
  const [stage, setStage] = useState(1);
  const [rcData, setRcData] = useState('');
  const [emptyFields, setEmptyFields] = useState([]);
  const [loading, setLoading] = useState(false);

  const isMobile = useMediaQuery('(max-width: 940px)');
  const [view, setView] = useState(false);
  const [view1, setView1] = useState();
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openWhatsApp, setOpenWhatsApp] = useState(false);

  const navigate = useNavigate();

  const handleWhatsappModal = () => {
    setOpenWhatsApp(!openWhatsApp);
  }

  const [formData, setFormData] = useState({
    vehiclenumber: vehicleNumber || ''
  })

  const handleInputChange = (e, inputName) => {
    let newValue = e.target.files ? e.target.files[0] : e.target.value;

    if (e.target.tagName === 'INPUT') {
      newValue = e.target.value;
    }

    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        [inputName]: newValue,
      };

      // Update the URL with the new vehicle number
      // if (inputName === 'vehiclenumber') {
      //   navigate(`?vehiclenumber=${newValue}`);
      // }

      return updatedFormData;
    });
  };

  useEffect(() => {
    if (formData.vehiclenumber) {
      // navigate(`?vehiclenumber=${formData.vehiclenumber}`);
      const fetchData = async () => {
        try {
          const result = await axios.post(`/driveaway_data`, { vehiclenumber: formData.vehiclenumber });
          setRcData(result.data);
          setErrorMessage('');
        } catch (error) {
          console.log('Error fetching data', error);
          setErrorMessage('No Data Found');
        }
      };

      fetchData();
    }
  }, [formData.vehiclenumber, navigate]);

  const validateStage1 = () => {
    return (
      formData.vehiclenumber !== ''
    );
  };

  const handleNextStage = () => {
    setStage(stage + 1)
  };

  const handleViewPDF = (e) => {
    e.preventDefault()
    setView1(!view1);
  }

  const handleCloseModal = () => {
    setOpen(!open);
    setView(!view);
    setView1(!view1);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emptyFields = [];

    if (stage === 1 && !validateStage1()) {
      emptyFields.push("vehiclenumber", "mobile");
    }
    else {
      if (emptyFields.length === 0) {
        try {
          const result = await axios.post(`/driveaway_data`, formData);
          setRcData(result.data);
          setErrorMessage('');
        } catch (error) {
          console.log('Error in handleNextStage', error);
          setErrorMessage('No Data Found');
        }
      }
    }
    setEmptyFields(emptyFields);
  };
  const dataLength = Object.keys(rcData).length;

  const inputRef = useRef(null);

  useEffect(() => {
    if (formData?.vehiclenumber?.length > 7) {
      inputRef.current.click();
    }
  }, [formData?.vehiclenumber])

  const reportUrl = `${window.location.origin}/driveaway/${formData.vehiclenumber}`;

  // NEW BTN FOR DOWNLOADING PDF
  const componentRef = useRef();

  const handleDownloadPdf = async () => {
    setLoading(true);
    const element = componentRef.current;
    const canvas = await html2canvas(element, { scale: 2 });
    const data = canvas.toDataURL('image/jpeg', 0.8);

    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: 'a4',
      compress: true,
    });

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const imgProps = pdf.getImageProperties(data);
    const imgWidth = pdfWidth;
    const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

    let heightLeft = imgHeight;
    let position = 0;

    pdf.addImage(data, 'JPEG', 0, position, imgWidth, imgHeight);
    heightLeft -= pdfHeight;

    while (heightLeft > 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(data, 'JPEG', 0, position, imgWidth, imgHeight);
      heightLeft -= pdfHeight;
    }

    pdf.save(`Report_${formData.vehiclenumber}.pdf`);
    setLoading(false);
  };



  return (
    <>

      <div className={styles.container1}>
        <div className="no_print">
          <Header />
        </div>
        <div className={styles.white_container} id="report-content">
          <div className={styles.rc_container}>
            <div className={styles.left}>
              <div className={styles.rc_heading}>
                Nxcar’s Car Scope
              </div>
              <div className={styles.rc_sub_heading}>
                {/* Lorem ipsum dolor sit amet consectetur. Blandit nec commodo blandit fusce tellus turpis. Ultrices tempus consequat sit maecenas sagittis. */}
              </div>

              <form onSubmit={handleSubmit}>
                {/* VEHICLE NUMBER */}
                <div className={styles.flex_between}>
                  <div className={styles.flex_col}>
                    <div className={styles.MUI_input}>
                      <input
                        className={formData?.vehiclenumber?.length > 0 ? styles.input_field1 : styles.input_field}
                        type="text"
                        value={formData?.vehiclenumber}
                        onChange={(e) => handleInputChange(e, 'vehiclenumber')}
                        pattern='^[A-Z]{2}[0-9]{1,2}(?:[A-Z])?(?:[A-Z]*)?[0-9]{4}$'
                        maxLength={10}
                        title='FORMAT RJ14CVXXXX'
                        onInput={(e) => {
                          e.target.value = e.target.value.toUpperCase();
                        }}
                        required
                      />
                      <div className={`${styles.float_label} ${formData?.vehiclenumber && styles.labelActive}`}>
                        Enter RC Number <span style={{ color: `var(--red)` }}></span>
                      </div>
                    </div>
                    {emptyFields.includes("vehiclenumber") && formData?.vehiclenumber === "" && (
                      <p className={styles.error}>RC Number is required</p>
                    )}
                    {formData?.vehiclenumber && !/^[A-Z]{2}[0-9]{1,2}(?:[A-Z])?(?:[A-Z]*)?[0-9]{4}$/.test(formData?.vehiclenumber) && (
                      <p className={styles.error}>
                        Please provide a valid Indian RC number
                      </p>
                    )}
                  </div>
                  {
                    formData?.vehiclenumber?.length === 10 ?
                      (
                        <button ref={inputRef} className={styles.none}>
                          Get Car Details
                        </button>
                      ) : (
                        <button ref={inputRef} className={styles.CTA_button}>
                          Get Car Details
                        </button>
                      )
                  }
                </div>
              </form>
            </div>

            {/* RIGHT */}
            <div className={styles.right}>
              <img src={hero} alt="" />
            </div>
          </div>

          {
            dataLength > 0 ? (
              <>
                <div className={styles.navbar}>

                  <div className={styles.navbar_flex}>
                    <div className={styles.navbar_image}>
                      <img src={rc} alt="" />
                    </div>
                    <div className={styles.navbar_text}>RC Check </div>
                  </div>
                  <div className={styles.navbar_flex}>
                    <div className={styles.navbar_image}>
                      <img src={warranty} alt="" />
                    </div>
                    <div className={styles.navbar_text}>Warranty </div>
                  </div>
                  <div className={styles.navbar_flex}>
                    <div className={styles.navbar_image}>
                      <img src={insurance} alt="" />
                    </div>
                    <div className={styles.navbar_text}>Insurance </div>
                  </div>
                  <div className={styles.navbar_flex}>
                    <div className={styles.navbar_image}>
                      <img src={loan} alt="" />
                    </div>
                    <div className={styles.navbar_text}>Loan </div>
                  </div>

                </div>

                <div className={styles.container} >
                  {/* RC CHECK REPORT -> VIEW AND DOWNLOAD */}
                  <div className="no_print">
                    <div className={styles.green}>
                      <div className={styles.heading}>RC Check Report</div>
                      <div className={styles.rc_report}>
                        {dataLength > 0 ? (
                          view1 ? (
                            <Modal
                              open={handleCloseModal}
                              onClose={handleCloseModal}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                            >
                              <Box sx={isMobile ? styleMUIModalMobile : styleMUIModal}>
                                <RcReport
                                  view={view}
                                  handleViewPDF={handleViewPDF}
                                  data={rcData}
                                  data1={rcData.rc_report_generate} />
                              </Box>
                            </Modal>
                          ) : (
                            <div className={styles.blur}>
                              <div className={styles.blur_image}>
                                <img src={blur_rc} alt="" />
                              </div>
                              <div className={styles.flex}>
                                {!view ? (
                                  <button className={styles.button} onClick={handleViewPDF}>
                                    View Full Report <span style={{ marginRight: '10px' }}></span>
                                    <LuEye />
                                  </button>
                                ) : (
                                  <button className={styles.button} onClick={handleViewPDF}>
                                    Hide Report <span style={{ marginRight: '10px' }}></span>
                                    <FiEyeOff />
                                  </button>
                                )}
                              </div>
                            </div>
                          )
                        ) : null}
                      </div>
                    </div>
                  </div>

                  {/* WARRANTY */}
                  <>
                    {/* <div className={styles.heading}>Warranty Certified </div> */}
                    <Rates data1={rcData} />
                    <WarrantyTerms data1={rcData} />
                  </>

                  {/* INSURANCE QUOTES PROPOSAL */}
                  <div className={styles.blue_border}>
                    <div className={styles.mobile_image}>
                      <img src={mobile_insurance} alt="" />
                    </div>
                    <div className={styles.heading2}>Insurance Proposals</div>
                    <PackageWithPrice data1={rcData} />
                  </div>
                </div>

                {/* LOAN APPLICATION FORM- NEW ONE */}
                <div className='no_print'>
                  <DriveAwayForm />

                  <div className={styles.blue_bg}>
                    <div className={styles.heading3}>Share with your friend or download the page</div>
                    <div className={styles.flex3}>

                      {loading ? (
                        <div
                          className={styles.loading}
                        >
                          <div className={styles.button3}>
                            <span>Please Wait</span>
                            <span className={styles.icon}> <BiLoader /> </span>
                          </div>
                        </div>
                      )
                        :
                        (
                          <div className={styles.button3} onClick={handleDownloadPdf}>Full Report
                            <span className={styles.icon}> <BsDownload /> </span>
                          </div>
                        )
                      }

                      <div className={styles.button3} onClick={handleWhatsappModal}>Share Now
                        <span className={styles.icon}><CiShare2 /></span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={styles.no_data_found}>
                  {errorMessage && <div>{errorMessage}</div>}
                </div>
              </>
            )
          }

          <div className={styles.none2}>
            <ReportComponent data1={rcData} ref={componentRef} />
          </div>

        </div>
      </div>
      <div className={styles.footer}>
        <div className="no_print">
          <Footer />
        </div>
      </div>

      {/* MODAL */}
      {
        openWhatsApp && (
          <Modal
            open={handleWhatsappModal}
            onClose={handleWhatsappModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={isMobile ? styleMUIModalMobile1 : styleMUIModal1}>
              <div className={styles.share}>
                <div className={styles.share_heading}>Share PDF</div>

                <div className={styles.flex_between}>
                  <div className={styles.share_icon}>
                    <WhatsappShareButton url={reportUrl}>
                      <WhatsappIcon size={50} round={true} />
                    </WhatsappShareButton>
                  </div>
                  <div className={styles.share_icon}>
                    <FacebookShareButton url={reportUrl}>
                      <FacebookIcon size={50} round={true} />
                    </FacebookShareButton>
                  </div>
                  <div className={styles.share_icon}>
                    <TwitterShareButton url={reportUrl}>
                      <TwitterIcon size={50} round={true} />
                    </TwitterShareButton>
                  </div>
                  <div className={styles.share_icon}>
                    <LinkedinShareButton url={reportUrl}>
                      <LinkedinIcon size={50} round={true} />
                    </LinkedinShareButton>
                  </div>
                  <div className={styles.share_icon}>
                    <EmailShareButton url={reportUrl}>
                      <EmailIcon size={50} round={true} />
                    </EmailShareButton>
                  </div>
                  <div className={styles.share_icon}>
                    <TelegramShareButton url={reportUrl}>
                      <TelegramIcon size={50} round={true} />
                    </TelegramShareButton>
                  </div>
                </div>

              </div>
            </Box>
          </Modal>
        )
      }
      {/* MODAL */}
    </>
  )
}