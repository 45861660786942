import { useRef, useState, useEffect } from 'react';
import styles from './Testimonial.module.css'
import { FaAngleLeft } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa";
import img1 from '../../images/vectors/homescreen/testimonials/img1.svg'
import img2 from '../../images/vectors/homescreen/testimonials/img2.svg'
import img3 from '../../images/vectors/homescreen/testimonials/img3.svg'
import img4 from '../../images/vectors/homescreen/testimonials/img4.svg'
import img5 from '../../images/vectors/homescreen/testimonials/img5.svg'


const Testimonial = () => {
    const arr = [
        {
            text: "I was picking a car from a dealer which I really liked, however it was beyond my budget. The dealer told me about the second hand car loan facility available at Nxcar. I got my loan disbursed within 2 days of reaching out to them. I am happily riding my car, thanks to Nxcar.",
            profilePic: img4,
            profileName: "Nidhi Raj, Chandigarh"
        },
        {
            text: "I was buying a car from my cousin, but didn’t wish to spend my savings on the car. I got to know about Nxcar from a friend and decided to take a loan. To my surprise, the process was simple and didn’t take much time. They also took care of my insurance transfer which my cousin didn’t utilise. It was a win-win transaction. Recommended",
            profilePic: img1,
            profileName: "Ankush Singh, Gurgaon"
        },
        {
            text: "RC transfer, those who know, know that it's a hassle which everyone wants to avoid. I saw an ad from Nxcar and contacted them for RC transfer, for a minimal cost, they helped me with the process. It was a big relief and their paperwork handling was fantastic. Thanks Nxcar!",
            profilePic: img3,
            profileName: " Davinder Goyal, Delhi"
        },
        {
            text: "I recently got a car loan through Nxcar for my first used car, and the experience was incredibly smooth and straightforward. Not only did they provide me with an affordable loan option at the competitive rates, but also offered a car inspection service. The entire process was hassle-free, and I'm satisfied with the services provided by Nxcar.",
            profilePic: img2,
            profileName: "Ravinder Reddy, Hyderabad "
        },

        {
            text: "I never thought that my second hand car buying experience would be this smooth. From car inspection to rc transfer to loan disbursement, Nxcar did it all.",
            profilePic: img5,
            profileName: "Arjun Verma, Delhi"
        },
    ];
    const containerRef = useRef(null);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [lineWidth, setLineWidth] = useState(300);
    const [expandedIndex, setExpandedIndex] = useState(null);

    const toggleExpand = (index) => {
        if (expandedIndex === index) {
            setExpandedIndex(null); // Close if already open
        } else {
            setExpandedIndex(index); // Open if closed
        }
    };


    const handleScroll = (scrollOffset) => {
        containerRef.current.scrollLeft += scrollOffset;
        setScrollLeft(containerRef.current.scrollLeft);
    };

    useEffect(() => {
        const container = containerRef.current;
        const updateLineWidth = () => {
            const containerWidth = container.offsetWidth;
            const scrollWidth = container.scrollWidth;
            const maxScroll = scrollWidth - containerWidth;
            const percentage = (container.scrollLeft / maxScroll) * 100;
            setLineWidth((percentage * containerWidth) / 100);
        };
        updateLineWidth();

        container.addEventListener('scroll', updateLineWidth);
        return () => {
            container.removeEventListener('scroll', updateLineWidth);
        };
    }, []);
    
    return (
        <>
            <div className={styles.container}>
                <div className={styles.heading}>
                    {/* Testimonials */}
                    Listen Direct From Our Clients
                </div>

                <div className={styles.green_container}>
                    <div className={styles.main_container} ref={containerRef}>
                        {arr.map((testimonial, index) => (
                            <div className={styles.view} key={index}>
                                {/* <div className={styles.big_inverted_commas_left}>"</div> */}
                                <div className={styles.view_text}>
                                    {expandedIndex === index ? testimonial.text : `${testimonial.text.slice(0, 150)}...`}
                                    {testimonial.text.length > 150 && (
                                        <span className={styles.read_more} onClick={() => toggleExpand(index)}>
                                            {expandedIndex === index ? ' ...Read Less' : 'Read More'}
                                        </span>
                                    )}
                                </div>
                                {/* <div className={styles.big_inverted_commas_right}>"</div> */}
                                <div className={styles.profile}>
                                    <div className={styles.profile_pic}>
                                        <img src={testimonial.profilePic} alt="" />
                                    </div>
                                    <div className={styles.profile_name}>{testimonial.profileName}</div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className={styles.scroll_line} style={{ width: lineWidth }}>
                        <div className={styles.scroll_line_label} style={{ width: lineWidth, color: "red" }}> </div>
                    </div>
                </div>
                {arr.length > 1 && (
                    <div className={styles.button}>
                        <div className={styles.left} onClick={(e) => { e.stopPropagation(); handleScroll(-420) }}>
                            <FaAngleLeft />
                        </div>

                        <div className={styles.right} onClick={(e) => { e.stopPropagation(); handleScroll(420) }}>
                            <FaAngleRight />
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default Testimonial
