import styles from './UpdatedServiceForm.module.css';
import { IoClose } from 'react-icons/io5';
import React, { useEffect, useState } from 'react'
import { FormControl, InputLabel, MenuItem, Modal, Select, makeStyles } from '@material-ui/core';
import { MdKeyboardArrowDown } from "react-icons/md";
import NewLoan from '../../../../reusable/loanEligibility/NewLoanEligibility/NewLoan';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useMediaQuery } from '@material-ui/core';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import green_tick from '../../../../images/forms/green_tick.svg'



const styleMUIModal = {
    position: "absolute",
    top: "0%",
    right: "0%",
    width: '40%',
    height: '100vh',
    bgcolor: "white",
    border: "0px solid #000",
    boxShadow: 24,
    outline: "none",
    overflowY: "auto",
    p: 0,
};
const styleMUIModalMobile = {
    width: "100%",
    height: "100vh",
    top: "0%",
    right: "0%",
    height: '100vh',
    bgcolor: "white",
    border: "0px solid #000",
    boxShadow: 24,
    outline: "none",
    overflowY: "auto",
    p: 0,
}
const useStyles = makeStyles((theme) => ({
    select: {
        color: 'black',
        outline: 'none',
        borderRadius: '8px',
        padding: '-13px',
        boxShadow: 'none',
        // backgroundColor: `white`,
        '&:focus': {
            // backgroundColor: `var(--light_tteal)`,
            color: 'white',
            border: '0',
            outline: 'none',
            '& .MuiOutlinedInput-notchedOutline': {
                border: '0',
                outline: 'none',
            },
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            backgroundColor: `transparent`,
            color: 'white',
            border: "0.01px solid var(--pale_white)",
        },

    },
    label: {
        fontSize: "18px",
        paddingLeft: "10px",
        color: `grey`,
        '&.Mui-focused': {
            // color: 'white',
        },
    },
    option: {
        // backgroundColor: `var(--dark_green)`,
        color: 'black',
        margin: '-8px 0',
        padding: '16px 18px',
        width: '100%',
        fontSize: '18px',
        '&:focus': {
            // backgroundColor: `var(--dark_green)`,
            color: 'black',
            outline: 'none',
        },
        '&:hover': {
            // backgroundColor: `var(--green)`,
            color: 'black',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            outline: 'none',
        },
    },
    selectedOption: {
        fontSize: '18px',
        paddingLeft: "10px",
        border: 'none',
        outline: 'none',
        '&:hover': {
            // backgroundColor: `var(--dark_green)`,
            color: 'white',
        },
        '&:focus': {
            // backgroundColor: `var(--dark_green)`,
            color: 'white',
            outline: 'none',
        },

    },
    arrowIcon: {
        fill: `var(--pale_white)`,
        fontSize: '30px',
        marginRight: '24px',
        background: 'transparent',
    },
    filled: {
        color: 'black',
        backgroundColor: 'var(--light_teal)',
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            outline: 'none',
        },
    },
}));

const UpdatedServiceForm = ({ handleCloseBuy }) => {

    const classes = useStyles();
    const [stage, setStage] = useState(1)
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [emptyFields, setEmptyFields] = useState([]);
    const [isFocused, setIsFocused] = useState(false);
    const [showLoanComponent, setShowLoanComponent] = useState(false);
    const [consentChecked, setConsentChecked] = useState(false);

    // const [otp, setOtp] = useState(new Array(4).fill(""));
    const [otp, setOtp] = useState('');
    const [isOtpValid, setIsOtpValid] = useState(true);
    const [isTimeExpired, setIsTimeExpired] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [invalidCoupon, setInvalidCoupon] = useState(true);

    const isMobile = useMediaQuery('(max-width: 940px)');
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    // const handleClose = () => setOpen(false);

    const url = 'https://ipapi.co/json/'
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const handleInputFocus = () => {
        setIsFocused(true);
    };

    const handleInputBlur = () => {
        setIsFocused(false);
    };

    const isValidPincode = (pincode) => {
        const pincodeRegex = /^[1-9][0-9]{5}$/;
        return pincodeRegex.test(pincode);
    };

    const [user_ip, setUser_ip] = useState('');

    const getIP = async () => {
        try {
            const res = await axios.get(url);
            const IP_Data = res.data.ip;
            setUser_ip(IP_Data);
            setFormData(prevState => ({
                ...prevState,
                ip: IP_Data
            }));
        } catch (error) {
            console.error("Error fetching IP:", error);
        }
    }
    useEffect(() => {
        getIP();
    }, [])

    let currentURL = window.location.href;
    let urlParts = currentURL.split('/');

    let pageName = urlParts[urlParts.length - 1];

    if (pageName === '') {
        pageName = 'homescreen';
    }

    const [formData, setFormData] = useState({
        // Marketing Purpose 
        ip: user_ip,
        utm_medium: "",
        utm_source: "Website",
        type: "",
        time: "",
        page: pageName,

        // Visible 
        consumer_id: '',
        consent: '0',
        full_name: '',
        mobile: '',
        email: '',
        pincode: '',
        pan: "",
        monthly_income: "",
        existing_emi: "0",
        vehicle_no: "",
        loan_amount: "",
        inspection_date: null,
        address: "",
        coupon: "",
        services: {
            loan: localStorage.getItem('used_car_loan'),
            inspection: localStorage.getItem('inspection'),
            docs_verification: localStorage.getItem('document_verification'),
            Extended_warranty: localStorage.getItem('extended_warranty'),
            insurence_transfer: localStorage.getItem('insurance_transfer'),
            rc_transfer: localStorage.getItem('rc_transfer'),
            car_insurance: localStorage.getItem('car_insurance')
        },
    });
    const validateStage1 = () => {
        return (
            formData.full_name !== '' &&
            formData.email !== '' &&
            formData.mobile !== '' &&
            formData.consent == 1
        );
    };


    const handleNextStage = async (e) => {
        e.preventDefault();
        const emptyFields = [];

        if (stage === 1 && !validateStage1()) {
            emptyFields.push("full_name", "mobile", "email", "consent");
        }

        setEmptyFields(emptyFields);

        if (emptyFields.length === 0) {
            try {
                const result = await axios.post(`/leads`, formData);
                // console.log(result);
                localStorage.setItem('OTP_login_id', result.data.login_id)
                setStage(stage + 1);
            } catch (error) {
                console.log('Error in handleSubmit', error);
            }
        }
    };

    const resendOTP = async () => {
        try {
            const response = await axios.post(`/resend_otp`, {
                mobile: formData.mobile,
                signature: "Team Nxcar",
                login_id: localStorage.getItem('OTP_login_id')
            });
            if (response.status === 200) {
                toast.dismiss();
                toast.success('OTP sent');
            } else {
                toast.dismiss();
                toast.error('error OTP sent');
                setIsValid(false);
            }
        } catch (error) {
            console.error('Error during login API call:', error);
        }
    };

    // OTP VERIFICATION API
    // const handleOTPChange = (element, index) => {
    //     if (isNaN(element.value)) return false;

    //     setOtp(otp);

    //     // Focus next input
    //     if (element.nextSibling) {
    //         element.nextSibling.focus();
    //     }
    // };

    const handleOTPChange = (e) => {
        const newValue = e.target.value;
        setOtp(newValue);
    }

    const handleOTPVerification = async (e) => {
        e.preventDefault()
        try {
            const response = await axios.post(`/otpverify`, {
                mobile: formData.mobile,
                otp: otp,
                login_id: localStorage.getItem('OTP_login_id')
            });
            if (response.data.status === true) {
                setIsFormSubmitted(true);
                window.location.href = '/nxcar-journey';
            } else {
                toast.error('Wrong OTP');
                setIsOtpValid(false);
            }
        } catch (error) {
            // console.log('hi2000', error.message)
            toast.error('Wrong OTP');
            setIsOtpValid(false);
            setOtp('');
        }
    };

    const validCoupons = new Set([
        "EXTW2024",
        "EXTWSHIV",
        "INSUR2024",
        "EXTWNAME",
        "EXTWNIRBHAY",
        "EXTWNAMASTE"
    ]);

    useEffect(() => {
        const couponValue = formData.coupon.toUpperCase();
        if (couponValue && !validCoupons.has(couponValue)) {
            setInvalidCoupon(true);
        } else {
            setInvalidCoupon(false);
        }
    }, [formData.coupon]);

    const handleInputChange = (e, inputName) => {
        let newValue;
        if (e.$d) {
            newValue = e.$d;
        } else {
            newValue = e.target.value;
        }

        setFormData((prevFormData) => ({
            ...prevFormData,
            [inputName]: newValue,
        }));
    };

    const handleClose = () => {
        setShowLoanComponent(false);
    };

    const handleCheckBox = (e) => {
        const checked = e.target.checked;
        setConsentChecked(checked);
        setFormData((prevData) => ({
            ...prevData,
            consent: checked ? "1" : "0",
        }));
    }

    const my_loan = localStorage.getItem('used_car_loan');
    const my_rc_transfer = localStorage.getItem('rc_transfer');
    const my_docs_verification = localStorage.getItem('document_verification');

    const my_extended_warranty = localStorage.getItem('extended_warranty');
    const my_insurance_transfer = localStorage.getItem('insurance_transfer');
    const my_inspection = localStorage.getItem('inspection');
    const car_insurance = localStorage.getItem('car_insurance');


    const currentDate = dayjs();
    const minDate = currentDate.add(1, 'day');

    if (stage === 1) {
        window.history.pushState(null, null, '/nxcarpackage')
    } else if (stage === 2) {
        window.history.pushState(null, null, '/nxcarpackage/otp/verify')
    }


    return (
        <>
            {showLoanComponent ? (
                <NewLoan handleClose={handleClose} />
            ) : (
                <div className={styles.container}>
                    {
                        isFormSubmitted ? (
                            <>
                                {/* <div className={styles.thank_you_image}>
                                    <div className={styles.modal_image}>
                                        <img src={notice} alt="thank_you" />
                                    </div>
                                    <div
                                        onClick={handleCloseBuy}
                                        className={styles.mui_close_button}
                                    >
                                        <IoClose />
                                    </div>
                                </div> */}
                            </>
                        ) : (
                            <div className={styles.form_stage}>
                                <form onSubmit={handleOTPVerification}>
                                    {stage === 1 && (
                                        <>
                                            {/* WELCOME TEXT */}

                                            <div className={styles.heading_section}>
                                                <div className={styles.welcome_text}>
                                                    <div className={styles.welcome_text1}>
                                                        Please share details
                                                    </div>
                                                    <div className={styles.welcome_text2}>
                                                        Fill out your details
                                                    </div>
                                                </div>
                                                <div
                                                    onClick={handleCloseBuy}
                                                    className={styles.mui_close_button}
                                                >
                                                    <IoClose />
                                                </div>
                                            </div>

                                            <div className={styles.form}>
                                                <div className={styles.col}>
                                                    <>
                                                        {/* FULL NAME */}
                                                        <>
                                                            <div className={styles.MUI_input}>
                                                                <input
                                                                    className={formData.full_name.length > 0 ? styles.input_field1 : styles.input_field}
                                                                    type="text"
                                                                    value={formData.full_name}
                                                                    onChange={(e) => handleInputChange(e, 'full_name')}
                                                                    required
                                                                    id='name'
                                                                    onKeyDown={(e) => {
                                                                        const keyPressed = e.key;
                                                                        const isValidInput = /^[A-Za-z\s]*$/.test(keyPressed);
                                                                        const isNavigationKey = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete', 'Backspace'].includes(keyPressed);
                                                                        const isCtrlKey = e.ctrlKey || e.metaKey;
                                                                        const isCopyPaste = isCtrlKey && ['a', 'c', 'v'].includes(keyPressed.toLowerCase());
                                                                        if (!isValidInput && !isNavigationKey && !isCopyPaste) {
                                                                            e.preventDefault();
                                                                        }
                                                                    }}
                                                                />
                                                                <div className={`${styles.float_label} ${formData.full_name && styles.labelActive}`}>
                                                                    Enter Full Name <span style={{ color: `var(--red)` }}>*</span>
                                                                </div>
                                                                {localStorage.setItem('buyer_name', formData.full_name)}
                                                            </div>
                                                            {emptyFields.includes("full_name") && formData.full_name === "" && (
                                                                <p className={styles.error}>Full Name is required</p>
                                                            )}
                                                        </>

                                                        {/* MOBILE NUMBER */}
                                                        <>
                                                            <div className={styles.MUI_input}>
                                                                <div className={`${styles.phoneNumberDisplay} ${formData.mobile && styles.phoneNumberActive}`}>
                                                                    +91
                                                                </div>
                                                                <input
                                                                    className={`${styles.input_field} ${formData.mobile.length > 0 ? styles.input_field2 : ''} ${isFocused ? styles.input_field2 : ''}`}
                                                                    type="tel"
                                                                    maxLength={10}
                                                                    value={formData.mobile}
                                                                    onFocus={handleInputFocus}
                                                                    onBlur={handleInputBlur}
                                                                    id='phone_number'
                                                                    style={{ padding: "12px 0px" }}
                                                                    required
                                                                    // onChange={(e) => handleInputChange(e, 'mobile')}
                                                                    onChange={(e) => {
                                                                        const inputValue = e.target.value;
                                                                        const sanitizedValue = inputValue.replace(/\D/g, '');
                                                                        handleInputChange({ target: { value: sanitizedValue } }, 'mobile');
                                                                    }}
                                                                />
                                                                <div className={`${styles.float_label} ${formData.mobile && styles.labelActive}`}>
                                                                    {isFocused ? "Enter your mobile number" : formData.mobile.length === 0 ? "+91 Enter your mobile number" : "Enter your mobile number"} <span style={{ color: `var(--red)` }}>*</span>
                                                                </div>
                                                                {localStorage.setItem('buyer_phone', formData.mobile)}
                                                            </div>
                                                            {emptyFields.includes("mobile") && (formData.mobile === 0 || formData.mobile === "") && (
                                                                <p className={styles.error}> Mobile is required</p>
                                                            )}
                                                            {formData.mobile && !/^[6789]\d{9}$/.test(formData.mobile) && (
                                                                <p className={styles.error}>
                                                                    Please provide an Indian number
                                                                </p>
                                                            )}
                                                        </>

                                                        {/* EMAIL..  */}
                                                        <>
                                                            <div className={styles.MUI_input}>
                                                                <input
                                                                    className={formData.email.length > 0 ? styles.input_field1 : styles.input_field}
                                                                    type="email"
                                                                    value={formData.email}
                                                                    onChange={(e) => handleInputChange(e, 'email')}
                                                                    id='email'
                                                                    required
                                                                />
                                                                <div className={`${styles.float_label} ${formData.email && styles.labelActive}`}>
                                                                    Enter Email<span style={{ color: `var(--red)` }}> *</span>
                                                                </div>
                                                            </div>
                                                            {emptyFields.includes("email") && formData.email === "" && (
                                                                <p className={styles.error}>Email is required</p>
                                                            )}
                                                            {formData.email && !(emailRegex).test(formData.email) && (
                                                                <p className={styles.error}>
                                                                    Please provide a valid email
                                                                </p>
                                                            )}
                                                        </>

                                                        {/* VEHICLE NUMBER */}
                                                        <>
                                                            <div className={styles.MUI_input}>
                                                                <input
                                                                    className={formData.vehicle_no.length > 0 ? styles.input_field1 : styles.input_field}
                                                                    type="text"
                                                                    value={formData.vehicle_no}
                                                                    onChange={(e) => handleInputChange(e, 'vehicle_no')}
                                                                    pattern='^[A-Z]{2}[0-9]{1,2}(?:[A-Z])?(?:[A-Z]*)?[0-9]{4}$'
                                                                    maxLength={10}
                                                                    title='FORMAT DL AE 01 2321'
                                                                    onInput={(e) => {
                                                                        e.target.value = e.target.value.toUpperCase();
                                                                    }}
                                                                    required
                                                                />
                                                                <div className={`${styles.float_label} ${formData.vehicle_no && styles.labelActive}`}>
                                                                    Enter Vehicle Number (If Available)<span style={{ color: `var(--red)` }}></span>
                                                                </div>
                                                            </div>
                                                            {/* {emptyFields.includes("vehicle_no") && formData.vehicle_no === "" && (
                                                                <p className={styles.error}>Vehicle Number is required</p>
                                                            )} */}
                                                            {formData.vehicle_no && !/^[A-Z]{2}[0-9]{1,2}(?:[A-Z])?(?:[A-Z]*)?[0-9]{4}$/.test(formData.vehicle_no) && (
                                                                <p className={styles.error}>
                                                                    Please provide a valid Indian vehicle number
                                                                </p>
                                                            )}
                                                        </>

                                                        <>
                                                            {
                                                                my_loan === 'true' ? (
                                                                    <>
                                                                        {/* LOAN AMOUNT  */}
                                                                        <>
                                                                            <div className={styles.MUI_input}>
                                                                                <input
                                                                                    className={formData.loan_amount.length > 0 ? styles.input_field1 : styles.input_field}
                                                                                    type="tel"
                                                                                    maxLength={10}
                                                                                    value={formData.loan_amount}
                                                                                    id='loan_amount'
                                                                                    required
                                                                                    // onChange={(e) => handleInputChange(e, 'loan_amount')}
                                                                                    onChange={(e) => {
                                                                                        const inputValue = e.target.value;
                                                                                        const sanitizedValue = inputValue.replace(/\D/g, '');
                                                                                        handleInputChange({ target: { value: sanitizedValue } }, 'loan_amount');
                                                                                    }}
                                                                                />
                                                                                <div className={`${styles.float_label} ${formData.loan_amount && styles.labelActive}`}>
                                                                                    Enter Loan Amount <span style={{ color: `var(--red)` }}></span>
                                                                                </div>
                                                                            </div>
                                                                            {emptyFields.includes("loan_amount") && formData.loan_amount === "" && (
                                                                                <p className={styles.error}>Enter Loan is required</p>
                                                                            )}
                                                                        </>

                                                                        {/* PAN  */}
                                                                        <>
                                                                            <div className={styles.MUI_input}>
                                                                                <input
                                                                                    className={formData.pan.length > 0 ? styles.input_field1 : styles.input_field}
                                                                                    type="text"
                                                                                    maxLength={10}
                                                                                    value={formData.pan.toUpperCase()}
                                                                                    onChange={(e) => handleInputChange(e, 'pan')}
                                                                                    required
                                                                                // onChange={(e) => {
                                                                                //     const inputValue = e.target.value;
                                                                                //     const sanitizedValue = inputValue.replace(/\D/g, '');
                                                                                //     handleInputChange({ target: { value: sanitizedValue } }, 'pan');
                                                                                // }}
                                                                                />
                                                                                <div className={`${styles.float_label} ${formData.pan && styles.labelActive}`}>
                                                                                    Enter PAN <span style={{ color: "#CD1C1C" }}></span>
                                                                                </div>
                                                                            </div>
                                                                            <div className={styles.new_tagline}>
                                                                                Required for pre approved Loan offers
                                                                            </div>
                                                                        </>

                                                                        {/* INCOME */}
                                                                        <>
                                                                            <div className={styles.MUI_input}>
                                                                                <input
                                                                                    className={formData.monthly_income.length > 0 ? styles.input_field1 : styles.input_field}
                                                                                    type="tel"
                                                                                    maxLength={15}
                                                                                    value={formData.monthly_income}
                                                                                    required
                                                                                    // onChange={(e) => handleInputChange(e, 'monthly_income')}
                                                                                    onChange={(e) => {
                                                                                        const inputValue = e.target.value;
                                                                                        const sanitizedValue = inputValue.replace(/\D/g, '');
                                                                                        handleInputChange({ target: { value: sanitizedValue } }, 'monthly_income');
                                                                                    }}
                                                                                />
                                                                                <div className={`${styles.float_label} ${formData.monthly_income && styles.labelActive}`}>
                                                                                    Enter Monthly Income<span style={{ color: "#CD1C1C" }}></span>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                style={{ paddingBottom: '12px' }}
                                                                                className={styles.new_tagline}>
                                                                                Required for pre approved Loan offers
                                                                            </div>
                                                                        </>

                                                                        {/* EMI */}
                                                                        {/* <>
                                                                            <div className={styles.MUI_input}>
                                                                                <input
                                                                                    className={formData.existing_emi.length > 0 ? styles.input_field1 : styles.input_field}
                                                                                    type="tel"
                                                                                    maxLength={10}
                                                                                    value={formData.existing_emi}
                                                                                    onChange={(e) => handleInputChange(e, 'existing_emi')}
                                                                                    required
                                                                                />
                                                                                <div className={`${styles.float_label} ${formData.existing_emi && styles.labelActive}`}>
                                                                                    Enter Existing EMI <span style={{ color: "#CD1C1C" }}></span>
                                                                                </div>
                                                                            </div>
                                                                            <div className={styles.new_tagline}>
                                                                                Required for pre approved Loan offers
                                                                            </div>
                                                                        </> */}
                                                                    </>
                                                                ) : null
                                                            }
                                                        </>

                                                        {/* CALENDER */}
                                                        <div className={styles.time_flex_box}>
                                                            {
                                                                (my_extended_warranty === 'true'
                                                                    || my_inspection === 'true'
                                                                    || car_insurance === 'true'
                                                                    || my_insurance_transfer === 'true') ? (
                                                                    <>
                                                                        <div className={styles.MUI_input}>
                                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                <DatePicker
                                                                                    label={<div
                                                                                        style={{
                                                                                            fontSize: '18px',
                                                                                            fontWeight: '400',
                                                                                            width: `100%`,
                                                                                            borderRadius: '20px',
                                                                                            color: `var(--more_grey)`
                                                                                        }}>
                                                                                        Choose Date
                                                                                    </div>}
                                                                                    style={{ borderRadius: '20px' }}
                                                                                    sx={window.innerWidth <= 940 ? { width: '100%' } : { width: '90%', marginRight: '12px' }}
                                                                                    minDate={minDate}
                                                                                    value={formData.inspection_date}
                                                                                    onChange={(e) => handleInputChange(e, 'inspection_date')}
                                                                                    slotProps={{
                                                                                        textField: {
                                                                                            error: false,
                                                                                        },
                                                                                    }}
                                                                                />
                                                                            </LocalizationProvider>
                                                                        </div>

                                                                        <div className={styles.MUI_input}>
                                                                            <div className={styles.option_container}>
                                                                                <FormControl
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                >
                                                                                    <InputLabel
                                                                                        className={classes.label}
                                                                                        id="demo-simple-select-outlined-label"
                                                                                    >
                                                                                        Choose Time <span style={{ color: `var(--red)` }}></span>
                                                                                    </InputLabel>
                                                                                    <Select
                                                                                        required
                                                                                        labelId="demo-simple-select-outlined-label"
                                                                                        id="demo-simple-select-outlined"
                                                                                        value={formData.time}
                                                                                        onChange={(e) => handleInputChange(e, 'time')}
                                                                                        label="Choose  Time Tim"
                                                                                        renderValue={(selected) => <span className={classes.selectedOption}>{selected}</span>}
                                                                                        IconComponent={() => (
                                                                                            <MdKeyboardArrowDown className={classes.arrowIcon} />
                                                                                        )}
                                                                                        className={`${classes.select} ${formData.time.length > 0 ? classes.filled : ''}`}
                                                                                        MenuProps={{
                                                                                            anchorOrigin: {
                                                                                                vertical: 'bottom',
                                                                                                horizontal: 'left',
                                                                                            },
                                                                                            transformOrigin: {
                                                                                                vertical: 'top',
                                                                                                horizontal: 'left',
                                                                                            },
                                                                                            getContentAnchorEl: null,
                                                                                            autowidth: "false",
                                                                                        }}
                                                                                        menulistprops={{
                                                                                            autoFocus: true,
                                                                                        }}
                                                                                    // onFocus={() => setIsFocused(true)}
                                                                                    // onBlur={() => setIsFocused(false)}
                                                                                    >
                                                                                        <MenuItem
                                                                                            value="Morning"
                                                                                            className={`${classes.option}`}>
                                                                                            Morning
                                                                                        </MenuItem>
                                                                                        <MenuItem
                                                                                            value="Evening"
                                                                                            className={`${classes.option}`}>
                                                                                            Evening
                                                                                        </MenuItem>
                                                                                    </Select>
                                                                                </FormControl>
                                                                            </div>
                                                                        </div>
                                                                        {emptyFields.includes("time") && formData.time === "" && (
                                                                            <p className={styles.error}>Time is required</p>
                                                                        )}
                                                                    </>
                                                                ) : null
                                                            }
                                                        </div>


                                                        {/* PINCODE */}
                                                        <>
                                                            <div className={styles.MUI_input}>
                                                                <input
                                                                    className={formData.pincode.length > 0 ? styles.input_field1 : styles.input_field}
                                                                    type="tel"
                                                                    maxLength={6}
                                                                    minLength={6}
                                                                    value={formData.pincode}
                                                                    // id='pin'
                                                                    required
                                                                    // onChange={(e) => handleInputChange(e, 'pincode')}
                                                                    onChange={(e) => {
                                                                        const inputValue = e.target.value;
                                                                        const sanitizedValue = inputValue.replace(/\D/g, '');
                                                                        handleInputChange({ target: { value: sanitizedValue } }, 'pincode');
                                                                    }}
                                                                />
                                                                <div className={`${styles.float_label} ${formData.pincode && styles.labelActive}`}
                                                                >
                                                                    Enter PIN Code <span style={{ color: `var(--red)` }}></span>
                                                                </div>
                                                            </div>
                                                            {/* {emptyFields.includes("pincode") && formData.pincode === "" && (
                                                                <p className={styles.error}>Pincode is required</p>
                                                            )} */}
                                                            <div className={styles.new_tagline}>
                                                                For Vehicle Inspection
                                                            </div>
                                                            {/* {!isValidPincode(formData.pincode) && (
                                                                <p className={styles.error}>Invalid PIN code</p>
                                                            )} */}
                                                        </>

                                                        {/* COUPON CODE */}
                                                        <>
                                                            {
                                                                (my_loan === 'true' ||
                                                                    my_extended_warranty === 'true') ? (
                                                                    <>
                                                                        <div className={styles.MUI_input}>
                                                                            <input
                                                                                style={{
                                                                                    borderRadius: invalidCoupon ? '4px' : '4px 0 0 4px',
                                                                                    borderRight: invalidCoupon ? '1px solid transparent' : 'none',
                                                                                }}
                                                                                className={formData.coupon.length > 0 ? styles.input_field1 : styles.input_field}
                                                                                type="text"
                                                                                maxLength={15}
                                                                                minLength={5}
                                                                                value={formData.coupon.toUpperCase()}
                                                                                required
                                                                                onChange={(e) => handleInputChange(e, 'coupon')}
                                                                            />
                                                                            {
                                                                                formData.coupon.length > 0 ? (<>
                                                                                    {
                                                                                        invalidCoupon ? (
                                                                                            <div className={styles.invalid_emoji}>
                                                                                                !
                                                                                            </div>
                                                                                        ) : (
                                                                                            <div className={styles.valid_emoji}>
                                                                                                <img src={green_tick} alt="green_tick" />
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                </>) : (null)
                                                                            }
                                                                            <div className={`${styles.float_label} ${formData.coupon && styles.labelActive}`}
                                                                            >
                                                                                Enter Coupon Code
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            formData.coupon.length > 1 ?
                                                                                (
                                                                                    <>
                                                                                        {
                                                                                            invalidCoupon ? (
                                                                                                <div
                                                                                                    style={{ color: `var(--red)` }}
                                                                                                    className={styles.new_tagline}>
                                                                                                    Invalid  Coupon Code
                                                                                                </div>
                                                                                            ) : (
                                                                                                <div className={styles.new_tagline}>
                                                                                                    You Will Get Free Extended Warranty Worth ₹5999.
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </>
                                                                                ) : (null)
                                                                        }
                                                                    </>
                                                                ) : (null)
                                                            }
                                                        </>
                                                    </>


                                                    <div className={styles.consent_box}>
                                                        <div className={styles.consent_left}>
                                                            <input
                                                                type="checkbox"
                                                                required
                                                                onClick={handleCheckBox}
                                                            />
                                                        </div>
                                                        {
                                                            my_loan === 'true' ?
                                                                <>
                                                                    <div className={styles.consent_right}>
                                                                        I agree that I have read, understood, and accepted the <span style={{ color: 'teal', textDecoration: 'underline' }}> <a href="https://nxcar.in/privacy-policy" target='_blank'>Privacy Policy</a>, <a href="https://nxcar.in/terms-of-use" target="_blank"> & Terms of Use</a> and Consent Declaration</span> and hereby appoint Nxcar as my authorized representative to receive my credit information from any certified Credit Information Companies
                                                                    </div>
                                                                </> :
                                                                <>
                                                                    <div className={styles.consent_right}>
                                                                        I agree that I have read, understood, and accepted the <span style={{ color: 'teal', textDecoration: 'underline' }}> <a href="https://nxcar.in/privacy-policy" target='_blank'>Privacy Policy</a>, <a href="https://nxcar.in/terms-of-use" target="_blank"> & Terms of Use</a></span>  and hereby authorize Nxcar to contact me for further process.
                                                                    </div>
                                                                </>
                                                        }

                                                    </div>
                                                    {emptyFields.includes("consent") && formData.consent === '0' && (
                                                        <p className={styles.error} style={{
                                                            marginTop: "5px",
                                                            marginLeft: "25px",
                                                            fontWeight: '500'
                                                        }}>Consent is required</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={styles.final_button_container1}>
                                                <button
                                                    className={styles.submit_button1}
                                                    onClick={handleNextStage}
                                                >
                                                    Next
                                                </button>
                                            </div>
                                        </>
                                    )}

                                    {stage === 2 && (
                                        <div>
                                            <div className={styles.heading_section}>
                                                <div className={styles.welcome_text}>
                                                    <div className={styles.welcome_text1}>
                                                        Verify your OTP
                                                    </div>
                                                    <div className={styles.welcome_text2}>
                                                        OTP has been sent to your number
                                                    </div>
                                                </div>
                                                <div
                                                    onClick={handleCloseBuy}
                                                    className={styles.mui_close_button}
                                                >
                                                    <IoClose />
                                                </div>
                                            </div>
                                            {/* <div className={styles.otp_fields}>
                                                <OtpInput
                                                    className={`otp-field ${!isOtpValid ? styles.error_message_otp : ''}`}
                                                    type="tel"
                                                    name="otp"
                                                    required
                                                    value={otp}
                                                    onChange={setOtp}
                                                    numInputs={4}
                                                    renderSeparator={<span></span>}
                                                    renderInput={(props) => <input {...props} />}
                                                />
                                            </div> */}
                                            <div className={styles.new_otp}>
                                                <input
                                                    placeholder='● ● ● ●'
                                                    required
                                                    maxLength={4}
                                                    value={otp}
                                                    onChange={handleOTPChange}
                                                    type="tel" />
                                            </div>
                                            <div className={styles.otp_will_expire}>
                                                {!isOtpValid && (
                                                    <div className={styles.error_message_otp}>
                                                        {/* {otpErrorMessage} */}
                                                    </div>
                                                )}
                                                <>
                                                    <button
                                                        className={styles.resend_button}
                                                        onClick={resendOTP}>
                                                        Resend OTP
                                                    </button>
                                                </>
                                            </div>

                                            {isOtpValid ? (
                                                <button
                                                    type="submit"
                                                    className={styles.submit_button1}
                                                >
                                                    Submit
                                                </button>
                                            ) : (
                                                <>
                                                    <button
                                                        className={styles.submit_button1}
                                                        type='submit'
                                                    >
                                                        Submit
                                                    </button>
                                                    <span style={{ color: "red", margin: "10px 0" }}>
                                                        Wrong OTP
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    )}

                                </form>
                            </div>
                        )
                    }
                </div>
            )}

        </>
    )
}

export default UpdatedServiceForm