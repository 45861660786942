import React from 'react'
import Header from '../../../components/Header/Header'
import Footer from '../../../components/Footer/Footer'
import styles from './Grievance.module.css';


const Grievance = () => {
    return (
        <>
            <div className={styles.container1}>
                <Header />
                <div className={styles.dark}>
                    <div className={styles.container}>
                        <div className={styles.main_heading}>
                            GRIEVANCE REDRESSAL POLICY
                        </div>

                        {/* 1 */}
                        <div className={styles.heading}>
                            1. Objective
                        </div>
                        <div className={styles.points_container}>
                            <div className={styles.points}>
                                As a Fintech organisation, Nxcar partners with different NBFCs to provide credit facilities and lending solutions to its customers.
                            </div>
                            <div className={styles.points}>
                                Our primary responsibility is to focus on Customer service and satisfaction. This document details the policy of redressal of customer complaints. The key objective of this policy is to ensure the following:
                            </div>
                            <div className={styles.sub_points}>
                                1. All concerns/complaints raised by Customers are resolved in effective and timely manner, leading to their satisfaction
                            </div>
                            <div className={styles.sub_points}>
                                2. Through Customers’ feedback, we are able to improve our processes and products
                            </div>
                            <div className={styles.points}>
                                This document describes the process and policy of Nxcar to handle Customer complaints. It describes various channels for lodging the complaint, obtaining solutions from the concerned department, and the committed time-period for resolution.
                            </div>
                        </div>

                        {/* 2 */}
                        <div className={styles.heading}>
                            2. Complaint
                        </div>
                        <div className={styles.points_container}>
                            <div className={styles.points}>
                                Complaint is an expression of dissatisfaction or resentment either in the form of a representation made in writing or through electronic means or over phone, containing a grievance alleging deficiency in:
                            </div>
                            <div className={styles.sub_points}>
                                <ul>
                                    <li>
                                        services of Nxcar
                                    </li>
                                    <li>
                                        Maintaining confidentiality/ protection of customer’s personal (including sensitive personal information) and financial information,
                                    </li>
                                    <li>
                                        Perceived anomalies in the data as reported to the Credit Bureaus, partner NBFC and other Govt agencies etc. as required by the Regulator
                                    </li>
                                </ul>
                            </div>
                            <div className={styles.points}>
                                Complaint is not a request for data modification or inquiry about loan products/ schemes, interest rates or other requests which can be solved by Customer care.
                            </div>
                            <div className={styles.points}>
                                Complaints Review Committee (comprising of representatives from all key departments including customer experience) review the complaints on a periodical basis. They shall review the process and suggest changes, if any, required for making this process more effective. There is a complaints MIS and reporting in place. This reporting serves as an input for other analysis, for periodical review.
                            </div>
                        </div>

                        {/* 3 */}
                        <div className={styles.heading}>
                            3. Customers
                        </div>
                        <div className={styles.points_container}>
                            <div className={styles.points}>
                                Customer means the person who on the basis of information submitted online on our Digital Platform, Nxcar, has availed a Credit Facility of a certain amount.
                            </div>
                        </div>

                        {/* 4 */}
                        <div className={styles.heading}>
                            4. Procedure for raising a complaint
                        </div>
                        <div className={styles.points_container}>
                            <div className={styles.points}>
                                1. Complaints may be sent in the below mentioned format
                            </div>
                            <div className={styles.sub_points}>
                                <ul>
                                    <li>Calling on number: +91 97112 02816</li>
                                </ul>
                                <ul>
                                    <li>Contact hours: Monday to Saturday – 10:00 AM to 6:00 PM</li>
                                </ul>
                                <ul>
                                    <li>In writing through Email at: contact@Nxcar.in</li>
                                </ul>
                                <ul>
                                    <li>“Contact Us” page on the website</li>
                                </ul>
                            </div>
                            <div className={styles.points}>
                                2. Complaint raising by the customer
                            </div>
                            <div className={styles.points}
                                style={{ paddingLeft: "4%" }}
                            >
                                While raising a complaint each complaint should have the following information:
                            </div>
                            <div className={styles.sub_points}
                                style={{ paddingLeft: "6%" }}
                            >
                                1. Customer’s complete name
                            </div>
                            <div className={styles.sub_points}
                                style={{ paddingLeft: "6%" }}
                            >
                                2. Customer’s complete correspondence address
                            </div>
                            <div className={styles.sub_points}
                                style={{ paddingLeft: "6%" }}
                            >
                                3. Loan agreement number
                            </div>
                            <div className={styles.sub_points}
                                style={{ paddingLeft: "6%" }}
                            >
                                4. Phone contact number
                            </div>
                            <div className={styles.sub_points}
                                style={{ paddingLeft: "6%" }}
                            >
                                5. e-mail address (if any)
                            </div>
                            <div className={styles.sub_points}
                                style={{ paddingLeft: "6%" }}
                            >
                                6. Details of the complaint
                            </div>
                        </div>

                        {/* 5 */}
                        <div className={styles.heading}>
                            5. Procedure for addressing a complaint
                        </div>
                        <div className={styles.points_container}>
                            <div className={styles.points}>
                                Complaints Treatment
                            </div>
                            <div className={styles.points}>
                                First call resolution: All the complaints which can be resolved immediately when raised, the response should be provided on the same call / email and close the same. We are committed to give First call resolution within a week’s time.
                            </div>
                            <div className={styles.points}>
                                ‍Resolution post verification: For the complaints which must be verified and may need further investigation/support from other departments and hence cannot be resolved immediately, customers should be informed about the expected timelines of the closure.
                            </div>
                            <div className={styles.points}>
                                Our TAT for resolving all complaints is 30 working days. Any complaint not resolved within this timeframe will be escalated.
                            </div>
                            <div className={styles.points}>
                                Customer care department will inform the customer about the following:
                            </div>
                            <div className={styles.sub_points}>
                                Information pertaining to all issues/concerns raised by the customer,
                            </div>
                            <div className={styles.sub_points}>
                                Explanation of final solution provided,
                            </div>
                            <div className={styles.sub_points}>
                                Expected timelines towards closure (where immediate solution cannot be provided,)
                            </div>
                            <div className={styles.sub_points}>
                                Maintain contact at defined intervals/milestones to communicate progress on his concern and share reasons for delay/time taken
                            </div>
                            <div className={styles.sub_points}>
                                Request for supporting documents/information (where applicable) in a clear manner along with the reason for such requirement
                            </div>
                            <div className={styles.points}>
                                The Customer Care should make limited but reasonable attempts to reach the customer for providing solution to his/ her complaint via Phone, Email etc
                            </div>
                        </div>

                        {/* 6 */}
                        <div className={styles.heading}>
                            6. Escalation Process
                        </div>
                        <div className={styles.points_container}>
                            <div className={styles.points}>
                                <b>1. Escalation Level 1: Grievance Redressal Officer</b>
                            </div>
                            <div className={styles.points}>
                                If the customer is not satisfied with the resolution or has not received any resolution within the committed turnaround time, he/she can raise his/her concern to the “Grievance Redressal Officer” at Nxcar, by giving background and history of the issue, in the following form:
                            </div>
                            <div className={styles.points}>
                                In writing (through email) to a secure email ID: contact@Nxcar.in
                            </div>
                            <div className={styles.points}>
                                In writing (through hard copy/letter) to:
                            </div>
                            <div className={styles.points}>
                                Mani Singh
                            </div>
                            <div className={styles.points}>
                                9355924133 
                            </div>
                            <div className={styles.points}>
                                The Grievance Redressal Officer,
                            </div>
                            <div className={styles.points}>
                                Propsera Services Private Limited,
                            </div>
                            <div className={styles.points}>
                                3rd Floor Plot No 809,
                            </div>
                            <div className={styles.points}>
                                Sector 42, Golf Course Road,
                            </div>
                            <div className={styles.points}>
                                Gurgaon Haryana, India 122009
                            </div>
                            <div className={styles.points}>
                                Grievance Redressal Officer will respond within a week of receiving the complaint and provide resolution within 30 working days.
                            </div>
                            <div className={styles.points}>
                                <br /><br />
                                In order to escalate the complaint to the next level, the customer should share the ticket / complaint number. Also, the turnaround time is applicable only when the aforementioned escalation matrix is followed.
                            </div>

                            <div className={styles.points}>
                                <b>2. Resolution Turnaround Time</b>
                            </div>
                            <div className={styles.points}>
                                All the customer complaints have to be resolved within 30 days of receipt/escalation.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <Footer />
            </div>
        </>
    )
}

export default Grievance