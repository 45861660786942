import React, { useEffect } from 'react'
import styles from './Home.module.css'
import Hero from '../../../reusable/hero/Hero';
import Card from '../../../reusable/card/Card';
import Check_now from '../../../reusable/check_now/Check_now';
import General_process from '../../../reusable/general_process/General_process';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';
import NewHero from '../../../reusable/hero/HomeHero/NewHero';
import { Parallax } from 'react-parallax';
import triangle_effect from '../../../images/vectors/parallax_effect/web.svg'
import LendingPartners from '../../../reusable/lending_partners/LendingPartners';
import Process from '../../../components/homescreen_compo/process/Process';
import Servicess from '../../../components/homescreen_compo/servicess/Servicess';
import ServicesWeOffer from '../../../components/homescreen_compo/servicesWeOffer/Services';
import UpdateHomeHero from '../../../reusable/hero/UpdateHomeHero/UpdateHomeHero';
import Testimonial from '../../../reusable/testimonial/Testimonial';
import Knowledge from '../../../components/knowledge_section/Knowledge';
import ReferForm from '../../../reusable/referForm/ReferForm';
import ListingHomeScreen from '../../../components/homescreen_compo/ListingHomeScreen/ListingHomeScreen';



const Homescreen = () => {
    return (
        <>
            <Header />
            <div className={styles.container}>
                {/* <div className={styles.circles1}></div> */}
                {/* <NewHero
                    buttonText1="Buy Car"
                    buttonText2="Sell Car"
                /> */}
                {/* <Card /> */}
                {/* <Check_now /> */}
                {/* <General_process /> */}
                {/* <UpdateHomeHero /> */}

                {/* NEW BUSINESS MODAL */}
                <ListingHomeScreen />
                <Process />
                <ServicesWeOffer />
                <Servicess />
                <ReferForm />
                <Knowledge />
                <Testimonial />
                <LendingPartners />
                {/* NEW BUSINESS MODAL */}
            </div>

            <div className={styles.footer}>
                <Footer />
            </div>

        </>
    )
}

export default Homescreen