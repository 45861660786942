import React, { useState } from 'react'
import Card from '../../../components/channel_partner/card/Card';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';
import NewHero from '../../../reusable/hero/ChannelPartnerHero/NewHero';
import { Parallax } from 'react-parallax';
import triangle_effect from '../../../images/vectors/channel_partner/space_rect.svg'
import styles from './Channel_Partner.module.css';
import NewCards from '../../../components/channel_partner/new_cards/NewCards';
import Testimonial from '../../../components/channel_partner/testimonials/Testimonials';
import BubbleInfo from '../../../components/channel_partner/bubble_info/BubbleInfo';
import Nxcar_form from '../../../components/channel_partner/nxcar_form/Nxcar_form';
import ApplicationQR from '../../../components/channel_partner/ApplicationQR.js/ApplicationQR';


const Channel_partner = () => {
    const [formFocused, setFormFocused] = useState(false);

    const handleFormFocus = () => {
        setFormFocused(true);
    };

    return (
        <>
            <div className={styles.container}>
                <Header />
                <NewHero
                    single_button="Become Partner"
                    handleFormFocus={handleFormFocus}
                />
                <Nxcar_form
                    formFocused={formFocused}
                />
                <ApplicationQR />
                <NewCards />
                <BubbleInfo />
                <Testimonial />
                {/* <Card /> */}
            </div>
            <div className={styles.footer}>
                <Footer />
            </div>
        </>
    )
}

export default Channel_partner