import React from 'react'
import ConsumerMain from '../../../../Layout/Consumer/Main/ConsumerMain'
import LoanApplication2 from '../LoanApplication2/LoanApplication2'

export default function LoanApplication3() {
    return (
        <ConsumerMain>
            <LoanApplication2 />
        </ConsumerMain>
    )
}
