import React from 'react'
import styles from './ApniCarTestimonial.module.css'

// images
import avtar1 from '../../../../../images/vectors/landing/APNI_CAR/avtar1.svg'
import avtar2 from '../../../../../images/vectors/landing/APNI_CAR/avtar2.svg'
import avtar3 from '../../../../../images/vectors/landing/APNI_CAR/avtar3.svg'
import avtar4 from '../../../../../images/vectors/landing/APNI_CAR/avtar4.svg'
import lending1 from '../../../../../images/vectors/homescreen/lending_partners/lending1.svg'
import lending2 from '../../../../../images/vectors/homescreen/lending_partners/lending2.svg'
import lending3 from '../../../../../images/vectors/homescreen/lending_partners/lending3.svg'
import lending4 from '../../../../../images/vectors/homescreen/lending_partners/lending4.svg'
import lending5 from '../../../../../images/vectors/homescreen/lending_partners/lending5.svg'
import lending6 from '../../../../../images/vectors/homescreen/lending_partners/lending6.svg'
import lending7 from '../../../../../images/vectors/homescreen/lending_partners/lending7.svg'
import lending8 from '../../../../../images/vectors/homescreen/lending_partners/lending8.svg'
import lendingpartner from '../../../../../images/vectors/landing/APNI_CAR/lendingpartner.svg'

export default function ApniCarTestimonial() {
  return (
    <>
      <div className={styles.page}>
        <div className={styles.heading1}> 1000+ Cars Financed Already </div>

        <div className={styles.testimonials}>
          <div className={styles.review}>
            <div className={styles.avtar}>
              <img src={avtar1} alt="" />
            </div>
            <div className={styles.avtar_name}>Nayaz Zed</div>
            <div className={styles.avtar_review}>
              “I was looking for used car loan when I found Nxcar. Surprisingly, their process was quick and easy. I got my loan in just 3 days.”
            </div>
          </div>
          <div className={styles.review}>
            <div className={styles.avtar}>
              <img src={avtar2} alt="" />
            </div>
            <div className={styles.avtar_name}>Sukhmani Singh</div>
            <div className={styles.avtar_review}>
              “I bought my second hand creta with Nxcar fast service in all paper work. Thanks Nxcar”
            </div>
          </div>
          <div className={styles.review}>
            <div className={styles.avtar}>
              <img src={avtar3} alt="" />
            </div>
            <div className={styles.avtar_name}>Pravina Zakane</div>
            <div className={styles.avtar_review}>
              “I never thought buying a used car could be this easy. Nxcar made it possible with their loan and RC transfer services.”
            </div>
          </div>
          <div className={styles.review}>
            <div className={styles.avtar}>
              <img src={avtar4} alt="" />
            </div>
            <div className={styles.avtar_name}>Pillala Santosh</div>
            <div className={styles.avtar_review}>
              “I got my loan in just 3 days. Good experience in used car loans. The process was online and I got a good deal with free insurance.”
            </div>
          </div>
        </div>


        {/* Lending */}
        <div className={styles.web_view}>
         <img src={lendingpartner} alt="" />
        </div>

        <div className={styles.mobile_view}>
          <div className={styles.heading}> Lending Partners </div>
          <div className={styles.parnter_container}>
            <img src={lending8} alt="" />
            <img src={lending7} alt="" />
            <img src={lending6} alt="" />
            <img src={lending5} alt="" />
            <img src={lending4} alt="" />
            <img src={lending3} alt="" />
            <img src={lending2} alt="" />
            <img src={lending1} alt="" />
          </div>
        </div>
      </div>
    </>
  )
}
