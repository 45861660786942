import React, { useState } from 'react';
import styles from './Inspection.module.css';
import { Modal, Button, Box, useMediaQuery } from '@mui/material';
import { IoCloseCircle, IoCloudDownload } from "react-icons/io5";
import { LuEye } from "react-icons/lu";
import blur_inspection from '../../../../images/vectors/carscope/blur_ins.png'
import PdfViewer from '../../../pdfViewer/PdfViewer';

const styleMUIModal = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: '40%',
  height: '80%',
  border: "0px solid #000",
  boxShadow: 24,
  overflowY: "auto",
  backgroundColor: 'white',
  outline: "none",
  p: 0,
  textAlign: 'center'
};

const styleMUIModalMobile = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "80%",
  border: "0px solid #000",
  boxShadow: 24,
  outline: "none",
  overflowY: "auto",
  p: 0,
  backgroundColor: 'whitesmoke',
};

export default function Inspection({ data2 }) {
  const getDeviceType = () => {
    if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      return 'IOS';
    } else if (navigator.userAgent.match(/Android/i)) {
      return 'Android';
    } else {
      return 'Other';
    }
  };

  let deviceType = getDeviceType();

  const isMobile = useMediaQuery('(max-width: 940px)');
  const [view, setView] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = () => { setOpen(!open) }

  let isPDF;
  const mypdf = (data2?.inspectionReportName?.split('.') || data2?.inspection_report?.split('.'));

  if (mypdf && mypdf.length >= 5) {
    const isPDF1 = mypdf[4];
    const nopdf = isPDF1.split('/')[2];
    const otherpdf = nopdf.split('?')
    isPDF = otherpdf[0]?.toLowerCase().endsWith('pdf');
  }


  const handleViewReport = () => {
    setView(true);
    setOpen(true);

    if (deviceType === 'IOS' && isMobile && isPDF) {
      setTimeout(() => {
        setView(false);
        setOpen(false);
      }, 3000);
    }
  }



  return (
    <div className='no_print'>
      <div className={styles.container}>
        <div className={styles.heading}>
          Inspection Report
        </div>
        <div className={styles.blur}>
          <img src={blur_inspection} alt="my-report" />
          <div className={styles.flex1}>
            <button className={styles.button1} onClick={handleViewReport}>
              view full report <span style={{ marginRight: '10px' }}></span> <LuEye />
            </button>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={isMobile ? styleMUIModalMobile : styleMUIModal}>
          {
            view ? (
              <div className={styles.flex1}>
                {isPDF ? (
                  <>
                    <PdfViewer pdfUrl={data2.inspectionReportName || data2.inspection_report} />
                  </>
                ) : (

                  <>
                    <PdfViewer pdfUrl={data2.inspectionReportName || data2.inspection_report} />
                    {/* <img
                      className={styles.image}
                      src={data2.inspection_report || data2.inspectionReportName}
                      alt="inspection-report"
                    /> */}
                  </>
                )}
              </div>
            ) : (null)
          }
        </Box>
      </Modal>
    </div>
  )
}