import React, { useState } from 'react'
import styles from './BuySell.module.css';
import { Link } from 'react-router-dom';

import buy from '../../../images/vectors/homescreen/services/Buying.svg'
import sell from '../../../images/vectors/homescreen/services/seller.svg'

const BuySellCards = () => {
    const [formData, setFormData] = useState({
        used_car_loan: false,
        inspection: false,
        document_verification: false,
        extended_warranty: false,
        insurance_transfer: false,
        rc_transfer: false,
        car_insurance: false,
        
    })

    const single_button = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        localStorage.setItem('used_car_loan', true)
        localStorage.setItem('extended_warranty', true)
        localStorage.setItem('document_verification', true)
        localStorage.setItem('rc_transfer', true)
        localStorage.setItem('inspection', true)
        localStorage.setItem('insurance_transfer', true)
        localStorage.setItem('car_insurance', false)
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.seller_buyer}>

                    <div className={styles.buy_card}>
                        <div className={styles.car_image}>
                            <img src={buy} alt="" />
                            <div className={styles.gradient}>
                                <div className={styles.my_gradient}></div>
                            </div>
                        </div>

                        <div className={styles.buy_card_sub_heading}>
                            <ol>
                                <li>
                                    Comprehensive 250-Point Car Inspection for Total Confidence.
                                </li>
                                <li>
                                    Thorough Expert Documentation Verification
                                </li>
                                <li>
                                    Flexible Used Car Loans Tailored to You.
                                </li>
                                <li>
                                    Select from Extended Warranty Packages of 6-12 Months.
                                </li>
                                <li>
                                    Smooth Insurance and RC Transfer Process
                                </li>
                            </ol>
                        </div>
                        <Link to='/bundle' onClick={single_button}>
                            <div className={styles.CTA2}>I’m a buyer</div>
                        </Link>
                    </div>

                    <div className={styles.buy_card}>
                        <div className={styles.car_image}>
                            <img src={sell} alt="" />
                            <div className={styles.gradient}>
                                <div className={styles.my_gradient}></div>
                            </div>
                        </div>
                        <div className={styles.buy_card_sub_heading}>
                            <ol>
                                <li>
                                    Choose from over 170 dealers for selling your car.
                                </li>
                                <li>
                                    Get the best quotes for your car.
                                </li>
                                <li>
                                    Sell faster with Nxcar's network of buyers.
                                </li>
                                <li>
                                    Boost your sales potential by 2x with Nxcar's credibility.
                                </li>
                                <li>
                                    Elevate your buyer's experience with the Nxcar Advantage Package
                                </li>
                            </ol>
                        </div>
                        <Link to='/bundle' onClick={single_button}>
                            <div className={styles.CTA2}>I’m a seller</div>
                        </Link>
                    </div>

                </div>
            </div>
        </>
    )
}

export default BuySellCards