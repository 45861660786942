import { useEffect, useState } from 'react'
import { Box, Modal, useMediaQuery } from '@mui/material'
import { Link } from 'react-router-dom'
import used_car_image from '../../../images/vectors/homescreen/hero/used_car_loan.svg'
import rc_transfer_image from '../../../images/vectors/homescreen/hero/rc.svg'
import insurance_transfer_image from '../../../images/vectors/homescreen/hero/insurance.svg'
import path from '../../../images/vectors/homescreen/hero/path.svg'
import family from '../../../images/vectors/homescreen/family.jpg'
import styles from './UpdateHomeHero.module.css'
import CarLoan from './animation/CarLoan'
import Trade_meter from './animation/Trade_meter'
import EMI from './animation/EMI'
import hand_arrow from '../../../images/vectors/homescreen/arrows/simple_arrow.svg'
import CarAnime from './car_anime/CarAnime';
import EMI_circle from './animation/EMI_circle'
import PopUpConsumerForm from '../../popupConsumerForm/PopUpConsumerForm'

const styleMUIModal = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    margin: 'auto',
    width: '500px',
    height: 'fitContent',
    bgcolor: "white",
    border: "none",
    borderRadius: 2,
    boxShadow: 0,
    outline: "none",
    p: 0,
};
const styleMUIModalMobile = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '86%',
    height: 'fitContent',
    bgcolor: "white",
    border: "none",
    borderRadius: 2,
    boxShadow: 0,
    outline: "none",
    p: 0,
}

const UpdateHomeHero = () => {

    const isMobile = useMediaQuery('(max-width: 940px)');
    const [isLoanFormVisible, setIsLoanFormVisible] = useState(false);
    const popup_login = localStorage.getItem('Authorization');
    const popupClosed = localStorage.getItem('popupClosed');

    const toggleLoanForm = () => {
        setIsLoanFormVisible(!isLoanFormVisible);
    };

    useEffect(() => {
        if (popup_login?.length > 100 && popup_login !== undefined) {
            setIsLoanFormVisible(false);
        }
    }, [popup_login]);

    const currentURL = window.location.pathname;

    useEffect(() => {
        if (currentURL === '/consumer-form' || currentURL === '/consumer-login') {
            setIsLoanFormVisible(false);
        }
        else if (
            (currentURL !== '/consumer-form' || currentURL !== '/consumer-login') &&
            (popup_login == null || popup_login == undefined) &&
            popupClosed != 'true'
        ) {
            const timer = setTimeout(() => {
                setIsLoanFormVisible(true);
            }, 10000);
            return () => clearTimeout(timer);
        }
        else {
            setIsLoanFormVisible(false);
        }
    }, [currentURL]);

    useEffect(() => {
        if (currentURL === '/consumer-form' || currentURL === '/consumer-login') {
            setIsLoanFormVisible(false);
        }
    }, [currentURL, popupClosed])

    const top = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        localStorage.setItem('used_car_loan', true)
        localStorage.setItem('extended_warranty', true)
        localStorage.setItem('document_verification', true)
        localStorage.setItem('rc_transfer', true)
        localStorage.setItem('inspection', true)
        localStorage.setItem('insurance_transfer', true)
    }

    return (
        <>
            {/* WAVES */}
            <div className={styles.my_canvas}>
                <div className={styles.canvas2}>
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.left}>
                    <div className={styles.heading_part}>
                        <div className={styles.heading}>
                            Now Get Used Car Loans, Even When You Buy Directly.
                        </div>
                        <div className={styles.sub_heading}>
                            Now buy a pre-owned vehicle directly from a friend, neighbour, classified listing, or a dealer. Let Nxcar handle the loan and all transfer processes hassle-free.
                        </div>
                    </div>
                    <div className={styles.CTA_homescreen}>
                        <Link to='/bundle' onClick={top}>
                            <div className={styles.CTA}>
                                Explore Advantage Package
                            </div>
                        </Link>
                        {/* <div className={styles.CTA_image}>
                            <img src={hand_arrow} alt="" />
                        </div> */}
                    </div>
                </div>

                <div className={styles.right}>
                    {/* ANIMATION FIRST */}
                    {/* GIRL WITH FAMILY ANIMATION */}
                    {/* <div className={styles.car_loan}>
                        <CarLoan />
                    </div>
                    <img src={family} alt="" />
                    <div className={styles.green_div}></div>
                    <div className={styles.path}>
                        <img src={path} alt="" />
                    </div>
                    <div className={styles.trade_meter}>
                        <Trade_meter />
                    </div>
                    <div className={styles.emi}>
                        <EMI />
                    </div> */}
                    {/* <div className={styles.emi_circle}>
                        <EMI_circle />
                    </div> */}

                    {/* ANIMATION SECOND  */}
                    {/* NEW CAR ROAT. ANIMATION 8 MARCH 2024 */}
                    {/* <CarAnime /> */}

                    {/* <div className={styles.circle_container}>
                        <div className={styles.circleContainer}>
                            <div className={styles.circle}>
                                <div className={`${styles.card} ${styles.card1}`}> Insurance <br /> Transfer</div>
                                <div className={`${styles.card} ${styles.card2}`}> Used Car <br /> Loans</div>
                                <div className={`${styles.card} ${styles.card3}`}>Extended <br /> Warranty</div>
                                <div className={`${styles.card} ${styles.card4}`}>RC <br /> Transfer</div>
                            </div>
                        </div>
                    </div> */}


                    {/* ANIMATION THIRD */}
                    <div className={styles.family}>
                        <img src={family} alt="family-nxcar-used-car-loan" />
                    </div>
                    <div className={styles.green_div}></div>
                    <div className={styles.path}>
                        <img src={path} alt="path-to-used-car-loan" />
                    </div>

                    <div className={styles.used_car_loan}>
                        <div className={styles.car_loan_image}>
                            <img src={used_car_image} alt="used-car-loan-easy-loan" />
                        </div>
                        <div className={styles.car_loan_heading}>
                            Used Car Loan
                        </div>
                    </div>

                    <div className={styles.insurance_transfer}>
                        <div className={styles.car_loan_image}>
                            <img src={insurance_transfer_image} alt="nxcar-insurance-warranty" />
                        </div>
                        <div className={styles.car_loan_heading}>
                            Insurance Transfer &
                            Extended Warranty
                        </div>
                    </div>

                    <div className={styles.rc_transfer}>
                        <div className={styles.car_loan_image}>
                            <img src={rc_transfer_image} alt="nxcar-rc-transfer" />
                        </div>
                        <div className={styles.car_loan_heading}>
                            RC Transfer
                        </div>
                    </div>

                </div>
            </div>

            {/* MODAL */}
            {
                isLoanFormVisible && (
                    <Modal
                        open={isLoanFormVisible}
                        onClose={toggleLoanForm}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={isMobile ? styleMUIModalMobile : styleMUIModal}>
                            <PopUpConsumerForm
                                onClose={toggleLoanForm} />
                        </Box>
                    </Modal>
                )
            }
        </>
    )
}

export default UpdateHomeHero