import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SideNav from '../SideNav/SideNav';
import logo from '../../../images/logos/logo.svg';
import styles from './ConsumerMain.module.css';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';


const ConsumerMain = ({ children }) => {
    const token = localStorage.getItem('Authorization');
    // const [isValidToken, setIsValidToken] = useState(true);


    // const validToken = async () => {
    //     try {
    //         const response = await axios.get('/', {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 Authorization: `${token}`,
    //             },
    //         });
    //         if (response.data.status === 'active') {
    //             setIsValidToken(true);
    //         } else {
    //             setIsValidToken(false);
    //         }
    //     } catch (error) {
    //         alert(error.message);
    //         Swal.fire({
    //             title: "Token Is Not Valid!",
    //             text: "Something went wrong",
    //             icon: "error"
    //         });
    //         setIsValidToken(false);
    //     }
    // }

    // useEffect(() => {
    //     validToken();
    // }, [token])


    // COMMENTING ONLY FOR TESTING ONLY
    // if (!token) {
    //     return (
    //         <div className={styles.loginContainer}>
    //             <div className={styles.logo_Nxcar}>
    //                 <img src={logo} alt="" />
    //             </div>
    //             <span>
    //                 Please
    //                 <Link to='/'>
    //                     <span
    //                         style={{
    //                             color: " #4AA09B",
    //                             fontWeight: "bold"
    //                         }}>
    //                         Consumer Login
    //                     </span>
    //                 </Link>
    //             </span>
    //         </div>
    //     );
    // }

    return (
        <>
            {!token ? (
                <>
                    <div className={styles.no_token_container}>
                        <div className={styles.no_token}>
                            Plese Login with right credentials
                        </div>
                        <Link to='https://nxcar.in/ ' className={styles.link}>
                            LOGIN
                        </Link>
                    </div>
                </>
            ) : (
                <>
                    <div className={styles.container}>
                        <div className={styles.left}>
                            <SideNav />
                        </div>
                        <div className={styles.right}>
                            {children}
                        </div>
                    </div>
                </>
            )}

        </>
    );
};

export default ConsumerMain;
