import styles from './TechDriven.module.css';
import React, { useRef, useEffect } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
// import car_4 from '../../../images/vectors/about/tech-driven/car_4.svg';
import CarAnimation from './animation/CarAnimation';


const TechDriven = () => {
    const containerRef = useRef(null);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        const containers = containerRef.current.querySelectorAll(`.${styles.text_container}`);

        containers.forEach((container, index) => {
            if (window.innerWidth < 768) {
                gsap.from(container, {
                    opacity: 0,
                    y: 100,
                    // duration: 0.5,
                    duration: 1,
                    scrollTrigger: {
                        trigger: container,
                        start: () => `top+=${index * 100}% center`,
                        end: () => `top+=${(index) * 100}% center`,
                        toggleActions: 'play none none reverse',
                        scrub: true,
                    },
                });
            } else {
                gsap.from(container, {
                    opacity: 0,
                    y: 100,
                    duration: 0.5,
                    scrollTrigger: {
                        trigger: container,
                        start: () => `top+=${index * 100} center`,
                        end: () => `top+=${(index) * 100} center`,
                        toggleActions: 'play none none reverse',
                        scrub: true,
                    },
                });
            }
        });

        // Animation for the image
        // const image = containerRef.current.querySelector(`.${styles.car_image1}`);
        // gsap.from(image, {
        //     opacity: 0,
        //     duration: 0.5,
        //     scrollTrigger: {
        //         trigger: containerRef.current,
        //         start: 'top 80%',
        //         end: 'bottom 20%',
        //         toggleActions: 'play none none reverse',
        //     },
        // });

    }, []);

    return (
        <>
            <div className={styles.container} ref={containerRef}>
                <div className={styles.left}>
                    <CarAnimation />
                </div>
                <div className={styles.right}>
                    <div className={styles.heading}>Tech-Driven Loan Experience</div>
                    <div className={styles.text_container}>
                        <div className={styles.text_left}>
                            Dealer
                            Dashboard
                        </div>
                        <div className={styles.text_right}>
                            <div className={styles.text_right_single}>
                                Ensuring the smoothest loan experience while safeguarding your information privacy.
                            </div>
                        </div>
                    </div>
                    <div className={styles.text_container}>
                        <div className={styles.text_left}>
                            Mobile App for
                            Dealers
                        </div>
                        <div className={styles.text_right}>
                            <div className={styles.text_right_single}>
                                Empowering our dealers with a user-friendly app for seamless transactions and extended services.
                            </div>
                        </div>
                    </div>
                    <div className={styles.text_container}>
                        <div className={styles.text_left}>
                            EMI Calculators
                        </div>
                        <div className={styles.text_right}>

                            <div className={styles.text_right_single}>
                                Effortlessly estimate your EMIs with our calculators for accurate loan projections.
                            </div>
                        </div>
                    </div>
                    <div className={styles.text_container}>
                        <div className={styles.text_left}>
                            Digital Loan
                            Application
                        </div>
                        <div className={styles.text_right}>
                            <div className={styles.text_right_single}>
                                Experience convenience with our fully online loan application process.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TechDriven