import React from 'react'
import styles from './AppSidePolicy.module.css';

const AppSidePolicy = () => {
    return (
        <div className={styles.dark}>
            <div className={styles.container}>
                {/* STARTING PRIVACY policy */}
                <>
                    <div className={styles.main_heading}>
                        PRIVACY POLICY
                    </div>
                    <div className={styles.text1}>
                        This privacy policy for Nxcar Partners (‘Nxcar’, ‘NXFIN TECHNOLOGIES PRIVATE LIMITED’, 'we', 'us', or 'our' ), describes how and why we might collect, store, use, and/or share ( 'process') your information when you use our services ( 'Services'), such as when you:
                    </div>
                    <div className={styles.sub_points}>
                        <ul>
                            <li>
                                Download and use our mobile application (Nxcar Partners), or any other application of ours that links to this privacy policy
                            </li>
                            <li>
                                Engage with us in other related ways, including any sales, marketing, or events
                            </li>
                        </ul>
                    </div>
                    <div className={styles.text1}>
                        <b>Questions or concerns?</b>  Reading this privacy policy will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services.
                    </div>
                </>

                {/* SUMMARY */}
                <>
                    <div className={styles.heading}>
                        SUMMARY OF KEY POINTS
                    </div>
                    <div className={styles.text1}>
                        This summary provides key points from our privacy policy, but you can find out more details about any of these topics by using our Table of contents below to find the section you are looking for.
                    </div>
                    <div className={styles.text1}>
                        <b> What personal information do we process?</b> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about the <b>personal information you disclose to us.</b>
                    </div>
                    <div className={styles.text1}>
                        <b> Do we process any sensitive personal information?</b> We may process sensitive personal information when necessary with your consent or as otherwise permitted by applicable law. Learn more about the <b>sensitive information we process.</b>
                    </div>
                    <div className={styles.text1}>
                        <b> Do we receive any information from third parties?</b> We may receive information from public databases, marketing partners, social media platforms, and other outside sources. Learn more about <b>information collected from other sources.</b>
                    </div>
                    <div className={styles.text1}>
                        <b>How do we process your information?</b> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about <b>how we process your information.</b>
                    </div>
                    <div className={styles.text1}>
                        <b>In what situations and with which types of parties do we share personal information?</b> We may share information in specific situations and with specific categories of third parties. Learn more about when and with whom we share your personal information.
                        How do we keep your information safe? We have organisational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about <b>how we keep your information safe.</b>
                    </div>
                    <div className={styles.text1}>
                        <b> What are your rights?</b> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about <b>your privacy rights.</b>
                    </div>
                    <div className={styles.text1}>
                        <b> How do you exercise your rights?</b> The easiest way to exercise your rights is by submitting a data subject access request, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.
                    </div>
                    <div className={styles.text1}>
                        Want to learn more about what we do with any information we collect? Review the privacy policy in full.
                    </div>
                </>

                {/* TABLE OF CONTENTS */}
                <>
                    <div className={styles.heading}>
                        TABLE OF CONTENTS
                    </div>
                    <div className={styles.points_container}>
                        <ol>
                            <li>
                                WHAT INFORMATION DO WE COLLECT?
                            </li>
                            <li>
                                HOW DO WE PROCESS YOUR INFORMATION?
                            </li>
                            <li>
                                WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                            </li>
                            <li>
                                DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                            </li>
                            <li>
                                HOW LONG DO WE KEEP YOUR INFORMATION?
                            </li>
                            <li>
                                HOW DO WE KEEP YOUR INFORMATION SAFE?
                            </li>
                            <li>
                                WHAT ARE YOUR PRIVACY RIGHTS?
                            </li>
                            <li>
                                CONTROLS FOR DO-NOT-TRACK FEATURES
                            </li>
                            <li>
                                DO WE MAKE UPDATES TO THIS POLICY?
                            </li>
                            <li>
                                HOW CAN YOU CONTACT US ABOUT THIS POLICY?
                            </li>
                            <li>
                                HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                            </li>
                        </ol>
                    </div>
                </>

                {/* 1 */}
                <>
                    <div className={styles.heading}>
                        1. WHAT INFORMATION DO WE COLLECT?
                    </div>
                    <div className={styles.text1}>
                        Personal information you disclose to us
                    </div>
                    <div className={styles.text1}>
                        In Short: We collect personal information that you provide to us.
                    </div>
                    <br /><br />
                    <div className={styles.text1}>
                        We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
                    </div>
                    <div className={styles.text1}>
                        <b>Personal Information Provided by You.</b> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:
                    </div>
                    <div className={styles.sub_points}>
                        <ul>
                            <b>
                                <li>
                                    Names
                                </li>
                                <li>
                                    Phone numbers
                                </li>
                                <li>
                                    Email addresses
                                </li>
                            </b>
                        </ul>
                    </div>
                    <div className={styles.text1}>
                        <b> Sensitive Information.</b> When necessary, with your consent or as otherwise permitted by applicable law, we process the following categories of sensitive information:
                    </div>
                    <div className={styles.sub_points}>
                        <ul>
                            <li>
                                <b> Creditworthiness data</b>
                            </li>
                        </ul>
                    </div>
                    <div className={styles.text1}>
                        <b>Photos, Files and Docs</b> -We collect photos, files, and docs uploaded by users in order to use various mobile application functionalities.
                        <br />
                        <b>Purchase Data</b>.We may collect information about purchases or transactions that a user has made and uploaded on our mobile application.
                        <br />
                        <b>Application Data.</b> If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:
                    </div>


                    <div className={styles.sub_points}>
                        <ul>
                            <li>
                                <b>Geolocation Information.</b> We may request access or permission to location-based information from your mobile device, while you are using our mobile application(s), to provide certain location-based services. If you wish to change our access or permissions, you may do so in your device's settings.
                            </li>
                            <li>
                                <b>Mobile Device Data.</b> We automatically collect device information (such as your mobile device ID, model, and manufacturer), operating system, version information and system configuration information, device and application identification numbers, browser type and version, hardware model Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our application(s), we may also collect information about the phone network associated with your mobile device, your mobile device’s operating system or platform, the type of mobile device you use, your mobile device’s unique device ID, and information about the features of our application(s) you accessed.
                            </li>
                            <li>
                                <b>Push Notifications. </b> We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device's settings.
                            </li>
                        </ul>
                    </div>
                    <div className={styles.text1}>
                        This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.
                        <br />
                        All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
                        <br />
                        Information automatically collected
                        <br />
                        In Short: Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.
                        We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.

                        <br />
                        <br />
                        Like many businesses, we also collect information through cookies and similar technologies.
                        <br />
                        <b>The information we collect includes:</b>

                        <div className={styles.sub_points}>
                            <ul>
                                <li>
                                    <b>Device Data.</b> We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.
                                </li>
                                <li>
                                    <b> Location Data.</b> We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.
                                </li>
                                <li>
                                    <b>Crash logs.</b>
                                    We collect Crash log data from your app. For example, the number of times that your app has crashed, stack traces or other information related to crashes.
                                </li>
                                <li>
                                    <b>Diagnostics Data.</b>
                                    We collect Information about the performance of your app. For example, battery life, loading time, latency, frame rate or technical diagnostics.
                                </li>
                                <li>
                                    <b>Derived Insights for Marketing.</b> Derivative data can also be used in marketing efforts. For example, insights derived from user data may inform targeted marketing campaigns or personalized push notifications.
                                </li>
                                <li>
                                    <b>User Analytics. </b> Derivative data could include analytics derived from user interactions within the app. This might involve tracking user behavior, engagement metrics, and other derived insights that help app developers understand how users interact with their apps.
                                </li>
                                <li>
                                    <b>Performance Metrics.</b> Data derived from the app's performance, such as load times, response times, and error rates, can be considered derivative data. These metrics are crucial for app developers to optimize and enhance the overall user experience
                                </li>
                                <li>
                                    <b>Derived User Profiles.</b> If your app collects user data, derivative data could involve creating user profiles based on user preferences, behavior, or other patterns. This can be used to offer personalized recommendations, content, or features.
                                </li>
                                <li>
                                    <b> Usage Patterns.</b> Analyzing how users navigate through the app, the features they use the most, and the paths they take can provide valuable derivative data. This information can guide developers in making user interface improvements and optimizing the app's workflow.
                                </li>
                            </ul>
                        </div>
                        <div className={styles.text1}>
                            <b> Information collected from other sources</b> <br />
                            In Short: We may collect limited data from public databases, marketing partners, and other <br /> outside sources. <br /> <br />
                            In order to enhance our ability to provide relevant marketing, offers, and services to you and update our records, we may obtain information about you from other sources, such as public databases, joint marketing partners, affiliate programs, data providers, and from other third parties. This information includes mailing addresses, job titles, email addresses, phone numbers, intent data (or user behaviour data), Internet Protocol (IP) addresses, social media profiles, social media URLs, and custom profiles, for purposes of targeted advertising and event promotion.
                            <br /><br />
                        </div>
                    </div>
                </>

                {/* 2 */}
                <>
                    <div className={styles.heading}>
                        2. HOW DO WE PROCESS YOUR INFORMATION?
                    </div>
                    <div className={styles.text1}>
                        In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.
                        <br /> <br />
                    </div>
                    <div className={styles.text1}>
                        <b>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</b>
                    </div>
                    <div className={styles.sub_points}>
                        <ul>
                            <li>
                                <b>To facilitate account creation and authentication and otherwise manage user accounts.</b> We may process your information so you can create and log in to your account, as well as keep your account in working order.
                            </li>
                            <li>
                                <b> To deliver and facilitate delivery of services to the user. </b>
                                We may process your information to provide you with the mobile application functionalities.
                            </li>
                            <li>
                                <b> To respond to user inquiries/offer support to users.</b> We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.
                            </li>
                            <li>
                                <b> To send administrative information to you.</b> We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.
                            </li>
                            <li>
                                <b>To fulfil and manage your orders.</b> We may process your information to fulfil and manage your orders, payments, returns, and exchanges made through the Services.
                            </li>
                            <li>
                                <b>To send you marketing and promotional communications.</b> We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time. For more information, see <b>' WHAT ARE YOUR PRIVACY RIGHTS? '</b> below.
                            </li>
                            <li>
                                <b> To protect our Services. </b> We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.
                            </li>
                            <li>
                                <b> To evaluate and improve our Services, products, marketing, and your experience. </b> We may process your information when we believe it is necessary to identify usage trends, determine the effectiveness of our promotional campaigns, and to evaluate and improve our Services, products, marketing, and your experience.
                            </li>
                            <li>
                                <b>To identify usage trends.</b> We may process information about how you use our Services to better understand how they are being used so we can improve them.
                            </li>
                            <li>
                                <b> To comply with our legal obligations.</b> We may process your information to comply with our legal obligations, respond to legal requests, and exercise, establish, or defend our legal rights.
                            </li>
                            <li>
                                <b> Process improvement.</b> We may use your information for other business purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Services, products, marketing and your experience.
                            </li>
                        </ul>
                    </div>
                </>

                {/* 3 */}
                <>
                    <div className={styles.heading}>
                        3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                    </div>
                    <div className={styles.text1}>
                        In Short: We may share information in specific situations described in this section and/or with the following categories of third parties. <br /> <br />
                    </div>
                    <div className={styles.text1}>
                        <b> Vendors, Consultants, and Other Third-Party Service Providers. </b> We may share your data with third-party vendors, service providers, contractors, or agents ( <b>'third parties'</b> ) who perform services for us or on our behalf and require access to such information to do that work. We have contracts in place with our third parties, which are designed to help safeguard your personal information. This means that they cannot do anything with your personal information unless we have instructed them to do it. They will also not share your personal information with any organisation apart from us. They also commit to protect the data they hold on our behalf and to retain it for the period we instruct. The categories of third parties we may share personal information with are as follows:
                        <br /><br />
                    </div>
                    <div className={styles.sub_points}>
                        <ul>
                            <li>
                                Cloud Computing Services
                            </li>
                            <li>
                                Communication & Collaboration Tools
                            </li>
                            <li>
                                Data Analytics Services
                            </li>
                            <li>
                                Data Storage Service Providers
                            </li>
                            <li>
                                Payment Processors
                            </li>
                            <li>
                                Product Engineering & Design Tools
                            </li>
                            <li>
                                Sales & Marketing Tools
                            </li>
                            <li>
                                Social Networks
                            </li>
                            <li>
                                User Account Registration & Authentication Services
                            </li>
                            <li>
                                Website Hosting Service Providers
                            </li>
                        </ul>
                    </div>
                    <div className={styles.text1}>
                        We also may need to share your personal information in the following situations:
                    </div>
                    <div className={styles.sub_points}>
                        <ul>
                            <li>
                                <b>Business Transfers.</b> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
                            </li>
                            <li>
                                <b>When we use Google Maps Platform APIs. </b> We may share your information with certain Google Maps Platform APIs (e.g. Geocoding API). We obtain and store on your device ( 'cache' ) your location . You may revoke your consent anytime by contacting us at the contact details provided at the end of this document.
                            </li>
                            <li>
                                <b>Business/Financial Partners.</b> We may share your information with our Business/Financial partners to offer you certain products, services, or promotions.
                            </li>
                        </ul>
                    </div>
                </>

                {/* 4 */}
                <>
                    <div className={styles.heading}>
                        4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                    </div>
                    <div className={styles.text1}>
                        In Short: We may use cookies and other tracking technologies to collect and store your information.
                    </div>
                    <div className={styles.text1}>
                        We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie policy.
                    </div>
                </>

                {/* 5 */}
                <>
                    <div className={styles.heading}>
                        5. HOW LONG DO WE KEEP YOUR INFORMATION?
                    </div>
                    <div className={styles.text1}>
                        In Short: We keep your information for as long as necessary to fulfil the purposes outlined in this privacy policy unless otherwise required by law.
                    </div>
                    <div className={styles.text1}>
                        We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this policy will require us keeping your personal information for longer than the period of time in which users have an account with us
                    </div>
                    <div className={styles.text1}>
                        When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
                    </div>
                </>

                {/* 6 */}
                <>
                    <div className={styles.heading}>
                        6. HOW DO WE KEEP YOUR INFORMATION SAFE?
                    </div>
                    <div className={styles.text1}>
                        In Short: We aim to protect your personal information through a system of organisational and technical security measures.
                    </div>
                    <div className={styles.text1}>
                        We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any personal information we process. We Handle all personal and sensitive user data securely, including transmitting it using modern cryptography (over HTTPS). We Use a runtime permissions request whenever available, prior to accessing data gated by Android permissions. We do not sell personal and sensitive user data. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.
                    </div>
                </>

                {/* 7 */}
                <>
                    <div className={styles.heading}>
                        7. WHAT ARE YOUR PRIVACY RIGHTS?

                    </div>
                    <div className={styles.text1}>
                        In Short: You may review, change, or terminate your account at any time.
                    </div>
                    <div className={styles.text1}>
                        <b> Withdrawing your consent:</b> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section ' HOW CAN YOU CONTACT US ABOUT THIS policy? ' below .
                    </div>
                    <div className={styles.text1}>
                        However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
                    </div>
                    <div className={styles.text1}>
                        <b>Opting out of marketing and promotional communications:</b> You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, replying 'STOP' or 'UNSUBSCRIBE' to the SMS messages that we send, or by contacting us using the details provided in the section <b>' HOW CAN YOU CONTACT US ABOUT THIS policy? ' </b> below. You will then be removed from the marketing lists. However, we may still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.
                        <br /> <br />
                        <b>Account Information</b> <br />
                        If you would at any time like to review or change the information in your account or terminate your account, you can:
                        <br />
                        <div className={styles.sub_points}>
                            <ul>
                                <li>
                                    <b>Contact us using the contact information provided.</b>
                                </li>
                            </ul>
                        </div>
                        <div className={styles.text1}>
                            Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.
                        </div>
                        <div className={styles.text1}>
                            <b>Cookies and similar technologies:</b> Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services.
                        </div>
                    </div>
                </>

                {/* 8 */}
                <>
                    <div className={styles.heading}>
                        8. CONTROLS FOR DO-NOT-TRACK FEATURES
                    </div>
                    <div className={styles.text1}>
                        Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ( 'DNT' ) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognising and implementing DNT signals has been finalised . As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy policy.
                    </div>
                </>

                {/* 9 */}
                <>
                    <div className={styles.heading}>
                        9. DO WE MAKE UPDATES TO THIS policy?
                    </div>
                    <div className={styles.text1}>
                        In Short: Yes, we will update this policy as necessary to stay compliant with relevant laws.
                    </div>
                    <div className={styles.text1}>
                        We may update this privacy policy from time to time. The updated version will be indicated by an updated 'Revised' date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify you either by prominently posting a policy of such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.
                    </div>
                </>

                {/* 10 */}
                <>
                    <div className={styles.heading}>
                        10. HOW CAN YOU CONTACT US ABOUT THIS policy?
                    </div>
                    <div className={styles.text1}>
                        If you have questions or comments about this policy, you may email us at <a target='_blank' href="https://www.nxcar.in/contact_us">
                            contact@nxcar.in
                        </a> or contact us by post at  <a target='_blank' href="https://www.nxcar.in/contact_us">
                            https://www.nxcar.in/contact_us
                        </a>

                        <div className={styles.text1}>
                            Follow these steps-
                        </div>
                        <div className={styles.sub_points}>
                            <ul>
                                <li>
                                    Enter your Name
                                </li>
                                <li>
                                    Enter the mobile number registered with us for existing users and the Mobile number used while signing up for new users
                                </li>
                                <li>
                                    Subject- Inquiry Regarding [Specific Aspect] of Nxcar's Privacy Policy
                                </li>
                                <li>
                                    Enter a Detailed description in enter enquiry input field
                                </li>
                                <li>
                                    Submit
                                </li>
                            </ul>
                        </div>
                    </div>
                </>

                {/* 11 */}
                <>
                    <div className={styles.heading}>
                        11.  HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                    </div>
                    <div className={styles.text1}>
                        Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it.
                        To delete any information user is required to delete their account. To request to review, update, or delete your personal information/account, please fill out and submit a request at
                        <a target='_blank' href="/p1rtn5rs-@ccou5t"> https://www.nxcar.in/p1rtn5rs-@ccou5t</a>
                    </div>

                    <div className={styles.text1}>
                        Follow these steps-
                    </div>
                    <div className={styles.sub_points}>
                        <ul>
                            <li>
                                Enter your Name
                            </li>
                            <li>
                                Enter the mobile number registered with us for existing users and the Mobile number used while signing up for new users
                            </li>
                            <li>
                                Subject- Inquiry Regarding [Specific Aspect] of Data Collection
                            </li>
                            <li>
                                Enter a Detailed description in enter enquiry input field
                            </li>
                            <li>
                                Submit
                            </li>
                        </ul>
                    </div>
                </>
            </div>
        </div>
    )
}

export default AppSidePolicy
