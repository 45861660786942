import React, { useEffect, useState } from 'react'
import styles from './Profile.module.css';
import Main from '../../../../Layout/Dealer/Main/Main'
import axios from 'axios';
import QRCode from 'qrcode.react';
import { Link } from 'react-router-dom';
import { MdContentCopy, MdOutlineContentCopy } from "react-icons/md";
import { FiShare2 } from "react-icons/fi";
import share_button from '../../../../images/button/share_button.svg'
import Box from '@mui/material/Box';
import copy from '../../../../images/button/content_copy.svg'
import Modal from '@mui/material/Modal';
import {
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    XIcon,
} from "react-share";
import { jwtDecode } from "jwt-decode";
import Dashboard from '../Dashboard/Dashboard';


const Profile = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [id, setId] = useState('');
    const [dealerData, setDealerData] = useState('');
    const [qrData, setQrData] = useState('');

    const token = localStorage.getItem('token');
    const decoded = jwtDecode(token);
    const dealerId = decoded.uid || decoded.id;
    // console.log('decoded', decoded)


    const dealer_url = 'https://dev.nxfin.in/api/uat.app'


    const fetchData = async () => {
        try {
            if (token) {
                const response = await axios.get(`${dealer_url}/profile?dealer_id=${dealerId}`, {
                    // const response = await axios.get(`profile?dealer_id=49`, {
                    headers: {
                        Authorization: token,
                    },
                });
                // console.log(`my dealer profile --> `, response.data);

                // if (response.data.type === 'Partnership')   {
                const fetchedId = response.data.id;

                if (fetchedId) {
                    setDealerData(response.data);
                    setId(fetchedId);

                    // FOR PROD
                    // const qrData = `https://www.nxcar.in/consumer-login?id=${fetchedId}`;
                    // setQrData(qrData);

                    // FOR DEV
                    const qrData = `https://www.nxcar.in/consumer-login?id=${fetchedId}`;
                    setQrData(qrData);

                    localStorage.setItem('my-dealer-id', fetchedId);
                    setTimeout(() => {
                        localStorage.removeItem('scannedID');
                    }, 24 * 60 * 60 * 1000);

                }
                // else if (fetchedId === undefined) {
                //     alert('You are not a Dealer or Partner');
                // }
                // }
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [token]);

    const dealerName = dealerData.name;
    const firstLetter = dealerName ? dealerName.substring(0, 1) : '';

    return (
        <Main>
            <div className={styles.container}>
                <div className={styles.right}>
                    {/* WELCOME TEXT */}
                    <div className={styles.welcome_text}>
                        <div className={styles.welcome_text1}>My Profile</div>

                    </div>

                    {/* DASHBOARD */}
                    <div className={styles.qr_code_container}>
                        <div className={styles.left_qrcode}>
                            <div className={styles.name}>
                                <div className={styles.name_circle}>
                                    {firstLetter}
                                </div>
                                {dealerName}
                            </div>
                            <div className={styles.name_line}>
                                Unlock Your Dream Car: Scan Now  for easy loan
                            </div>

                            {id && (
                                <>
                                    <div>
                                        <QRCode
                                            size={240}
                                            value={qrData}
                                            className={styles.qrcode_line}
                                        />
                                    </div>
                                </>
                            )}

                            <div className={styles.share_button}>
                                <div
                                    onClick={handleOpen}
                                    className={styles.share_button_1}
                                >
                                    <FiShare2 size={20} /> Share
                                </div>

                                <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box
                                        className={styles.share_modal}
                                    >
                                        <div>
                                            <WhatsappShareButton
                                                url={qrData}
                                            >
                                                <WhatsappIcon size={32} round />
                                            </WhatsappShareButton>
                                        </div>
                                        <div>
                                            <TelegramShareButton
                                                url={qrData}
                                            >
                                                <TelegramIcon size={32} round />
                                            </TelegramShareButton>
                                        </div>
                                        <div>
                                            <TwitterShareButton
                                                url={qrData}
                                            >
                                                <XIcon size={32} round />
                                            </TwitterShareButton>
                                        </div>
                                        <div>
                                            <FacebookMessengerShareButton
                                                url={qrData}
                                            >
                                                <FacebookMessengerIcon size={32} round />
                                            </FacebookMessengerShareButton>
                                        </div>
                                    </Box>
                                </Modal>
                            </div>
                        </div>

                        <div className={styles.right_qrcode}>
                            <div className={styles.word_link}>
                                <Link
                                    to={qrData}
                                    target="_blank"
                                    style={{ textAlign: "left", color: "black", fontSize: "16px" }}
                                >
                                    {qrData} &nbsp;
                                </Link>
                                <button
                                    className={styles.copy}
                                    onClick={() => {
                                        navigator.clipboard.writeText(qrData);
                                    }}
                                >
                                    <img src={copy} alt="" />
                                </button>
                            </div>

                            <ol style={{ textAlign: "left", marginTop: '30px', listStyleType: 'decimal' }}>
                                <li>Be ready with Identity proofs (PAN, Aadhaar)</li>
                                <li>Residence proofs ( Aadhaar , Voter id , etc) </li>
                                <li>Income proofs (3 month Salary slips, 3 yrs ITR) </li>
                                <li>Others (Bank Statement, Electricity Bill)</li>
                            </ol>
                        </div>
                        <div
                            onClick={handleOpen}
                            className={styles.share_button_2}
                        >
                            <span ><FiShare2 size={24} /></span> <span>Share</span>
                        </div>
                    </div>

                    <div className={styles.details_container}>
                        <div className={styles.details_left}>
                            Dealer Name
                            <div className={styles.details}>
                                {dealerData.name}
                            </div>
                            Company Name
                            <div className={styles.details}>
                                {dealerData.company_name}
                            </div>
                            Contact Number
                            <div className={styles.details}>
                                {dealerData.phoneNumber}
                            </div>
                            Shop Address
                            <div className={styles.details}>
                                {dealerData.shop_address}
                            </div>
                            Residence Address
                            <div className={styles.details}>
                                {dealerData.residence_address}
                            </div>
                        </div>

                        <div className={styles.details_right}>
                            Email
                            <div className={styles.details}>
                                {dealerData.email}
                            </div>
                            Aleternative Contact Number
                            <div className={styles.details}>
                                {dealerData.alternative_contact_number}
                            </div>
                            Type
                            <div className={styles.details}>
                                {dealerData.type}
                            </div>
                            City
                            <div className={styles.details}>
                                {dealerData.city}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Main>
    )
}

export default Profile