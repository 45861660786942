import React, { forwardRef } from 'react';
import styles from '../../DriveAway.module.css';
import style from './Report.module.css';
import PackageWithPrice from '../insurance/PackageWithPrice';
import mobile_insurance from '../../../../images/vectors/carscope/car_insurance.jpg';
import RcReport from '../../../rcReport/RcReport';
import WarrantyPDF from '../warranty/WarrantyPDF';
import PdfViewer from '../../../pdfViewer/PdfViewer';

const ReportComponent = forwardRef(({ data1 }, ref) => {
  const mypdf = data1?.inspection_report?.split('.');
  let isPDF;

  if (mypdf && mypdf.length >= 5) {
    const isPDF1 = mypdf[5];
    isPDF = isPDF1?.toLowerCase().startsWith('pdf');
  }


  return (

    <div className={styles.container} ref={ref}>

      {/* RC REPORT */}
      <RcReport data1={data1.rc_report_generate} />

      {/* WARRANTY AND VEHICLE DETAILS */}
      <WarrantyPDF data1={data1} />

      {/* INSURANCE QUOTES PROPOSAL */}
      <div className={styles.blue_border}>
        <div className={styles.mobile_image}>
          <img src={mobile_insurance} alt="" />
        </div>
        <div className={styles.heading2}>Insurance Proposals</div>
        <PackageWithPrice data1={data1} />
      </div>

      {/* INSPECTION REPORT */}
      {/* <div className={styles.heading2}>Inspection Report</div>
      <div className={style.flex1}>
        {isPDF ? (
          <PdfViewer pdfUrl={data1?.inspection_report} />
        ) : (
          data1.inspection_report &&
          <img
            className={style.image}
            src={data1?.inspection_report}
            alt="inspection-report"
          />
        )}
      </div> */}
    </div>
  )
});

export default ReportComponent;
