import React, { useState } from 'react'
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Consumer from '../../../../components/FORMS/Consumer/Consumer';
import styles from './DriveAwayForm.module.css';
import used_car_loan from '../../../../images/vectors/homescreen/services/used_car.svg';
import { CiLogin } from "react-icons/ci";
import banner from '../../../../images/vectors/carscope/banner.svg';
import mobile_banner from '../../../../images/vectors/carscope/mobile_banner.svg';


const styleMUIModal = {
  position: "absolute",
  top: "0%",
  right: "0%",
  width: '40%',
  height: '100%',
  bgcolor: "white",
  boxShadow: 24,
  outline: 0,
  p: 4,
};

const styleMUIModalMobile = {
  width: "100%",
  p: "0",
}

export default function DriveAwayForm() {
  const [openConsumerModal, setOpenConsumerModal] = useState(false);

  const handleLogin = () => {
    setOpenConsumerModal(!openConsumerModal);
  }
  const closeModal = () => {
    setOpenConsumerModal(false);
  }

  return (
    <>
      {/* <div className={styles.container} onClick={handleLogin}>
        <div className={styles.image}>
          <img src={used_car_loan} alt="" />
        </div>

        <div className={styles.login_button}>Apply</div>

        <div className={styles.text1}>
          <div className={styles.text_heading}></div>
          <div className={styles.text_heading}></div>
          <div className={styles.text}></div>
          <div className={styles.text}></div>
          <div className={styles.text}></div>
          <div className={styles.text}></div>
          <div className={styles.text_heading}></div>
          <div className={styles.text}></div>
          <div className={styles.text}></div>
        </div>
      </div> */}

      <div className={styles.banner} onClick={handleLogin}>
        <img className={styles.web} src={banner} alt="" />
        <img className={styles.mobile} src={mobile_banner} alt="" />
      </div>


      {/* MODAL */}
      <Modal
        open={openConsumerModal}
        onClose={handleLogin}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...styleMUIModal, ...(window.innerWidth <= 600 && styleMUIModalMobile) }}>
          <Consumer onClose={closeModal} />
        </Box>
      </Modal>
    </>
  )
}