import { Route, Routes, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './Language.style.css'
import Dashboard from './screens/AllDashboards/Dealer/Dashboard/Dashboard';
import LoanStatus from './screens/AllDashboards/Dealer/LoanStatus/LoanStatus';
import ApplicationStatus from './screens/AllDashboards/Dealer/ApplicationStatus/ApplicationStatus';
import Profile from './screens/AllDashboards/Dealer/Profile/Profile';
import LoanApplication from './screens/AllDashboards/Dealer/LoanApplicationForm/LoanApplication';
import ConsumerDashboard from './screens/AllDashboards/Consumer/Dashboard/Dashboard';
import Homescreen from './screens/Static/home/Home';
import About from './screens/Static/about/About';
import Used_car_loan from './screens/Static/used_car_loan/Used_car_loan';
import Calculator from './screens/Static/calculator/Calculator';
import Car_services from './screens/Static/car_services/Car_services';
import Channel_partner from './screens/Static/channel_partner/Channel_partner';
import Contact_us from './screens/Static/contact_us/Contact_us';
import Privacy_policy from './screens/policyAndTerms/privacy_policy/Privacy_policy';
import Terms_of_use from './screens/policyAndTerms/terms_of_use/Terms_of_use';
import Grievance from './screens/policyAndTerms/grievance_policy/Grievance';
import Page_Not_Found from './screens/Static/page_not_found/Page_Not_Found';
import FAQ from './screens/policyAndTerms/FAQ/FAQ';
import AppSidePolicy from './screens/policyAndTerms/AppSidePolicy/AppSidePolicy';
import Consumer from './components/FORMS/Consumer/Consumer';
import Partner from './components/FORMS/Partner/Partner';
import Testing from './Testing/Testing';
import TermsOfService from './screens/policyAndTerms/TermsOfService/TermsOfService';
import Cart from './components/homescreen_compo/cart/Cart';
import Your_journey from './screens/Static/your_journey/Your_journey';
import NewHero from './reusable/hero/HomeHero/NewHero';
import Buy_Sell from './screens/Static/buy_sell/Buy_Sell';
import Blog1 from './screens/Static/blogs/blog1/Blog1';
import Blog2 from './screens/Static/blogs/blog1/Blog2';
import Blog3 from './screens/Static/blogs/blog1/Blog3';
import Blog4 from './screens/Static/blogs/blog1/Blog4';
import Blog5 from './screens/Static/blogs/blog1/Blog5';
import ExtendedWarranty from './screens/policyAndTerms/ExtendedWarranty/ExtendedWarranty';
import AllBlogs from './screens/Static/blogs/allBlogs/AllBlogs';
import NewCards from './components/channel_partner/new_cards/NewCards';
import PreLoaderWebsite from './components/preloaderWebsite/PreLoaderWebsite';
import Landing from './screens/Static/landing/Landing';
import Career from './screens/Static/career/Career';
import AdminPage from './components/career/AdminPage/AdminPage';
import JobForm from './components/career/jobForm/JobForm';
import SellCar from './screens/Static/sell_car/SellCar';
import RcReport from './reusable/rcReport/RcReport';
import RcCheck from './components/homescreen_compo/servicesWeOffer/rcCheck/RcCheck';
import InsuranceCheck from './components/homescreen_compo/servicesWeOffer/insuranceCheck/InsuranceCheck';
import DriveAway from './reusable/driveAway/DriveAway';
import Flyer from './Testing/flyer/Flyer';
import LoanApplication3 from './screens/AllDashboards/Consumer/LoanApplication3/LoanApplication3';
import LoanHistory from './screens/AllDashboards/Consumer/LoanHistory/LoanHistory';
import ConsumerProfile from './screens/AllDashboards/Consumer/Profile/Profile';
import RCReportShare from './reusable/rcReport/RCReportShare';
import RedirectDriveawayToCarscope from './reusable/driveAway/RedirectDriveawayToCarscope';
import TestingPage from './utils/TestingPage';
import Redirect from './Testing/QRNxcar/Redirect';
import AppQR from './Testing/QRNxcar/AppQR';
import AccountDeletion from './screens/Static/contact_us/AccountDeletion';
import ApniCar from './screens/Static/landing/ApniCarApniMarzi/ApniCar';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      // setIsLoading(false);
    }, 800);
  }, []);

  // FOR SCREEN SIZE IN ALL DEVICES + + + + + + + + + + + + + + + + + + + + + + + + 
  // const [screenSize, setScreenSize] = useState(window.innerWidth);
  // useEffect(() => {
  //   const handleResize = () => {
  //     setScreenSize(window.innerWidth);
  //   };
  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (screenSize >= 940) {
  //     document.body.style.zoom = "90%";
  //   } else {
  //     document.body.style.zoom = "100%";
  //   }
  //   return () => {
  //     document.body.style.zoom = "100%";
  //   };
  // }, [screenSize]);

  //  + + + + + + + + + + + + + + + + + + + + + + + + 

  // FOR LANGUAGE BARRIER -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> 
  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement({
  //     pageLanguage: 'en',
  //     includedLanguages: "en,hi",
  //     layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE
  //   },
  //     'google_translate_element');
  // };

  // useEffect(() => {
  //   let addScript = document.createElement('script');
  //   addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;
  // }, []);
  // -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> -> ->

  return (
    <>
      {/* <div
        className="header"
        id="google_translate_element">
      </div> */}

      {/* { isLoading ? (<><PreLoaderWebsite /></>) : ( null ) }*/}

      <Routes>
        {/* For Testing */}
        <Route exact path="/testing" element={<Testing />} />
        {/* <Route exact path="/test" element={<TestingPage />} /> */}


        {/* Static Part Of Website */}
        <Route exact path="*" element={<Page_Not_Found />} />
        <Route exact path="/" element={<Homescreen />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/about_us" element={<About />} />
        <Route exact path="/used-car-loan" element={<Used_car_loan />} />
        <Route exact path="/calculator" element={<Calculator />} />
        <Route exact path="/car_services" element={<Car_services />} />
        <Route exact path="/partner" element={<Channel_partner />} />
        <Route exact path="/contact_us" element={<Contact_us />} />
        <Route exact path="/p1rtn5rs-@ccou5t" element={<AccountDeletion />} />  {/* FOR APP' ACCOUNT SIDE */}
        <Route exact path="/nxcar-journey" element={<Your_journey />} />


        {/* Policy && terms */}
        <Route exact path="/privacy-policy" element={<Privacy_policy />} />
        <Route exact path="/faq" element={<FAQ />} />
        <Route exact path="/terms-of-use" element={<Terms_of_use />} />
        <Route exact path="/grievance-policy" element={<Grievance />} />
        <Route exact path="/nxcar-extended-warranty-terms-and-conditions" element={<ExtendedWarranty />} />
        <Route exact path="/nxcar-p1rtn5rs-pr1va3y-poli3y" element={<AppSidePolicy />} />  {/* FOR APP SIDE */}
        <Route exact path="/nxcar-t3rms-8$d-m$rv$pe" element={<TermsOfService />} />  {/* FOR Terms And Condition Side */}
        <Route exact path="/redirect" element={<Redirect />} /> {/* APP QR REDIRECTION PAGE */}
        <Route exact path="/app_qr" element={<AppQR />} /> {/* APP QR */}


        {/* Dealer Dashboard */}
        <Route path="/dealer-dashboard" element={<Dashboard />} />
        <Route path="/dealer-loan-form" element={<LoanApplication />} />
        <Route path="/dealer-loan-status" element={<LoanStatus />} />
        <Route path="/dealer-application-status" element={<ApplicationStatus />} />
        <Route path="/dealer-profile" element={<Profile />} />


        {/* Consumer Dashboard */}
        <Route path="/consumer-dashboard" element={<ConsumerDashboard />} />
        <Route path="/consumer-history" element={<LoanHistory />} />
        <Route path="/consumer-loan-form" element={<LoanApplication3 />} />
        <Route path="/consumer-profile" element={<ConsumerProfile />} />


        {/* ⚠️ DO NOT TOUCH ⚠️ {QR codes are made already} ⚠️ */}
        <Route path="/consumer-login" element={<Consumer />} />


        {/* New Business Modal Redirect Page [CART] */}
        <Route path="/bundle" element={<Cart />} />


        {/* Particular Forms URLs new */}
        <Route path="/partner-form" element={<Homescreen />} />
        <Route path="/partner-form" element={<NewCards />} />
        <Route path="/consumer-form" element={<Homescreen />} />
        <Route path="/buy-sell" element={<Buy_Sell />} />
        <Route path="/nxcarpackage" element={<Cart />} />
        <Route path="/used-car-loan/eligibility" element={<Used_car_loan />} />
        <Route path="/car-services-extended-warranty" element={<Car_services />} />
        <Route path="/car-services-inspection-valuation" element={<Car_services />} />
        <Route path="/car-services-document-verification" element={<Car_services />} />
        <Route path="/car-services-insurance-transfer" element={<Car_services />} />
        <Route path="/car-services-rc-transfer" element={<Car_services />} />
        <Route exact path="/used-car-loan-special-offer" element={<Landing />} />
        <Route exact path="/apni_car_apni_marzi" element={<ApniCar />} />


        {/* Blogs URLS */}
        <Route path="/blogs-of-nxcar" element={<AllBlogs />} />
        <Route path="/blog-should-you-buy-a-used-car" element={<Blog1 />} />
        <Route path="/blog-how-to-buy-a-used-car" element={<Blog2 />} />
        <Route path="/blog-when-not-to-buy-a-used-car" element={<Blog3 />} />
        <Route path="/blog-right-time-to-buy-car" element={<Blog4 />} />
        <Route path="/blog-before-you-buy-used-car" element={<Blog5 />} />


        {/* Career */}
        {/* <Route exact path="/career" element={<Career />} /> */}
        {/* <Route exact path="/career/:jobProfile" element={<JobForm />} /> */}
        {/* <Route exact path="/career/admin" element={<AdminPage />} /> */}


        {/* Sell Car */}
        {/* <Route exact path="/sell-car" element={<SellCar />} /> */}


        {/* Checks */}
        <Route path="/insurance-check" element={<InsuranceCheck />} />
        <Route path="/rc-check" element={<RcCheck />} />
        <Route exact path="/rc-details" element={<RcReport />} />
        <Route exact path="/rc-details/:vehicleNumber1" element={<RCReportShare />} />


        {/* Driveaway */}
        <Route path="/carscope" element={<DriveAway />} />
        <Route path="/driveaway/:vehicleNumber" element={<RedirectDriveawayToCarscope />} />
        <Route path="/carscope/:vehicleNumber" element={<DriveAway />} />
        {/* <Route path="/flyer" elem ent={<Flyer />} /> */}

      </Routes>
    </>
  );
};

export default App;