import React from 'react'
import styles from './General_process.module.css';
import web from '../../images/vectors/homescreen/general_process/main_general_process.svg'
import mobile from '../../images/vectors/homescreen/general_process/Mobile_general_process.svg'
import Fade from 'react-reveal/Fade';


const General_process = () => {
    return (
        <>
            <div className={styles.container}>
                {/* web */}
                {/* <Fade bottom cascade> */}
                    <div className={styles.process_container}>
                        <img src={web} alt="" />
                    </div>
                {/* </Fade> */}


                {/* mobile */}
                <Fade bottom cascade>
                    <div className={styles.mobile_process_container}>
                        <img src={mobile} alt="" />
                    </div>
                </Fade>
            </div>
        </>
    )
}

export default General_process