import React from 'react'
import styles from './Application.module.css';
import Main from '../../../../Layout/Dealer/Main/Main'
import Table from '../../../../reusable/table/Table';
import { useState, useEffect } from 'react';
import axios from 'axios';


const ApplicationStatus = () => {
    const [name, setName] = useState('');
    const [loanData, setLoanData] = useState([]);
    const token = localStorage.getItem('token');
    const dealer_id = localStorage.getItem('my-dealer-id')

    // PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const range = 2;
    const totalItems = loanData.length;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = loanData.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    
    const dealer_url = 'https://dev.nxfin.in/api/uat.app'

    const fetchData = async () => {
        try {
            const response = await axios.get(`${dealer_url}/dealer_dashboard?dealer_id=${dealer_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${token}`,
                },
            });
            // console.log('response', response.data.loan_data);
            // console.log('res', loanData)
            if (response.data.loan_data) {
                setLoanData(response.data.loan_data);
            } else {
                console.error('API request failed:', response);
            }
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const columns = [
        { label: 'Application No', field: 'application_no' },
        { label: 'Status', field: 'application_status' },
        { label: 'Reason', field: 'reason' },
    ];



    return (
        <Main>
            <div className={styles.container}>
                <div className={styles.right}>
                    {/* WELCOME TEXT */}
                    <div className={styles.welcome_text}>
                        <div className={styles.welcome_text1}>Application Status</div>
                        <div className={styles.welcome_text2}>
                            {/* Lorem ipsum dolor sit amet consectetur adipisicing elit */}
                        </div>
                    </div>

                    {/* Application */}
                    <div className={styles.table}>
                        {/* <Table data={loanData} columns={columns} /> */}



                        {/* WITH PAGINATION */}
                        <Table data={currentData} columns={columns} />

                        <div className={styles.pagination}>
                            <button
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Prev
                            </button>

                            {[...Array(Math.ceil(totalItems / itemsPerPage))].map((_, index) => {
                                const page = index + 1;
                                const isCurrentPage = currentPage === page;
                                if (page >= currentPage - range && page <= currentPage + range) {
                                    return (
                                        <button
                                            key={index}
                                            onClick={() => handlePageChange(page)}
                                            className={isCurrentPage ? styles.activePage : ''}
                                        >
                                            {page}
                                        </button>
                                    );
                                }
                                return null;
                            })}

                            <button
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Main>
    )
}

export default ApplicationStatus