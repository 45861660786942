import React from 'react'
import styles from './Page_Not_Found.module.css'
import { Link } from 'react-router-dom'
import { TbArrowBackUp } from "react-icons/tb";
import logo from '../../../images/logos/white_logo_bg.svg'

const Page_Not_Found = () => {
    return (
        <>
            <div className={styles.container}>
                <div className={styles.pagenot_found}>
                    {/* 404 || Not Found */}
                    The page you’re looking
                    <br />
                    for can’t be found.
                </div>

                <Link to='/'>
                    <div className={styles.back}>
                        Back To Home  {" "}
                        <TbArrowBackUp size={20} />
                    </div>
                </Link>
                <div className={styles.logo}> <img src={logo} alt="" /> </div>
            </div>
        </>
    )
}

export default Page_Not_Found